import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { ListTalentEvents } from "./ListTalentEvents";
import { IAppState } from "../../../generic/interfaces";
import { setPage } from "../../../HomePage/homePageSlice";
import {
	listBandsEventsList,
	listBandsPastEventsList,
	setFilterText,
	setPastFilterText,
	getRequestHistory,
	deleteRequest,
	loadMore,
	loadMorePast,
	changeRequestStatus,
} from "./listTalentEventsSlice";
import { openReviewTalentDialog } from "../ReviewTalent/reviewTalentSlice";

const mapStateToProps = (state: IAppState, ownProps: any) => ({
	authentication: state.authentication,
	bandEvents: state.account.bandEvent,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
	listBandsEventsList: (bandId: number, filterText: string) =>
		dispatch(listBandsEventsList(bandId, filterText)),
	setPage: (page: string) => {
		dispatch(setPage(page));
	},
	listBandsPastEventsList: (bandId: number, filterText: string) =>
		dispatch(listBandsPastEventsList(bandId, filterText)),
	setFilterText: (textFilter: string) => dispatch(setFilterText(textFilter)),
	setPastFilterText: (pastTextFilter: string) =>
		dispatch(setPastFilterText(pastTextFilter)),
	deleteRequest: (requestId: number) => dispatch(deleteRequest(requestId)),
	getRequestHistory: (eventId: number) =>
		dispatch(getRequestHistory(eventId)),
	loadMore: (bandId: number, textFilter: string) =>
		dispatch(loadMore(bandId, textFilter)),
	loadMorePast: (bandId: number, pastTextFilter: string) =>
		dispatch(loadMorePast(bandId, pastTextFilter)),
	changeRequestStatus: (requestId: number, status: string) =>
		dispatch(changeRequestStatus(requestId, status)),
	openReviewTalentDialog: (
		bandId: number,
		eventId: number,
		location: string,
	) =>
		dispatch(
			openReviewTalentDialog({
				bandId,
				eventId,
				location,
			}),
		),
});

export default connect(mapStateToProps, mapDispatchToProps)(ListTalentEvents);
