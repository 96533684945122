import React from "react";
import Rating from "../../../generic/Rating";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

const Form = (props: {
	classes?: any;
	errors: any;
	values: any;
	touched: any;
	handleChange: any;
	isValid: any;
	setFieldTouched: any;
	setFieldValue: any;
	handleSubmit: any;
	disabled: boolean;
	type: string;
}) => {
	const {
		errors,
		values,
		touched,
		handleChange,
		setFieldTouched,
		setFieldValue,
		handleSubmit,
		disabled,
		type,
	} = props;

	const change = (name: any, e: { persist: () => void }) => {
		e.persist();
		handleChange(e);
		setFieldTouched(name, true, false);
	};

	return (
		<Box p={5}>
			<form onSubmit={handleSubmit}>
				<Grid container spacing={6}>
					<Grid item xs={12}>
						<Typography
							variant="body1"
							style={{ fontWeight: "bold" }}>
							How was your overall experience using Repsy?
						</Typography>
						{/* added rating component and provided handleClick fuc */}
						<Rating
							color="#64FECC"
							width="24px"
							rate={values.repsyRating}
							handleClick={(rate: number) => {
								setFieldValue("repsyRating", rate);
							}}
						/>
						<TextField
							autoComplete="off"
							id="repsyReview"
							name="repsyReview"
							helperText={
								touched.repsyReview ? errors.repsyReview : ""
							}
							error={
								touched.repsyReview &&
								Boolean(errors.repsyReview)
							}
							variant="outlined"
							color="secondary"
							fullWidth={true}
							margin="dense"
							multiline={true}
							rows={8}
							onChange={change.bind(null, "repsyReview")}
						/>
					</Grid>
					<Grid item xs={12}>
						<Typography
							variant="body1"
							style={{ fontWeight: "bold" }}>
							Please rate your experince with the {type}?
						</Typography>
						{/* added rating component and provided handleClick fuc */}
						<Rating
							color="#64FECC"
							width="24px"
							rate={values.userRating}
							handleClick={(rate: number) => {
								setFieldValue("userRating", rate);
							}}
						/>
						<TextField
							autoComplete="off"
							id="userReview"
							name="userReview"
							helperText={
								touched.userReview ? errors.userReview : ""
							}
							error={
								touched.userReview && Boolean(errors.userReview)
							}
							variant="outlined"
							color="secondary"
							fullWidth={true}
							margin="dense"
							multiline={true}
							rows={8}
							onChange={change.bind(null, "userReview")}
						/>
					</Grid>
				</Grid>
				<Grid
					container
					direction="column"
					justify="center"
					alignItems="center"
					spacing={3}>
					<Grid item>
						<Button
							type="submit"
							variant="contained"
							onClick={handleSubmit}
							color="secondary"
							size="large"
							disabled={disabled}>
							Submit
						</Button>
					</Grid>
				</Grid>
			</form>
		</Box>
	);
};

export default Form;
