import {
	IListEventRequestAction,
	ListEventRequestActionType,
	IItemEventRequest,
} from "../interfaces";
import { combineReducers } from "redux";
import { itemBookerRequestReducer } from "./request-item-reducer";

const isRequested = (state = false, action: IListEventRequestAction) => {
	switch (action.type) {
		case ListEventRequestActionType.BOOKER_REQUEST_LIST_REQUEST:
			return true;
		case ListEventRequestActionType.BOOKER_REQUEST_LIST_SUCCESS:
		case ListEventRequestActionType.BOOKER_REQUEST_LIST_FAILURE:
			return false;
		default:
			return state;
	}
};

const isSuccess = (state = false, action: IListEventRequestAction) => {
	switch (action.type) {
		case ListEventRequestActionType.BOOKER_REQUEST_LIST_SUCCESS:
			return true;
		case ListEventRequestActionType.BOOKER_REQUEST_LIST_REQUEST:
		case ListEventRequestActionType.BOOKER_REQUEST_LIST_FAILURE:
			return false;
		default:
			return state;
	}
};

const loadMoreIsRequested = (
	state = false,
	action: IListEventRequestAction,
) => {
	switch (action.type) {
		case ListEventRequestActionType.LOADMORE_BOOKER_REQUEST_LIST_REQUEST:
			return true;
		case ListEventRequestActionType.LOADMORE_BOOKER_REQUEST_LIST_SUCCESS:
		case ListEventRequestActionType.LOADMORE_BOOKER_REQUEST_LIST_FAILURE:
			return false;
		default:
			return state;
	}
};

const loadMoreIsSuccess = (state = false, action: IListEventRequestAction) => {
	switch (action.type) {
		case ListEventRequestActionType.LOADMORE_BOOKER_REQUEST_LIST_SUCCESS:
			return true;
		case ListEventRequestActionType.LOADMORE_BOOKER_REQUEST_LIST_REQUEST:
		case ListEventRequestActionType.LOADMORE_BOOKER_REQUEST_LIST_FAILURE:
			return false;
		default:
			return state;
	}
};

const bookerRequestPage = (state = 1, action: IListEventRequestAction) => {
	switch (action.type) {
		case ListEventRequestActionType.BOOKER_REQUEST_LIST_SUCCESS:
		case ListEventRequestActionType.LOADMORE_BOOKER_REQUEST_LIST_SUCCESS:
			return state + 1;
		default:
			return state;
	}
};

// const totalItems = (state = 0, action: IListEventRequestAction) => {
// 	switch (action.type) {
// 		case ListEventRequestActionType.BOOKER_REQUEST_LIST_SUCCESS:
// 		case ListEventRequestActionType.LOADMORE_BOOKER_REQUEST_LIST_SUCCESS:
// 			return action.payload.total_items;
// 		default:
// 			return state;
// 	}
// };

const items = (state: any = [], action: IListEventRequestAction) => {
	switch (action.type) {
		case ListEventRequestActionType.BOOKER_REQUEST_LIST_SUCCESS:
			return action.payload.items.map((item: IItemEventRequest) => ({
				...item,
				itemState: itemBookerRequestReducer(item.itemState, action),
			}));
		case ListEventRequestActionType.LOADMORE_BOOKER_REQUEST_LIST_SUCCESS:
			return state.concat(action.payload.items);
		case ListEventRequestActionType.UPDATED_MODAL_OPEN:
		case ListEventRequestActionType.UPDATED_MODAL_CLOSE:
		case ListEventRequestActionType.UPDATE_BOOKER_REQUEST:
		case ListEventRequestActionType.UPDATE_BOOKER_SUCCESS:
		case ListEventRequestActionType.UPDATE_BOOKER_FAILURE:
		case ListEventRequestActionType.GET_FULL_ITEM_REQUEST:
		case ListEventRequestActionType.GET_FULL_ITEM_SUCCESS:
		case ListEventRequestActionType.GET_FULL_ITEM_FAILURE:
		case ListEventRequestActionType.EVENT_REQUEST_CANCEL_CONFIRM_DIALOG_OPEN:
		case ListEventRequestActionType.EVENT_REQUEST_CANCEL_CONFIRM_DIALOG_CLOSE:
		case ListEventRequestActionType.CANCEL_BOOKER_REQUEST:
		case ListEventRequestActionType.CANCEL_BOOKER_SUCCESS:
		case ListEventRequestActionType.CANCEL_BOOKER_FAILURE:
		case ListEventRequestActionType.CONFIRM_EVENT_REQUEST_CONFIRM_DIALOG_OPEN:
		case ListEventRequestActionType.CONFIRM_EVENT_REQUEST_CONFIRM_DIALOG_CLOSE:
		case ListEventRequestActionType.CONFIRM_EVENT_REQUEST:
		case ListEventRequestActionType.CONFIRM_EVENT_SUCCESS:
		case ListEventRequestActionType.CONFIRM_EVENT_FAILURE:
		case ListEventRequestActionType.EVENT_REQUEST_APPROVE_CONFIRM_DIALOG_OPEN:
		case ListEventRequestActionType.EVENT_REQUEST_APPROVE_CONFIRM_DIALOG_CLOSE:
		case ListEventRequestActionType.APPROVE_EVENT_REQUEST:
		case ListEventRequestActionType.APPROVE_EVENT_SUCCESS:
		case ListEventRequestActionType.APPROVE_EVENT_FAILURE:
		case ListEventRequestActionType.EVENT_REQUEST_REJECT_CONFIRM_DIALOG_OPEN:
		case ListEventRequestActionType.EVENT_REQUEST_REJECT_CONFIRM_DIALOG_CLOSE:
		case ListEventRequestActionType.REJECT_EVENT_REQUEST:
		case ListEventRequestActionType.REJECT_EVENT_SUCCESS:
		case ListEventRequestActionType.REJECT_EVENT_FAILURE:
			// lazem nb3t el id
			return state.map((item: IItemEventRequest) => {
				return item.id === action.payload.id
					? {
							...item,
							itemState: itemBookerRequestReducer(
								item.itemState,
								action,
							),
					  }
					: item;
			});
		default:
			return state;
	}
};

const error = (state = {}, action: IListEventRequestAction) => {
	switch (action.type) {
		case ListEventRequestActionType.BOOKER_REQUEST_LIST_FAILURE:
		case ListEventRequestActionType.LOADMORE_BOOKER_REQUEST_LIST_FAILURE:
			return action.payload;
		default:
			return state;
	}
};

export const listEventRequest = combineReducers({
	isRequested,
	isSuccess,
	loadMoreIsRequested,
	loadMoreIsSuccess,
	bookerRequestPage,
	// total_items: totalItems,
	items,
	error,
});
