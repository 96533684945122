import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import makeStyles from "@material-ui/core/styles/makeStyles";
import createStyles from "@material-ui/core/styles/createStyles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Services from "../../generic/Services";
import IconButton from '@material-ui/core/IconButton';
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCcVisa, faCcMastercard, faCcDiscover, faCcAmex } from "@fortawesome/free-brands-svg-icons"
import { CircularProgress, Radio, RadioGroup } from "@material-ui/core";
import useTheme from "@material-ui/core/styles/useTheme";
import AddCircleIcon from "@material-ui/icons/AddCircle";

const useStyles = makeStyles(() =>
	createStyles({
		margin: {
			margin: "0px",
		},
		pointer: {
			cursor: "pointer",
		}
	}),
);

interface SelectPaymentMethodItemProps {
	items: any;
	toggleDefault: boolean;
	setToggleDefault: any;
	deletePaymentMethod: boolean;
	setDeletePaymentMethod: any;
	disable: boolean;
	setDisable: any;
	count: number;
	setModelType: any;
}

interface ToggleDefaultPaymentResponse {
	status: string;
	server_message: string;
	user_message: string;
}

export const SelectPaymentMethodItem: React.FC<SelectPaymentMethodItemProps> = ({
	items,
	toggleDefault,
	setToggleDefault,
	setDisable,
	setModelType
}) => {
	const classes = useStyles();


	const [makeDefaultLoading, setMakeDefaultLoading] = useState<Number>(0);
	const [accountList, setAccountList] = useState([]);
	const [creditCardList, setCreditCardList] = useState([]);
	const [defaultPaymentMethodId, setDefaultPaymentMethodId] = useState(0);
	const theme = useTheme();
	

	useEffect(() => {
		if (items) {
			if (items.length > 0) {
				let defaultMethod = items.find((item: any) => {
					return item.isDefault == true
				})
				setDefaultPaymentMethodId(defaultMethod ? defaultMethod?.id : 0);

				let acList = items.filter((item: any) => {
					return item.type == 'ACCOUNT'
				});

				setAccountList(acList);

				let crList = items.filter((item: any) => {
					return item.type == 'CARD'
				});

				setCreditCardList(crList);
			}
		}
	}, [setAccountList, items])

	console.log('items: ', items);
	
	const { id } = useParams();

	const changeDefault = (id: number) => {
		setMakeDefaultLoading(id);
		console.log('changeDefault ID: ', id)
			setDisable(true);
			Services.paymentMethods
			.toggleDefaultPaymentMethod(id)
			.then((res: ToggleDefaultPaymentResponse) => {
				setToggleDefault(!toggleDefault);
				setTimeout(() => {
					setMakeDefaultLoading(0);
					setDisable(false);
				}, 500);
			})
			.catch((err: any) => {
				// Failed to fetch
				console.log("error: ", err);
				setTimeout(() => {
					setMakeDefaultLoading(0);
					setDisable(false);
				}, 500);
			});
	};

	return (
		<Box pt={3}>
			<Box pb={0.5} style={{ borderBottom: `1px solid rgba(100, 254, 204, 1)`, fontWeight: 'bold' }}>
				Credit Card
			</Box>
			<Box pb={3} pt={2}>
			<RadioGroup aria-label="gender" name="gender1" value={defaultPaymentMethodId} onChange={(e) => changeDefault(parseInt(e.target.value))}>
				{creditCardList && creditCardList.map((item: any, index: Number) => (
					<div className="radio-circular-div">
						{makeDefaultLoading === item?.id &&  <CircularProgress size={68} className="radio-circular" /> }
						<FormControlLabel className="payment-grid" value={item.id} control={<Radio />} label={
						<Grid container justify="space-between" spacing={1}>
								<Grid item xs={4} md={4} lg={4}>
									<small>
										{item.brand == 'Visa' &&
											<FontAwesomeIcon style={{ marginRight: '10px' }} icon={faCcVisa} size="lg" />
										}
										{item.brand == 'MasterCard' &&
											<FontAwesomeIcon style={{ marginRight: '10px' }} icon={faCcMastercard} size="lg" />
										}
										{item.brand == 'Discover' &&
											<FontAwesomeIcon style={{ marginRight: '10px' }} icon={faCcDiscover} size="lg" />
										}
										{item.brand == 'Amex' &&
											<FontAwesomeIcon style={{ marginRight: '10px' }} icon={faCcAmex} size="lg" />
										}
										Ending in {item.last_4_digits}
									</small>
								</Grid>
								<Grid item xs={4} md={4} lg={4}>
									<small className={classes.margin}>{item.username}</small>
								</Grid>
								<Grid item xs={4} md={4} lg={4}>
									<small className={classes.margin}>Expires {item?.exp_month}/{item?.exp_year?.toString()?.slice(-2)}</small>
								</Grid>
							</Grid>} />
							</div>
				))}
				
			</RadioGroup>

			{creditCardList?.length < 2 && 
				<Box color={theme.palette.secondary.main} onClick={(e) => setModelType('addCard')}>
					<div style={{cursor: 'pointer'}}>
					<IconButton color="inherit" style={{fontSize: '16px', padding: '0px 12px 0px 0px'}}>
						<AddCircleIcon fontSize="inherit" />
					</IconButton>
					<small>Add New Card</small>
					</div>
				</Box>
			}
			
			</Box>
			<Box pb={0.5} style={{ borderBottom: `1px solid rgba(100, 254, 204, 1)`, fontWeight: 'bold' }}>
				Bank Account
			</Box>
			<Box pt={2}>
				<RadioGroup aria-label="gender" name="gender1" value={defaultPaymentMethodId} onChange={(e) => changeDefault(parseInt(e.target.value))}>
					{accountList && accountList.map((item: any, index: Number) => (
						<div className="radio-circular-div">
						{makeDefaultLoading === item?.id &&  <CircularProgress size={68} className="radio-circular" /> }
							<FormControlLabel className="payment-grid"  value={item.id} control={<Radio />} label={
								<Grid container justify="space-between" spacing={1}>
								<Grid item xs={6} md={6} lg={6}>
									<small>
										{item.bank_name}
									</small>
								</Grid>
								<Grid item xs={6} md={6} lg={6}>
									<small>
										Ending in {item.last_4_digits}
									</small>
								</Grid>
							</Grid>} />
							</div>
					))}
				</RadioGroup>
			</Box>
		</Box>

	);
};