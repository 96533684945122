import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import { Section } from "./Section";
import { Notification } from "./Section";
import { listNotifications } from "../../generic/Services/settings";

interface Section {
	id: number;
	title: string;
	description: string;
}

const Sections: Section[] = [
	{ id: 1, title: "General", description: "Imprtant updates regarding payments, events, and contract activity" },
	{ id: 2, title: "Promotional", description: "Alerts on special offers, app features, and exclusive deals." },
	{ id: 3, title: "Support", description: "Notifications regarding open tickets." },
];

export const Notifications = () => {
	const [data, setData] = useState<Notification[]>();
	const [toggleSwitch, setToggleSwitch] = useState<boolean>(false);
	useEffect(() => {
		listNotifications()
			.then((res) => {
				setData(res.items);
			})
			.catch((err) => {
				console.log("error: ", err);
			});
	}, [toggleSwitch]);

	return (
		<Box mb={7} mt={6}>
			<Box mb={4} fontFamily="poppins" fontSize="1.2rem">
				Notifications
			</Box>
			{Sections.map((section: Section) => {
				return (
					<Section
						key={section.id}
						toggleSwitch={toggleSwitch}
						setToggleSwitch={setToggleSwitch}
						title={section.title}
						description={section.description}
						notifications={data?.filter(
							(notification) => notification.name === section.title.toUpperCase(),
						)}
					/>
				);
			})}
		</Box>
	);
};
