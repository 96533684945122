import {
	ListEventRequestActionType,
	IListEventRequestAction,
	IItemConfirmEventRequest,
} from "../../interfaces";
import { generateConfirmReducer } from "../../../ConfirmationDialog/reducer";

const confirmModalOpened = generateConfirmReducer("CONFIRM_EVENT_REQUEST_");

const confirmIsRequested = (state = false, action: IListEventRequestAction) => {
	switch (action.type) {
		case ListEventRequestActionType.CONFIRM_EVENT_REQUEST:
			return true;
		case ListEventRequestActionType.CONFIRM_EVENT_SUCCESS:
		case ListEventRequestActionType.CONFIRM_EVENT_FAILURE:
			return false;
		default:
			return state;
	}
};

const confirmIsSucceeded = (state = false, action: IListEventRequestAction) => {
	switch (action.type) {
		case ListEventRequestActionType.CONFIRM_EVENT_SUCCESS:
			return true;
		case ListEventRequestActionType.CONFIRM_EVENT_REQUEST:
		case ListEventRequestActionType.CONFIRM_EVENT_FAILURE:
			return false;
		default:
			return state;
	}
};

const confirmError = (state = null, action: IListEventRequestAction) => {
	switch (action.type) {
		case ListEventRequestActionType.CONFIRM_EVENT_FAILURE:
			return action.payload;
		case ListEventRequestActionType.CONFIRM_EVENT_REQUEST:
			return null;
		default:
			return state;
	}
};

export const confirmRequestReducer = (
	state: IItemConfirmEventRequest,
	action: IListEventRequestAction,
) => {
	return {
		confirmModalOpened: confirmModalOpened(
			state && state.confirmModalOpened,
			action,
		),
		confirmIsRequested: confirmIsRequested(
			state && state.confirmIsRequested,
			action,
		),
		confirmIsSucceeded: confirmIsSucceeded(
			state && state.confirmIsSucceeded,
			action,
		),
		confirmError: confirmError(state && state.confirmError, action),
	};
};
