import React from "react";
import { Box, TextField, Grid, InputLabel, Button } from "@material-ui/core";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { Formik, FormikProps } from "formik";
import { SettingsState } from "./settingsSlice";
import ErrorMessage from "../../generic/ErrorMessage";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		textAlign: {
			textAlign: "end",
		},
	}),
);

export interface MyFormValues {
	old_password: string;
	new_password: string;
	confirm_password: string;
}

export interface PasswordFormProps {
	validationSchema: any;
	initialValues: any;
	settings: SettingsState;
	handleOnSubmit: (values: {
		old_password: string;
		new_password: string;
	}) => any;
}

export const PasswordForm: React.FC<PasswordFormProps> = ({
	validationSchema,
	initialValues,
	handleOnSubmit,
	settings,
}) => {
	const classes = useStyles();

	return (
		<Box mb={7} mt={6}>
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={async (values, actions) => {
					handleOnSubmit({
						old_password: values.old_password,
						new_password: values.new_password,
					}).then((res: any) => {
						if (res) actions.resetForm();
					});
					actions.setSubmitting(false);
				}}
				render={(formikBag: FormikProps<MyFormValues>) => {
					return (
						<>
							<Box mb={2} fontFamily="poppins" fontSize="1.2rem">
								Password
							</Box>
							<form onSubmit={formikBag.handleSubmit}>
								<Grid
									container
									direction="column"
									alignItems="center"
									spacing={4}
								>
									<Grid
										container
										item
										direction="row"
										alignItems="center"
										spacing={4}
									>
										<Grid item xs={4} md={4} lg={4}>
											<InputLabel
												className={classes.textAlign}
												htmlFor="new_password"
											>
												Old Password
											</InputLabel>
										</Grid>
										<Grid item xs={8} md={5} lg={5}>
											<TextField
												autoComplete="off"
												variant="outlined"
												color="secondary"
												id="old_password"
												name="old_password"
												type="password"
												helperText={
													formikBag.touched
														.old_password
														? formikBag.errors
																.old_password
														: ""
												}
												error={
													formikBag.touched
														.old_password &&
													Boolean(
														formikBag.errors
															.old_password,
													)
												}
												fullWidth={true}
												margin="dense"
												value={
													formikBag.values
														.old_password
												}
												placeholder="Old Password"
												onChange={(e: any) => {
													e.persist();
													formikBag.setFieldValue(
														"old_password",
														e.target.value,
													);
													formikBag.setFieldTouched(
														"old_password",
														true,
														false,
													);
												}}
											/>
										</Grid>
									</Grid>
									<Grid
										container
										item
										direction="row"
										alignItems="center"
										spacing={4}
									>
										<Grid item xs={4} md={4} lg={4}>
											<InputLabel
												className={classes.textAlign}
												htmlFor="new_password"
											>
												New Password
											</InputLabel>
										</Grid>
										<Grid item xs={8} md={5} lg={5}>
											<TextField
												autoComplete="off"
												variant="outlined"
												color="secondary"
												id="new_password"
												name="new_password"
												type="password"
												helperText={
													formikBag.touched
														.new_password
														? formikBag.errors
																.new_password
														: ""
												}
												error={
													formikBag.touched
														.new_password &&
													Boolean(
														formikBag.errors
															.new_password,
													)
												}
												fullWidth={true}
												margin="dense"
												value={
													formikBag.values
														.new_password
												}
												placeholder="New Password"
												onChange={(e: any) => {
													e.persist();
													formikBag.setFieldValue(
														"new_password",
														e.target.value,
													);
													formikBag.setFieldTouched(
														"new_password",
														true,
														false,
													);
												}}
											/>
										</Grid>
									</Grid>
									<Grid
										container
										item
										direction="row"
										alignItems="center"
										spacing={4}
									>
										<Grid item xs={4} md={4} lg={4}>
											<InputLabel
												className={classes.textAlign}
												htmlFor="confirm_password"
											>
												Confirm New Password
											</InputLabel>
										</Grid>
										<Grid item xs={8} md={5} lg={5}>
											<TextField
												autoComplete="off"
												variant="outlined"
												color="secondary"
												id="confirm_password"
												name="confirm_password"
												type="password"
												helperText={
													formikBag.touched
														.confirm_password
														? formikBag.errors
																.confirm_password
														: ""
												}
												error={
													formikBag.touched
														.confirm_password &&
													Boolean(
														formikBag.errors
															.confirm_password,
													)
												}
												fullWidth={true}
												margin="dense"
												value={
													formikBag.values
														.confirm_password
												}
												placeholder="Confirm New Password"
												onChange={(e: any) => {
													e.persist();
													formikBag.setFieldValue(
														"confirm_password",
														e.target.value,
													);
													formikBag.setFieldTouched(
														"confirm_password",
														true,
														false,
													);
												}}
											/>
										</Grid>
									</Grid>
									<Grid
										container
										item
										direction="row"
										alignItems="center"
									>
										<Grid item xs={4} md={4} lg={4}></Grid>
										<Grid item xs={6} md={6} lg={6}>
											{settings.errorPassword &&
												settings.errorPassword
													.message && (
													<ErrorMessage
														error={
															settings.errorPassword
														}
													/>
												)}
										</Grid>
									</Grid>
									<Grid
										container
										item
										direction="row"
										alignItems="center"
										spacing={4}
									>
										<Grid item xs={4} md={4} lg={4}></Grid>
										<Grid item xs={8} md={6} lg={6}>
											<Button
												type="submit"
												size="large"
												variant="contained"
												color="secondary"
												disabled={
													formikBag.isSubmitting
												}
											>
												Change Password
											</Button>
										</Grid>
									</Grid>
								</Grid>
							</form>
						</>
					);
				}}
			/>
		</Box>
	);
};
