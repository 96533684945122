import React, { useEffect, useState } from "react";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import searchBarIcon from "../../Assets/Search Bar Icon.svg";
import { Avatar, Box, Grid, Typography } from "@material-ui/core";

import ImagePlaceholder from "../../Assets/ImagePlaceholder.png";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import moment from "moment";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        unread: {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText,
            "font-weight": "bold",
            borderRadius: "3px",
            fontSize: "13px",
            display: "inline-block",
            padding: "5px 15px"
        },
        message: {
            "word-wrap": "break-word",
            width: "227px",
        },
        BOOKER: {
			border: `5px solid ${theme.palette.primary.main}`,
			width: 70,
			height: 70,
			margin: "auto",
		},
		TALENT: {
			border: `5px solid ${theme.palette.secondary.main}`,
			width: 70,
			height: 70,
			margin: "auto",
		},
		AGENT: {
			border: `5px solid #ffffff`,
			width: 70,
			height: 70,
			margin: "auto",
		},
		ADMIN: {
			width: 70,
			height: 70,
			margin: "auto",
        },
        notificationListGrid: {
            paddingTop: '10px',
            marginTop: '15px',
            borderTop: `1px solid rgb(91, 255, 203)`,
            cursor: 'pointer'
        }
    })
);

const ListNotificaions: React.FC = (setLoc) => {
    const classes = useStyles();
    const history = useHistory();
    const searchbuttonRef = React.createRef<any>();

    const [text, setText] = useState<string>();
    const [notificationList, setNotificationList] = useState<any>();

    const handleKeyDown = (e: any) => {
        if (e.key === "Enter" && searchbuttonRef.current) {
            searchbuttonRef.current.focus();
        }
    };

    const search = (e: any) => {
        if (e.key === "Enter" && searchbuttonRef.current) {
            searchbuttonRef.current.focus();
        }
    };

    const notificationClick = (item: any) => {  
        switch (item.data.type) {
            case 'ACCEPTED_BY_AGENT':
            case 'ACCEPTED_BY_TALENT':
                history.push(`/account/edit-band/${item.data.id}`);
                break;
                case 'PROFILE_APPROVED':
                    history.push(`/account/edit-profile`);
                    break;
                case 'SENT_BY_AGENT':
                case 'SENT_BY_TALENT':
                case 'REJECTED_BY_AGENT':
                case 'REJECTED_BY_TALENT':
                case 'REMOVED_BY_AGENT':
                case 'REMOVED_BY_TALENT':
                    break;
                default:
                    history.push({
                        pathname: `/account/active-event/band/${item.data.band_id}`,
                        state: { new: true, requestId: item.data.id },
                    })
                    break;
        }
    }
    useEffect(() => {
        const storageItem = localStorage.getItem("Notification");
        //setNotificationList([]);
        if(storageItem)
        {
            let storageItemData = JSON.parse(storageItem);

            setNotificationList(storageItemData.notifications);
            console.log('storageItem: ', storageItemData.notifications);
        }
        
    }, [setNotificationList])
    return (
        <>
            <OutlinedInput
                labelWidth={0}
                placeholder="filter events/requests"
                onChange={(e: any) => setText(e.target.value)}
                onKeyDown={handleKeyDown}
                value={text}
                margin="dense"
                style={{marginBottom: '20px'}}
                endAdornment={
                    <IconButton
                        color="primary"
                        size="medium"
                        aria-label="Search"
                        onClick={() => search(text)}
                        ref={searchbuttonRef}>
                        <Icon fontSize="large">
                            <img alt="search icon" src={searchBarIcon} />
                        </Icon>
                    </IconButton>
                }
            />
            {notificationList && notificationList.map((item: any, index: number) => (
            <Grid key={index} container alignItems="center" justify="space-between" spacing={1} onClick={() => { notificationClick(item) }} className={classes.notificationListGrid}>
                <Grid item xs={3} md={2} lg={2} >
                    <Avatar
                        alt="Band or Booker thumbnail"
                        // src={ImagePlaceholder}
                        className={item.data.role == 'AGENT' ? classes.AGENT : classes.TALENT}
                        src={item.data?.photo || ImagePlaceholder}
                    />

                </Grid>
                <Grid item xs={9} md={10} lg={10}>
                    <Typography>{item.message} <small style={{float:'right'}}>{moment(item.timestamp).fromNow()}</small></Typography>
                </Grid>
                
            </Grid>
            )
            )}
        </>
    );
}

export default ListNotificaions;