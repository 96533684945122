import React, { useEffect, useState } from "react";
import { Grid, Container, Link, Box, Typography } from "@material-ui/core";
import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";
import { ReactComponent as Soundcloud } from "../Assets/soundcloud.svg";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { ReactComponent as SpotifySVG } from "../Assets/spotify.svg";
import { Facebook, Instagram, YouTube } from "@material-ui/icons";
import ImagePlaceholder from "../Assets/ImagePlaceholder.png";
import { ReactComponent as Play } from "../Assets/Play.svg";
import { RouteComponentProps, useLocation } from "react-router-dom";
import ErrorMessage from "../generic/ErrorMessage";
import BandEventRequest from "./BandRequest";
import { BandDetailsPageState } from "./bandDetailsSlice";
import BookmarkSharpIcon from "@material-ui/icons/BookmarkSharp";
import BookmarkBorderSharpIcon from "@material-ui/icons/BookmarkBorderSharp";
import BandReview from "./BandReview";
import { UsaMap } from "./UsaMap";
import Button from "@material-ui/core/Button";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import YoutubeEmbed from '../generic/components/YoutubeEmbed/YoutubeEmbed';
import SpotifyEmbed from '../generic/components/SpotifyEmbed/SpotifyEmbed';
import ReactPlayer from "react-player";
import { MetadataTags } from '../generic/components/MetadataTags/metadata-tags'
import { RedirectAs404 } from "../generic/Routes/404Route";

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import { getBandPhotoGalleryImages } from "../generic/Services/bands";
import useCheckMobileScreen from "../generic/components/CheckIsMobileScreen/CheckMobileScreen";
import { IsAnonymous, IsBooker, IsLoggedIn } from "../AuthBase";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		Link: {
			fontSize: "16px",
			color: "#DDD",
		},
		featuredSong: {
			fontWeight: "bold",
			fontSize: "17px",
			letterSpacing: "1px",
		},
		h4: {
			margin: "0px",
		},
		h3: {
			color: "white",
			"text-shadow": "0px 0px 0px grey, 0 0 10px #4F4F4F, 0 0 0px grey",
		},
		caption: {
			color: "white",
			"text-shadow": "0px 0px 0px grey, 0 0 10px #4F4F4F, 0 0 0px grey",
		},
		p: {
			marginTop: "0px",
			color: "#ddd",
			wordBreak: "break-word",
		},
		link: {
			cursor: "pointer",
		},
		favoriteIcon: {
			position: "absolute",
			top: "-30px",
			right: "80px",
			cursor: "pointer",
		},
		featuredSongsItem: {
			marginBottom: '5px',
			border: '2px solid rgba(255,255,255,0.7)',
		},
		photoGalleryView: {
			display: 'grid',
			flexWrap: 'wrap',
			alignItems: 'stretch',
			justifyContent: 'flex-start',
			gridTemplateColumns: '2fr 1fr 1fr',
			columnGap: '20px',
			rowGap: '20px',
			marginBottom: '20px',
			"@media screen and (max-width: 900px)": {
				display: 'block',
				gridTemplateColumns: '1fr',
			}
		},
		photoGalleryItems: {
			verticalAlign: 'middle',
			position: 'relative',
			height: '100%',
			minHeight: '176px',
			'&:first-child': {
				gridRow: 'span 2',
				height: '23.3rem',
			},
			"@media screen and (min-width: 1921px)": {
				'&:first-child': {
					height: '36rem',
				}
			},
			"@media screen and (max-width: 900px)": {
				marginBottom: '10px',
				'&:first-child': {
					gridRow: 'span 1',
				}
			}
		},
		photoGalleryItemsImg: {
			width: '100%',
			cursor: 'pointer',
			height: '100%',
			verticalAlign: 'middle',
			position: 'absolute',
			objectFit: 'cover',
			objectPosition: 'center',
			"@media screen and (max-width: 900px)": {
				width: '100%',
			}
		},
		photoGalleryViewOverlay: {
			position: 'absolute',
			left: '0px',
			top: '0px',
			padding: '20px',
			width: '100%',
			height: '100%',
			background: 'rgba(8,5,34,0.5)',
			textAlign: 'center',
			display: 'flex',
			flexWrap: 'wrap',
			alignItems: 'center',
			justifyContent: 'center',
			fontSize: '1rem',
			cursor: 'pointer',
			"@media screen and (max-width: 900px)": {
				background: '#64FECC',
				position: 'relative',
				color: '#0F24FD',
				fontSize: '0.7rem',
				padding: '7px 15px',
				justifyContent: 'space-between',
				height: 'auto',
			}
		},
		currentNumberOfImages: {
			fontWeight: 'bold',
		},
		photoGalleryCouDesktop: {
			"@media screen and (max-width: 900px)": {
				display: 'none',
			},
		},
		photoGalleryCouMobile: {
			"@media screen and (min-width: 901px)": {
				display: 'none',
			},
		},
		photoGalleryItemsArrow: {
			"@media screen and (min-width: 901px)": {
				display: 'none',
			},
		},
		reviewsDesktop: {
			"@media screen and (max-width: 900px)": {
				display: 'none',
			},
		},
		reviewsMobile: {
			"@media screen and (min-width: 901px)": {
				display: 'none',
			}
		}
	}),
);

export interface BandPageProps {
	authenticated: any;
	bandDetailsSlice: BandDetailsPageState;
	getBandById: (bandId: number, authenticated: boolean) => any;
	getBandByUsername: (username: string, authenticated: boolean) => any;
	favoriteToggle: (bandId: number, isFavorite: boolean) => void;
	setPage: (page: string) => void;
	openSignupModal: () => void;
	isUserApproved: boolean;
	handleOpen: () => void;
	handleOpenWarningModel: () => void;
}

export const BandPage: React.FC<RouteComponentProps<{ id: string }> & BandPageProps> = ({
	match,
	bandDetailsSlice,
	getBandById,
	getBandByUsername,
	authenticated,
	favoriteToggle,
	setPage,
	openSignupModal,
	isUserApproved,
	handleOpenWarningModel,
	handleOpen
}) => {

	const [photoIndex, setPhotoIndex] = useState(0);
	const [photoGalleryImages, setPhotoGalleryImages] = useState([""]);
	const [isOpen, setIsOpen] = useState(false);

	const isMobileScreen = useCheckMobileScreen();
	const location = useLocation();
	const { id } = match.params;
	useEffect(() => {
		if (location?.pathname?.includes('/bands/')) {
			getBandById(Number(id), authenticated.isLoggedIn);
		}
		else {
			getBandByUsername(id, authenticated.isLoggedIn);
		}
		getBandPhotoGallery();
		setPage("band_details");
		window.scrollTo(0, 0)
	}, [authenticated.isLoggedIn, getBandById, getBandByUsername, id, setPage]);



	const getBandPhotoGallery = () => {

	}
	const classes = useStyles();

	const theme = useTheme();

	const matches = useMediaQuery(theme.breakpoints.up("sm"));

	const { bandDetails } = bandDetailsSlice;
	const [isBioExpanded, setBioExpanded] = useState(false);

	useEffect(() => {
		let displayGalleyArr = [];
		if (bandDetails?.item?.photo?.url) {
			displayGalleyArr.push(bandDetails?.item?.photo?.url);
		}
		if (bandDetails?.item?.band_gallery && bandDetails?.item?.band_gallery?.length > 0) {
			let bGalleyArr = bandDetails?.item?.band_gallery;

			bGalleyArr.forEach((element: any) => {
				displayGalleyArr.push(element?.image_path);
			});
		}

		setPhotoGalleryImages(displayGalleyArr);

	}, [bandDetails, isMobileScreen]);

	const getBandBio = () => {
		if (isBioExpanded) {
			return bandDetails && bandDetails.item.bio;
		} else {
			if (bandDetails && bandDetails?.item?.bio?.length > 700) {
				return bandDetails && bandDetails.item.bio.substring(0, 700) + "...";
			}
			else {
				return bandDetails && bandDetails.item.bio;
			}
		}
	};

	const getFeaturedSongs = () => {
		if (bandDetails && bandDetails.item) {
			var featured_songs = bandDetails.item.featured_songs;
			return (
				<Grid container alignItems="center">
					<Grid item lg={12} xs={12}>
						<h1 style={{ marginTop: '0px', }}>Featured Songs</h1>
					</Grid>
					{featured_songs.map(song =>
						<Grid container alignItems="center" spacing={0} key={song.id} className={classes.featuredSongsItem}>
							{/* <Grid item>
								<Play
									className={classes.link}
									width="60px"
									height="70px"
									onClick={() => {window.open(song.url,'_blank');}}
								/>
							</Grid>
							<Grid item>
								<Link
									className={classes.Link}
									href={song.url}
									target="_blank">
									{song.name}
								</Link>
							</Grid> */}
							{song.url.includes("youtube") && <YoutubeEmbed embedId={song.url.substring(song.url.indexOf("?v=") + 3)} />}
							{song.url.includes("youtu.be") && <YoutubeEmbed embedId={song.url.substring(song.url.indexOf(".be/") + 4)} />}
							{song.url.includes("spotify") && <SpotifyEmbed embedId={song.url.substring(song.url.indexOf("/track/") + 7)} />}
							{song.url.includes("soundcloud") && <ReactPlayer url={song.url} />}
							{!(song.url.includes("youtube") || song.url.includes("spotify") || song.url.includes("soundcloud") || song.url.includes("youtu.be")) &&
								<>
									<Grid item>
										<Play
											className={classes.link}
											width="60px"
											height="70px"
											onClick={() => { window.open(song.url, '_blank'); }}
										/>
									</Grid>
									<Grid item>
										<Link
											className={classes.Link}
											href={song.url}
											target="_blank">
											{song.name}
										</Link>
									</Grid>
								</>
							}
						</Grid>
					)}
					{featured_songs.length === 0 && <h2>No featured songs</h2>}
				</Grid>
			)
		}
	};

	return (
		<Box>
			{bandDetailsSlice.bandDetailsSucceeded ? (
				<Container maxWidth="lg">
					<MetadataTags
						title={bandDetails?.item?.meta_title ? bandDetails?.item?.meta_title : bandDetails?.item.name}
						description={bandDetails?.item?.meta_description ? bandDetails?.item?.meta_description : bandDetails?.item.bio}
						image={bandDetails?.item?.meta_image ? bandDetails?.item?.meta_image_path : bandDetails?.item.photo?.url}
					/>
					<Grid container direction="row" spacing={matches ? 10 : 5} justify="center">
						{/* header photo */}
						<Grid item lg={12} md={12} xs={12}>
							<Box
								overflow="hidden"
								position="relative"
								display="flex"
								flexDirection="column"
								alignItems="flex-start"
								justifyContent="flex-end"
								pl={4}
								pb={6}
								height="500px"
								style={{
									backgroundImage: `url(${(bandDetails &&
										bandDetails.item.header_photo &&
										bandDetails.item.header_photo.url) ||
										ImagePlaceholder})`,
									backgroundRepeat: "no-repeat",
									backgroundSize: "cover",
									backgroundPosition: "center",
								}}>
								<Typography variant="h3" className={classes.h3}>{bandDetails && bandDetails.item.name}</Typography>
								<Typography variant="caption" className={classes.caption}>
									{bandDetails &&
										bandDetails.item.genres &&
										bandDetails.item.genres.map((genre: any, index: number, array: any) =>
											index === array.length - 1 ? genre.title : genre.title.concat(", "),
										)}
								</Typography>
								{((authenticated.isLoggedIn && authenticated.user.role === "BOOKER") || authenticated.isLoggedIn === false) && (
									<BandEventRequest band={bandDetails && bandDetails.item} />
								)}
								{authenticated.isLoggedIn &&
									authenticated.user.role === "BOOKER" &&
									bandDetails &&
									(bandDetails.item.isFavorite ? (
										<BookmarkSharpIcon
											color="secondary"
											fontSize="large"
											style={{ fontSize: 140 }}
											className={classes.favoriteIcon}
											onClick={() =>
												favoriteToggle(Number(bandDetails.item.id), bandDetails.item.isFavorite)
											}
										/>
									) : (
										<BookmarkBorderSharpIcon
											color="secondary"
											fontSize="large"
											style={{ fontSize: 140 }}
											className={classes.favoriteIcon}
											onClick={() =>
												favoriteToggle(Number(bandDetails.item.id), bandDetails.item.isFavorite)
											}
										/>
									))}
							</Box>
						</Grid>
						{/* rest of the page */}
						<Grid item xs={12} md={8} lg={8}>
							<Grid className={classes.photoGalleryView}>
								{photoGalleryImages.map((imgData, indx) => {
									if (isMobileScreen) {
										if (indx == 0) {
											return <div className={classes.photoGalleryItems}>
												<img src={imgData} onClick={(e) => {
													setPhotoIndex(indx);
													setIsOpen(true);
												}} alt="icon" className={classes.photoGalleryItemsImg} />
												<div onClick={(e) => {
													setPhotoIndex(indx);
													setIsOpen(true);
												}} className={indx == 0 ? classes.photoGalleryViewOverlay : ""}>
													{indx == 0 &&
														<>
															<h1 style={{ margin: '0px', }}>
																<span className={classes.photoGalleryCouMobile}>Photo Gallery ({photoGalleryImages?.length})</span>
															</h1>
															<ArrowForwardIcon className={classes.photoGalleryItemsArrow} />
														</>
													}

												</div>
											</div>
										}
									}
									else {
										if (indx <= 4) {
											console.log('indx: ', indx)
											return <div className={classes.photoGalleryItems}>
												<img src={imgData} onClick={(e) => {
													setPhotoIndex(indx);
													setIsOpen(true);
												}} alt="icon" className={classes.photoGalleryItemsImg} />
												<div onClick={(e) => {
													setPhotoIndex(indx);
													setIsOpen(true);
												}} className={(indx == 4 && photoGalleryImages?.length > 5) ? classes.photoGalleryViewOverlay : ""}>
													{indx == 4 && photoGalleryImages?.length > 5 &&
														<>
															<h1 style={{ margin: '0px', }}>
																<span className={classes.photoGalleryCouDesktop}>+ {photoGalleryImages?.length - 5}</span>
															</h1>
															<ArrowForwardIcon className={classes.photoGalleryItemsArrow} />
														</>
													}

												</div>
											</div>
										}
									}


								})
								}
							</Grid>
							<Grid
								container
								direction="row"
								justify="space-between"
								alignItems="flex-start"
								spacing={matches ? 10 : 0}>
								<Grid item lg={12} md={12} xs={12}>
									<Grid item xs={12}>
										<h1>About the Artist</h1>
									</Grid>
									<Grid item xs={12}>
										<p className={classes.p} style={{ whiteSpace: "pre-line" }}>
											{getBandBio()}{" "}
										</p>
										{bandDetails && bandDetails?.item?.bio?.length > 700 &&
											<Button
												style={{ padding: 0 }}
												onClick={() => setBioExpanded(!isBioExpanded)}>
												{isBioExpanded ? "Show Less" : "Show More"}
											</Button>
										}

									</Grid>
								</Grid>

								{/* <Box
										height="400px"
										style={{
											backgroundImage: `url(${(bandDetails &&
												bandDetails.item.photo &&
												bandDetails.item.photo.url) ||
												ImagePlaceholder})`,
											backgroundRepeat: "no-repeat",
											backgroundSize: "cover",
											backgroundPosition: "center",
										}}
									/> */}
								<Grid item lg={12} md={12} xs={12} className={classes.reviewsDesktop}>
									<BandReview bandId={Number(bandDetails?.item?.id)} />
								</Grid>
							</Grid>
						</Grid>
						<Grid item md={4} lg={4} xs={12}>
							<Grid container direction="column" alignItems="flex-start" spacing={1}>

								{getFeaturedSongs()}

								<Grid item xs={12}>
									<h1>Details</h1>
								</Grid>
								<Grid item xs={12}>
									<Typography variant="h5">Current Location</Typography>
									<p className={classes.p}>{bandDetails && bandDetails.item.location}</p>
								</Grid>
								{((authenticated.isLoggedIn && authenticated.user.status === "APPROVED" && authenticated.user.role === "BOOKER") ||
									(authenticated.isLoggedIn && authenticated.user.status === "APPROVED" && authenticated.user.role === "TALENT" && authenticated.user.id === bandDetails?.item.created_by.id)) && (
										<>
											{bandDetails && bandDetails.item.price_show ? <Grid item xs={12}>
												<Typography variant="h5">Price Range</Typography>
												<p className={classes.p}>
													${bandDetails && bandDetails.item.price_from} -{" "}
													{bandDetails && bandDetails.item.price_to}
												</p>
											</Grid> : <Grid item xs={12}><Typography variant="h5">Price Range</Typography>
												<p className={classes.p}>
													Inquire for Pricing
												</p></Grid>}
											{/* <Grid item xs={12}>
											<Typography variant="h5">Deposit Percentage</Typography>
											<p className={classes.p}>
												{bandDetails && bandDetails.item.deposit}%
											</p>
										</Grid> */}
										</>
									)}
								<Grid item xs={12}>
									<Typography variant="h5">Preferred Gigs</Typography>
									<p className={classes.p}>
										{bandDetails &&
											bandDetails.item.preferred_gig_types
												.map((gig: any) => gig.title)
												.join(", ")}
									</p>
								</Grid>
								<Grid item xs={12}>
									<Grid container spacing={1}>
										{bandDetails && bandDetails.item.social_links.map(link => {
											if (link.type === "SPOTIFY") {
												return (
													<Grid item key={link.id}>
														<a
															href={link.url}
															rel="noopener noreferrer"
															target="_blank">
															<SpotifySVG
																className={classes.link}
																color="secondary"
																width="1.25rem"
																fill="#64FECC"
															/>
														</a>
													</Grid>
												)
											} else if (link.type === "SOUNDCLOUD") {
												return (
													<Grid item>
														<a href={link.url} rel="noopener noreferrer" target="_blank">
															<Soundcloud
																className={classes.link}
																color="secondary"
																width="1.5rem"
																fill="#64FECC"
															/>
														</a>
													</Grid>
												)
											} else if (link.type === "YOUTUBE") {
												return (
													<Grid item>
														<a href={link.url} rel="noopener noreferrer" target="_blank">
															<YouTube className={classes.link} color="secondary" />
														</a>
													</Grid>
												)
											} else if (link.type === "FACEBOOK") {
												return (
													<Grid item key={link.id}>
														<a href={link.url} rel="noopener noreferrer" target="_blank">
															<Facebook className={classes.link} color="secondary" />
														</a>
													</Grid>
												)
											} else if (link.type === "INSTAGRAM") {
												return (
													<Grid item key={link.id}>
														<a href={link.url} rel="noopener noreferrer" target="_blank">
															<Instagram className={classes.link} color="secondary" />
														</a>
													</Grid>
												)
											} else {
												return <></>;
											}
										})}
									</Grid>
								</Grid>
								<Grid item>
									<Box mt={3}>
										<h1>Traveling and Touring</h1>
										<p className={classes.p}>
											Current states {bandDetails?.item.name} is willing to travel to.
										</p>
										<UsaMap
											willingToTravel={
												bandDetails &&
												bandDetails.item.willing_to_travel.map((state) => state.title)
											}
										/>
									</Box>
								</Grid>
								<Grid item xs={12} className={classes.reviewsMobile}>
									<BandReview bandId={Number(bandDetails?.item?.id)} />
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12} style={{ textAlign: 'center' }}>
							<IsAnonymous>
								<Button size="large" variant="contained" color="secondary" onClick={openSignupModal}>
									Sign Up to Request Artist
								</Button>
							</IsAnonymous>
							<IsLoggedIn>
								<IsBooker>
								<Button size="large" variant="contained" color="secondary" onClick={isUserApproved ? handleOpen : handleOpenWarningModel}>
									Send Booking Request
								</Button>
								</IsBooker>
							</IsLoggedIn>
						</Grid>
					</Grid>
					{bandDetailsSlice.bandDetailsFailure && bandDetailsSlice.error.message && (
						<ErrorMessage error={bandDetailsSlice.error} />
					)}
				</Container>
			) : (

				<span>
					{!bandDetailsSlice.bandDetailsSucceeded && bandDetailsSlice.bandDetailsFailure &&
						<span>
							PAGE NOT FOUND
							<RedirectAs404 />
						</span>
					}
				</span>
			)}

			{isOpen && (
				<Lightbox
					imageCaption={<span>
						<span className={classes.currentNumberOfImages}>{photoIndex + 1}</span> / <span>{photoGalleryImages.length}</span>

					</span>}
					animationOnKeyInput={true}
					clickOutsideToClose={false}
					enableZoom={false}
					mainSrc={photoGalleryImages[photoIndex]}
					nextSrc={photoGalleryImages[(photoIndex + 1) % photoGalleryImages.length]}
					prevSrc={photoGalleryImages[(photoIndex + photoGalleryImages.length - 1) % photoGalleryImages.length]}
					onCloseRequest={() => setIsOpen(false)}
					onMovePrevRequest={() =>

						setPhotoIndex((photoIndex + photoGalleryImages.length - 1) % photoGalleryImages.length)
					}
					onMoveNextRequest={() =>
						setPhotoIndex((photoIndex + 1) % photoGalleryImages.length)
					}
				/>
			)}
		</Box>
	);
};
