import {
	ListEventRequestActionType,
	IListEventRequestAction,
	IItemCancelEventRequest,
} from "../../interfaces";
import { generateConfirmReducer } from "../../../ConfirmationDialog/reducer";

const cancelModalOpened = generateConfirmReducer("EVENT_REQUEST_CANCEL_");

const cancelIsRequested = (state = false, action: IListEventRequestAction) => {
	switch (action.type) {
		case ListEventRequestActionType.CANCEL_BOOKER_REQUEST:
			return true;
		case ListEventRequestActionType.CANCEL_BOOKER_SUCCESS:
		case ListEventRequestActionType.CANCEL_BOOKER_FAILURE:
			return false;
		default:
			return state;
	}
};

const cancelIsSucceeded = (state = false, action: IListEventRequestAction) => {
	switch (action.type) {
		case ListEventRequestActionType.CANCEL_BOOKER_SUCCESS:
			return true;
		case ListEventRequestActionType.CANCEL_BOOKER_REQUEST:
		case ListEventRequestActionType.CANCEL_BOOKER_FAILURE:
			return false;
		default:
			return state;
	}
};

const cancelError = (state = null, action: IListEventRequestAction) => {
	switch (action.type) {
		case ListEventRequestActionType.CANCEL_BOOKER_FAILURE:
			return action.payload;
		case ListEventRequestActionType.CANCEL_BOOKER_REQUEST:
			return null;
		default:
			return state;
	}
};

export const cancelRequestReducer = (
	state: IItemCancelEventRequest,
	action: IListEventRequestAction,
) => {
	return {
		cancelModalOpened: cancelModalOpened(
			state && state.cancelModalOpened,
			action,
		),
		cancelIsRequested: cancelIsRequested(
			state && state.cancelIsRequested,
			action,
		),
		cancelIsSucceeded: cancelIsSucceeded(
			state && state.cancelIsSucceeded,
			action,
		),
		cancelError: cancelError(state && state.cancelError, action),
	};
};
