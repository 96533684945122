import React from "react";
import { Link, Button, Grid, Box } from "@material-ui/core";
import DayPicker from "react-day-picker";
import moment from "moment";
import "./style.css";
const DateUtils = require("react-day-picker/lib/src/DateUtils");

interface IDatesFilterProps {
	values: [];
	saveDates: (dates: any) => void;
	handlePopoverClose: (e: any) => void;
}

class DatesFilter extends React.Component<IDatesFilterProps> {
	state = {
		selectedDays: [] as any,
	};

	constructor(props: any) {
		super(props);
		this.handleDayClick = this.handleDayClick.bind(this);
	}

	componentDidMount() {
		const { values } = this.props;
		const formatedValues = values
			? values.map((item: { day: string }) => {
					const formattedDate = moment(item.day, "YYYY-MM-DD");
					return new Date(
						formattedDate.year(),
						formattedDate.month(),
						formattedDate.date(),
					);
			  })
			: [];

		this.setState({ selectedDays: formatedValues });
	}

	handleClearDates = () => {
		this.setState({ selectedDays: [] });
	};

	handleDayClick(day: any, { selected }: any) {
		const { selectedDays } = this.state;
		let temp = selectedDays;
		if (DateUtils.isFutureDay(day)) {
			if (selected) {
				const selectedIndex = temp.findIndex((selectedDay: any) =>
					DateUtils.isSameDay(selectedDay, day),
				);
				temp.splice(selectedIndex, 1);
			} else if (selectedDays.length < 14) {
				temp.push(day);
			}
			this.setState({ selectedDays: temp });
		}
	}

	handleSubmit = (e: any) => {
		this.props.saveDates(this.state.selectedDays);
		this.props.handlePopoverClose(e);
	};

	render() {
		const { selectedDays } = this.state;
		const today = new Date();
		return (
			<div>
				<DayPicker
					disabledDays={{ before: today }}
					numberOfMonths={1}
					selectedDays={selectedDays}
					onDayClick={this.handleDayClick}
				/>
				<Box ml={3} mb="12px" fontSize="14px" fontStyle="italic">
					You can choose up to 14 days
				</Box>
				<Grid
					container
					direction="row"
					justify="flex-end"
					alignItems="center"
					spacing={2}
				>
					<Grid item>
						<Link
							color="textPrimary"
							component="button"
							onClick={this.handleClearDates}
						>
							Clear Filters
						</Link>
					</Grid>
					<Grid item>
						<Button
							size="small"
							variant="contained"
							color="secondary"
							onClick={this.handleSubmit}
						>
							Apply
						</Button>
					</Grid>
				</Grid>
			</div>
		);
	}
}

export default DatesFilter;
