import { connect } from "react-redux";
import { TalentsAgent } from "./TalentsAgent";
import { IAppState } from "../../generic/interfaces";
import { ThunkDispatch } from "redux-thunk";
import { countTalentsAgent } from "./TalentsAgentSlice";

const mapStateToProps = (state: IAppState) => ({
	countTalentsAgent: state.countTalentsAgent,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
	listTalentsAgent: () => {
		dispatch(countTalentsAgent());
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(TalentsAgent);
