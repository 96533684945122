import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Switch from "@material-ui/core/Switch";
import makeStyles from "@material-ui/core/styles/makeStyles";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import createStyles from "@material-ui/core/styles/createStyles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { PaymentMethodItem } from "./BillingInformation";
import Services from "../../generic/Services";
import { DeleteDialog } from "./DeleteDialog";
import IconButton from '@material-ui/core/IconButton';
import { VerificationDialog } from "./VerificationDialog";
import { useParams } from "react-router-dom";

const useStyles = makeStyles(() =>
	createStyles({
		margin: {
			margin: "0px",
		},
		pointer: {
			cursor: "pointer",
		},
	}),
);

interface PaymentMethodListItemProps {
	item: PaymentMethodItem;
	toggleDefault: boolean;
	setToggleDefault: any;
	deletePaymentMethod: boolean;
	setDeletePaymentMethod: any;
	disable: boolean;
	setDisable: any;
	count: number;
}

interface ToggleDefaultPaymentResponse {
	status: string;
	server_message: string;
	user_message: string;
}

export const PaymentMethodListItem: React.FC<PaymentMethodListItemProps> = ({
	item,
	toggleDefault,
	setToggleDefault,
	deletePaymentMethod,
	setDeletePaymentMethod,
	disable,
	setDisable,
	count,
}) => {
	const classes = useStyles();

	//  To handle the delete dialog's Open state
	const [open, setOpen] = useState<boolean>(false);
	const [verifyOpen, setVerifyOpen] = useState<boolean>(false);
	const [verifyStatus, setVerifyStatus] = useState("");

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleVerifyOpen = () => {
		setVerifyOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleVerifyClose = (status:string) => {
		setVerifyOpen(false);
		setVerifyStatus(status);
	}

	const handleVerifyCancel = () => {
		setVerifyOpen(false);
	}


	const { id }  = useParams();
	const bandId = id;
	const deletePayment = (id: number) => {

		if(!bandId)
		{
			Services.paymentMethods
			.deletePaymentMethod(id)
			.then((res) => {
				setDeletePaymentMethod(!deletePaymentMethod);
				handleClose();
			})
			.catch((err) => {
				//  Failed to delete
				console.log("error: ", err);
			});
		}
		else
		{
			Services.paymentMethods
			.deleteBandPaymentMethodByAgent(id, bandId)
			.then((res) => {
				setDeletePaymentMethod(!deletePaymentMethod);
				handleClose();
			})
			.catch((err) => {
				//  Failed to delete
				console.log("error: ", err);
			});
		}
		
	};

	const changeDefault = (id: number) => {
		if (!item.isDefault) {
			setDisable(true);
			Services.paymentMethods
			.toggleDefaultPaymentMethod(id)
			.then((res: ToggleDefaultPaymentResponse) => {
				setToggleDefault(!toggleDefault);
				setTimeout(() => {
					setDisable(false);
				}, 1000);
			})
			.catch((err: any) => {
				// Failed to fetch
				console.log("error: ", err);
				setTimeout(() => {
					setDisable(false);
				}, 1000);
			});
		}
	};

	return (
		<Box pb={3} pt={3} borderBottom={`1px solid rgba(100, 254, 204, 0.5)`}>
			<Grid container justify="space-between" spacing={1}>
				<Grid item>
					<p className={classes.margin}>{item.username}</p>
				</Grid>
				{item.type === 'ACCOUNT' &&
					<Grid item xs={6} md={8} lg={8}>
						<Box display="flex" justifyContent="space-between">
							<p className={classes.margin} style={item.isVerified === '1' ? {color: '#64FECC'} : {color: 'red'}}><strong>{item.isVerified === "1" ? 'VERIFIED' : "NOT VERIFIED"}</strong></p>
						</Box>
					</Grid>
				}
				<Grid item>
					<FormControlLabel
						value={item.isDefault}
						control={
							<>
								<Switch
									color="secondary"
									checked={item.isDefault}
									disabled={disable}
									onChange={() => {
										changeDefault(item.id);
									}}
								/>
							</>
						}
						label="Make Default"
						labelPlacement="start"
					/>
				</Grid>
			</Grid>
			<Grid container spacing={1}>
				<Grid item xs={6} md={4} lg={4}>
					<p className={classes.margin}>{item.address.street}</p>
				</Grid>
				<Grid item xs={6} md={8} lg={8}>
					<p className={classes.margin}>
						{item.type === "ACCOUNT" ? `${item.bank_name} Account` : item.brand}
					</p>
				</Grid>
				<Grid item xs={6} md={4} lg={4}>
					<p className={classes.margin}>
						{item.address.city}, {item.address.state.title} {item.address.zip}
					</p>
				</Grid>
				<Grid item xs={6} md={8} lg={8}>
					<Box display="flex" justifyContent="space-between">
						<p className={classes.margin}>Ends with {item.last_4_digits}</p>
						<IconButton onClick={handleClickOpen} color="inherit" disabled={count === 1 || item.isDefault} className={classes.pointer}>
							<DeleteForeverIcon />
						</IconButton>
					</Box>
				</Grid>
				{item.type === 'ACCOUNT' && item.isVerified !== '1' &&
					<Grid item xs={6} md={8} lg={8}>
						<Grid item>
							<Button
								onClick={handleVerifyOpen}
								type="submit"
								variant="contained"
								color="secondary"
								size="large">
								Verify Now
							</Button>
							
						</Grid>
						<Grid item>
							<a>{ verifyStatus }</a>
						</Grid>
					</Grid>
				}
			</Grid>
			{/* the delete dialog */}
			<DeleteDialog open={open} handleClose={handleClose} deletePayment={deletePayment} id={item.id} />
			<VerificationDialog verifyOpen={verifyOpen} handleClose={handleVerifyClose} handleCancel={handleVerifyCancel} id={item.id}/>
		</Box>
	);
};
