import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import { BookerEventItem } from "../BookerEventItem/BookerEventItem";
import { RouteComponentProps } from "react-router-dom";
import { ListBookerEventState } from "./listBookerEventsSlice";
import { EventHistory } from "../EventHistory";
import { SearchTextField } from "../../../SearchPage/FilterBar/SearchTextField";
import Button from "@material-ui/core/Button";
import ReviewTalent from "../../TalentEvent/ReviewTalent";

export interface ListBookerEventProps {
	listBookerEvent: ListBookerEventState;
	listBookerEventsList: (textFilter: string) => void;
	deleteBookerEvent: (eventID: number) => void;
	listEventHistory: (id: number) => void;
	setLoc: (location: string) => void;
	setTextFilter: (textFilter: string) => void;
	setPastFilterText: (pastTextFilter: string) => void;
	listBookerPastEventsList: (textFilter: string) => void;
	loadMore: (textFilter: string) => void;
	loadMorePast: (pastTextFilter: string) => void;
	setSelectedEvent: (eventItem: any) => void;
	setPage: (page: string) => void;
	status: string;
	openReviewTalentDialog: (
		bandId: number,
		eventId: number,
		location: string,
	) => void;
}

export const ListEvents: React.FC<ListBookerEventProps &
	RouteComponentProps> = ({
	setLoc,
	listBookerEventsList,
	listBookerPastEventsList,
	deleteBookerEvent,
	status,
	setTextFilter,
	setPastFilterText,
	listBookerEvent,
	listEventHistory,
	loadMore,
	loadMorePast,
	setSelectedEvent,
	setPage,
	openReviewTalentDialog,
}) => {
	const [showEventHistory, setEventHistory] = useState(false);

	useEffect(() => {
		setTextFilter("")
		setPastFilterText("")
	},[setPastFilterText, setTextFilter])

	useEffect(() => {
		if (status === "ACTIVE") {
			setLoc("/account/active-event");
			setPage("active-booker-events");
			listBookerEventsList(listBookerEvent.textFilter);
		} else if (status === "PAST") {
			setLoc("/account/past-event");
			setPage("past-booker-events");
			listBookerPastEventsList(listBookerEvent.pastTextFilter);
		}
	}, [listBookerEvent.textFilter, listBookerEvent.pastTextFilter, status, setLoc, setPage, listBookerEventsList, listBookerPastEventsList]);

	const bookerEvents =
		status === "ACTIVE" && listBookerEvent
			? listBookerEvent.events
			: listBookerEvent.pastEvents;

	return (
		<>
			<Box mb="30px" fontWeight="bold" fontFamily="Rubik" fontSize="30px">
				{status === "ACTIVE" ? "Active " : "Past "} Events
			</Box>
			{!showEventHistory && listBookerEvent.listEventRequested && (
				<div>Loading...</div>
			)}
			{!showEventHistory &&
				listBookerEvent.listEventSucceeded &&
				listBookerEvent &&
				bookerEvents.items && (
					<>
						<SearchTextField
							value={
								status === "ACTIVE"
									? listBookerEvent.textFilter
									: listBookerEvent.pastTextFilter
							}
							search={
								status === "ACTIVE"
									? setTextFilter
									: setPastFilterText
							}
						/>
						{bookerEvents.items.length > 0 &&
							bookerEvents.items.map(
								(item: any, index: number) => (
									<Box key={index}>
										<BookerEventItem
											key={item.id}
											event={item}
											deleteBookerEvent={
												deleteBookerEvent
											}
											openReviewTalentDialog={(band_id: number, event_id: number, band: any) =>
												openReviewTalentDialog(
													band_id,
													event_id,
													band.location,
												)
											}
											showEventHistory={() => {
												listEventHistory(item.id);
												setEventHistory(true);
											}}
											status={status}
											setSelectedEvent={setSelectedEvent}
										/>
									</Box>
								),
							)}
						{bookerEvents.items.length <
							bookerEvents.total_items && (
							<Box mt={3} display="flex" justifyContent="center">
								<Button
									variant="contained"
									color="secondary"
									onClick={() =>
										status === "ACTIVE"
											? loadMore(
													listBookerEvent.textFilter,
											  )
											: loadMorePast(
													listBookerEvent.pastTextFilter,
											  )
									}
									disabled={
										listBookerEvent.loadMoreRequested
									}>
									Load More Results
								</Button>
							</Box>
						)}
						<ReviewTalent
							titles={[
								"LEAVE A REVIEW",
								"Let us Know what you thought of",
								"Repsy and your experience with us.",
							]}
							type="Talent"
						/>
					</>
				)}
			{!showEventHistory &&
				listBookerEvent.listEventSucceeded &&
				listBookerEvent &&
				bookerEvents &&
				bookerEvents.items &&
				bookerEvents.items.length === 0 && (
					<Box mt={3}>{status === "ACTIVE" ? "No Active Events" : "No Past Events"}</Box>
				)}
			{showEventHistory && (
				<EventHistory
					isRequested={listBookerEvent.eventHistoryRequested}
					isSucceeded={listBookerEvent.eventHistorySucceeded}
					isFailed={listBookerEvent.eventHistoryFailure}
					eventHistory={listBookerEvent.eventHistory}
					onBackClick={() => setEventHistory(false)}
				/>
			)}
		</>
	);
};
