import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import SignupDialog from "./signup-dialog";
import { ISignupDialogOwnProps } from "./interfaces";
import { IAppState } from "../generic/interfaces";

const mapStateToProps = (
	state: IAppState,
	ownProps: ISignupDialogOwnProps,
) => ({
	opened: ownProps.opened,
	state: state.registration,
});

const mapDispatchToProps = (
	dispatch: ThunkDispatch<{}, {}, any>,
	ownProps: ISignupDialogOwnProps,
) => ({
	handleClose: () => dispatch(ownProps.handleClose()),
	handleOpenLogin: () => {
		dispatch(ownProps.handleClose());
		setTimeout(() => {
			dispatch(ownProps.handleOpenLogin());
		}, 50);
	},
	handleLoginSubmit: (email: string, password: string, login: any) => {
		dispatch(
			ownProps.handleLoginSubmit(email, password, () => {
				dispatch(ownProps.handleClose());
			}),
		);
	},
	handleSignupSubmit: (user: any) => {
		dispatch(
			ownProps.handleSignupSubmit(user, () => {
				dispatch(ownProps.handleClose());
				dispatch(ownProps.handleSignUpConfirmationOpen());
			}),
		);
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(SignupDialog);
