import React, { useEffect } from "react";
import { Box, Container, Typography, Grid, Button } from "@material-ui/core";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Services from "../generic/Services";
import PageBG from "../Assets/Contact.jpg"
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        unread: {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText,
        },
        paragraphFont: {
            fontSize: "15px",
            color: "#ddd",
        },
        page: {
			backgroundImage: `url(${PageBG})`,
			height: "550px",
			backgroundPosition: "top",
			backgroundSize: "cover",
			backgroundRepeat: "no-repeat",
        },
        blogItemImg: {
            borderRadius: '15px',
            marginBottom: '25px',
            width: '100%',
            minHeight: '260px',
            backgroundSize: 'cover',
        },
        blogItemTitle: {
            fontFamily: 'Poppins',
            fontWeight: 'bold',
            fontSize: '22px',
            marginBottom: '20px',
        },
        blogItemText: {
            marginBottom: '10px',
        },
        blogItemLink: {
            fontFamily: 'Poppins',
            fontWeight: 'bold',
            fontSize: '20px',
            textDecoration: 'underline',
            color: 'rgb(255, 255, 255)',
            "&:hover": {
                color: 'rgb(100, 254, 204)',
            }
        },
        BlogListItemActive: {
            "&:blogItemImg": {
                border: `4px solid ${theme.palette.secondary.main}`,
            },
            "&:blogItemLink": {
                color: 'rgb(100, 254, 204)',
                "&:hover": {
                    color: 'rgb(255, 255, 255)',
                }
            }
        }
    })
);

const BlogList: React.FC = () => {
    const classes = useStyles();
    const [data, setData] = React.useState<any>();
    const [pageNumber, setPageNumber] = React.useState(1);
    const [totalRecords, setTotalRecords] = React.useState(0);

    useEffect(() => {
        if(window)
            window.scrollTo(0, 0);
            
        getBlogList(pageNumber);
    }, [setPageNumber]);

    const getBlogList = (page: number) => {
        Services.blogs.listBlogs(page).then((response: any) => {
            console.log('response.items: ', response.items)
            console.log('data: ', data);
            if(!data)
            {
                setData(response.items);
                setTotalRecords(response.total_items);
            }
            else
            {

                setData((data: any) => [...data, ...response.items])
            }
        });
    }

    const loadMoreBlogLists = () => {
        setPageNumber(pageNumber + 1);
        getBlogList(pageNumber + 1);
    }

    return (
        <>
            
                <Container maxWidth="lg">
                    <Box
					display="flex"
					justifyContent="center"
					alignItems="center"
					flexDirection="row"
					fontFamily="Rubik"
					fontSize={72}
					fontWeight={800}
					className={classes.page}
				>
					Blog
				</Box>
               
                    <Box>
                    <Grid container direction="row" justify="center" alignItems="center">
                        <Grid item xs={12} sm={12} md={8} lg={10}>
                            <Grid container spacing={8}>
                            {data && data.map((item: any, index: number) => (
                                <Grid item xs={12} md={6} lg={6} justify="flex-start" className={classes.BlogListItem}>
                                    <Grid className={classes.BlogListItemActive}>
                                        <div className={classes.blogItemImg} style={{ background: `url(${item.image}) no-repeat center center`, backgroundSize: 'cover' }}></div>
                                        <Typography variant="h6" className={classes.blogItemTitle}>{item.title}</Typography>
                                        {
                                         //<Typography variant="body1" className={classes.blogItemText}>{item.description.replace( /(<([^>]+)>)/ig, '').substring(0, 300)}</Typography>                                        
                                        }
                                        <Typography variant="body1" className={classes.blogItemText}>{item.summary}</Typography>
                                        <Typography variant="h6" className={classes.blogItemTitle} style={{ marginBottom: '0px' }}>
                                            <Link to={`/blog/${item?.slug}`} className={classes.blogItemLink} >See More</Link>
                                        </Typography>
                                    </Grid>
                                </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>
                    {totalRecords > 0 && totalRecords != data.length && (
                        <Grid container direction="row" justify="center" alignItems="center" style={{ marginTop: '45px' }}>
                            <Grid item>
                                <Button variant="contained" size="medium" color="secondary" style={{ padding: '8px 30px 8px' }} onClick={loadMoreBlogLists}>Load More</Button>
                            </Grid>
                        </Grid>    
                    )}
                    
                    </Box>
                    
                </Container>
            
        </>
    );
};

export default BlogList;