import { HeaderActionType } from "./interfaces";

const openSignupModal = () => {
	return {
		type: HeaderActionType.SIGNUP_MODAL_OPEN,
	};
};

const closeSignupModal = () => {
	return {
		type: HeaderActionType.SIGNUP_MODAL_CLOSE,
	};
};

const openConfirmationModal = () => {
	return {
		type: HeaderActionType.CONFIRMATION_MODAL_OPEN,
	};
};

const closeConfirmationModal = () => {
	return {
		type: HeaderActionType.CONFIRMATION_MODAL_CLOSE,
	};
};

const openLoginModal = () => {
	return {
		type: HeaderActionType.LOGIN_MODAL_OPEN,
	};
};

const closeLoginModal = () => {
	return {
		type: HeaderActionType.LOGIN_MODAL_CLOSE,
	};
};

const incrementMessageNotification = (id: number) => {
	return {
		type: HeaderActionType.MESSAGE_NOTIFICATION_NEW,
		payload: id,
	};
};

const markAsSeen = (id: number) => {
	return {
		type: HeaderActionType.MESSAGE_SEEN,
		payload: id,
	};
};

const resetMessageNotification = () => {
	return {
		type: HeaderActionType.MESSAGE_NOTIFICATION_SEEN,
	};
};

const messageNew = (id: number) => {
	return {
		type: HeaderActionType.MESSAGE_NEW,
		payload: id,
	};
};

const newNotification = (item: { message: string, timestamp: number, data: any }) => {
	return {
		type: HeaderActionType.NOTIFICATION_RECIEVED,
		payload: item
	}
}

const NotificationSeen = (index: number) => {
	return {
		type: HeaderActionType.NOTIFICATION_SEEN,
		payload: index
	}
}

const openNtifications = () => {
	return {
		type: HeaderActionType.NOTIFICATIONS_OPENED
	}
}

const closeSnackBar = () => {
	return {
		type: HeaderActionType.SNACKBAR_CLOSE
	}
}

const headerActions = {
	openSignupModal,
	closeSignupModal,
	openLoginModal,
	closeLoginModal,
	openConfirmationModal,
	closeConfirmationModal,
	incrementMessageNotification,
	resetMessageNotification,
	markAsSeen,
	messageNew,
	newNotification,
	NotificationSeen,
	openNtifications,
	closeSnackBar,
};

export default headerActions;
