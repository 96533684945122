import { combineReducers } from "redux";
import { IError } from "../../Base/ErrorMessageBase";

export const generateFetchReducer = (prefix: string) => {
	const isRequested = (state = false, action: any) => {
		switch (action.type) {
			case prefix + "REQUEST":
				return true;
			case prefix + "SUCCESS":
			case prefix + "FAILURE":
				return false;
			default:
				return state;
		}
	};

	const isSucceed = (state = false, action: any) => {
		switch (action.type) {
			case prefix + "REQUEST":
			case prefix + "FAILURE":
				return false;
			case prefix + "SUCCESS":
				return true;
			default:
				return state;
		}
	};

	const isFailure = (state = false, action: any) => {
		switch (action.type) {
			case prefix + "REQUEST":
			case prefix + "SUCCESS":
				return false;
			case prefix + "FAILURE":
				return true;
			default:
				return state;
		}
	};

	const items = (state: any = {}, action: any) => {
		switch (action.type) {
			case prefix + "SUCCESS":
				const nextState = { ...state };
				action.payload.forEach((item: any) => {
					nextState[item.id] = item;
				});
				return nextState;
			default:
				return state;
		}
	};
	const allIds = (state = [], action: any) => {
		switch (action.type) {
			case prefix + "SUCCESS":
				return action.payload.map((item: any) => item.id);
			default:
				return state;
		}
	};
	const error = (state = null, action: any) => {
		switch (action.type) {
			case prefix + "FAILURE":
				return action.payload;
			default:
				return state;
		}
	};

	const lastFetched = (state = "", action: any) => {
		switch (action.type) {
			case prefix + "SUCCESS":
				return Date();
			default:
				return state;
		}
	};
	return combineReducers({
		isRequested,
		isSucceed,
		isFailure,
		items,
		allIds,
		error,
		lastFetched,
	});
};

export interface IGeneratedFetchReducer<T> {
	isRequested: boolean;
	isSucceed: boolean;
	isFailure: boolean;
	items: { [id: number]: T };
	allIds: number[];
	error: IError;
	lastFetched: string;
}
