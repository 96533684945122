import { IAuthReducer } from "../AuthBase/interfaces";
export enum HeaderActionType {
	SIGNUP_MODAL_CLOSE = "SIGNUP_MODAL_CLOSE",
	SIGNUP_MODAL_OPEN = "SIGNUP_MODAL_OPEN",
	LOGIN_MODAL_OPEN = "LOGIN_MODAL_OPEN",
	LOGIN_MODAL_CLOSE = "LOGIN_MODAL_CLOSE",
	CONFIRMATION_MODAL_OPEN = "CONFIRMATION_MODAL_OPEN",
	CONFIRMATION_MODAL_CLOSE = "CONFIRMATION_MODAL_CLOSE",
	FORGOT_PASSWORD_MODAL_OPEN = "FORGOT_PASSWORD_MODAL_OPEN",
	FORGOT_PASSWORD_MODAL_CLOSE = "FORGOT_PASSWORD_MODAL_CLOSE",
	RESEND_ACTIVATION_LINK_MODAL_OPEN = "RESEND_ACTIVATION_LINK_MODAL_OPEN",
	RESEND_ACTIVATION_LINK_MODAL_CLOSE = "RESEND_ACTIVATION_LINK_MODAL_CLOSE",
	MESSAGE_NOTIFICATION_NEW = "MESSAGE_NOTIFICATION_NEW",
	MESSAGE_NOTIFICATION_SEEN = "MESSAGE_NOTIFICATION_SEEN",
	MESSAGE_SEEN = "MESSAGE_SEEN",
	MESSAGE_NEW = "MESSAGE_NEW",
	NOTIFICATION_RECIEVED = "NOTIFICATION_RECIEVED",
	NOTIFICATION_SEEN = "NOTIFICATION_SEEN",
	NOTIFICATIONS_OPENED = "NOTIFICATIONS_OPENED",
	SNACKBAR_CLOSE = "SNACKBAR_CLOSE",
}

export interface IHeaderActions {
	type: HeaderActionType;
	payload: any;
}

export interface IHeaderReducer {
	email: string;
	messagesNotification: {messageCount: number, ids: number[]};
	signupModalOpened: boolean;
	loginModalOpened: boolean;
	confirmationModalOpened: boolean;
	forgetPasswordModalOpened: boolean;
	resendActivationLinkModalOpened: boolean;
	notification: { notifications: Array<{ message: string, timestamp: number, data: any }>, new: boolean, snackBar: { open: boolean, message: string } }
}

export interface IHeaderState {
	header: IHeaderReducer;
}

export interface IHeaderProps {
	page: string;
	header: IHeaderReducer;
	authentication: IAuthReducer;
	search: (text: string) => void;
	logout: () => void;
	openLoginModal: () => void;
	openSignupModal: () => void;
	incrementMessageNotification: (id: number) => void;
	resetMessageNotification: () => void;
	notificationSeen: (index: number) => void;
	openNotification: () => void;
	isWhiteHeader?: boolean

}
