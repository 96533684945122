import { combineReducers } from "redux";
import { createSlice } from "redux-starter-kit";
import { Dispatch } from "redux";
import Services from "../generic/Services";
import { IAppState } from "../generic/interfaces";
import { filterFavoriteBands } from "./filterBarSlice";
import {
	filterFavoriteBandsPageReset,
	filterFavoriteBandsPageIncrement,
} from "./filterBarSlice/pageSlice";
import { IFilterBandsReducer } from "../SearchPage/FilterBar/interfaces";
import { IBand } from "../interfaces";

export interface ISearchFavoriteBandsCombinedReducer {
	searchFavoriteBands: ISearchBandsSlice;
	filterFavoriteBands: IFilterBandsReducer;
}
export interface ISearchBandsSlice {
	isBandsRequested: boolean;
	isLoadMoreRequested: boolean;
	bands: IBand[];
	error: any;
	count?: number;
}
const isBandsRequestedSlice = createSlice({
	slice: "isFavoriteBandsRequested",
	initialState: false,
	reducers: {
		searchBandsRequest(state, action) {
			return true;
		},
		searchBandsSuccess(state, action) {
			return false;
		},
		searchBandsFail(state, action) {
			return false;
		},
	},
});

export const {
	searchBandsRequest,
	searchBandsSuccess,
	searchBandsFail,
} = isBandsRequestedSlice.actions;

export default isBandsRequestedSlice.reducer;

const isLoadMoreRequestedSlice = createSlice({
	slice: "isFavoriteLoadMoreRequested",
	initialState: false,
	reducers: {
		searchBandsLoadmoreRequest(state, action) {
			return true;
		},
		searchBandsLoadmoreSuccess(state, action) {
			return false;
		},
		searchBandsLoadmoreFail(state, action) {
			return false;
		},
	},
});

export const {
	searchBandsLoadmoreRequest,
	searchBandsLoadmoreSuccess,
	searchBandsLoadmoreFail,
} = isLoadMoreRequestedSlice.actions;

export const { reducer } = isLoadMoreRequestedSlice;

const bandsSlice = createSlice({
	slice: "favoriteBands",
	initialState: [],
	reducers: {},
	extraReducers: {
		[isBandsRequestedSlice.actions.searchBandsSuccess.type]: (
			state,
			action,
		) => {
			return action.payload.items;
		},
		[isLoadMoreRequestedSlice.actions.searchBandsLoadmoreSuccess.type]: (
			state,
			action,
		) => {
			return state.concat(action.payload.items);
		},
	},
});

const countSlice = createSlice({
	slice: "favoriteCount",
	initialState: 0,
	reducers: {},
	extraReducers: {
		[isBandsRequestedSlice.actions.searchBandsSuccess.type]: (
			state,
			action,
		) => {
			return action.payload.total_items;
		},
		[isLoadMoreRequestedSlice.actions.searchBandsLoadmoreSuccess.type]: (
			state,
			action,
		) => {
			return action.payload.total_items;
		},
	},
});

const errorSlice = createSlice({
	slice: "favoriteCount",
	initialState: {},
	reducers: {},
	extraReducers: {
		[isBandsRequestedSlice.actions.searchBandsFail.type]: (
			state,
			action,
		) => {
			return action.error;
		},
		[isLoadMoreRequestedSlice.actions.searchBandsLoadmoreFail.type]: (
			state,
			action,
		) => {
			return action.error;
		},
	},
});

export const search = () => {
	return (dispatch: Dispatch, getState: () => IAppState) => {
		dispatch(filterFavoriteBandsPageReset());
		const { searchFavoriteBands, authentication } = getState();
		dispatch(searchBandsRequest());
		Services.bands
			.list(
				searchFavoriteBands.filterFavoriteBands,
				authentication.isLoggedIn,
			)
			.then(
				res => {
					dispatch(searchBandsSuccess(res));
					dispatch(filterFavoriteBandsPageIncrement());
					return res;
				},
				error => {
					dispatch(searchBandsFail(error));
				},
			);
	};
};

export const loadMore = () => {
	return (dispatch: Dispatch, getState: () => IAppState) => {
		dispatch(searchBandsLoadmoreRequest());
		const { searchFavoriteBands, authentication } = getState();
		Services.bands
			.list(
				searchFavoriteBands.filterFavoriteBands,
				authentication.isLoggedIn,
			)
			.then(
				res => {
					dispatch(searchBandsLoadmoreSuccess(res));
					dispatch(filterFavoriteBandsPageIncrement());
					return res;
				},
				error => {
					dispatch(searchBandsLoadmoreFail(error));
				},
			);
	};
};

const searchFavoriteBandsReducer = () => {
	return combineReducers({
		isBandsRequested: isBandsRequestedSlice.reducer,
		isLoadMoreRequested: isLoadMoreRequestedSlice.reducer,
		bands: bandsSlice.reducer,
		count: countSlice.reducer,
		error: errorSlice.reducer,
	});
};

export const searchFavoriteBands = combineReducers({
	searchFavoriteBands: searchFavoriteBandsReducer(),
	filterFavoriteBands,
});
