import {
	NotifierActionType,
	INotifierActions,
	INotification,
} from "./interfaces";
import { combineReducers } from "redux";

const notifications = (
	state: INotification[] = [],
	action: INotifierActions,
) => {
	switch (action.type) {
		case NotifierActionType.ENQUEUE_SNACKBAR:
			return [...state, { key: action.payload.key, ...action.payload }];
		case NotifierActionType.CLOSE_SNACKBAR:
			return state.map((notification: INotification) =>
				action.payload.dismissAll ||
				notification.key === action.payload.key
					? { ...notification, dismissed: true }
					: { ...notification },
			);
		case NotifierActionType.REMOVE_SNACKBAR:
			return state.filter(
				(notification: INotification) =>
					notification.key !== action.payload.key,
			);
		default:
			return state;
	}
};

export const notifier = combineReducers({
	notifications,
});
