import React, { useEffect, useState } from "react";
import {
	Box,
	Dialog,
	DialogActions,
	Button,
	DialogContent,
	DialogContentText,
} from "@material-ui/core";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { TalentState } from "./editTalentProfileSlice";
import TalentProfileForm from "./TalentProfileForm";
import * as Yup from "yup";
import { checkRescourcesLoaded } from "../../generic/Resources/index";
import { IResourcesReducer } from "../../generic/Resources/interfaces";
import { IAuthReducer } from "../../AuthBase";
import { useHistory, useLocation, useParams } from "react-router-dom"

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		dialogRoot: {
			border: "1px solid",
			borderColor: theme.palette.secondary.main,
		},
	}),
);

const validationSchema = Yup.object({
	name: Yup.string()
		.required("Band Name is required")
		.matches(/^(?!\s*$).+/, { message: "First Name field is required" }),
	location: Yup.string()
		.required("Location is required")
		.matches(/^(?!\s*$).+/, { message: "Location field is required" }),
	state: Yup.object()
		.required()
		.shape({
			id: Yup.number(),
		}),
	price_from: Yup.number()
		.required("Price From is required")
		.typeError("enter a number"),
	price_to: Yup.number()
		.required("Price To is required")
		.typeError("enter a number"),
	deposit: Yup.number(),
		// .required("Deposit Percentage is required")
		// .typeError("enter a number")
		// .min(0)
		// .max(100),
	size: Yup.number()
		.required("Size is required")
		.moreThan(0)
		.typeError("enter a number"),
	bio: Yup.string().required("bio is required"),
	social_links: Yup.array()
		.of(
			Yup.object().shape({
				type: Yup.string(),
				url: Yup.string(),
			}),
		)
		.nullable(),
	featured_songs: Yup.array()
		.of(
			Yup.object().shape({
				name: Yup.string().required(),
				url: Yup.string()
					.url("Error, should be a link")
					.required(),
			}),
		)
		.nullable(),
	genres: Yup.array()
		.min(1)
		.of(
			Yup.object()
				.shape({
					id: Yup.number().required(),
				})
				.required(),
		),
	states: Yup.array()
		.min(1)
		.of(
			Yup.object()
				.shape({
					id: Yup.number().required(),
				})
				.required(),
		),
	preferred_gig_types: Yup.array()
		.min(1)
		.of(
			Yup.object()
				.shape({
					id: Yup.number().required(),
				})
				.required(),
		),
	willing_to_travel: Yup.array()
		.required()
		.of(
			Yup.object().shape({
				id: Yup.number(),
				title: Yup.string(),
			}),
		),
	photo: Yup.object().nullable(),
	header_photo: Yup.object().nullable(),
	hospitality_and_production_rider: Yup.mixed().required(),

	//social links
	spotify: Yup.string().matches(
		/^(https:\/\/open.spotify.com\/)([a-zA-Z0-9]+)(.*)$/,
		{
			message: "Error, spotify url should be a link",
		},
	),
	soundcloud: Yup.string().matches(
		/^https?:\/\/(soundcloud\.com|snd\.sc)\/(.*)$/,
		{
			message: "Error, soundcloud url should be a link",
		},
	),
	youtube: Yup.string().matches(
		/^(https?:\/\/)?(www\.youtube\.com|youtu\.?be)\/.+$/,
		{
			message: "Error, youtube url should be a link",
		},
	),
	facebook: Yup.string().matches(
		/(?:(?:http|https):\/\/)?(?:www.)?facebook.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[?\w-]*\/)?(?:profile.php\?id=(?=\d.*))?([\w-]*)/,
		{ message: "Error, facebook url should be a link" },
	),
	instagram: Yup.string().matches(
		/(?:(?:http|https):\/\/)?(?:www.)?(?:instagram.com|instagr.am)\/([A-Za-z0-9-_.]+)/,
		{
			message: "Error, instagram url should be a link",
		},
	),

	///phone number
	phone_number: Yup.string().matches(/^[0-9]{10}$/, {
		message: "Phone number is not valid",
	}),
	phone_number1: Yup.string()
		.required()
		.matches(/^[0-9]{3}$/, { message: "Phone number is not valid" }),
	phone_number2: Yup.string()
		.required()
		.matches(/^[0-9]{3}$/, { message: "Phone number is not valid" }),
	phone_number3: Yup.string()
		.required()
		.matches(/^[0-9]{4}$/, { message: "Phone number is not valid" }),

	//featured songs
	featured_song_name_1: Yup.string(),
	featured_song_link_1: Yup.string().url("Error, should be a link"),
	featured_song_name_2: Yup.string(),
	featured_song_link_2: Yup.string().url("Error, should be a link"),
});

export interface EditTalentProfileProps {
	talentProfile: TalentState;
	resources: IResourcesReducer;
	listMyBands: () => void;
	addTalentProfile: (talentProfile: any) => void;
	editTalentProfile: (talentProfile: any, id: number) => void;
	getBandById:(id: number) => void;
	setPage: (page: string) => void;
	unMount: () => void;
	handleSaveClose: () => any;
	handleFailClose: () => any;
	handleOpen: () => any;
	setLoc: (location: string) => void;
	authentication: IAuthReducer;
}

export const EditTalentProfile: React.FC<EditTalentProfileProps> = ({
	setLoc,
	handleSaveClose,
	handleOpen,
	unMount,
	setPage,
	listMyBands,
	addTalentProfile,
	editTalentProfile,
	getBandById,
	talentProfile,
	resources,
	authentication,
	handleFailClose
}) => {
	const classes = useStyles();
	const history = useHistory();
	const locationData = useLocation();
	const { id }  = useParams();
	
	useEffect(() => {
		if(!id){
			setLoc("/account/edit-profile");
			listMyBands(); 
		}
		else
		{
			setLoc("/account/edit-band/" + id);
			getBandById(id);
		}

		setPage("edit-profile");
		return () => unMount();
	}, [listMyBands, setLoc, setPage, unMount, id]);

	const initialValues = {
		//all fields
		id:
			(talentProfile.talentProfile &&
				talentProfile.talentProfile.items &&
				talentProfile.talentProfile.items[0] &&
				talentProfile.talentProfile.items[0].id) ||
			"",
		name:
			(talentProfile.talentProfile &&
				talentProfile.talentProfile.items &&
				talentProfile.talentProfile.items[0] &&
				talentProfile.talentProfile.items[0].name) ||
			"",
	location:
		(talentProfile.talentProfile &&
			talentProfile.talentProfile.items &&
			talentProfile.talentProfile.items[0] &&
			talentProfile.talentProfile.items[0].location) ||
		"",
		phone_number: 
			(talentProfile.talentProfile && 
				talentProfile.talentProfile.items[0] && 
				talentProfile.talentProfile.items[0].created_by.phone_number) || 
				(authentication?.user && authentication?.user?.phone_number || ""),
		phone_number1:
			(talentProfile.talentProfile &&
				talentProfile.talentProfile.items[0] &&
				talentProfile.talentProfile.items[0].created_by.phone_number &&
				talentProfile.talentProfile.items[0].created_by.phone_number.substring(0, 3)) ||
				(authentication?.user && authentication?.user?.phone_number.substring(0, 3) || ""),
		phone_number2:
			(talentProfile.talentProfile &&
				talentProfile.talentProfile.items[0] &&
				talentProfile.talentProfile.items[0].created_by.phone_number &&
				talentProfile.talentProfile.items[0].created_by.phone_number.substring(3, 6)) ||
				(authentication?.user && authentication?.user?.phone_number.substring(3, 6) || ""),
		phone_number3:
			(talentProfile.talentProfile &&
				talentProfile.talentProfile.items[0] &&
				talentProfile.talentProfile.items[0].created_by.phone_number &&
				talentProfile.talentProfile.items[0].created_by.phone_number.substring(6, 10)) ||
				(authentication?.user && authentication?.user?.phone_number.substring(6, 10) || ""),
		state:
			(talentProfile.talentProfile &&
				talentProfile.talentProfile.items &&
				talentProfile.talentProfile.items[0] &&
				talentProfile.talentProfile.items[0].state) ||
			null,
		price_from:
			(talentProfile.talentProfile &&
				talentProfile.talentProfile.items &&
				talentProfile.talentProfile.items[0] &&
				talentProfile.talentProfile.items[0].price_from) ||
			null,
		price_to:
			(talentProfile.talentProfile &&
				talentProfile.talentProfile.items &&
				talentProfile.talentProfile.items[0] &&
				talentProfile.talentProfile.items[0].price_to) ||
			null,
		size:
			(talentProfile.talentProfile &&
				talentProfile.talentProfile.items &&
				talentProfile.talentProfile.items[0] &&
				talentProfile.talentProfile.items[0].size) ||
			1,
		deposit: 50,
			// (talentProfile.talentProfile &&
			// 	talentProfile.talentProfile.items &&
			// 	talentProfile.talentProfile.items[0] &&
			// 	talentProfile.talentProfile.items[0].deposit) || 50,
		bio:
			(talentProfile.talentProfile &&
				talentProfile.talentProfile.items &&
				talentProfile.talentProfile.items[0] &&
				talentProfile.talentProfile.items[0].bio) ||
			"",
		social_links:
			(talentProfile.talentProfile &&
				talentProfile.talentProfile.items &&
				talentProfile.talentProfile.items[0] &&
				talentProfile.talentProfile.items[0].social_links) ||
			null,
		featured_songs:
			(talentProfile.talentProfile &&
				talentProfile.talentProfile.items &&
				talentProfile.talentProfile.items[0] &&
				talentProfile.talentProfile.items[0].featured_songs) ||
			null,
		genres:
			(talentProfile.talentProfile &&
				talentProfile.talentProfile.items &&
				talentProfile.talentProfile.items[0] &&
				talentProfile.talentProfile.items[0].genres) ||
			[],
		preferred_gig_types:
			(talentProfile.talentProfile &&
				talentProfile.talentProfile.items &&
				talentProfile.talentProfile.items[0] &&
				talentProfile.talentProfile.items[0].preferred_gig_types) ||
			[],
		willing_to_travel:
			(talentProfile.talentProfile &&
				talentProfile.talentProfile.items &&
				talentProfile.talentProfile.items[0] &&
				talentProfile.talentProfile.items[0].willing_to_travel) ||
			[],
		photo:
			(talentProfile.talentProfile &&
				talentProfile.talentProfile.items &&
				talentProfile.talentProfile.items[0] &&
				talentProfile.talentProfile.items[0].photo) ||
			null,
		hospitality_and_production_rider:
			(talentProfile.talentProfile &&
				talentProfile.talentProfile.items &&
				talentProfile.talentProfile.items[0] &&
				talentProfile.talentProfile.items[0].hospitality_and_production_rider) ||
			null,
		header_photo:
			(talentProfile.talentProfile &&
				talentProfile.talentProfile.items &&
				talentProfile.talentProfile.items[0] &&
				talentProfile.talentProfile.items[0].header_photo) ||
			null,

		//social link
		facebook:
			talentProfile.talentProfile &&
			talentProfile.talentProfile.items &&
			talentProfile.talentProfile.items[0] &&
			talentProfile.talentProfile.items[0].social_links.filter(
				(element: any) => element.type === "FACEBOOK",
			).length
				? talentProfile.talentProfile.items[0].social_links.filter(
						(element: any) => element.type === "FACEBOOK",
				  )[0].url
				: "",
		spotify:
			talentProfile.talentProfile &&
			talentProfile.talentProfile.items &&
			talentProfile.talentProfile.items[0] &&
			talentProfile.talentProfile.items[0].social_links.filter(
				(element: any) => element.type === "SPOTIFY",
			).length
				? talentProfile.talentProfile.items[0].social_links.filter(
						(element: any) => element.type === "SPOTIFY",
				  )[0].url
				: "",
		instagram:
			talentProfile.talentProfile &&
			talentProfile.talentProfile.items &&
			talentProfile.talentProfile.items[0] &&
			talentProfile.talentProfile.items[0].social_links.filter(
				(element: any) => element.type === "INSTAGRAM",
			).length
				? talentProfile.talentProfile.items[0].social_links.filter(
						(element: any) => element.type === "INSTAGRAM",
				  )[0].url
				: "",
		soundcloud:
			talentProfile.talentProfile &&
			talentProfile.talentProfile.items &&
			talentProfile.talentProfile.items[0] &&
			talentProfile.talentProfile.items[0].social_links.filter(
				(element: any) => element.type === "SOUNDCLOUD",
			).length
				? talentProfile.talentProfile.items[0].social_links.filter(
						(element: any) => element.type === "SOUNDCLOUD",
				  )[0].url
				: "",
		youtube:
			talentProfile.talentProfile &&
			talentProfile.talentProfile.items &&
			talentProfile.talentProfile.items[0] &&
			talentProfile.talentProfile.items[0].social_links.filter(
				(element: any) => element.type === "YOUTUBE",
			).length
				? talentProfile.talentProfile.items[0].social_links.filter(
						(element: any) => element.type === "YOUTUBE",
				  )[0].url
				: "",

		//featured link
		featured_song_name_1:
			talentProfile.talentProfile &&
			talentProfile.talentProfile.items &&
			talentProfile.talentProfile.items[0] &&
			talentProfile.talentProfile.items[0].featured_songs[0] &&
			talentProfile.talentProfile.items[0].featured_songs[0].name,
		featured_song_link_1:
			talentProfile.talentProfile &&
			talentProfile.talentProfile.items &&
			talentProfile.talentProfile.items[0] &&
			talentProfile.talentProfile.items[0].featured_songs[0] &&
			talentProfile.talentProfile.items[0].featured_songs[0].url,
		featured_song_name_2:
			talentProfile.talentProfile &&
			talentProfile.talentProfile.items &&
			talentProfile.talentProfile.items[0] &&
			talentProfile.talentProfile.items[0].featured_songs[1] &&
			talentProfile.talentProfile.items[0].featured_songs[1].name,
		featured_song_link_2:
			talentProfile.talentProfile &&
			talentProfile.talentProfile.items &&
			talentProfile.talentProfile.items[0] &&
			talentProfile.talentProfile.items[0].featured_songs[1] &&
			talentProfile.talentProfile.items[0].featured_songs[1].url,
	};

	return (
		<Box>
			{talentProfile.talentProfileSucceeded &&
				talentProfile.talentProfile.items &&
				talentProfile.talentProfile.items.length === 0 &&
				checkRescourcesLoaded(resources) && (
					<TalentProfileForm
						initialValues={initialValues}
						validationSchema={validationSchema}
						resources={resources}
						talentProfile={talentProfile}
						handleOnSubmit={(talentProfile: any) =>
							addTalentProfile(talentProfile)
						}
					/>
				)}
			{talentProfile.talentProfileSucceeded &&
				talentProfile.talentProfile.items &&
				talentProfile.talentProfile.items.length > 0 &&
				checkRescourcesLoaded(resources) && (
					<TalentProfileForm
						initialValues={initialValues}
						validationSchema={validationSchema}
						resources={resources}
						talentProfile={talentProfile}
						handleOnSubmit={(talentProfile: any, id: number) =>
							editTalentProfile(talentProfile, id)
						}
					/>
				)}
				<Dialog
					fullWidth={true}
					maxWidth="sm"
					scroll="body"
					open={(talentProfile && talentProfile.saveModal) || false}
					onClose={handleSaveClose}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
					classes={{ paper: classes.dialogRoot }}
				>
					<DialogContent>
						<DialogContentText
							color="textPrimary"
							id="alert-dialog-description"
						>
							{talentProfile.talentProfile.items[0]?.status === "MISSING_PAYMENT" ? "Your Profile information is saved. You need to complete your billing information before your account can be approved." : "Your Profile info is saved." }
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button
							onClick={
								talentProfile.talentProfile?.items[0]?.status === "MISSING_PAYMENT"
									? () => {
										handleSaveClose();
										history.push("/account/billing-information");
									}
									: () => handleSaveClose()
							}
							color="default"
							autoFocus>
							{talentProfile.talentProfile?.items[0]?.status === "MISSING_PAYMENT" ? "Add Billing Info" : "Ok"}
						</Button>
					</DialogActions>
				</Dialog>
				<Dialog
					fullWidth={true}
					maxWidth="sm"
					scroll="body"
					open={talentProfile.editTalentProfileFailure || talentProfile.addTalentProfileFailure}
					onClose={handleFailClose}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
					classes={{ paper: classes.dialogRoot }}
				>
					<DialogContent>
						<DialogContentText
							color="textPrimary"
							id="alert-dialog-description"
						>
							Something went wrong, please try again later.
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleFailClose} color="default" autoFocus>
							Ok
						</Button>
					</DialogActions>
				</Dialog>
		</Box>
	);
};
