import { connect } from "react-redux";
import { BandPage } from "./BandDetailsPage";
import { IAppState } from "../generic/interfaces";
import { ThunkDispatch } from "redux-thunk";
import { getBandById, bandFavoriteToggle, getBandByUsername } from "./bandDetailsSlice";
import { setPage } from "../HomePage/homePageSlice";
import headerActions from "../Header/actions";
import { bandRequestModalOpen, warningModalOpen } from "./BandRequest/bandRequestSlice";

const mapStateToProps = (state: IAppState) => ({
	isUserApproved: state.authentication.user.status === "APPROVED",
	bandDetailsSlice: state.bandPage.bandDetails,
	authenticated: state.authentication,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
	setPage: (page: string) => {
		dispatch(setPage(page));
	},
	getBandById: (bandId: number, authenticated: boolean) =>
		dispatch(getBandById(bandId, authenticated)),
	getBandByUsername: (username: string, authenticated: boolean) =>
		dispatch(getBandByUsername(username, authenticated)),
	favoriteToggle: (bandId: number, isFavorite: boolean) => {
		dispatch(bandFavoriteToggle(bandId, isFavorite));
	},
	openSignupModal: () => {
		dispatch(headerActions.openSignupModal());
	},
	handleOpen: () => dispatch(bandRequestModalOpen()),
	handleOpenWarningModel: () => dispatch(warningModalOpen()),
});

export default connect(mapStateToProps, mapDispatchToProps)(BandPage);
