import { createSlice } from "redux-starter-kit";
import Services from "../../generic/Services";

export interface BandReviewItem {
	id: number;
	user_review: string;
	repsy_review: string;
	date: string;
	location: string;
	band_rating: string;
	repsy_rating: string;
	user_rating: string;
}

export interface BandReviewState {
	bandReviewRequested: boolean;
	bandReviewSucceeded: boolean;
	bandReviewFailure: boolean;

	loadMoreRequested: boolean;
	loadMoreSucceeded: boolean;
	loadMoreFailure: boolean;

	bandReviewPage: number;
	totalItems: number;
	bandReviews: {
		items: BandReviewItem[];
		server_message: string;
		user_message: string;
		total_items: string
	};
	error: {
		code: number;
		message: string;
		errors: [];
	};
}

let initialState: BandReviewState = {
	bandReviewRequested: false,
	bandReviewSucceeded: false,
	bandReviewFailure: false,

	loadMoreRequested: false,
	loadMoreSucceeded: false,
	loadMoreFailure: false,

	bandReviewPage: 2,
	totalItems: 0,
	bandReviews: {
		items: [],
		server_message: "",
		user_message: "",
		total_items: ""
	},
	error: {
		code: 0,
		message: "",
		errors: [],
	},
};

const bandReviewSlice = createSlice({
	slice: "bandReview",
	initialState,
	reducers: {
		bandReviewRequested(state, action) {
			state.bandReviewRequested = true;
			state.bandReviewSucceeded = false;
			state.bandReviewFailure = false;
		},
		bandReviewSucceeded(state, action) {
			state.bandReviewRequested = false;
			state.bandReviewSucceeded = true;
			state.bandReviewFailure = false;
			state.totalItems = action.payload.total_items;
			state.bandReviews = action.payload;
			state.error = {
				code: 0,
				message: "",
				errors: [],
			};
		},
		bandReviewFailure(state, action) {
			state.bandReviewRequested = false;
			state.bandReviewSucceeded = false;
			state.bandReviewFailure = true;
			state.error = action.payload;
		},

		loadMoreRequested(state, action) {
			state.loadMoreRequested = true;
			state.bandReviewSucceeded = false;
			state.bandReviewFailure = false;
		},
		loadMoreSucceeded(state, action) {
			state.bandReviewRequested = false;
			state.bandReviewSucceeded = true;
			state.bandReviewFailure = false;
			state.bandReviewPage = state.bandReviewPage + 1;
			state.totalItems = action.payload.total_items;
			state.bandReviews.items = state.bandReviews.items.concat(
				action.payload.items,
			);
			state.error = {
				code: 0,
				message: "",
				errors: [],
			};
		},
		loadMoreFailure(state, action) {
			state.bandReviewRequested = false;
			state.bandReviewSucceeded = false;
			state.bandReviewFailure = true;
			state.error = action.payload;
		},
	},
});

export const {
	bandReviewRequested,
	bandReviewSucceeded,
	bandReviewFailure,
	loadMoreRequested,
	loadMoreSucceeded,
	loadMoreFailure,
} = bandReviewSlice.actions;

export default bandReviewSlice.reducer;

export const getBandsReviews = (bandId: number): any => async (
	dispatch: any,
) => {
	try {
		dispatch(bandReviewRequested());
		const res = await Services.bands.getReviewsList(bandId, 1);
		dispatch(bandReviewSucceeded(res));
	} catch (err) {
		dispatch(bandReviewFailure(err));
	}
};

export const loadMoreReviewsList = (
	bandId: number,
	bandReviewPage: number,
): any => async (dispatch: any) => {
	try {
		dispatch(loadMoreRequested());
		const res = await Services.bands.getReviewsList(bandId, bandReviewPage);
		dispatch(loadMoreSucceeded(res));
	} catch (err) {
		dispatch(loadMoreFailure(err));
	}
};
