import { createSlice } from "redux-starter-kit";

export interface FiltersState {
	sort: { by: string; dir: string };
	sortText: string | null;
}

let initialState: FiltersState = {
	sort: { by: "RATING", dir: "DESC" },
	sortText: null,
};

const sortSlice = createSlice({
	slice: "sortFavorite",
	initialState: initialState,
	reducers: {
		filterBandsSort(state, action) {
			state.sort = action.payload;
		},
		filterBandsClearSort(state, action) {
			state.sort = { by: "PRICE", dir: "ASC" };
		},
		filterBandsSortText(state, action) {
			state.sortText = action.payload;
		},
		filterBandsClearSortText(state, action) {
			state.sortText = null;
		},
	},
});

export const {
	filterBandsSort,
	filterBandsClearSort,
	filterBandsSortText,
	filterBandsClearSortText,
} = sortSlice.actions;

export default sortSlice.reducer;
