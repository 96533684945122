import React from "react";
import Grid from "@material-ui/core/Grid";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import searchBarIcon from "../../Assets/Search Bar Icon.svg";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";

export const Filters: React.FC<any> = ({ handleClick, interactiveSearch }) => {
	const searchbuttonRef = React.createRef<any>();
	const [text, setText] = React.useState<string>("");

	const onChange = (e: any) => {
		setText(e.target.value);
		interactiveSearch(e.target.value);
	};

	const handleKeyDown = (e: any) => {
		if (e.key === "Enter") {
			searchbuttonRef.current.focus();
		}
	};

 return (
		<Grid container justify="space-between" spacing={1}>
			<Grid item xs={12} md={6} lg={6}>
				<OutlinedInput
				labelWidth={0}
				onChange={onChange}
				onKeyDown={handleKeyDown}
				value={text}
				fullWidth
				margin="dense"
				endAdornment={
					<IconButton
					color="primary"
					size="medium"
					aria-label="Search"
					onClick={() => handleClick(text)}
					ref={searchbuttonRef}
					>
					<Icon fontSize="large">
						<img alt="search icon" src={searchBarIcon} />
					</Icon>
					</IconButton>
				}
				/>
			</Grid>
		</Grid>
  	);
};
