import { ResetPasswordActionType, IResetPasswordAction } from "./interfaces";
import { combineReducers } from "redux";

const resetPasswordDialogOpen = (
	state: boolean = false,
	action: IResetPasswordAction,
) => {
	switch (action.type) {
		case ResetPasswordActionType.RESET_PASSWORD_DIALOG_OPEN:
			return true;
		case ResetPasswordActionType.RESET_PASSWORD_DIALOG_CLOSE:
			return false;
		default:
			return state;
	}
};

const resetPasswordRequest = (
	state: boolean = false,
	action: IResetPasswordAction,
) => {
	switch (action.type) {
		case ResetPasswordActionType.RESET_PASSWORD_REQUEST:
			return true;
		case ResetPasswordActionType.RESET_PASSWORD_SUCCESS:
		case ResetPasswordActionType.RESET_PASSWORD_FAILURE:
			return false;
		default:
			return state;
	}
};

const resetPasswordSuccess = (
	state: boolean = false,
	action: IResetPasswordAction,
) => {
	switch (action.type) {
		case ResetPasswordActionType.RESET_PASSWORD_SUCCESS:
			return true;
		case ResetPasswordActionType.RESET_SUCCESS:
		case ResetPasswordActionType.RESET_PASSWORD_REQUEST:
		case ResetPasswordActionType.RESET_PASSWORD_FAILURE:
			return false;
		default:
			return state;
	}
};

const confirmationOpen = (state: boolean = false, action: IResetPasswordAction) => {
	switch (action.type) {
		case ResetPasswordActionType.CONFIRMATION_OPEN:
			return true;
		case ResetPasswordActionType.CONFIRMATION_CLOSE:
			return false;
		default:
			return state;
	}
}

const error = (state = null, action: IResetPasswordAction) => {
	switch (action.type) {
		case ResetPasswordActionType.RESET_PASSWORD_FAILURE:
			return action.payload;
		case ResetPasswordActionType.RESET_PASSWORD_REQUEST:
		case ResetPasswordActionType.RESET_PASSWORD_SUCCESS:
			return null;
		default:
			return state;
	}
};

export const resetPassword = combineReducers({
	resetPasswordDialogOpen,
	resetPasswordRequest,
	resetPasswordSuccess,
	confirmationOpen,
	error,
});
