import React from "react";
import {
	Box,
	TextField,
	Grid,
	InputLabel,
	Button,
	Typography,
} from "@material-ui/core";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { Facebook, Instagram } from "@material-ui/icons";
import { Formik, FormikProps } from "formik";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		font: {
			fontFamily: "Squada One",
			fontSize: "22px",
			fontWeight: "normal",
		},
		infoFont: {
			fontWeight: "bold",
			fontStyle: "italic",
			fontSize: "20px",
		},
		link: {
			cursor: "pointer",
		},
	}),
);

export interface MyFormValues {
	name: any;
	email: string;
	message: string;
}

export interface ContactFormProps {
	validationSchema: any;
	initialValues: any;
	handleOnSubmit: (values: any) => void;
	handleOpen: () => any;
}

export const ContactForm: React.FC<ContactFormProps> = ({
	handleOpen,
	handleOnSubmit,
	initialValues,
	validationSchema,
}) => {
	const classes = useStyles();

	return (
		<Box>
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={async (values, actions) => {
					handleOnSubmit(values);
					actions.resetForm();
					actions.setSubmitting(false);
				}}
				render={(formikBag: FormikProps<MyFormValues>) => (
					<form onSubmit={formikBag.handleSubmit}>
						<Grid container direction="row" spacing={9}>
							<Grid item xs={12} md={6} lg={6}>
								<Grid container direction="column" spacing={4}>
									<Grid item xs={12}>
										<InputLabel
											htmlFor="name"
											className={classes.font}
										>
											NAME
										</InputLabel>
										<TextField
											autoComplete="off"
											variant="outlined"
											color="secondary"
											id="name"
											name="name"
											helperText={
												formikBag.touched.name
													? formikBag.errors.name
													: ""
											}
											error={
												formikBag.touched.name &&
												Boolean(formikBag.errors.name)
											}
											fullWidth={true}
											value={formikBag.values.name}
											onChange={(e: any) => {
												e.persist();
												formikBag.setFieldValue(
													"name",
													e.target.value,
												);
												formikBag.setFieldTouched(
													"name",
													true,
													false,
												);
											}}
										/>
									</Grid>
									<Grid item xs={12}>
										<InputLabel
											htmlFor="email"
											className={classes.font}
										>
											EMAIL ADDRESS
										</InputLabel>
										<TextField
											autoComplete="off"
											variant="outlined"
											color="secondary"
											id="email"
											name="email"
											helperText={
												formikBag.touched.email
													? formikBag.errors.email
													: ""
											}
											error={
												formikBag.touched.email &&
												Boolean(formikBag.errors.email)
											}
											fullWidth={true}
											value={formikBag.values.email}
											onChange={(e: any) => {
												e.persist();
												formikBag.setFieldValue(
													"email",
													e.target.value,
												);
												formikBag.setFieldTouched(
													"email",
													true,
													false,
												);
											}}
										/>
									</Grid>
									<Grid item xs={12}>
										<InputLabel
											htmlFor="message"
											className={classes.font}
										>
											MESSAGE
										</InputLabel>
										<TextField
											autoComplete="off"
											variant="outlined"
											color="secondary"
											id="message"
											name="message"
											helperText={
												formikBag.touched.message
													? formikBag.errors.message
													: ""
											}
											error={
												formikBag.touched.message &&
												Boolean(
													formikBag.errors.message,
												)
											}
											fullWidth={true}
											value={formikBag.values.message}
											multiline={true}
											rows={6}
											onChange={(e: any) => {
												e.persist();
												formikBag.setFieldValue(
													"message",
													e.target.value,
												);
												formikBag.setFieldTouched(
													"message",
													true,
													false,
												);
											}}
										/>
									</Grid>
									<Grid item>
										<Box
											display="flex"
											justifyContent="flex-end"
											flexDirection="row"
										>
											<Button
												type="submit"
												variant="contained"
												color="secondary"
												disabled={
													formikBag.isSubmitting
												}
											>
												Send
											</Button>
										</Box>
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={12} md={6} lg={6}>
								<Grid container direction="column" spacing={4}>
									<Grid item md={6} lg={6}>
										<Typography
											variant="body2"
											className={classes.infoFont}
										>
											2112 Morris Avenue Suite 104<br></br>
											Birmingham, AL 35203
										</Typography>
									</Grid>
									<Grid item md={6} lg={6}>
										<Typography
											variant="body2"
											className={classes.infoFont}
										>
											(205) 905-7500
										</Typography>
									</Grid>
									<Grid item md={6} lg={6}>
										<Typography
											variant="body2"
											className={classes.infoFont}
										>
											hi@repsy.com
										</Typography>
									</Grid>
									<Grid item>
										<Instagram
											className={classes.link}
											color="secondary"
											onClick={() => {
												window.location.href =
													"https://www.instagram.com/repsylive/";
											}}
										/>
										<Facebook
											className={classes.link}
											color="secondary"
											onClick={() => {
												window.location.href =
													"https://www.facebook.com/gorepsy/";
											}}
										/>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</form>
				)}
			/>
		</Box>
	);
};
