import { refineResponse } from "../helpers/refineResponse";
import { headers, baseUrl } from "../../AuthBase/helpers/auth-header";

export const listTermsAndConditions = () => {
	const requestOptions = {
		method: "POST",
		headers: { ...headers() },
	};

	// waiting for api
	return fetch(`${baseUrl}term/list`, requestOptions).then(refineResponse);
};

export const aboutPage = ({ page, items_per_page }: { page?: number; items_per_page?: number }) => {
	const requestOptions = {
		method: "POST",
		headers: { ...headers() },
		body: JSON.stringify({ page, items_per_page }),
	};

	return fetch(`${baseUrl}about/list`, requestOptions).then(refineResponse);
};

export const homePage = () => {
	const requestOptions = {
		method: "GET",
		headers: { ...headers() }
	};

	return fetch(`${baseUrl}get_front_home`, requestOptions).then(refineResponse);
};

export const listPrivacyPolicy = () => {
	const requestOptions = {
		method: "POST",
		headers: { ...headers() },
	};

	// waiting for api
	return fetch(`${baseUrl}privacy_policy/list`, requestOptions).then(refineResponse);
};