import React, { useRef } from "react";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import ErrorIcon from "@material-ui/icons/Error";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import moment from "moment";
import { ReplyForm } from "./ReplyForm";
import Services from "../../generic/Services";
import { intialPagination } from "./Messages";
import { IConversation, ReplyObject } from "./Interfaces";
import ImagePlaceholder from "../../Assets/ImagePlaceholder.png";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Linkify from "react-linkify";
import RequestView from "./RequestView";
import Collapse from "@material-ui/core/Collapse";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { BandRequestState } from "../../BandPage/BandRequest/bandRequestSlice";
import IconGreen from "../../Assets/Repsy Logo Green.svg";
import PaymentConfirmation from "./PaymentConfirmationPopup";
import { Link } from "react-router-dom";
import BookerPaymentModel from "./BookerPaymentModel";

import "../../Assets/global.css";

export interface ConversationProps {
    authentication: any;
    id: number;
    classes: any;
    eventRequestId: number;
    setSelectedEvent: (eventItem: any) => void;
    handleOpen: () => void;
    bandRequest: BandRequestState;
    handleClose: () => void;
    handleBandRequestSubmit: (values: any) => void;
    listBookerEventsList: () => void;
    //updateUserData: () => void;
    user: any;
    states: any;
}

interface Filters {
    date_to: number | null;
    date_from: number | null;
    event_request_id: number;
}

const useStyles = makeStyles(() =>
    createStyles({
        message: {
            "word-wrap": "break-word",
            width: "413px",
        },
        repsy: {
            width: 100,
            height: 100,
            margin: "auto",
            "@media screen and (max-width: 640px)": {
                width: 80,
                height: 80,
            },
            "@media screen and (max-width: 480px)": {
                width: 60,
                height: 60,
            },
        }
    })
);

export const Conversation: React.FC<ConversationProps> = ({
    authentication,
    id,
    classes,
    eventRequestId,
    setSelectedEvent,
    handleOpen,
    bandRequest,
    handleClose,
    handleBandRequestSubmit,
    listBookerEventsList,
    user,
    states
}) => {
    const messageClass = useStyles();
    // Handle Filters
    const [loadOlderLoading, setLoadOlderLoading] = React.useState<boolean>(false);

    // Handle Conversations API Data
    const [data, setData] = React.useState<Array<IConversation | ReplyObject>>([]);
    const [replyData, setReplyData] = React.useState<Array<ReplyObject>>([]);
    const [loading, setLoading] = React.useState<boolean>(true);
    const [listAll, setListAll] = React.useState<boolean>(true);
    const [ExpandDetails, setExpandDetails] = React.useState<boolean>(false);
    const [error, setError] = React.useState();
    const [request, setRequest] = React.useState<any>();
    const [getRequest, setGetRequest] = React.useState<boolean>(false);

    const [itemsPerPage, setItemsPerPage] = React.useState<number>(5);
    // Handle Conversations Pagination
    const [pagination, setPagination] = React.useState<{ page: number; total_items: number }>(intialPagination);

    const [noMoreFound, setNoMoreFound] = React.useState<boolean>(false);
    const [open, setOpen] = React.useState<string | null>();
    const [bookerPaymentOpen, setBookerPaymentOpen] = React.useState<string | null>();
    const [paymentType, setPaymentType] = React.useState<string | ''>();
    const [paymentModelType, setPaymentModelType] = React.useState<string | 'payment'>();
    
    const [disabled, setDisabled] = React.useState<boolean>(false);

    const [message, setMessage] = React.useState<string>("");
    const [status, setStatus] = React.useState<string>("");

    const timeOut = useRef<any>()

    // Call Conversation API Again on Filters change (or) Page change and Every 4 sec
    React.useEffect(() => {
        timeOut.current = setTimeout(() => setListAll(!listAll), 30000);
        Services.eventRequest
            .listConversation({
                filter: {
                    date_to: Date.now(),
                    date_from: null,
                    event_request_id: eventRequestId,
                },
                page: 1,
                items_per_page: itemsPerPage,
            })
            .then((res) => {
                const filteredData = res.items.map((item: any) => {
                    return { ...item, sent: true };
                });
                if (filteredData.length === 0) {
                    setNoMoreFound(true);
                }
                console.log('filteredData ', filteredData)
                setData(filteredData.reverse());
                setPagination({ page: res.page, total_items: res.total_items });
                setLoading(false);
                setLoadOlderLoading(false);
            })
            .catch((err) => {
                // Failed to Fetch
                setError(err);
                setLoading(false);
                setLoadOlderLoading(false);
            });
        return () => clearTimeout(timeOut.current);
    }, [eventRequestId, itemsPerPage, listAll]);

    React.useEffect(() => {
        Services.eventRequest.getItemById(eventRequestId).then((res) => {
            console.log('Request ', res.item)
            setRequest(res.item);
        });
    }, [eventRequestId, getRequest, bandRequest.bandRequestSucceeded]);

    const handlePay = () => {
        setDisabled(true);
        setOpen(null);
        Services.eventRequest
            .changeStatus(eventRequestId, status)
            .then((res) => {
                setDisabled(false);
                setGetRequest(!getRequest)
                setPaymentModelType('success');
                //Amount Paid: $XXX
                setMessage(
                    `Congratulations on booking your gig with ${request.band.name}! Please pay the event deposit of $${(((Number(request.talent_budget) + (Number(request.production_budget))) + ((Number(request.talent_budget) + Number(request.production_budget)) * 0.13)) * (Number(request.band.deposit) / 100)).toFixed(2)} within 72 hours to avoid automatic event cancellation.`
                );
            })
            .catch((e) => {
                setDisabled(false);
                setMessage(e.message);
                setOpen("error");
                setPaymentModelType('error');
            });
    };

    if (loading) {
        return <div>laoding...</div>;
    }

    if (error) {
        return <div>error</div>;
    }

    const reply = (message: string) => {
        const lastID = Math.random();
        const replyObject: ReplyObject = {
            // Date.now() get timestamp in millisecondds, divide by 1000 to convert to seconds
            created_at: Date.now() / 1000,
            created_by: {
                id: authentication.id,
                email: authentication.email,
                fname: authentication.fname,
                lname: authentication.lname,
                photo: authentication.photo,
                role: authentication.role,
            },
            event_request_id: eventRequestId,
            id: lastID,
            message_text: message,
            message_type: null,
            parent_id: 0,
            request_band: {
                band: {
                    name: request.band.name,
                    photo: { thumbnail: request.band.photo?.thumbnail } || null,
                },
                event: {
                    created_by: {
                        fname: request.event.created_by.fname,
                        photo: { thumbnail: request.band.photo?.thumbnail } || null,
                    },
                },
            },
            sent: false,
        };
        setReplyData((prevState: Array<ReplyObject>) => {
            return [...prevState, replyObject];
        });
        // Call Reply API
        Services.eventRequest
            .reply({ event_request_id: eventRequestId, message_text: message })
            .then((res) => {
                setData((prevState: Array<IConversation | ReplyObject>) => {
                    return [
                        ...prevState,
                        {
                            ...res.item,
                            request_band: {
                                band: {
                                    name: request.band.name,
                                    photo: { thumbnail: request.band.photo?.thumbnail } || null,
                                },
                                event: {
                                    created_by: {
                                        fname: request.event.created_by.fname,
                                        photo: { thumbnail: request.band.photo?.thumbnail } || null,
                                    },
                                },
                            },
                        },
                    ];
                });
                const rest = replyData.filter((item) => item.id !== res.item.id);
                setReplyData(rest);
            })
            .catch((err) => {
                console.log("err", err);
            });
    };

    const viewReceiptHandle = (payType: string) => {

        Services.eventRequest
            .getReceipt(eventRequestId, payType)
            .then((res) => {
                console.log('res: ', res);
                if (res) {
                    if (res?.receipt?.receipt) {
                        console.log(res?.receipt?.receipt);
                        var matches = res?.receipt?.receipt.match(/\bhttps?:\/\/\S+/gi);
                        console.log('matches: ', matches);
                        (window as any).open(matches[0], '_blank').focus();
                    }
                }
            })
            .catch((err) => { console.log("err", err); });

    }

    const componentDecorator = (href: string, text: string, key: number) => (
        <a href={href} className={messageClass.message} key={key} target="_blank" rel="noopener noreferrer">
            {text}
        </a>
    );


    return (
        <>
            <Grid container direction="column" spacing={4}>
                <Grid item>
                    <Grid container alignItems="center" spacing={4}>
                        <Grid item xs={12}>
                            {request && (
                                <Typography
                                    style={{ display: "flex", cursor: "pointer" }}
                                    variant="subtitle1"
                                    noWrap
                                    onClick={() => setExpandDetails(!ExpandDetails)}>
                                    {request.event.title}
                                    {ExpandDetails ? <ArrowDropUpIcon color="secondary" /> : <ArrowDropDownIcon color="secondary" />}
                                </Typography>
                            )}
                            <Collapse in={ExpandDetails} timeout="auto" unmountOnExit>
                                <RequestView
                                    authentication={authentication}
                                    handleClose={handleClose}
                                    handleBandRequestSubmit={handleBandRequestSubmit}
                                    listBookerEventsList={listBookerEventsList}
                                    bandRequest={bandRequest}
                                    handleOpen={handleOpen}
                                    setSelectedEvent={setSelectedEvent}
                                    request={request}
                                />
                            </Collapse>
                        </Grid>
                        {data && data.length < pagination.total_items && (
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    disabled={noMoreFound || loadOlderLoading}
                                    // onClick change date_to field to the oldest message's created_at field
                                    onClick={() => {
                                        setLoadOlderLoading(true);
                                        setItemsPerPage((prevState) => prevState + 5);
                                    }}>
                                    Load Older Messages
                                </Button>
                            </Grid>
                        )}
                        {data.map((item: IConversation | ReplyObject) => {
                            return item.created_by.role === "REPSY" ? (
                                item.message_type === "PAYMENT_DECLINED" ? (
                                    <Grid item xs={11} key={item.id}>
                                        <Grid container alignItems="center" spacing={3} className={item.created_by.id === id ? classes.Layout : ""}>
                                            <Grid item xs={3} md={2} lg={2}>
                                                <Avatar alt="Other's thumbnail" src={IconGreen} className={messageClass.repsy} />
                                            </Grid>
                                            <Grid item xs={9} md={7} lg={7}>
                                                <Grid container direction="column" alignItems="stretch">
                                                    <Grid item>
                                                        <Typography variant="subtitle2">Repsy</Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container direction="column">
                                                            <Typography variant="subtitle2">
                                                                Event has been cancelled due to declined or missing payment.
                                                            </Typography>
                                                            <Typography variant="body1">
                                                                Please see our cancellation policy in the{" "}
                                                                <Link style={{ color: "#fff" }}
                                                                    to={
                                                                        authentication.role === "BOOKER"
                                                                            ? "/how-it-works/booker"
                                                                            : "/how-it-works/talent"
                                                                    }>
                                                                    FAQ section
                                                                </Link>{" "}
                                                                or <Link style={{ color: "#fff" }} to="/account/support">contact our support team</Link> with questions.
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Box
                                                p={2}
                                                display="flex"
                                                flexGrow={1}
                                                justifyContent={item.created_by.id === id ? "flex-start" : "flex-end"}>
                                                {item.sent ? moment.unix(item.created_at).fromNow() : <ErrorIcon />}
                                            </Box>
                                        </Grid>
                                    </Grid>
                                ) : item.message_type === "NO_ACTION" ? (
                                    <Grid item xs={11} key={item.id}>
                                        <Grid container alignItems="center" spacing={3} className={item.created_by.id === id ? classes.Layout : ""}>
                                            <Grid item xs={3} md={2} lg={2}>
                                                <Avatar alt="Other's thumbnail" src={IconGreen} className={messageClass.repsy} />
                                            </Grid>
                                            <Grid item xs={9} md={7} lg={7}>
                                                <Grid container direction="column" alignItems="stretch">
                                                    <Grid item>
                                                        <Typography variant="subtitle2">Repsy</Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container direction="column">
                                                            {item.message_text.split("$$").map((item, index) => {
                                                                if (index === 0) return <Typography variant="subtitle2">{item}</Typography>;
                                                                else return <Typography variant="body1">{item}</Typography>;
                                                            })}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Box
                                                p={2}
                                                display="flex"
                                                flexGrow={1}
                                                justifyContent={item.created_by.id === id ? "flex-start" : "flex-end"}>
                                                {item.sent ? moment.unix(item.created_at).fromNow() : <ErrorIcon />}
                                            </Box>
                                        </Grid>
                                    </Grid>
                                ) : authentication.role === "BOOKER" ? (
                                    <Grid item xs={12} sm={11} key={item.id} >
                                        <Grid container alignItems="center" spacing={3} className={`${item.created_by.id === id ? classes.Layout : ""}, ${request && (item.message_type === "PAY_DEPOSIT" || item.message_type === "PAY_REMAINDER") ? "conversation-pay-deposit" : ""}`} >
                                            <Grid xs={12}>
                                                <Grid container>
                                                    <Grid item xs={3} sm={3} md={2} lg={2}>
                                                        <Avatar alt="Other's thumbnail" src={IconGreen} className={messageClass.repsy} />
                                                    </Grid>
                                                    <Grid item xs={8} sm={8} md={9} lg={9}>
                                                        <Grid container direction="column" alignItems="stretch">
                                                            <Grid item>
                                                                <Typography variant="subtitle2" style={{ marginBottom: '15px' }}>Repsy</Typography>
                                                            </Grid>
                                                            <Grid item>
                                                                <Grid container direction="column">
                                                                    {request && (item.message_type === "PAY_DEPOSIT" || item.message_type === "PAY_REMAINDER") ?
                                                                        (<>
                                                                            {item.message_text.split("$$").map((item, index) => {
                                                                                if (index === 0) return <Typography variant="subtitle2" style={{ marginBottom: '15px' }}>{item}</Typography>;
                                                                                else return <Typography variant="body1" style={{ fontWeight: 200 }}>{item}</Typography>;
                                                                            })}


                                                                        </>) : (
                                                                            <>
                                                                                {item.message_text.split("$$").map((item, index) => {
                                                                                    if (index === 0) return <Typography variant="subtitle2">{item}</Typography>;
                                                                                    else return <Typography variant="body1">{item}</Typography>;
                                                                                })}
                                                                            </>
                                                                        )
                                                                    }
                                                                    {request &&
                                                                        request.status === "PENDING_FULL_PAYMENT" &&
                                                                        item.message_type === "PAY_FULL_PAYMENT" && (
                                                                            <Box width="100%" display="flex" justifyContent="flex-end">
                                                                                <Button
                                                                                    variant="contained"
                                                                                    color="secondary"
                                                                                    onClick={() => {
                                                                                        setOpen("confirme");
                                                                                        setStatus("APPROVED");
                                                                                        setMessage(
                                                                                            //`Are you sure you want to pay $${Number(request.talent_budget) + Number(request.production_budget)} to ${request.band.name}?`
                                                                                            `Are you sure you want to pay $${((Number(request.talent_budget) + Number(request.production_budget)) * 1.13).toFixed(2)} to ${request.band.name}?`
                                                                                        );
                                                                                    }}>
                                                                                    Pay Full Payment
                                                                                </Button>
                                                                            </Box>
                                                                        )}
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} sm={4} md={3} lg={3}>
                                                        <Box p={2} display="flex" flexGrow={1} justifyContent={item.created_by.id === id ? "flex-start" : "flex-end"}>
                                                            {item.sent ? moment.unix(item.created_at).fromNow() : <ErrorIcon />}
                                                        </Box>
                                                    </Grid>
                                                </Grid>


                                                {request && item.message_type === "PAY_DEPOSIT" &&
                                                    <Grid container >
                                                        <Grid xs={12}>
                                                            <Box width="100%" display="flex" justifyContent="flex-end">
                                                                {request.status === "PENDING_DEPOSIT" ?
                                                                    <Button
                                                                        size="small"
                                                                        variant="contained"
                                                                        color="secondary"
                                                                        style={{ padding: "3px 25px" }}
                                                                        onClick={() => {
                                                                            
                                                                            //setOpen("confirme");
                                                                            setBookerPaymentOpen("confirme");
                                                                            setStatus("PENDING_REMAINDER");
                                                                            setPaymentType('PENDING_DEPOSIT');
                                                                            setPaymentModelType('payment')
                                                                            setMessage(
                                                                                //`By clicking this button, you are paying this artist $${(((Number(request.talent_budget) + (Number(request.production_budget))) + ( (Number(request.talent_budget) + Number(request.production_budget)) * 0.13 )) * (Number(request.band.deposit) / 100) ).toFixed(2)}. You must pay the remainder of the fee at least 10 days before the event.  If payment is not received within that time, the event will be canceled, and you will lose your deposit.`
                                                                                `Congratulations on booking your gig with ${request.band.name}! Please pay the event deposit of $${(((Number(request.talent_budget) + (Number(request.production_budget))) + ((Number(request.talent_budget) + Number(request.production_budget)) * 0.13)) * (Number(request.band.deposit) / 100)).toFixed(2)} within 72 hours to avoid automatic event cancellation.`
                                                                            );
                                                                        }}>
                                                                        Pay Deposit
                                                                    </Button>
                                                                    :
                                                                    <Button
                                                                        size="small"
                                                                        variant="contained"
                                                                        color="secondary"
                                                                        style={{ padding: "3px 25px" }}
                                                                        onClick={() => {
                                                                            viewReceiptHandle('Deposit Payment')
                                                                        }}>
                                                                        View Receipt
                                                                    </Button>}
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                }


                                                {request && item.message_type === "PAY_REMAINDER" &&
                                                    <Grid container >
                                                        <Grid xs={12}>
                                                            <Box width="100%" display="flex" justifyContent="flex-end">
                                                                {request.status === "PENDING_REMAINDER" ?
                                                                    <Button
                                                                        size="small"
                                                                        variant="contained"
                                                                        color="secondary"
                                                                        style={{ padding: "3px 25px" }}
                                                                        onClick={() => {
                                                                            //setOpen("confirme");
                                                                            setPaymentModelType('payment')
                                                                            setBookerPaymentOpen("confirme");
                                                                            setPaymentType('PENDING_REMAINDER');
                                                                            setStatus("APPROVED");
                                                                            setMessage(
                                                                                `Are you sure you want to pay $${(((Number(request.talent_budget) + Number(request.production_budget)) -
                                                                                    (Number(request.band.deposit) / 100) * (Number(request.talent_budget) + Number(request.production_budget))) * 1.13).toFixed(2)} 
                                                                                                to ${request.band.name
                                                                                }?`
                                                                            );
                                                                        }}>
                                                                        Pay Event Remainder
                                                                    </Button>
                                                                    :
                                                                    <Button
                                                                        size="small"
                                                                        variant="contained"
                                                                        color="secondary"
                                                                        style={{ padding: "3px 25px" }}
                                                                        onClick={() => {
                                                                            viewReceiptHandle('Remainder Payment')
                                                                        }}>
                                                                        View Receipt
                                                                    </Button>}
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                }

                                                {/* {request &&
                                                    request.status === "PENDING_REMAINDER" &&
                                                    item.message_type === "PAY_REMAINDER" && (
                                                        <Box width="100%" display="flex" justifyContent="flex-end">
                                                            <Button
                                                                variant="contained"
                                                                color="secondary"
                                                                onClick={() => {
                                                                    setOpen("confirme");
                                                                    setStatus("APPROVED");
                                                                    setMessage(
                                                                        `Are you sure you want to pay $${(((Number(request.talent_budget) + Number(request.production_budget)) -
                                                                            (Number(request.band.deposit) / 100) * (Number(request.talent_budget) + Number(request.production_budget))) * 1.13).toFixed(2)} 
                                                                                        to ${request.band.name
                                                                        }?`
                                                                    );
                                                                }}>
                                                                Pay Event Remainder
                                                            </Button>
                                                        </Box>
                                                    )} */}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                ) : (
                                    <></>
                                )
                            ) : (
                                <Grid item xs={11} key={item.id}>
                                    <Grid container alignItems="center" spacing={3} className={item.created_by.id === id ? classes.Layout : ""}>
                                        {item.created_by.id === id ? (
                                            <>
                                                <Grid item xs={3} md={2} lg={2}>
                                                    <Avatar
                                                        alt="My thumbnail"
                                                        src={authentication.photo?.thumbnail || ImagePlaceholder}
                                                        className={authentication.role === "TALENT"
                                                            ? classes.TALENT
                                                            : authentication.role === "AGENT"
                                                                ? classes.AGENT
                                                                : classes.BOOKER}
                                                    />
                                                </Grid>
                                                <Grid item xs={9} md={7} lg={7}>
                                                    <Grid container direction="column" alignItems="stretch">
                                                        <Grid item>
                                                            <Typography variant="subtitle2">
                                                                {authentication.role === "BOOKER"
                                                                    ? item.request_band?.event.created_by.fname
                                                                    : authentication.role === "AGENT"
                                                                        ? `${item.created_by.fname} ${item.created_by.lname}  (Agent)`
                                                                        : item.request_band?.band.name}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            {item.message_text.split("\n").map((line, index) => {
                                                                return (
                                                                    <div key={index}>
                                                                        <Linkify key={index} componentDecorator={componentDecorator}>
                                                                            {line}
                                                                        </Linkify>
                                                                    </div>
                                                                );
                                                            })}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </>
                                        ) : (
                                            <>
                                                <Grid item xs={3} md={2} lg={2}>
                                                    <Avatar
                                                        alt="Other's thumbnail"
                                                        src={
                                                            item.created_by.role === "TALENT"
                                                                ? item.request_band?.band.photo?.thumbnail || ImagePlaceholder
                                                                : item.created_by.role === "BOOKER"
                                                                    ? item.request_band?.event.created_by?.photo?.thumbnail || ImagePlaceholder
                                                                    : item.created_by.role === "ADMIN"
                                                                        ? item.created_by.photo?.thumbnail || ImagePlaceholder
                                                                        : item.created_by.role === "AGENT"
                                                                            ? item.created_by.photo?.thumbnail || ImagePlaceholder
                                                                            : IconGreen
                                                        }
                                                        className={
                                                            item.created_by.role === "TALENT"
                                                                ? classes.TALENT
                                                                : item.created_by.role === "BOOKER"
                                                                    ? classes.BOOKER
                                                                    : item.created_by.role === "AGENT"
                                                                        ? classes.AGENT
                                                                        : item.created_by.role === "ADMIN"
                                                                            ? classes.ADMIN
                                                                            : messageClass.repsy
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item xs={9} md={7} lg={7}>
                                                    <Grid container direction="column" alignItems="stretch">
                                                        <Grid item>
                                                            <Typography variant="subtitle2">
                                                                {item.created_by.role === "TALENT"
                                                                    ? item.request_band?.band.name
                                                                    : item.created_by.role === "AGENT"
                                                                        ? `${item.created_by.fname} ${item.created_by.lname}  (Agent)`
                                                                        : item.created_by.role === "BOOKER"
                                                                            ? item.request_band?.event.created_by.fname
                                                                            : item.created_by.role === "ADMIN"
                                                                                ? item.created_by.fname + "(Admin)"
                                                                                : "Repsy"}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            {item.message_text.split("\n").map((line, index) => {
                                                                return (
                                                                    <div key={index}>
                                                                        <Linkify key={index} componentDecorator={componentDecorator}>
                                                                            {line}
                                                                        </Linkify>
                                                                    </div>
                                                                );
                                                            })}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </>
                                        )}
                                        <Box p={2} display="flex" flexGrow={1} justifyContent={item.created_by.id === id ? "flex-start" : "flex-end"}>
                                            {item.sent ? moment.unix(item.created_at).fromNow() : <ErrorIcon />}
                                        </Box>
                                    </Grid>
                                </Grid>
                            );
                        })}
                        {replyData.map((reply) => {
                            return (
                                <Grid item xs={11} key={reply.id}>
                                    <Grid container alignItems="center" spacing={3} className={classes.Layout}>
                                        <Grid item xs={3} md={2} lg={2}>
                                            <Avatar
                                                alt="My thumbnail"
                                                src={authentication.photo?.thumbnail || ImagePlaceholder}
                                                className={
                                                    authentication.role === "TALENT"
                                                        ? classes.TALENT
                                                        : authentication.role === "AGENT"
                                                            ? classes.AGENT
                                                            : classes.BOOKER}
                                            />
                                        </Grid>
                                        <Grid item xs={9} md={7} lg={7}>
                                            <Grid container direction="column" alignItems="stretch">
                                                <Grid item>
                                                    <Typography variant="subtitle2">
                                                        {authentication.role === "BOOKER"
                                                            ? reply.request_band?.event.created_by.fname
                                                            : authentication.role === "AGENT"
                                                                ? `${reply.created_by.fname} ${reply.created_by.lname}  (Agent)`
                                                                : reply.request_band?.band.name}
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    {reply.message_text.split("\n").map((line, index) => {
                                                        return (
                                                            <div key={index}>
                                                                <Linkify key={index} componentDecorator={componentDecorator}>
                                                                    {line}
                                                                </Linkify>
                                                            </div>
                                                        );
                                                    })}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Box
                                            p={2}
                                            display="flex"
                                            flexGrow={1}
                                            justifyContent={reply.created_by.id === id ? "flex-start" : "flex-end"}>
                                            {reply.sent ? moment.unix(reply.created_at).fromNow() : <ErrorIcon />}
                                        </Box>
                                    </Grid>
                                </Grid>
                            );
                        })}
                        <Grid item xs={3} md={2} lg={2}></Grid>
                        <Grid item xs={9} md={9} lg={9}>
                            <Divider variant="fullWidth" classes={{ root: classes.divider }} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <ReplyForm
                        authentication={authentication}
                        BandName={request?.band.name}
                        classes={classes}
                        buttonText="Reply"
                        sendMessage={reply}
                    />
                </Grid>
            </Grid>
            <PaymentConfirmation open={open === "confirme"} handlePay={handlePay} handleClose={() => setOpen(null)} message={message} />
            <PaymentConfirmation open={open === "error"} handlePay={() => setOpen(null)} handleClose={() => setOpen(null)} message={message} />

            
            {bookerPaymentOpen === 'confirme' && 
                <BookerPaymentModel disabled={disabled}  setDisabled={setDisabled} request={request} setModelType={setPaymentModelType} model_type={paymentModelType ? paymentModelType : ''} paymentType={paymentType ? paymentType : ''} open={bookerPaymentOpen === "confirme"} handlePay={handlePay} handleClose={() => setBookerPaymentOpen(null)} message={message} states={states} user={user} />
            }
        </>
    );
};
