import React, {useState, useEffect} from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogContent from "@material-ui/core/DialogContent";
import Services from "../../generic/Services";
import { Prompt, useParams, useHistory } from "react-router-dom";
import { SelectPaymentMethodItem } from "../BillingInformation/SelectPaymentMethod";
import Typography from "@material-ui/core/Typography";
import { AddNewCardForm } from "../BillingInformation/AddNewCardForm";

interface BillingInformationProps {
    open: boolean;
    message: string;
    handleClose: () => void;
    handlePay: () => void;
    user: any,
    states: any,
    paymentType: string;
    model_type: string;
    request: any;
    setModelType: any;
    disabled: boolean;
    setDisabled: any;
}

interface Address {
	street: string;
	city: string;
	zip: string;
	state: { id: number; title: string };
}

export interface PaymentMethodItem {
	id: number;
	username: string;
	address: Address;
	last_4_digits: number;
	isDefault: boolean;
	type: string;
	bank_name: string;
	brand: string;
	isVerified: string;
    exp_year: string,
    exp_month: string,
}

interface ListPaymentMethodsResponse {
	status: string;
	server_message: string;
	user_message: string;
	items: PaymentMethodItem[];
}

const BookerPaymentModel: React.FC<BillingInformationProps> = ({ open, message, handleClose, handlePay, user, states, paymentType, model_type, request, setModelType, disabled, setDisabled }) => {

    const [addPaymentMethod, setAddPaymentMethod] = useState<boolean>(false);
    const [error, setError] = useState<boolean>();
    const [data, setData] = useState<ListPaymentMethodsResponse>();
    const [toggleDefault, setToggleDefault] = useState<boolean>(false);
    const [dirtyCardForm, setDirtyCardForm] = React.useState<boolean>(false);
	const [dirtyAccForm, setDirtyAccForm] = React.useState<boolean>(false);
    const [deletePaymentMethod, setDeletePaymentMethod] = useState<boolean>(false);
    const [disable, setDisable] = useState<boolean>(false);
    const history = useHistory();


    const { id }  = useParams();

	// fuc that runs when default payment method changes and on componentWillMount
	useEffect(() => {
		if(!id){
			Services.paymentMethods
				.listPaymentMethods()
				.then((res: ListPaymentMethodsResponse) => {
					setData(res);
				})
				.catch((err: any) => {
					setError(err);
				});
		}
		else{
			Services.paymentMethods
				.listAgentBandPaymentMethods(id)
				.then((res: ListPaymentMethodsResponse) => {
					setData(res);
				})
				.catch((err: any) => {
					setError(err);
				});
		}
	}, [toggleDefault, addPaymentMethod, id]);

    const updateUserData = ()=>{
        console.log('updateUserData');
    }

    return (
        <Dialog
            fullWidth={true}
            maxWidth="sm"
            scroll="body"
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <div style={{ padding: 0 }} className="font-poppins dialog-title">
                
                {model_type === 'payment' &&<>       
                    {paymentType == 'PENDING_DEPOSIT' && 'Pay Deposit' }
                    {paymentType == 'PENDING_REMAINDER' && 'Pay Event Remainder' }
                </>}
                {model_type === 'success' &&<>       
                    Payment Confirmation
                </>}
                {model_type === 'error' &&<>       
                    Payment Unsuccessful
                </>}
                {model_type === 'addCard' &&<>       
                    Add Credit or Debit Card
                </>}
            </div>
            <DialogContent style={{padding: '15px 15px 0px 15px'}}>
                <DialogContentText color="textPrimary" id="alert-dialog-description">
                    {model_type === 'payment' ? 
                        <>
                        {message}
                        <SelectPaymentMethodItem
                                items={data?.items}
                                toggleDefault={toggleDefault}
                                setToggleDefault={setToggleDefault}
                                deletePaymentMethod={deletePaymentMethod}
                                setDeletePaymentMethod={setDeletePaymentMethod}
                                setModelType={setModelType}
                                disable={disable}
                                setDisable={setDisable}
                                count={data?.items?.length || 0}
                            />

                        
                    </> : model_type === 'addCard' ? <div >
                        <Prompt when={dirtyCardForm || dirtyAccForm} message={() => `Do you want to discard these changes?`} />
                            <AddNewCardForm 
                                addPaymentMethod={addPaymentMethod}
                                setAddPaymentMethod={setAddPaymentMethod}
                                dirtyForm={dirtyCardForm}
                                setDirtyForm={setDirtyCardForm}
                                states={states}
                                updateUserData={updateUserData}
                                setModelType={setModelType}
                            /> 
                            {/* <CardAccount
                                addPaymentMethod={addPaymentMethod}
                                setAddPaymentMethod={setAddPaymentMethod}
                                type="CARD"
                                dirtyForm={dirtyCardForm}
                                setDirtyForm={setDirtyCardForm}
                                states={states}
                                updateUserData={updateUserData}
                            /> */}
                    </div> : model_type === 'success' ? <div style={{textAlign: 'center'}} >
                        <Typography color="secondary" style={{fontWeight: 'bold', marginBottom: '15px'}}>
                            Amount Paid: {`$${(((Number(request.talent_budget) + (Number(request.production_budget))) + ((Number(request.talent_budget) + Number(request.production_budget)) * 0.13)) * (Number(request.band.deposit) / 100)).toFixed(2)}`}
                        </Typography>
                        <div>
                            Congratulations! You’re one step closer to your gig with {request?.band?.name}! Be on the look out for your next payment date. Thank you!
                        </div>

                    </div> : model_type === 'error' ? <> 
                        <div>
                            Unfortunately we were not able to process your payment method at this time. Please choose a different payment method or ensure your information is correct, and try again.
                        </div>

                    </> : <>
                    
                    </>
                    }
                    
                </DialogContentText>
            </DialogContent>
            <DialogActions style={{justifyContent: 'center', display: 'block', textAlign: 'center'}}>
            {model_type === 'payment' ? 
                <>
                <Button 
                disabled={disabled}
                size="small"
                variant="contained"
                color="secondary"
                style={{ padding: "7px 30px" }}
                onClick={handlePay} autoFocus>
                {paymentType == 'PENDING_DEPOSIT' && 'Pay Deposit' }
                {paymentType == 'PENDING_REMAINDER' && 'Pay Event Remainder' }
                </Button>
                <br/>
                <Button onClick={handleClose} color="default" autoFocus style={{marginLeft: 0, fontWeight: 200, fontSize: '12px', margin: '10px 0px'}}>
                    <u>Cancel</u>
                </Button>
                </>
                : model_type === 'success' ? <> 
                    <Button onClick={handleClose} color="default" autoFocus style={{marginLeft: 0, fontWeight: 200, fontSize: '12px', margin: '10px 0px'}}>
                        <u>Close Window</u>
                    </Button>
                </>
                : model_type === 'error' ? 
                <>
                    <Button 
                        size="small"
                        variant="contained"
                        color="secondary"
                        style={{ padding: "7px 30px" }}
                        onClick={handleClose} autoFocus>
                            Choose Different Payment Menthod
                        </Button>
                    <br/>
                    <Button onClick={(e: any) => {history.push("/contact"); handleClose()}} color="default" autoFocus style={{marginLeft: 0, fontWeight: 200, fontSize: '12px', margin: '10px 0px'}}>
                        <u>Contact Us</u>
                    </Button>
                    <Button onClick={handleClose} color="default" autoFocus style={{marginLeft: 0, fontWeight: 200, fontSize: '12px', margin: '10px 0px'}}>
                        <u>Close Window</u>
                    </Button>
                </>
                : <></>
            }
            </DialogActions>
        </Dialog>
    );
};

export default BookerPaymentModel;
