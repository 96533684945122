import React from "react";
import { connect } from "react-redux";
import Container from '@material-ui/core/Container';
import Box from "@material-ui/core/Box";
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";
import Instagram from "@material-ui/icons/Instagram";
import Facebook from "@material-ui/icons/Facebook";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import makeStyles from "@material-ui/core/styles/makeStyles";
import createStyles from "@material-ui/core/styles/createStyles";
import { setPage } from "../../HomePage/homePageSlice";
import { ThunkDispatch } from "redux-thunk";
import Services from "../../generic/Services";
import TranumPhoto from "../../Assets/tranum.jpg";
import { AboutMeta } from "../../Assets/metadata.json";
import { MetadataTags } from '../../generic/components/MetadataTags/metadata-tags'

const useStyles = makeStyles((theme) =>
	createStyles({
		title: {
			color: theme.palette.secondary.main,
			fontSize: "48px",
		},
		subtitle: {
			fontStyle: "italic",
			fontSize: "32px",
		},
		body: {
			fontSize: "20px",
			color: theme.palette.grey[400],
			textAlign: "left",
		},
		margin: {
			marginBottom: theme.spacing(4),
		},
		responsive: {
			"@media only screen and (max-width: 900px)": {
					width: "100%",
			},
	},
	}),
);

interface AboutPageProps {
	setPageBg: (page: string) => void;
}

interface AboutObject {
	id: number;
	page_name: string;
	title: string;
	subtitle: string;
	body: string;
	created_by: number;
}

const AboutPage: React.FC<AboutPageProps> = ({ setPageBg }) => {
	const classes = useStyles();
	const [data, setData] = React.useState<AboutObject[]>([]);
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.up("sm"));

	React.useEffect(() => {
		setPageBg("about");
		Services.termsAndConditions
			.aboutPage({})
			.then((res: any) => {
				setData(res.items);
			})
			.catch((err: any) => {
				console.log("err: ", err);
			});
	}, [setPageBg]);

	return (
		<>
		    <MetadataTags 
                title={AboutMeta?.title}
                description={AboutMeta?.description}        
                image={AboutMeta?.image}              
            />
			<Box
				mt="4em"
				mb="4em"
				display="flex"
				justifyContent="center"
				alignItems="center"
				flexDirection="row"
				fontFamily="Rubik"
				fontSize={72}
				fontWeight={800}>
				ABOUT
			</Box>
			<Box display="flex" flexDirection="column">
				{data.length > 0 && (
					<Box width={matches ? "45%" : "100%"} margin="auto" textAlign="center">
						<Typography className={`${classes.margin} ${classes.title}`} variant="h4">
							{/*{data[0].title.toUpperCase()}*/}
							BRAINS BEHIND THE BRAND
						</Typography>
						<Typography className={`${classes.margin} ${classes.subtitle}`} variant="subtitle2">
							{/*{data[0].subtitle}*/}
							We thought: "There's got to be a better way to book bands and talent." So we created it.
						</Typography>
						<p className={`${classes.margin} ${classes.body}`}>
							{/*{data[0].body}*/}
							{/* Repsy is a cloud-based platform dedicated to connecting artists and buyers for unforgettable live music experiences. */}
						</p>
						<Box>
							<a href="https://www.instagram.com/repsylive/" rel="noopener noreferrer" target="_blank">
								<Instagram color="secondary" />
							</a>
							<a href="https://www.facebook.com/gorepsy/" rel="noopener noreferrer" target="_blank">
								<Facebook color="secondary" />
							</a>
						</Box>
						<Box mt="100px">
							<div style={{padding:"56.25% 0 0 0", position:"relative"}}>
								<iframe src="https://www.youtube.com/embed/HioRzekwqgs"  allow="autoplay; fullscreen; picture-in-picture" 
								 style={{position:"absolute",top:0,left:0,width:"100%",height:"100%"}} title="Homepage Video (rough)">
								</iframe>
							</div>
							</Box>
					</Box>
				)}

			</Box>
			<Box mt="100px">
				<Container>
					<Grid container direction="row" justify="space-around" alignItems="center" spacing={10}>
						<Grid item xs={12} md={6}>
							<img src={TranumPhoto} width="95%" alt="image" />
						</Grid>
						<Grid item xs={12} md={6}>
							<Typography className={`${classes.margin} ${classes.title}`} variant="h4">
								TRANUM FITZPATRICK
							</Typography>
							<p className={`${classes.margin} ${classes.body}`}>
							Tranum got his start in the music world in college, when he booked live acts of all levels for many different events across campus. During this time, he learned the ins and outs of the live industry, and the inefficiencies that come with it. With this in mind, he made it his goal to change and innovate the way things were done, and the result of this endeavor is Repsy. Since then, Repsy has grown and progressed, and is set to be the new way to book artists and live entertainment.</p>
						</Grid>
					</Grid>
				</Container>
			</Box>
		</>
	);
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
	setPageBg: (page: string) => {
		dispatch(setPage(page));
	},
});

export default connect(null, mapDispatchToProps)(AboutPage);
