import React, { useEffect } from "react";
import { Avatar, Box, Button, Dialog, Grid, OutlinedInput, Typography } from "@material-ui/core";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { RouteComponentProps } from "react-router-dom";
import Services from "../../generic/Services";
import { CustomDialogTitle } from "../../generic/components";
import { InviteAgentDialog } from "../../generic/components/InviteAgentDialog/invite-agent-dialog";
import { RemoveAgentDialog } from "../../generic/components/RemoveAgentDialog/remove-agent-dialog";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		large: {
			width: theme.spacing(15),
			height: theme.spacing(15),
			marginRight: "35px",
			'& img': {
				borderRadius: "70px",
				border: "4px solid rgb(255, 255, 255)",
			},
		},
		themeBtn: {
			fontWeight: 500,
			fontSize: "0.9rem",
		},
		paragraphFont: {
			fontSize: "15px",
			color: "#ddd",
		},
		
		Link: {
			display: "flex",
			alignItems: "center",
			color: "#0F24FD",
			backgroundColor: "#64FECC",
			padding: "5px 20px",
			fontSize: "1rem",
			fontFamily: "Rubik",
			fontWeight: "bold",
			borderRadius: "4px",
			textDecoration: "none",
			margin: 10,
			height: 35,
			"&:hover": {
				backgroundColor: "#64FECC"
			},
		},
	}),
);

export interface TalentsAgentProps {
	talentsAgent?: any;
	setLoc: (location: string) => void;
	listTalentsAgent: () => void;
}

export const TalentsAgent: React.FC<TalentsAgentProps &
	RouteComponentProps> = ({
	setLoc,
}) => {
	const classes = useStyles();
	const [data, setData] = React.useState<any>();
	
	const [inviteDialogOpen, setinviteDialogOpen] = React.useState(false);
	const [inviteEmail, setInviteEmail] = React.useState('');
    const [removebandDialogOpen, setremovebandDialogOpen] = React.useState(false);

	useEffect(() => {
		setLoc("/account/my-agent");
		Services.agent.listTalentsAgent().then(response => {
			console.log(response);
			setData(response.item)
		})
	}, [setLoc]);

	const closeRemovebandDialog = () => {
        setremovebandDialogOpen(false);
    }
	
	return (
		<>
		{!data && (
			<InviteAgentDialog />
		)}
			{data ? (
				<>
				<Grid container>
					<Grid item xs={12} style={{ padding: "10px 0px" }}>
						<Grid container direction="row" justify="center" alignItems="center">
							<Grid item xs={12} style={{ padding: "10px 0px", marginBottom: "25px" }} direction="row" justify="center" alignItems="center">
								<Box component="div" display="flex" alignItems="center" justifyContent="flex-start">
									<Avatar alt={data.agent.fname} src={data.agent.photo && data.agent.photo.thumbnail} className={classes.large} style={{ marginLeft: "45px" }} />
									<Typography variant="subtitle2" classes={{ root: classes.gutters }}>
										<strong style={{ fontWeight: "bold", textTransform: "uppercase", fontSize: "30px" }}>{data.agent.fname} {data.agent.lname}</strong><br></br>
										<Button variant="text"  color="secondary" className={classes.newbandicon} style={{ padding: 0, backgroundColor: "rgba(100, 254, 204, 0.0)", color: "#FF0200", fontWeight: 500, fontSize: "16px" }}
										onClick={() => {
											setremovebandDialogOpen(true);
										}}>
											<small><span>Remove Agent</span></small>
										</Button>
									</Typography>
									{removebandDialogOpen &&
											<RemoveAgentDialog closeRemoveagentDialog={closeRemovebandDialog} showRemoveagentDialog={removebandDialogOpen} agentData={data.agent}  />
										}
								</Box>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				<Grid container direction="row" justify="space-between" alignItems="center">
					<Grid item xs={3} style={{ padding: "6px 0px" }}>
						<Typography variant="body1" style={{ fontWeight: "bold", textAlign: "right", paddingRight: "35px" }}>First Name</Typography>
					</Grid>
					<Grid xs={9} style={{ padding: "6px 0px" }}>
						<Typography variant="body1">{data.agent.fname}</Typography>
					</Grid>
					<Grid item xs={3} style={{ padding: "6px 0px" }}>
						<Typography variant="body1" style={{ fontWeight: "bold", textAlign: "right", paddingRight: "35px" }}>Last Name</Typography>
					</Grid>
					<Grid xs={9} style={{ padding: "6px 0px" }}>
						<Typography variant="body1">{data.agent.lname}</Typography>
					</Grid>
					<Grid item xs={3} style={{ padding: "6px 0px" }}>
						<Typography variant="body1" style={{ fontWeight: "bold", textAlign: "right", paddingRight: "35px" }}>Phone Number</Typography>
					</Grid>
					<Grid xs={9} style={{ padding: "6px 0px" }}>
						<Typography variant="body1">{data.agent.phone_number}</Typography>
					</Grid>
					<Grid item xs={3} style={{ padding: "6px 0px" }}>
						<Typography variant="body1" style={{ fontWeight: "bold", textAlign: "right", paddingRight: "35px" }}>Agency (Optional)</Typography>
					</Grid>
					<Grid xs={9} style={{ padding: "6px 0px" }}>
						<Typography variant="body1">{data.agent.organization_name}</Typography>
					</Grid>
					{/* <Grid item xs={12} style={{ padding: "10px 0px", textAlign: "right" }}>
						<Button color="secondary" variant="contained" className={classes.themeBtn} style={{ padding: "6px 20px", marginLeft: "12px" }}>Send Message</Button>
					</Grid> */}
				</Grid>
				</>
			) : <> </>}
		</>
	);
};
