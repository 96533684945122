import { refineResponse } from "../helpers/refineResponse";
import { headers, baseUrl } from "../../AuthBase/helpers/auth-header";

export const list = () => {
	const requestOptions = {
		method: "GET",
		headers: { ...headers() },
	};

	return fetch(`${baseUrl}users/list-notification`, requestOptions).then(refineResponse);
};
