import { IError } from "../Base/ErrorMessageBase";

export enum AuthActionType {
	REGISTER_REQUEST = "REGISTER_REQUEST",
	REGISTER_SUCCESS = "REGISTER_SUCCESS",
	REGISTER_FAILURE = "REGISTER_FAILURE",
	LOGIN_REQUEST = "LOGIN_REQUEST",
	LOGIN_SUCCESS = "LOGIN_SUCCESS",
	LOGIN_FAILURE = "LOGIN_FAILURE",
	RESEND_CONFIRMATION_REQUEST = "RESEND_CONFIRMATION_REQUEST",
	RESEND_CONFIRMATION_SUCCESS = "RESEND_CONFIRMATION_SUCCESS",
	RESEND_CONFIRMATION_FAILURE = "RESEND_CONFIRMATION_FAILURE",
	LOGOUT = "LOGOUT",
	EDIT_USER = "EDIT_USER",
	FIRST_LOGIN_POPUP_OPEN = "FIRST_LOGIN_POPUP_OPEN",
	FIRST_LOGIN_POPUP_CLOSE = "FIRST_LOGIN_POPUP_CLOSE",
	UPDATE_USER = "UPDATE_USER",
	UPDATE_TALENT_STATUSES = "UPDATE_TALENT_STATUSES"
}

export interface IAuthReducer {
	isLoggingIn: boolean;
	isLoggedIn: boolean;
	user: any;
	error?: IError;
	firstLogIn: boolean;
}

export interface IAuthAction {
	type: AuthActionType;
	payload: any;
}

export interface IRegistrationAction {
	type: AuthActionType;
	payload: any;
}

export interface IRegistrationReducer {
	isRegistering: boolean;
	user: any;
	error: IError;
}
