import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Divider from "@material-ui/core/Divider";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import YouTube from "@material-ui/icons/YouTube";
import Facebook from "@material-ui/icons/Facebook";
import Instagram from "@material-ui/icons/Instagram";
import Hidden from "@material-ui/core/Hidden";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Formik, FormikProps } from "formik";
import React, { useState, useCallback, useEffect } from "react";
import { Prompt } from "react-router";
import { ReactComponent as Soundcloud } from "../../Assets/soundcloud.svg";
import { ReactComponent as Spotify } from "../../Assets/spotify.svg";
import ErrorMessage from "../../generic/ErrorMessage";
import { IResourcesReducer } from "../../generic/Resources/interfaces";
import { uploadFile, uploadMedia, uploadProductFile } from "../../generic/Services/bands";
import HeaderDropzone from "../../ImageUploader/header-uploader";
import HospitalityDropzone from "../../ImageUploader/hospitality-Production-uploader";
import Dropzone from "../../ImageUploader/image-uploader";
import { TalentProfileObject } from "./editTalentProfileSlice";
import { TalentState } from "./editTalentProfileSlice";

import  ImageCropperDialog from "../../generic/components/ImageCropperDialog/index";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		textAlign: {
			paddingTop: theme.spacing(1),
			textAlign: "end",
		},
		svgAlign: {
			float: "right",
		},
		dropDownPlaceholder: {
			color: "#63637B",
			fontWeight: 600,
		},
		textFieldWidth: {
			maxWidth: "100px",
			marginRight: "1.3rem",
			"@media screen and (max-width: 1280px)": {
				marginRight: "1.5rem",
			},
			"@media screen and (max-width: 1024px)": {
				marginRight: "1.5rem",
			},
			"@media screen and (max-width: 960px)": {
				marginRight: "1.5rem",
			},
			"@media screen and (max-width: 767px)": {
				marginRight: "6% !important",
				maxWidth: "27%",
			},
		},
	}),
);

export interface MyFormValues extends TalentProfileObject {
	phone_number: string;
	phone_number1: string;
	phone_number2: string;
	phone_number3: string;
	spotify: string;
	youtube: string;
	facebook: string;
	instagram: string;
	soundcloud: string;
	featured_song_name_1: string;
	featured_song_link_1: string;
	featured_song_name_2: string;
	featured_song_link_2: string;
}

export interface TalentProfileFormProps {
	initialValues: any;
	validationSchema: any;
	resources: IResourcesReducer;
	talentProfile: TalentState;
	handleOnSubmit: (talentProfile: any, id: number) => void;
}

function getFileExtension(filename:any) {
	return filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2);
}

export const TalentProfileForm: React.FC<TalentProfileFormProps> = ({
	talentProfile,
	initialValues,
	validationSchema,
	handleOnSubmit,
	resources,
}) => {

	const classes = useStyles();
	const [imageSrc, setImageSrc] = React.useState('');

	useEffect(() => {
		// action on update of movies
	}, [imageSrc]);

	const inputRef1 = React.useRef<HTMLDivElement>(null);
	const inputRef2 = React.useRef<HTMLDivElement>(null);
	const inputRef3 = React.useRef<HTMLDivElement>(null);

	// handle uploading files on edit/add
	const handleSubmitForm = async (values: any) => {
		if (values.hospitality_and_production_rider !== null && typeof values.hospitality_and_production_rider.lastModified !== "undefined") {
			const promise = await Promise.all([uploadProductFile(values.hospitality_and_production_rider)]);
			const [production] = promise;
			await production.json().then((res: any) => {
				values.hospitality_and_production_rider = res.item;
			});
		}

		if (values.header_photo !== null && typeof values.header_photo.lastModified !== "undefined") {
			const promise = await Promise.all([uploadMedia(values.header_photo, undefined, 'header')]);
			const [header] = promise;
			await header.json().then((res: any) => {
				values.header_photo = res.item;
			});
		}

		if (values.header_photo_original !== null && values.header_photo_original !== undefined ) {
			const promise = await Promise.all([uploadMedia(values.header_photo_original, undefined, 'header')]);
			const [header] = promise;
			await header.json().then((res: any) => {
				values.header_photo_original = res.item;
			});
		}

		
		if (values.photo !== null && typeof values.photo.lastModified !== "undefined") {
			
			const promise = await Promise.all([uploadMedia(values.photo, undefined, 'profile')]);
			const [profile] = promise;
			await profile.json().then((res: any) => {
				values.photo = res.item;
				let user = JSON.parse(localStorage.getItem("user") || "");
				user = { ...user, photo: res.item, status: res.item.status };
				user = JSON.stringify(user);
				localStorage.setItem("user", user);
			});
		}
	};

	// handle changing social links objects in social_links array
	const handleSocialLinksArray = (values: any) => {
		const socialArray: Array<{ type: string; url: string }> = [];
		if (values.spotify !== "") {
			socialArray.push({ type: "SPOTIFY", url: values.spotify });
		}
		if (values.youtube !== "") {
			socialArray.push({ type: "YOUTUBE", url: values.youtube });
		}
		if (values.facebook !== "") {
			socialArray.push({ type: "FACEBOOK", url: values.facebook });
		}
		if (values.instagram !== "") {
			socialArray.push({ type: "INSTAGRAM", url: values.instagram });
		}
		if (values.soundcloud !== "") {
			socialArray.push({ type: "SOUNDCLOUD", url: values.soundcloud });
		}
		return socialArray;
	};

	// handle changing featured_songs objects in featured_songs array
	const handleFeaturedSongsArray = (values: any) => {
		const featuredArray: Array<{ name: string; url: string }> = [];
		if (values.featured_song_name_1 && values.featured_song_link_1) {
			featuredArray.push({
				name: values.featured_song_name_1,
				url: values.featured_song_link_1,
			});
		}
		if (values.featured_song_name_2 && values.featured_song_link_2) {
			featuredArray.push({
				name: values.featured_song_name_2,
				url: values.featured_song_link_2,
			});
		}
		return featuredArray;
	};

	return (
		<Box>
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={async (values, actions) => {
					values.phone_number = values.phone_number1.concat(
						values.phone_number2,
						values.phone_number3,
					);
					values.social_links = handleSocialLinksArray(values);
					values.featured_songs = handleFeaturedSongsArray(values);

					// this is to validate that both song and name for both featured
					// song fields(1 & 2) are either both full or both empty
					if (
						(!(values.featured_song_name_2 && values.featured_song_link_2) &&
							!(!values.featured_song_name_2 && !values.featured_song_link_2)) ||
						(!(values.featured_song_name_1 && values.featured_song_link_1) &&
							!(!values.featured_song_name_1 && !values.featured_song_link_1))
					) {
						if (
							!(values.featured_song_name_2 && values.featured_song_link_2) &&
							!(!values.featured_song_name_2 && !values.featured_song_link_2)
						) {
							if (values.featured_song_name_2) {
								actions.setSubmitting(false);
								actions.setFieldError("featured_song_link_2", "field is required");
							} else if (values.featured_song_link_2) {
								actions.setSubmitting(false);
								actions.setFieldError("featured_song_name_2", "field is required");
							}
						}
						if (
							!(values.featured_song_name_1 && values.featured_song_link_1) &&
							!(!values.featured_song_name_1 && !values.featured_song_link_1)
						) {
							if (values.featured_song_name_1) {
								actions.setSubmitting(false);
								actions.setFieldError("featured_song_link_1", "field is required");
							} else if (values.featured_song_link_1) {
								actions.setSubmitting(false);
								actions.setFieldError("featured_song_name_1", "field is required");
							}
						}
					} else {
						await handleSubmitForm(values);
						handleOnSubmit(values, values.id);
					}
					actions.setSubmitting(false);
				}}
				render={(formikBag: FormikProps<MyFormValues>) => {
					//  to block user from lossing data by refreshning page */
					window.addEventListener("beforeunload", (event) => {
						if (formikBag.dirty) {
							event.returnValue = `Are you sure you want to leave?`;
						}
					});

					const isGenreChecked = (id: number) => {
						if (formikBag.values.genres) {
							return formikBag.values.genres.filter((item: any) => item.id === id).length ? true : false;
						}
					};

					const isGigsChecked = (id: number) => {
						if (formikBag.values.preferred_gig_types) {
							return formikBag.values.preferred_gig_types.filter((item: any) => item.id === id).length
								? true
								: false;
						}
					};

					const isStateChecked = (id: number) => {
						if (formikBag.values.willing_to_travel) {
							return formikBag.values.willing_to_travel.filter((item: any) => item.id === id).length
								? true
								: false;
						}
					};

					const handleGenreChange = (e: any) => {
						const { value, checked } = e.target;
						let updatedStates = [];
						const id = Number(value);
						updatedStates = checked
							? [...formikBag.values.genres, { id: Number(value) }]
							: formikBag.values.genres.filter((item: any) => Number(item.id) !== id);
						formikBag.setFieldValue("genres", updatedStates);
						formikBag.setFieldTouched("genres", true, false);
					};

					const handleGigsChange = (e: any) => {
						const { value, checked } = e.target;
						let updatedStates = [];
						const id = Number(value);
						updatedStates = checked
							? [...formikBag.values.preferred_gig_types, { id: Number(value) }]
							: formikBag.values.preferred_gig_types.filter((item: any) => Number(item.id) !== id);
						formikBag.setFieldValue("preferred_gig_types", updatedStates);
						formikBag.setFieldTouched("preferred_gig_types", true, false);
					};

					const handleStatesChange = (e: any) => {
						const { value, checked } = e.target;
						let updatedStates = [];
						const id = Number(value);
						updatedStates = checked
							? [...formikBag.values.willing_to_travel, { id: Number(value) }]
							: formikBag.values.willing_to_travel.filter((item: any) => Number(item.id) !== id);
						formikBag.setFieldValue("willing_to_travel", updatedStates);
						formikBag.setFieldTouched("willing_to_travel", true, false);
					};

					const headerFileCallback = (cropperData: any) => {
						formikBag.setFieldValue("header_photo", cropperData.croppedFile);
						formikBag.setFieldValue("header_photo_original", cropperData.originalSelectedFile);
					}

					

					return (
						<form onSubmit={formikBag.handleSubmit}>
							{/* to block user from lossing data by going back or changing url */}
							<Prompt
								when={formikBag.dirty}
								message={(location) => `Do you want to discard these changes?`}
							/>
							<Grid container direction="row" alignItems="flex-start" justify="space-evenly" spacing={2} style={{marginTop: "5%"}}>
								{/* <Grid item xs={12} md={12} lg={12}>
									<Dropzone
										url={formikBag.values.photo && formikBag.values.photo.url}
										handleChange={(file: any) => {
											formikBag.setFieldValue("photo", file);
										}}
										inputContent=""
										btnText="Change Profile Picture"
										title={formikBag.values.name}
									/>
								</Grid> */}
								<Grid item xs={4} md={4} lg={4}>
									<InputLabel className={classes.textAlign} htmlFor="name">
										Band Name *
									</InputLabel>
								</Grid>
								<Grid item xs={8} md={8} lg={8}>
									<TextField
										placeholder="Band Name"
										autoComplete="off"
										id="name"
										name="name"
										variant="outlined"
										color="secondary"
										helperText={formikBag.touched.name ? formikBag.errors.name : ""}
										error={formikBag.touched.name && Boolean(formikBag.errors.name)}
										fullWidth={true}
										value={formikBag.values.name}
										onChange={(e: any) => {
											e.persist();
											formikBag.setFieldValue("name", e.target.value);
											formikBag.setFieldTouched("name", true, false);
										}}
									/>
								</Grid>
								<Grid item xs={12} md={12} lg={12}>
									<Grid container spacing={3} alignItems="flex-start">
										<Grid item xs={4} md={4} lg={4}>
											<InputLabel className={classes.textAlign} htmlFor="location">
												Location *
											</InputLabel>
										</Grid>
										<Grid item xs={4} md={4} lg={4}>
											<TextField
												placeholder="Location"
												autoComplete="off"
												id="location"
												name="location"
												variant="outlined"
												color="secondary"
												helperText={formikBag.touched.location ? formikBag.errors.location : ""}
												error={formikBag.touched.location && Boolean(formikBag.errors.location)}
												fullWidth={true}
												value={formikBag.values.location}
												onChange={(e: any) => {
													e.persist();
													formikBag.setFieldValue("location", e.target.value);
													formikBag.setFieldTouched("location", true, false);
												}}
											/>
										</Grid>
										<Grid item xs={4} md={4} lg={4}>
											<Select
												className={formikBag.values.state ? "" : classes.dropDownPlaceholder}
												value={
													formikBag.values.state && formikBag.values.state.id
														? formikBag.values.state.id
														: 99
												}
												fullWidth={true}
												id="state"
												name="state"
												variant="outlined"
												color="secondary"
												error={formikBag.touched.state && Boolean(formikBag.errors.state)}
												MenuProps={{
													getContentAnchorEl: null,
													anchorOrigin: {
														vertical: "bottom",
														horizontal: "left",
													},
													transformOrigin: {
														vertical: "top",
														horizontal: "left",
													},
												}}
												onChange={(e: any) => {
													e.persist();
													formikBag.setFieldValue("state", { id: e.target.value });
													formikBag.setFieldTouched("state", true, false);
												}}>
												<MenuItem disabled value={99}>
													State
												</MenuItem>
												{resources.states.allIds.map((item: any) => (
													<MenuItem key={item} value={item}>
														{resources.states.items[item].title}
													</MenuItem>
												))}
											</Select>
											{formikBag.errors.state && formikBag.touched.state && (
												<FormHelperText error variant="outlined">
													State is a required field
												</FormHelperText>
											)}
										</Grid>
									</Grid>
								</Grid>
								<Grid
									container
									item
									direction="row"
									alignItems="center"
									spacing={4}
								>
									<Grid item xs={4} md={4} lg={4}>
										<InputLabel
											className={classes.textAlign}
											htmlFor="phone_number"
										>
											Phone Number *
										</InputLabel>
									</Grid>
									<Grid item xs={8} md={8} lg={8}>
										<TextField
											placeholder="Area"
											className={classes.textFieldWidth}
											autoComplete="off"
											id="phone_number1"
											name="phone_number1"
											value={formikBag.values.phone_number1}
											helperText={
												formikBag.touched.phone_number1
													? formikBag.errors.phone_number1
													: ""
											}
											error={
												formikBag.touched.phone_number1 &&
												Boolean(
													formikBag.errors.phone_number1,
												)
											}
											variant="outlined"
											color="secondary"
											margin="dense"
											inputRef={inputRef1}
											inputProps={{
												maxLength: 3,
												style: { textAlign: "center" },
											}}
											onChange={(e: any) => {
												e.persist();
												if (
													e.target.value.length === 3 &&
													inputRef2 &&
													inputRef2.current
												) {
													inputRef2.current.focus();
												}
												formikBag.setFieldValue(
													"phone_number1",
													e.target.value,
												);
												formikBag.setFieldTouched(
													"phone_number1",
													true,
													false,
												);
											}}
										/>
										<TextField
											placeholder="Exchange"
											className={classes.textFieldWidth}
											autoComplete="off"
											id="phone_number2"
											name="phone_number2"
											error={
												formikBag.touched.phone_number2 &&
												Boolean(
													formikBag.errors.phone_number2,
												)
											}
											helperText={
												formikBag.touched.phone_number2
													? formikBag.errors.phone_number2
													: ""
											}
											value={formikBag.values.phone_number2}
											variant="outlined"
											color="secondary"
											inputProps={{
												maxLength: 3,
												style: { textAlign: "center" },
											}}
											inputRef={inputRef2}
											margin="dense"
											onChange={(e: any) => {
												e.persist();
												if (
													e.target.value.length === 3 &&
													inputRef3 &&
													inputRef3.current
												) {
													inputRef3.current.focus();
												}
												formikBag.setFieldValue(
													"phone_number2",
													e.target.value,
												);
												formikBag.setFieldTouched(
													"phone_number2",
													true,
													false,
												);
											}}
										/>
										<TextField
											placeholder="Number"
											className={classes.textFieldWidth}
											autoComplete="off"
											id="phone_number3"
											name="phone_number3"
											error={
												formikBag.touched.phone_number3 &&
												Boolean(
													formikBag.errors.phone_number3,
												)
											}
											helperText={
												formikBag.touched.phone_number3
													? formikBag.errors.phone_number3
													: ""
											}
											value={formikBag.values.phone_number3}
											variant="outlined"
											color="secondary"
											inputProps={{
												maxLength: 4,
												style: { textAlign: "center" },
											}}
											margin="dense"
											inputRef={inputRef3}
											onChange={(e: any) => {
												if (
													e.target.value.length === 1 &&
													inputRef2 &&
													inputRef2.current &&
													e.key === "Backspace"
												) {
													inputRef2.current.focus();
												}
												formikBag.setFieldValue(
													"phone_number3",
													e.target.value,
												);
												formikBag.setFieldTouched(
													"phone_number3",
													true,
													false,
												);
											}}
										/>
									</Grid>
								</Grid>
								<Grid item xs={12} md={12} lg={12}>
									<Grid container spacing={4} alignItems="flex-start">
										<Grid item xs={4} md={4} lg={4}>
											<InputLabel className={classes.textAlign} htmlFor="price_from">
												Price Range *
											</InputLabel>
										</Grid>
										<Grid item xs={3} md={2} lg={2}>
											<TextField
												placeholder="Min"
												autoComplete="off"
												id="price_from"
												name="price_from"
												variant="outlined"
												color="secondary"
												helperText={
													formikBag.touched.price_from ? formikBag.errors.price_from : ""
												}
												error={
													formikBag.touched.price_from && Boolean(formikBag.errors.price_from)
												}
												value={formikBag.values.price_from || ""}
												onChange={(e: any) => {
													e.persist();
													formikBag.setFieldValue("price_from", e.target.value);
													formikBag.setFieldTouched("price_from", true, false);
												}}
											/>
										</Grid>
										<Grid item>
											<Hidden xsDown>
												<Divider
													style={{
														width: "2em",
														margin: "25px 10px",
														height: "2px",
													}}
													color="secondary"
												/>
											</Hidden>
										</Grid>
										<Grid item xs={3} md={2} lg={2}>
											<TextField
												placeholder="Max"
												autoComplete="off"
												id="price_to"
												name="price_to"
												variant="outlined"
												color="secondary"
												helperText={formikBag.touched.price_to ? formikBag.errors.price_to : ""}
												error={formikBag.touched.price_to && Boolean(formikBag.errors.price_to)}
												value={formikBag.values.price_to || ""}
												onChange={(e: any) => {
													e.persist();
													formikBag.setFieldValue("price_to", e.target.value);
													formikBag.setFieldTouched("price_to", true, false);
												}}
											/>
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={4} md={4} lg={4}>
									{/* <InputLabel className={classes.textAlign} htmlFor="size">
										Deposit Percentage *
									</InputLabel> */}
								</Grid>
								{/* { <Grid item xs={4} md={2} lg={2}>
									<TextField
										placeholder="Deposit"
										autoComplete="on"
										id="deposit"
										name="deposit"
										variant="outlined"
										color="secondary"
										helperText={formikBag.touched.deposit ? formikBag.errors.deposit : ""}
										error={formikBag.touched.deposit && Boolean(formikBag.errors.deposit)}
										value={formikBag.values.deposit}
										onChange={(e: any) => {
											e.persist();
											formikBag.setFieldValue("deposit", e.target.value);
											formikBag.setFieldTouched("deposit", true, false);
										}}
									/>
								</Grid> } */}
								<Grid item xs={5} md={6} lg={6}></Grid>
								<Grid item xs={4} md={4} lg={4}>
									<InputLabel className={classes.textAlign} htmlFor="size">
										Band Size *
									</InputLabel>
								</Grid>
								<Grid item xs={3} md={2} lg={2}>
									<TextField
										placeholder="Band Size"
										autoComplete="off"
										id="size"
										name="size"
										variant="outlined"
										color="secondary"
										helperText={formikBag.touched.size ? formikBag.errors.size : ""}
										error={formikBag.touched.size && Boolean(formikBag.errors.size)}
										value={formikBag.values.size}
										onChange={(e: any) => {
											e.persist();
											formikBag.setFieldValue("size", e.target.value);
											formikBag.setFieldTouched("size", true, false);
										}}
									/>
								</Grid>
								<Grid item xs={5} md={6} lg={6}></Grid>
								<Grid item xs={4} md={4} lg={4}>
									<Spotify width="2.1875rem" fill="#fff" className={classes.svgAlign} />
								</Grid>
								<Grid item xs={8} md={8} lg={8}>
									<TextField
										placeholder="Spotify"
										autoComplete="off"
										id="spotify"
										name="spotify"
										variant="outlined"
										color="secondary"
										helperText={formikBag.touched.spotify ? formikBag.errors.spotify : ""}
										error={formikBag.touched.spotify && Boolean(formikBag.errors.spotify)}
										fullWidth={true}
										value={formikBag.values.spotify}
										onChange={(e: any) => {
											e.persist();
											formikBag.setFieldValue("spotify", e.target.value);
											formikBag.setFieldTouched("spotify", true, false);
										}}
									/>
								</Grid>
								<Grid item xs={4} md={4} lg={4}>
									<YouTube fontSize="large" className={classes.svgAlign} />
								</Grid>
								<Grid item xs={8} md={8} lg={8}>
									<TextField
										placeholder="Youtube"
										autoComplete="off"
										id="youtube"
										name="youtube"
										helperText={formikBag.touched.youtube ? formikBag.errors.youtube : ""}
										error={formikBag.touched.youtube && Boolean(formikBag.errors.youtube)}
										variant="outlined"
										color="secondary"
										fullWidth={true}
										value={formikBag.values.youtube}
										onChange={(e: any) => {
											e.persist();
											formikBag.setFieldValue("youtube", e.target.value);
											formikBag.setFieldTouched("youtube", true, false);
										}}
									/>
								</Grid>
								<Grid item xs={4} md={4} lg={4}>
									<Facebook fontSize="large" className={classes.svgAlign} />
								</Grid>
								<Grid item xs={8} md={8} lg={8}>
									<TextField
										placeholder="Facebook"
										autoComplete="off"
										id="facebook"
										name="facebook"
										helperText={formikBag.touched.facebook ? formikBag.errors.facebook : ""}
										error={formikBag.touched.facebook && Boolean(formikBag.errors.facebook)}
										variant="outlined"
										color="secondary"
										fullWidth={true}
										value={formikBag.values.facebook}
										onChange={(e: any) => {
											e.persist();
											formikBag.setFieldValue("facebook", e.target.value);
											formikBag.setFieldTouched("facebook", true, false);
										}}
									/>
								</Grid>
								<Grid item xs={4} md={4} lg={4}>
									<Instagram fontSize="large" className={classes.svgAlign} />
								</Grid>
								<Grid item xs={8} md={8} lg={8}>
									<TextField
										placeholder="Instagram"
										autoComplete="off"
										id="instagram"
										name="instagram"
										helperText={formikBag.touched.instagram ? formikBag.errors.instagram : ""}
										error={formikBag.touched.instagram && Boolean(formikBag.errors.instagram)}
										variant="outlined"
										color="secondary"
										fullWidth={true}
										value={formikBag.values.instagram}
										onChange={(e: any) => {
											e.persist();
											formikBag.setFieldValue("instagram", e.target.value);
											formikBag.setFieldTouched("instagram", true, false);
										}}
									/>
								</Grid>
								<Grid item xs={4} md={4} lg={4}>
									<Soundcloud
										width="2.1875rem"
										fill="#fff"
										fontSize="large"
										className={classes.svgAlign}
									/>
								</Grid>
								<Grid item xs={8} md={8} lg={8}>
									<TextField
										placeholder="Soundcloud"
										autoComplete="off"
										id="soundcloud"
										name="soundcloud"
										variant="outlined"
										color="secondary"
										helperText={formikBag.touched.soundcloud ? formikBag.errors.soundcloud : ""}
										error={formikBag.touched.soundcloud && Boolean(formikBag.errors.soundcloud)}
										fullWidth={true}
										value={formikBag.values.soundcloud}
										onChange={(e: any) => {
											e.persist();
											formikBag.setFieldValue("soundcloud", e.target.value);
											formikBag.setFieldTouched("soundcloud", true, false);
										}}
									/>
								</Grid>
								<Grid item xs={4} md={4} lg={4}>
									<InputLabel className={classes.textAlign} htmlFor="Featured Song Name 1">
										Featured Song Name 1
									</InputLabel>
								</Grid>
								<Grid item xs={8} md={8} lg={8}>
									<TextField
										placeholder="Feature Song Name 1"
										autoComplete="off"
										id="featured_song_name_1"
										name="featured_song_name_1"
										helperText={
											formikBag.touched.featured_song_name_1
												? formikBag.errors.featured_song_name_1
												: ""
										}
										error={
											formikBag.touched.featured_song_name_1 &&
											Boolean(formikBag.errors.featured_song_name_1)
										}
										variant="outlined"
										color="secondary"
										fullWidth={true}
										value={formikBag.values.featured_song_name_1 || ""}
										onChange={(e: any) => {
											e.persist();
											formikBag.setFieldValue("featured_song_name_1", e.target.value);
											formikBag.setFieldTouched("featured_song_name_1", true, false);
										}}
									/>
								</Grid>
								<Grid item xs={4} md={4} lg={4}>
									<InputLabel className={classes.textAlign} htmlFor="Featured Song URL 1">
										Featured Song Link 1
									</InputLabel>
								</Grid>
								<Grid item xs={8} md={8} lg={8}>
									<TextField
										placeholder="Feature Song Link 1"
										autoComplete="off"
										id="featured_song_link_1"
										name="featured_song_link_1"
										helperText={
											formikBag.touched.featured_song_link_1
												? formikBag.errors.featured_song_link_1
												: ""
										}
										error={
											formikBag.touched.featured_song_link_1 &&
											Boolean(formikBag.errors.featured_song_link_1)
										}
										variant="outlined"
										color="secondary"
										fullWidth={true}
										value={formikBag.values.featured_song_link_1 || ""}
										onChange={(e: any) => {
											e.persist();
											formikBag.setFieldValue("featured_song_link_1", e.target.value);
											formikBag.setFieldTouched("featured_song_link_1", true, false);
										}}
									/>
								</Grid>
								<Grid item xs={4} md={4} lg={4}>
									<InputLabel className={classes.textAlign} htmlFor="Featured Song Name 2">
										Featured Song Name 2
									</InputLabel>
								</Grid>
								<Grid item xs={8} md={8} lg={8}>
									<TextField
										placeholder="Feature Song Name 2"
										autoComplete="off"
										id="featured_song_name_2"
										name="featured_song_name_2"
										helperText={
											formikBag.touched.featured_song_name_2
												? formikBag.errors.featured_song_name_2
												: ""
										}
										error={
											formikBag.touched.featured_song_name_2 &&
											Boolean(formikBag.errors.featured_song_name_2)
										}
										variant="outlined"
										color="secondary"
										fullWidth={true}
										value={formikBag.values.featured_song_name_2 || ""}
										onChange={(e: any) => {
											e.persist();
											formikBag.setFieldValue("featured_song_name_2", e.target.value);
											formikBag.setFieldTouched("featured_song_name_2", true, false);
										}}
									/>
								</Grid>
								<Grid item xs={4} md={4} lg={4}>
									<InputLabel className={classes.textAlign} htmlFor="Featured Song URL 2">
										Featured Song URL 2
									</InputLabel>
								</Grid>
								<Grid item xs={8} md={8} lg={8}>
									<TextField
										placeholder="Feature Song Link 1"
										autoComplete="off"
										id="featured_song_link_2"
										name="featured_song_link_2"
										helperText={
											formikBag.touched.featured_song_link_2
												? formikBag.errors.featured_song_link_2
												: ""
										}
										error={
											formikBag.touched.featured_song_link_2 &&
											Boolean(formikBag.errors.featured_song_link_2)
										}
										variant="outlined"
										color="secondary"
										fullWidth={true}
										value={formikBag.values.featured_song_link_2 || ""}
										onChange={(e: any) => {
											e.persist();
											formikBag.setFieldValue("featured_song_link_2", e.target.value);
											formikBag.setFieldTouched("featured_song_link_2", true, false);
										}}
									/>
								</Grid>
								<Grid item xs={4} md={4} lg={4}>
									<InputLabel className={classes.textAlign} htmlFor="Bio">
										Bio *
									</InputLabel>
								</Grid>
								<Grid item xs={8} md={8} lg={8}>
									<TextField
										placeholder="Bio"
										autoComplete="off"
										id="bio"
										name="bio"
										multiline={true}
										rows={6}
										helperText={formikBag.touched.bio ? formikBag.errors.bio : ""}
										error={formikBag.touched.bio && Boolean(formikBag.errors.bio)}
										variant="outlined"
										color="secondary"
										fullWidth={true}
										value={formikBag.values.bio}
										onChange={(e: any) => {
											e.persist();
											formikBag.setFieldValue("bio", e.target.value);
											formikBag.setFieldTouched("bio", true, false);
										}}
									/>
								</Grid>
								<Grid item xs={4} md={4} lg={4}>
									<InputLabel className={classes.textAlign} htmlFor="allGenres">
										What Genre of Music are you? *
									</InputLabel>
								</Grid>
								<Grid item xs={8} md={8} lg={8}>
									<Select
										className={formikBag.values.genres ? "" : classes.dropDownPlaceholder}
										fullWidth={true}
										id="genres"
										name="genres"
										variant="outlined"
										color="secondary"
										error={formikBag.touched.genres && Boolean(formikBag.errors.genres)}
										MenuProps={{
											getContentAnchorEl: null,
											anchorOrigin: {
												vertical: "bottom",
												horizontal: "center",
											},
											transformOrigin: {
												vertical: "top",
												horizontal: "center",
											},
										}}
										multiple
										value={formikBag.values.genres}
										renderValue={(selected: any) => {
											if (
												(selected as Array<{
													id: number;
												}>) &&
												(selected as Array<{
													id: number;
												}>).length === 0
											) {
												return <div className={classes.dropDownPlaceholder}>Genres</div>;
											}
											const x = selected.map(
												(item: any) => resources.genres.items[item.id].title,
											);
											return x.join(", ");
										}}
										displayEmpty>
										{resources.genres.allIds.map((id: any) => (
											<MenuItem key={id} value={resources.genres.items[id].title}>
												<Checkbox
													name={resources.genres.items[id].title}
													checked={isGenreChecked(id)}
													onChange={handleGenreChange}
													value={id}
												/>
												<ListItemText primary={resources.genres.items[id].title} />
											</MenuItem>
										))}
									</Select>
									{formikBag.errors.genres && formikBag.touched.genres && (
										<FormHelperText error variant="outlined">
											Genres is a required field
										</FormHelperText>
									)}
								</Grid>
								<Grid item xs={4} md={4} lg={4}>
									<InputLabel className={classes.textAlign} htmlFor="preferred_gigs_type">
										Preferred Gigs *
									</InputLabel>
								</Grid>
								<Grid item xs={8} md={8} lg={8}>
									<Select
										className={
											formikBag.values.preferred_gig_types ? "" : classes.dropDownPlaceholder
										}
										fullWidth={true}
										id="preferred_gig_types"
										name="preferred_gig_types"
										variant="outlined"
										color="secondary"
										error={
											formikBag.touched.preferred_gig_types &&
											Boolean(formikBag.errors.preferred_gig_types)
										}
										MenuProps={{
											getContentAnchorEl: null,
											anchorOrigin: {
												vertical: "bottom",
												horizontal: "center",
											},
											transformOrigin: {
												vertical: "top",
												horizontal: "center",
											},
										}}
										multiple
										renderValue={(selected: any) => {
											if (
												(selected as Array<{
													id: number;
												}>) &&
												(selected as Array<{
													id: number;
												}>).length === 0
											) {
												return (
													<div className={classes.dropDownPlaceholder}>Preferred Gigs</div>
												);
											}
											const x = selected.map((item: any) => resources.gigs.items[item.id].title);
											return x.join(", ");
										}}
										displayEmpty
										value={formikBag.values.preferred_gig_types}>
										{resources.gigs.allIds.map((id: any) => (
											<MenuItem key={id} value={resources.gigs.items[id].title}>
												<Checkbox
													name={resources.gigs.items[id].title}
													checked={isGigsChecked(id)}
													onChange={handleGigsChange}
													value={id}
												/>
												<ListItemText primary={resources.gigs.items[id].title} />
											</MenuItem>
										))}
									</Select>
									{formikBag.errors.preferred_gig_types && formikBag.touched.preferred_gig_types && (
										<FormHelperText error variant="outlined">
											Gigs is a required field
										</FormHelperText>
									)}
								</Grid>
								<Grid item xs={4} md={4} lg={4}>
									<InputLabel className={classes.textAlign} htmlFor="hospitality_and_production_rider">
										Hospitality & Production Rider *
									</InputLabel>
									<Typography className={classes.textAlign} variant="body2">
										10mb file size
									</Typography>
								</Grid>
								<Grid item xs={8} md={8} lg={8}>
									<HospitalityDropzone
										url={
											formikBag.values.hospitality_and_production_rider && formikBag.values.hospitality_and_production_rider.url
										}
										handleChange={(file: any) => {
											formikBag.setFieldValue("hospitality_and_production_rider", file);
										}}
										inputContent=""
									/>
									{formikBag.errors.hospitality_and_production_rider && formikBag.touched.hospitality_and_production_rider && (
										<FormHelperText error variant="outlined">
											Hospitality and Production Rider is required
										</FormHelperText>
										
									)}
								</Grid>
								<Grid item xs={4} md={4} lg={4}>
									<InputLabel className={classes.textAlign} htmlFor="willing_to_travel">
										Willing To Travel *
									</InputLabel>
								</Grid>
								<Grid item xs={8} md={8} lg={8}>
									<Select
										className={
											formikBag.values.willing_to_travel ? "" : classes.dropDownPlaceholder
										}
										fullWidth={true}
										id="willing_to_travel"
										name="willing_to_travel"
										variant="outlined"
										color="secondary"
										error={
											formikBag.touched.willing_to_travel &&
											Boolean(formikBag.errors.willing_to_travel)
										}
										MenuProps={{
											getContentAnchorEl: null,
											anchorOrigin: {
												vertical: "bottom",
												horizontal: "center",
											},
											transformOrigin: {
												vertical: "top",
												horizontal: "center",
											},
										}}
										multiple
										renderValue={(selected: any) => {
											if (
												(selected as Array<{
													id: number;
												}>) &&
												(selected as Array<{
													id: number;
												}>).length === 0
											) {
												return (
													<div className={classes.dropDownPlaceholder}>Willing To Travel</div>
												);
											}
											const x = selected.map(
												(item: any) => resources.states.items[item.id].title,
											);
											return x.join(", ");
										}}
										displayEmpty
										value={formikBag.values.willing_to_travel}>
										{resources.states.allIds.map((id: any) => (
											<MenuItem key={id} value={resources.states.items[id].title}>
												<Checkbox
													name={resources.states.items[id].title}
													checked={isStateChecked(id)}
													onChange={handleStatesChange}
													value={id}
												/>
												<ListItemText primary={resources.states.items[id].title} />
											</MenuItem>
										))}
									</Select>
								</Grid>
								{/* <Grid item xs={4} md={4} lg={4}>
									<InputLabel className={classes.textAlign} htmlFor="header_photo">
										Header Image
									</InputLabel>
									<Typography className={classes.textAlign} variant="body2">
										Image format must be{" "} PNG or JPG
									</Typography>
									<Typography className={classes.textAlign} variant="body2">
										less than 1500px x 1500px.
									</Typography>
									<Typography className={classes.textAlign} variant="body2">
										10mb file size
									</Typography>
									
								</Grid>
								<Grid item xs={8} md={8} lg={8}>
									<ImageCropperDialog existingFile={formikBag.values.header_photo && formikBag.values.header_photo.url} fileCallback={headerFileCallback} fileUrl={formikBag.values.header_photo && formikBag.values.header_photo.url} ></ImageCropperDialog>
								</Grid> */}
								<Grid item xs={4} md={4} lg={4}></Grid>
								<Grid item xs={6} md={6} lg={6}>
									{talentProfile.error && talentProfile.error.message && (
										<ErrorMessage error={talentProfile.error} />
									)}
								</Grid>
							</Grid>
							<Box display="flex" flexDirection="row-reverse" m={3} mr={0}>
								<Button
									type="submit"
									size="large"
									variant="contained"
									color="secondary"
									disabled={formikBag.isSubmitting}>
									Save
								</Button>
							</Box>
						</form>
					);
				}}
			/>
		</Box>
	);
};

export default TalentProfileForm;
