import React from "react";
import {
	withStyles,
	DialogContent,
	DialogContentText,
	Dialog,
	CircularProgress,
	DialogTitle,
} from "@material-ui/core";
import { IBookerRequestDialogProps } from "./interfaces";
import { styles } from "./styles";
import { Formik } from "formik";
import * as Yup from "yup";
import Form from "./form";
import moment from "moment";

const validationSchema = Yup.object({
	// event: Yup.string().required('Event is required'),
	title: Yup.string().required("Event Name is required"),
	date: Yup.string().required("Event Date is required"),
	location: Yup.string().required("Location is required"),
	number_of_attendees: Yup.number().required(
		"number of attendees are required",
	),
	budget: Yup.number().required("Budget is required"),
	genres: Yup.array().required("Genres is required"),
	startTime: Yup.string().required("Start time is required"),
	desiredSetLength: Yup.number().required("Desired Set Length is required"),
	venue_description: Yup.string().required("Venue Description is required"),
});

class BookerRequestDialog extends React.Component<IBookerRequestDialogProps> {
	setSelectedEventItem = (eventItem: any) => {
		this.props.setSelectedEventItem(eventItem);
	};

	handleValues = (values: any) => {
		values.genres = values.genres.map((item: any) => ({
			id: item.id,
			title: item.title,
		}));
		if (!values.start_time) {
			values.start_time = values.date + " ";
		}

		if (values.startTime) {
			values.start_time =
				values.start_time &&
				values.start_time.split(" ")[0] + " " + values.startTime;
		}

		const startingTime = +values.start_time.split(" ")[1].split(":")[0];

		if (values.desiredSetLength) {
			values.end_time =
				values.end_time &&
				values.end_time.split(" ")[0] +
					" " +
					(startingTime + values.desiredSetLength);
		}
		values.budget = (values.budget && Number(values.budget)) || 0;
		values.number_of_attendees =
			(values.number_of_attendees &&
				Number(values.number_of_attendees)) ||
			0;
		values.currency = "$";
		values.end_time = new Date(values.start_time);
		values.end_time.setHours(
			values.end_time.getHours() + values.desiredSetLength,
		);
		values.end_time = moment(values.end_time).format("MMM DD, YYYY hh:mm:ss");
		values.event = { id: values.selectedEventItem.id };
		values.band = { id: this.props.bandId };
		return values;
	};

	render() {
		const {
			classes,
			handleClose,
			opened,
			showLoading,
			titleForm,
			handleBookerRequestDialogSubmit,
			setSelectedEventItem,
			values,
			error,
		} = this.props;

		return (
			<Dialog
				className={classes.dialog}
				fullWidth={true}
				maxWidth="sm"
				open={opened}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle
					className={classes.dialogContentText}
					id="alert-dialog-title"
				>
					{titleForm}
				</DialogTitle>

				<DialogContent>
					<div className={classes.container}>
						{showLoading ? (
							<div className={classes.progressWrapper}>
								<CircularProgress
									className={classes.progress}
								/>
							</div>
						) : (
							<Formik
								render={props => (
									<Form
										{...props}
										setSelectedEventItem={
											setSelectedEventItem
										}
									/>
								)}
								initialValues={values}
								enableReinitialize={true}
								validationSchema={validationSchema}
								onSubmit={(values, actions) => {
									handleBookerRequestDialogSubmit(values);
									actions.setSubmitting(true);
								}}
							/>
						)}
					</div>
				</DialogContent>

				<DialogContent>
					{error && Object.keys(error).length > 0 && (
						<DialogContentText>
							There is a error while submitting
						</DialogContentText>
					)}
				</DialogContent>
			</Dialog>
		);
	}
}

export default withStyles(styles)(BookerRequestDialog);
