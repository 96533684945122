import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import ConfirmationDialog from "./confirmation-dialog";
import { IConfirmationDialogOwnProps } from "./interfaces";
import { IAppState } from "../generic/interfaces";

const mapStateToProps = (
	state: IAppState,
	ownProps: IConfirmationDialogOwnProps,
) => ({
	opened: ownProps.opened,
	userEmail: state.registration.user.email,
	state: state.confirmationDialog,
});

const mapDispatchToProps = (
	dispatch: ThunkDispatch<{}, {}, any>,
	ownProps: IConfirmationDialogOwnProps,
) => ({
	handleClose: () => dispatch(ownProps.handleClose()),
	handleResendConfirmation: (userEmail: string) =>
		dispatch(ownProps.handleResendConfirmation(userEmail)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationDialog);
