import { IError } from "../../Base/ErrorMessageBase";
import { Genre, IState, IGig, INotificationSettings } from "../../interfaces";

export enum ResourcesActionType {
	GENRES_REQUEST = "GENRES_REQUEST",
	GENRES_SUCCESS = "GENRES_SUCCESS",
	GENRES_FAILURE = "GENRES_FAILURE",
	STATES_REQUEST = "STATES_REQUEST",
	STATES_SUCCESS = "STATES_SUCCESS",
	STATES_FAILURE = "STATES_FAILURE",
	GIGS_REQUEST = "GIGS_REQUEST",
	GIGS_SUCCESS = "GIGS_SUCCESS",
	GIGS_FAILURE = "GIGS_FAILURE",
	PRICE_RANGE_REQUEST = "PRICE_RANGE_REQUEST",
	PRICE_RANGE_SUCCESS = "PRICE_RANGE_SUCCESS",
	PRICE_RANGE_FAILURE = "PRICE_RANGE_FAILURE",
	NOTIFICATION_SETTINGS_REQUEST = "NOTIFICATION_SETTINGS_REQUEST",
	NOTIFICATION_SETTINGS_SUCCESS = "NOTIFICATION_SETTINGS_SUCCESS",
	NOTIFICATION_SETTINGS_FAILURE = "NOTIFICATION_SETTINGS_FAILURE",
}

export interface ResourcesAction {
	type: ResourcesActionType;
	payload: any;
}

export interface IGeneratedFetchReducer<T> {
	isSucceed: boolean;
	isFailure: boolean;
	isRequested: boolean;
	items: { [id: number]: T };
	allIds: number[];
	error: IError;
	lastFetched: string;
}

export interface IResourcesReducer {
	genres: IGeneratedFetchReducer<Genre>;
	states: IGeneratedFetchReducer<IState>;
	gigs: IGeneratedFetchReducer<IGig>;
	priceRange: any;
	notificationSettings: IGeneratedFetchReducer<INotificationSettings>;
}
