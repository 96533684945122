import { refineResponse } from "../helpers/refineResponse";
import { headers, baseUrl } from "../../AuthBase/helpers/auth-header";

export const upload = (file: any) => {
	const formData = new FormData();
	formData.append("media", file);
	const body = formData;

	const requestOptions = {
		method: "POST",
		headers: { ...headers() },
		body,
	};

	return fetch(`${baseUrl}media/upload`, requestOptions).then(
		refineResponse,
	);
};

const requestOptions = {
	method: "GET",
	headers: { ...headers() },
};

// to get the file from url to show the photo
export const getImageFile = (imageDataUrl: string) => {
	return fetch(
		"https://cors-anywhere.herokuapp.com/" + imageDataUrl,
		requestOptions,
	).then(res => {
		return res.arrayBuffer().then(buf => {
			return new File([buf], "image_data_url.png", { type: "image/png" });
		});
	});
};

export const getFile = (pdfDataUrl: string) => {
	return fetch("https://cors-anywhere.herokuapp.com/" + pdfDataUrl).then(
		res => {
			return res.arrayBuffer().then(buf => {
				return new File([buf], "pdf_data_url.pdf", {
					type: "application/pdf",
				});
			});
		},
	);
};
