import ReCAPTCHA from "react-google-recaptcha";
import React, { useEffect, useState , useRef  } from "react";
import { PaymentMethodListItem } from "./PaymentMethodListItem";
import { CardAccount } from "./CardAccount";
import Services from "../../generic/Services";
import { Prompt } from "react-router";
import Box from "@material-ui/core/Box";
import { IsTalent, IsBooker, IsAgent } from "../../AuthBase";
import { Link, useParams } from "react-router-dom";

interface BillingInformationProps {
	setLoc: any;
	states: any;
	setPage: (page: string) => void;
	updateUserData: () => void;
	user: any;
}

interface Address {
	street: string;
	city: string;
	zip: string;
	state: { id: number; title: string };
}

export interface PaymentMethodItem {
	id: number;
	username: string;
	address: Address;
	last_4_digits: number;
	isDefault: boolean;
	type: string;
	bank_name: string;
	brand: string;
	isVerified: string;
	exp_month: string;
	exp_year: string;
}

interface ListPaymentMethodsResponse {
	status: string;
	server_message: string;
	user_message: string;
	items: PaymentMethodItem[];
}

export const BillingInformation: React.FC<BillingInformationProps> = ({ setLoc, states, setPage, updateUserData, user }) => {
	const [loading, setLoading] = useState<boolean>(true);
	const [error, setError] = useState<boolean>();
	const [data, setData] = useState<ListPaymentMethodsResponse>();
	const [toggleDefault, setToggleDefault] = useState<boolean>(false);
	const [addPaymentMethod, setAddPaymentMethod] = useState<boolean>(false);
	const [deletePaymentMethod, setDeletePaymentMethod] = useState<boolean>(false);
	const [disable, setDisable] = useState<boolean>(false);

	// State To monitor if the form has values (is dirty) to prevent from navigating if dirty
	const [dirtyCardForm, setDirtyCardForm] = React.useState<boolean>(false);
	const [dirtyAccForm, setDirtyAccForm] = React.useState<boolean>(false);
	const recaptchaRef = useRef(null);
	const { id }  = useParams();

	// fuc that runs when default payment method changes and on componentWillMount
	useEffect(() => {
		if(!id){
			setLoc("/account/billing-information");
			setPage("billing-information")
			Services.paymentMethods
				.listPaymentMethods()
				.then((res: ListPaymentMethodsResponse) => {
					setData(res);
					setLoading(false);
				})
				.catch((err: any) => {
					setError(err);
					setLoading(false);
				});
		}
		else{
			setLoc("/account/band-billing-information/" + id);
			setPage("band-billing-information/")
			Services.paymentMethods
				.listAgentBandPaymentMethods(id)
				.then((res: ListPaymentMethodsResponse) => {
					setData(res);
					setLoading(false);
				})
				.catch((err: any) => {
					setError(err);
					setLoading(false);
				});
		}
	}, [setLoc, toggleDefault, addPaymentMethod, deletePaymentMethod, setPage, id]);

	if (loading) {
		return <div>loading...</div>;
	}

	if (error) {
		return <div>error</div>;
	}

	return (
		<React.Fragment>
			<Box mb="30px" fontWeight="bold" fontFamily="Rubik" fontSize="30px">
				Payment Methods
			</Box>
			<IsTalent>
				<Box mb="14px" fontFamily="Rubik" fontSize="14px">
					A valid payment method is required for all accounts. Repsy charges 13% for service fee on all events. Need to change or update your payment method? Please add it as a new account or card and select it as your default method to use it for upcoming payments or transfers. Then you may delete your previous method. All transactions will always utilize the default method. For additional information, click <Link to="/how-it-works/talent">here</Link>.
				</Box>
			</IsTalent>
			<IsAgent>
				<Box mb="14px" fontFamily="Rubik" fontSize="14px">
					A valid payment method is required for all accounts. Repsy charges 13% for service fee on all events. Need to change or update your payment method? Please add it as a new account or card and select it as your default method to use it for upcoming payments or transfers. Then you may delete your previous method. All transactions will always utilize the default method. For additional information, click <Link to="/how-it-works/talent">here</Link>.
				</Box>
			</IsAgent>
			<IsBooker>
				<Box mb="14px" fontFamily="Rubik" fontSize="14px">
					A valid payment method is required for all accounts. Repsy charges 13% for service fee on all events. Need to change or update your payment method? Please add it as a new account or card and select it as your default method to use it for upcoming payments or transfers. Then you may delete your previous method. All transactions will always utilize the default method. For additional information, click <Link to="/how-it-works/booker">here</Link>.
				</Box>
			</IsBooker>
			{/* list my payment methods */}
			{data &&
				data.items &&
				data.items.map((item) => (
					<PaymentMethodListItem
						key={item.id}
						item={item}
						toggleDefault={toggleDefault}
						setToggleDefault={setToggleDefault}
						deletePaymentMethod={deletePaymentMethod}
						setDeletePaymentMethod={setDeletePaymentMethod}
						disable={disable}
						setDisable={setDisable}
						count={data.items.length}
					/>
				))}
			<Prompt when={dirtyCardForm || dirtyAccForm} message={() => `Do you want to discard these changes?`} />
			<IsBooker>
				<CardAccount
					addPaymentMethod={addPaymentMethod}
					setAddPaymentMethod={setAddPaymentMethod}
					type="CARD"
					dirtyForm={dirtyCardForm}
					setDirtyForm={setDirtyCardForm}
					states={states}
					updateUserData={updateUserData}
				/>
				<CardAccount
					addPaymentMethod={addPaymentMethod}
					setAddPaymentMethod={setAddPaymentMethod}
					type="ACCOUNT"
					dirtyForm={dirtyAccForm}
					setDirtyForm={setDirtyAccForm}
					states={states}
					updateUserData={updateUserData}
				/>
			</IsBooker>
			<IsTalent>
				<CardAccount
						addPaymentMethod={addPaymentMethod}
						setAddPaymentMethod={setAddPaymentMethod}
						type="ACCOUNT"
						dirtyForm={dirtyAccForm}
						setDirtyForm={setDirtyAccForm}
						states={states}
						updateUserData={updateUserData}
					/>
				<CardAccount
						addPaymentMethod={addPaymentMethod}
						setAddPaymentMethod={setAddPaymentMethod}
						type="CARD"
						dirtyForm={dirtyCardForm}
						setDirtyForm={setDirtyCardForm}
						states={states}
						updateUserData={updateUserData}
					/>				
			</IsTalent>
			<IsAgent>
				<CardAccount
					addPaymentMethod={addPaymentMethod}
					setAddPaymentMethod={setAddPaymentMethod}
					type="CARD"
					dirtyForm={dirtyCardForm}
					setDirtyForm={setDirtyCardForm}
					states={states}
					updateUserData={updateUserData}
				/>
				<CardAccount
					addPaymentMethod={addPaymentMethod}
					setAddPaymentMethod={setAddPaymentMethod}
					type="ACCOUNT"
					dirtyForm={dirtyAccForm}
					setDirtyForm={setDirtyAccForm}
					states={states}
					updateUserData={updateUserData}
				/>
			</IsAgent>

			<div style={{zIndex:1,position:'relative'}}>
			<ReCAPTCHA
							ref={recaptchaRef}
							size="invisible"
							sitekey="6LdkbpEbAAAAAH9cbGStg8INIgmZB9ND1rTVZQAL"
							
						/>
			</div>
			

		</React.Fragment>
		
	);
};
