import React from "react";
import Grid from "@material-ui/core/Grid";
import ArrowRight from "@material-ui/icons/ArrowRight";
import Typography from "@material-ui/core/Typography";
import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import moment from "moment";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		leftArrow: {
			marginRight: "20px",
		},
		ExpansionPanelSummary: {
			flexDirection: "row-reverse",
		},
		ExpansionPanelDetails: {
			paddingLeft: "65px",
			flexDirection: "column",
		},
		ExpansionPanel: {
			border: "none",
		},
	}),
);

interface RequestHistoryProps {
	isRequested: boolean;
	isSucceeded: boolean;
	isFailed: boolean;
	requestHistory: any;
	onBackClick: () => void;
}

export const RequestHistory: React.FC<RequestHistoryProps> = ({
	isRequested,
	isSucceeded,
	isFailed,
	requestHistory,
	onBackClick,
}) => {
	const classes = useStyles();

	return (
		<ExpansionPanel expanded={true} className={classes.ExpansionPanel}>
			<ExpansionPanelSummary
				className={classes.ExpansionPanelSummary}
				IconButtonProps={{
					edge: "start",
					classes: { root: classes.leftArrow },
					onClick: onBackClick,
				}}
				expandIcon={<ArrowRight fontSize="large" color="secondary" />}
				aria-controls="panel1a-content"
				id="panel1a-header"
			>
				{isFailed ? (
					<div>Error...</div>
				) : isRequested ? (
					<div>Loading...</div>
				) : (
					<Grid
						container
						direction="row"
						alignItems="center"
						justify="space-between"
					>
						<Typography>
							{isSucceeded &&
								requestHistory.items.length > 0 &&
								requestHistory.items[0].event.title}
						</Typography>
					</Grid>
				)}
			</ExpansionPanelSummary>
			<ExpansionPanelDetails className={classes.ExpansionPanelDetails}>
				{isSucceeded &&
					requestHistory.items.length > 0 &&
					requestHistory.items.map((event: any, index: number) => (
						<Grid
							key={index}
							container
							justify="space-between"
							direction="row"
							spacing={2}
						>
							<Grid item xs={3}>
								<h4>
									{moment.unix(event.date).format("MMM DD, YYYY")}
								</h4>
							</Grid>
							<Grid item xs={9}>
								<p>{event.action}</p>
							</Grid>
						</Grid>
					))}
				{isSucceeded && requestHistory.items.length === 0 && (
					<div>There is no event histroy</div>
				)}
			</ExpansionPanelDetails>
		</ExpansionPanel>
	);
};
