import React, { useEffect } from "react";
import { Typography, Box, Grid, Button, TextField, Select, MenuItem, Dialog, DialogActions, DialogContentText, DialogContent } from "@material-ui/core";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";

const types = [ "Billing Dispute", "Account Management" ]

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		font: {
			fontFamily: "Squada One",
			fontSize: "22px",
			fontWeight: "normal",
		},
		infoFont: {
			fontSize: "1rem",
			marginBottom: "20px"
		},
		link: {
            cursor: "pointer",
            marginRight: "10px",
            fontSize: "16px",
            color: "white"
        },
        dropDownPlaceholder: {
			fontFamily: "Poppins",
			fontWeight: 400,
		},
	}),
);

export interface ISupportProps {
    role: string;
    support: any;
    setPage: (page: string) => void;
    sendInquiry: (values: any) => void;
    handleSaveClose: () => any;
	handleFailClose: () => any;
}

export interface IFormValues {
	type: string;
	message: string;
}

const validationSchema = Yup.object({
	type: Yup.string().required("Please select a Ticket Type"),
	message: Yup.string().required("Message field is required"),
});

const Support: React.FC<ISupportProps> = ({ setPage, sendInquiry, role, support, handleSaveClose, handleFailClose }) => {
    const classes = useStyles();
    useEffect(() => {
        setPage("support");
    },[setPage])

    const initialValues = {
		type: "",
		message: "",
	};

    return(
        <>
            <Box mb="10px" fontWeight="bold" fontFamily="Rubik" fontSize="35px">
				Support
			</Box>
            <Typography className={classes.infoFont} variant="subtitle1"> Submit your request and we'll have a customer service representative contact you as soon as possible</Typography>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={async (values, actions) => {
                    await sendInquiry(values);
                    actions.resetForm();
                    actions.setSubmitting(false);
                }}
                render={(formikBag: FormikProps<IFormValues>) => (
                    <form onSubmit={formikBag.handleSubmit}>
                        <Grid container direction="column" spacing={4}>
                            <Grid item xs={5}>
                                <Select
                                    className={
                                        formikBag.values
                                            .type
                                            ? ""
                                            : classes.dropDownPlaceholder
                                    }
                                    value={
                                        formikBag.values.type ? formikBag.values.type : "Support Ticket Type"
                                    }
                                    inputProps={{
                                        className: formikBag.values
                                            .type
                                            ? ""
                                            : classes.dropDownPlaceholder
                                    }}
                                    fullWidth={true}
                                    id="type"
                                    name="type"
                                    variant="outlined"
                                    color="secondary"
                                    error={
                                        formikBag.touched
                                            .type &&
                                        Boolean(
                                            formikBag.errors
                                                .type,
                                        )
                                    }
                                    MenuProps={{
                                        getContentAnchorEl: null,
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "center",
                                        },
                                        transformOrigin: {
                                            vertical: "top",
                                            horizontal: "center",
                                        },
                                    }}
                                    onChange={(e: any) => {
                                        e.persist();
                                        formikBag.setFieldValue(
                                            "type",
                                            e.target.value,
                                        );
                                        formikBag.setFieldTouched(
                                            "type",
                                            true,
                                            false,
                                        );
                                    }}>
                                    <MenuItem disabled value={"Support Ticket Type"}>
                                        Support Ticket Type
                                    </MenuItem>
                                    {types.map(
                                        (item: any) => (
                                            <MenuItem
                                                key={item}
                                                value={item}>
                                                {item}
                                            </MenuItem>
                                        ),
                                    )}
                                </Select>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    autoComplete="off"
                                    variant="outlined"
                                    color="secondary"
                                    id="message"
                                    name="message"
                                    helperText={
                                        formikBag.touched.message
                                            ? formikBag.errors.message
                                            : ""
                                    }
                                    error={
                                        formikBag.touched.message &&
                                        Boolean(
                                            formikBag.errors.message,
                                        )
                                    }
                                    fullWidth={true}
                                    value={formikBag.values.message}
                                    multiline={true}
                                    rows={10}
                                    onChange={(e: any) => {
                                        e.persist();
                                        formikBag.setFieldValue(
                                            "message",
                                            e.target.value,
                                        );
                                        formikBag.setFieldTouched(
                                            "message",
                                            true,
                                            false,
                                        );
                                    }}
                                />
                            </Grid>
                            <Grid item>
                                <Box
                                    display="flex"
                                    justifyContent="flex-end"
                                    flexDirection="row"
                                    alignItems="center"
                                >
                                    <Link to={role === "BOOKER" ? "/how-it-works/booker" : "/how-it-works/talent"} className={classes.link}>View our FAQ</Link>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="secondary"
                                        disabled={
                                            formikBag.isSubmitting
                                        }
                                    >
                                        Send
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </form>
                )}
            />
            <Dialog
					fullWidth={true}
					maxWidth="sm"
					scroll="body"
					open={support.sendInquirySucceeded}
					onClose={handleSaveClose}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<DialogContent>
						<DialogContentText
							color="textPrimary"
							id="alert-dialog-description"
						>
							Your request was sent succesfully.
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleSaveClose} color="default" autoFocus>
							Ok
						</Button>
					</DialogActions>
				</Dialog>
				<Dialog
					fullWidth={true}
					maxWidth="sm"
					scroll="body"
					open={support.sendInquiryFailure}
					onClose={handleFailClose}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<DialogContent>
						<DialogContentText
							color="textPrimary"
							id="alert-dialog-description"
						>
							Something went wrong, please try again later.
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleFailClose} color="default" autoFocus>
							Ok
						</Button>
					</DialogActions>
				</Dialog>
        </>
    )
}

export default Support