import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import App from "./App";
import { storeRSK } from "./generic/helpers/store";
import { SnackbarProvider } from "notistack";
import { Route, BrowserRouter as Router } from "react-router-dom";
import { Global404 } from "./generic/Routes/404Route";
import ReactGA from "react-ga";
import { createBrowserHistory } from 'history';
const history = createBrowserHistory();


// Google Analiytics script code
const trackingId = "G-8RJMXK9REV";
ReactGA.initialize(trackingId);
// Initialize google analytics page view tracking
history.listen(location => {
    ReactGA.set({ page: location.pathname }); // Update the user's current page
    ReactGA.pageview(location.pathname); // Record a pageview for the given page
  });


if ("serviceWorker" in navigator) {
	navigator.serviceWorker
	  .register("./firebase-messaging-sw.js")
	  .then(function(registration) {
		console.log("Registration successful, scope is:", registration.scope);
	  })
	  .catch(function(err) {
		console.log("Service worker registration failed, error:", err);
	  });
}


ReactDOM.render(
	<Provider store={storeRSK}>
		<SnackbarProvider maxSnack={3}>
			<Router>
				<Route
					history={history}
					render={({ location }: any) =>
						location.state && location.state.is404 ? (
							<Global404 />
						) : (
							<App />
						)
					}
				/>
			</Router>
		</SnackbarProvider>
	</Provider>,
	document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
