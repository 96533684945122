import React from "react";
import { IListEventRequestProps, IItemEventRequest } from "./interfaces";
import {
	Button,
	Container,
	Grid,
	withStyles,
} from "@material-ui/core";
import { styles } from "./styles";
import EventRequestListItem from "../EventRequestItem/event-request-item";

class ListEventRequest extends React.Component<IListEventRequestProps> {
	componentDidMount() {
		this.props.listEventRequests();
	}

	render() {
		const {
			classes,
			listEventRequest,
			loadMoreEventRequests,
			getItemFullObject,
			closeUpdatedModal,
			updateEventRequest,
			openCancelModal,
			closeCancelModal,
			cancelEventRequest,
		} = this.props;
		return (
			<Container>
				<h4>Request Status</h4>
				<hr />
				<Grid
					container
					direction="row"
					className={classes.root}
					spacing={2}
				>
					{listEventRequest.isRequested && <span>Loading</span>}
					<Grid item xs={9}>
						{listEventRequest.isSuccess &&
							listEventRequest.items.map(
								(item: IItemEventRequest) => (
									<Grid item key={item.id}>
										<EventRequestListItem
											EventRequestItem={item}
											getItemFullObject={
												getItemFullObject
											}
											closeUpdatedModal={
												closeUpdatedModal
											}
											updateEventRequest={
												updateEventRequest
											}
											openCancelModal={openCancelModal}
											closeCancelModal={closeCancelModal}
											cancelEventRequest={
												cancelEventRequest
											}
										/>
									</Grid>
								),
							)}
					</Grid>

					{listEventRequest.items.length <
						listEventRequest.total_items && (
						<Button
							variant="outlined"
							onClick={loadMoreEventRequests}
						>
							See More
						</Button>
					)}
					{Object.keys(listEventRequest.error).length > 0 && (
						<div>Error</div>
					)}
				</Grid>
			</Container>
		);
	}
}

export default withStyles(styles)(ListEventRequest);
