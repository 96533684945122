import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { IAppState } from "../../generic/interfaces";
import { setPage } from "../../HomePage/homePageSlice";
import { EditAgentProfile } from "./EditAgentProfile";
import {
	editAgentProfile,
	getAgentProfile,
	closeSaveModal,
	openSaveModal,
	closeFailModal,
} from "./editAgentProfileSlice";

const mapStateToProps = (state: IAppState) => ({
	agentProfile: state.account.editAgentProfile,
	user: state.authentication.user,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
	editAgentProfile: (bandProfile: any) => {
		dispatch(editAgentProfile(bandProfile));
	},
	getAgentProfile: () => {
		dispatch(getAgentProfile());
	},
	setPage: (page: string) => {
		dispatch(setPage(page));
	},
	handleSaveClose: () => dispatch(closeSaveModal()),
	handleOpen: () => dispatch(openSaveModal()),
	handleFailClose: () => dispatch(closeFailModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditAgentProfile);
