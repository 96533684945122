import React, { Component } from "react";
import { ISearchBandsSlice } from "./interfaces";
import { BandCard } from "./BandCard";

export interface ISearchPageProps {
	authentication: any;
	searchBands: ISearchBandsSlice;
	filterBands: () => void;
	loadMore: () => void;
	favoriteToggle: (bandId: number, isFavorite: boolean) => void;
	changeBandStatus: (bandId: number, request_status: string) => void;
	setPage: (page: string) => void;
	updateUser: () => void;
	opened: boolean;
	closeSearchDialog: () => void;
}

class SearchBands extends Component<ISearchPageProps> {

	public componentDidMount() {
		this.props.setPage("search");
		this.props.filterBands();
		this.props.updateUser();
	}

	public render() {
		const {
			searchBands,
			authentication,
			favoriteToggle,
			changeBandStatus,
			opened,
			closeSearchDialog
		} = this.props;
		return (
			<BandCard
			opened={opened}
			closeSearchDialog={closeSearchDialog}
			searchBands={searchBands}
			authentication={authentication}
			favoriteToggle={favoriteToggle}
			changeBandStatus={changeBandStatus}
			isFavouritePage={false}
								/>
		);
	}
}

export default SearchBands;
