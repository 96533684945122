import { Genre, IBookerType, Media } from "./index";

export interface IMiniUser {
	id: number;
	username: string;
	email: string;
	fname: string;
	lname: string;
	photo: Media;
}

enum UserStatus {
	ACTIVE = "ACTIVE",
	NOT_ACTIVE = "NOT_ACTIVE",
}

export enum UserRole {
	BOOKER = "BOOKER",
	TALENT = "TALENT",
	AGENT = "AGENT",
	ADMIN = "ADMIN"
}

export interface IUser extends IMiniUser {
	api_token: string;
	status: UserStatus;
	role: UserRole;
	booker_type: IBookerType;
	location: string;
	university: string;
	sorority_fraternity: string;
	business_name: string;
	events_per_year: number;
	doc_info: string;
	genres: Genre[];
}
