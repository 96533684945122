import { createSlice, PayloadAction } from "redux-starter-kit";
import Services from "../generic/Services";

export interface ContactState {
	sendInquiryRequested: boolean;
	sendInquirySucceeded: boolean;
	sendInquiryFailure: boolean;
	contactModal: boolean;
	error: {};
}

let initialState: ContactState = {
	sendInquiryRequested: false,
	sendInquirySucceeded: false,
	sendInquiryFailure: false,
	contactModal: false,
	error: {},
};

const contactSlice = createSlice({
	slice: "contact",
	initialState,
	reducers: {
		sendInquiryRequested(state, action) {
			state.sendInquiryRequested = true;
			state.sendInquirySucceeded = false;
			state.sendInquiryFailure = false;
			state.contactModal = false;
		},
		sendInquirySucceeded(state, action: PayloadAction<boolean>) {
			state.sendInquirySucceeded = true;
			state.sendInquiryRequested = false;
			state.sendInquiryFailure = false;
			state.contactModal = true;
		},
		sendInquiryFailure(state, action: PayloadAction<boolean>) {
			state.sendInquirySucceeded = false;
			state.sendInquiryRequested = false;
			state.sendInquiryFailure = true;
			state.contactModal = false;
			state.error = action.payload;
		},
		closeContactModal(state, action) {
			state.contactModal = false;
		},
		openContactModal(state, action) {
			state.contactModal = true;
		},
	},
});

export const {
	sendInquiryRequested,
	sendInquirySucceeded,
	sendInquiryFailure,
	closeContactModal,
	openContactModal,
} = contactSlice.actions;

export default contactSlice.reducer;

export const sendInquiry = (inquiry: any): any => async (dispatch: any) => {
	try {
		dispatch(sendInquiryRequested());
		const res = await Services.users.sendInquiry(inquiry);
		dispatch(sendInquirySucceeded(res));
	} catch (err) {
		dispatch(sendInquiryFailure(err));
	}
};
