import React from "react";
import { Dialog, withStyles, Typography } from "@material-ui/core";
import { IConfirmationDialogProps } from "./interfaces";
import { CustomDialogTitle } from "../generic/components";
import { dialogStyles } from "./styles";

class ConfirmationDialog extends React.Component<IConfirmationDialogProps> {
	public render() {
		const {
			classes,
			handleClose,
			opened,
		} = this.props;
		return (
			<Dialog
				fullWidth
				maxWidth="sm"
				open={opened}
				scroll="body"
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				classes={{ paper: classes.dialogRoot }}
			>
				<CustomDialogTitle
					onClose={handleClose}
					id="alert-dialog-title"
				>
					<Typography variant="h4">
						Your password has been reset.
					</Typography>
					<Typography variant="h4">
						You may login with your updated credentials.
					</Typography>
				</CustomDialogTitle>
			</Dialog>
		);
	}
}

export default withStyles(dialogStyles)(ConfirmationDialog);
