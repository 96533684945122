import React from "react";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import CssBaseline from "@material-ui/core/CssBaseline";
import { theme } from "./theme";
import Header from "./Header";
import { Switch, Route, RouteComponentProps } from "react-router-dom";
import ConfirmAccount from "./ConfirmAccount";
import BandPage from "./BandPage";
import HowItWorks from "./HowItWorks";
import ContactPage from "./ContactPage";
import AboutPage from "./InfrmativePages/AboutPage/AboutPage";
import SearchPage from "./SearchPage";
import HomePage from "./HomePage";
import ListBookerRequest from "./EventRequest/ListEventRequest";
import { connect } from "react-redux";
import { fetchResources } from "./generic/Resources";
import Account from "./Account";
import ResetPassword from "./ResetPassword";
import { IAppState } from "./generic/interfaces";
import { withStyles, createStyles } from "@material-ui/core/styles";
import BookingBg from "./Assets/BookingBg.jpg";
import TalentBg from "./Assets/TalentBg.jpg";
import HomeBg from "./Assets/HomeBg.jpg";
import AboutBackground from "./Assets/AboutBackground.png";
import PrivateRoute from "./generic/Routes/PrivateRoute";
import { RedirectAs404 } from "./generic/Routes/404Route";
import Box from "@material-ui/core/Box";
import { Footer } from "./Footer";
import headerActions from "./Header/actions";
import SimpleDialog from "./FirstLogInPopUp/firstLogInPopUp";
import { userActions } from "./AuthBase/actions";
import Snackbar from "@material-ui/core/Snackbar";
import TermsAndConditionPage from "./SignupDialog/PublicTermsAndConditions";
import UpdateAgentBandRequest from "./UpdateAgentBandRequest/update-agent-band-request";
import PrivacyPolicyPage from "./PrivacyPolicy/PrivacyPolicy";
import BlogListPage from "./Blogs/blog-list";
import BlogPage from "./Blogs/blog";

const styles = createStyles({
    talent: {
        // hide background on small screen
        "@media only screen and (min-width: 600px)": {
            backgroundSize: "cover",
            background: "no-repeat center top",
            backgroundImage: `url(${TalentBg})`,
        },
    },
    booker: {
        // hide background on small screen
        "@media only screen and (min-width: 400px)": {
            backgroundSize: "cover",
            background: "no-repeat center top",
            backgroundImage: `url(${BookingBg})`,
        },
    },
    home: {
        // hide background on small screen
        "@media only screen and (min-width: 600px)": {
            backgroundSize: "100% 100%",
            background: "no-repeat right top",
            // backgroundImage: `url(${HomeBg})`,
        },
    },
    headerTransperant: {
        position: 'absolute',
        width: '100%',
        zIndex: 2,
        left: '0px',
        top: '0px',
        background: 'linear-gradient(0deg, rgba(7, 5, 33, 0.0001) 40%, #070521 80%)',
    },
    about: {
        // hide background on small screen
        "@media only screen and (min-width: 600px)": {
            backgroundSize: "cover",
            background: "no-repeat center top",
            backgroundImage: `url(${AboutBackground})`,
        },
    },
});

const bookerMessage =
    "New users must fill out their profile before sending a booking request. While we confirm your information, you'll be able to search and favorite bands.";
const talentMessage =
    "New users must fill out their profile and add a payment method to be listed in search results. While we confirm your information, take a look around and check out the How It Works page so you're ready to rock once approved";

class App extends React.Component<any> {
    state = {
        openSnackBar: false,
        message: "",
    };
    public async componentDidMount() {
        const { dispatch } = this.props;
        fetchResources(dispatch);
        if (this.props.authentication.isLoggedIn) {
            let request = indexedDB.open("REPSY_DB", 1);
            let db: any;
            let notificationStore: any;
            request.onsuccess = function (event: any) {
                db = event?.target?.result;
                var transaction = db?.transaction("notfications", "readwrite");
                notificationStore = transaction.objectStore("notfications");
                var db_op_req = notificationStore.getAll();
                db_op_req.onsuccess = function (event: any) {
                    event.target.result.forEach((item: any) => {
                        if (item.data.data.type === "NEW_MESSAGE") {
                            dispatch(headerActions.incrementMessageNotification(item.data.data.id));
                        } else {
                            dispatch(
                                headerActions.newNotification({
                                    message: item.data.notification.body,
                                    timestamp: new Date().getTime(),
                                    data: item.data.data,
                                })
                            );
                        }
                    });
                };
                notificationStore.clear();
            };
            request.onerror = function (event) {
                console.log("[onerror]", request.error);
            };
            const channel = new BroadcastChannel("sw-messages");
            channel.addEventListener("message", (event) => {
                console.log("Received", event.data);
                var transaction = db?.transaction("notfications", "readwrite");
                notificationStore = transaction.objectStore("notfications");
                notificationStore.clear();
                if (event.data.data.type === "NEW_MESSAGE") {
                    this.props.dispatch(headerActions.incrementMessageNotification(event.data.data.id));
                } else {
                    this.props.dispatch(
                        headerActions.newNotification({
                            message: event.data.notification.body,
                            timestamp: new Date().getTime(),
                            data: event.data.data,
                        })
                    );
                }
            });
        }
    }

    public handleClose = () => {
        this.props.dispatch(userActions.firstLogInClose());
    };
    public render() {
        const { classes } = this.props;

        return (
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <div
                    className={
                        this.props.appPage === "talent"
                            ? classes.talent
                            : this.props.appPage === "booker"
                                ? classes.booker
                                : this.props.appPage === "home"
                                    ? classes.home
                                    : this.props.appPage === "about"
                                        ? classes.about
                                        : ""
                    }>
                    <header className={
                        this.props.appPage === "home"
                            ? classes.headerTransperant
                            : ""
                    }>
                        <Header />
                    </header>
                    <Box minHeight="150vh">
                        <Switch>
                            <Route path="/events/requests" exact={true} render={(props) => <ListBookerRequest classes={{}} {...props} />} />
                            <Route path="/bands/:id" render={(props) => <BandPage {...props} />} />
                            <Route path="/confirm-account" exact={true} render={(props) => <ConfirmAccount {...props} />} />
                            <Route path="/search" exact={true} render={(props) => <SearchPage {...props} />} />
                            <Route path="/contact" render={(props) => <ContactPage {...props} />} />
                            <Route path="/about" render={(props) => <AboutPage {...props} />} />
                            <Route path="/blogs" render={(props) => <BlogListPage />} />
                            <Route path="/blog/:id" render={(props) => <BlogPage key={Date.now()} />} />
                            <Route path="/" exact={true} render={(props: RouteComponentProps) => <HomePage {...props} />} />
                            <Route path="/signup" exact={true} render={(props: RouteComponentProps) => <HomePage {...props} />} />
                            <Route path="/login" exact={true} render={(props: RouteComponentProps) => <HomePage {...props} />} />
                            <Route path="/how-it-works/:type" render={(props) => <HowItWorks {...props} />} />
                            <PrivateRoute path="/account" component={Account} />
                            <Route path="/reset-password" render={(props) => <ResetPassword {...props} />} />
                            <Route path="/update/band-request-status" render={(props) => <UpdateAgentBandRequest />} />

                            <Route path="/privacy" exact component={PrivacyPolicyPage} />
                            <Route path="/terms-conditions" exact component={TermsAndConditionPage} />

                            {/* KEEP THIS TWO ROUTES IN BOTTOM, FOLOOWING ROUTE IS FOR BANDS PERMALINK */}
                            <Route path="/:id" exact={true} render={(props) => <BandPage {...props} />} />
                            <Route component={RedirectAs404} />
                        </Switch>
                    </Box>
                    <Snackbar
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                        }}
                        open={this.props.notification?.snackBar?.open}
                        autoHideDuration={6000}
                        onClose={() => this.props.dispatch(headerActions.closeSnackBar())}
                        message={this.props.notification?.snackBar?.message}
                    />
                    <Footer />
                    {this.props.authentication.firstLogIn &&
                        <SimpleDialog
                            role={this.props.authentication.user.role}
                            //role={"BOOKER"}
                            open={this.props.authentication.firstLogIn}
                            //open={true}
                            ///message={this.props.authentication.user.role === "BOOKER" ? bookerMessage : talentMessage}
                            //message={bookerMessage}
                            onClose={this.handleClose}
                        />
                    }
                </div>
            </ThemeProvider>
        );
    }
}

const mapStateToProps = (state: IAppState) => ({
    authentication: state.authentication,
    appPage: state.homePage.page,
    notification: state.header.notification,
});

export default withStyles(styles)(connect(mapStateToProps)(App));
