import React, { useState } from "react";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import searchBarIcon from "../../Assets/Search Bar Icon.svg";

export interface SearchTextFieldProps {
	value: string;
	search: (text: string) => void;
	fullWidth?: boolean;
	placeholder?: string;
}

export const SearchTextField: React.FC<SearchTextFieldProps> = ({ search, value, ...rest }) => {
	const searchbuttonRef = React.createRef<any>();

	const [text, setText] = useState<string>(value);

	const handleKeyDown = (e: any) => {
		if (e.key === "Enter" && searchbuttonRef.current) {
			searchbuttonRef.current.focus();
		}
	};

	return (
		<OutlinedInput
			labelWidth={0}
			placeholder="filter events/requests"
			onChange={(e: any) => setText(e.target.value)}
			onKeyDown={handleKeyDown}
			value={text}
			margin="dense"
			endAdornment={
				<IconButton
					color="primary"
					size="medium"
					aria-label="Search"
					onClick={() => search(text)}
					ref={searchbuttonRef}>
					<Icon fontSize="large">
						<img alt="search icon" src={searchBarIcon} />
					</Icon>
				</IconButton>
			}
			{...rest}
		/>
	);
};
