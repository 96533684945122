import React from "react";
import { Divider, TextField, Grid, Link, Button } from "@material-ui/core";
import { FormHelperText } from "@material-ui/core";

interface IPricesFilterProps {
	values: {
		from: number | null;
		to: number | null;
	};
	savePrices: (prices: any) => void;
	handlePopoverClose: (e: any) => void;
}

class PricesFilter extends React.Component<IPricesFilterProps> {
	public state = {
		from: null,
		to: null,
		error: false,
	};

	public componentDidMount() {
		const { values } = this.props;
		this.setState({
			from: values.from,
			to: values.to,
		});
	}

	public handleChange = (name: string) => (e: any) => {
		e.persist();
		if (e.target.value.length < 1) {
			this.setState({
				[name]: null,
			});
		} else {
			const last = e.target.value[e.target.value.length - 1];
			if (e.target.value.length <= 10 && last >= "0" && last <= "9") {
				this.setState({
					[name]: e.target.value,
				});
			}
		}
	};

	public handleClear = (e: any) => {
		this.setState({
			from: null,
			to: null,
		});
	};

	public handleSubmit = (e: any) => {
		if ((Number(this.state.from) || 0) < (Number(this.state.to) || 10000000)) {
			this.props.savePrices(this.state);
			this.props.handlePopoverClose(e);
		} else {
			this.setState({
				error: true,
			});
		}
	};

	public render() {
		return (
			<Grid container direction="column" spacing={2}>
				<Grid item>
					<Grid container direction="row" alignItems="center" spacing={2}>
						<Grid item>
							<TextField
								autoComplete="off"
								id="price_from"
								name="price_from"
								variant="outlined"
								color="secondary"
								placeholder="Min"
								margin="dense"
								value={this.state.from || ""}
								onChange={this.handleChange("from")}
								style={{ width: "100px" }}
							/>
						</Grid>
						<Grid item>
							<Divider
								style={{
									width: "2em",
									margin: "25px 10px",
									height: "2px",
								}}
								color="secondary"
							/>
						</Grid>
						<Grid item>
							<TextField
								autoComplete="off"
								id="price_to"
								name="price_to"
								variant="outlined"
								color="secondary"
								placeholder="Max"
								margin="dense"
								value={this.state.to || ""}
								onChange={this.handleChange("to")}
								style={{ width: "100px" }}
							/>
						</Grid>
					</Grid>
				</Grid>

				<Grid>
					{this.state.error && (
						<FormHelperText error={this.state.error || ""} variant="outlined">
							Error: {this.state.error}
							Invalid price range
						</FormHelperText>
					)}
				</Grid>
				
				<Grid item>
					<Grid container direction="row" justify="flex-end" alignItems="center" spacing={2}>
						<Grid item>
							<Link color="textPrimary" component="button" onClick={this.handleClear}>
								Clear Filters
							</Link>
						</Grid>
						<Grid item>
							<Button size="small" variant="contained" color="secondary" onClick={this.handleSubmit}>
								Apply
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		);
	}
}

export default PricesFilter;
