import {
	ListEventRequestActionType,
	IListEventRequestAction,
	IItemRejectEventRequest,
} from "../../interfaces";
import { generateConfirmReducer } from "../../../ConfirmationDialog/reducer";

const rejectModalOpened = generateConfirmReducer("EVENT_REQUEST_REJECT_");

const rejectIsRequested = (state = false, action: IListEventRequestAction) => {
	switch (action.type) {
		case ListEventRequestActionType.REJECT_EVENT_REQUEST:
			return true;
		case ListEventRequestActionType.REJECT_EVENT_SUCCESS:
		case ListEventRequestActionType.REJECT_EVENT_FAILURE:
			return false;
		default:
			return state;
	}
};

const rejectIsSucceeded = (state = false, action: IListEventRequestAction) => {
	switch (action.type) {
		case ListEventRequestActionType.REJECT_EVENT_SUCCESS:
			return true;
		case ListEventRequestActionType.REJECT_EVENT_REQUEST:
		case ListEventRequestActionType.REJECT_EVENT_FAILURE:
			return false;
		default:
			return state;
	}
};

const rejectError = (state = null, action: IListEventRequestAction) => {
	switch (action.type) {
		case ListEventRequestActionType.REJECT_EVENT_FAILURE:
			return action.payload;
		case ListEventRequestActionType.REJECT_EVENT_REQUEST:
			return null;
		default:
			return state;
	}
};

export const rejectRequestReducer = (
	state: IItemRejectEventRequest,
	action: IListEventRequestAction,
) => {
	return {
		rejectModalOpened: rejectModalOpened(
			state && state.rejectModalOpened,
			action,
		),
		rejectIsRequested: rejectIsRequested(
			state && state.rejectIsRequested,
			action,
		),
		rejectIsSucceeded: rejectIsSucceeded(
			state && state.rejectIsSucceeded,
			action,
		),
		rejectError: rejectError(state && state.rejectError, action),
	};
};
