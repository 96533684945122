import { connect } from "react-redux";
import ConfirmAccount from "./confirm-account";
import { IAppState } from "../generic/interfaces";
import headerActions from "../Header/actions";
import { ThunkDispatch } from "redux-thunk";

const mapStateToProps = (state: IAppState) => ({
	confirmAccountState: state.confirmAccount,
});

const mapDispatchToProps = (
	dispatch: ThunkDispatch<{}, {}, any>,
	ownProps: any,
) => ({
	openSignupModal: () => {
		dispatch(headerActions.openSignupModal());
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmAccount);
