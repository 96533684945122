import React, { useEffect, useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Services from "../generic/Services";
import { Box, Grid } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        unread: {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText,
            "font-weight": "bold",
            borderRadius: "3px",
            fontSize: "13px",
            display: "inline-block",
            padding: "5px 15px"
        },
        message: {
            "word-wrap": "break-word",
            width: "227px",
        },
    })
);

const UpdateAgentBandRequest: React.FC = () => {
    const [errorMessage, setErrorMessage] = useState<any>();
    useEffect(() => {
        Services.agent.updateAgentBandRequest(window.location.search).then(response => {
             console.log('response: ', response);
             //if(response.code === 200){
                setErrorMessage(response.user_message);     
             //}
		 }).catch(err => {
            console.log('response err: ', err);
            setErrorMessage(err.message);
         })
        
    }, [setErrorMessage])
    return (
        <>
            {errorMessage && 
            <Box mb="30px" style={{textAlign : "center"}} fontWeight="bold" fontFamily="Rubik" fontSize="30px">
                {errorMessage}
            </Box>
                
            }
        </>
    );
}

export default UpdateAgentBandRequest;