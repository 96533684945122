import { Theme, createStyles, makeStyles } from "@material-ui/core";

export const useAgentBandListStyles = makeStyles((theme: Theme) =>
	createStyles({
		paperLink: {
			width: "100%",
			textDecoration: "none",
			color: "#fff",
		},
		gutters: {
			padding: theme.spacing(3),
			paddingBottom: theme.spacing(1),
		},
		nested: {
			borderLeft: "70px solid transparent",
			fontSize: "1rem",
			marginBottom: theme.spacing(1),
		},
		nested2: {
			borderLeft: "70px solid transparent",
			'& p': {
				paddingTop: "0px",
				paddingLeft: "0px",
				paddingRight: "0px",
			},
			'& a': {
				fontSize: "1.0rem",
			},
			'& small': {
				float: "right",
				backgroundColor: "#E02020",
				width: "24px",
				height: "24px",
				borderRadius: "36px",
				textAlign: "center",
				lineHeight: "25px",
				fontSize: "12px",
			},
		},
		nested3: {
			borderLeft: "100px solid transparent",
			fontSize: "1rem",
			marginBottom: "10px",
		},
		removeLink: {
			borderLeft: "70px solid transparent",
			marginTop: "8px",
			'& a': {
				fontSize: "12px",
				color: "#F50000",
				textDecoration: "none",
				'& :hover': {
					color: "#ffffff",
				}
			},
		},
		padding: {
			paddingTop: theme.spacing(2),
			paddingBottom: theme.spacing(3),
		},
		mainSection: {
			marginTop: theme.spacing(3),
			fontSize: "1rem",
			fontWeight: "bold",
			borderLeft: `${theme.spacing(3)}px solid transparent`,
		},
		sidebarSubListMenu: {
			width: "100%",
			'& span': {
				float: "left",
			},
			'& svg': {
				float: "left",
				marginLeft: "3px",
				marginTop: "1px",
				transform: "rotate(0deg)",
				transition: "all 0.3s ease-in-out",
				cursor: "pointer",
				fontSize: "26px",
			},
		},
		sidebarSubAvtar: {
			'& img': {
				width: "40px",
				height: "40px",
				borderRadius: "45px",
				border: "2px solid rgb(100, 254, 204)",
			}
		},
		submenulistmain: {
			paddingLeft: "0px",
			margin: "0px",
			'& h6': {
				padding: "15px 0px 15px 15px",
				paddingBottom: "12px",
				'& small': {
					float: "right",
					backgroundColor: "#E02020",
					width: "24px",
					height: "24px",
					borderRadius: "36px",
					textAlign: "center",
					lineHeight: "25px",
					fontSize: "12px",
				},
			},
			'& ul': {
				paddingLeft: "0px",
				display: "none",
				transition: "all .3s ease",
			},
		},
		submenulist: {
			paddingLeft: "0px",
			margin: "0px",
			'& h6': {
				padding: "15px 0px 15px 15px",
				paddingBottom: "12px",
				'& small': {
					float: "right",
					backgroundColor: "#E02020",
					width: "24px",
					height: "24px",
					borderRadius: "36px",
					textAlign: "center",
					lineHeight: "25px",
					fontSize: "12px",
				},
			},
			'& svg': {
				transform: "rotate(-180deg) !important",
			},
			'& ul': {
				paddingLeft: "0px",
				display: "block",
				transition: "all .3s ease",
			},
		},
    })
);
