import React, { useEffect, useState } from "react";
import { uploadMedia, updateBandPhotoId } from "../../generic/Services/bands";
import { Button, CircularProgress, Grid, InputLabel, Typography } from "@material-ui/core";
import ImageCropperDialog from "../../generic/components/ImageCropperDialog";


const HeaderImageDropzone = (props) => {
    const { reloadGallery, setReloadGallery, bandData } = props;
    const [originalSelectedFile, setOriginalSelectedFile] = useState(null);
    const [croppedFile, setCroppedFile] = useState(null);
    const [thumbUrl, setThumbUrl] = useState(null);
    const [showUploadLoading, setShowUploadLoading] = useState(false);
    const [headerPhoto, setHeaderPhoto] = useState(bandData?.header_photo?.url);

    useEffect(() => {
        setThumbUrl(bandData?.photo?.thumbnail);
        setHeaderPhoto(bandData?.header_photo?.url);
    }, [bandData])

    const handleSubmit = async () => {
        setShowUploadLoading(true);
        await uploadHeaderImage('header', croppedFile);
        //await uploadHeaderImage('header-original', originalSelectedFile);
        setShowUploadLoading(false);
        setReloadGallery(!reloadGallery);
        setCroppedFile(null);
        setOriginalSelectedFile(null);
    }
    const showUploadButton = () => {
        if (originalSelectedFile) {
            return <Button
                type="submit"
                size="large"
                variant="contained"
                color="secondary"
                onClick={handleSubmit}
                disabled={false}>
                <span style={{paddingRight: "8px"}}>Upload</span> 
                {showUploadLoading && <CircularProgress size={15} />}
            </Button>
        }
        else{
            return null;
        }
    }



    const uploadHeaderImage = async (type, uploadFileData) => {
        const promise = await Promise.all([uploadMedia(uploadFileData, undefined, type)]);
        const [header] = promise;
        await header.json().then(async (res) => {
            if (res?.status == 'SUCCESS') {
                await updateBandPhotoId(res?.item?.id, bandData?.id, type);
            }
        });
    }

    const headerFileCallback = (cropperData) => {
        setCroppedFile(cropperData.croppedFile);
        setOriginalSelectedFile(cropperData.originalSelectedFile);
    }

    return (
        <>
            <Grid item xs={12} md={12} lg={12}>
                <InputLabel htmlFor="header_photo">
                    Header Image
                </InputLabel>
                <Typography variant="body2" style={{marginBottom:'10px'}}>
                    Image format must be{" "} PNG or JPG, less than 1500px x 1500px, 10mb file size
                </Typography>

            </Grid>
            <Grid item xs={12} md={12} lg={12} className="image-gallery-header-dropzone">
                <ImageCropperDialog showUpload={showUploadButton()} existingFile={headerPhoto} fileCallback={headerFileCallback} fileUrl={headerPhoto} ></ImageCropperDialog>
            </Grid>
        </>
    )
}

export default HeaderImageDropzone;