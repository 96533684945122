import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

interface WarningDialogProps {
	open: boolean;
	handleClose: any;
}

export const WarningDialoge: React.FC<WarningDialogProps> = ({ open, handleClose }) => (
	<Dialog
		open={open}
		onClose={handleClose}
		aria-labelledby="delete-dialog-title"
		aria-describedby="delete-dialog-description">
		<DialogContent>
			<DialogContentText color="textPrimary" id="delete-dialog-description">
			You need to complete both your profile and your billing information before your account can be approved.
			</DialogContentText>
		</DialogContent>
		<DialogActions>
			<Button onClick={handleClose} color="default" autoFocus>
				Ok
			</Button>
		</DialogActions>
	</Dialog>
);
