import React from "react";
import { ICONS } from "./icons";

/**
 * Render SVG Icons
 * @returns {string} SVG HTML elemt
 */
export const SVGIcon = (props: any) => {
	const icon = ICONS[props.name] || ICONS.CIRCLE;
	return (
		<svg
			width={props.width}
			style={props.style}
			className={props.className}
			xmlns="http://www.w3.org/2000/svg"
			viewBox={props.viewBox || icon.viewbox}
			xmlnsXlink="http://www.w3.org/1999/xlink"
		>
			{icon.path({ fill: props.fill })}
		</svg>
	);
};

SVGIcon.defaultProps = {
	name: "CIRCLE",
	style: { verticalAlign: "middle" },
	fill: "currentColor",
	viewBox: null,
	width: "1em",
	className: "",
};
