import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Switch from "@material-ui/core/Switch";
import { changeUserNotifications } from "../../generic/Services/settings";

export interface Notification {
	id: number;
	name: string;
	type: string;
	isVisible: boolean;
}

interface SectionProps {
	toggleSwitch: boolean;
	title: string;
	description: string;
	notifications: Notification[] | undefined;
	setToggleSwitch: (toggleSwitch: boolean) => void;
}

export const Section: React.FC<SectionProps> = ({
	title,
	description,
	notifications,
	toggleSwitch,
	setToggleSwitch,
}) => {
	const handleChange = (id: number) => {
		changeUserNotifications({ notification: { id } })
			.then((res) => {
				setToggleSwitch(!toggleSwitch);
			})
			.catch((err) => {
				console.log("error: ", err);
			});
	};

	return (
		<>
			<InputLabel>{title}</InputLabel>
			<p>{description}</p>
			<Box mt={5} mb={5}>
				<Grid container spacing={2}>
					{notifications?.map((item: Notification) => {
						return (
							<React.Fragment key={item.id}>
								<Grid item xs={6} md={3} lg={3}>
									<InputLabel>{item.type}</InputLabel>
								</Grid>
								<Grid item xs={6} md={9} lg={9}>
									<Switch
										checked={item.isVisible}
										onChange={() => handleChange(item.id)}
										value="SMS"
										color="secondary"
										inputProps={{ "aria-label": "secondary checkbox" }}
									/>
								</Grid>
							</React.Fragment>
						);
					})}
				</Grid>
			</Box>
		</>
	);
};
