import React, { useEffect } from "react";
import { Avatar, Box, Button, Dialog, Grid, OutlinedInput, Typography } from "@material-ui/core";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import Services from "../../Services";
import { CustomDialogTitle } from "../DialogTitle/dialog-title";
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		large: {
			width: theme.spacing(8),
			height: theme.spacing(8),
			marginRight: "15px",
			'& img': {
				borderRadius: "70px",
				border: "4px solid rgb(255, 255, 255)",
			},
		},
		themeBtn: {
			fontWeight: 500,
			fontSize: "0.9rem",
		},
		paragraphFont: {
			fontSize: "15px",
			color: "#ddd",
		},
		Link: {
			display: "flex",
			alignItems: "center",
			color: "#0F24FD",
			backgroundColor: "#64FECC",
			padding: "5px 20px",
			fontSize: "1rem",
			fontFamily: "Rubik",
			fontWeight: "bold",
			borderRadius: "4px",
			textDecoration: "none",
			margin: 10,
			height: 35,
			"&:hover": {
				backgroundColor: "#64FECC"
			},
		},
	}),
);


export interface InviteAgentDialogProps {
}

export const InviteAgentDialog: React.FC<InviteAgentDialogProps> = ({
}) => {
    const [inviteDialogOpen, setinviteDialogOpen] = React.useState(false);
    const classes = useStyles();
    const [inviteEmail, setInviteEmail] = React.useState('');
	const [showEmailError, setshowEmailError] = React.useState('');
	const [showEmailExistMessage, setshowEmailExistMessage] = React.useState('');

	useEffect(() => {
    }, []);
    
    const sendEmailInvitationToBand = () => {
		setshowEmailError('')
		setshowEmailExistMessage('');
		if(validEmail(inviteEmail))
		{
		Services.agent.sendInvitationToAgent(inviteEmail)
					.then((res: any) => {
                        console.log('res: ', res)
                        setinviteDialogOpen(false);
					})
					.catch((err) => {
						if(err.code === 400)
						{
							setshowEmailExistMessage(err.message)
						}
						console.log(err)
					});
		}
		else
		{
			setshowEmailError('Invalid Email!')
		}
		
	}

	const validEmail = (e: string) => {
		var filter = /^\s*[\w\-\+_]+(\.[\w\-\+_]+)*\@[\w\-\+_]+\.[\w\-\+_]+(\.[\w\-\+_]+)*\s*$/;
		return String(e).search (filter) != -1;
	}

    
    return (
        <>
				<Grid container spacing={3}>
					<Grid item xs={12} md={12} lg={12} alignItems="center" style={{ padding: "20px 0px 20px 0px" }}>
						<Typography classes={{ root: classes.gutters }}>
							<Box component="div" display="flex" alignItems="center" justifyContent="flex-start">
								<strong>Already have an agent?</strong>
								<Button onClick={() => {
									setinviteDialogOpen(true);
								}} color="secondary" variant="contained" style={{ padding: "6px 20px", marginLeft: "15px" }} className={classes.themeBtn}>Invite Agent</Button>
							</Box>
						</Typography>
					</Grid>
				</Grid>
				<Grid container direction="column" spacing={2}>
					<Dialog
						fullWidth={true}
						maxWidth="md"
						open={inviteDialogOpen}
						scroll="body"
						onClose={() => {
							setinviteDialogOpen(false);
						}}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
					>
						<CustomDialogTitle
					onClose={() => {
						setinviteDialogOpen(false);
					}}
					id="alert-dialog-title"
				>
					<Typography variant="h4">INVITE AN AGENT</Typography>

					</CustomDialogTitle>
					
					<Grid container direction="row" justify="center" alignItems="center">
						<Grid item xs={12} md={12} lg={11} alignItems="center">
							<Typography component="h6" align="center">Already have an agent? Send them an email to add them now.</Typography>
						</Grid>
					</Grid>
					
					<Box display="flex" flexWrap="wrap" justifyContent="center" py={4} px={5}>
						<Grid container direction="row" justify="center" alignItems="center">
							<Grid item xs={12} md={12} lg={11} alignItems="center">
								<OutlinedInput
									labelWidth={0}
									placeholder="Enter Email Address"
									onChange={(e: any) => setInviteEmail(e.target.value)}
									value={inviteEmail}
									fullWidth={true}
									margin="dense"
									endAdornment={
										<Button
											variant="contained"
											size="large"
											color="secondary"
											aria-label="Search"
											className={classes.themeBtn}
											style={{ width: 150 }}
											onClick={sendEmailInvitationToBand} >
											Send Invite
										</Button>
									}
								/>
                                {showEmailError !== '' && 
                                    <Typography><small style={{ padding: 0, backgroundColor: "rgba(100, 254, 204, 0.0)", color: "#FF0200", fontWeight: 500, fontStyle:"italic", fontSize: "12px" }}> {showEmailError}</small></Typography>
                                }
								{showEmailExistMessage !== '' && 
									<Typography><span style={{ padding: "15px 30px", display:"inline-block", width:"100%", textAlign: "center", marginTop: "20px", fontWeight: 300, fontStyle:"italic", fontSize: "18px" }}> {showEmailExistMessage}</span></Typography>
								}
							</Grid>
						</Grid>
					</Box>
					</Dialog>
			</Grid>
            </>
                    );
                };
                