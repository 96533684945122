import Services from "../Services";
import { Dispatch } from "redux";
import { ResourcesActionType } from "./interfaces";
import { messaging } from "../firebase/firebase"

export const genres = () => {
	return (dispatch: Dispatch) => {
		dispatch(request());
		Services.genres
			.list()
			.then(payload => {
				dispatch(success(payload));
			})
			.catch((error: any) => {
				dispatch(failure(error));
			});
	};

	function request() {
		return { type: ResourcesActionType.GENRES_REQUEST };
	}
	function success(payload: any) {
		return {
			type: ResourcesActionType.GENRES_SUCCESS,
			payload: payload.items,
		};
	}
	function failure(payload: any) {
		return { type: ResourcesActionType.GENRES_FAILURE, payload };
	}
};

export const states = () => {
	return (dispatch: Dispatch) => {
		dispatch(request());
		Services.states
			.list()
			.then(payload => {
				dispatch(success(payload));
			})
			.catch((error: any) => {
				dispatch(failure(error));
			});
	};

	function request() {
		return { type: ResourcesActionType.STATES_REQUEST };
	}
	function success(payload: any) {
		return {
			type: ResourcesActionType.STATES_SUCCESS,
			payload: payload.items,
		};
	}
	function failure(payload: any) {
		return { type: ResourcesActionType.STATES_FAILURE, payload };
	}
};

export const gigs = () => {
	return (dispatch: Dispatch) => {
		dispatch(request());
		Services.gigs
			.list()
			.then(payload => {
				dispatch(success(payload));
			})
			.catch((error: any) => {
				dispatch(failure(error));
			});
	};

	function request() {
		return { type: ResourcesActionType.GIGS_REQUEST };
	}
	function success(payload: any) {
		return {
			type: ResourcesActionType.GIGS_SUCCESS,
			payload: payload.items,
		};
	}
	function failure(payload: any) {
		return { type: ResourcesActionType.GIGS_FAILURE, payload };
	}
};

export const priceRange = () => {
	return (dispatch: Dispatch) => {
		dispatch(request());
		Services.priceRange
			.getBandsPriceList()
			.then(payload => {
				dispatch(success(payload));
			})
			.catch((error: any) => {
				dispatch(failure(error));
			});
	};

	function request() {
		return { type: ResourcesActionType.PRICE_RANGE_REQUEST };
	}
	function success(payload: any) {
		return {
			type: ResourcesActionType.PRICE_RANGE_SUCCESS,
			payload: payload.items,
		};
	}
	function failure(payload: any) {
		return { type: ResourcesActionType.PRICE_RANGE_FAILURE, payload };
	}
};

export const notificationSettings = () => {
	
	return (dispatch: Dispatch) => {
		dispatch(request());
		Services.notificationSettings
			.list()
			.then(payload => {
				dispatch(success(payload));
				try {
					if(messaging)
					{
						if (payload.items[2].isVisible) {
							if (Notification.permission === "granted") {
								messaging.getToken().then((token: any) => {
									localStorage.setItem("FCM_Token", token);
									Services.users.sendPushToken(token);
								}).catch((e: any) => {
									console.log(e);
								});
							} else {
								Notification.requestPermission((status) => {
									
									messaging.getToken().then((token: any) => {
										localStorage.setItem("FCM_Token", token);
										Services.users.sendPushToken(token);
									}).catch((e: any) => {
										console.log(e);
									});
								});
							}
						}
					}
				} catch (error) {
				}
			})
			.catch((error: any) => {
				dispatch(failure(error));
			});
	};

	function request() {
		return { type: ResourcesActionType.NOTIFICATION_SETTINGS_REQUEST };
	}
	function success(payload: any) {
		return {
			type: ResourcesActionType.NOTIFICATION_SETTINGS_SUCCESS,
			payload: payload.items,
		};
	}
	function failure(payload: any) {
		return { type: ResourcesActionType.NOTIFICATION_SETTINGS_FAILURE, payload };
	}
};
