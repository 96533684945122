import React, { useEffect } from "react";
import { Box, Container, Typography, Dialog, Grid } from "@material-ui/core";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { ContactForm } from "./ContactForm";
import * as Yup from "yup";
import { CustomDialogTitle } from "../generic/components";
import PageBG from "../Assets/Contact.jpg"
import { SuggestionDialog } from "../generic/components/SuggestionDialog/suggestion-dialog";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		dialogRoot: {
			border: "1px solid",
			borderColor: theme.palette.secondary.main,
		},
		page: {
			backgroundImage: `url(${PageBG})`,
			height: "550px",
			backgroundPosition: "top",
			backgroundSize: "cover",
			backgroundRepeat: "no-repeat",
		}
	}),
);

const validationSchema = Yup.object({
	name: Yup.string().required("Name field is required"),
	email: Yup.string()
		.email("Enter a valid email")
		.required("Email is required"),
	message: Yup.string().required("Message field is required"),
});

export interface ContactProps {
	contactPage: any;
	sendInquiry: (inquiry: any) => void;
	setPage: (page: string) => void;
	handleClose: () => any;
	handleOpen: () => any;
}

export const Contact: React.FC<ContactProps> = ({
	contactPage,
	handleClose,
	handleOpen,
	setPage,
	sendInquiry,
}) => {
	const classes = useStyles();

	useEffect(() => {
		setPage("contact");
	}, [setPage]);

	const initialValues = {
		name: "",
		email: "",
		message: "",
	};

	return (
			<Container maxWidth="lg">
				<Box
					display="flex"
					justifyContent="center"
					alignItems="center"
					flexDirection="row"
					fontFamily="Rubik"
					fontSize={72}
					fontWeight={800}
					className={classes.page}
				>
					Contact
				</Box>
				<Grid item xs={12} sm={12} md={12} style={{ marginBottom: '40px' }}>
					<Typography component="h6">
						Have a questions or need to get in touch with us? Drop us a line below.<br/>
						Looking for something specific? Submit a band or genre suggestion to us <SuggestionDialog viewType="contact" />
					</Typography>
				</Grid>
				<ContactForm
					handleOpen={handleOpen}
					initialValues={initialValues}
					validationSchema={validationSchema}
					handleOnSubmit={(inquiry: any) => {
						sendInquiry(inquiry);
					}}
				/>
				<Dialog
					fullWidth={true}
					maxWidth="sm"
					scroll="body"
					open={(contactPage && contactPage.contactModal) || false}
					onClose={handleClose}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
					classes={{ paper: classes.dialogRoot }}
				>
					<CustomDialogTitle
						onClose={handleClose}
						id="alert-dialog-title"
					>
						<Typography variant="h4">
							Thanks for contacting us!
						</Typography>
						<Typography>Your inquiry is sent to the admin.</Typography>
						<Typography>
							We will get back to you as soon as possible.
						</Typography>
					</CustomDialogTitle>
				</Dialog>
			</Container>
	);
};
