export const generateConfirmReducer = (prefix: string) => {
	const isOpened = (state = false, action: any) => {
		switch (action.type) {
			case prefix + "CONFIRM_DIALOG_OPEN":
				return true;
			case prefix + "CONFIRM_DIALOG_CLOSE":
				return false;
			default:
				return state;
		}
	};

	return isOpened;
};
