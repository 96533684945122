import { createSlice } from "redux-starter-kit";
import Services from "../../generic/Services";
import { userActions } from "../../AuthBase/actions";

export interface TalentProfileObject {
	id: number;
	name: string;
	location: string;
	state: { id: number };
	price_from: number;
	price_to: number;
	size: number;
	bio: string;
	social_links: Array<{ type: string; url: string }>;
	featured_songs: Array<{ name: string; url: string }>;
	genres: Array<{ id: number }>;
	preferred_gig_types: Array<{ id: number }>;
	willing_to_travel: Array<{ id: number }>;
	photo: { id: number; url: string };
	hospitality_and_production_rider: { id: number; url: string };
	header_photo: { id: number; url: string };
	deposit: number;
	status: string;
	created_by: { phone_number: string }
}

export interface TalentState {
	talentProfileRequested: boolean;
	talentProfileSucceeded: boolean;
	talentProfileFailure: boolean;

	addTalentProfileRequested: boolean;
	addTalentProfileSucceeded: boolean;
	addTalentProfileFailure: boolean;

	editTalentProfileRequested: boolean;
	editTalentProfileSucceeded: boolean;
	editTalentProfileFailure: boolean;

	saveModal: boolean;
	error: {
		code: number;
		message: string;
		errors: [];
	};
	talentProfile: {
		status: string;
		server_message: string;
		user_message: string;
		items: TalentProfileObject[];
	};
}

const initialState: TalentState = {
	talentProfileRequested: false,
	talentProfileSucceeded: false,
	talentProfileFailure: false,

	addTalentProfileRequested: false,
	addTalentProfileSucceeded: false,
	addTalentProfileFailure: false,

	editTalentProfileRequested: false,
	editTalentProfileSucceeded: false,
	editTalentProfileFailure: false,

	saveModal: false,
	error: {
		code: 0,
		message: "",
		errors: [],
	},
	talentProfile: {
		status: "",
		server_message: "",
		user_message: "",
		items: [],
	},
};

const talentProfileSlice = createSlice({
	slice: "talentProfile",
	initialState,
	reducers: {
		talentProfileRequested(state, action) {
			state.talentProfileRequested = true;
			state.talentProfileSucceeded = false;
			state.talentProfileFailure = false;
		},
		talentProfileSucceeded(state, action) {
			state.talentProfileSucceeded = true;
			state.talentProfileRequested = false;
			state.talentProfileFailure = false;
			state.talentProfile = action.payload;
		},
		talentProfileFailure(state, action) {
			state.talentProfileSucceeded = false;
			state.talentProfileRequested = false;
			state.talentProfileFailure = true;
			state.error = action.payload;
		},
		addTalentProfileRequested(state, action) {
			state.addTalentProfileRequested = true;
			state.addTalentProfileSucceeded = false;
			state.addTalentProfileFailure = false;
			state.saveModal = false;
		},
		addTalentProfileSucceeded(state, action) {
			state.addTalentProfileSucceeded = true;
			state.addTalentProfileRequested = false;
			state.addTalentProfileFailure = false;
			state.saveModal = true;
			state.error = {
				code: 0,
				message: "",
				errors: [],
			};
		},
		addTalentProfileFailure(state, action) {
			state.addTalentProfileSucceeded = false;
			state.addTalentProfileRequested = false;
			state.addTalentProfileFailure = true;
			state.error = action.payload;
			state.saveModal = false;
		},
		editTalentProfileRequested(state, action) {
			state.editTalentProfileRequested = true;
			state.editTalentProfileSucceeded = false;
			state.editTalentProfileFailure = false;
			state.saveModal = false;
		},
		editTalentProfileSucceeded(state, action) {
			state.editTalentProfileSucceeded = true;
			state.editTalentProfileRequested = false;
			state.editTalentProfileFailure = false;
			state.saveModal = true;
			state.error = {
				code: 0,
				message: "",
				errors: [],
			};
		},
		editTalentProfileFailure(state, action) {
			state.editTalentProfileSucceeded = false;
			state.editTalentProfileRequested = false;
			state.editTalentProfileFailure = true;
			state.error = action.payload;
			state.saveModal = false;
		},
		unMount(state, action) {
			state.talentProfileSucceeded = false;
			state.talentProfileRequested = false;
			state.talentProfileFailure = false;
			state.talentProfile = {
				status: "",
				server_message: "",
				user_message: "",
				items: [],
			};
		},
		closeSaveModal(state, action) {
			state.saveModal = false;
		},
		openSaveModal(state, action) {
			state.saveModal = true;
		},
		closeFailModal(state, action) {
			state.editTalentProfileFailure = false;
			state.addTalentProfileFailure = false;
		},
	},
});

export const {
	talentProfileRequested,
	talentProfileSucceeded,
	talentProfileFailure,
	addTalentProfileRequested,
	addTalentProfileSucceeded,
	addTalentProfileFailure,
	editTalentProfileRequested,
	editTalentProfileSucceeded,
	editTalentProfileFailure,
	unMount,
	closeSaveModal,
	openSaveModal,
	closeFailModal,
} = talentProfileSlice.actions;

export default talentProfileSlice.reducer;

export const listMyBands = (): any => async (dispatch: any) => {
	try {
		dispatch(talentProfileRequested());
		const res = await Services.bands.listMyBands(1);
		dispatch(talentProfileSucceeded(res));
	} catch (err) {
		dispatch(talentProfileFailure(err));
	}
};

export const getBandById = (bandId: number): any => async (dispatch: any) => {
	try {
		dispatch(talentProfileRequested());
		const res = await Services.bands.getBandById(bandId, false);
		if(res.status === "SUCCESS"){
			res.items = [];
			res.items.push(res.item);
		}
		
		dispatch(talentProfileSucceeded(res));
	} catch (err) {
		dispatch(talentProfileFailure(err));
	}
};

export const addTalentProfile = (talentProfile: any): any => async (dispatch: any) => {
	try {
		dispatch(addTalentProfileRequested());
		const res = await Services.bands.addTalentProfile(talentProfile);
		let user = JSON.parse(localStorage.getItem("user") || "");
		user = { ...user, photo: res.item.photo, status: res.item.status, band_profile_status: "" };
		dispatch(userActions.updateTalentStatuses(user));
		user = JSON.stringify(user);
		localStorage.setItem("user", user);
		dispatch(userActions.editUser(res.item));

		listMyBands()(dispatch);
		dispatch(addTalentProfileSucceeded(res));
	} catch (err) {
		dispatch(addTalentProfileFailure(err));
	}
};

export const editTalentProfile = (talentProfile: any, id: number): any => async (dispatch: any) => {
	try {
		dispatch(editTalentProfileRequested());
		const res = await Services.bands.editTalentProfile(talentProfile, id);
		dispatch(editTalentProfileSucceeded(res));
		let user = JSON.parse(localStorage.getItem("user") || "");
		user = { ...user, photo: res.item.photo, status: res.item.status, band_profile_status: "" };
		dispatch(userActions.updateTalentStatuses(user));
		user = JSON.stringify(user);
		localStorage.setItem("user", user);
		dispatch(userActions.editUser(res.item));
		listMyBands()(dispatch);
	} catch (err) {
		dispatch(editTalentProfileFailure(err));
	}
};
