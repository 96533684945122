import { refineResponse } from "../helpers/refineResponse";
import { headers, baseUrl } from "../../AuthBase/helpers/auth-header";

export const updatePassword = (passwordFormObject: any) => {
	const requestOptions = {
		method: "POST",
		headers: { ...headers() },
		body: JSON.stringify(passwordFormObject),
	};

	return fetch(`${baseUrl}users/change-password`, requestOptions).then(refineResponse);
};

export const updateEmail = (emailFormObject: any) => {
	const requestOptions = {
		method: "POST",
		headers: { ...headers() },
		body: JSON.stringify(emailFormObject),
	};

	return fetch(`${baseUrl}users/change-email`, requestOptions).then(refineResponse);
};

export const listNotifications = () => {
	const requestOptions = {
		method: "GET",
		headers: { ...headers() },
	};

	return fetch(`${baseUrl}users/list-notification`, requestOptions).then(refineResponse);
};

export const changeUserNotifications = (id: { notification: { id: number } }) => {
	const requestOptions = {
		method: "POST",
		headers: { ...headers() },
		body: JSON.stringify(id),
	};

	return fetch(`${baseUrl}users/notification`, requestOptions).then(refineResponse);
};
