import { createSlice, PayloadAction } from "redux-starter-kit";
import Services from "../generic/Services";

export interface HowItWorksState {
	howItWorksRequested: boolean;
	howItWorksSucceeded: boolean;
	howItWorksFailure: boolean;

	quotesRequested: boolean;
	quotesSucceeded: boolean;
	quotesFailure: boolean;

	testimonialsRequested: boolean;
	testimonialsSucceeded: boolean;
	testimonialsFailure: boolean;

	benefitsRequested: boolean;
	benefitsSucceeded: boolean;
	benefitsFailure: boolean;

	faqsRequested: boolean;
	faqsSucceeded: boolean;
	faqsFailure: boolean;

	headersRequested: boolean;
	headersSucceeded: boolean;
	headersFailure: boolean;

	faqs: any;
	benefits: any;
	quotes: any;
	testimonials: any;
	howItWorks: any;
	headers: any;
	error: {};
}

let initialState: HowItWorksState = {
	howItWorksRequested: false,
	howItWorksSucceeded: false,
	howItWorksFailure: false,

	quotesRequested: false,
	quotesSucceeded: false,
	quotesFailure: false,

	testimonialsRequested: false,
	testimonialsSucceeded: false,
	testimonialsFailure: false,

	benefitsRequested: false,
	benefitsSucceeded: false,
	benefitsFailure: false,

	faqsRequested: false,
	faqsSucceeded: false,
	faqsFailure: false,

	headersRequested: false,
	headersSucceeded: false,
	headersFailure: false,

	faqs: null,
	benefits: null,
	quotes: null,
	testimonials: null,
	howItWorks: null,
	headers: null,
	error: {},
};

const howItWorksPageSlice = createSlice({
	slice: "howItWorks",
	initialState,
	reducers: {
		howItWorksRequested(state, action) {
			state.howItWorksRequested = true;
			state.howItWorksSucceeded = false;
			state.howItWorksFailure = false;
		},
		howItWorksSucceeded(state, action) {
			state.howItWorksRequested = false;
			state.howItWorksSucceeded = true;
			state.howItWorksFailure = false;
			state.howItWorks = action.payload;
		},
		howItWorksFailure(state, action) {
			state.howItWorksRequested = false;
			state.howItWorksSucceeded = false;
			state.howItWorksFailure = true;
			state.error = action.payload;
		},
		quotesRequested(state, action) {
			state.quotesRequested = true;
			state.quotesSucceeded = false;
			state.quotesFailure = false;
		},
		quotesSucceeded(state, action: PayloadAction<boolean>) {
			state.quotesRequested = false;
			state.quotesSucceeded = true;
			state.quotesFailure = false;
			state.quotes = action.payload;
		},
		quotesFailure(state, action: PayloadAction<boolean>) {
			state.quotesRequested = false;
			state.quotesSucceeded = false;
			state.quotesFailure = true;
			state.error = action.payload;
		},
		testimonialsRequested(state, action) {
			state.testimonialsRequested = true;
			state.testimonialsSucceeded = false;
			state.testimonialsFailure = false;
		},
		testimonialsSucceeded(state, action: PayloadAction<boolean>) {
			state.testimonialsRequested = false;
			state.testimonialsSucceeded = true;
			state.testimonialsFailure = false;
			state.testimonials = action.payload;
		},
		testimonialsFailure(state, action: PayloadAction<boolean>) {
			state.testimonialsRequested = false;
			state.testimonialsSucceeded = false;
			state.testimonialsFailure = true;
			state.error = action.payload;
		},
		benefitsRequested(state, action) {
			state.benefitsRequested = true;
			state.benefitsSucceeded = false;
			state.benefitsFailure = false;
		},
		benefitsSucceeded(state, action: PayloadAction<boolean>) {
			state.benefitsRequested = false;
			state.benefitsSucceeded = true;
			state.benefitsFailure = false;
			state.benefits = action.payload;
		},
		benefitsFailure(state, action: PayloadAction<boolean>) {
			state.benefitsRequested = false;
			state.benefitsSucceeded = false;
			state.benefitsFailure = true;
			state.error = action.payload;
		},
		faqsRequested(state, action) {
			state.faqsRequested = true;
			state.faqsSucceeded = false;
			state.faqsFailure = false;
		},
		faqsSucceeded(state, action: PayloadAction<boolean>) {
			state.faqsRequested = false;
			state.faqsSucceeded = true;
			state.faqsFailure = false;
			state.faqs = action.payload;
		},
		faqsFailure(state, action: PayloadAction<boolean>) {
			state.faqsRequested = false;
			state.faqsSucceeded = false;
			state.faqsFailure = true;
			state.error = action.payload;
		},
		headersRequested(state, action) {
			state.headersRequested = true;
			state.headersSucceeded = false;
			state.headersFailure = false;
		},
		headersSucceeded(state, action: PayloadAction<boolean>) {
			state.headersRequested = false;
			state.headersSucceeded = true;
			state.headersFailure = false;
			state.headers = action.payload;
		},
		headersFailure(state, action: PayloadAction<boolean>) {
			state.headersRequested = false;
			state.headersSucceeded = false;
			state.headersFailure = true;
			state.error = action.payload;
		},
	},
});

export const {
	howItWorksRequested,
	howItWorksSucceeded,
	howItWorksFailure,
	quotesRequested,
	quotesSucceeded,
	quotesFailure,
	testimonialsRequested,
	testimonialsSucceeded,
	testimonialsFailure,
	benefitsRequested,
	benefitsSucceeded,
	benefitsFailure,
	faqsRequested,
	faqsSucceeded,
	faqsFailure,
	headersRequested,
	headersSucceeded,
	headersFailure,
} = howItWorksPageSlice.actions;

export default howItWorksPageSlice.reducer;

export const getHowItWorks = (type: string): any => async (dispatch: any) => {
	try {
		dispatch(howItWorksRequested());
		const res = await Services.events.getHowItWorks(type);
		dispatch(howItWorksSucceeded(res));
	} catch (err) {
		dispatch(howItWorksFailure(err));
	}
};

export const getQuotes = (): any => async (dispatch: any) => {
	try {
		dispatch(quotesRequested());
		const res = await Services.events.getQuotes();
		dispatch(quotesSucceeded(res));
	} catch (err) {
		dispatch(quotesFailure(err));
	}
};

export const getTestimonials = (): any => async (dispatch: any) => {
	try {
		dispatch(testimonialsRequested());
		const res = await Services.events.getTestimonials();
		dispatch(testimonialsSucceeded(res));
	} catch (err) {
		dispatch(testimonialsFailure(err));
	}
};

export const getBenefits = (type: string): any => async (dispatch: any) => {
	try {
		dispatch(benefitsRequested());
		const res = await Services.events.getBenefits(type);
		dispatch(benefitsSucceeded(res));
	} catch (err) {
		dispatch(benefitsFailure(err));
	}
};

export const getFaqs = (type: string): any => async (dispatch: any) => {
	try {
		dispatch(faqsRequested());
		const res = await Services.events.getFaqs(type);
		dispatch(faqsSucceeded(res));
	} catch (err) {
		dispatch(faqsFailure(err));
	}
};

export const getMainText = (type: string): any => async (dispatch: any) => {
	try {
		dispatch(headersRequested());
		const res = await Services.events.getMainText();
		res.items.map((item: any) => {
			if(item.role.toLowerCase() === type){
				dispatch(headersSucceeded(item));
			}
			return 0;
		})
	} catch (err) {
		dispatch(headersFailure(err));
	}
};
