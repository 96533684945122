import React, { useEffect } from "react";
import { Avatar, Box, Button, Dialog, Grid, OutlinedInput, Typography, Divider } from "@material-ui/core";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { RouteComponentProps, useHistory } from "react-router-dom";
import Services from "../../generic/Services";
import { CustomDialogTitle } from "../../generic/components";
import { InviteAgentDialog } from "../../generic/components/InviteAgentDialog/invite-agent-dialog";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		large: {
			width: theme.spacing(8),
			height: theme.spacing(8),
			marginRight: "15px",
			'& img': {
				borderRadius: "70px",
				border: "4px solid rgb(255, 255, 255)",
			},
		},
		themeBtn: {
			fontWeight: 500,
			fontSize: "0.9rem",
		},
		paragraphFont: {
			fontSize: "15px",
			color: "#ddd",
		},
		Link: {
			display: "flex",
			alignItems: "center",
			color: "#0F24FD",
			backgroundColor: "#64FECC",
			padding: "5px 20px",
			fontSize: "1rem",
			fontFamily: "Rubik",
			fontWeight: "bold",
			borderRadius: "4px",
			textDecoration: "none",
			margin: 10,
			height: 35,
			"&:hover": {
				backgroundColor: "#64FECC"
			},
		},
	}),
);

export interface TalentsAgentProps {
	talentsAgent?: any;
	setLoc: (location: string) => void;
	listTalentsAgent: () => void;
}

export const TalentsAgentRequest: React.FC<TalentsAgentProps &
	RouteComponentProps> = ({
	setLoc,
}) => {
	const classes = useStyles();
	let history = useHistory();
	const [data, setData] = React.useState<any>();
	const [hasAgentLinked, setHasAgentLinked] = React.useState(false);
	const [requestRemovedData, setrequestRemovedData] = React.useState(false);
	
	const removeRequest = async (agentId: number)=>{
		Services.agent.rejectAgentRequet(agentId).then(response => {
			setrequestRemovedData(!requestRemovedData);
            setLoc("/account/my-agent");
		})
	}
	useEffect(() => {
		setLoc("/account/my-agent");
		Services.agent.talentsAgentRequestList().then(response => {
			setHasAgentLinked(response.has_agent);
			setData(response.item)
		})
	}, [setLoc, setData, setrequestRemovedData, requestRemovedData]);

	const approveAgent = async (agentId: number)=>{
		Services.agent.approveBandAgent(agentId).then(response => {
            history.push('/account/my-agent');
		})
	}
	
	return (
		<>
			<InviteAgentDialog />
			{data  &&
				data.map((item: any) => (
				<>
				<Grid container spacing={3}>
					<Grid item xs={12} md={12} lg={12} alignItems="center" style={{ padding: "25px 0px 15px 0px" }}>
						<Divider style={{ width: '100%', margin: '15px auto 0px auto', backgroundColor:'#5BFFCB', height: '1px' }} color="secondary" variant="middle" />
					</Grid>
					<Grid item xs={12} style={{ padding: "10px 0px" }}>
						<Grid container direction="row" justify="center" alignItems="center">
							<Grid item xs={6} style={{ padding: "10px 0px" }} direction="row" justify="center" alignItems="center">
								<Box component="div" display="flex" alignItems="center" justifyContent="flex-start">
									<Avatar alt={item.agent.fname} src={item.agent.photo && item.agent.photo.thumbnail} className={classes.large} />
									<Typography variant="subtitle2" classes={{ root: classes.gutters }}>
										<strong style={{ fontWeight: "bold", textTransform: "uppercase", fontSize: "18px" }}>{item.agent.fname} {item.agent.lname}</strong> 
									</Typography>
								</Box>
							</Grid>
							<Grid item xs={6} style={{ padding: "10px 0px", textAlign: "right" }}>
								<Button onClick={() => {
										removeRequest(item.agent.id);
									}} color="secondary" variant="outlined" className={classes.themeBtn} style={{ padding: "4px 20px", border: "2px solid rgba(100, 254, 204, 1.0)" }}>Remove Request</Button>
								{!hasAgentLinked && 
									<Button onClick={() => {
										approveAgent(item.agent.id);
									}} color="secondary" variant="contained" className={classes.themeBtn} style={{ padding: "6px 20px", marginLeft: "12px" }}>Approve Agent</Button>
								}
								
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={3} style={{ padding: "6px 0px" }}>
						<Typography variant="body1" style={{ fontWeight: "bold" }}>First Name</Typography>
					</Grid>
					<Grid xs={9} style={{ padding: "6px 0px" }}>
						<Typography variant="body1">{item.agent.fname}</Typography>
					</Grid>
					<Grid xs={3} style={{ padding: "6px 0px" }}>
						<Typography variant="body1" style={{ fontWeight: "bold" }}>Last Name</Typography>
					</Grid>
					<Grid xs={9} style={{ padding: "6px 0px" }}>
						<Typography variant="body1">{item.agent.lname}</Typography>
					</Grid>
					<Grid xs={3} style={{ padding: "6px 0px" }}>
						<Typography variant="body1" style={{ fontWeight: "bold" }}>Phone Number</Typography>
					</Grid>
					<Grid xs={9} style={{ padding: "6px 0px" }}>
						<Typography variant="body1">{item.agent.phone_number}</Typography>
					</Grid>
					<Grid xs={3} style={{ padding: "6px 0px" }}>
						<Typography variant="body1" style={{ fontWeight: "bold" }}>Agency (Optional)</Typography>
					</Grid>
					<Grid xs={9} style={{ padding: "6px 0px" }}>
						<Typography variant="body1">{item.agent.organization_name}</Typography>
					</Grid>
					
				</Grid>
			</>
))}</>
	);
};
