import { ForgetPasswordActionType } from "./interfaces";
import { Dispatch } from "redux";
import Services from "../generic/Services";
import { enqueueSnackbar } from "../Notifier/actions";
import { IError } from "../Base/ErrorMessageBase";

export const openForgetPasswordModal = () => {
	return {
		type: ForgetPasswordActionType.FORGET_PASSWORD_MODAL_OPEN,
	};
};

export const closeForgetPasswordModal = () => {
	return {
		type: ForgetPasswordActionType.FORGET_PASSWORD_MODAL_CLOSE,
	};
};

export const forgetPassword = (data: { email: string }) => {
	return (dispatch: Dispatch) => {
		dispatch(request());
		Services.users.forgetPassword(data).then(
			response => {
				dispatch(success(response.item));
			},
			(error: IError) => {
				dispatch(failure(error));
				dispatch(
					enqueueSnackbar({
						message: error.message,
						options: {
							variant: "error",
						},
					}),
				);
			},
		);
	};

	function request() {
		return { type: ForgetPasswordActionType.FORGET_PASSWORD_REQUEST };
	}
	function success(payload: any) {
		return {
			type: ForgetPasswordActionType.FORGET_PASSWORD_SUCCESS,
			payload,
		};
	}
	function failure(payload: IError) {
		return {
			type: ForgetPasswordActionType.FORGET_PASSWORD_FAILURE,
			payload,
		};
	}
};
