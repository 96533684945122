import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";
import LockIcon from "@material-ui/icons/Lock";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import makeStyles from "@material-ui/core/styles/makeStyles";
import FormHelperText from "@material-ui/core/FormHelperText";
import createStyles from "@material-ui/core/styles/createStyles";
import { Formik, FormikProps } from "formik";
import { FormTextField } from "./FormTextField";
import { DiscardDialog } from "./DiscardDialog";
import { InputLabel } from "@material-ui/core";
import ErrorMessage from "../../generic/ErrorMessage";
import Services from "../../generic/Services";
import * as Yup from "yup";
import { WarningDialoge } from "./WarningDialoge"
import { useParams } from "react-router-dom";
import { consoleTestResultHandler } from "tslint/lib/test";
import { useDispatch } from 'react-redux';
import { userActions } from "../../AuthBase";

const useStyles = makeStyles((theme) =>
	createStyles({
		dropDownPlaceholder: {
			color: "#63637B",
			fontWeight: 600,
		},
		select: {
			margin: `${theme.spacing(1)}px 0px 0px 0px`,
		},
		textAlign: {
			textAlign: "center",
		},
	}),
);

export interface AddNewCard {
	street: string;
	city: string;
	state: { id: number } | null;
	zip: string;
	mobile_number: string;
	first_name: string;
	last_name: string;
	credit_card: string;
	exp_month: number | null;
	exp_year: number | null;
	cvv: string;
}

const initialValues = {
	street: "",
	city: "",
	state: null,
	zip: "",
	mobile_number: "",
	first_name: "",
	last_name: "",
	credit_card: "",
	exp_month: null,
	exp_year: null,
	cvv: "",
};

// validation schema of the add new card form
const validationSchema = Yup.object({
	street: Yup.string().required("address is required"),
	city: Yup.string().required("city is required"),
	state: Yup.object({ id: Yup.number().required() }),
	zip: Yup.string()
		.matches(/^[0-9]{5}$|^[0-9]{9}$/, 'Zip Must be 5 or 9 digits')
		.required("zip is required"),
	mobile_number: Yup.string()
		.matches(/^[0-9]{10}$|^[0-9]{3}-[0-9]{3}-[0-9]{4}$/, {
			message: "invalid phone number",
		})
		.required("phone number is required"),
	first_name: Yup.string().required("first name is required"),
	last_name: Yup.string().required("last name is required"),
	credit_card: Yup.string()
		.matches(/^[0-9]{15,16}$/, "invalid card number")
		.required("credit card number is required"),
	exp_month: Yup.number().required("exp month is required"),
	exp_year: Yup.number().required("exp year is required"),
	cvv: Yup.string()
		.matches(/^[0-9]{3,4}$/, "ccv must be 3-4 digits")
		.required(),
});

interface AddCardFormProps {
	states: any;
	addPaymentMethod: boolean;
	setAddPaymentMethod: any;
	dirtyForm: boolean;
	setDirtyForm: any;
	setExpantionOpen: (state: boolean) => void;
	updateUserData: () => void;
}

// months arra y
const months = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"];

// years array
const years: string[] = [];
for (let i = 2020; i < 2050; i++) {
	years.push(i.toString());
}

export const AddCardForm: React.FC<AddCardFormProps> = ({
	states,
	addPaymentMethod,
	setAddPaymentMethod,
	dirtyForm,
	setDirtyForm,
	setExpantionOpen,
	updateUserData,
}) => {
	const classes = useStyles();
	const dispatch = useDispatch(); // to update store values

	const theme = useTheme();

	const matches = useMediaQuery(theme.breakpoints.up("md"));

	const [error, setError] = useState<any>();

	const [open, setOpen] = React.useState(false);
	const [openWarning, setOpenWarning] = React.useState(false);
	const { id }  = useParams();

	const handleClose = () => {
		setOpen(false);
	};

	const beforeUnload = (event: any) => {
		if (dirtyForm) {
			event.returnValue = `Are you sure you want to leave?`;
		}
	};

	useEffect(() => {
		window.addEventListener("beforeunload", beforeUnload);
		return () => {
			window.removeEventListener("beforeunload", beforeUnload);
		};
	});

	return (
		<Formik
			validationSchema={validationSchema}
			initialValues={initialValues}
			onSubmit={(values, actions) => {
				const cardData = { type: "card", provider: "stripe", ...values };

				if(!id){
					Services.paymentMethods
					.addPaymentMethod(cardData)
					.then((res: any) => {
						if (res?.item?.status === "MISSING_PROFILE")
							setOpenWarning(true)
						updateUserData()
						actions.setSubmitting(false);
						actions.resetForm();
						setError({});
						setAddPaymentMethod(!addPaymentMethod);

						let user = JSON.parse(localStorage.getItem("user") || "");
						user = { ...user, billing_status: "" };
						dispatch(userActions.updateTalentStatuses(user))
						user = JSON.stringify(user);
						localStorage.setItem("user", user);

						console.log('ERROR 1: ', error);
					})
					.catch((err) => {
						console.log('ERROR 2: ', err);
						setError(err)
					});
				}
				else{
					Services.paymentMethods
					.addBandPaymentMethodByAgent(id,cardData)
					.then((res: any) => {
						
						if (res?.item?.status === "MISSING_PROFILE")
							setOpenWarning(true)
						updateUserData()
						actions.setSubmitting(false);
						actions.resetForm();
						console.log('ERROR 3: ', error);
						setError({});
						setAddPaymentMethod(!addPaymentMethod);

						let user = JSON.parse(localStorage.getItem("user") || "");
						user = { ...user, billing_status: "" };
						dispatch(userActions.updateTalentStatuses(user))
						user = JSON.stringify(user);
						localStorage.setItem("user", user);
					})
					.catch((err) => {
						console.log('ERROR 4: ', err);
						setError(err)
					});
				}
				
			}}
			render={(formikBag: FormikProps<AddNewCard>) => {
				const {
					values,
					errors,
					touched,
					handleChange,
					handleSubmit,
					setFieldValue,
					setFieldTouched,
				} = formikBag;

				const onKeyPress = ({
					fieldname,
					length,
				}: {
					fieldname: "zip" | "mobile_number" | "cvv" | "credit_card";
					length: number;
				}) => (e: any) => {
					e.persist();
					if (!(e.key >= "0" && e.key <= "9") || values[fieldname].length > length) {
						e.preventDefault();
					}
				};

				setDirtyForm(formikBag.dirty);
				return (
					<Box width="100%">
						<form onSubmit={handleSubmit}>
							<Grid container direction="row" spacing={9}>
								<Grid item xs={12} md={6} lg={6}>
									<Box mb={4} fontSize="1.2rem">
										Billing Information
									</Box>
									<Grid container direction="row" spacing={3}>
										<Grid item xs={12} md={12} lg={12}>
											<FormTextField
												fieldName="street"
												value={values.street}
												touched={touched.street}
												error={errors.street}
												handleChange={handleChange}
											/>
										</Grid>
										<Grid item xs={12} md={6} lg={6}>
											<FormTextField
												fieldName="city"
												value={values.city}
												touched={touched.city}
												error={errors.city}
												handleChange={handleChange}
											/>
										</Grid>
										<Grid item xs={12} md={6} lg={6}>
											<Grid container direction="column">
												<Grid item>
													<InputLabel htmlFor={"state"}>State</InputLabel>
												</Grid>
												<Grid item>
													<Select
														className={
															values.state
																? classes.select
																: `${classes.dropDownPlaceholder} ${classes.select}`
														}
														value={values.state && values.state.id ? values.state.id : 99}
														fullWidth={true}
														id="state"
														name="state"
														variant="outlined"
														color="secondary"
														error={touched.state && Boolean(errors.state)}
														MenuProps={{
															getContentAnchorEl: null,
															anchorOrigin: {
																vertical: "bottom",
																horizontal: "left",
															},
															transformOrigin: {
																vertical: "top",
																horizontal: "left",
															},
														}}
														onChange={(e: any) => {
															e.persist();
															setFieldValue("state", {
																id: e.target.value,
															});
															setFieldTouched("state", true, false);
														}}>
														<MenuItem disabled value={99}>
															State
														</MenuItem>
														{states.allIds.map((item: any) => (
															<MenuItem key={item} value={item}>
																{states.items[item].title}
															</MenuItem>
														))}
													</Select>
													{errors.state && touched.state && (
														<FormHelperText error variant="outlined">
															state is a required field
														</FormHelperText>
													)}
												</Grid>
											</Grid>
										</Grid>
										<Grid item xs={12} md={5} lg={5}>
											<FormTextField
												textAlignCenter={true}
												fieldName="zip"
												value={values.zip}
												touched={touched.zip}
												error={errors.zip}
												handleChange={handleChange}
												onKeyPress={onKeyPress({ fieldname: "zip", length: 8 })}
											/>
										</Grid>
										<Grid item xs={12} md={7} lg={7}>
											<FormTextField
												textAlignCenter={true}
												fieldName="mobile_number"
												value={values.mobile_number}
												touched={touched.mobile_number}
												error={errors.mobile_number}
												handleChange={handleChange}
												onKeyPress={onKeyPress({ fieldname: "mobile_number", length: 9 })}
											/>
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={12} md={6} lg={6}>
									<Box mb={4} display="flex" alignItems="center" fontSize="1.2rem">
										<LockIcon />
										Secure Credit Card Form
									</Box>
									<Grid container direction="row" spacing={3}>
										<Grid item xs={12} md={12} lg={12}>
											<FormTextField
												fieldName="first_name"
												value={values.first_name}
												touched={touched.first_name}
												error={errors.first_name}
												handleChange={handleChange}
											/>
										</Grid>
										<Grid item xs={12} md={12} lg={12}>
											<FormTextField
												fieldName="last_name"
												value={values.last_name}
												touched={touched.last_name}
												error={errors.last_name}
												handleChange={handleChange}
											/>
										</Grid>
										<Grid item xs={12} md={12} lg={12}>
											<FormTextField
												fieldName="credit_card"
												value={values.credit_card}
												touched={touched.credit_card}
												error={errors.credit_card}
												handleChange={handleChange}
												type="password"
												onKeyPress={onKeyPress({ fieldname: "credit_card", length: 15 })}
											/>
										</Grid>
										<Grid item xs={12} md={7} lg={7}>
											<Grid container direction="row" justify="space-between">
												<Grid item xs={12} md={12} lg={12}>
													<InputLabel htmlFor="exp_month">Expiration Date</InputLabel>
												</Grid>
												<Grid item xs={5} md={5} lg={5}>
													<Select
														className={
															values.exp_month
																? classes.select
																: `${classes.dropDownPlaceholder} ${classes.select}`
														}
														value={values.exp_month ? values.exp_month : 0}
														fullWidth={true}
														id="exp_month"
														name="exp_month"
														variant="outlined"
														color="secondary"
														error={touched.exp_month && Boolean(errors.exp_month)}
														MenuProps={{
															getContentAnchorEl: null,
															anchorOrigin: {
																vertical: "bottom",
																horizontal: "left",
															},
															transformOrigin: {
																vertical: "top",
																horizontal: "left",
															},
														}}
														onChange={(e: any) => {
															e.persist();
															setFieldValue("exp_month", e.target.value);
															setFieldTouched("exp_month", true, false);
														}}>
														<MenuItem disabled value={0}>
															Exp Month
														</MenuItem>
														{months.map((month: any) => (
															<MenuItem key={month} value={month}>
																{month}
															</MenuItem>
														))}
													</Select>
													{errors.exp_month && touched.exp_month && (
														<FormHelperText error variant="outlined">
															exp month is a required field
														</FormHelperText>
													)}
												</Grid>
												<Grid item xs={5} md={5} lg={5}>
													<Select
														className={
															values.exp_year
																? classes.select
																: `${classes.dropDownPlaceholder} ${classes.select}`
														}
														value={values.exp_year ? values.exp_year : 0}
														fullWidth={true}
														id="exp_year"
														name="exp_year"
														variant="outlined"
														color="secondary"
														error={touched.exp_year && Boolean(errors.exp_year)}
														MenuProps={{
															getContentAnchorEl: null,
															anchorOrigin: {
																vertical: "bottom",
																horizontal: "left",
															},
															transformOrigin: {
																vertical: "top",
																horizontal: "left",
															},
														}}
														onChange={(e: any) => {
															e.persist();
															setFieldValue("exp_year", e.target.value);
															setFieldTouched("exp_year", true, false);
														}}>
														<MenuItem disabled value={0}>
															Exp Year
														</MenuItem>
														{years.map((year: any) => (
															<MenuItem key={year} value={year}>
																{year}
															</MenuItem>
														))}
													</Select>
													{errors.exp_year && touched.exp_year && (
														<FormHelperText error variant="outlined">
															exp year is a required field
														</FormHelperText>
													)}
												</Grid>
											</Grid>
										</Grid>
										{matches ? <Grid item xs={1} md={1} lg={1}></Grid> : ""}
										<Grid item xs={6} md={4} lg={4}>
											<FormTextField
												fieldName="cvv"
												value={values.cvv}
												touched={touched.cvv}
												error={errors.cvv}
												handleChange={handleChange}
												onKeyPress={onKeyPress({ fieldname: "cvv", length: 3 })}
											/>
										</Grid>
										{error && error.message && (
											<Grid item xs={12} md={12} lg={12}>
												<ErrorMessage error={error.errors.length > 0 ? error.errors : error} />
											</Grid>
										)}
									</Grid>
									<Box pt={5} pb={3}>
										<Grid container alignItems="center" justify="flex-end" spacing={3}>
											<Grid item>
												<Link
													onClick={() => {
														if(formikBag.dirty)
															setOpen(true);
														else
															setExpantionOpen(false);
													}}
													color="textPrimary"
													component="button"
													type="button">
													Cancel
												</Link>
											</Grid>
											<Grid item>
												<Button
													type="submit"
													variant="contained"
													color="secondary"
													size="large">
													Save
												</Button>
											</Grid>
										</Grid>
										<DiscardDialog
											open={open}
											resetForm={() => {
												formikBag.resetForm()
												setExpantionOpen(false);
											}}
											handleClose={handleClose}
										/>
									</Box>
								</Grid>
							</Grid>
						</form>
						<WarningDialoge open={openWarning} handleClose={() => setOpenWarning(false)} />
					</Box>
				);
			}}
		/>
	);
};
