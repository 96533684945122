import { createSlice } from "redux-starter-kit";
import Services from "../../../generic/Services";
import { IAppState } from "../../../generic/interfaces";

export interface ReviewTalentObject {
	reviewTalentDialogOpened: boolean;
	reviewTalentRequested: boolean;
	reviewTalentSucceeded: boolean;
	reviewTalentFailure: boolean;
	bandId: number | null;
	eventId: number | null;
	location: string | null;
	error: any;
}

const initialState: ReviewTalentObject = {
	reviewTalentDialogOpened: false,
	reviewTalentRequested: false,
	reviewTalentSucceeded: false,
	reviewTalentFailure: false,
	bandId: null,
	eventId: null,
	location: null,
	error: {},
};

const reviewTalentSlice = createSlice({
	slice: "reviewTalent",
	initialState,
	reducers: {
		reviewTalentRequested(state, action) {
			state.reviewTalentRequested = true;
			state.reviewTalentSucceeded = false;
			state.reviewTalentFailure = false;
		},
		reviewTalentSucceeded(state, action) {
			state.reviewTalentRequested = false;
			state.reviewTalentSucceeded = true;
			state.reviewTalentFailure = false;
			state.error = {};
		},
		reviewTalentFailure(state, action) {
			state.reviewTalentRequested = false;
			state.reviewTalentSucceeded = false;
			state.reviewTalentFailure = true;
			state.error = action.payload;
		},
		openReviewTalentDialog(state, action) {
			state.reviewTalentDialogOpened = true;
			state.bandId = action.payload.bandId;
			state.eventId = action.payload.eventId;
			state.location = action.payload.location;
		},
		closeReviewTalentDialog(state, action) {
			state.reviewTalentDialogOpened = false;
			state.bandId = null;
			state.eventId = null;
			state.location = null;
		},
	},
});

export const {
	reviewTalentRequested,
	reviewTalentSucceeded,
	reviewTalentFailure,
	openReviewTalentDialog,
	closeReviewTalentDialog,
} = reviewTalentSlice.actions;

export default reviewTalentSlice.reducer;

export const addReview = ({
	userReview,
	userRating,
	repsyReview,
	repsyRating,
}: {
	userReview: string;
	userRating: number;
	repsyReview: string;
	repsyRating: number;
}): any => async (dispatch: any, getState: () => IAppState) => {
	try {
		dispatch(reviewTalentRequested());
		const { bandId, eventId, location } = getState().account.reviewTalent;
		if (!bandId || !eventId || !location) {
			throw new Error();
		}
		const res = await Services.events.addReview({
			bandId,
			location,
			userRating,
			userReview,
			eventId,
			repsyReview,
			repsyRating,
		});
		dispatch(reviewTalentSucceeded(res));
		dispatch(closeReviewTalentDialog());
	} catch (err) {
		dispatch(reviewTalentFailure(err));
	}
};
