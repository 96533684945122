import { connect } from "react-redux";
import { TalentsAgentRequest } from "./TalentsAgentRequestView";
import { IAppState } from "../../generic/interfaces";
import { ThunkDispatch } from "redux-thunk";
import { talentsAgentRequestList } from "./TalentsAgentRequestSlice";

const mapStateToProps = (state: IAppState) => ({
	countTalentsAgent: state.countTalentsAgent,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
	listTalentsAgent: () => {
		dispatch(talentsAgentRequestList());
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(TalentsAgentRequest);
