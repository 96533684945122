import React from "react";
import {
	DialogTitle,
	IconButton,
	Icon,
} from "@material-ui/core";
import cancelIcon from "../../../Assets/Cancel.svg";

interface IDialogTitleProps {
	id: string;
	children: React.ReactNode;
	onClose?: () => void;
	title?: string;
	classes?: any;
}

export const CustomDialogTitle = (props: IDialogTitleProps) => {
	const { children, onClose } = props;
	return (
		<DialogTitle disableTypography>
			{children}
			{onClose ? (
				<IconButton aria-label="Close" size="small" onClick={onClose} style={{ left: "auto", right: "8px" }}>
					{/* <Close /> */}
					<Icon
						style={{
							width: "1.5em",
							height: "1.5em",
						}}
					>
						<img src={cancelIcon} alt="cancel icon" />
					</Icon>
				</IconButton>
			) : null}
		</DialogTitle>
	);
};
