import React from "react"
import Box from "@material-ui/core/Box";
import moment from "moment";
import Typography from "@material-ui/core/Typography";
import { makeStyles, Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		notification: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "450px",
            "&:hover": {
                backgroundColor: "#0F24FD",
                cursor: "pointer"
            },
        }
	}),
);

interface INotificationProps {
    data: any;
    index: number;
    handleNotificationSeen: (index: number) => void;
    handleCloseNotification: () => void;
    authentication: any;
}

const NotificationItem: React.FC<INotificationProps> = ({ data, handleNotificationSeen, index, handleCloseNotification, authentication }) => {
    const classes = useStyles()
    const history = useHistory()

    return (
        <Box p={1} className={classes.notification} onClick={() => {
            handleNotificationSeen(index)
            handleCloseNotification();
            if( authentication.user.role === "TALENT"){
                if( data.data.type === "PROFILE_APPROVED")
                {
                    history.push("/account/edit-profile")
                } else {
                    history.push({
						pathname: "/account/active-event",
						state: { requestId: data.data.id },
					})
                }
            } else if (authentication.user.role === "BOOKER"){
                if( data.data.type === "BOOKER_APPROVED")
                {
                    history.push("/account/edit-profile")
                } else {
                    history.push({
						pathname: "/account/messages",
						state: { new: true, requestId: data.data.id },
					})
                }
            } else if (authentication.user.role === "AGENT"){
                switch (data.data.type) {
                    case 'ACCEPTED_BY_AGENT':
                    case 'ACCEPTED_BY_TALENT':
                        history.push(`/account/edit-band/${data.data.id}`);
                        break;
                    case 'PROFILE_APPROVED':
                        history.push(`/account/edit-profile`);
                        break;
                    case 'SENT_BY_AGENT':
                    case 'SENT_BY_TALENT':
                    case 'REJECTED_BY_AGENT':
                    case 'REJECTED_BY_TALENT':
                    case 'REMOVED_BY_AGENT':
                    case 'REMOVED_BY_TALENT':
                        break;
                    default:
                        history.push({
                            pathname: `/account/active-event/band/${data.data.band_id}`,
                            state: { new: true, requestId: data.data.id },
                        })
                        break;
                }
            }
        }}>
            <Typography style={{ maxWidth: "75%" }} variant="body1">{data.message}</Typography>
            <Box>{moment(data.timestamp).fromNow()}</Box>
        </Box>
    )
}

export default NotificationItem