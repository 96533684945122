import { ThunkDispatch } from "redux-thunk";

export enum ConfrimAccountActionType {
	CONFIRM_ACCOUNT_REQUEST = "CONFIRM_ACCOUNT_REQUEST",
	CONFIRM_ACCOUNT_SUCCESS = "CONFIRM_ACCOUNT_SUCCESS",
	CONFIRM_ACCOUNT_FAILURE = "CONFIRM_ACCOUNT_FAILURE",
	LOGIN_DIALOG_OPEN = "LOGIN_DIALOG_OPEN",
	LOGIN_DIALOG_CLOSE = "LOGIN_DIALOG_CLOSE",
}

export interface IConfrimAccountReducer {
	loginDialogOpen: boolean;
	confirmAccountRequest: boolean;
	confirmAccountSuccess: boolean;
	confirmAccountFailed: boolean;
	confirmAccountRole: any;
	error: any;
}

export interface IConfrimAccountAction {
	type: ConfrimAccountActionType;
	payload: any;
}

export interface IConfirmAccountProps {
	location: any;
	history: any;
	confirmAccountState: IConfrimAccountReducer;
	dispatch: ThunkDispatch<{}, {}, any>;
	openSignupModal: () => void;
}
