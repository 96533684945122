import React, { useState } from "react";
import Dropzone from "react-dropzone-uploader";
import './style.css'

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { uploadMultipleMedia } from "../../generic/Services/bands";
import { CircularProgress } from "@material-ui/core";

const useStyles = makeStyles((theme) =>
  createStyles({
    dzuDropzone: {
      width: '100%',
      overflow: 'scroll',
      borderTop: '0px',
      borderRight: '0px',
      borderTop: 'solid 0px rgba(100, 254, 204, 0.5)',
      borderBottom: '0px',
      height: 'auto',
      minHeight: '120px',
    }
  })
)


const MultipleImageDropzone = (props) => {
  const {reloadGallery, setReloadGallery, bandId} = props;
  const [showUploadLoading, setShowUploadLoading] = useState(false);
  // const getUploadParams = ({ meta }) => {
  //   const url = 'https://httpbin.org/post'
  //   return { url, meta: { fileUrl: `${url}/${encodeURIComponent(meta.name)}` } }
  // }

  const handleChangeStatus = ({ meta }, status) => {
    console.log(status, meta)
  }

  const handleSubmit = async (files, allFiles) => {
    setShowUploadLoading(true)
    //console.log(files.map(f => f.meta))
    let selectedFiles = [];
    files.forEach(element => {
      selectedFiles.push(element.file);
    });
    const promise = await Promise.all([uploadMultipleMedia(selectedFiles, bandId)]);
    allFiles.forEach(f => f.remove());
    setShowUploadLoading(false)
    setReloadGallery(!reloadGallery);
  }

  return (
    <div className="multipleImageUpload">
      <Dropzone
        submitButtonDisabled={showUploadLoading}
        //getUploadParams={getUploadParams}
        onChangeStatus={handleChangeStatus}
        onSubmit={handleSubmit}
        accept="image/*"
        submitButtonContent={<span style={{paddingRight: "8px"}}>Upload {showUploadLoading && <CircularProgress size={15} />} </span>}
        inputWithFilesContent="Add Images"
        inputContent={(files, extra) => (extra.reject ? 'Image files only' : 'Upload Images')}
        styles={{
          dropzoneReject: { borderColor: 'red', backgroundColor: '#DAA' },
          inputLabel: (files, extra) => (extra.reject ? { color: 'red' } : {}),
        }}
      />
    </div>
  )
}

export default MultipleImageDropzone;