import { generateFetchReducer } from "../helpers/generate-fetch-reducer";
import { combineReducers } from "redux";
import * as resourcesActions from "./actions";
import { IResourcesReducer } from "../../generic/Resources/interfaces";
import { ThunkDispatch } from "redux-thunk";
import { priceRange } from "./reducer";

const genres = generateFetchReducer("GENRES_");
const states = generateFetchReducer("STATES_");
const gigs = generateFetchReducer("GIGS_");
const notificationSettings = generateFetchReducer("NOTIFICATION_SETTINGS_");

export const checkRescourcesLoaded = (stateResources: IResourcesReducer) => {
	if (
		stateResources.gigs.isSucceed &&
		stateResources.genres.isSucceed &&
		stateResources.states.isSucceed &&
		stateResources.notificationSettings.isSucceed
	) {
		return true;
	}
	return false;
};

export const resources = combineReducers({
	genres,
	states,
	gigs,
	priceRange,
	notificationSettings,
});

export const fetchResources = (dispatch: ThunkDispatch<{}, {}, any>) => {
	// TODO: check last fetch date
	dispatch(resourcesActions.genres());
	// if (!cachedResources.genres.lastFetched) {
	// }
	dispatch(resourcesActions.states());
	dispatch(resourcesActions.gigs());
	dispatch(resourcesActions.priceRange());
	dispatch(resourcesActions.notificationSettings());
	// if (!cachedResources.states.lastFetched) {
	// }
	// let allResources = Object.keys(resources);

	// allResources = allResources.filter((resource:string) => {
	//   return !resources[resource].lastFetched;
	// })
	// const availableActions = Object.keys(resourcesActions);
	// allResources.map((resource: availableResources<string>) => {

	//   if (availableActions.includes(resource) && resourcesActions[resource]) {
	//     resourcesActions[resource]();
	//   }
	// });
};
