import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { IAppState } from "../../../generic/interfaces";
import {
	createBookerEvent,
	listVenueDescription,
	listEventById,
	unMount,
} from "./createEventSlice";
import { CreateBookerEvent } from "./CreateBookerEvent";
import { setPage } from "../../../HomePage/homePageSlice";

const mapStateToProps = (state: IAppState) => ({
	isUserApproved: state.authentication.user.status === "APPROVED",
	bookerEvent: state.account.bookerEvent.createBookerEvent,
	states: state.resources.states,
	gigs: state.resources.gigs,
	authentication: state.authentication,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
	createBookerEvent: (event: any) => {
		dispatch(createBookerEvent(event));
	},
	listVenueDescription: () => {
		dispatch(listVenueDescription());
	},
	setPage: (page: string) => {
		dispatch(setPage(page));
	},
	listEventById: (eventId: number) => {
		dispatch(listEventById(eventId));
	},
	unMount: () => {
		dispatch(unMount());
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateBookerEvent);
