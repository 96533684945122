import {
	Box,
	Button,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Divider,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Formik, FormikProps } from "formik";
import React from "react";
import { uploadMedia } from "../../generic/Services/bands";
import Dropzone from "../../ImageUploader/image-uploader";
import { Prompt } from "react-router";
import FormHelperText from "@material-ui/core/FormHelperText";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		textAlign: {
			textAlign: "end",
		},
		svgAlign: {
			float: "right",
		},
		justifyContent: {
			justifyContent: "space-between",
		},
		textFieldWidth: {
			maxWidth: "100px",
			marginRight: "35px",
		},
		dropdownButton: {
			border: "1px solid rgba(100, 254, 204, 1)",
			justifyContent: "start",
			fontWeight: "normal",
			"&:hover": {
				backgroundColor: "rgba(100, 254, 204, 0)",
			},
		},
	}),
);

export interface MyFormValues {
	photo: any;
	fname: string;
	lname: string;
	phone_number: string;
	phone_number1: string;
	phone_number2: string;
	phone_number3: string;
	organization_name: string;
}

export interface AgentProfileFormProps {
	validationSchema: any;
	initialValues: any;
	bandName: string;
	handleOnSubmit: (agentProfile: any) => void;
}

export const AgentProfileForm: React.FC<AgentProfileFormProps> = ({
	handleOnSubmit,
	initialValues,
	validationSchema,
	bandName,
}) => {
	const classes = useStyles();

	const handleSubmitForm = async (values: any) => {
		if (
			values.photo !== null &&
			typeof values.photo.lastModified !== "undefined"
		) {
			const promise = await Promise.all([uploadMedia(values.photo, undefined, 'profile')]);
			const [profile] = promise;
			await profile.json().then((res: any) => {
				values.photo = res.item;
				let user = JSON.parse(localStorage.getItem("user") || "");
				user = { ...user, photo: res.item, status: res.item.status };
				user = JSON.stringify(user);
				localStorage.setItem("user", user);
			});
		}
	};

	const inputRef1 = React.useRef<HTMLDivElement>(null);
	const inputRef2 = React.useRef<HTMLDivElement>(null);
	const inputRef3 = React.useRef<HTMLDivElement>(null);

	return (
		<Box>
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={async (values, actions) => {
					values.phone_number = values.phone_number1.concat(
						values.phone_number2,
						values.phone_number3,
					);
					await handleSubmitForm(values);
					handleOnSubmit(values);
					actions.setSubmitting(false);
				}}
				render={(formikBag: FormikProps<MyFormValues>) => {
					console.log('formikBag: ', formikBag);
					window.addEventListener("beforeunload", (event) => {
						if (formikBag.dirty) {
							event.returnValue = `Are you sure you want to leave?`;
						}
					});

					return (
						<form onSubmit={formikBag.handleSubmit}>
							<Prompt
								when={formikBag.dirty}
								message={(location) => `Do you want to discard these changes?`}
							/>
							<Grid
								container
								direction="column"
								alignItems="center"
								spacing={4}
							>
								<Grid
									container
									item
									direction="row"
									alignItems="center"
									justify="space-evenly"
								>
									<Grid item xs={12} md={12} lg={12}>
										<Dropzone
											url={
												formikBag.values.photo &&
												formikBag.values.photo.url
											}
											handleChange={(file: any) => {
												formikBag.setFieldValue(
													"photo",
													file,
												);
											}}
											inputContent=""
											btnText="Change Profile Picture"
											title={bandName.toUpperCase()}
										/>
									</Grid>
								</Grid>
								<Grid
									container
									item
									direction="row"
									alignItems="center"
									spacing={4}
								>
									<Grid item xs={4} md={4} lg={4}>
										<InputLabel
											className={classes.textAlign}
											htmlFor="fname"
										>
											First Name *
										</InputLabel>
									</Grid>
									<Grid item xs={8} md={8} lg={8}>
										<TextField
											placeholder="First Name"
											autoComplete="off"
											id="fname"
											name="fname"
											variant="outlined"
											color="secondary"
											helperText={
												formikBag.touched.fname
													? formikBag.errors.fname
													: ""
											}
											error={
												formikBag.touched.fname &&
												Boolean(formikBag.errors.fname)
											}
											fullWidth={true}
											margin="dense"
											value={formikBag.values.fname}
											onChange={(e: any) => {
												e.persist();
												formikBag.setFieldValue(
													"fname",
													e.target.value,
												);
												formikBag.setFieldTouched(
													"fname",
													true,
													false,
												);
											}}
										/>
									</Grid>
								</Grid>
								<Grid
									container
									item
									direction="row"
									alignItems="center"
									spacing={4}
								>
									<Grid item xs={4} md={4} lg={4}>
										<InputLabel
											className={classes.textAlign}
											htmlFor="lname"
										>
											Last Name *
										</InputLabel>
									</Grid>
									<Grid item xs={8} md={8} lg={8}>
										<TextField
											placeholder="Last Name"
											autoComplete="off"
											id="lname"
											name="lname"
											variant="outlined"
											color="secondary"
											helperText={
												formikBag.touched.lname
													? formikBag.errors.lname
													: ""
											}
											error={
												formikBag.touched.lname &&
												Boolean(formikBag.errors.lname)
											}
											fullWidth={true}
											margin="dense"
											value={formikBag.values.lname}
											onChange={(e: any) => {
												e.persist();
												formikBag.setFieldValue(
													"lname",
													e.target.value,
												);
												formikBag.setFieldTouched(
													"lname",
													true,
													false,
												);
											}}
										/>
									</Grid>
								</Grid>
								<Grid
									container
									item
									direction="row"
									alignItems="center"
									spacing={4}
								>
									<Grid item xs={4} md={4} lg={4}>
										<InputLabel
											className={classes.textAlign}
											htmlFor="phone_number"
										>
											Phone Number *
										</InputLabel>
									</Grid>
									<Grid item xs={8} md={8} lg={8}>
										<TextField
											placeholder="Area"
											className={classes.textFieldWidth}
											autoComplete="off"
											id="phone_number1"
											name="phone_number1"
											value={formikBag.values.phone_number1}
											helperText={
												formikBag.touched.phone_number1
													? formikBag.errors.phone_number1
													: ""
											}
											error={
												formikBag.touched.phone_number1 &&
												Boolean(
													formikBag.errors.phone_number1,
												)
											}
											variant="outlined"
											color="secondary"
											margin="dense"
											inputRef={inputRef1}
											inputProps={{
												maxLength: 3,
												style: { textAlign: "center" },
											}}
											onChange={(e: any) => {
												e.persist();
												if (
													e.target.value.length === 3 &&
													inputRef2 &&
													inputRef2.current
												) {
													inputRef2.current.focus();
												}
												formikBag.setFieldValue(
													"phone_number1",
													e.target.value,
												);
												formikBag.setFieldTouched(
													"phone_number1",
													true,
													false,
												);
											}}
										/>
										<TextField
											placeholder="Exchange"
											className={classes.textFieldWidth}
											autoComplete="off"
											id="phone_number2"
											name="phone_number2"
											error={
												formikBag.touched.phone_number2 &&
												Boolean(
													formikBag.errors.phone_number2,
												)
											}
											helperText={
												formikBag.touched.phone_number2
													? formikBag.errors.phone_number2
													: ""
											}
											value={formikBag.values.phone_number2}
											variant="outlined"
											color="secondary"
											inputProps={{
												maxLength: 3,
												style: { textAlign: "center" },
											}}
											inputRef={inputRef2}
											margin="dense"
											onChange={(e: any) => {
												e.persist();
												if (
													e.target.value.length === 3 &&
													inputRef3 &&
													inputRef3.current
												) {
													inputRef3.current.focus();
												}
												formikBag.setFieldValue(
													"phone_number2",
													e.target.value,
												);
												formikBag.setFieldTouched(
													"phone_number2",
													true,
													false,
												);
											}}
										/>
										<TextField
											placeholder="Number"
											className={classes.textFieldWidth}
											autoComplete="off"
											id="phone_number3"
											name="phone_number3"
											error={
												formikBag.touched.phone_number3 &&
												Boolean(
													formikBag.errors.phone_number3,
												)
											}
											helperText={
												formikBag.touched.phone_number3
													? formikBag.errors.phone_number3
													: ""
											}
											value={formikBag.values.phone_number3}
											variant="outlined"
											color="secondary"
											inputProps={{
												maxLength: 4,
												style: { textAlign: "center" },
											}}
											margin="dense"
											inputRef={inputRef3}
											onChange={(e: any) => {
												if (
													e.target.value.length === 1 &&
													inputRef2 &&
													inputRef2.current &&
													e.key === "Backspace"
												) {
													inputRef2.current.focus();
												}
												formikBag.setFieldValue(
													"phone_number3",
													e.target.value,
												);
												formikBag.setFieldTouched(
													"phone_number3",
													true,
													false,
												);
											}}
										/>
									</Grid>
								</Grid>
								
								<Grid
									container
									item
									direction="row"
									alignItems="center"
									spacing={4}
								>
									<Grid item xs={4} md={4} lg={4}>
										<InputLabel
											className={classes.textAlign}
											htmlFor="organization_name"
										>
											Agency (Optional)
										</InputLabel>
									</Grid>
									<Grid item xs={8} md={8} lg={8}>
									<TextField
											placeholder="Agency (Optional)"
											autoComplete="off"
											id="organization_name"
											name="organization_name"
											variant="outlined"
											color="secondary"
											fullWidth={true}
											margin="dense"
											value={formikBag.values.organization_name}
											onChange={(e: any) => {
												e.persist();
												formikBag.setFieldValue(
													"organization_name",
													e.target.value,
												);
											}}
										/>
									</Grid>
								</Grid>
							</Grid>
							<Box
								display="flex"
								flexDirection="row-reverse"
								m={3}
								mr={0}
							>
								<Button
									type="submit"
									size="large"
									variant="contained"
									color="secondary"
									disabled={formikBag.isSubmitting}
								>
									Save
								</Button>
							</Box>
						</form>
					)
				}}
			/>
		</Box>
	);
};

export default AgentProfileForm;
