import React, { useEffect } from "react";
import { Box, Typography, Grid } from "@material-ui/core";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Services from "../generic/Services";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paragraphFont: {
            fontSize: "15px",
            color: "#ddd",
        },
    })
);

const PrivacyPolicy: React.FC = () => {
    const classes = useStyles();
    const [data, setData] = React.useState<any>();

    useEffect(() => {
        Services.termsAndConditions.listPrivacyPolicy().then((response: any) => {
            setData(response.items[0]);
        });
    }, []);

    return (
        <>
            {data ? (
				<Box ml={15} mr={7} mt="100px">
                <Grid container direction="column" alignItems="center">
                    <Box mb="30px" fontWeight="bold" fontFamily="Rubik" fontSize="30px">
                        {data.page_name}
                    </Box>
                    <Grid item xs={6}>
                        <Typography variant="body2" className={classes.paragraphFont}>
							<div dangerouslySetInnerHTML={{ __html: data.body }} >
							</div>
                        </Typography>
                    </Grid>
                </Grid>
				</Box>
            ) : (
                <> </>
            )}
        </>
    );
};

export default PrivacyPolicy;