import { createSlice } from "redux-starter-kit";

const pageSlice = createSlice({
	slice: "page",
	initialState: 1,
	reducers: {
		filterBandsPageReset(state, action) {
			//console.log('filterBandsPageReset: ', state)
			return 1;
		},
		filterBandsPageSearchIncrement(state, action) {
			//console.log('filterBandsPageReset: ', state)
			return 2;
		},
		filterBandsPageIncrement(state, action) {
			return state + 1;
		},
	},
});

export const {
	filterBandsPageReset,
	filterBandsPageIncrement,
	filterBandsPageSearchIncrement
} = pageSlice.actions;

export default pageSlice.reducer;
