import React, { useEffect } from "react";
import { PasswordForm } from "./PasswordForm";
import { Notifications } from "./Notifications";
import { EmailForm } from "./EmailForm";
import { SettingsState } from "./settingsSlice";
import * as Yup from "yup";
import {
	Box,
	Dialog,
	Divider,
	DialogActions,
	Button,
	DialogContent,
	DialogContentText,
} from "@material-ui/core";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		dialogRoot: {
			border: "1px solid",
			borderColor: theme.palette.secondary.main,
		},
	}),
);

const emailValidationSchema = Yup.object({
	currentEmail: Yup.string()
		.email("Enter a valid email")
		.required("Email is required"),
	email: Yup.string()
		.email("Enter a valid email")
		.required("Email is required")
		.notOneOf([Yup.ref("currentEmail")], "Enter a new email"),
});

const validationSchema = Yup.object({
	old_password: Yup.string().required("Enter your old password"),
	new_password: Yup.string()
		.matches(
			/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
			`Minimum eight characters, at least one uppercase letter
			, one lowercase letter,
			one number and one special character`,
		)
		.required("Enter your new password")
		.notOneOf([Yup.ref("old_password")], "Enter a new password"),
	confirm_password: Yup.string()
		.required("Confirm password is a required field")
		.oneOf([Yup.ref("new_password")], "Password does not match"),
});

export interface SettingsProps {
	setPage: (page: string) => void;
	editPassword: (passwordFormObject: any) => any;
	editEmail: (emailFormObject: any) => any;
	setLoc: (location: string) => void;
	closeModal: () => void;
	settings: SettingsState;
	authentication: any;
}

export const Settings: React.FC<SettingsProps> = ({
	setPage,
	editPassword,
	editEmail,
	settings,
	authentication,
	setLoc,
	closeModal,
}) => {
	const classes = useStyles();

	useEffect(() => {
		setLoc("/account/settings");
		setPage("settings");
	}, [setLoc, setPage]);

	const initialValues = {
		old_password: "",
		new_password: "",
		confirm_password: "",
	};

	const emailInitialValues = {
		currentEmail: authentication.email,
		email: "",
	};

	return (
		<>
			<Box fontWeight="bold" fontFamily="Rubik" fontSize="30px">
				Settings
			</Box>
			<PasswordForm
				initialValues={initialValues}
				validationSchema={validationSchema}
				settings={settings}
				handleOnSubmit={(passwordFormObject: any) => editPassword(passwordFormObject)}
			/>
			<Divider orientation="horizontal" />
			<EmailForm
				initialValues={emailInitialValues}
				validationSchema={emailValidationSchema}
				settings={settings}
				handleOnSubmit={(emailFormObject: any) => editEmail(emailFormObject)}
			/>
			<Divider orientation="horizontal" />
			<Notifications />
			<Dialog
					fullWidth={true}
					maxWidth="sm"
					scroll="body"
					open={settings.editPasswordSucceeded || settings.editEmailSucceeded}
					onClose={closeModal}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
					classes={{ paper: classes.dialogRoot }}
				>
					<DialogContent>
						<DialogContentText
							color="textPrimary"
							id="alert-dialog-description"
						>
							{settings.userMessage}
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={closeModal} color="default" autoFocus>
							Ok
						</Button>
					</DialogActions>
				</Dialog>
		</>
	);
};
