import React, { useEffect, useState } from "react";
import ProfileDropzone from "../../ImageUploader/image-uploader";
import { uploadMedia, updateBandPhotoId } from "../../generic/Services/bands";
import { Button, CircularProgress, Grid, InputLabel } from "@material-ui/core";
import { useDispatch } from 'react-redux';
import { userActions } from "../../AuthBase";


const ProfileImageDropzone = (props) => {
  const { reloadGallery, setReloadGallery, bandData } = props;
  const [selectedFile, setSelectedFile] = useState(null);
  const [thumbUrl, setThumbUrl] = useState(null);
  const [showUploadLoading, setShowUploadLoading] = useState(false);
  const dispatch = useDispatch(); // to update store values

  const handleChangeStatus = (file) => {
    setSelectedFile(file)
  }
  
  useEffect(() => {
    setThumbUrl(bandData?.photo?.thumbnail);
  }, [bandData])

  const handleSubmit = async () => {
    setShowUploadLoading(true);
    const promise = await Promise.all([uploadMedia(selectedFile, undefined, "profile")]);
    const [profile] = promise;
			await profile.json().then(async (res) => {
        if(res?.status == 'SUCCESS'){
          await updateBandPhotoId(res?.item?.id, bandData?.id, 'profile');
          let user = JSON.parse(localStorage.getItem("user") || "");
          user = { ...user, photo: res.item, status: res.item.status, profile_images_status: "" };
          dispatch(userActions.updateTalentStatuses(user))
          setThumbUrl(res?.item?.thumbnail);
          user = JSON.stringify(user);
          localStorage.setItem("user", user);
        }
			});
      setShowUploadLoading(false);
      setReloadGallery(!reloadGallery);
      setSelectedFile(null);
  }

  return (
    <Grid container direction="row" alignItems="flex-start">
      <Grid item xs={12} md={12} lg={12} style={{paddingBottom: "5px"}}>
          <InputLabel htmlFor="header_photo">
              Profile Image
          </InputLabel>
      </Grid>
      <Grid item xs={12} md={8} lg={8} className="image-gallery-profile-dropzone">
        <ProfileDropzone
          accept="image/*"
          url={thumbUrl}
          submitButtonContent="Upload"
          handleChange={handleChangeStatus}
          inputWithFilesContent="Add Images"
          btnText={<div style={{paddingLeft: "10px"}}>
              <div style={{marginBottom: "10px"}}>Change Profile Picture</div>

              {selectedFile && <div>
                  <Button 
                    type="submit" 
                    size="large" 
                    variant="contained" 
                    color="secondary" 
                    onClick={handleSubmit}
                    disabled={false}> 
                      <span style={{paddingRight: "8px"}}>Upload</span> 
                      {showUploadLoading && <CircularProgress size={15} />}
                  </Button>
              </div>}
            </div>}
        // title={formikBag.values.name}
        />
      </Grid>
    </Grid>
  )
}

export default ProfileImageDropzone;