import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { IAppState } from "../../../generic/interfaces";
import { ListEvents } from "./ListBookerEvent";
import { setPage } from "../../../HomePage/homePageSlice";
import {
    deleteBookerEvent,
    listBookerEventsList,
    listBookerPastEventsList,
    listEventHistory,
    setFilterText,
    setPastFilterText,
    loadMore,
    loadMorePast,
} from "./listBookerEventsSlice";
import { setSelectedEvent } from "../../../BandPage/BandRequest/bandRequestSlice";
import { openReviewTalentDialog } from "../../TalentEvent/ReviewTalent/reviewTalentSlice";

const mapStateToProps = (state: IAppState, ownProps: any) => ({
    listBookerEvent: state.account.bookerEvent.listBookerEvent,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
    listBookerEventsList: (textFilter: string) => dispatch(listBookerEventsList(textFilter)),
    listBookerPastEventsList: (textFilter: string) => dispatch(listBookerPastEventsList(textFilter)),
    deleteBookerEvent: (event: any) => {
        dispatch(deleteBookerEvent(event));
    },
    setPage: (page: string) => {
        dispatch(setPage(page));
    },
    setSelectedEvent: (eventItem: any) => dispatch(setSelectedEvent(eventItem)),
    loadMore: (textFilter: string) => dispatch(loadMore(textFilter)),
    loadMorePast: (pastTextFilter: string) => dispatch(loadMorePast(pastTextFilter)),
    setTextFilter: (textFilter: string) => dispatch(setFilterText(textFilter)),
    setPastFilterText: (pastTextFilter: string) => dispatch(setPastFilterText(pastTextFilter)),
    listEventHistory: (id: number) => dispatch(listEventHistory(id)),
    openReviewTalentDialog: (bandId: number, eventId: number, location: string) =>
        dispatch(
            openReviewTalentDialog({
                bandId,
                eventId,
                location,
            })
        ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ListEvents);
