import { combineReducers } from "redux";
import page from "./pageSlice";
import filter from "./filterSlice";
import sort from "./sortSlice";

export const filterFavoriteBands = combineReducers({
	filter,
	sort,
	page,
});
