import { refineResponse } from "../helpers/refineResponse";
import { headers, baseUrl } from "../../AuthBase/helpers/auth-header";

export const listBlogs = (page: number) => {
	const requestOptions = {
		method: "POST",
		headers: { ...headers() },
		body: JSON.stringify({ page, items_per_page: 6 }),
	};

	// waiting for api
	return fetch(`${baseUrl}blogs/list`, requestOptions).then(refineResponse);
};

export const getBlog = (id: number) => {
	const requestOptions = {
		method: "GET",
		headers: { ...headers() },
	};
    

	return fetch(`${baseUrl}blogs/get_single/${id}`, requestOptions).then(refineResponse);
};

export const getBlogBySlug = (slug: string) => {
	const requestOptions = {
		method: "GET",
		headers: { ...headers() },
	};
    

	return fetch(`${baseUrl}blogs/get_single_by_slug/${slug}`, requestOptions).then(refineResponse);
};