import React, { useEffect } from "react";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import makeStyles from "@material-ui/core/styles/makeStyles";
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles";
import { Carousel } from "react-responsive-carousel";
import { RouteComponentProps } from "react-router-dom";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import BookingBg from "../Assets/BookingBg.jpg";
import { HowitWorksMeta } from "../Assets/metadata.json";
import { IsAnonymous } from "../AuthBase/";
import { MetadataTags } from '../generic/components/MetadataTags/metadata-tags'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            "@media only screen and (max-width: 900px)": {
                // eslint-disable-line no-useless-computed-key
                width: "100%",
                fontWeight: 900,
                fontSize: "3rem",
                lineHeight: theme.typography.pxToRem(46),
            },
            fontFamily: "Rubik",
            fontSize: "5rem",
            fontWeight: 900,
            lineHeight: theme.typography.pxToRem(74),
            "word-wrap": "break-word",
            width: "720px",
        },
        paragraph: {
            fontSize: "16px",
            lineHeight: "1.8",
            marginBottom: "46px",
        },
        titleHowItWorks: {
            color: theme.palette.secondary.main,
            fontSize: "50px",
            textAlign: "center",
            fontWeight: 800,
            "@media only screen and (max-width: 900px)": {
                fontSize: "40px",
            },
            "@media screen and (max-width: 640px)": {
                fontSize: "36px",
            },
        },
        image: {
            height: "14em",
            width: "14em",
        },
        howItIcon: {
            width: "100%",
            maxWidth: "128px",
        },
        textAlign: {
            textAlign: "center",
        },
        text: {
            "@media only screen and (max-width: 900px)": {
                // eslint-disable-line no-useless-computed-key
                width: "100%",
            },
            width: "50%",
            fontFamily: "poppins",
        },
        testimonials: {
            "@media only screen and (max-width: 900px)": {
                width: "100%",
            },
        },
        owner: {
            fontWeight: "bold",
            fontSize: "23px",
        },
        responsive: {
            "@media only screen and (max-width: 900px)": {
                width: "100%",
            },
        },
        BenefitsBookingTitle: {
            marginTop: '150px',
            marginBottom: '72px',
            "@media only screen and (max-width: 900px)": {
                width: "100%",
                marginTop: '100px',
                marginBottom: '50px',
            },
            "@media only screen and (max-width: 767px)": {
                marginTop: '80px',
                marginBottom: '40px',
            },
            "@media only screen and (max-width: 640px)": {
                width: "100%",
                marginTop: '60px',
                marginBottom: '30px',
            },
        }
    })
);

export interface HowItWorksProps {
    howItWorks: any;
    quotes: any;
    testimonials: any;
    benefits: any;
    faqs: any;
    mainText: any;
    getFaqs: (type: string) => void;
    getBenefits: (type: string) => void;
    getHowItWorks: (type: string) => void;
    getMainText: (type: string) => void;
    getQuotes: () => void;
    getTestimonials: () => void;
    openSignupModal: () => void;
    setPage: (page: string) => void;
}

export const HowItWorks: React.FC<RouteComponentProps<{
    type: "booker" | "talent";
}> &
    HowItWorksProps> = ({ setPage, openSignupModal, getHowItWorks, howItWorks, getBenefits, benefits, quotes, getQuotes, testimonials, getTestimonials, faqs, getFaqs, match, getMainText, mainText }) => {
    const { type } = match.params;

    useEffect(() => {
        getHowItWorks(type);
        getQuotes();
        getTestimonials();
        getBenefits(type);
        getFaqs(type);
        setPage(type);
        getMainText(type)
    }, [type, setPage, getFaqs, getBenefits, getTestimonials, getQuotes, getHowItWorks, getMainText]);

    const classes = useStyles();
    return (
        <>
            <MetadataTags 
                title={HowitWorksMeta?.title}
                description={HowitWorksMeta?.description}        
                image={HowitWorksMeta?.image}              
            />
            <Container maxWidth="lg">
                <Box mt="100px">
                    <Grid container direction="column">
                        <Grid item>
                            <Typography variant="subtitle2" className={classes.title}>
                                {mainText?.title}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <p className={classes.paragraph}>
                                {mainText?.body}
                            </p>
                        </Grid>
                        <IsAnonymous>
                            <Grid item>
                                <Button
                                    size="medium"
                                    variant="contained"
                                    color={type === "talent" ? "secondary" : "primary"}
                                    onClick={() => openSignupModal()}>
                                    Sign Up
                                </Button>
                            </Grid>
                        </IsAnonymous>
                    </Grid>
                </Box>
                <Box
                    mt="20px"
                    mb={9}
                    border={15}
                    borderColor="#0F24FD"
                    p={3}
                    pt={6}
                    pb={6}
                    display="flex"
                    justifyContent="center"
                    flexDirection="column"
                    alignItems="center"
                    style={{
                        borderImage: "linear-gradient( to top, #0F24FD, #1a7cf6) 1 47%",
                        backgroundColor: 'rgba(8,5,34,0.8)',
                    }}
                    className=""
                    >
                    <Grid container direction="column" alignItems="center" spacing={6}>
                        <Grid item>
                            <Typography className={classes.titleHowItWorks} variant="subtitle2">
                                How it Works
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Grid container direction="row" justify="space-between">
                                {howItWorks &&
                                    howItWorks.howItWorks &&
                                    howItWorks.howItWorks.items.map((item: any) => {
                                        return (
                                            <Grid key={item.id} item xs={12} md={4} lg={4}>
                                                <Grid container alignItems="center" direction="column">
                                                    <Grid item>
                                                        <img src={item.photo.url} className={classes.howItIcon} alt="stepsIcons" />
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography variant="h4" style={{marginBottom:'10px', fontSize: '1.8rem',}}>{item.subtitle.toUpperCase()}</Typography>
                                                    </Grid>
                                                    <Grid item md={8} className={classes.textAlign}>
                                                        <Typography>{item.text}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        );
                                    })}
                            </Grid>
                        </Grid>
                        <IsAnonymous>
                            <Grid item>
                                <Button size="medium" variant="contained" color="secondary" onClick={() => openSignupModal()}>
                                    Sign Up
                                </Button>
                            </Grid>
                        </IsAnonymous>
                    </Grid>
                </Box>
                <Box className={classes.BenefitsBookingTitle}>
                    <Typography className={classes.titleHowItWorks} variant="subtitle2">
                        Benefits of Booking with Repsy
                    </Typography>
                </Box>
                <Grid container direction="row" spacing={5} justify="center">
                    {benefits &&
                        benefits.items.map((item: any) => {
                            return (
                                <Grid key={item.id} item xs={12} md={4} lg={5}>
                                    <Grid container direction="row" spacing={1}>
                                        <Grid item>
                                            <CheckCircleOutlineOutlinedIcon fontSize="small" color="secondary" />
                                        </Grid>
                                        <Grid item xs={11} md={11} lg={10}>
                                            <Typography>{item.body}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            );
                        })}
                </Grid>
                <Box mt="90px">
                    <Carousel showArrows={false} showThumbs={false} showStatus={false} useKeyboardArrows>
                        {quotes &&
                            quotes.items.map((item: any) => {
                                return (
                                    <Box mx="auto" key={item.id} className={classes.text} fontStyle="italic" fontSize="26px" fontWeight="200">
                                        <p>{item.text}</p>
                                        <Typography color="secondary" className={classes.owner} variant="body1">
                                            {item.owner}
                                        </Typography>
                                    </Box>
                                );
                            })}
                    </Carousel>                    
                </Box>
                {testimonials && testimonials.items.length > 0 && (
                    <Carousel showArrows={false} showThumbs={false} showStatus={false} useKeyboardArrows dynamicHeight={true}>
                        {testimonials &&
                            testimonials.items.map((item: any) => {
                                return (
                                    <Box
                                        key={item.id}
                                        width="100%"
                                        maxWidth="500px"
                                        mx="auto"
                                        className={classes.testimonials}
                                        fontFamily="poppins"
                                        fontWeight="200"
                                        fontSize="22px"
                                        fontStyle="italic">
                                        {/* <Typography style={{width: '100%', maxWidth: '500px', fontSize: '22px', fontStyle: 'italic', fontFamily: 'poppins', fontWeight: 200, marginLeft: 'auto', marginRight: 'auto'}}>{item.description}</Typography> */}
                                        <Typography color="secondary" variant="body1" className={classes.owner} style={{paddingTop: '15px', fontSize:'17px', textTransform: 'uppercase'}}>
                                            - {item.name}
                                        </Typography>
                                        <p>{item.description}</p>
                                    </Box>
                                );
                            })}
                    </Carousel>
                )}
                <Box mb="30px" className={classes.responsive}>
                    <Typography className={classes.titleHowItWorks} variant="subtitle2">
                        Frequently Asked Questions
                    </Typography>
                </Box>
                <Box width="85%" mx="auto" className={classes.responsive}>
                    {faqs &&
                        faqs.items.map((item: any) => {
                            return (
                                <ExpansionPanel key={item.id}>
                                    <ExpansionPanelSummary
                                        expandIcon={<ArrowDropDownIcon fontSize="large" color="secondary" />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header">
                                        <Typography variant="subtitle2">{item.question}</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <Typography>{item.answer}</Typography>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            );
                        })}
                </Box>
                <Box mt="80px"></Box>
            </Container>
        </>
    );
};
