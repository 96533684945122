import { AuthActionType, IAuthAction } from "../interfaces";
import { combineReducers } from "redux";
import { IUser } from "../../interfaces";
import { HeaderActionType, IHeaderActions } from "../../Header/interfaces";

const data = localStorage.getItem("user") || "{}";
const userData = JSON.parse(data);

const isLoggingIn = (state: boolean = false, action: IAuthAction) => {
	switch (action.type) {
		case AuthActionType.LOGIN_REQUEST:
			return true;
		case AuthActionType.LOGIN_SUCCESS:
		case AuthActionType.LOGIN_FAILURE:
		case AuthActionType.LOGOUT:
			return false;
		default:
			return state;
	}
};
const isLoggedIn = (state: boolean = userData && userData.api_token ? true : false, action: IAuthAction) => {
	switch (action.type) {
		case AuthActionType.LOGIN_SUCCESS:
			return true;
		case AuthActionType.LOGIN_REQUEST:
		case AuthActionType.LOGIN_FAILURE:
		case AuthActionType.LOGOUT:
			return false;
		default:
			return state;
	}
};

const user = (state: IUser | {} = userData, action: IAuthAction) => {
	switch (action.type) {
		case AuthActionType.EDIT_USER:
			return { ...state, photo: action.payload.photo, status: action.payload.status};
		case AuthActionType.LOGIN_SUCCESS:
		case AuthActionType.LOGIN_REQUEST:
			return action.payload;
		case AuthActionType.LOGIN_FAILURE:
		case AuthActionType.LOGOUT:
			return {};
		case AuthActionType.UPDATE_USER:
			return { ...state, status: action.payload.status }
		case AuthActionType.UPDATE_TALENT_STATUSES:
			return { ...state, 
				band_profile_status: action.payload.band_profile_status,
				profile_images_status: action.payload.profile_images_status,
				billing_status: action.payload.billing_status,
			}
		default:
			return state;
	}
};
const error = (state = null, action: IAuthAction | IHeaderActions) => {
	switch (action.type) {
		case AuthActionType.LOGIN_FAILURE:
			return action.payload;
		case AuthActionType.LOGIN_SUCCESS:
		case AuthActionType.LOGIN_REQUEST:
		case AuthActionType.LOGOUT:
		case HeaderActionType.LOGIN_MODAL_OPEN:
			return null;
		default:
			return state;
	}
};

const firstLogIn = (state = false, action: IAuthAction) => {
	switch (action.type) {
		case AuthActionType.FIRST_LOGIN_POPUP_OPEN:
			return true;
		case AuthActionType.FIRST_LOGIN_POPUP_CLOSE:
			return false;
		default:
			return state;
	}
};

export const authentication = combineReducers({
	isLoggingIn,
	isLoggedIn,
	user,
	error,
	firstLogIn,
});
