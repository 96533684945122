import { refineResponse } from "../helpers/refineResponse";
import { headers, baseUrl } from "../../AuthBase/helpers/auth-header";

export const getBandsPriceList = () => {
	const requestOptions = {
		method: "GET",
		headers: { ...headers() },
	};

	return fetch(`${baseUrl}search/price-list`, requestOptions).then(
		refineResponse,
	);
};
