import React, { useState } from "react";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import Grid from "@material-ui/core/Grid";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import Box from "@material-ui/core/Box";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import SettingsIcon from "@material-ui/icons/Settings";
import moment from "moment";
import { RequestObject } from "../TalentEvent/ListTalentEvents/listTalentEventsSlice";
import { EventDescription } from "./EventDescription";
import { useHistory, useParams } from "react-router-dom";
import { IAuthReducer } from "../../AuthBase/interfaces";
import Services from "../../generic/Services";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		ExpansionPanelSummary: {
			flexDirection: "row-reverse",
		},
		ExpansionPanel: {
			borderBottom: "1px solid rgba(100, 254, 204, 0.5)",
		},
		ExpansionPanelDetails: {
			paddingLeft: "65px",
			flexDirection: "column",
		},
		iconButton: {
			marginRight: "20px",
		},
		settingsIcon: {
			position: "absolute",
			top: "14px",
			right: "12px",
		},
		margin: {
			margin: "0px",
		},
		marginMenuItem: {
			marginTop: theme.spacing(1),
		},
		letterSpacing: {
			letterSpacing: "3px",
		},
		accept: {
			color: "#000",
		},
		cancel: {
			color: "#000",
			backgroundColor: "#808080",
		},
		decline: {
			color: "#000",
			backgroundColor: "rgb(255,0,0,1)",
			"&:hover": {
				backgroundColor: "rgb(255,0,0,0.8)",
			},
		},
		title: {
			"word-wrap": "break-word",
			maxWidth: "550px"
		},
	}),
);

interface RequestItemProps {
	authentication: IAuthReducer;
	request: RequestObject;
	status: string;
	intialOpen: boolean;
	showEventHistory: () => void;
	deleteRequest: (requestId: number) => void;
	changeRequestStatus: (requestId: number, status: string) => void;
	openReviewTalentDialog: () => void;
}

export const RequestItem: React.FC<RequestItemProps> = ({
	authentication,
	request,
	status,
	intialOpen,
	showEventHistory,
	deleteRequest,
	changeRequestStatus,
	openReviewTalentDialog,
}) => {
	const requestStatus = {
		acceptedByTalent: "ACCEPTED_BY_TALENT",
		rejectedByTalent: "REJECTED_BY_TALENT",
		cancelledByTalent: "CANCELED_BY_TALENT",
		open: "OPEN",
	};

	const classes = useStyles();

	const [open, setOpen] = React.useState<boolean>(intialOpen);

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

	const [openDelete, setOpenDelete] = useState<boolean>(false);

	const history = useHistory();
	let sendMessageUrl = '/account/messages';
	const { bandId } = useParams();

	const updateRequestLastSeen = () => {
        if(bandId)
		{
			Services.agent.updateAgentRequestLastSeen(request.id).then(data => {})
		}
    };



	if(bandId){
		sendMessageUrl = `/account/messages/band/${bandId}`;
	}
	return (
		<ExpansionPanel
			className={classes.ExpansionPanel}
			expanded={open}
			onChange={(event: object, expanded: boolean) => {
				if (expanded) {
					updateRequestLastSeen();
					setOpen(true);
				} else {
					setOpen(false);
				}
			}}>
			<ExpansionPanelSummary
				className={classes.ExpansionPanelSummary}
				IconButtonProps={{
					edge: "start",
					classes: { root: classes.iconButton },
				}}
				expandIcon={
					<ArrowDropDownIcon fontSize="large" color="secondary" />
				}
				aria-controls="panel1a-content"
				id="panel1a-header">
				<Grid
					container
					direction="row"
					alignItems="center"
					justify="space-between">
					<Grid item xs={6} md={6} lg={6}>
						<Typography className={classes.title}>{request.event.title}</Typography>
					</Grid>
					<Grid item xs={4} md={4} lg={4}>
						{!open && (
							<h4
								className={`${classes.margin} ${classes.letterSpacing}`}>
								{moment(request.event.date, "YYYY.MM.DD")
										.format("LL")
										.toString() + " " +
										moment(request.event.start_time, "hh:mm").format("hh:mm A").toString()}
							</h4>
						)}
					</Grid>
				</Grid>
			</ExpansionPanelSummary>
			{open && (
				<IconButton
					aria-controls="settings"
					aria-haspopup="true"
					className={classes.settingsIcon}
					onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
						setAnchorEl(event.currentTarget)
					}>
					<SettingsIcon color="secondary" />
				</IconButton>
			)}
			<Menu
				MenuListProps={{
					disablePadding: true,
				}}
				classes={{
					paper: classes.margin,
				}}
				id="settings"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={() => setAnchorEl(null)}
				getContentAnchorEl={null}
				anchorOrigin={{
					vertical: "center",
					horizontal: "center",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}>
				<MenuItem onClick={() => showEventHistory()}>
					<Typography variant="body2">View Event History</Typography>
				</MenuItem>
				
				<MenuItem className={classes.marginMenuItem}
					onClick={() => history.push({
						pathname: sendMessageUrl,
						state: { new: true, requestId: request.id },
					})}>
					<Typography variant="body2">Send a Message</Typography>
				</MenuItem>	
				
				<Dialog
					open={openDelete}
					onClose={() => setOpenDelete(false)}
					aria-describedby="alert-dialog-delete">
					<DialogContent>
						<DialogContentText
							color="textPrimary"
							id="alert-dialog-delete">
							Are you sure you want to delete this request?
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button
							onClick={() => {
								deleteRequest(request.id);
								setOpenDelete(false);
							}}
							color="default">
							Delete
						</Button>
						<Button
							onClick={() => setOpenDelete(false)}
							color="default"
							autoFocus>
							Cancel
						</Button>
					</DialogActions>
				</Dialog>
			</Menu>
			<ExpansionPanelDetails className={classes.ExpansionPanelDetails}>
				<EventDescription request={request} />
				<Box mt={4} display="flex" justifyContent="flex-end">
					{status === "ACTIVE" ? (
						<>
							<Grid container justify="flex-end" spacing={3}>
								{/* if the status is open there will be confirm button else not */}
								{request.status === requestStatus.open ? (
									<Grid item>
										<Button
											className={classes.accept}
											size="small"
											variant="contained"
											color="secondary"
											onClick={() =>
												changeRequestStatus(
													request.id,
													requestStatus.acceptedByTalent,
												)
											}>
											Accept
										</Button>
									</Grid>
								) : null}
								<Grid item>
									<Button
										className={
											request.status ===
											requestStatus.open
												? classes.decline
												: classes.cancel
										}
										size="small"
										variant="contained"
										color="secondary"
										// decline === reject
										// if the status is open then call "rejectedByTalent" else its "cancelledByTalent"
										onClick={() =>
											changeRequestStatus(
												request.id,
												request.status ===
													requestStatus.open
													? requestStatus.rejectedByTalent
													: requestStatus.cancelledByTalent,
											)
										}>
										{request.status === requestStatus.open
											? "Decline"
											: "Cancel"}
									</Button>
								</Grid>
							</Grid>
						</>
					) : (
						<Grid container justify="flex-end" spacing={3}>
							<Grid item>
								<Button
									size="small"
									variant="contained"
									color="secondary"
									onClick={() => {
										openReviewTalentDialog();
									}}>
									Leave a Review
								</Button>
							</Grid>
						</Grid>
					)}
				</Box>
			</ExpansionPanelDetails>
		</ExpansionPanel>
	);
};
