import { baseUrl, headers } from "../../AuthBase/helpers/auth-header";
import { refineResponse } from "../helpers/refineResponse";

export const add = (event: object) => {
	const requestOptions = {
		method: "POST",
		headers: { ...headers() },
		body: JSON.stringify(event),
	};

	return fetch(`${baseUrl}events`, requestOptions).then(refineResponse);
};

export const listBandRequests = (
	bandId: number,
	options: {
		text?: string;
		page?: number;
		items_per_page?: number;
	},
) => {
	const requestOptions = {
		method: "POST",
		headers: { ...headers() },
		body: JSON.stringify({
			items_per_page: 10,
			page: 1,
			...options,
		}),
	};

	return fetch(
		`${baseUrl}active/talent-request/${bandId}`,
		requestOptions,
	).then(refineResponse);
};

export const listPastBandRequests = (
	bandId: number,
	options: {
		text?: string;
		page?: number;
		items_per_page?: number;
	},
) => {
	const requestOptions = {
		method: "POST",
		headers: { ...headers() },
		body: JSON.stringify({
			items_per_page: 10,
			page: 1,
			...options,
		}),
	};

	return fetch(
		`${baseUrl}past/talent-request/${bandId}`,
		requestOptions,
	).then(refineResponse);
};

export const deleteEvent = (eventId: number) => {
	const requestOptions = {
		method: "DELETE",
		headers: { ...headers() },
	};

	return fetch(`${baseUrl}events/${eventId}`, requestOptions).then(
		refineResponse,
	);
};

export const list = (options: {
	text?: string;
	page?: number;
	items_per_page?: number;
}) => {
	const requestOptions = {
		method: "POST",
		headers: { ...headers() },
		body: JSON.stringify({
			items_per_page: 10,
			page: 1,
			...options,
		}),
	};

	return fetch(`${baseUrl}my-events/list`, requestOptions).then(
		refineResponse,
	);
};

export const listPastEvents = (options: {
	text?: string;
	page?: number;
	items_per_page?: number;
}) => {
	const requestOptions = {
		method: "POST",
		headers: { ...headers() },
		body: JSON.stringify({
			items_per_page: 10,
			page: 1,
			...options,
		}),
	};

	return fetch(`${baseUrl}past-events/list`, requestOptions).then(
		refineResponse,
	);
};

export const listEventById = (eventId: number) => {
	const requestOptions = {
		method: "GET",
		headers: { ...headers() },
	};

	return fetch(`${baseUrl}events/${eventId}`, requestOptions).then(
		refineResponse,
	);
};

export const update = (event: any, eventId: number) => {
	const requestOptions = {
		method: "PUT",
		headers: { ...headers() },
		body: JSON.stringify(event),
	};

	return fetch(`${baseUrl}events/${eventId}`, requestOptions).then(
		refineResponse,
	);
};

export const getHowItWorks = (type: string) => {
	const requestOptions = {
		method: "GET",
		headers: { ...headers() },
	};

	return fetch(
		`${baseUrl}welcome-screen/list?role=${type}`,
		requestOptions,
	).then(refineResponse);
};

export const getQuotes = () => {
	const requestOptions = {
		method: "POST",
		headers: { ...headers() },
	};

	return fetch(`${baseUrl}quotes/list`, requestOptions).then(
		refineResponse,
	);
};

export const getTestimonials = () => {
	const requestOptions = {
		method: "GET",
		headers: { ...headers() },
	};

	return fetch(`${baseUrl}get_testimonial`, requestOptions).then(
		refineResponse,
	);
};

export const getMainText = () => {
	const requestOptions = {
		method: "POST",
		headers: { ...headers() },
	};

	return fetch(`${baseUrl}how-it-works/list`, requestOptions).then(
		refineResponse,
	);
};

export const getBenefits = (type: string) => {
	const requestOptions = {
		method: "POST",
		headers: { ...headers() },
	};

	return fetch(`${baseUrl}benefits/list?role=${type}`, requestOptions).then(
		refineResponse,
	);
};

export const getFaqs = (type: string) => {
	const requestOptions = {
		method: "POST",
		headers: { ...headers() },
	};

	return fetch(`${baseUrl}faqs/list?role=${type}`, requestOptions).then(
		refineResponse,
	);
};

export const listVenueDescription = () => {
	const requestOptions = {
		method: "POST",
		headers: { ...headers() },
		body: JSON.stringify({ page: 1, items_per_page: 100 }),
	};

	return fetch(`${baseUrl}venues/list`, requestOptions).then(
		refineResponse,
	);
};

export const listEventHistoryById = (id: number) => {
	const requestOptions = {
		method: "POST",
		headers: { ...headers() },
		body: JSON.stringify({ page: 1, items_per_page: 100 }),
	};

	return fetch(`${baseUrl}event/history/${id}`, requestOptions).then(
		refineResponse,
	);
};

export const addReview = ({
	userReview,
	userRating,
	location,
	bandId,
	eventId,
	repsyReview,
	repsyRating,
}: {
	userReview: string;
	userRating: number;
	location: string;
	bandId: number;
	eventId: number;
	repsyReview: string;
	repsyRating: number;
}) => {
	const requestOptions = {
		method: "POST",
		headers: { ...headers() },
		body: JSON.stringify({
			userReview,
			userRating,
			location,
			band: { id: bandId },
			event: { id: eventId },
			repsyReview,
			repsyRating,
		}),
	};

	return fetch(`${baseUrl}reviews`, requestOptions).then(refineResponse);
};
