import { headers, baseUrl } from "../../AuthBase/helpers/auth-header";

import { refineResponse } from "../helpers/refineResponse";

export const login = (email: string, password: string) => {
	const fcmToken = localStorage.getItem("FCM_Token");
	const requestOptions = {
		method: "POST",
		headers: { ...headers(), "Push-Token": fcmToken ? fcmToken : "" },
		body: JSON.stringify({ login: email, password }),
	};

	return fetch(`${baseUrl}users/login`, requestOptions)
		.then(refineResponse)
		.then(async (res) => {
			// store user details and jwt token in local storage to keep user logged in between page refreshes
			localStorage.setItem("user", JSON.stringify({ ...res.item }));
			if (res.item.role === "TALENT") {
				await fetch(`${baseUrl}my-bands/list`, {
					method: "POST",
					headers: { ...headers() },
				})
					.then(refineResponse)
					.then((ress) => {
						if(ress.items.length > 0) {
							res.item.bandId = ress.items[0].id;
							res.item.photo = ress.items[0].photo;
							localStorage.setItem("user", JSON.stringify({ ...res.item }));
						}
					});
			}
			return res;
		});
};

export const logout = () => {
	// remove user from local storage to log user out
	const fcmToken = localStorage.getItem("FCM_Token");
	const requestOptions = {
		method: "POST",
		headers: { ...headers(), "Push-Token": fcmToken ? fcmToken : "" },
	};
	localStorage.clear();
	return fetch(`${baseUrl}users/signout`, requestOptions).then(refineResponse);
};

export const register = (user: any) => {
	const { confirmPassword, ...temp } = user;
	const requestOptions = {
		method: "POST",
		headers: { ...headers() },
		body: JSON.stringify(temp),
	};
	return fetch(`${baseUrl}users/signup`, requestOptions).then(refineResponse);
};

export const registerV2 = async (user: any) => {
	const requestOptions = {
		method: "POST",
		headers: { ...headers() },
		body: JSON.stringify({email: user?.email}),
	};
	return fetch(`${baseUrl}v2/users/signup`, requestOptions).then(refineResponse);
};

export const confirmV2 = (email: string, confirmationToken: string) => {
	const requestOptions = {
		method: "GET",
		headers: { ...headers() },
	};
	return fetch(
		`${baseUrl}v2/confirm-account?email=${email}&confirmation_token=${confirmationToken}`,
		requestOptions,
	).then(refineResponse);
};

export const CreateProfileV2 = async (user: any) => {
	const { confirmPassword, ...temp } = user;
	const requestOptions = {
		method: "POST",
		headers: { ...headers() },
		body: JSON.stringify(temp),
	};
	return fetch(`${baseUrl}v2/users/create_profile`, requestOptions).then(refineResponse);
};

export const CheckEmailVerificationStatusV2 = async (email: string) => {
	const requestOptions = {
		method: "POST",
		headers: { ...headers() },
		body: JSON.stringify({email: email}),
	};
	return fetch(`${baseUrl}v2/users/check_email_verification`, requestOptions).then(refineResponse);
};



export const resendConfirmationEmail = (email: any) => {
	const requestOptions = {
		method: "POST",
		headers: { ...headers() },
		body: JSON.stringify({ email }),
	};
	return fetch(`${baseUrl}users/resend-verification`, requestOptions).then(refineResponse);
};

export const confirm = (email: string, confirmationToken: string) => {
	const requestOptions = {
		method: "GET",
		headers: { ...headers() },
	};
	return fetch(
		`${baseUrl}confirm-account?email=${email}&confirmation_token=${confirmationToken}`,
		requestOptions,
	).then(refineResponse);
};

export const forgetPassword = (data: { email: string }) => {
	const requestOptions = {
		method: "POST",
		headers: { ...headers() },
		body: JSON.stringify(data),
	};
	return fetch(`${baseUrl}users/forgot-password`, requestOptions).then(refineResponse);
};

export const resetPassword = (data: { password: string }, token: string) => {
	const requestOptions = {
		method: "POST",
		headers: { ...headers() },
		body: JSON.stringify(data),
	};
	return fetch(`${baseUrl}users/reset-password?reset-token=${token}`, requestOptions).then(refineResponse);
};

export const sendInquiry = (inquiry: { name: string; email: string; messge: string }) => {
	const requestOptions = {
		method: "POST",
		headers: { ...headers() },
		body: JSON.stringify(inquiry),
	};
	return fetch(`${baseUrl}contact-us`, requestOptions).then(refineResponse);
};

export const sendSupportInquiry = (inquiry: { type: string; message: string }) => {
	const requestOptions = {
		method: "POST",
		headers: { ...headers() },
		body: JSON.stringify(inquiry),
	};
	return fetch(`${baseUrl}support`, requestOptions).then(refineResponse);
};

export const sendPushToken = (token: any) => {
	const requestOptions = {
		method: "POST",
		headers: { ...headers(), "Push-Token": token },
	};
	return fetch(`${baseUrl}notification/push-token`, requestOptions).then(refineResponse);
};

export const getUserData = () => {
	const requestOptions = {
		method: "POST",
		headers: { ...headers() },
	};
	return fetch(`${baseUrl}users/details`, requestOptions).then(refineResponse);
}

export const sendSuggestion = (data: any) => {
	const requestOptions = {
		method: "POST",
		headers: { ...headers() },
		body: JSON.stringify(data),
	};
	return fetch(`${baseUrl}send_suggestion`, requestOptions).then(refineResponse);
};