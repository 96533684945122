import React from "react";
import Downshift from "downshift";
import {
	Input,
	ListItem,
	List,
	ListItemText,
	Paper,
	Typography,
	Grid,
	Link,
	Button,
} from "@material-ui/core";
import location from "../../Assets/Location.svg";
import cancel from "../../Assets/Cancel.svg";

interface ILocationFilterProps {
	suggestions: any;
	getInitialValue: () => void;
	handleSubmit: (e: any) => void;
	handleClear: () => void;
	handleChangeLocation: (id: { id: number }) => void;
	handleClearLocation: () => void;
}

export interface IOptionType {
	label: string;
	id: number;
}

export class LocationFilter extends React.Component<ILocationFilterProps> {
	render() {
		return (
			<Downshift
				onChange={selection =>
					Object.keys(selection).length
						? this.props.handleChangeLocation({ id: selection.id })
						: this.props.handleClearLocation()
				}
				itemToString={item =>
					Object.keys(item).length ? item.label : ""
				}
				initialSelectedItem={this.props.getInitialValue()}
			>
				{({
					getInputProps,
					getItemProps,
					selectedItem,
					selectItem,
					inputValue,
				}) => (
					<div style={{ paddingTop: "15px", paddingLeft: "15px" }}>
						<Grid
							container
							spacing={2}
							direction="column"
							justify="flex-start"
						>
							<Grid item>
								<Grid
									container
									direction="row"
									justify="flex-start"
									alignItems="flex-end"
								>
									<Grid item>
										<Typography id="states" gutterBottom>
											Location
										</Typography>
									</Grid>
									<Grid item>
										<img
											height="25em"
											width="30em"
											src={location}
											alt="location"
											style={{
												paddingLeft: "8px",
												paddingBottom: "5px",
											}}
										/>
									</Grid>
								</Grid>
							</Grid>
							<Grid item>
								<Input
									placeholder="Search by State"
									{...getInputProps()}
									inputProps={{
										"aria-label": "states",
									}}
									endAdornment={
										<img
											onClick={() => {
												selectItem({});
											}}
											width="40em"
											height="35em"
											src={cancel}
											alt="cancel"
											style={{
												cursor: "pointer",
												paddingLeft: "15px",
											}}
										/>
									}
								/>
								<Paper
									style={{ height: 110, overflow: "hidden" }}
								>
									<List
										component="div"
										aria-label="states"
										style={{
											width: "100%",
											height: "100%",
											overflowY: "scroll",
											paddingRight: " 20px",
											boxSizing: "content-box",
										}}
									>
										{this.props.suggestions
											.filter(
												(state: any) =>
													!inputValue ||
													state.label
														.toLowerCase()
														.includes(
															inputValue.toLowerCase(),
														),
											)
											.map(
												(state: any, index: number) => (
													<ListItem
														button
														key={state.id}
														selected={
															selectedItem.id ===
															state.id
																? true
																: false
														}
														{...getItemProps({
															key: state.id,
															index,
															item: state,
														})}
													>
														<ListItemText
															primary={
																state.label
															}
														/>
													</ListItem>
												),
											)}
									</List>
								</Paper>
							</Grid>
						</Grid>
						<Grid item>
							<Grid
								container
								direction="row"
								justify="flex-end"
								alignItems="center"
								spacing={2}
							>
								<Grid item>
									<Link
										color="textPrimary"
										component="button"
										onClick={() => {
											selectItem({});
											this.props.handleClear();
										}}
									>
										Clear Filters
									</Link>
								</Grid>
								<Grid item>
									<Button
										size="small"
										variant="contained"
										color="secondary"
										onClick={this.props.handleSubmit}
									>
										Apply
									</Button>
								</Grid>
							</Grid>
						</Grid>
					</div>
				)}
			</Downshift>
		);
	}
}
