import { combineReducers } from "redux";
import reviewTalent from "../Account/TalentEvent/ReviewTalent/reviewTalentSlice";
import { bookerEvent } from "./BookerEvent/ListBookerEvents/listBookerEventsSlice";
import editBookerProfile from "./EditBookerProfilePage/editBookerProfileSlice";
import editAgentProfile from "./EditAgentProfilePage/editAgentProfileSlice";
import editTalentProfile from "./EditTalentProfilePage/editTalentProfileSlice";
import settings from "./SettingsPage/settingsSlice";
import bandEvent from "./TalentEvent/ListTalentEvents/listTalentEventsSlice";
import termsAndConditions from "./TermsAndConditionPage/termsAndConditionsSlice";
import support from "./Support/supportSlice"

export default combineReducers({
	bandEvent,
	bookerEvent,
	editBookerProfile,
	editTalentProfile,
	editAgentProfile,
	reviewTalent,
	settings,
	termsAndConditions,
	support,
});
