import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { IAppState } from "../../generic/interfaces";
import { setPage } from "../../HomePage/homePageSlice";
import { EditBookerProfile } from "./EditBookerProfile";
import {
	editBookerProfile,
	getBookerProfile,
	listBookerTypes,
	closeSaveModal,
	openSaveModal,
	closeFailModal,
} from "./editBookerProfileSlice";

const mapStateToProps = (state: IAppState) => ({
	bookerProfile: state.account.editBookerProfile,
	user: state.authentication.user,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
	editBookerProfile: (bandProfile: any) => {
		dispatch(editBookerProfile(bandProfile));
	},
	listBookerTypes: () => {
		dispatch(listBookerTypes());
	},
	getBookerProfile: () => {
		dispatch(getBookerProfile());
	},
	setPage: (page: string) => {
		dispatch(setPage(page));
	},
	handleSaveClose: () => dispatch(closeSaveModal()),
	handleOpen: () => dispatch(openSaveModal()),
	handleFailClose: () => dispatch(closeFailModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditBookerProfile);
