import React from "react";
import { Typography, MenuItem, Grid } from "@material-ui/core";
import { FilterBandsSortBy, FilterBandsSortDir } from "./interfaces";

export interface ISortProps {
	value: {
		by: FilterBandsSortBy;
		dir: FilterBandsSortDir;
	};
	setSort: (by: string, dir: string) => void;
	setSortText: (text: string | null) => void;
	handlePopoverClose: (e: any) => void;
}

const optionsValues = [
	{ by: "isFeatured", dir: "DESC" },
	{ by: "PRICE", dir: "ASC" },
	{ by: "PRICE", dir: "DESC" },
	{ by: "RATING", dir: "DESC" },
	{ by: "SIZE", dir: "ASC" },
	{ by: "SIZE", dir: "DESC" },
];

const options = [
	"Featured",
	"Price: Low to High",
	"Price: High to Low",
	"Highest Rated",
	"Band Size: Fewest to Most",
	"Band Size: Most to Fewest",
];

class Sort extends React.Component<ISortProps> {
	public state = {
		by: "isFeatured",
		dir: "DESC",
	};

	public componentDidMount() {
		const { value } = this.props;
		this.setState({
			...value,
		});
	}

	public getText = (by: string, dir: string) => {
		if (by === "PRICE" && dir === "ASC") {
			return "Price: Low to High";
		} else if (by === "PRICE" && dir === "DESC") {
			return "Price: High to Low";
		} else if (by === "RATING" && dir === "DESC") {
			return "Highest Rated";
		} else if (by === "SIZE" && dir === "DESC") {
			return "Band Size: Fewest to Most";
		} else if (by === "SIZE" && dir === "ASC") {
			return "Band Size: Most to Fewest";
		} else if (by === "isFeatured" && dir === "DESC") {
			return "Featured";
		} else {
			return "Sort By";
		}
	};

	public handleSubmit = () => {
		this.props.setSort(this.state.by, this.state.dir);
		this.props.setSortText(this.getText(this.state.by, this.state.dir));
	};

	public handleMenuItemClick(
		event: React.MouseEvent<HTMLElement>,
		index: number,
	) {
		this.props.setSort(optionsValues[index].by, optionsValues[index].dir);
		this.props.setSortText(
			this.getText(optionsValues[index].by, optionsValues[index].dir),
		);
		this.setState({
			by: optionsValues[index].by,
			dir: optionsValues[index].dir,
		});
		this.props.handlePopoverClose(event);
	}

	public isSelected = (index: number) => {
		return (
			optionsValues[index].by === this.state.by &&
			optionsValues[index].dir === this.state.dir
		);
	};

	public render() {
		return (
			<Grid container direction="column">
				<Grid item xs={12}>
					{options.map((option, index) => (
						<MenuItem
							key={index}
							selected={this.isSelected(index)}
							onClick={(event) =>
								this.handleMenuItemClick(event, index)
							}>
							<Typography
								style={{ fontSize: "0.9em" }}
								variant="body1">
								{option}
							</Typography>
						</MenuItem>
					))}
				</Grid>
			</Grid>
		);
	}
}
export default Sort;
