import { createSlice } from "redux-starter-kit";

const pageSlice = createSlice({
	slice: "pageFavorite",
	initialState: 1,
	reducers: {
		filterFavoriteBandsPageReset(state, action) {
			return 1;
		},
		filterFavoriteBandsPageIncrement(state, action) {
			return state + 1;
		},
	},
});

export const {
	filterFavoriteBandsPageReset,
	filterFavoriteBandsPageIncrement,
} = pageSlice.actions;

export default pageSlice.reducer;
