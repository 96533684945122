import React from "react";
import { ILoginpDialogProps } from "./interfaces";
import { CustomDialogTitle } from "../generic/components";
import { Divider, Dialog, DialogContent, Typography } from "@material-ui/core";
import { Formik } from "formik";
import * as Yup from "yup";
import Form from "./form";

const validationSchema = Yup.object({
	username_email: Yup.string().required("Username or Email is required"),
	password: Yup.string()
		.min(8, "Password must contain at least 8 characters")
		.required("Enter your password"),
	isRememberMe: Yup.boolean(),
});

class LoginDialog extends React.Component<ILoginpDialogProps> {
	render() {
		const {
			handleClose,
			opened,
			handleLoginSubmit,
			handleOpenForgotPassword,
			handleOpenResendActivationLink,
			handleOpenSignup,
			state,
			confirmable,
			passwordReset,
			login,
		} = this.props;
		const values = {
			username_email:
				localStorage.getItem("email") === null
					? ""
					: localStorage.getItem("email"),
			password: "",
			isRememberMe: localStorage.getItem("email") === null ? false : true,
		};

		return (
			<Dialog
				fullWidth={true}
				maxWidth="sm"
				open={opened}
				scroll="body"
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<CustomDialogTitle
					onClose={handleClose}
					id="alert-dialog-title"
				>
					{passwordReset ? (
						<>
							<Typography variant="h4">
								Your password has been reset.
							</Typography>
							<Typography variant="h4">
								You may login with your updated credentials.
							</Typography>
						</>						
					) : confirmable ? (
						<>
							<Typography variant="h4">
								YOUR ACCOUNT HAS BEEN CONFIRMED!
							</Typography>{" "}
							<Typography variant="subtitle1">
								Login to access your account
							</Typography>
						</>
					) : (
						<Typography variant="h4">
							LOGIN TO ACCESS YOUR ACCOUNT.
						</Typography>
					)}
				</CustomDialogTitle>
				<Divider variant="middle" style={{marginLeft: "50px", marginRight: "50px"}} />
				<DialogContent>
					<Formik
						render={props => (
							<Form
								{...props}
								state={state}
								handleOpenForgotPassword={
									handleOpenForgotPassword
								}
								handleOpenResendActivationLink={
									handleOpenResendActivationLink
								}
								handleOpenSignup={handleOpenSignup}
							/>
						)}
						initialValues={values}
						validationSchema={validationSchema}
						onSubmit={(values: any, actions) => {
							handleLoginSubmit(
								values.username_email,
								values.password,
								() => {
									login();
									if (values.isRememberMe === true) {
										localStorage.setItem(
											"email",
											values.username_email.toString(),
										);
									} else if (values.isRememberMe === false) {
										localStorage.removeItem("email");
									}
								},
							);
							actions.setSubmitting(true);
						}}
					/>
				</DialogContent>
			</Dialog>
		);
	}
}

// export default withStyles(dialogStyles)(LoginDialog);
export default LoginDialog;
