import { createSlice, PayloadAction } from "redux-starter-kit";
import Services from "../../generic/Services";

export interface TermsAndConditionsState {
	termsAndConditionsRequested: boolean;
	termsAndConditionsSucceeded: boolean;
	termsAndConditionsFailure: boolean;
	error: {};
	termsAndConditions: any;
}

let initialState: TermsAndConditionsState = {
	termsAndConditionsRequested: false,
	termsAndConditionsSucceeded: false,
	termsAndConditionsFailure: false,
	error: {},
	termsAndConditions: null,
};

const termsAndConditionsSlice = createSlice({
	slice: "termsAndConditions",
	initialState,
	reducers: {
		termsAndConditionsRequested(state, action) {
			state.termsAndConditionsRequested = true;
			state.termsAndConditionsSucceeded = false;
			state.termsAndConditionsFailure = false;
		},
		termsAndConditionsSucceeded(state, action: PayloadAction<boolean>) {
			state.termsAndConditionsSucceeded = true;
			state.termsAndConditionsRequested = false;
			state.termsAndConditionsFailure = false;
			state.termsAndConditions = action.payload;
		},
		termsAndConditionsFailure(state, action: PayloadAction<boolean>) {
			state.termsAndConditionsSucceeded = false;
			state.termsAndConditionsRequested = false;
			state.termsAndConditionsFailure = true;
			state.error = action.payload;
		},
	},
});

export const {
	termsAndConditionsRequested,
	termsAndConditionsSucceeded,
	termsAndConditionsFailure,
} = termsAndConditionsSlice.actions;

export default termsAndConditionsSlice.reducer;

export const listTermsAndConditions = (): any => async (dispatch: any) => {
	try {
		dispatch(termsAndConditionsRequested());
		const res = await Services.termsAndConditions.listTermsAndConditions();
		dispatch(termsAndConditionsSucceeded(res));
	} catch (err) {
		dispatch(termsAndConditionsFailure(err));
	}
};
