import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import { CustomDialogTitle } from "../generic/components";
import Form from "./form";
import { Formik } from "formik";
import * as Yup from "yup";
import { useHistory, useLocation } from "react-router-dom";
import { registerV2, confirmV2, CreateProfileV2 } from "../generic/Services/users";
import { ISignupDialogOwnProps, ISignupDialogProps } from "./interfaces";

/* SIGNUP STEPS
    Step - 1: Email verification
    Step - 2: Verification sent confirmation
    Step - 3: Resend email verification
    Step - 4: Choose your path (Select Booker, Talent OR Agent)
    Step - 5: Create profile form
*/

const emailValidationSchema = Yup.object({
    email: Yup.string()
        .email("Enter a valid email")
        .max(100)
        .required("Email is required")
});

const bookerValidationSchema = Yup.object({
    email: Yup.string(),
    fname: Yup.string()
        .required("First Name is required")
        .trim()
        .max(35, "you exceeds the limit of allowed letters"),
    lname: Yup.string()
        .required("Last Name is required")
        .trim()
        .max(35, "you exceeds the limit of allowed letters"),
    phone_number: Yup.string().matches(/^[0-9]{10}$/, {
        message: "Phone number is not valid",
    }),
    phone_number1: Yup.string()
        .required("phone1 is required")
        .matches(/^[0-9]{3}$/, { message: "Phone number is not valid" }),
    phone_number2: Yup.string()
        .required("phone2 is required")
        .matches(/^[0-9]{3}$/, { message: "Phone number is not valid" }),
    phone_number3: Yup.string()
        .required("phone3 is required")
        .matches(/^[0-9]{4}$/, { message: "Phone number is not valid" }),
    password: Yup.string()
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,32})/,
            `Minimum eight characters,
    		at least one uppercase letter,
    		one lowercase letter, one number and one special character`,
        )
        .required("Enter your password"),
    confirmPassword: Yup.string()
        .required("Confirm your password")
        .oneOf([Yup.ref("password")], "Password does not match"),
    role: Yup.string(),
    not_affiliated_organization: Yup.boolean(),
    organization_name: Yup.string().when('not_affiliated_organization', {
        is: false,
        then: Yup.string().required("Organization or Business Name is required")
            .trim()
            .max(65, "you exceeds the limit of allowed letters")
    }),
    promo_code: Yup.string()
        .matches(
            /^(?=.*[A-Za-z0-9])(?=.{8,8})/,
            `Minimum eight characters`,
        )
        .trim()
});

const talentValidationSchema = Yup.object({
    email: Yup.string(),
    username: Yup.string()
        .required("Username is required")
        .matches(/^\S*$/, "no spaces are allowed")
        .max(65, "you exceeds the limit of allowed letters"),
    fname: Yup.string()
        .required("First Name is required")
        .trim()
        .max(35, "you exceeds the limit of allowed letters"),
    lname: Yup.string()
        .required("Last Name is required")
        .trim()
        .max(35, "you exceeds the limit of allowed letters"),
    phone_number: Yup.string().matches(/^[0-9]{10}$/, {
        message: "Phone number is not valid",
    }),
    phone_number1: Yup.string()
        .required("phone1 is required")
        .matches(/^[0-9]{3}$/, { message: "Phone number is not valid" }),
    phone_number2: Yup.string()
        .required("phone2 is required")
        .matches(/^[0-9]{3}$/, { message: "Phone number is not valid" }),
    phone_number3: Yup.string()
        .required("phone3 is required")
        .matches(/^[0-9]{4}$/, { message: "Phone number is not valid" }),
    password: Yup.string()
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,32})/,
            `Minimum eight characters,
        at least one uppercase letter,
        one lowercase letter, one number and one special character`,
        )
        .required("Enter your password"),
    confirmPassword: Yup.string()
        .required("Confirm your password")
        .oneOf([Yup.ref("password")], "Password does not match"),
    role: Yup.string(),
    promo_code: Yup.string()
        .matches(
            /^(?=.*[A-Za-z0-9])(?=.{8,8})/,
            `Minimum eight characters`,
        )
        .trim()
});

const agentValidationSchema = Yup.object({
    email: Yup.string(),
    fname: Yup.string()
        .required("First Name is required")
        .trim()
        .max(35, "you exceeds the limit of allowed letters"),
    lname: Yup.string()
        .required("Last Name is required")
        .trim()
        .max(35, "you exceeds the limit of allowed letters"),
    phone_number: Yup.string().matches(/^[0-9]{10}$/, {
        message: "Phone number is not valid",
    }),
    phone_number1: Yup.string()
        .required("phone1 is required")
        .matches(/^[0-9]{3}$/, { message: "Phone number is not valid" }),
    phone_number2: Yup.string()
        .required("phone2 is required")
        .matches(/^[0-9]{3}$/, { message: "Phone number is not valid" }),
    phone_number3: Yup.string()
        .required("phone3 is required")
        .matches(/^[0-9]{4}$/, { message: "Phone number is not valid" }),
    password: Yup.string()
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,32})/,
            `Minimum eight characters,
        at least one uppercase letter,
        one lowercase letter, one number and one special character`,
        )
        .required("Enter your password"),
    confirmPassword: Yup.string()
        .required("Confirm your password")
        .oneOf([Yup.ref("password")], "Password does not match"),
    role: Yup.string(),
    promo_code: Yup.string()
        .matches(
            /^(?=.*[A-Za-z0-9])(?=.{8,8})/,
            `Minimum eight characters`,
        )
        .trim()
});


///^[ A-Za-z0-9_@./#&+-]*$/
export const SignupDialog: React.FC<ISignupDialogProps> = ({
    handleClose,
    opened,
    handleOpenLogin,
    handleLoginSubmit
}) => {
    const location = useLocation();
    const [selectedRole, setSelectedRole] = useState("");
    const [selectedSignupStep, setSelectedSignupStep] = useState(location?.pathname == '/confirm-account' ? 0 : 1);
    const [addedFormValues, setaddedFormValues] = useState<any>();
    const [isSubmitLoading, setIsSubmitLoading] = useState(false);

    const [serverError, setServerError] = useState<any>();
    const [confirmedEmail, setConfirmedEmail] = useState<string>("");
    const history = useHistory()

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
    const values = {
        email: "",
        username: "",
        fname: "",
        lname: "",
        password: "",
        confirmPassword: "",
        role: "",
        promo_code: "",
        phone_number: "",
        phone_number1: "",
        phone_number2: "",
        phone_number3: "",
        organization_name: "",
        not_affiliated_organization: false
    };
    //const [values, setValues ] = useState<any>({dataValues})

    useEffect(() => {
    }, [selectedSignupStep, serverError])

    useEffect(() => {
        if (location?.pathname == '/confirm-account') {
            confirmAccountHandle();
        }
    }, [])

    const confirmAccountHandle = async () => {
        if (selectedSignupStep !== 4) {
            const { search } = location;
            const params = new URLSearchParams(search);
            const email = params.get("email") || "";
            const confirmationToken = params.get("confirmation_token") || "";
            /*params.delete("email");
            params.delete("confirmation_token");
            history.replace({
                search: params.toString(),
            })*/
            if (email && confirmationToken) {
                confirmV2(email, confirmationToken).then(
                    (response) => {
                        if (response?.status == 'SUCCESS') {
                            setConfirmedEmail(email)
                            setSelectedSignupStep(4);
                        }
                    },
                    (error) => {
                        setServerError(error);
                        setSelectedSignupStep(1)
                        console.log('error: ', error);
                    },
                );
            }
            else {
                setSelectedSignupStep(1);
            }
        }
    }

    const handleSignupSubmit = async (formValues: any) => {
        setServerError(null);
        setaddedFormValues(formValues)
        if (selectedSignupStep == 1 || selectedSignupStep == 3) {
            setIsSubmitLoading(true);
            registerV2(formValues).then(
                (response) => {
                    setIsSubmitLoading(false);
                    if (response?.status == 'SUCCESS' && response?.server_message == "ACCOUNT_CREATED") {
                        setSelectedSignupStep(2)
                    }
                },
                (error) => {
                    setIsSubmitLoading(false);
                    setServerError(error);
                    console.log('error: ', error);
                },
            );
        }
        else if (selectedSignupStep == 5) {
            setIsSubmitLoading(true);
            if (selectedRole == "BOOKER" || selectedRole == "AGENT") {
                formValues.username = formValues.email;
            }
            CreateProfileV2(formValues).then(
                (response) => {
                    setIsSubmitLoading(false);
                    if (response?.status == 'SUCCESS' && response?.server_message == "ACCOUNT_CREATED") {

                        handleLoginSubmit(formValues.email,
                            formValues.password,
                            () => {},
                        );

                        // login(formValues?.email, formValues?.password).then(
                        //     (loginResponse) => {
                        //         if (loginResponse?.status == 'SUCCESS') {

                        //         }
                        //     },
                        //     (loginError) => {
                        //         setServerError(loginError);
                        //         console.log('loginError: ', loginError);
                        //     },
                        // )

                    }
                },
                (error) => {
                    setIsSubmitLoading(false);
                    setServerError(error);
                    console.log('error: ', error);
                },
            );

        }
    }

    const getDialogTitle = () => {

        if (selectedSignupStep == 1) {
            return <Grid item>
                <Typography variant="h4" color="textPrimary" style={{ paddingBottom: "10px", width: "90%", marginLeft: 'auto', marginRight: 'auto', textAlign: "center" }}>ENTER YOUR EMAIL ADDRESS TO GET STARTED!</Typography>
                <Typography variant="body1" color="textPrimary" style={{ paddingBottom: "10px" }}>
                    We’ll send you an email with a verification link to confirm it’s you.
                </Typography>
            </Grid>
        }
        else if (selectedSignupStep == 2) {
            return <Grid item>
                <Typography variant="h4" color="textPrimary" style={{ paddingBottom: "25px", width: "90%", marginLeft: 'auto', marginRight: 'auto', textAlign: "center" }}>CHECK YOUR EMAIL</Typography>
                <Typography variant="body1" color="textPrimary" style={{ paddingBottom: "5px" }}>
                    We have sent a message to
                </Typography>
                <Typography variant="body1" color="textPrimary" style={{ fontWeight: "bold", paddingBottom: "5px" }}>
                    {addedFormValues?.email}
                </Typography>
                <Typography variant="body1" color="textPrimary" style={{ paddingBottom: "25px" }}>
                    with a link to verify your account.
                </Typography>
                <Typography variant="body1" color="textPrimary">
                    Didn’t get an email? Check your spam folder.
                    <Link
                        component="button"
                        style={{
                            color: "white",
                            fontWeight: "initial",
                            fontSize: "initial",
                        }}
                        onClick={() => { setSelectedSignupStep(3) }}>
                        Re-enter your email and try again.
                    </Link>
                </Typography>
            </Grid>
        }
        else if (selectedSignupStep == 3) {
            return <Grid item>
                <Typography variant="h4" color="textPrimary" style={{ paddingBottom: "10px", width: "90%", marginLeft: 'auto', marginRight: 'auto', textAlign: "center" }}>RESEND VERIFICATION EMAIL</Typography>
                <Typography variant="body1" color="textPrimary" style={{ paddingBottom: "10px" }}>
                    We sent you a message with a link to activate your account. If you didn’t recieve it, you can re-enter your email address or try another address, and we’ll send you a new verification link.
                </Typography>
            </Grid>
        }
        else if (selectedSignupStep == 4) {
            return <Grid item>
                <Typography variant="h4" color="textPrimary" style={{ paddingBottom: "10px", width: "90%", marginLeft: 'auto', marginRight: 'auto', textAlign: "center", textTransform: "uppercase" }}>Your Email Has Been Verified!</Typography>
                <Typography variant="body1" color="textPrimary">
                    Let's get started! Choose the type of profile you want to create.
                </Typography>
            </Grid>
        }
        else if (selectedSignupStep == 5) {
            if (selectedRole == "BOOKER") {
                return <Grid item>
                    <Typography variant="h4" color="textPrimary" style={{ paddingBottom: "10px", width: "90%", marginLeft: 'auto', marginRight: 'auto', textAlign: "center" }}>CREATE YOUR BOOKER PROFILE</Typography>
                    <Typography variant="body1" color="textPrimary" style={{ paddingBottom: "10px" }}>
                        Great to have you join the crew! Tell us about yourself
                    </Typography>
                    <Typography variant="body1" color="textPrimary" style={{ paddingBottom: "10px" }}>
                        to create your profile.
                    </Typography>
                </Grid>
            }
            else if (selectedRole == "TALENT") {
                return <Grid item>
                    <Typography variant="h4" color="textPrimary" style={{ paddingBottom: "10px", width: "90%", marginLeft: 'auto', marginRight: 'auto', textAlign: "center" }}>CREATE YOUR TALENT PROFILE</Typography>
                    <Typography variant="body1" color="textPrimary" style={{ paddingBottom: "10px" }}>
                        Great to have you join the crew! Tell us about yourself
                    </Typography>
                    <Typography variant="body1" color="textPrimary" style={{ paddingBottom: "10px" }}>
                        to create your profile.
                    </Typography>
                </Grid>
            }
            else if (selectedRole == "AGENT") {
                return <Grid item>
                    <Typography variant="h4" color="textPrimary" style={{ paddingBottom: "10px", width: "90%", marginLeft: 'auto', marginRight: 'auto', textAlign: "center" }}>CREATE YOUR AGENT PROFILE</Typography>
                    <Typography variant="body1" color="textPrimary" style={{ paddingBottom: "10px" }}>
                        Great to have you join the crew! Tell us about yourself
                    </Typography>
                    <Typography variant="body1" color="textPrimary" style={{ paddingBottom: "10px" }}>
                        to create your profile.
                    </Typography>
                </Grid>
            }

        }

    }

    const getDialogBottomText = () => {

        if (selectedSignupStep == 1) {
            return <Grid item>
                <Typography variant="body1" color="textPrimary" style={{ paddingBottom: "10px" }}>
                    Already have an account? Log in{" "}
                    <Link
                        component="button"
                        style={{
                            color: "white",
                            fontWeight: "initial",
                            fontSize: "initial",
                        }}
                        onClick={handleOpenLogin}>
                         here.
                    </Link>
                </Typography>
            </Grid>
        }
        else if (selectedSignupStep == 4) {
            return <Grid item>
                <Typography variant="body2">
                    By registering, I accept the {` `}
                    <Link
                        target="_blank"
                        href="/terms-conditions"
                        type="button"
                        style={{
                            color: "white",
                            fontSize: "12px",
                            fontWeight: "initial",
                            textDecoration: "underline",
                        }}>
                        Terms of Service
                    </Link>
                    {` and `}
                    <Link
                        target="_blank"
                        href="/privacy"
                        type="button"
                        style={{
                            color: "white",
                            fontSize: "12px",
                            fontWeight: "initial",
                            textDecoration: "underline",
                        }}>
                        Privacy of Policy
                    </Link>
                </Typography>
            </Grid>
        }
        else if (selectedSignupStep == 5) {
            return <Grid item>
                <Typography variant="body2">
                    By registering, I accept the {` `}
                    <Link
                        target="_blank"
                        href="/terms-conditions"
                        type="button"
                        style={{
                            color: "white",
                            fontSize: "12px",
                            fontWeight: "initial",
                            textDecoration: "underline",
                        }}>
                        Terms of Service
                    </Link>
                    {` and `}
                    <Link
                        target="_blank"
                        href="/privacy"
                        type="button"
                        style={{
                            color: "white",
                            fontSize: "12px",
                            fontWeight: "initial",
                            textDecoration: "underline",
                        }}>
                        Privacy of Policy
                    </Link>
                </Typography>
            </Grid>
        }

    }

    const handleDialogClose = () => {
        handleClose();
        setServerError(null);
        setSelectedSignupStep(1);
        setSelectedRole("");
        if (location?.pathname == '/confirm-account') {
            history.push('/')
        }
    }
    if (selectedSignupStep == 0) {
        return (<></>)
    }

    return (
        <Dialog
            disableEscapeKeyDown={true}
            disableBackdropClick={true}
            fullScreen={fullScreen}
            open={opened}
            scroll="body"
            onClose={() => {
                handleDialogClose();
                setTimeout(() => {
                    setSelectedRole("");
                }, 500);

            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <CustomDialogTitle
                onClose={() => {
                    handleDialogClose();
                    setTimeout(() => {
                        setSelectedRole("");
                    }, 500);
                }} id="alert-dialog-title">

                {getDialogTitle()}


            </CustomDialogTitle>
            {selectedSignupStep != 2 &&
                <Divider variant="middle" />
            }
            <DialogContent>
                <div>
                    <Formik
                        render={(props) => <Form {...props}
                            handleDialogClose={handleDialogClose}
                            handleOpenLogin={handleOpenLogin}
                            fullScreen={fullScreen}
                            setSelectedRole={setSelectedRole}
                            setSelectedSignupStep={setSelectedSignupStep}
                            selectedSignupStep={selectedSignupStep}
                            serverError={serverError}
                            confirmedEmail={confirmedEmail}
                            isSubmitLoading={isSubmitLoading}
                        />}
                        initialValues={{ ...values, email: confirmedEmail }}
                        validationSchema={
                            selectedRole == "BOOKER" ? bookerValidationSchema : selectedRole == "TALENT" ? talentValidationSchema : selectedRole == "AGENT" ? agentValidationSchema : emailValidationSchema
                        }
                        onSubmit={(formValues, actions) => {
                            formValues.phone_number = formValues.phone_number1.concat(
                                formValues.phone_number2,
                                formValues.phone_number3,
                            );
                            handleSignupSubmit(formValues);
                            actions.setSubmitting(true);
                        }}
                    />
                    <Grid container direction="column" justify="center" alignItems="center">
                        {getDialogBottomText()}
                    </Grid>

                    {/* <Grid container direction="column" justify="center" alignItems="center">
                        <Link component="button"
                            onClick={() => { setSelectedSignupStep(2) }}>
                            Step - 2
                        </Link>
                        <Link component="button"
                            onClick={() => { setSelectedSignupStep(3) }}>
                            Step - 3
                        </Link>
                        <Link component="button"
                            onClick={() => { setSelectedSignupStep(4) }}>
                            Step - 4
                        </Link>
                    </Grid> */}
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default SignupDialog;