import React from "react";
import { EventRequestStatus, IEventRequestListItemProps } from "./interfaces";
import {
	Button,
	Grid,
	withStyles,
	CircularProgress,
} from "@material-ui/core";
import { Genre } from "../../interfaces";
import { styles } from "./styles";
import BookerRequestDialog from "../BookerRequestDialog/booker-request-dialog";
import ConfirmationDialog from "../ConfirmationDialog/confirmation-dialog";
import { IsBooker, IsTalent } from "../../AuthBase";
import ConfirmBooking from "./RequestActions/confirm-booking";
import ApproveRequest from "./RequestActions/approve-request";
import RejectRequest from "./RequestActions/reject-request";

class EventRequestListItem extends React.Component<IEventRequestListItemProps> {
	getStatus(status: EventRequestStatus) {
		switch (status) {
			case EventRequestStatus.PENDING:
				return {
					text: "In review with talent",
					helperText: "No Action Required.",
				};
			case EventRequestStatus.APPROVED_BY_TALENT:
				return {
					text: "Approved by Talent",
					helperText: "Please confirm your booking",
				};
			case EventRequestStatus.REJECTED_BY_TALENT:
				return {
					text: "Rejected by Talent",
					helperText: "This request has been rejected",
				};
			case EventRequestStatus.PENDING_DEPOSIT:
				return {
					text: "Ready for Event Deposit",
					helperText: "Please pay Event Deposit",
				};
			case EventRequestStatus.PENDING_REMAINDER:
				return {
					text: "Ready for Final Payment",
					helperText: "Please pay Event Deposit",
				};
			case EventRequestStatus.CONFIRMED:
				return {
					text: "Confirmed",
					helperText: "Confirmed helper text",
				};
			case EventRequestStatus.CANCELED:
				return {
					text: "Canceled",
					helperText: "This request has been canceled",
				};
			default:
				return {
					text: "",
					helperText: "",
				};
		}
	}

	render() {
		const {
			classes,
			EventRequestItem,
			getItemFullObject,
			closeUpdatedModal,
			updateEventRequest,
			openCancelModal,
			closeCancelModal,
			cancelEventRequest,
		} = this.props;
		const eventRequestStatus = this.getStatus(EventRequestItem.status);
		return (
			<Grid
				container
				direction="row"
				className={classes.root}
				spacing={2}
			>
				<Grid
					container
					direction="row"
					className={classes.root}
					spacing={2}
				>
					<Grid item xs={5}>
						<Grid item={true} xs={12}>
							{EventRequestItem.title}
						</Grid>
						<Grid item={true} xs={12}>
							{EventRequestItem.date}
						</Grid>
						<Grid item={true} xs={12}>
							{EventRequestItem.currency}
							{EventRequestItem.budget}
						</Grid>
						<Grid item={true} xs={12}>
							{EventRequestItem.start_time.split(" ")[1]}{" "}
						</Grid>
						<Grid item={true} xs={12}>
							{Number(
								EventRequestItem.end_time
									.split(" ")[1]
									.split(":")[0],
							) -
								Number(
									EventRequestItem.start_time
										.split(" ")[1]
										.split(":")[0],
								)}{" "}
							Hours
						</Grid>
						{EventRequestItem.genres.map((genre: Genre) => (
							<Grid key={genre.id} item={true} xs={12}>
								{genre.title}
							</Grid>
						))}
					</Grid>
					<IsBooker>
						<Grid item xs={3}>
							<Grid container direction="column" spacing={2}>
								<Grid item xs={12}>
									<Button
										variant="outlined"
										onClick={() =>
											getItemFullObject(
												EventRequestItem.id,
											)
										}
									>
										Update Request
									</Button>
									<BookerRequestDialog
										bandId={EventRequestItem.id || 0}
										opened={
											(EventRequestItem.itemState &&
												EventRequestItem.itemState
													.updatedModalOpened) ||
											false
										}
										handleClose={() =>
											closeUpdatedModal(
												EventRequestItem.id,
											)
										}
										showLoading={
											(EventRequestItem.itemState &&
												EventRequestItem.itemState
													.getFullItemIsRequested) ||
											false
										}
										titleForm="Update This Item"
										setSelectedEventItem={() => null}
										handleBookerRequestDialogSubmit={
											updateEventRequest
										}
										values={
											EventRequestItem.itemState &&
											EventRequestItem.itemState.fullItem
										}
										error={
											(EventRequestItem.itemState &&
												EventRequestItem.itemState
													.fullItemError) ||
											(EventRequestItem.itemState &&
												EventRequestItem.itemState
													.error)
										}
									/>
								</Grid>
								<Grid item xs={12}>
									{EventRequestItem.itemState &&
										!EventRequestItem.itemState.cancel
											.cancelIsSucceeded && (
											<div className={classes.wrapper}>
												<Button
													variant="outlined"
													color="primary"
													className={
														classes.buttonClassname
													}
													disabled={
														EventRequestItem.itemState &&
														EventRequestItem
															.itemState.cancel
															.cancelIsRequested
													}
													onClick={() =>
														openCancelModal(
															EventRequestItem.id,
														)
													}
												>
													Cancel Request
												</Button>
												{EventRequestItem.itemState &&
													EventRequestItem.itemState
														.cancel
														.cancelIsRequested && (
														<CircularProgress
															size={24}
															className={
																classes.buttonProgress
															}
														/>
													)}
											</div>
										)}
									{EventRequestItem.itemState &&
										EventRequestItem.itemState.cancel
											.cancelError && (
											<span>
												{
													EventRequestItem.itemState
														.cancel.cancelError
														.response.message
												}
											</span>
										)}
									{EventRequestItem.itemState &&
										EventRequestItem.itemState.cancel
											.cancelIsSucceeded && (
											<Button variant="outlined" disabled>
												Cancel Succeeded
											</Button>
										)}
									<ConfirmationDialog
										opened={
											EventRequestItem.itemState &&
											EventRequestItem.itemState.cancel
												.cancelModalOpened
										}
										handleOk={() =>
											cancelEventRequest(
												EventRequestItem.id,
											)
										}
										handleCancel={() =>
											closeCancelModal(
												EventRequestItem.id,
											)
										}
										title="Are you Sure"
									/>
								</Grid>
							</Grid>
						</Grid>
					</IsBooker>
				</Grid>
				<Grid container direction="column">
					<Grid item xs={3}>
						<Grid container direction="column" spacing={2}>
							<Grid item>
								<strong>Status: </strong>
								{eventRequestStatus.text}
							</Grid>
							<Grid item>{eventRequestStatus.helperText}</Grid>
						</Grid>
					</Grid>

					<IsBooker>
						<Grid item xs={6}>
							{EventRequestItem.status.includes("CANCELED") && (
								<ConfirmBooking
									opened={
										EventRequestItem.itemState.confirm
											.confirmModalOpened
									}
									requestId={EventRequestItem.id || 0}
									showProgress={
										EventRequestItem.itemState.confirm
											.confirmIsRequested
									}
									disabled={
										EventRequestItem.itemState.confirm
											.confirmIsSucceeded
									}
								/>
							)}
							{EventRequestItem.itemState.confirm
								.confirmError && (
								<span>
									{
										EventRequestItem.itemState.confirm
											.confirmError.response.message
									}
								</span>
							)}
						</Grid>
					</IsBooker>

					<IsTalent>
						<Grid container>
							<Grid item>
								{EventRequestItem.status.includes(
									"CANCELED",
								) && (
									<ApproveRequest
										opened={
											EventRequestItem.itemState.approve
												.approveModalOpened
										}
										requestId={EventRequestItem.id || 0}
										showProgress={
											EventRequestItem.itemState.approve
												.approveIsRequested
										}
										disabled={
											EventRequestItem.itemState.approve
												.approveIsSucceeded
										}
									/>
								)}
								{EventRequestItem.itemState.approve
									.approveError && (
									<span>
										{
											EventRequestItem.itemState.approve
												.approveError.response.message
										}
									</span>
								)}
							</Grid>
							<Grid item>
								{EventRequestItem.status.includes(
									"CANCELED",
								) && (
									<RejectRequest
										opened={
											EventRequestItem.itemState.reject
												.rejectModalOpened
										}
										requestId={EventRequestItem.id || 0}
										showProgress={
											EventRequestItem.itemState.reject
												.rejectIsRequested
										}
										disabled={
											EventRequestItem.itemState.reject
												.rejectIsSucceeded
										}
									/>
								)}
								{EventRequestItem.itemState.reject
									.rejectError && (
									<span>
										{
											EventRequestItem.itemState.reject
												.rejectError.response.message
										}
									</span>
								)}
							</Grid>
						</Grid>
					</IsTalent>
				</Grid>
			</Grid>
		);
	}
}

export default withStyles(styles)(EventRequestListItem);
