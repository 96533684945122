import React, { useEffect, useState } from "react";
import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";
import { IBand, Genre } from "../interfaces";
import Rating from "../generic/Rating";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import BookmarkSharpIcon from "@material-ui/icons/BookmarkSharp";
import BookmarkBorderSharpIcon from "@material-ui/icons/BookmarkBorderSharp";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import ImagePlaceholder from "../Assets/ImagePlaceholder.png";
import { Link } from "react-router-dom";
import { IAuthReducer } from "../AuthBase/interfaces";
import Button from "@material-ui/core/Button"
import { ISearchBandsSlice } from "./interfaces";
import { Dialog, Grid, Icon, IconButton, OutlinedInput } from "@material-ui/core";
import ErrorMessage from "../generic/ErrorMessage";
//import FilterBar from "../SearchPage/FilterBar";
import { CustomDialogTitle } from "../generic/components";
import { MessageDialog } from "../generic/components/MessageDialog/message-dialog";
import FilterBar from "./FilterBar";
import Services from "../generic/Services";
import { StoreMallDirectory } from "@material-ui/icons";

interface BandCardProps {
	authentication: IAuthReducer;
	favoriteToggle: (bandId: number, isFavorite: boolean) => void;
	changeBandStatus: (bandId: number, request_status: string) => void;
	isFavouritePage: boolean;
	BookArtist?: () => void;
	searchBands: ISearchBandsSlice;
	opened: boolean;
	closeSearchDialog: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		backLink: {
			top: "15px",
			left: "20px",
			position: "absolute",
			textDecoration: "none",
			fontSize: "15px",
			"& button": {
				color: "#ffffff",
				textDecoration: "none",
				backgroundColor: "transparent",
				fontWeight: "500",
				padding: "0px",
				fontSize: "15px",
				"&:hover": {
					color: "#5BFFCB",
					backgroundColor: "transparent",
				}
			}
		},
		favoriteIcon: {
			position: "absolute",
			top: "-20px",
			right: "8px",
			cursor: "pointer",
		},
		h4: {
			fontSize: "2.625rem",
		},
		"& h4": {
			fontSize: "2.625rem",
		},
		banditem: {
			border: "2px solid #ffffff",
			borderRadius: "8px",
			banditemimg: {
				height: "auto",
			},
			cardContent: {
				padding: "0px 16px 16px 16px",
			},
			"& h4": {
				fontSize: "1.6rem",
			},
			"& a": {
				display: "flex",
				alignItems: "center",
				color: "#5BFFCB",
				backgroundColor: "transparent",
				padding: "7px 10px 5px 10px",
				fontSize: "12px",
				fontFamily: "Rubik",
				fontWeight: "bold",
				lineHeight: "normal",
				borderRadius: "4px",
				border: "1px solid #5BFFCB",
				textDecoration: "none",
				width: "100%",
				textAlign: "center",
				justifyContent: "center",
				"&:hover": {
					backgroundColor: "#5BFFCB",
					color: "#1023FC",
				},
			},
			"& button": {
				display: "flex",
				alignItems: "center",
				color: "#1023FC",
				backgroundColor: "#5BFFCB",
				padding: "7px 10px 5px 10px",
				fontSize: "12px",
				fontFamily: "Rubik",
				fontWeight: "bold",
				lineHeight: "normal",
				borderRadius: "4px",
				border: "1px solid #5BFFCB",
				textDecoration: "none",
				width: "100%",
				textAlign: "center",
				justifyContent: "center",
				"&:hover": {
					color: "#5BFFCB",
					backgroundColor: "transparent",
				},
			},
			InviteBtn: {
				display: "flex",
				alignItems: "center",
				color: "#1023FC",
				backgroundColor: "#5BFFCB",
				padding: "7px 10px 5px 10px",
				fontSize: "12px",
				fontFamily: "Rubik",
				fontWeight: "bold",
				lineHeight: "normal",
				borderRadius: "4px",
				border: "1px solid #5BFFCB",
				textDecoration: "none",
				width: "100%",
				textAlign: "center",
				justifyContent: "center",
				marginBottom: "8px",
				"&:hover": {
					color: "#5BFFCB",
					backgroundColor: "transparent",
				},
			},
			largebtn: {
				"& button": {
					width: "120px",
				}
			}
		},
	}),
);

export const BandCard: React.FC<BandCardProps> = ({
	authentication,
	favoriteToggle,
	changeBandStatus,
	isFavouritePage,
	BookArtist,
	searchBands,
	opened,
	closeSearchDialog
}) => {
	
	const classes = useStyles();
	const [showInviteBand, setShowInviteBand] = useState(false);
	const [showSentInviteBand, setShowSentInviteBand] = useState(false);
	const [showSearchBand, setshowSearchBand] = useState(true);
	const [showMessageDialog, setshowMessageDialog] = useState(false);
	const [inviteEmail, setInviteEmail] = useState('');
	const [showEmailError, setshowEmailError] = useState('');
	const [showEmailExistMessage, setshowEmailExistMessage] = useState('');
	
	useEffect(() => {
	}, [showMessageDialog, searchBands]);

	const requestBand = (band: any) => {

		changeBandStatus(band.id, 'SENT_BY_AGENT')
		if(band.agent_band_status){
			band.agent_band_status.request_status = 'SENT_BY_AGENT';
		}
		else
		{
			band.agent_band_status = {};
			band.agent_band_status.request_status = 'SENT_BY_AGENT';
		}
		setShowInviteBand(false);
		setShowSentInviteBand(false);
		setshowSearchBand(false);
		setshowMessageDialog(true);

		//setshowMessageDialog(true);
		//return false;
		 let bandId = band.id;
		Services.agent.agentBandRequest(bandId)
			.then((res: any) => {
				// if(band.agent_band_status){
				// 	band.agent_band_status.request_status = 'SENT_BY_AGENT';
				// }
				// else
				// {
				// 	band.agent_band_status = {};
				// 	band.agent_band_status.request_status = 'SENT_BY_AGENT';
				// }	
				// setShowInviteBand(false);
				// setShowSentInviteBand(false);
				// setshowSearchBand(false);
				// setshowMessageDialog(true);
				//closeSearchDialog();
			})
			.catch((err) => console.log(err));
		
	}

	const closeMessageDialog = () => {
		closeSearchDialog();
		setshowMessageDialog(false);
		setshowSearchBand(true);
		
	}
	
	const sendEmailInvitationToBand = () => {
		setshowEmailError('')
		setshowEmailExistMessage('');
		if(validEmail(inviteEmail))
		{
		Services.agent.sendInvitationToBand(inviteEmail)
					.then((res: any) => {
						console.log('res: ', res)
						setShowInviteBand(false);
						setShowSentInviteBand(true);
					})
					.catch((err) => {
						console.log(err)
						if(err.code === 400)
						{
							setshowEmailExistMessage(err.message)
						}
					});
		}
		else
		{
			setshowEmailError('Invalid Email!')
		}
		
	}

	const validEmail = (e: string) => {
		var filter = /^\s*[\w\-\+_]+(\.[\w\-\+_]+)*\@[\w\-\+_]+\.[\w\-\+_]+(\.[\w\-\+_]+)*\s*$/;
		return String(e).search (filter) != -1;
	}
	

	return (
		<>
		<Dialog
			fullWidth={true}
			maxWidth="md"
			open={opened}
			scroll="body"
			onClose={() => {
				closeSearchDialog()
				setShowInviteBand(false);
				setShowSentInviteBand(false);
				setshowSearchBand(true);
			}}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
		{showSearchBand && <div>
			<CustomDialogTitle
				onClose={() => {
					closeSearchDialog()
					setShowInviteBand(false);
					setShowSentInviteBand(false);
					setshowSearchBand(true);
					
				}}
				id="alert-dialog-title"
			>
				<Typography variant="h4" className={classes.popuptitlemain}>
					SEARCH FOR A BAND
					</Typography>
			</CustomDialogTitle>
			<Box display="flex" flexWrap="wrap" justifyContent="center" py={4} px={5}>
				{/* {alert(navigator.userAgent)} */}
				<FilterBar />
			</Box>

			<Box display="flex" flexWrap="wrap" justifyContent="center" py={3} px={4}>
			<Grid container direction="row" justify="center" alignItems="center" spacing={3}>
				{searchBands.bands &&
					searchBands.bands.bands &&
					searchBands.bands.bands.map((band: any) => (
						<Grid item xs={12} sm={5} md={4} key={band.id}>
							<Card square={true} className={classes.banditem}>
								<CardMedia image={band.photo === null ? ImagePlaceholder : band.photo.thumbnail} title={band.name} className={classes.banditemimg}>
									{authentication.isLoggedIn &&
										authentication.user.role === "BOOKER" &&
										(band.isFavorite ? (
											<BookmarkSharpIcon
												color="secondary"
												style={{ fontSize: 60 }}
												className={classes.favoriteIcon}
												onClick={() => favoriteToggle(band.id, band.isFavorite)}
											/>
										) : (
											<BookmarkBorderSharpIcon
												color="secondary"
												style={{ fontSize: 60 }}
												className={classes.favoriteIcon}
												onClick={() => favoriteToggle(band.id, band.isFavorite)}
											/>
										))}
								</CardMedia>
								<Box borderTop={0}>
									<CardContent classes={{ root: classes.cardContent }}>
										<Typography variant="h4">{band.name}</Typography>
										<Typography component="p" gutterBottom variant="subtitle1">
											{band.genres.map((genre: Genre) => genre.title).join(", ")}
										</Typography>
									</CardContent>
									{authentication.isLoggedIn && authentication.user.status === "APPROVED" && authentication.user.role === "BOOKER" && (
										<Typography component="p" variant="h6" style={{ padding: "15px 15px 0px 15px", fontSize: "2.7em" }}>
											{band.price_show ? `$${band.price_from}-${band.price_to} ` : ''}
										</Typography>
									)}
									{authentication.isLoggedIn && authentication.user.status === "APPROVED" && authentication.user.role === "TALENT" && band.created_by.id === authentication.user.id && (
										<Typography component="p" variant="h6" style={{ padding: "15px 15px 0px 15px", fontSize: "2.7em" }}>
											${band.price_from}-${band.price_to}
										</Typography>
									)}
									<CardActions className={classes.Link}>
										<Box display="flex" flexWrap="wrap" justifyContent="space-between" alignItems="center" py={2} px={1} width="100%">
											<Box alignSelf="center" width="48%">
												{/* <Link to={`/bands/${band.id}`} className={classes.Link}>View Artist</Link> */}
												<Link to={`/${band?.created_by?.username}`} className={classes.Link}>View Artist</Link>
											</Box>

											<Box alignSelf="center" width="48%">
												{band.agent_band_status && (band.agent_band_status.request_status == 'ACCEPTED_BY_AGENT' || band.agent_band_status.request_status == 'ACCEPTED_BY_TALENT') ? (
													<i color="secondary">
														Artist Represented
													</i>
												) : (<>{band.agent_band_status && band.agent_band_status.request_status == 'SENT_BY_AGENT' ? (
													<>
														<i color="secondary">Request Sent</i>
													</>
												) : (<>{band.agent_band_status && band.agent_band_status.request_status == 'SENT_BY_TALENT' ? (
													<>
														<i color="secondary">
															Request Received
														</i>
													</>
												) : (<>{(
													<Button onClick={() => {
														requestBand(band);
													}} className={classes.Link}>
														Request Artist
													</Button>
												)}	</>)
												}</>)
												}
												</>)
												}

											</Box>
										</Box>
									</CardActions>
								</Box>
							</Card>
						</Grid>
					))}
					<Grid item xs={12} md={12} lg={12} justify="center" alignItems="center">
						<Typography component="p" align="center"><i><small>Showing {searchBands.bands.bands?.length} of {JSON.stringify(searchBands.count)}</small></i></Typography>
						<Typography component="p" align="center"><i><small>Please enter the exact band name.</small></i></Typography>
					{!searchBands.isBandsRequested && searchBands.count === 0 && (
						<Typography component="h6" align="center"><Box py={2}>No Search Results</Box></Typography>
					)}
					{searchBands.error.code && (
						<Box py={3}><ErrorMessage error={searchBands.error} /></Box>
					)}
			
					<Typography component="h6" variant="subtitle1" align="center"><Box py={2}><strong>Can't find your band? Invite them to create a profile with Repsy!</strong></Box></Typography>
					<Typography align="center" className={classes.InviteBtn}>
						<Box py={3} className={classes.largebtn}>
							<Button onClick={() => {
								setShowInviteBand(true);
								setShowSentInviteBand(false);
								setshowSearchBand(false);
							}} variant="contained" size="large" color="secondary" style={{ width: 120 }}>
								Invite
							</Button>
						</Box>
					</Typography>
				</Grid>
			</Grid>
		</Box>
		</div>}
		{showInviteBand && <div>
			<Typography className={classes.backLink}>
				<Button onClick={() => {
					setShowInviteBand(false);
					setShowSentInviteBand(false);
					setshowSearchBand(true);
				}}>
					Back to Bands
				</Button></Typography>
				<CustomDialogTitle
					onClose={() => {
						
						closeSearchDialog();
						setShowInviteBand(false);
						setShowSentInviteBand(false);
						setTimeout(() => {
							setshowSearchBand(true);	
						}, 500);
						
					}}
					id="alert-dialog-title"
				>
					<Typography variant="h4">INVITE A BAND</Typography>
					
				</CustomDialogTitle>
				
				
				<Grid container direction="row" justify="center" alignItems="center">
					<Grid item xs={12} md={12} lg={11} alignItems="center">
						<Typography component="h6" align="center">Can't find your band? Invite them to create a profile with Repsy!</Typography>
					</Grid>
				</Grid>
				
				<Box display="flex" flexWrap="wrap" justifyContent="center" py={4} px={5}>
					<Grid container direction="row" justify="center" alignItems="center">
						<Grid item xs={12} md={12} lg={11} alignItems="center">
							<OutlinedInput
								labelWidth={0}
								placeholder="Enter Email Address"
								onChange={(e: any) => setInviteEmail(e.target.value)}
								value={inviteEmail}
								fullWidth={true}
								margin="dense"
								endAdornment={
									<Button
										variant="contained"
										size="large"
										color="secondary"
										aria-label="Search"
										style={{ width: 150 }}
										onClick={sendEmailInvitationToBand} >
										Send Invite
									</Button>
								}
							/>
							{showEmailError !== '' && 
								<Typography><small style={{ padding: 0, backgroundColor: "rgba(100, 254, 204, 0.0)", color: "#FF0200", fontWeight: 500, fontStyle:"italic", fontSize: "12px" }}> {showEmailError}</small></Typography>
							}
							{showEmailExistMessage !== '' && 
								<Typography><span style={{ padding: "15px 30px", display:"inline-block", width:"100%", textAlign: "center", marginTop: "20px", fontWeight: 300, fontStyle:"italic", fontSize: "18px" }}> {showEmailExistMessage}</span></Typography>
							}
							
						</Grid>
					</Grid>
				</Box>
			</div>}


			{showSentInviteBand && <div>
				<CustomDialogTitle
					onClose={() => {
						closeSearchDialog();
						setShowInviteBand(false);
						setShowSentInviteBand(false);
						setshowSearchBand(true);
					}}
					id="alert-dialog-title"
				>
					<Typography variant="h4">INVITE A BAND</Typography>
				</CustomDialogTitle>
				<Box display="flex" flexWrap="wrap" justifyContent="center" py={4} px={5}>
				<Grid container direction="row" justify="center" alignItems="center">
					<Grid item xs={12} md={12} lg={11} alignItems="center">
						<Typography component="h6" align="center">
								Can't find your band? Invite them to create a profile with Repsy!
						</Typography>
					</Grid>
				</Grid>
				<Grid container direction="row" justify="center" alignItems="center">
					<Grid item xs={12} md={12} lg={11} alignItems="center">
						<Typography variant="h4" align="center">Sent!</Typography>
					</Grid>
				</Grid>
	
					<Grid container direction="row" justify="center" alignItems="center">
						<Grid item xs={12} md={12} lg={11} alignItems="center">
						<Box display="flex" flexWrap="wrap" justifyContent="center" py={4} px={5}>
							<Button onClick={() => {
								setShowInviteBand(false);
								setShowSentInviteBand(false);
								setshowSearchBand(true);
							}} variant="contained"
							size="large"
							color="secondary"
							className={classes.margin}
							style={{ width: 150 }}>
								Back to Bands
							</Button>
							<Button onClick={() => {
								closeSearchDialog();
								setShowInviteBand(false);
								setShowSentInviteBand(false);
								setshowSearchBand(true);
							}} variant="contained"
							size="large"
							color="secondary"
							className={classes.margin}
							style={{ width: 150, marginLeft: "15px", }}>
								Close
							</Button>
							</Box>
						</Grid>
					</Grid>
				</Box>
			</div>}

			{showMessageDialog &&
				<MessageDialog showDialog={showMessageDialog} closeDialog={closeMessageDialog} title="Request Sent"  message="Manage band request sent successfully!" />
			 }

		</Dialog>
		
		</>
	);
};