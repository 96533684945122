import { createSlice, PayloadAction } from "redux-starter-kit";
import { userActions } from "../../AuthBase";
import Services from "../../generic/Services";

export interface BookerState {
	bookerProfileRequested: boolean;
	bookerProfileSucceeded: boolean;
	bookerProfileFailure: boolean;
	error: {};
	bookerProfile: any;
	bookerTypesRequested: boolean;
	bookerTypesSucceeded: boolean;
	bookerTypesFailure: boolean;
	bookerTypes: {
		status: string;
		server_message: string;
		user_message: string;
		items: [];
	};
	editBookerProfileRequested: boolean;
	editBookerProfileSucceeded: boolean;
	editBookerProfileFailure: boolean;
	saveModal: boolean;
}

const initialState: BookerState = {
	bookerProfileRequested: false,
	bookerProfileSucceeded: false,
	bookerProfileFailure: false,
	error: {},
	bookerProfile: null,
	bookerTypesRequested: false,
	bookerTypesSucceeded: false,
	bookerTypesFailure: false,
	bookerTypes: {
		status: "",
		server_message: "",
		user_message: "",
		items: [],
	},
	editBookerProfileRequested: false,
	editBookerProfileSucceeded: false,
	editBookerProfileFailure: false,
	saveModal: false,
};

const bookerProfileSlice = createSlice({
	slice: "bookerProfile",
	initialState,
	reducers: {
		bookerProfileRequested(state, action) {
			state.bookerProfileRequested = true;
			state.bookerProfileSucceeded = false;
			state.bookerProfileFailure = false;
		},
		bookerProfileSucceeded(state, action: PayloadAction<boolean>) {
			state.bookerProfileSucceeded = true;
			state.bookerProfileRequested = false;
			state.bookerProfileFailure = false;
			state.bookerProfile = action.payload;
		},
		bookerProfileFailure(state, action: PayloadAction<boolean>) {
			state.bookerProfileSucceeded = false;
			state.bookerProfileRequested = false;
			state.bookerProfileFailure = true;
			state.error = action.payload;
		},
		bookerTypesRequested(state, action) {
			state.bookerTypesRequested = true;
			state.bookerTypesSucceeded = false;
			state.bookerTypesFailure = false;
		},
		bookerTypesSucceeded(state, action) {
			state.bookerTypesRequested = false;
			state.bookerTypesSucceeded = true;
			state.bookerTypesFailure = false;
			state.bookerTypes = action.payload;
		},
		bookerTypesFailure(state, action) {
			state.bookerTypesRequested = false;
			state.bookerTypesSucceeded = false;
			state.bookerTypesFailure = true;
			state.error = action.payload;
		},
		editBookerProfileRequested(state, action) {
			state.editBookerProfileRequested = true;
			state.editBookerProfileSucceeded = false;
			state.saveModal = false;
			state.editBookerProfileFailure = false;
		},
		editBookerProfileSucceeded(state, action) {
			state.editBookerProfileRequested = false;
			state.editBookerProfileSucceeded = true;
			state.editBookerProfileFailure = false;
			state.saveModal = true;
			state.bookerProfile = action.payload;
		},
		editBookerProfileFailure(state, action) {
			state.editBookerProfileRequested = false;
			state.editBookerProfileSucceeded = false;
			state.editBookerProfileFailure = true;
			state.saveModal = false;
			state.error = action.payload;
		},
		closeSaveModal(state, action) {
			state.saveModal = false;
		},
		openSaveModal(state, action) {
			state.saveModal = true;
		},
		closeFailModal(state, action) {
			state.editBookerProfileFailure = false;
		},
	},
});

export const {
	bookerProfileRequested,
	bookerProfileSucceeded,
	bookerProfileFailure,
	bookerTypesRequested,
	bookerTypesSucceeded,
	bookerTypesFailure,
	editBookerProfileRequested,
	editBookerProfileSucceeded,
	editBookerProfileFailure,
	closeSaveModal,
	openSaveModal,
	closeFailModal,
} = bookerProfileSlice.actions;

export default bookerProfileSlice.reducer;

export const editBookerProfile = (bandProfile: any): any => async (dispatch: any) => {
	try {
		dispatch(editBookerProfileRequested());
		const res = await Services.booker.editBookerProfile(bandProfile);
		let user = JSON.parse(localStorage.getItem("user") || "");
		user = { ...user, fname: res.item.fname, lname: res.item.lname};
		user = JSON.stringify(user);
		localStorage.setItem("user", user);
		dispatch(userActions.editUser(res.item));

		dispatch(editBookerProfileSucceeded(res));
		getBookerProfile()(dispatch)
	} catch (err) {
		dispatch(editBookerProfileFailure(err));
	}
};

export const listBookerTypes = (): any => async (dispatch: any) => {
	try {
		dispatch(bookerTypesRequested());
		const res = await Services.booker.listBookerTypes();
		dispatch(bookerTypesSucceeded(res));
	} catch (err) {
		dispatch(bookerTypesFailure(err));
	}
};

export const getBookerProfile = (): any => async (dispatch: any) => {
	try {
		dispatch(bookerProfileRequested());
		const res = await Services.booker.getBookerProfile();
		dispatch(bookerProfileSucceeded(res));
	} catch (err) {
		dispatch(bookerProfileFailure(err));
	}
};
