import { ResourcesActionType, ResourcesAction } from "./interfaces";
import { combineReducers } from "redux";

const priceRangeIsRequested = (state = false, action: ResourcesAction) => {
	switch (action.type) {
		case ResourcesActionType.PRICE_RANGE_REQUEST:
			return true;
		case ResourcesActionType.PRICE_RANGE_SUCCESS:
		case ResourcesActionType.PRICE_RANGE_FAILURE:
			return false;
		default:
			return state;
	}
};

const priceRangeIsSucceeded = (state = false, action: ResourcesAction) => {
	switch (action.type) {
		case ResourcesActionType.PRICE_RANGE_SUCCESS:
			return true;
		case ResourcesActionType.PRICE_RANGE_REQUEST:
		case ResourcesActionType.PRICE_RANGE_FAILURE:
			return false;
		default:
			return state;
	}
};

const priceRangeList = (state = [], action: ResourcesAction) => {
	switch (action.type) {
		case ResourcesActionType.PRICE_RANGE_SUCCESS:
			return action.payload;
		default:
			return state;
	}
};

const error = (state = {}, action: ResourcesAction) => {
	switch (action.type) {
		case ResourcesActionType.PRICE_RANGE_FAILURE:
			return action.payload;
		default:
			return state;
	}
};

export const priceRange = combineReducers({
	priceRangeIsRequested,
	priceRangeIsSucceeded,
	priceRangeList,
	error,
});
