import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import React, { useEffect, useState } from "react";
import DialogContent from "@material-ui/core/DialogContent";
import { Link, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";

interface ISimpleDialogProps {
	open: boolean;
	onClose: () => void;
	message?: string;
	role?: string;
}

const useStyles = makeStyles((theme) => ({
	test: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "flex-start",
	},
	dialog: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "flex-start",
		margin: 0,
	},
	actions: {
		display: "flex",
		justifyContent: "center",
		margin: "0 0 20px 0",
		fontSize: "18px",
		color: "white"
	},
	message: {
		padding: "0px 30px",
		marginTop: 10,
		color: "#FFFF",
		textAlign: "center",
		alignContent: "center",
	},
	title: {
		margin: 0,
		padding: 0,
		textAlign: "center",
		textTransform: "uppercase"
	},
	Close: {
		display: "flex",
		justifyContent: "flex-start",
	},
}));

const SimpleDialog = (props: ISimpleDialogProps) => {
	const classes = useStyles();
	const history = useHistory()

	const { onClose, open, role } = props;


	useEffect(() => {
		if(role == "TALENT"){
			history.push('/account/edit-profile');
		}
	}, [role])

	const handleClose = () => {
		onClose();
	};

	const handleSubmit = () => {
		handleClose();
		if (role == "AGENT") {
			history.push('/account/billing-information');
		}
		else if (role == "BOOKER") {
			history.push('/search');
		}
		else if (role == "TALENT") {

		}
	}
	if (role != "TALENT") {
		return (
			<Dialog className="first-login-dialog" aria-labelledby="simple-dialog-title" open={open} fullWidth={false} maxWidth="sm" onClose={handleClose}>
				<DialogActions className={classes.Close}>
					<Link onClick={handleSubmit} to="">
						<CloseIcon style={{ color: 'white' }} />
					</Link>
				</DialogActions>
				<Typography variant="h4" className={classes.title} id="simple-dialog-title">
					{role == "BOOKER" ? "Your profile is under review!" : role == "AGENT" ? "Almost there!" : ""}
				</Typography>
				<DialogContent className={classes.message}>
					{role == "BOOKER" ? (
						<div>
							<Typography variant="body1" color="inherit" style={{ paddingBottom: "20px" }} align="center" id="simple-dialog-description1">
								Thank you for creating your profile!
							</Typography>
							<Typography variant="body1" color="inherit" align="center" id="simple-dialog-description2">
								To ensure the highest quality experience, we must confirm a few things before you can book an artist from our incredible network. Keep an eye out for an approval notification and feel free to browse or favorite the artist profiles that you like!
							</Typography>
						</div>
					) : (
						<div>
							<Typography variant="body1" color="inherit" align="center" id="simple-dialog-description">
								One more step before you can connect to artists you represent and begin booking gigs! As an agent you need to get paid for events and Repsy requires billing information to validate user accounts. Please click below to add your preferred payment method. Got questions? Please <Link to={"/contact"} style={{ color: "white" }} onClick={handleClose}>contact</Link> our team.
							</Typography>
						</div>
					)}
				</DialogContent>
				<DialogActions className={classes.actions} style={{ marginTop: "35px" }}>
					<Grid item xs={12} style={{ textAlign: "center" }}>
						<Button style={{ padding: "4px 20px" }} size="medium" variant="contained" color="secondary" onClick={handleSubmit}>
							{role == "BOOKER" ? "Browse Artists" : role == "AGENT" ? "Finish Setting Up My Profile" : ""}
						</Button>
					</Grid>
					{/* <Link onClick={handleClose} to="/account/edit-profile" style={{ color: '#64FECC' }} type="button">
					Got it!
				</Link> */}
				</DialogActions>
			</Dialog>
		);
	}
	else{
		return (<></>)
	}
};
export default SimpleDialog;
