import React from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";
import { RequestObject } from "./ListTalentEvents/listTalentEventsSlice";
import { minutesToHours } from "../../BandPage/BandRequest/BandRequest";
import moment from "moment";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		margin: {
			margin: "0px",
		},
	}),
);

interface EventDescriptionProps {
	request: RequestObject;
}

const changeText = (status: string) => {
	switch (status) {
		case "OPEN":
			return <Box color="#64fecd">New</Box>;
		case "ACCEPTED_BY_TALENT":
			return <Box color="#ffff00">Pending Confirmation</Box>;
		case "PENDING_DEPOSIT":
			return <Box color="#ffff00">Confirmed</Box>;
		case "PENDING_REMAINDER":
			return <Box color="#ff6600">Paid Deposit</Box>;
		case "APPROVED":
			return <Box color="#24b500">Paid</Box>;
		case "COMPLETED":
			return <Box color="#24b500">Completed</Box>;
		case "REJECTED_BY_TALENT":
			return <Box color="#ff0000">Rejected</Box>;
		case "CANCELED_BY_BOOKER":
			return <Box color="#808080">Canceled</Box>;
		case "CANCELED_BY_TALENT":
			return <Box color="#808080">Canceled by You</Box>;
		case "PENDING_FULL_PAYMENT":
			return <Box color="#ffff00">Pending Full Payment</Box>;
		default:
			return status;
	}
};
export const EventDescription: React.FC<EventDescriptionProps> = ({
	request,
}) => {
	const classes = useStyles();

	return (
		<Grid
			container
			direction="column"
			justify="center"
			spacing={1}
			wrap="nowrap">
			<Grid item>
				<Grid container direction="row">
					<Grid item xs={3}>
						<h4 className={classes.margin}>Booker's Name</h4>
					</Grid>
					<Grid item xs={8}>
						<p
							className={
								classes.margin
							}>{`${request.created_by.fname} ${request.created_by.lname}`}</p>
					</Grid>
				</Grid>
			</Grid>
			<Grid item>
				<Grid container direction="row">
					<Grid item xs={3}>
						<h4 className={classes.margin}>Event Date</h4>
					</Grid>
					<Grid item xs={8}>
						<p className={classes.margin}>
							{moment(request.event.date)
								.format("MMM DD, YYYY")
								.toString()}
						</p>
					</Grid>
				</Grid>
			</Grid>
			<Grid item>
				<Grid container direction="row">
					<Grid item xs={3}>
						<h4 className={classes.margin}>Event Location</h4>
					</Grid>
					<Grid item xs={8}>
						{/* check on if state obj is present to access the title field inside of it */}
						<p className={classes.margin}>{`${
							request.event.location
						}, ${
							request.event.state ? request.event.state.title : ""
						} `}</p>
					</Grid>
				</Grid>
			</Grid>
			<Grid item>
				<Grid container direction="row">
					<Grid item xs={3}>
						<h4 className={classes.margin}>Number of Attendees</h4>
					</Grid>
					<Grid item xs={8}>
						<p className={classes.margin}>
							{request.event.number_of_attendees}
						</p>
					</Grid>
				</Grid>
			</Grid>
			<Grid item>
				<Grid container direction="row">
					<Grid item xs={3}>
						<h4 className={classes.margin}>Talent Budget</h4>
					</Grid>
					<Grid item xs={8}>
						<p className={classes.margin}>{`$${request.talent_budget}`}</p>
					</Grid>
				</Grid>
			</Grid>
			<Grid item>
				<Grid container direction="row">
					<Grid item xs={3}>
						<h4 className={classes.margin}>Production Budget</h4>
					</Grid>
					<Grid item xs={8}>
						<p className={classes.margin}>{`$${request.production_budget}`}</p>
					</Grid>
				</Grid>
			</Grid>
			{request.event.event_type && (
				<Grid item>
					<Grid container direction="row">
						<Grid item xs={3}>
							<h4 className={classes.margin}>Event Type</h4>
						</Grid>
						<Grid item xs={8}>
							<p className={classes.margin}>
								{request.event.event_type &&
									request.event.event_type.title}
							</p>
						</Grid>
					</Grid>
				</Grid>
			)}
			{request.event.start_time && (
				<Grid item>
					<Grid container direction="row">
						<Grid item xs={3}>
							<h4 className={classes.margin}>Start Time</h4>
						</Grid>
						<Grid item xs={8}>
							<p className={classes.margin}>
								{moment(
									request.event.start_time, "h:mm:ss").format("h:mm A")}
							</p>
						</Grid>
					</Grid>
				</Grid>
			)}
			<Grid item>
				<Grid container direction="row">
					<Grid item xs={3}>
						<h4 className={classes.margin}>Desired Length</h4>
					</Grid>
					<Grid item xs={8}>
						<p className={classes.margin}>
							{minutesToHours(request.event.desired_set_length)}
						</p>
					</Grid>
				</Grid>
			</Grid>
			<Grid item>
				<Grid container direction="row">
					<Grid item xs={3}>
						<h4 className={classes.margin}>Venue Description</h4>
					</Grid>
					<Grid item xs={8}>
						<p className={classes.margin}>
							{request.event.venue_description
								.map((item) => item.title)
								.join(", ")}
						</p>
					</Grid>
				</Grid>
			</Grid>
			{/* added status field to the event description */}
			{request.status && (
				<Grid item>
					<Grid container direction="row">
						<Grid item xs={3}>
							<h4 className={classes.margin}>Status</h4>
						</Grid>
						<Grid item xs={8}>
							{changeText(request.status)}
						</Grid>
					</Grid>
				</Grid>
			)}
			{request.notes && (
				<Grid item>
					<Grid container direction="row">
						<Grid item xs={3}>
							<h4 className={classes.margin}>Special Notes</h4>
						</Grid>
						<Grid item xs={8}>
							<p className={classes.margin}>{request.notes}</p>
						</Grid>
					</Grid>
				</Grid>
			)}
			<Grid item>
				<Grid container direction="row">
					<Grid item xs={3}>
						<h4 className={classes.margin}>Deposit Percentage</h4>
					</Grid>
					<Grid item xs={8}>
						{request.band.deposit}
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};
