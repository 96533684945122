import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import cancelIcon from "../../Assets/Cancel.svg";

interface IConfirme {
    open: boolean;
    message: string;
    handleClose: () => void;
    handlePay: () => void;
}

const PaymentConfirmation: React.FC<IConfirme> = ({ open, message, handleClose, handlePay }) => {
    return (
        <Dialog
            fullWidth={true}
            maxWidth="sm"
            scroll="body"
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle disableTypography style={{ padding: 0 }}>
                <IconButton aria-label="Close" size="small" onClick={handleClose}>
                    {/* <Close /> */}
                    <Icon
                        style={{
                            width: "1.5em",
                            height: "1.5em",
                        }}>
                        <img src={cancelIcon} alt="cancel icon" />
                    </Icon>
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <DialogContentText color="textPrimary" id="alert-dialog-description">
                    {message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handlePay} color="default" autoFocus>
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default PaymentConfirmation;
