import Header from "./header";
import { connect } from "react-redux";
import { IAppState } from "../generic/interfaces";
import { ThunkDispatch } from "redux-thunk";
import { userActions } from "../AuthBase";
import headerActions from "./actions";
import { filterBandsKeywordSet } from "../SearchPage/FilterBar/filterBarSlice/filterSlice";

const mapStateToProps = (state: IAppState) => ({
	header: state.header,
	authentication: state.authentication,
	page: state.homePage.page,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
	logout: () => dispatch(userActions.logout()),
	openLoginModal: () => dispatch(headerActions.openLoginModal()),
	openSignupModal: () => dispatch(headerActions.openSignupModal()),
	search: (text: string) => {
		dispatch(filterBandsKeywordSet(text));
		//dispatch(search())
	},
	resetMessageNotification: () => dispatch(headerActions.resetMessageNotification()),
	incrementMessageNotification: (id: number) => dispatch(headerActions.incrementMessageNotification(id)),
	notificationSeen: (index: number) => dispatch(headerActions.NotificationSeen(index)),
	openNotification: () => dispatch(headerActions.openNtifications()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
