import { refineResponse } from "../helpers/refineResponse";
import { headers, baseUrl } from "../../AuthBase/helpers/auth-header";

export const list = (page: number) => {
	const requestOptions = {
		method: "POST",
		headers: { ...headers() },
		body: JSON.stringify({ filter: {}, sort: {}, page, items_per_page: 1 }),
	};
	return fetch(`${baseUrl}events/requests`, requestOptions).then(refineResponse);
};

export const update = (eventRequest: any) => {
	const requestOptions = {
		method: "PUT",
		headers: { ...headers() },
		body: JSON.stringify(eventRequest),
	};
	return fetch(`${baseUrl}events/request-band/${eventRequest.id}`, requestOptions).then(refineResponse);
};

export const getItemById = (requestId: number) => {
	const requestOptions = {
		method: "GET",
		headers: { ...headers() },
	};

	return fetch(`${baseUrl}events/request-band/${requestId}`, requestOptions).then(refineResponse);
};

export const changeStatus = (requestId: number, status: string) => {
	const requestOptions = {
		method: "PUT",
		headers: { ...headers() },
		body: JSON.stringify({ status }),
	};

	return fetch(`${baseUrl}events/requests/${requestId}`, requestOptions).then(refineResponse);
};

export const addEventRequest = (eventRequest: any) => {
	const requestOptions = {
		method: "POST",
		headers: { ...headers() },
		body: JSON.stringify(eventRequest),
	};

	return fetch(`${baseUrl}events/request-band`, requestOptions).then(refineResponse);
};

export const editEventRequest = (eventRequest: any) => {
	const requestOptions = {
		method: "PUT",
		headers: { ...headers() },
		body: JSON.stringify({ ...eventRequest.data }),
	};

	return fetch(`${baseUrl}events/request-band/${eventRequest.id}`, requestOptions).then(refineResponse);
};

export const deleteRequest = (requestId: number) => {
	const requestOptions = {
		method: "DELETE",
		headers: { ...headers() },
	};

	return fetch(`${baseUrl}events/request-band/${requestId}`, requestOptions).then(refineResponse);
};

export const getRequestHistory = (id: number) => {
	const requestOptions = {
		method: "POST",
		headers: { ...headers() },
		body: JSON.stringify({ page: 1, items_per_page: 100 }),
	};

	return fetch(`${baseUrl}talent-event/history/${id}`, requestOptions).then(refineResponse);
};

export const listThreads = ({
	filter,
	items_per_page = 10,
	sort,
	page,
}: {
	filter?: any;
	items_per_page?: number;
	sort?: any;
	page: number;
}) => {
	const requestOptions = {
		method: "POST",
		headers: { ...headers() },
		body: JSON.stringify({ ...filter, items_per_page, sort, page }),
	};

	console.log('listThreads events/requests: ');
	return fetch(`${baseUrl}events/requests`, requestOptions).then(refineResponse);
};

export const listConversation = ({
	filter,
	items_per_page = 10,
	sort,
	page = 1,
}: {
	filter: any;
	items_per_page?: number;
	sort?: any;
	page?: number;
}) => {
	const requestOptions = {
		method: "POST",
		headers: { ...headers() },
		body: JSON.stringify({ items_per_page, sort, page, filter: { ...filter } }),
	};

	return fetch(`${baseUrl}messages/requests`, requestOptions).then(refineResponse);
};

export const reply = ({
	message_text,
	event_request_id,
	parent_id = 0,
}: {
	message_text: string;
	event_request_id: number;
	parent_id?: number;
}) => {
	const requestOptions = {
		method: "POST",
		headers: { ...headers() },
		body: JSON.stringify({ message_text, event_request_id, parent_id }),
	};

	return fetch(`${baseUrl}messages`, requestOptions).then(refineResponse);
};


export const updateLastSeen = (id: number) => {
	const requestOptions = {
		method: "PUT",
		headers: { ...headers() },
		body: JSON.stringify({last_seen: Math.floor(Date.now() / 1000)}),
	};

	return fetch(`${baseUrl}event-request-lastseen/${id}`, requestOptions).then(refineResponse);
}

export const getReceipt = (event_request_id: number, payment_type: string) => {
	const requestOptions = {
		method: "GET",
		headers: { ...headers() },
	};

	return fetch(`${baseUrl}get_stripe_receipt?request_band_id=${event_request_id}&payment_type=${payment_type}`, requestOptions).then(refineResponse);
}

export const listEventRequests = (id: number, page: number) => {
	const requestOptions = {
		method: "POST",
		headers: { ...headers() },
		body: JSON.stringify({ page }),
	};

	return fetch(`${baseUrl}event-request/list/${id}`, requestOptions).then(refineResponse);
};