import { connect } from "react-redux";
import { IAppState } from "../../generic/interfaces";
import { ThunkDispatch } from "redux-thunk";
import { Settings } from "./Settings";
import { setPage } from "../../HomePage/homePageSlice";
import { editPassword, editEmail, closeModal } from "./settingsSlice";

const mapStateToProps = (state: IAppState) => ({
	settings: state.account.settings,
	authentication: state.authentication.user,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
	setPage: (page: string) => {
		dispatch(setPage(page));
	},
	closeModal: () => {
		dispatch(closeModal());
	},
	editPassword: (passwordFormObject: any) => {
		return dispatch(editPassword(passwordFormObject));
	},
	editEmail: (emailFormObject: any) => {
		return dispatch(editEmail(emailFormObject));
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
