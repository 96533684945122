import React from "react";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";
import { IImageUploaderProps } from "./interfaces";
import { getImageFile } from "../generic/Services/media";
import { Grid, Box } from "@material-ui/core";
//@ts-ignore
import { getDroppedOrSelectedFiles } from "html5-file-selector";
import { ILayoutProps, IInputProps } from "react-dropzone-uploader";
import ImagePlaceholder from "../Assets/ImagePlaceholder.png";

class ImageUploader extends React.Component<IImageUploaderProps> {
	_isMounted = false;
	state = {
		file: undefined,
	};

	componentDidMount() {
		this._isMounted = true;

		if (this.props.url) {
			const promises = [getImageFile(this.props.url)];
			Promise.all(promises).then(responses => {
				if (this._isMounted) {
					this.setState({ file: responses });
				}
			});
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	handleChangeStatus = (temp: any, status: any) => {
		if (status === "removed") {
			this.props.handleChange(null);
		} else if (status === "done") {
			this.props.handleChange(temp.file);
		}
	};

	getFilesFromEvent = async (e: any) => {
		const chosenFiles = await getDroppedOrSelectedFiles(e);
		return chosenFiles.map((f: any) => f.fileObject);
	};

	render() {
		const { file } = this.state;
		const { url } = this.props;
		return (
			<Dropzone
				addClassNames={{
					dropzone: `dzu-dropzone-photo talent `,
					preview: "dzu-previewContainer-photo",
					previewImage: "dzu-previewImage-photo",
				}}
				initialFiles={file}
				LayoutComponent={(props: ILayoutProps) => {
					return (
						<>
							<Grid
								container
								direction="row"
								justify="space-evenly"
								alignItems="center"
							>
								<Grid item xs={4} md={4} lg={4}>
									{props.previews === null
										? null
										: props.previews[
												props.previews.length - 1
										  ]}
									{props.files.length === 0 && (
										<div {...props.dropzoneProps}></div>
									)}
								</Grid>
								<Grid item xs={7} md={7} lg={7}>
									<Grid
										container
										direction="column"
										justify="center"
									>
										<Grid item xs={12}>
											<Box
												fontSize={28}
												fontWeight={800}
												fontFamily="Rubik"
											>
												{this.props.title}
											</Box>
										</Grid>
										<Grid item>{props.input}</Grid>
									</Grid>
								</Grid>
							</Grid>
						</>
					);
				}}
				onChangeStatus={this.handleChangeStatus}
				InputComponent={(props: IInputProps) => {
					const text = this.props.btnText;
					return (
						<label
							style={{
								cursor: "pointer",
								backgroundColor: "transparent",
								color: "#bdbdbd",
								padding: "0px",
								borderBottom: "1px solid",
								fontSize: "medium",
							}}
						>
							{text}
							<input
								style={{ display: "none" }}
								type="file"
								accept={props.accept}
								multiple={false}
								onChange={e => {
									props
										.getFilesFromEvent(e)
										.then((chosenFiles: any) => {
											props.onFiles(chosenFiles);
										});
								}}
							/>
						</label>
					);
				}}
				styles={{
					dropzone: {
						background: `url(${url || ImagePlaceholder})`,
						backgroundSize: "cover",
						backgroundPosition: "center",
					},
				}}
				accept="image/*"
				multiple={false}
				inputContent={this.props.inputContent}
				submitButtonContent={null}
				getFilesFromEvent={this.getFilesFromEvent}
				canRemove={false}
			/>
		);
	}
}

export default ImageUploader;
