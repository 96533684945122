import { IBaseObject } from "./object";
import { Genre } from "./index";
import { Media } from "./media";
import { IMiniUser } from "./user";

enum EventRequestStatus {
	CANCELED = "CANCELED",
	CONFIRMED = "CONFIRMED",
}
export interface IMiniEventsRequest extends IBaseObject {
	event_id: number;
	band_id: number;
	status: EventRequestStatus;
	date: string;
	photo: Media;
	budget: number;
	currency: string;
	genres: Genre[];
	start_time: string;
	end_time: string;
}

export interface IEventsRequest extends IMiniEventsRequest {
	created_at: string;
	updated_at: string;
	created_by: IMiniUser;
	location: string;
	number_of_attendees: number;
	venue_description: string;
	notes: string;
}
