import React, { useEffect, useState } from "react";
import { Container, Box, Typography, Button, Grid, OutlinedInput, IconButton } from "@material-ui/core";
import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";
import { Link, RouteComponentProps } from "react-router-dom";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { FeaturedArtist } from "./FeaturedArtist";
import { FeaturedArtistMobile } from "./FeaturedArtistMobile";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { IsAnonymous } from "../AuthBase/";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { IAuthReducer } from "../AuthBase";
import Services from "../generic/Services";
import "./style.css";
import HomeFilterBar from "../SearchPage/HomeFilterBar";
import { MetadataTags } from '../generic/components/MetadataTags/metadata-tags'
import { HomePageMeta } from "../Assets/metadata.json";
interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}
  
  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  function a11yProps(index: any) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        pageTitle: {
            fontFamily: "Rubik",
            fontSize: "4rem",
            fontWeight: 800,
            textAlign: 'center',
            lineHeight: 'normal',
            marginBottom: '1.25rem',
            textShadow: '0px 4px 17px rgba(0, 0, 0, 0.25)',
            "@media only screen and (max-width: 900px)": {
                fontSize: "3.5rem",
                marginBottom: '3rem',
            },
            "@media only screen and (max-width: 767px)": {
                fontSize: "3rem",
                marginBottom: '2rem',
            },
        },
        searchBar: {
            // expand search bar to 100% on small screen
            "@media only screen and (max-width: 900px)": {
                width: "100%",
            },
            height: "60px",
            width: "42%",
            display: "block",
            position: "relative",
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(1.5),
        },
        link: {
            textDecoration: "none",
            height: "100%",
            top: "0px",
            right: "0px"
        },
        iconButton: {
            width: "6em",
        },
        letsRock: {
            fontSize: "20px",
            fontWeight: "bolder",
        },
        testimonials: {
            "@media only screen and (max-width: 900px)": {
                width: "100%",
            },
        },
        paper: {
            zIndex: 1,
            position: "relative",
            margin: theme.spacing(1),
        },
        titleHowItWorks: {
            color: theme.palette.secondary.main,
            fontSize: "50px",
            textAlign: "center",
            fontWeight: 800,
            "@media only screen and (max-width: 900px)": {
                fontSize: "40px",
            },
            "@media screen and (max-width: 640px)": {
                fontSize: "36px",
            },
        },
        titleEvent: {
            color: theme.palette.secondary.main,
            fontSize: "40px",
            textAlign: "left",
            fontWeight: 800,
            "@media screen and (max-width: 640px)": {
                fontSize: '24px',
                lineHeight: '32px',
            }
        },
        eventDetails: {
            fontFamily: "poppins",
            fontWeight: "normal",
            fontSize: "18px",
            lineHeight: "1.8",
            "@media screen and (max-width: 640px)": {
                fontSize: '14px',
                lineHeight: '1.3',
            }
        },
        howitWorksTabsMain: {
            width: "auto !important"
        }, 
        tabItem: {
            border: '2px solid #080522',
            borderRadius: '10px',
        },
        HomeHeroHeader: {
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'center',
            height: '86vh',
            position: 'relative',
            "&::before": {
                position: "absolute",
                content: '""',
                background: 'linear-gradient(180deg, rgba(7, 5, 33, 0.0001) 0%, #070521 100%)',
                width: '100%',
                height: '40%',
                left: '0px',
                top: 'auto',
                bottom: '0px',
                zIndex: 1,
            },
            "&::after": {
                position: "absolute",
                content: '""',
                background: 'linear-gradient(0deg, rgba(7, 5, 33, 0.0001) 40%, #070521 100%)',
                width: '100%',
                height: '40%',
                left: '0px',
                bottom: 'auto',
                top: '0px',
                zIndex: 1,
            }
        },
        filterBtn: {
            backgroundColor: 'tranperant',
            borderTop: '0px',
            borderRight: '0px',
            borderBottom: '0px',
        },
        image: {
            height: "14em",
            width: "14em",
        },
        howItIcon: {
            width: "100%",
            maxWidth: "128px",
        },
        signupBG: {
            position: 'relative',
            display: 'flex',
            flexWrap: 'wrap',
            backgroundColor: '#071F29',
            backgroundRepeat: "no-repeat",
            backgroundPosition: "left top",
            backgroundSize: "auto 100%",
            "@media screen and (min-width: 1921px)": {
                backgroundPosition: "left 12%",
                backgroundSize: "auto 100%",
                minHeight:'660px',
            },
            "@media screen and (min-width: 2560px)": {
                backgroundSize: "auto 100%",
                minHeight:'660px',
            },
            "@media screen and (min-width: 3000px)": {
                backgroundSize: '64% auto',
                minHeight:'760px',
            },
            "@media screen and (min-width: 3600px)": {
                backgroundSize: '66% auto',
                minHeight:'760px',
            },
            "@media screen and (min-width: 4000px)": {
                backgroundSize: '68% auto',
                minHeight:'800px',
            },
            "@media screen and (min-width: 4400px)": {
                backgroundSize: '68% auto',
                minHeight:'960px',
            },
            "@media screen and (min-width: 5000px)": {
                backgroundSize: '68% auto',
                minHeight:'1024px',
            },
            "@media screen and (max-width: 900px)": {
                backgroundSize: '155% auto',
                backgroundPosition: '100% bottom',
                "&::before": {
                    position: "absolute",
                    content: '""',
                    background: "linear-gradient(180deg, #071F29 80%, rgba(7, 31, 41, 0) 100%)",
                    width: '100%',
                    height: '13%',
                    left: '0px',
                    top: '0px',
                    bottom: 'auto',
                },
            },
            "@media screen and (max-width: 860px)": {
                backgroundSize: '165% auto',
                backgroundPosition: '100% bottom',
                "&::before": {
                    height: '13%',
                }
            },
            "@media screen and (max-width: 767px)": {
                backgroundSize: 'cover',
                backgroundPosition: '50%',
                "&::before": {
                    height: '35%',
                }
            },
            "@media screen and (max-width: 640px)": {
                paddingBottom: '0px',
                backgroundSize: 'cover',
                backgroundPosition: '50%',
                "&::before": {
                    height: '26%',
                    top: 'auto',
                    bottom: '0px',
                    background: "linear-gradient(0deg, #071F29 80%, rgba(7, 31, 41, 0) 100%)",
                }
            },
            "@media screen and (max-width: 414px)": {
                "&::before": {
                    // height: '94%',
                }
            }
        },
        featured: {
            "@media only screen and (max-width: 900px)": {
                width: "100%",
            },
        },
        eventSectionSpacing: {
            padding: '100px 0px',
            "@media only screen and (max-width: 640px)": {
                padding: '40px 0px',
            },
        },
        textAlign: {
            textAlign: "center",
        },
        owner: {
            fontWeight: "bold",
            fontSize: "23px",
        },
        root: {
            flexGrow: 1,
            backgroundColor: theme.palette.background.paper,
        },
    })
);
export interface HomePageProps {    
    featured: any;
    howItWorks: any;
    // quotes: any;
    testimonials: any;
    authentication: IAuthReducer;
    listFeatured: () => void;
    getHowItWorks: (type: string) => void;
    // getQuotes: () => void;
    getTestimonials: () => void;
    openSignupModal: () => void;
    openLoginModal: () => void;
    search: (text: string) => void;
    setPage: (page: string) => void;
}

export const HomePage: React.FC<HomePageProps & RouteComponentProps<{}>> = ({
    setPage,
    listFeatured,
    featured,
    getHowItWorks,
    // getQuotes,
    // quotes,
    getTestimonials,
    testimonials,
    howItWorks,
    search,
    openSignupModal,
    openLoginModal,
    location,
    authentication,
}) => {
    const [value, setValue] = useState(0);
    const [homepagedetails, setHomepageDetails] = useState({
        header_image: '',
        event_title: '',
        event_description: '',
        event_image: ''
    });
    useEffect(() => {
        Services.termsAndConditions
            .homePage()
            .then((res: any) => {                
                setHomepageDetails(res.items);
            })
            .catch((err: any) => {
                console.log("err: ", err);
            }); 
        listFeatured();
        console.log('value', value)
        if (value == 0)  {
            getHowItWorks("booker");
        } else {
            getHowItWorks("talent");
        }
        getTestimonials();
        //getQuotes();
        setPage("home");
    }, [getHowItWorks, getTestimonials, listFeatured, location, openSignupModal, setPage, authentication.isLoggedIn, value]);

    // if user is logged out and navigates to root/#signup sign up dialog will open
    useEffect(() => {   
        
        console.log('location : ', location);
        if(!authentication.isLoggedIn && location?.pathname === '/signup')
        {
            openSignupModal();
        }
        if(!authentication.isLoggedIn && location?.pathname === '/login')
        {
            openLoginModal();
        }
    }, [authentication.isLoggedIn, location, openSignupModal, openLoginModal]);

    const [text, setText] = useState("");

    const searchbuttonRef = React.useRef<any>(null);

    const theme = useTheme();

    const matches = useMediaQuery(theme.breakpoints.up("sm"));

    const classes = useStyles();

    const handleKeyDown = (e: any) => {
        if (e.key === "Enter" && searchbuttonRef && searchbuttonRef.current) {
            searchbuttonRef.current.focus();
        }
    };

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {        
      setValue(newValue);      
    };

    const redirectToHowItWorks = (event: React.ChangeEvent<{}>, newValue: number) => {        
        setValue(newValue);      
    };

    return (
        <div className='homePageBg'>
            <MetadataTags 
                title={HomePageMeta?.title}
                description={HomePageMeta?.description}        
                image={HomePageMeta?.image}              
            />
            <Box alignItems="center" className={classes.HomeHeroHeader}>
            <Box alignItems="center" style={ homepagedetails && { background: "no-repeat 24% top", backgroundSize: 'cover', backgroundImage: `url(${homepagedetails.header_image})`, position: 'absolute', width: '100%', height:'100%', left: '0px', top: '0px', opacity:'0.5',}}></Box>
                <Container maxWidth="lg">
                <Box ml={matches ? "56px" : "0px"} mr={matches ? "56px" : "0px"} style={{position: 'relative', zIndex: 1,}}>
                    <Grid container direction="row" justify="center">
                        <Grid item xs={12} sm={12} md={12}>

                            <Typography variant="subtitle2" className={classes.pageTitle}>Find an Artist</Typography>
                            <Grid container justify="center" className="rw-margin">
                                <Grid item xs={12} sm={12} md={6}>
                                    <Typography variant="h4" align="right" className="mobile-align">
                                        FOR YOUR NEXT
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <Typography variant="h4" className="rw-sentence rw-words rw-words-1">
                                            <span>WEDDING</span>
                                            <span>BIRTHDAY BASH</span>
                                            <span>FRATERNITY PARTY</span>
                                            <span>SORORITY EVENT</span>
                                            <span>CORPORATE EVENT</span>
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid alignItems="center">
                               <HomeFilterBar />
                            </Grid>
                        </Grid>
                    </Grid>

                    {/* hide sign up in case user anonymous */}
                    <Grid container direction="row" justify="center" className="joinForFree">
                        <Grid item xs={12} sm={12} md={12}>
                            <IsAnonymous>
                                <Typography variant="h6" align="center" style={{fontFamily: 'poppins'}}>
                                    Are you an artist? Join for{" "}
                                    <u
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                            openSignupModal();
                                        }}>
                                        FREE
                                    </u>
                                </Typography>
                            </IsAnonymous>
                        </Grid>
                    </Grid>
                    
                    </Box>
                </Container>
                </Box>
                
                <Container maxWidth="lg">

                    <Box m="20px">
                        <Typography className={classes.titleHowItWorks} variant="subtitle2" style={{marginBottom: '40px'}}>
                            Featured Artists
                        </Typography>
                    </Box>
                    
                    <Box ml={matches ? "56px" : "0px"}
                        mr={matches ? "56px" : "0px"} mb={12} className="featured-artists-desktop">
                        <Grid container direction="row" justify="center" spacing={4} className={classes.FeaturedArtistItems}>
                        {featured &&
                            featured.items.slice(0, 3).map((item: any, index: number) => {
                                return (
                                    <Grid item xs={12} sm={6} md={4}>
                                        <FeaturedArtist band={item} />
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Box>

                    <Box mb={12} className="featured-artists-mobile">
                    <Carousel centerMode={true} showArrows={false} showIndicators={true} showThumbs={false} showStatus={false} centerSlidePercentage={70} className="FeaturedArtistItems">
                    {featured &&
                            featured.items.slice(0, 3).map((item: any, index: number) => {
                                return (                                    
                                    <FeaturedArtistMobile band={item} />                                    
                                );
                            })} 
                    {/* <div key="slide1" className="FeaturedArtistItem">
                        <Grid className="FeaturedArtistItem">
                            <img alt="" src={'https://s3.us-east-2.amazonaws.com/tc-repsy-dev/katy-anne-nTP8aZjedqI-unsplash.jpg'} style={{maxWidth: "100%", width:'100%'}} />
                            <Grid className="FeaturedArtistItemOverlay">
                                <Grid className="FeaturedArtistItemOverlayIn">
                                    <Typography variant="h5" className="FeaturedArtistItemTitle">OPOV Band</Typography>                            
                                    <p style={{marginTop:'5px', fontSize:'16px'}}>Rock, Hip Hop/Rap, Pop, Country....</p>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                    <div key="slide2" className="FeaturedArtistItem">
                        <Grid className="FeaturedArtistItem">
                            <img alt="" src={'https://s3.us-east-2.amazonaws.com/tc-repsy-dev/katy-anne-nTP8aZjedqI-unsplash.jpg'} style={{maxWidth: "100%", width:'100%'}} />
                            <Grid className="FeaturedArtistItemOverlay">
                                <Grid className="FeaturedArtistItemOverlayIn">
                                    <Typography variant="h5" className="FeaturedArtistItemTitle">OPOV Band</Typography>                            
                                    <p style={{marginTop:'5px', fontSize:'16px'}}>Rock, Hip Hop/Rap, Pop, Country....</p>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                    <div key="slide3" className="FeaturedArtistItem">
                        <Grid className="FeaturedArtistItem">
                            <img alt="" src={'https://s3.us-east-2.amazonaws.com/tc-repsy-dev/katy-anne-nTP8aZjedqI-unsplash.jpg'} style={{maxWidth: "100%", width:'100%'}} />
                            <Grid className="FeaturedArtistItemOverlay">
                                <Grid className="FeaturedArtistItemOverlayIn">
                                    <Typography variant="h5" className="FeaturedArtistItemTitle">OPOV Band</Typography>                            
                                    <p style={{marginTop:'5px', fontSize:'16px'}}>Rock, Hip Hop/Rap, Pop, Country....</p>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div> */}
                </Carousel>
                </Box>
                    
            </Container>

            <Container maxWidth="lg">
                <Box width="100%" mx="auto" alignItems="center" mb={12} className={classes.signupBG} style={ homepagedetails && { backgroundImage: `url(${homepagedetails.event_image})`}}>
                    <Container maxWidth="lg" style={{position:'relative'}}>
                        <Box
                            ml={matches ? "56px" : "0px"}
                            mr={matches ? "56px" : "0px"}
                            display="flex"
                            justifyContent="space-between"
                            flexDirection="column"
                            alignItems="flex-start"
                            flexGrow="1"
                            className={classes.eventSectionSpacing}
                        >
                            <Grid item>
                                <Grid container direction="row" justify="flex-end" alignItems="center">
                                    <Grid item xs={7} sm={7} md={7} lg={6} style={{paddingLeft: '45px'}}>
                                        <Typography variant="subtitle2" className={classes.titleEvent}>{homepagedetails && homepagedetails.event_title}</Typography>                            
                                        <p className={classes.eventDetails}>{homepagedetails && homepagedetails.event_description}</p>
                                        <IsAnonymous>                                    
                                            <Button size="large" variant="contained" color="secondary" style={{textTransform: 'capitalize', padding: '10px 30px 8px 30px', fontSize: '18px', height: 'auto'}}                                          
                                                onClick={() => {
                                                    openSignupModal();
                                                }}>
                                                Sign Up
                                            </Button>                                    
                                        </IsAnonymous>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </Container>
                </Box>  
            </Container>
                
         <Container maxWidth="lg">       
            <Box
                border={15}
                borderColor={value == 1 ? "#C9FFED" : "#0F24FD"}                
                // ml={matches ? 7 : 0}
                // mr={matches ? 7 : 0}
                p={3}
                pt={6}
                pb={6}
                display="flex"
                justifyContent="center"
                flexDirection="column"
                alignItems="center"
                style={value == 1 ? {
                        borderImage: "linear-gradient( to top, #0F24FD, #1a7cf6) 1 47%",
                    } : {
                        borderImage: "linear-gradient( to top, #64FECC, #C9FFED) 1 47%",
                    }}
                >
                <Grid container direction="column" alignItems="center" spacing={6}>
                    <Grid item>
                        <Typography className={classes.titleHowItWorks} variant="subtitle2">
                            How it Works
                        </Typography>
                    </Grid>                    
                    <AppBar position="static" className={classes.howitWorksTabsMain} style={{justifyContent: 'center', width:'auto', boxShadow: 'none'}}>
                        <Grid alignItems="center" justify="center">
                            <Tabs value={value} onChange={handleChange} aria-label="simple tabs example" className='howitWorksTabs' style={{justifyContent: 'center'}}>
                                <Tab label="BOOKER" className='tabItem' {...a11yProps(0)} style={{fontSize: '2.2em', fontFamily: 'Squada One'}} />
                                <Tab label="TALENT" className='tabItem' {...a11yProps(1)} style={{fontSize: '2.2em', fontFamily: 'Squada One'}}/>                        
                            </Tabs>
                        </Grid>
                    </AppBar>
                    <TabPanel value={value} index={value}>
                    <Grid item>
                        <Grid container direction="row" justify="space-between">
                            {howItWorks &&
                                howItWorks.items.map((item: any) => {
                                    return (
                                        <Grid key={item.id} item xs={12} md={4} lg={4}>
                                            <Grid container alignItems="center" direction="column">
                                                {/* <Grid item>
                                                    <Typography variant="h4">{item.title.toUpperCase()}</Typography>
                                                </Grid> */}
                                                <Grid item>
                                                    <img src={item.photo.url} className={classes.howItIcon} alt="icon" />
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="h4" style={{marginBottom:'10px', fontSize: '1.8em',}}>{item.subtitle.toUpperCase()}</Typography>
                                                </Grid>
                                                <Grid item md={8} className={classes.textAlign}>
                                                    <Typography>{item.text}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    );
                                })}
                        </Grid>
                    </Grid>
                    </TabPanel>
                    {/* <TabPanel value={value} index={1}>
                    Item Two
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                    Item Three
                    </TabPanel>  */}
                    {/* hide sign up in case user anonymous */}
                    {/* <IsAnonymous> */}
                        <Grid item>
                            <Link to={`how-it-works/${value === 0 ? `booker` : `talent` }`}  className={classes.link}>
                                <Button size="medium" variant="contained" color="secondary" style={{padding: '10px 30px 8px 30px', fontSize: '18px', height: 'auto'}} >
                                    Learn More
                                </Button>
                            </Link>
                        </Grid>
                    {/* </IsAnonymous> */}
                </Grid>
            </Box>

            {testimonials && testimonials.items.length > 0 && (
                <Carousel showArrows={false} showThumbs={false} showStatus={false} useKeyboardArrows dynamicHeight={true}>
                    {testimonials &&
                        testimonials.items.map((item: any) => {
                            return (
                                <Box
                                    key={item.id}
                                    width="100%"
                                    maxWidth="500px"
                                    mx="auto"
                                    className={classes.testimonials}
                                    fontFamily="poppins"
                                    fontWeight="200"
                                    fontSize="22px"
                                    fontStyle="italic">
                                    <Typography style={{width: '100%', maxWidth: '500px', fontSize: '22px', fontStyle: 'italic', fontFamily: 'poppins', fontWeight: 200, marginLeft: 'auto', marginRight: 'auto'}}>{item.description}</Typography>
                                    <Typography color="secondary" variant="body1" className={classes.owner} style={{paddingTop: '15px', fontSize:'17px', textTransform: 'uppercase'}}>
                                        - {item.name}
                                    </Typography>
                                    {/* <p>{item.description}</p> */}
                                </Box>
                            );
                        })}
                </Carousel>
            )}
            </Container>
        </div>
    );
};