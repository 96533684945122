import React, { ReactNode } from "react";

import { connect } from "react-redux";
import { IAuthReducer } from "../../AuthBase";
import { IAppState } from "../../generic/interfaces";
import { UserRole } from "../../interfaces";

const C = ({
	condition,
	children,
}: {
	condition: boolean;
	children?: ReactNode;
}) => <>{condition && children}</>;

const Role = ({
	roles,
	auth,
	...props
}: {
	roles: UserRole[];
	auth: IAuthReducer;
}) => <C condition={roles.includes(auth.user.role)} {...props} />;

const LoggedIn = ({ auth, ...props }: { auth: IAuthReducer }) => (
	<C condition={auth.isLoggedIn} {...props} />
);
const Anonymous = ({ auth, ...props }: { auth: IAuthReducer }) => (
	<C condition={!auth.isLoggedIn} {...props} />
);

const mapStateToProps = (state: IAppState) => ({
	auth: state.authentication,
});

export const IsRole = connect(mapStateToProps)(Role);

export const IsBooker = (props: any) => (
	<IsRole {...props} roles={[UserRole.BOOKER]} />
);
export const IsTalent = (props: any) => (
	<IsRole {...props} roles={[UserRole.TALENT]} />
);
export const IsAgent = (props: any) => (
	<IsRole {...props} roles={[UserRole.AGENT]} />
);
export const IsAdmin = (props: any) => (
	<IsRole {...props} roles={[UserRole.ADMIN]} />
);
export const IsUser = (props: any) => (
	<IsRole {...props} roles={[UserRole.TALENT, UserRole.BOOKER, UserRole.AGENT]} />
);
export const IsTalentAgent = (props: any) => (
	<IsRole {...props} roles={[UserRole.TALENT, UserRole.AGENT]} />
);
export const IsTalentBooker = (props: any) => (
	<IsRole {...props} roles={[UserRole.TALENT, UserRole.BOOKER]} />
);
export const IsLoggedIn = connect(mapStateToProps)(LoggedIn);
export const IsAnonymous = connect(mapStateToProps)(Anonymous);
