import React from "react";
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
} from "@material-ui/core";
import { IConfirmationDialogProps } from "./interfaces";

class ConfirmationDialog extends React.Component<IConfirmationDialogProps> {
	render() {
		const { handleCancel, handleOk, title, opened } = this.props;
		return (
			<Dialog
				disableBackdropClick
				disableEscapeKeyDown
				maxWidth="xs"
				// onEntering={handleEntering}
				aria-labelledby="confirmation-dialog-title"
				open={opened}
				// {...other}
			>
				<DialogTitle id="confirmation-dialog-title">
					{title}
				</DialogTitle>
				<DialogContent dividers />
				<DialogActions>
					<Button onClick={handleCancel} color="primary">
						Cancel
					</Button>
					<Button onClick={handleOk} color="primary">
						Ok
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}

export default ConfirmationDialog;
