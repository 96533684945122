import { connect } from "react-redux";
import { IAppState } from "../generic/interfaces";
import { ThunkDispatch } from "redux-thunk";
import { closeForgetPasswordModal, forgetPassword } from "./actions";
import ForgetPassword from "./forget-password";
import headerActions from "../Header/actions";

const mapStateToProps = (state: IAppState) => ({
	forgetPassword: state.forgetPassword,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
	handleClose: () => dispatch(closeForgetPasswordModal()),
	handleOpenLogin: () => {
		dispatch(closeForgetPasswordModal());
		setTimeout(() => {
			dispatch(headerActions.openLoginModal());
		}, 50);
	},
	handleOpenSignup: () => {
		dispatch(closeForgetPasswordModal());
		setTimeout(() => {
			dispatch(headerActions.openSignupModal());
		}, 50);
	},
	handleForgetPasswordSubmit: (data: { email: string }) =>
		dispatch(forgetPassword(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgetPassword);
