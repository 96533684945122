import React from "react";
import {
  Dialog,
  Divider,
  DialogContent,
  withStyles,
  Typography,
} from "@material-ui/core";
import { CustomDialogTitle } from "../generic/components";
import { Formik } from "formik";
import * as Yup from "yup";
import Form from "./form";
import { IResetPasswordProps } from "./interfaces";
import { dialogStyles } from "./styles";
import ConfirmationDialog from "./confirmationDialoge"

const validationSchema = Yup.object({
  password: Yup.string()
	.required("Password is required")
	.matches(
		/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,32})/,
		"Password is at least eight characters, at least one uppercase letter, one lowercase letter, one number and one special character",
	),
  confirmPassword: Yup.string()
	.required("Confirm your password")
	.oneOf([Yup.ref("password")], "Password does not match"),
});

class ResetPassword extends React.Component<IResetPasswordProps> {
  public state = {
	token: "",
  };

  public componentDidMount() {
	const { search } = this.props.location;
	const params = new URLSearchParams(search);
	const token = params.get("reset-token") || "";
	this.setState({
		token,
	});
	this.props.handleOpen();
  }

  public render() {
	const {
		classes,
		handleClose,
		handleSubmit,
		resetPasswordState,
		handleConfirmationClose,
	} = this.props;
	const values = {
		password: "",
		confirmPassword: "",
	};

	return (
		<>
			<Dialog
				fullWidth={true}
				maxWidth="sm"
				open={resetPasswordState.resetPasswordDialogOpen}
				scroll="body"
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				classes={{ paper: classes.dialogRoot }}
			>
				<CustomDialogTitle onClose={handleClose} id="alert-dialog-title">
				<Typography variant="h4" color="textPrimary">
					RESET YOUR PASSWORD
				</Typography>
				</CustomDialogTitle>
				<Divider variant="middle" className={classes.dialogDivider} />
				<DialogContent>
				<Formik
					render={(props) => (
					<Form
						{...props}
						showLoading={resetPasswordState.resetPasswordRequest}
					/>
					)}
					initialValues={values}
					validationSchema={validationSchema}
					onSubmit={(values, actions) => {
					handleSubmit({ password: values.password }, this.state.token);
					actions.setSubmitting(true);
					}}
				/>
				</DialogContent>
			</Dialog>
			<ConfirmationDialog opened={resetPasswordState.confirmationOpen} handleClose={handleConfirmationClose} classes={classes} />
		</>
	);
  }
}

export default withStyles(dialogStyles)(ResetPassword);
