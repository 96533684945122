import { refineResponse } from "../helpers/refineResponse";
import { headers, baseUrl } from "../../AuthBase/helpers/auth-header";

export const editBookerProfile = (bookerProfile: object) => {
	const requestOptions = {
		method: "POST",
		headers: { ...headers() },
		body: JSON.stringify(bookerProfile),
	};

	return fetch(`${baseUrl}users/booker`, requestOptions).then(
		refineResponse,
	);
};

export const listBookerTypes = () => {
	const requestOptions = {
		method: "POST",
		headers: { ...headers() },
		body: JSON.stringify({}),
	};

	return fetch(`${baseUrl}booker-types/list`, requestOptions).then(
		refineResponse,
	);
};

export const getBookerProfile = () => {
	const requestOptions = {
		method: "POST",
		headers: { ...headers() },
		body: JSON.stringify({}),
	};

	return fetch(`${baseUrl}users/details`, requestOptions).then(
		refineResponse,
	);
};
// export const uploadMedia = (file: any) => {
//     const formData = new FormData();
//     formData.append('media', file);
//     const body = formData;
//     return fetch(`${baseUrl}media/upload`, {
//         method: 'POST',
//         body,
//         headers: { ...headers() },
//     });
// };
