import { createSlice } from "redux-starter-kit";
import Services from "../../../generic/Services";
import moment from "moment";

export interface CreateEventState {
	createEventRequested: boolean;
	createEventSucceeded: boolean;
	createEventFailure: boolean;

	eventRequested: boolean;
	eventSucceeded: boolean;
	eventFailure: boolean;

	listVenueDescriptionRequested: boolean;
	listVenueDescriptionSucceeded: boolean;
	listVenueDescriptionFailure: boolean;

	event: any | null;

	venueDescription: {
		status: string;
		server_message: string;
		user_message: string;
		items: [];
	};
	error: {
		code: number;
		message: string;
		errors: [];
	};
}

const initialState: CreateEventState = {
	createEventRequested: false,
	createEventSucceeded: false,
	createEventFailure: false,

	eventRequested: false,
	eventSucceeded: false,
	eventFailure: false,

	listVenueDescriptionRequested: false,
	listVenueDescriptionSucceeded: false,
	listVenueDescriptionFailure: false,

	event: null,
	venueDescription: {
		status: "",
		server_message: "",
		user_message: "",
		items: [],
	},
	error: {
		code: 0,
		message: "",
		errors: [],
	},
};

const createEventSlice = createSlice({
	slice: "createEvent",
	initialState,
	reducers: {
		createEventRequested(state, action) {
			state.createEventRequested = true;
			state.createEventSucceeded = false;
			state.createEventFailure = false;
		},
		createEventSucceeded(state, action) {
			state.createEventSucceeded = true;
			state.createEventRequested = false;
			state.createEventFailure = false;
		},
		createEventFailure(state, action) {
			state.createEventSucceeded = false;
			state.createEventRequested = false;
			state.createEventFailure = true;
			state.error = action.payload.err;
			state.event = action.payload.event
		},

		eventRequested(state, action) {
			state.eventRequested = true;
			state.eventSucceeded = false;
			state.eventFailure = false;
		},
		eventSucceeded(state, action) {
			state.eventSucceeded = true;
			state.eventRequested = false;
			state.eventFailure = false;
			state.event = action.payload.item;
		},
		eventFailure(state, action) {
			state.eventSucceeded = false;
			state.eventRequested = false;
			state.eventFailure = true;
			state.error = action.payload;
		},

		unMount(state, action) {
			state.event = null;
			state.createEventSucceeded = false;
		},

		listVenueDescriptionRequested(state, action) {
			state.listVenueDescriptionRequested = true;
			state.listVenueDescriptionSucceeded = false;
			state.listVenueDescriptionFailure = false;
		},
		listVenueDescriptionSucceeded(state, action) {
			state.listVenueDescriptionSucceeded = true;
			state.listVenueDescriptionRequested = false;
			state.listVenueDescriptionFailure = false;
			state.venueDescription = action.payload;
		},
		listVenueDescriptionFailure(state, action) {
			state.listVenueDescriptionSucceeded = false;
			state.listVenueDescriptionRequested = false;
			state.listVenueDescriptionFailure = true;
			state.error = action.payload;
		},
	},
});

export const {
	createEventRequested,
	createEventSucceeded,
	createEventFailure,

	eventRequested,
	eventSucceeded,
	eventFailure,

	unMount,

	listVenueDescriptionRequested,
	listVenueDescriptionSucceeded,
	listVenueDescriptionFailure,
} = createEventSlice.actions;

export default createEventSlice.reducer;

export const createBookerEvent = (event: any): any => async (dispatch: any) => {
	try {
		dispatch(createEventRequested());
		event = { ...event, date: moment(event.date).format("YYYY-MM-DD").toString()}
		const res = await Services.events.add(event);
		dispatch(createEventSucceeded(res));
	} catch (err) {
		dispatch(createEventFailure({err, event}));
	}
};

export const listVenueDescription = (): any => async (dispatch: any) => {
	try {
		dispatch(listVenueDescriptionRequested());
		const res = await Services.events.listVenueDescription();
		dispatch(listVenueDescriptionSucceeded(res));
	} catch (err) {
		dispatch(listVenueDescriptionFailure(err));
	}
};

export const listEventById = (eventId: any): any => async (dispatch: any) => {
	try {
		dispatch(eventRequested());
		const res = await Services.events.listEventById(eventId);
		dispatch(eventSucceeded(res));
	} catch (err) {
		dispatch(eventFailure(err));
	}
};
