import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Services from "../../../generic/Services";
import { RequestObject } from "../../TalentEvent/ListTalentEvents/listTalentEventsSlice";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import { useHistory } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        TALENT: {
            border: `5px solid ${theme.palette.secondary.main}`,
            width: 70,
            height: 70,
            margin: 15,
        },
        text: {
            width: "43%",
        },
        clickable: {
            cursor: "pointer",
        },
        top: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
        },
        Close: {
            display: "flex",
            justifyContent: "flex-start",
        },
        content: {
            textAlign: "center",
            padding: 0,
        },
        message: {
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            textAlign: "center",
            padding: "0px 32px",
        },
        actions: {
            display: "flex",
            justifyContent: "space-evenly",
        },
        confirmButton: {
            textDecoration: "underline",
            color: "red",
            fontWeight: "normal",
        },
        name: {
            maxWidth: "100%",
            wordBreak: "break-word",
        },
    })
);

export const changeText = (status: string) => {
    switch (status) {
        case "OPEN":
            return "New";
        case "ACCEPTED_BY_TALENT":
            return "Pending Confirmation";
        case "PENDING_DEPOSIT":
            return "Confirmed";
        case "PENDING_REMAINDER":
            return "Paid Deposit";
        case "APPROVED":
            return "Paid";
        case "COMPLETED":
            return "Completed";
        case "REJECTED_BY_TALENT":
            return "Rejected";
        case "CANCELED_BY_BOOKER":
            return "Canceled By You";
        case "CANCELED_BY_TALENT":
            return "Canceled By Talent";
        case "PENDING_FULL_PAYMENT":
            return "Pending Full Payment";
        default:
            return status;
    }
};

interface RequestsProps {
    id: number;
    status: string;
    openReviewTalentDialog: (band_id: number, event_id: number, band: any) => void;
}

const EventRequests: React.FC<RequestsProps> = ({ id, status, openReviewTalentDialog }) => {
    const classes = useStyles();

    const history = useHistory();

    const [requests, setRequests] = useState<RequestObject[]>([]);
    const [listAll, setListAll] = useState(false);
    const [page, setPage] = useState(1);
    const [noMoreFound, setNoMoreFound] = useState(false);
    const [loadOlderLoading, setLoadOlderLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [cancelID, setCancelID] = useState(0);

    useEffect(() => {
        Services.eventRequest.listEventRequests(id, page).then((res) => {
            if (res.items.length === 0) setNoMoreFound(true);
            setRequests((prevstat) => {
                if (page === 1) return [...res.items];
                return [...prevstat, ...res.items];
            });
        });
    }, [listAll, page, id]);

    const handleCancel = (requestID: number) => {
        Services.eventRequest.changeStatus(requestID, "CANCELED_BY_BOOKER").then((res) => {
            setListAll(!listAll);
        });
    };

    return (
        <Grid container direction="column" justify="space-between">
            {requests.map((request) => (
                <>
                    <Grid container key={request.id} alignItems="center">
                        <Box
                            width="40%"
                            display="flex"
                            alignItems="center"
                            className={classes.clickable}
                            onClick={() =>
                                history.push({
                                    pathname: "/account/messages",
                                    state: { new: true, requestId: request.id },
                                })
                            }>
                            <Avatar alt="My thumbnail" src={request.band.photo?.thumbnail} className={classes.TALENT} />
                            <Typography className={classes.name} variant="subtitle2">
                                {request.band.name}
                            </Typography>
                        </Box>
                        <Typography
                            className={`${classes.text} ${classes.clickable}`}
                            variant="body1"
                            onClick={() =>
                                history.push({
                                    pathname: "/account/messages",
                                    state: { new: true, requestId: request.id },
                                })
                            }>
                            {changeText(request.status)}
                        </Typography>
                        {request.status !== "CANCELED_BY_TALENT" &&
                            request.status !== "REJECTED_BY_TALENT" &&
                            request.status !== "CANCELED_BY_BOOKER" && (
                                <Button
                                    size="small"
                                    variant="text"
                                    onClick={() => {
                                        setOpen(true);
                                        setCancelID(request.id);
                                    }}>
                                    Cancel Request
                                </Button>
                            )}
                    </Grid>
                    {status === "PAST" && (
                        <Box mt={2}>
                            <Grid container spacing={2} justify="flex-end">
                                <Grid item>
                                    <Button
                                        size="small"
                                        variant="contained"
                                        color="secondary"
                                        onClick={() => openReviewTalentDialog(request.band_id, request.event_id, request.band)}>
                                        Leave a Review
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    )}
                </>
            ))}
            {!noMoreFound && requests.length >= 10 && (
                <Grid item>
                    <Button
                        variant="contained"
                        color="secondary"
                        disabled={loadOlderLoading}
                        onClick={() => {
                            setLoadOlderLoading(true);
                            setPage((prevstate) => prevstate + 1);
                        }}>
                        Load More
                    </Button>
                </Grid>
            )}
            <Dialog onClose={() => setOpen(false)} classes={{ scrollPaper: classes.top }} aria-labelledby="simple-dialog-title" open={open}>
                <DialogActions className={classes.Close}>
                    <Button onClick={() => setOpen(false)}>
                        <CloseIcon />
                    </Button>
                </DialogActions>
                <DialogContent className={classes.content}>
                    <Typography variant="h4" id="simple-dialog-title">
                        ARE YOU SURE YOU WANT TO CANCEL?
                    </Typography>
                    <Typography className={classes.message} variant="body1" color="inherit" id="simple-dialog-description">
                        Please see our cancelation policyin the&nbsp;<p style={{ margin: 0, textDecoration: "underline" }}>FAQ section.</p>You could
                        still incur charges depending on when you are canceling your event
                    </Typography>
                </DialogContent>
                <DialogActions className={classes.actions}>
                    <Button
                        className={classes.confirmButton}
                        onClick={() => {
                            setOpen(false);
                            handleCancel(cancelID);
                        }}
                        variant="text">
                        Yes. Cancel my event.
                    </Button>
                    <Button style={{ textDecoration: "underline", fontWeight: "normal" }} onClick={() => setOpen(false)} variant="text">
                        Keep my event!
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
};

export default EventRequests;
