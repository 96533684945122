import { connect } from "react-redux";
import { TermsAndConditions } from "./TermsAndCondition";
import { IAppState } from "../../generic/interfaces";
import { ThunkDispatch } from "redux-thunk";
import { listTermsAndConditions } from "./termsAndConditionsSlice";

const mapStateToProps = (state: IAppState) => ({
	// termsAndConditions: state.termsAndConditionsPage.termsAndConditions,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
	listTermsAndConditions: () => {
		dispatch(listTermsAndConditions());
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(TermsAndConditions);
