import React, { useEffect } from "react";
import { Box, Typography } from "@material-ui/core";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { RouteComponentProps } from "react-router-dom";
import Services from "../../generic/Services";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		paragraphFont: {
			fontSize: "15px",
			color: "#ddd",
		},
	}),
);

export interface TermsAndConditionsProps {
	termsAndConditions?: any;
	setLoc: (location: string) => void;
	listTermsAndConditions: () => void;
}

export const TermsAndConditions: React.FC<TermsAndConditionsProps &
	RouteComponentProps> = ({
	setLoc,
}) => {
	const classes = useStyles();
	const [data, setData] = React.useState<any>();

	useEffect(() => {
		setLoc("/account/terms-conditions");
		Services.termsAndConditions.listTermsAndConditions().then(response => {
			setData(response.items[0])
		})
	}, [setLoc]);
	
	return (
		<>
			{data ? (
				<>
					<Box mb="30px" fontWeight="bold" fontFamily="Rubik" fontSize="30px">
						{data.page_name}
					</Box>
					<Typography variant="body2" className={classes.paragraphFont}>
						{data.body}
					</Typography>
				</>
			) : <> </>}
		</>
	);
};
