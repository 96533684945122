import {
	IConfirmationDialogActions,
	ConfirmationDialogActionType,
} from "./interfaces";
import { IError } from "../Base/ErrorMessageBase";
import { combineReducers } from "redux";

const confirmationDialogRequest = (
	state: boolean = false,
	action: IConfirmationDialogActions,
) => {
	switch (action.type) {
		case ConfirmationDialogActionType.RESEND_CONFIRMATION_REQUEST:
			return true;
		case ConfirmationDialogActionType.RESEND_CONFIRMATION_SUCCESS:
		case ConfirmationDialogActionType.RESEND_CONFIRMATION_FAILURE:
			return false;
		default:
			return state;
	}
};

const confirmationDialogSuccess = (
	state: boolean = false,
	action: IConfirmationDialogActions,
) => {
	switch (action.type) {
		case ConfirmationDialogActionType.RESEND_CONFIRMATION_SUCCESS:
			return true;
		case ConfirmationDialogActionType.RESEND_CONFIRMATION_REQUEST:
		case ConfirmationDialogActionType.RESEND_CONFIRMATION_FAILURE:
			return false;
		default:
			return state;
	}
};

const error = (
	state: IError | null = null,
	action: IConfirmationDialogActions,
) => {
	switch (action.type) {
		case ConfirmationDialogActionType.RESEND_CONFIRMATION_FAILURE:
			return action.payload;
		case ConfirmationDialogActionType.RESEND_CONFIRMATION_REQUEST:
		case ConfirmationDialogActionType.RESEND_CONFIRMATION_SUCCESS:
			return null;
		default:
			return state;
	}
};

export const confirmationDialog = combineReducers({
	confirmationDialogRequest,
	confirmationDialogSuccess,
	error,
});
