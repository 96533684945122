import {
	ListEventRequestActionType,
	IListEventRequestAction,
	IItemEventRequestReducer,
} from "../interfaces";
import { cancelRequestReducer } from "./booker/cancel";
import { confirmRequestReducer } from "./booker/confirm";
import { approveRequestReducer } from "./talent/approve";
import { rejectRequestReducer } from "./talent/reject";

const updatedModalOpened = (state = false, action: IListEventRequestAction) => {
	switch (action.type) {
		case ListEventRequestActionType.UPDATED_MODAL_OPEN:
			return true;
		case ListEventRequestActionType.UPDATED_MODAL_CLOSE:
			return false;
		default:
			return state;
	}
};

const updateIsRequested = (state = false, action: IListEventRequestAction) => {
	switch (action.type) {
		case ListEventRequestActionType.UPDATE_BOOKER_REQUEST:
			return true;
		case ListEventRequestActionType.UPDATE_BOOKER_SUCCESS:
		case ListEventRequestActionType.UPDATE_BOOKER_FAILURE:
			return false;
		default:
			return state;
	}
};

const error = (state = null, action: IListEventRequestAction) => {
	switch (action.type) {
		case ListEventRequestActionType.UPDATE_BOOKER_FAILURE:
			return action.payload;
		case ListEventRequestActionType.UPDATE_BOOKER_REQUEST:
			return null;
		default:
			return state;
	}
};

const getFullItemIsRequested = (
	state = false,
	action: IListEventRequestAction,
) => {
	switch (action.type) {
		case ListEventRequestActionType.GET_FULL_ITEM_REQUEST:
			return true;
		case ListEventRequestActionType.GET_FULL_ITEM_SUCCESS:
		case ListEventRequestActionType.GET_FULL_ITEM_FAILURE:
			return false;
		default:
			return state;
	}
};

const fullItem = (state = null, action: IListEventRequestAction) => {
	switch (action.type) {
		case ListEventRequestActionType.GET_FULL_ITEM_SUCCESS:
			return action.payload.response.items;
		case ListEventRequestActionType.GET_FULL_ITEM_REQUEST:
		case ListEventRequestActionType.GET_FULL_ITEM_FAILURE:
			return null;
		default:
			return state;
	}
};

const fullItemError = (state = null, action: IListEventRequestAction) => {
	switch (action.type) {
		case ListEventRequestActionType.GET_FULL_ITEM_FAILURE:
			return action.payload.response;
		case ListEventRequestActionType.GET_FULL_ITEM_REQUEST:
		case ListEventRequestActionType.GET_FULL_ITEM_SUCCESS:
			return null;
		default:
			return state;
	}
};

export const itemBookerRequestReducer = (
	state: IItemEventRequestReducer,
	action: IListEventRequestAction,
) => {
	return {
		updatedModalOpened: updatedModalOpened(
			state && state.updatedModalOpened,
			action,
		),
		updateIsRequested: updateIsRequested(
			state && state.updateIsRequested,
			action,
		),
		error: error(state && state.error, action),
		getFullItemIsRequested: getFullItemIsRequested(
			state && state.getFullItemIsRequested,
			action,
		),
		fullItem: fullItem(state && state.fullItem, action),
		fullItemError: fullItemError(state && state.fullItemError, action),
		cancel: cancelRequestReducer(state && state.cancel, action),
		confirm: confirmRequestReducer(state && state.confirm, action),
		approve: approveRequestReducer(state && state.approve, action),
		reject: rejectRequestReducer(state && state.reject, action),
	};
};
