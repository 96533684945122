// @ts-nocheck

import React from "react";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";
import { IImageUploaderProps } from "./interfaces";
import { getFile } from "../generic/Services/media";
import { Typography } from "@material-ui/core";
import { CloudUploadOutlined } from "@material-ui/icons";
import { ILayoutProps } from "react-dropzone-uploader";
import "./styles.css";

class HospitalityProducionUploader extends React.Component<
	IImageUploaderProps
> {
	_isMounted = false;
	state = {
		file: undefined,
	};

	componentDidMount() {
		this._isMounted = true;

		if (this.props.url) {
			const promises = [getFile(this.props.url)];
			Promise.all(promises).then(responses => {
				if (this._isMounted) {
					this.setState({ file: responses });
				}
			});
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	handleChangeStatus = (temp: any, status: any) => {
		if (status === "removed") {
			this.props.handleChange(null);
		} else if (status === "done") {
			this.props.handleChange(temp.file);
		}
	};

	render() {
		const { file } = this.state;
		const { url } = this.props;
		return (
			<Dropzone
				addClassNames={{
					dropzone: "dzu-dropzone-production",
					preview: "dzu-previewContainer-production",
					inputLabel: "input-label-production",
				}}
				initialFiles={file}
				LayoutComponent={(props: ILayoutProps) => {
					return (
						<>
							{props.previews === null
								? null
								: props.previews[props.previews.length - 1]}
							{props.files.length === 0 && (
								<div
									className="dzu-dropzone-production"
									{...props.dropzoneProps}
								>
									{url ? (
										<a
											className="link"
											href={url}
											target="_blank"
											rel="noopener noreferrer"
										>
											View File
										</a>
									) : (
										<Typography>Upload File</Typography>
									)}
									{props.input}
								</div>
							)}
						</>
					);
				}}
				inputContent={
					<CloudUploadOutlined
						fontSize="large"
						color="secondary"
						key={2}
					/>
				}
				onChangeStatus={this.handleChangeStatus}
				maxSizeBytes={10000000}
				accept="application/pdf, .doc, .docx, application/msword, image/*"
				multiple={false}
				submitButtonContent={null}
				canRemove={true}
			/>
		);
	}
}

export default HospitalityProducionUploader;
