import React from "react";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import createStyles from "@material-ui/core/styles/createStyles";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Services from "../../generic/Services";
import { IAuthReducer } from "../../AuthBase";
import { Conversation } from "./Conversation";
import { IThread } from "./Interfaces";
import { Thread } from "./Thread";
import { Filters } from "./Filters";
import { useHistory, useParams } from "react-router-dom";
import { debounce } from "lodash";
import { BandRequestState } from "../../BandPage/BandRequest/bandRequestSlice";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		ExpansionPanel: {
			borderBottom: "1px solid rgba(100, 254, 204, 0.5)",
		},
		BOOKER: {
			border: `5px solid ${theme.palette.primary.main}`,
			width: 70,
			height: 70,
			margin: "auto",
			"@media screen and (max-width: 640px)": {
                width: 60,
                height: 60,
				border: `4px solid ${theme.palette.primary.main}`,
            },
			"@media screen and (max-width: 480px)": {
                width: 50,
                height: 50,
				border: `3px solid ${theme.palette.primary.main}`,
            },
		},
		TALENT: {
			border: `5px solid ${theme.palette.secondary.main}`,
			width: 70,
			height: 70,
			margin: "auto",
			"@media screen and (max-width: 640px)": {
                width: 60,
                height: 60,
				border: `4px solid ${theme.palette.secondary.main}`,
            },
			"@media screen and (max-width: 480px)": {
                width: 50,
                height: 50,
				border: `3px solid ${theme.palette.secondary.main}`,
            },
		},
		AGENT: {
			border: `5px solid #ffffff`,
			width: 70,
			height: 70,
			margin: "auto",
			"@media screen and (max-width: 640px)": {
                width: 60,
                height: 60,
				border: `4px solid #ffffff`,
            },
			"@media screen and (max-width: 480px)": {
                width: 50,
                height: 50,
				border: `3px solid #ffffff`,
            },
		},
		ADMIN: {
			width: 70,
			height: 70,
			margin: "auto",
			"@media screen and (max-width: 640px)": {
                width: 60,
                height: 60,
            },
			"@media screen and (max-width: 480px)": {
                width: 50,
                height: 50,
            },
		},
		Layout: {
			textAlign: "end",
			flexDirection: "row-reverse",
		},
		divider: {
			backgroundColor: theme.palette.secondary.main,
			opacity: 0.5,
		},
		select: {
			paddingRight: theme.spacing(5),
			fontWeight: 600,
		},
		margin: {
			margin: "0px",
		},
	}),
);

export interface MessageProps {
	authentication?: IAuthReducer;
	setPage: (page: string) => void;
	unread: number[];
	markAsSeen: (id: number) => void;
	newMessage: (id: number) => void;
	setSelectedEvent: (eventItem: any) => void;
	handleOpen: () => void;
	bandRequest: BandRequestState;
	handleClose: () => void;
	handleBandRequestSubmit: (values: any) => void;
	listBookerEventsList: () => void;
	user: any;
	states: any

}

interface Filters {
	text: string;
	filter: {};
}

const intialFilters: Filters = {
	text: "",
	filter: {},
};

export const intialPagination = {
	page: 1,
	total_items: 1,
};

export const Messages: React.FC<MessageProps> = ({ authentication, setPage, unread, markAsSeen, newMessage, setSelectedEvent, handleOpen, bandRequest, handleClose, handleBandRequestSubmit, listBookerEventsList, user, states }) => {
	const classes = useStyles();
	const history = useHistory<any>();

	// Expansion Panel
	const [expanded, setExpanded] = React.useState<number | false>();
	const handleChange = (id: number) => (event: any, newExpanded: boolean) => {
		setExpanded(newExpanded ? id : false);
		markAsSeen(id)
		Services.eventRequest.updateLastSeen(id)
	};

	// Handle Pagination
	const [pagination, setPagination] = React.useState<{ page: number; total_items: number }>(intialPagination);
	const [noMoreFound, setNoMoreFound] = React.useState<boolean>(false);

	// Handle Thread API Data
	const [data, setData] = React.useState<IThread[]>([]);
	const [targetRequest, setTargetRequest] = React.useState<IThread>();
	const [loading, setLoading] = React.useState<boolean>(true);
	const [error, setError] = React.useState();
	const [dateFrom, setDateFrom] = React.useState();

	const [getNew, setGetNew] = React.useState(true);

	// Handle Filters
	const [filters, setFilters] = React.useState<Filters>(intialFilters);
	const handleClick = (text: any) => {
		setPagination({ ...pagination, page: 1 });
		setNoMoreFound(false);
		setFilters({ ...filters, text });
	};

	const interActiveSearch = debounce((text: string) => {
		setPagination({ ...pagination, page: 1 });
		setNoMoreFound(false);
		setFilters({ ...filters, text });
	}, 300)

	const { bandId } = useParams();
	if(bandId){
		filters.filter['agent_band_id'] = bandId;
	}



	// Call API Again on Filters change (or) Page change
	React.useEffect(() => {
		Services.eventRequest
			.listThreads({ sort: { by: "MESSAGES" }, page: pagination.page, items_per_page: 5, filter: filters })
			.then((res: any) => {
				const filteredData = res.items.filter((item: any) => item.last_message !== null);
				if (filteredData.length === 0) {
					setNoMoreFound(true);
				}
				setData((prevState) => {
					if (pagination.page === 1) {
						return [...filteredData];
					}
					return [...prevState, ...filteredData];
				});
				setDateFrom(filteredData[0]?.last_message.created_at + 1)
				setPagination({ page: res.page, total_items: res.total_items });
				setLoading(false);
				filteredData.map((item: any) => {
					if(authentication?.user.role === "BOOKER"){
						if(item.last_message.created_by.id !== authentication?.user.id && item.last_message.created_at > item.booker_last_seen && item.booker_last_seen !== null){
							newMessage(item.id)
						}
					} else if(authentication?.user.role === "TALENT"){
						if(item.last_message.created_by.id !== authentication?.user.id && item.last_message.created_at > item.talent_last_seen && item.talent_last_seen !== null){
							newMessage(item.id)
						}
					} else if(authentication?.user.role === "AGENT"){
						if(item.last_message.created_by.id !== authentication?.user.id && item.last_message.created_at > item.agent_last_seen && item.agent_last_seen !== null){
							newMessage(item.id)
						}
					}
					return item;
				})
				return Services.eventRequest.listThreads({ sort: { by: "MESSAGES" }, page: pagination.page, items_per_page: res.total_items, filter: filters });
			}).then((res) => {
				const filteredData = res.items.filter((item: any) => item.last_message !== null);
				if (history.location.state && history.location.state.new) {
					const existInThreads = filteredData.filter((item: any) => Number(item.id) === Number(history.location.state.requestId));
					if (existInThreads.length > 0) {
						setData((prevState) => {
							const existInData = prevState.filter((item: any) => Number(item.id) === Number(history.location.state.requestId));
							if (existInData.length > 0) {
								return prevState
							}else{
								return [...existInThreads, ...prevState];
							}
						});
						setExpanded(existInThreads[0].id);
					} else {
						const thread = res.items.filter((item: any) => Number(item.id) === Number(history.location.state.requestId))
						setTargetRequest(thread[0])
						setExpanded(thread[0].id);
					}
				}
			})
			.catch((err: any) => {
				setError(err);
				setLoading(false);
			});
		setPage("messages");
	}, [pagination.page, filters, pagination.total_items, setPage, authentication, newMessage, history.location.state]);

	React.useEffect(() => {
		const time = setTimeout(() => setGetNew(!getNew), 30000)
		Services.eventRequest.listThreads({ sort: { by: "MESSAGES" }, page: 1, items_per_page: pagination.page * 5, filter: { ...filters, filter: { ...filters.filter, date_to: Date.now() / 1000, date_from: dateFrom }} }).then((res) => {
			const filteredData = res.items.filter((item: any) => item.last_message !== null);
			filteredData.map((item: any) => {
				setData(prevstate => {
					const index = prevstate.findIndex(ele => ele.id === item.id);
					if(index === -1) {
							prevstate.splice(-1, 1)
							return [ item, ...prevstate]
					} else {
							prevstate.splice(index, 1)
							return [ item, ...prevstate]
					}
				})
				if(authentication?.user.role === "BOOKER"){
					if(item.last_message.created_by.id !== authentication?.user.id && item.last_message.created_at > item.booker_last_seen && item.booker_last_seen !== null){
						newMessage(item.id)
					}
				} else if (authentication?.user.role === "TALENT") {
					if(item.last_message.created_by.id !== authentication?.user.id && item.last_message.created_at > item.talent_last_seen && item.talent_last_seen !== null){
						newMessage(item.id)
					}
				} else if (authentication?.user.role === "AGENT") {
					if(item.last_message.created_by.id !== authentication?.user.id && item.last_message.created_at > item.agent_last_seen && item.agent_last_seen !== null){
						newMessage(item.id)
					}
				}
				return item;
			})
		})
		return () => clearTimeout(time)
	}, [authentication, dateFrom, filters, getNew, newMessage, pagination.page]);

	if (loading) {
		return <div>loading...</div>;
	}

	if (error) {
		return <div>error</div>;
	}

	return (
		<>
			<Box mb="30px" fontWeight="bold" fontFamily="Rubik" fontSize="30px">
				Message Center
			</Box>
			<Filters handleClick={handleClick} interactiveSearch={interActiveSearch} />
			{data?.length === 0 && <Box mt={3}>No Results</Box>}
			{targetRequest && (
				<ExpansionPanel
					key={targetRequest.id}
					TransitionProps={{ unmountOnExit: true }}
					className={classes.ExpansionPanel}
					onChange={handleChange(targetRequest.id)}
					expanded={expanded === targetRequest.id}>
					<ExpansionPanelSummary>
						<Thread
							classes={classes}
							lastMessage={targetRequest}
							role={authentication?.user.role}
							open={expanded === targetRequest.id}
							unread={unread}
						/>
					</ExpansionPanelSummary>
					<ExpansionPanelDetails>
						<Conversation
							classes={classes}
							eventRequestId={targetRequest.id}
							authentication={authentication?.user}
							id={authentication?.user.id}
							setSelectedEvent={setSelectedEvent}
							handleOpen={handleOpen}
							bandRequest={bandRequest}
							handleClose={handleClose}
							handleBandRequestSubmit={handleBandRequestSubmit}
							listBookerEventsList={listBookerEventsList}
							user={user}
							states={states}
						/>
					</ExpansionPanelDetails>
				</ExpansionPanel>
			)}
			{data?.map((item: IThread) => (
				<ExpansionPanel
					key={item.id}
					TransitionProps={{ unmountOnExit: true }}
					className={classes.ExpansionPanel}
					onChange={handleChange(item.id)}
					expanded={expanded === item.id}>
					<ExpansionPanelSummary>
						<Thread
							classes={classes}
							lastMessage={item}
							role={authentication?.user.role}
							open={expanded === item.id}
							unread={unread}
						/>
					</ExpansionPanelSummary>
					<ExpansionPanelDetails>
						<Conversation
							classes={classes}
							eventRequestId={item.id}
							authentication={authentication?.user}
							id={authentication?.user.id}
							setSelectedEvent={setSelectedEvent}
							handleOpen={handleOpen}
							bandRequest={bandRequest}
							handleClose={handleClose}
							handleBandRequestSubmit={handleBandRequestSubmit}
							listBookerEventsList={listBookerEventsList}
							user={user}
							states={states}
						/>
					</ExpansionPanelDetails>
				</ExpansionPanel>
			))}
			{/* Check if there is more data */}
			{data && data.length < pagination.total_items && (
				<Box m={4} display="flex" justifyContent="center" alignItems="center">
					<Button
						variant="contained"
						color="secondary"
						disabled={noMoreFound}
						// Increment Page on Load More
						onClick={() => setPagination({ ...pagination, page: pagination.page + 1 })}>
						Load More
					</Button>
				</Box>
			)}
		</>
	);
};
