import { IError } from "../Base/ErrorMessageBase";

export enum ForgetPasswordActionType {
	FORGET_PASSWORD_MODAL_OPEN = "FORGET_PASSWORD_MODAL_OPEN",
	FORGET_PASSWORD_MODAL_CLOSE = "FORGET_PASSWORD_MODAL_CLOSE",
	FORGET_PASSWORD_REQUEST = "FORGET_PASSWORD_REQUEST",
	FORGET_PASSWORD_SUCCESS = "FORGET_PASSWORD_SUCCESS",
	FORGET_PASSWORD_FAILURE = "FORGET_PASSWORD_FAILURE",
}

export interface IForgetPasswordActions {
	type: ForgetPasswordActionType;
	payload: any;
}

export interface IForgetPasswordReducer {
	forgetPasswordModalOpened: boolean;
	forgetPasswordRequest: boolean;
	forgetPasswordSuccess: boolean;
	error: IError;
}

export interface IForgetPasswordProps {
	forgetPassword: IForgetPasswordReducer;
	handleClose: () => any;
	handleForgetPasswordSubmit: (data: { email: string }) => any;
	classes: any;
}
