import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

interface DiscardDialogProps {
	open: boolean;
	handleClose: any;
	resetForm: any;
}

export const DiscardDialog: React.FC<DiscardDialogProps> = ({ open, resetForm, handleClose }) => (
	<Dialog
		open={open}
		onClose={handleClose}
		aria-labelledby="alert-dialog-title"
		aria-describedby="alert-dialog-description">
		<DialogContent>
			<DialogContentText color="textPrimary" id="alert-dialog-description">
				Do you want to discard these changes?
			</DialogContentText>
		</DialogContent>
		<DialogActions>
			<Button
				onClick={() => {
					resetForm();
					handleClose();
				}}
				color="default">
				Discard
			</Button>
			<Button onClick={handleClose} color="default" autoFocus>
				Cancel
			</Button>
		</DialogActions>
	</Dialog>
);
