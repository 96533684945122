import React from "react";
import "./style.css";

export const UsaMap = (props: any) => {
	return (
		<svg id="usa-svg" data-name="Usa svg" viewBox="0 0 914 599">
			<defs>
				<clipPath id="clip-path-2">
					<path
						className="usa-cls-1"
						d="M1686.66-148.11a11.22,11.22,0,0,0,3.14-7.6c0-5.53-4.42-10.26-10.69-12.24a11.48,11.48,0,0,1,.11,1.66c0,8.75-8.9,15.87-19.83,15.87a25.07,25.07,0,0,1-2.62-.16c2.6,4.75,8.72,8.09,15.84,8.09a20.86,20.86,0,0,0,9.2-2.07,14.51,14.51,0,0,0,7.32,2.07.66.66,0,0,0,.61-.4.66.66,0,0,0-.13-.72A18.34,18.34,0,0,1,1686.66-148.11Zm-10.08-18.18c0-7.3-7.7-13.22-17.19-13.22s-17.19,5.92-17.19,13.22a11.2,11.2,0,0,0,3.14,7.6,18.75,18.75,0,0,1-2.95,4.51.66.66,0,0,0-.13.72.66.66,0,0,0,.61.39,14.52,14.52,0,0,0,7.32-2.06,21.13,21.13,0,0,0,9.2,2.06C1668.88-153.07,1676.58-159,1676.58-166.29Z"
					/>
				</clipPath>
			</defs>
			<title>usa</title>
			<path
				className={`usa-cls-2 ${
					props.willingToTravel.includes("Alabama") ? "selected" : ""
				}`}
				d="M646.31,360.28l7.5,25.37,6.61,20.24,3.32,6.24,2,3.54-2.49,4-.19,6.77,1.44,3.83v3.75l-.08,3.49,1.46,2.54,1.19,2.37-36.86,4.19L620,448.83l-.14,1.62,4.62,4.73-.57,4.24-1,3-16.42-.9-3.25-32.06.29-33.77.76-27.37-1.58-3.95,21.6-2Z"
			>
				<title>Alabama</title>
			</path>
			<path
				className={`usa-cls-2 ${
					props.willingToTravel.includes("Arkansas") ? "selected" : ""
				}`}
				d="M575.4,345l0,1.75-1.53,3.06L571,352l-.42,3.51L568,358.47l.6,6.08-1.84,2.08-.22,1.68-3,1.75.12,3-2,5.74-1.94,1.31-2.91,3-1.6,4.42-3.42,7.53-.14,5,2.32,5.41-.63,4.17-15,0-19.32,1.44-17.11.41.77-11.94h-4.07l-3.41.34-.95-1.36.19-18.37,0-20.41-3.74-22.09,20.55-.15L530,337l17.83-.82,19.31.15,1.57,2.5L567,341.2l-1.75,2.38-.92,2.14Z"
			>
				{" "}
				<title>Arkansas</title>
			</path>
			<path
				className={`usa-cls-2 ${
					props.willingToTravel.includes("Colorado") ? "selected" : ""
				}`}
				d="M370.26,262.22l-2,32.2-2.06,30.67-16.11-1.23-20-1.71-28.41-2.85-26.35-3.18-26.05-3.53,11.86-83,15.76,2.18,15.77,2,31.6,3.49,15.83,1.49,31.61,2.45-1.4,20.3Z"
			>
				{" "}
				<title>Colorado</title>
			</path>
			<path
				className={`usa-cls-2 ${
					props.willingToTravel.includes("Connecticut")
						? "selected"
						: ""
				}`}
				d="M841.67,173.19l3.14,11-.12,3.22-6.47,2.83-8.62,3.34-10.76,7.85-1.18-2.34,2.85-2.83-1.65-1.42-3.07-15.76,11.69-2.61Z"
			>
				{" "}
				<title>Connecticut</title>
			</path>
			<path
				className={`usa-cls-2 ${
					props.willingToTravel.includes("Maryland") ? "selected" : ""
				}`}
				d="M776,258.54l-.17-1.22-1.71-1.48,1.09-1.25,2.48,1.69Z"
			>
				{" "}
				<title>Maryland</title>
			</path>
			<path
				className={`usa-cls-2 ${
					props.willingToTravel.includes("Delaware") ? "selected" : ""
				}`}
				d="M797.43,232.22l-1.84,2.67,1.31,3.83L802.73,249l6.77,9.42-10.69,2.46-7-25.76.84-1.89,1.21-1.23Z"
			>
				{" "}
				<title>Delaware</title>
			</path>
			<path
				className={`usa-cls-2 ${
					props.willingToTravel.includes("Florida") ? "selected" : ""
				}`}
				d="M731.25,439l4.82,14.84,9.77,16,10.94,14.29,1.41,8.17,11.9,20.08L774.21,531l-.74,19L766,555.11l-9.92-1-4.56-8.79-8.07-3.7L730.55,525,719,511l-3.19-9.88,1.3-13.27-6-11.14L697.4,465.82l-11.5-6.57-19.48,11.4-4.91-5.39-18.31-7.54-20.36,4.71,1-3,.57-4.24-4.62-4.73.14-1.62,10.24-2.19,36.86-4.19,3.44,5.31,18.33-1.39,29.79-1.08,1.9,3.44,2.05-2.23-1.08-7.26,2.06-1.05,3.81,1Z"
			>
				{" "}
				<title>Florida</title>
			</path>
			<path
				className={`usa-cls-2 ${
					props.willingToTravel.includes("Georgia") ? "selected" : ""
				}`}
				d="M690,354.8l-1.63,2-2.19,4.33,5.12,2.79,3.17.83,4.17,6.11,2.6,3.38,6.78,4,1.58,2.4,4.65,2.74L717,388l6.26,3.31,2,4.54L726.7,398l-.36,1.63,3.65,1.73,2.38,3.61.62,4,1.75.5L738,410l-5.22,16.52L731.25,439l-3.87.22-3.81-1-2.06,1.05,1.08,7.26-2.05,2.23-1.9-3.44-29.79,1.08-18.33,1.39-3.44-5.31-1.19-2.37-1.46-2.54.08-3.49V430.3l-1.44-3.83.19-6.77,2.49-4-2-3.54-3.32-6.24-6.61-20.24-7.5-25.37,13.24-1.38,9.11-1.37Z"
			>
				{" "}
				<title>Georgia</title>
			</path>
			<path
				className={`usa-cls-2 ${
					props.willingToTravel.includes("Iowa") ? "selected" : ""
				}`}
				d="M540.58,190.13l.28,1.14,2.17,3.22-1.28,1.66.28,4.41.73,1.83,1.18,3.28,5.16,1.73,1.69,3.11,1,1.54,1.91,1,.87,2.22,2.55,1.52,1.73,1.71-.48,5.68-2.61,4.92-1,1.57-3.51,1.42-5.35,1.34-1.22,3.67,2,1.53.85,3.2-1.87,3.74-.89,3.23-3.92,3.32-.13,3.84-2.27-1.64L535.31,251l-17.42,1.17-18.36.68-14.31.28-14.34.09-1.06-3.8.44-3.75-.35-3.61-1.64-6-1-2.51-1.14-.68L466,228l-1-3.47-2.83-4,.05-1.73-1-3.47-.69-2.09,0-1.92L458.09,209l1.26-3.43.83-3.36.37-2.28-1.94-2.8.05-5.13h2l16.72,0,20.79-.58,22.32-.8Z"
			>
				{" "}
				<title>Iowa</title>
			</path>
			<path
				className={`usa-cls-2 ${
					props.willingToTravel.includes("Idaho") ? "selected" : ""
				}`}
				d="M241.4,152.09l.47.46-8.62,51.53-45.94-8.27-45.73-10.14L150.44,149l2.66-5.51-.56-3-2.43-2,.81-3.27,3.16-4.16,4.1-3.32,3.62-6.14,3.17-4.84,2.08-2.35-.05-3.29-2-2.23-2.46-4.74,1-3.63-.57-3.45,6.35-28.8,6.16-26.43,13.44,3.07-3.77,22,2.46,6.31L186.72,77l1.88,3.88,2.83,2.67,1.67,3.71,2.58,7.24,3,3.77L203,99.35l-2.52,4.27L198,108.78l.11,5.57-1.95,1-1,5.32,2.17,2.58,3.37-1.87,2.07-2.62,1.65.93,1.17,3.18.38,6.87,2.08,3.09-.12,5.59.6,1.68,2.9,1.09,1.25,3.35,1.61,5.87,3.26-2.36,4.72,1.65.77-2,8.13,2.78,3.52-.07,2.83-3.76,1.5.84.95,3.16Z"
			>
				{" "}
				<title>Idaho</title>
			</path>
			<path
				className={`usa-cls-2 ${
					props.willingToTravel.includes("Illinois") ? "selected" : ""
				}`}
				d="M594.74,207.41v.05l.61,5.43,5.22,10.59h0l2.24,24.17,2.24,24.15-1.3,6,1.47,1.4,1.2,3.48.13,2.82-1.25,1.39-.87,3.57-3,4.85-1.81,5.81-.16,4.27.25,1.54L598.1,310l1.58,1.84-2.78,1.77-3.62,2.07,1,4.51-2,1.9-4.19-1.61-4.53-.78-1,2.11.88,3.11-3.21-3-1.76.67-2.51-5,.38-3.17-1.59-5-1.72-.5-4.65-4.29-1.89,0L563,302l-2.53-3,.83-3.74,1.33-3.14.68-3.91L560,285l-3,1.54-1.91-.76-.47-3.49L553,278.4l-1.55-.87-3.9-2.6-3.27-3.39-2.9-4.17-1.75-6.18,1.14-5.27.13-3.84,3.92-3.32.89-3.23,1.87-3.74-.85-3.2-2-1.53,1.22-3.67,5.35-1.34,3.51-1.42,1-1.57,2.61-4.92.48-5.68-1.73-1.71-2.55-1.52-.87-2.22-1.91-1-1-1.54,15.81-1,16.17-1.19,11.93-.84v-.05Z"
			>
				{" "}
				<title>Illinois</title>
			</path>
			<path
				className={`usa-cls-2 ${
					props.willingToTravel.includes("Indiana") ? "selected" : ""
				}`}
				d="M643.12,219.5l2.28,18.83,2.38,20.25L649.46,273l-.8,1.1,1.83,4.08-.51,1.62-2.73.37-2.32,2.14L641,282l.12,4L639,287.83l-1.75,3.83-2.25.91-2.93,6.59-3.53-1.42-1.3-2.44-2.43,4.45-1.59,2.51-3.79-2.08-3.61,2.36-1,2.15L609.36,302,606,304.62l-4.41-1.23,0,2.32-2.19-.35.16-4.27,1.81-5.81,3-4.85.87-3.57,1.25-1.39-.13-2.82-1.2-3.48-1.47-1.4,1.3-6-2.24-24.15-2.24-24.17h0l1.57,1.28,5.31-.63,4-2.72h0l16.81-1.7,14.66-1.83Z"
			>
				{" "}
				<title>Indiana</title>
			</path>
			<path
				className={`usa-cls-2 ${
					props.willingToTravel.includes("Kansas") ? "selected" : ""
				}`}
				d="M490.76,328.12l-14.74.17-30.23-.1-30.24-.69-16.78-.64L382,326l-15.79-.94,2.06-30.67,2-32.2h0l28.11,1.6,20.92.84,37,.75h22.18l3.85,2.76,2.1-.07.5,3L482.74,275l1.26,2,2.06,4.38,4.31,1.92.16,22.43Z"
			>
				{" "}
				<title>Kansas</title>
			</path>
			<path
				className={`usa-cls-2 ${
					props.willingToTravel.includes("Kentucky") ? "selected" : ""
				}`}
				d="M687.71,282.39l.6,1.92.46,4.31,2.44,2.94,1.26,3,2.81,2.33,2,2.25,3.27-.11-5.67,7.18-4.81,3.19-.16,1.74-1.44,2.44-4.2,3.13L683.09,319l-3.26,1.77-4.66,1.84-11.24,2.19L649,325.7l-4.79.82-9.69.5-9.85.88-9.05.79-10.46,1.57-.63-.91-3.33.35.34,3.27-24.1,1.73,3.62-2.47,2.54-1.94-.57-3.26.36-2.14-.88-3.11,1-2.11,4.53.78,4.19,1.61,2-1.9-1-4.51,3.62-2.07,2.78-1.77L598.1,310l1.62-3.07-.25-1.54,2.19.35,0-2.32,4.41,1.23,3.33-2.61,5.39,2.68,1-2.15,3.61-2.36,3.79,2.08,1.59-2.51,2.43-4.45,1.3,2.44,3.53,1.42,2.93-6.59,2.25-.91,1.75-3.83L641.1,286l-.12-4,3.95.29,2.32-2.14,2.73-.37.51-1.62-1.83-4.08.8-1.1,5.6-.21,3.12,1.63,4.93,4.13,3.64,1,2.73,1.34,3.8-1.11,3,.8,3.32-1.64,3-.8,1.72,2.71Z"
			>
				{" "}
				<title>Kentucky</title>
			</path>
			<path
				className={`usa-cls-2 ${
					props.willingToTravel.includes("Maryland") ? "selected" : ""
				}`}
				d="M791.76,235.07l7,25.76,10.69-2.46,1.33,1.64-4.57,8.43-3.67,1.17-1,1.69-10.39-7.92-4.08-15.19-1,7.77,4.1,17.66-11-4-1.55-2.68-2.44,1.44-2.37-.67.87-4.95,2.34-2.5-.07-1.72,1.69-2.26-2.48-1.69-1.09,1.25-3.45-1.66-3.63-.54.71-2.44-2.43-1.11-2.48-.21-1.78-2.37L758.53,245l-5.24-.65-2.75,2.15-1.44,1.87-4.86.08L742.53,251l-3,1.41-2.3,2.88-2.12,2.3-1.78-10.48,14.75-2.76,5-1L764.32,241l13.54-2.76Z"
			>
				{" "}
				<title>Maryland</title>
			</path>
			<path
				className={`usa-cls-2 ${
					props.willingToTravel.includes("Maine") ? "selected" : ""
				}`}
				d="M852.85,146.6l-1.51.65-1.48-2.63-3.58-2.22v-2.15L834,103.92l4.91-5.21L841.29,88,841,71l6-18.47,6.2,3.93,8.16-6.31,8,3.88,8.79,27.45L891,89.81l3,8.45-11.87,10.86-12.55,9.1-12.92,17.05Z"
			>
				{" "}
				<title>Maine</title>
			</path>
			<path
				className={`usa-cls-2 ${
					props.willingToTravel.includes("Michigan") ? "selected" : ""
				}`}
				d="M634.36,117.51h0l4.91,0h0l-1.56,2.32,8.33,7.4-12.61,3.55-.5,0-11.76-.83-11,5.47-9.8,2.92L593,152.52l-.4-.11v0l-2.2-2.3.78-3.48-3.28.11.37-3.47.75-1.77-1.57-2.83-3.59-1-1.11-3.39-2.88,0-4.15.32L567,131.44l-13-2.88-1.43-3.72-2.32-.55,0-.06-.88.49-.14-.1.13.09,10.86-6.11,11.08-7.71,12.09-8.06-3.65,11.53,8.43,2,11,7.05,12.39-6,14-3.47,3.75,5.51,4.54.3Zm42.06,69.57h0l-.34.33-5.52,12.48-2.35,7.76h0L664,216.41h0l-5.85,1-7.09.93-8,1.16-.22-1.62-14.66,1.83-16.81,1.7h0l2.86-3.21,5.23-10.83-1.07-13.81L612,181.17l-.18-8.95,2.83-10.68,3-7.44,5.38-5.69,2.19,6.86,1.41-10.64,3-2.52,1.09-8.21,13,2.61,12,6.66,2.54,9.31,0,9.69-7.12,9.48,1.48,5.14,3-.23,8.06-10.47,5.86,1.27L674,179l2,8.4Z"
			>
				{" "}
				<title>Michigan</title>
			</path>
			<path
				className={`usa-cls-2 ${
					props.willingToTravel.includes("Minnesota")
						? "selected"
						: ""
				}`}
				d="M558.76,93.64h0l-14.09,7.93-9.73,9L525.55,120l.1,3.1-3.79,1.34.64,11.84H522l-3.55,2.47-3.06,2.14-2,4,3.25,3.85-.94,5.33.19,5.72-.29,4.64,4.53,3.89,1.8.12,5,2.79,1.76,1.34,1.16,2.22,4,3.44,5.28,2.85.58,1.71.31,5,.52,2.34-20.06.53-22.32.8-20.79.58-16.72,0,.31-19.64-1.71-20.18-2.59-1.66-1.44-3.27.83-2.85,3.32-2.34.28-3.06,0-3.84-.89-3.22-1.2-3.43-.68-4.3-.13-4.82-.48-1.17-.54-6.21,0-2.85-.26-2.54-.67-4.3L453.14,98l-1.61-3.91-.19-3.85L451.21,86l.52-2.71.12-2.48-1.28-3.08-.17-2.1,29,.09-.05-8.19,4.72-.17,2.64,11.67,4.44,3.54,9.88,1.09,14.52,2.92,14,6,11.34-3.37,17.81,4.4Z"
			>
				{" "}
				<title>Minnesota</title>
			</path>
			<path
				className={`usa-cls-2 ${
					props.willingToTravel.includes("Missouri") ? "selected" : ""
				}`}
				d="M540.8,255.92l-1.14,5.27,1.75,6.18,2.9,4.17,3.27,3.39,3.9,2.6,1.55.87,1.59,3.89.47,3.49,1.91.76,3-1.54,3.32,3.17-.68,3.91-1.33,3.14-.83,3.74,2.53,3,3.46,2.73,1.89,0,4.65,4.29,1.72.5,1.59,5-.38,3.17,2.51,5,1.76-.67,3.21,3-.36,2.14.57,3.26-2.54,1.94-3.62,2.47-.3,2.05-.82,3.06-1,5.18-11.08.73.92-2.14L567,341.2l1.76-2.37-1.57-2.5-19.31-.15L530,337l-18.69.59-20.55.15,0-9.62h-.08l-.23-22.38-.16-22.43-4.31-1.92L484,277l-1.26-2,2.24-3.91-.5-3-2.1.07-3.85-2.76L476.41,261l-2.5-2.67L471.2,255l-.32-1.72,14.34-.09,14.31-.28,18.36-.68L535.31,251l3.22,3.25Z"
			>
				{" "}
				<title>Missouri</title>
			</path>
			<path
				className={`usa-cls-2 ${
					props.willingToTravel.includes("North Carolina")
						? "selected"
						: ""
				}`}
				d="M805.08,300.16l7,19.92-7.3,17.57-15,11.45-12.53,13.34-5.84,2.41L748.6,348.93l-19,3.11-.36-2.42L726,346.56l-1.49,1.66-.43-1.8-8.12.34L700.27,349l-8.68,4.91-1.62.86-21.31,2.73-.19-4.45,3.44-1.78.88-2.38,2.08-2.85,3.37-1,3.86-1.51,3.62-2.33,1.41-2.07,3-2.12-.3-1.49,3.83-3.43,1.63,1.62,5.62-4.85,3,0,2-3.88,3.27-1.43-.65-2.93v-2.67l28.26-3.83,33.11-6.17,17.49-3.79Z"
			>
				{" "}
				<title>North Carolina</title>
			</path>
			<path
				className={`usa-cls-2 ${
					props.willingToTravel.includes("North Dakota")
						? "selected"
						: ""
				}`}
				d="M450.4,75.66l.17,2.1,1.28,3.08-.12,2.48L451.21,86l.13,4.17.19,3.85L453.14,98l1.69,4.38.67,4.3.26,2.54,0,2.85.54,6.21.48,1.17.13,4.82.68,4.3,1.2,3.43.89,3.22,0,3.84-28,.69-23.91-.9-30.14-1.6-28.05-2.1,1.53-25.65,3.25-38.83,48.41,3.45Z"
			>
				{" "}
				<title>North Dakota</title>
			</path>
			<path
				className={`usa-cls-2 ${
					props.willingToTravel.includes("Nebraska") ? "selected" : ""
				}`}
				d="M460.55,213.29l.69,2.09,1,3.47-.05,1.73,2.83,4,1,3.47.11,4.85,1.14.68,1,2.51,1.64,6,.35,3.61-.44,3.75,1.06,3.8.32,1.72,2.71,3.31,2.5,2.67,2.12,4.46H456.35l-37-.75-20.92-.84-28.11-1.6h0l0-.67,1.4-20.3-31.61-2.45,3.59-41.72,30,2.34,23.09,1.37L427.64,202l3.91,2.76,5.65,1.81,1.34-.94,3.68.15,5.52-.12,4,2.75,4.23,1.79.69,1.76,1.33,1Z"
			>
				{" "}
				<title>Nebraska</title>
			</path>
			<path
				className={`usa-cls-2 ${
					props.willingToTravel.includes("New Hampshire")
						? "selected"
						: ""
				}`}
				d="M852.85,146.6l-1.22,5.25-1.86.12L847,154.23l-1,2.42-18.09,4.73-1.75-1.79.48-3-1.06-5.42.12-1.39-.77-4.93.53-4.49.73-2.09.46-5.28-.55-3.36-.05-2.11,2.55-1.74,2.62-3.25-.12-2.68-1.88-2.62.17-6h0l0-5.4,4.58-2,12.28,36.33v2.15l3.58,2.22,1.48,2.63Z"
			>
				{" "}
				<title>New Hampshire</title>
			</path>
			<path
				className={`usa-cls-2 ${
					props.willingToTravel.includes("New Jersey")
						? "selected"
						: ""
				}`}
				d="M815,202.94l-2.88,11.21,4.11-.07.91,15.32-7.73,18.44-2.42-5-3.73-.24-5-4.46-1.34.54-1.31-3.83,1.84-2.67,2.79-2.61.83-1.53,3.09-3.57,1.48-2.7-6.16-4.12-.77-2.09-1.84-.24-.69-3.3,1.23-2.92-1.45-2.46,2-2.38,1.61-5.19,1.67-1.35Z"
			>
				{" "}
				<title>New Jersey</title>
			</path>
			<path
				className={`usa-cls-2 ${
					props.willingToTravel.includes("New York") ? "selected" : ""
				}`}
				d="M815.47,164.26l.32,14.84,3.07,15.76,1.65,1.42-2.85,2.83,1.18,2.34,21-8.36,5.57,2.61-20,11.74L815.62,210l-.61-7-13.74-5.18-2.83-.85-3-.8-1.68-2.74-.12-2.34-2.26-1.42-4.06-2.44-20.75,4.59-22.31,4.62-24,4.55-1-5.6,10.83-14.73-.66-1.92v-.05l-3.19-5.57,7.35-4.1,12-1.59,12.66-.86L768,157.3l-2.65-7.79L763,146.68l-.07,0h0l.07,0,12-17.43,5.7-5.32,22-5.56.76,4.21.08,2.78,1.89,6,1.6,2.91-.4,4.58,2.7,5-.19,3.2.47.7,2.16-.39Z"
			>
				{" "}
				<title>New York</title>
			</path>
			<path
				className={`usa-cls-2 ${
					props.willingToTravel.includes("Ohio") ? "selected" : ""
				}`}
				d="M713.47,231.06l-2,1.4,1.11,2.52.08,4.88-.72,5.65-.8,4.72.1,2.17-3.57,5.44L706,259.15l-2.07.93-2-.15-3,4.13-.06,3.83-.19,2.1-1.31,1-.56-2.27-2.26-.17-1.59,4.84.44,4.63-1.68,3.19-4,1.14-3.33-1.55-1.72-2.71-3,.8-3.32,1.64-3-.8-3.8,1.11-2.73-1.34-3.64-1-4.93-4.13-3.12-1.63-5.6.21-1.68-14.41-2.38-20.25-2.28-18.83,8-1.16,7.09-.93,5.85-1h0l10.22,2.92,7.93-.09L693,214.74l8.25-7.22,7.48-4.13Z"
			>
				{" "}
				<title>Ohio</title>
			</path>
			<path
				className={`usa-cls-2 ${
					props.willingToTravel.includes("Oklahoma") ? "selected" : ""
				}`}
				d="M490.8,337.74l3.74,22.09,0,20.41-.19,18.37-7.61-4-4.93-2.18-4,1.45-6.16-.19-3.65,0-3,1.7-2.93.87-2.57-1-5.86,1.1L451,392.82l-2.77,3-4.77-1.42-4.84-3.34-5.3,2-2.18-5.1-8.15.28L417.86,387l-5.8-1.69-2.42-4.44L405,382.05l-2.76-1.83-4-2.43.88-20,.9-20.5-16.9-.85-16.9-1-16.86-1.21.77-10.36,16.11,1.23L382,326l16.77.83,16.78.64,30.24.69,30.23.1,14.74-.17h.08Z"
			>
				{" "}
				<title>Oklahoma</title>
			</path>
			<path
				className={`usa-cls-2 ${
					props.willingToTravel.includes("Oregon") ? "selected" : ""
				}`}
				d="M162.5,104.16,165,108.9l2,2.23.05,3.29L165,116.77l-3.17,4.84-3.62,6.14-4.1,3.32-3.16,4.16-.81,3.27,2.43,2,.56,3L150.44,149l-8.86,36.67,0,.06-45.08-11.3L33.29,155.61l-1.17-12.74,11.38-20,8.24-22.26L63.94,71.1l3,.64,4.24,1.44L74,76.07l3.72,2.41,1.44,4.65-.65,3.5.19,3.15,3,1.75,3.51,1L91.37,92l3.41.28,4.83,2.33,1.93,1.7,3.19.12,3.7-.74,6.35,2,5.9-.82,3.23-.92,3.39,1.86L133,97Z"
			>
				{" "}
				<title>Oregon</title>
			</path>
			<path
				className={`usa-cls-2 ${
					props.willingToTravel.includes("Pennsylvania")
						? "selected"
						: ""
				}`}
				d="M801.27,197.76l-1.67,1.35L798,204.3l-2,2.38,1.45,2.46-1.23,2.92.69,3.3,1.84.24.77,2.09,6.16,4.12-1.48,2.7-3.09,3.57-.83,1.53-2.79,2.61-3.62-.27-1.21,1.23-.84,1.89-13.9,3.13L764.32,241l-11.23,2.35-5,1-14.75,2.76-16.58,3-3.27-19-4.72-27.67h0l10.55-8,0,0,1,5.6,24-4.55,22.31-4.62,20.75-4.59,4.06,2.44,2.26,1.42.12,2.34,1.68,2.74,3,.8Z"
			>
				{" "}
				<title>Pennsylvania</title>
			</path>
			<path
				className={`usa-cls-2 ${
					props.willingToTravel.includes("Rhode Island")
						? "selected"
						: ""
				}`}
				d="M854.87,180.82l-10.18,6.61.12-3.22-3.14-11,6.3-2,2.59,5,3,1.65Z"
			>
				{" "}
				<title>Rhode Island</title>
			</path>
			<path
				className={`usa-cls-2 ${
					props.willingToTravel.includes("South Carolina")
						? "selected"
						: ""
				}`}
				d="M771.42,364.85l-4,15.79-12.86,15.62L738,410l-3.22-.56-1.75-.5-.62-4L730,401.35l-3.65-1.73.36-1.63-1.41-2.13-2-4.54L717,388l-2.8-4.63-4.65-2.74-1.58-2.4-6.78-4-2.6-3.38-4.17-6.11-3.17-.83-5.12-2.79,2.19-4.33,1.63-2,1.62-.86,8.68-4.91L716,346.76l8.12-.34.43,1.8,1.49-1.66,3.25,3.06.36,2.42,19-3.11Z"
			>
				{" "}
				<title>South Carolina</title>
			</path>
			<path
				className={`usa-cls-2 ${
					props.willingToTravel.includes("South Dakota")
						? "selected"
						: ""
				}`}
				d="M460.69,192h-2l-.05,5.13,1.94,2.8-.37,2.28-.83,3.36L458.09,209l2.49,2.35,0,1.92L458,213l-1.33-1-.69-1.76-4.23-1.79-4-2.75-5.52.12-3.68-.15-1.34.94-5.65-1.81L427.64,202l-30.88-1.22-23.09-1.37-30-2.34,3.41-42.78,2.48-19.19,28.05,2.1,30.14,1.6,23.91.9,28-.69-.28,3.06-3.32,2.34-.83,2.85,1.44,3.27,2.59,1.66L461,172.38Z"
			>
				{" "}
				<title>South Dakota</title>
			</path>
			<path
				className={`usa-cls-2 ${
					props.willingToTravel.includes("Tennessee")
						? "selected"
						: ""
				}`}
				d="M675.17,322.57l25-2.9,8.4-1.73v2.67l.65,2.93L705.9,325l-2,3.88-3,0-5.62,4.85-1.63-1.62-3.83,3.43.3,1.49-3,2.12-1.41,2.07-3.62,2.33-3.86,1.51-3.37,1-2.08,2.85-.88,2.38-3.44,1.78.19,4.45-9.11,1.37-13.24,1.38-22.07,2.1-21.6,2-18.91,1.07-17,1.18,1.84-2.08-.6-6.08,2.53-2.92L571,352l2.85-2.24,1.53-3.06,0-1.75,1-5.18.82-3.06.3-2.05,24.1-1.73-.34-3.27,3.33-.35.63.91,10.46-1.57,9.05-.79,9.85-.88,9.69-.5,4.79-.82,14.89-.94Z"
			>
				{" "}
				<title>Tennessee</title>
			</path>
			<path
				className={`usa-cls-2 ${
					props.willingToTravel.includes("Washington")
						? "selected"
						: ""
				}`}
				d="M175.42,41.85l-6.16,26.43-6.35,28.8.57,3.45-1,3.63L133,97l-5.67.8L123.91,96l-3.23.92-5.9.82-6.35-2-3.7.74-3.19-.12-1.93-1.7-4.83-2.33L91.37,92l-6.12.52-3.51-1-3-1.75-.19-3.15.65-3.5-1.44-4.65L74,76.07l-2.83-2.89-4.24-1.44-3-.64,1.57-18,4-20.14L87.3,42.65l1.24,17.74,5-4.34,2.79-17.33L95.05,20l40,11.61Z"
			>
				{" "}
				<title>Washington</title>
			</path>
			<path
				className={`usa-cls-2 ${
					props.willingToTravel.includes("Wisconsin")
						? "selected"
						: ""
				}`}
				d="M549.15,124.62l.14.11.87-.5,0,.06,2.33.56,1.43,3.72,13,2.87,8.75,3.13,4.14-.31,2.88,0,1.11,3.39,3.6,1,1.57,2.84-.75,1.77-.37,3.47,3.28-.11-.78,3.48,2.2,2.3v0l.4.11-.12.21-5.16,8.1,2.25,2.3,9.68-14.55,2.22-.29-6.18,16.88-2.05,14.92-1.81,12.1,2.84,10.07,0,5.14h0v.05l-11.93.84-16.17,1.19-15.81,1-1.69-3.11-5.16-1.73-1.18-3.28-.73-1.83-.28-4.41,1.28-1.66-2.17-3.22-.28-1.14-.52-2.34-.31-5-.58-1.71-5.28-2.85-4-3.44-1.16-2.22L527,171.18l-5-2.79-1.8-.12-4.53-3.89.29-4.64-.19-5.72.94-5.33-3.25-3.85,2-4,3.06-2.14,3.55-2.47h.46l-.64-11.84,3.79-1.34h0L540,118Z"
			>
				{" "}
				<title>Wisconsin</title>
			</path>
			<path
				className={`usa-cls-2 ${
					props.willingToTravel.includes("West Virginia")
						? "selected"
						: ""
				}`}
				d="M733.32,247.1l1.78,10.48,2.12-2.3,2.3-2.88,3-1.41,1.71-2.56,4.86-.08,1.44-1.87,2.75-2.15,5.24.65,2.53,2.53,1.78,2.37-1,4.22-9.26-3.94-.18,5.72-1.59,2.43-2.62,3.29-1.88,3.11-1.33-.63-1.24,3.25-1.11,2.86-.43,2L740,273.43l-2.68-1-2.44-1.2-.74,4.76L732,280.13l-.26,3.19-2.67,3.25-1.6,4,.7,2.37,0,2-2.33,2-2.34.1-1.79,1.86-2-.43,0,1.91-5.87,2.76L711.6,302l-4.81,3.33L704,304l-2.16-1.58-1.28-3.4-3.27.11-2-2.25-2.81-2.33-1.26-3-2.44-2.94-.46-4.31-.6-1.92,4-1.14,1.68-3.19-.44-4.63,1.59-4.84,2.26.17.56,2.27,1.31-1,.19-2.1.06-3.83,3-4.13,2,.15,2.07-.93,1.69-1.31,3.57-5.44-.1-2.17.8-4.72.72-5.65-.08-4.88-1.11-2.52,2-1.4,3.27,19Z"
			>
				{" "}
				<title>West Virginia</title>
			</path>
			<path
				className={`usa-cls-2 ${
					props.willingToTravel.includes("Wyoming") ? "selected" : ""
				}`}
				d="M347.08,154.3l-3.41,42.78-3.59,41.72-15.83-1.49-31.6-3.49-15.77-2-15.76-2.18-31.3-5.43,3.43-20.12,8.62-51.53-.47-.46.4-.69,1.7-10.56,29.08,4.39,23,3.37,26.73,3Z"
			>
				{" "}
				<title>Wyoming</title>
			</path>
			<path
				className={`usa-cls-2 ${
					props.willingToTravel.includes("Mississippi")
						? "selected"
						: ""
				}`}
				d="M602.64,364.38l1.58,3.95-.76,27.37-.29,33.77,3.25,32.06-10.83,5.64-10.55.44-.49-2.07-.89-3.45-2.57-2.28-1.34-2.58,1-3.54.28-3.53-17.11,1-16,.18-1-5.22,2.1-2.47,1.38-5.16,1.4-4.49,3.3-3.47.6-4,2.37-2.29-2.5-3.49.59-2.64L553.28,414l1.45-3.12-1.33-1.13.63-4.17-2.32-5.41.14-5,3.42-7.53,1.6-4.42,2.91-3,1.94-1.31,2-5.74-.12-3,3-1.75.22-1.68,17-1.18Z"
			>
				{" "}
				<title>Mississippi</title>
			</path>
			<path
				className={`usa-cls-2 ${
					props.willingToTravel.includes("Montana") ? "selected" : ""
				}`}
				d="M349.56,135.11l-2.48,19.19-24.77-2.66-26.73-3-23-3.37-29.08-4.39-1.7,10.56-.4.69L240,150.68l-.95-3.16-1.5-.84-2.83,3.76-3.52.07-8.13-2.78-.77,2L217.56,148l-3.26,2.36-1.61-5.87-1.25-3.35-2.9-1.09-.6-1.68.12-5.59L206,129.73l-.38-6.87-1.17-3.18-1.65-.93-2.07,2.62-3.37,1.87-2.17-2.58,1-5.32,1.95-1-.11-5.57,2.51-5.16L203,99.35l-4.26-1.08-3-3.77-2.58-7.24-1.67-3.71-2.83-2.67L186.72,77l.83-3.73L185.09,67l3.77-22,41,8.46,41.29,7.11,41.5,5.76,41.67,4.38-3.25,38.83Z"
			>
				{" "}
				<title>Montana</title>
			</path>
			<path
				className={`usa-cls-2 ${
					props.willingToTravel.includes("Utah") ? "selected" : ""
				}`}
				d="M261.12,229.63l-11.86,83-24.07-3.83-31.8-5.51-26.66-4.79,20.58-102.65,45.94,8.27-3.43,20.12Z"
			>
				{" "}
				<title>Utah</title>
			</path>
			<path
				className={`usa-cls-2 ${
					props.willingToTravel.includes("Virginia") ? "selected" : ""
				}`}
				d="M762.84,249.88l2.47.22,2.44,1.1-.72,2.44,3.63.54,3.46,1.66,1.7,1.48.17,1.22.07,1.72-2.33,2.5-.87,4.95,2.37.66,2.44-1.44,1.55,2.69,12.67,9.25,5.3,9.08.33.56,7.57,11.64-17.69,4-17.48,3.79L736.8,314.1l-28.26,3.84-8.4,1.73-25,2.9,4.67-1.85L683.1,319l1.2-2.24,4.2-3.12,1.45-2.44.16-1.74,4.81-3.19,5.66-7.19,1.28,3.4L704,304l2.77,1.26,4.82-3.33,2.22,1.13,5.87-2.75,0-1.91,2,.41,1.79-1.85,2.34-.11,2.34-2.05,0-1.94-.71-2.34,1.59-4,2.68-3.25.26-3.19,2.12-4.16.74-4.76,2.44,1.2,2.67,1,2.18-1.23.43-2,1.11-2.85,1.23-3.26,1.33.64,1.88-3.12,2.62-3.29,1.59-2.43.18-5.72,9.26,3.94Zm36.33,36.46,1.67.48,5.43-18.38-3.68,1.17-1,1.68Z"
			>
				{" "}
				<title>Virginia</title>
			</path>
			{/* Arizona */}
			<path
				className={`usa-cls-2 ${
					props.willingToTravel.includes("Arizona") ? "selected" : ""
				}`}
				d="M249.26,312.59l-17,117.65-35.44-5.41L159,403l-24.45-15.37L137,384l2.31.51,2.77-3.73-2.48-3.47,0-3.21-.14-3.81,4.34-4,3.31-9.13,6-3.17-2.42-4.6L149.34,345l-.65-3.88-.35-3,0-1.91,1.88-3.81v-4.19l.48-4.05.92-4.42-.9-3.12,1.67-2.29,3.5.72,2.89,2.12,2.11,1.27,1.74-2.91.85-.53,3.21-16.55,26.66,4.79,31.8,5.51Z"
			>
				{" "}
				<title>Arizona</title>
			</path>
			<path
				className={`usa-cls-2 ${
					props.willingToTravel.includes("California")
						? "selected"
						: ""
				}`}
				d="M96.48,174.43,80.08,235l23,35.18L126,304.13l22.39,32.09,0,1.91.35,3,.65,3.88,1.41,4.33,2.42,4.6-6,3.17-3.31,9.13-4.34,4,.14,3.81,0,3.21,2.48,3.47-2.77,3.73L137,384l-22.53-2.62-20.1-3-.31-8L89.3,358,74.17,341.31l-6.62-2.51-5.65-8.32L49,325.34l-3.37-4.45,1-11.64-10.18-24.8-5.18-31.89,2-4.48-5-8.75-6.95-20.51,3-16.95-4.69-13.51,8.65-15.48,5-17.27Z"
			>
				{" "}
				<title>California</title>
			</path>
			<path
				className={`usa-cls-2 ${
					props.willingToTravel.includes("New Mexico")
						? "selected"
						: ""
				}`}
				d="M350.1,323.86l-.77,10.36L345.1,389l-3.05,39.44-16.6-1.36-32.17-3.14-16-1.81,0,1.81,1.61,3.57-30.77-3.87-1.39,8.59-14.38-2,17-117.65,26.05,3.53,26.35,3.18,28.41,2.85Z"
			>
				{" "}
				<title>New Mexico</title>
			</path>
			<path
				className={`usa-cls-2 ${
					props.willingToTravel.includes("Nevada") ? "selected" : ""
				}`}
				d="M187.31,195.81,166.73,298.46,163.52,315l-.85.53-1.74,2.91-2.11-1.27-2.89-2.12-3.5-.72-1.67,2.29.9,3.12-.92,4.42-.48,4.05v4.19l-1.88,3.81L126,304.13l-22.9-33.91L80.08,235l16.4-60.61,45.08,11.3,0-.06Z"
			>
				{" "}
				<title>Nevada</title>
			</path>
			<path
				className={`usa-cls-2 ${
					props.willingToTravel.includes("Texas") ? "selected" : ""
				}`}
				d="M494.33,398.61l.95,1.36,3.41-.34h4.07L502,411.57l1.58,22.48,3,2.31,1.08,3.23,1.66,4.85,2.22,4.75,1.69,2.83-1.55,7.77-1.11,2.31-1.47,5.09,1.38,1.38,0,4.22-2.68,2.86L506,478.8l1.58,2.76-15.71,4.74-16.8,15.88-18.66,9.14-10.4,10.06-4.5,9.52-.4,14.72.8,10.27,3.57,7.34-7.57.53-13.64-5-14.86-7.07-5-10.22-3.63-15.08L390.14,513.8l-5.9-12.74-8.58-15-12.54-9.18-15.1-.6L335.11,492.1l-14.88-7.62-9-7.23-3.47-12-5-11.57L293,443.3l-8.49-7.63-5.69-8.18-1.61-3.57,0-1.81,16,1.81,32.17,3.14,16.6,1.36L345.1,389l4.23-54.76,16.86,1.21,16.9,1,16.9.85-.9,20.5-.88,20,4,2.43,2.76,1.83,4.63-1.23,2.42,4.44,5.8,1.69,5.16,1.28,8.15-.28,2.18,5.1,5.3-2,4.84,3.34,4.77,1.42,2.77-3,2.62,3.53,5.86-1.1,2.57,1,2.93-.87,3-1.7,3.65,0,6.16.19,4-1.45,4.93,2.18Z"
			>
				{" "}
				<title>Texas</title>
			</path>
			<path
				className={`usa-cls-2 ${
					props.willingToTravel.includes("Vermont") ? "selected" : ""
				}`}
				d="M827.92,161.38l-12.45,2.88-3.65-16.94-2.16.39-.47-.7.19-3.2-2.7-5,.4-4.58-1.6-2.91-1.89-6-.08-2.78-.76-4.21,26.62-7.09h0l-.17,6,1.88,2.62.12,2.68-2.62,3.25-2.55,1.74.05,2.11.55,3.36-.46,5.28-.73,2.09-.53,4.49.77,4.93-.12,1.39,1.06,5.42-.48,3Z"
			>
				{" "}
				<title>Vermont</title>
			</path>
			<path
				className={`usa-cls-2 ${
					props.willingToTravel.includes("Louisiana")
						? "selected"
						: ""
				}`}
				d="M553.4,409.7l1.33,1.13L553.28,414l2.81,4.19-.59,2.64,2.5,3.49-2.37,2.29-.6,4-3.3,3.47-1.4,4.49L549,443.69l-2.1,2.47,1,5.22,16-.18,17.11-1-.28,3.53-1,3.54,1.34,2.58,2.57,2.28.89,3.45.49,2.07-1.5,8.93,6.66,12.27-25.5,2.51-16-3.49L537.82,484l-14.17-2.22-16-.17L506,478.8l1.8-3.15,2.68-2.86,0-4.22-1.38-1.38,1.47-5.09,1.11-2.31,1.55-7.77-1.69-2.83-2.22-4.75-1.66-4.85-1.08-3.23-3-2.31L502,411.57l17.11-.41,19.32-1.44Z"
			>
				{" "}
				<title>Louisiana</title>
			</path>
			<path
				className={`usa-cls-2 ${
					props.willingToTravel.includes("Massachusetts")
						? "selected"
						: ""
				}`}
				d="M851.63,151.85l2.9,10.75,8,9.27,6.39-1.31-3.72-6.89,5.82,3.15.48,6.1-9.25,6.22-7.4,1.68-1.28-3-3-1.65-2.59-5-6.3,2-14.19,3.3-11.69,2.61-.32-14.84,12.45-2.88L846,156.65l1-2.42,2.77-2.26Z"
			>
				{" "}
				<title>Massachusetts</title>
			</path>
			<path
				className="usa-cls-1"
				d="M1686.66-148.11a11.22,11.22,0,0,0,3.14-7.6c0-5.53-4.42-10.26-10.69-12.24a11.48,11.48,0,0,1,.11,1.66c0,8.75-8.9,15.87-19.83,15.87a25.07,25.07,0,0,1-2.62-.16c2.6,4.75,8.72,8.09,15.84,8.09a20.86,20.86,0,0,0,9.2-2.07,14.51,14.51,0,0,0,7.32,2.07.66.66,0,0,0,.61-.4.66.66,0,0,0-.13-.72A18.34,18.34,0,0,1,1686.66-148.11Zm-10.08-18.18c0-7.3-7.7-13.22-17.19-13.22s-17.19,5.92-17.19,13.22a11.2,11.2,0,0,0,3.14,7.6,18.75,18.75,0,0,1-2.95,4.51.66.66,0,0,0-.13.72.66.66,0,0,0,.61.39,14.52,14.52,0,0,0,7.32-2.06,21.13,21.13,0,0,0,9.2,2.06C1668.88-153.07,1676.58-159,1676.58-166.29Z"
			></path>
			<g className="usa-cls-3">
				<rect
					className="usa-cls-1"
					x="-700.32"
					y="-2238.25"
					width="2538.17"
					height="2242.05"
				/>
			</g>
		</svg>
	);
};
