import Dialog from "@material-ui/core/Dialog";
import { CustomDialogTitle } from "../DialogTitle/dialog-title";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import { Formik } from "formik";
import React from "react";
import Form from "./form";

interface ReviewDialogProps {
	titles: string[];
	opened: boolean;
	disabled: boolean;
	type: string;
	handleClose: () => void;
	handleSubmit: (
		userReview: string,
		userRating: number,
		repsyReview: string,
		repsyRating: number,
	) => void;
}

export const ReviewDialog: React.FC<ReviewDialogProps> = ({
	titles,
	opened,
	disabled,
	handleClose,
	handleSubmit,
	type,
}) => (
	<Dialog
		fullWidth={true}
		maxWidth="sm"
		open={opened}
		scroll="body"
		onClose={handleClose}
		aria-labelledby="alert-dialog-title"
		aria-describedby="alert-dialog-description">
		<CustomDialogTitle onClose={handleClose} id="alert-dialog-title">
			<Typography variant="h4">{titles[0]}</Typography>
			<Typography variant="body1">{titles[1]}</Typography>
			<Typography variant="subtitle1">{titles[2]}</Typography>
		</CustomDialogTitle>
		<DialogContent>
			<Formik
				render={(props) => <Form {...props} disabled={disabled} type={type} />}
				initialValues={{
					userReview: "",
					userRating: 0,
					repsyReview: "",
					repsyRating: 0,
				}}
				onSubmit={(values: any, actions) => {
					const {
						userReview,
						userRating,
						repsyReview,
						repsyRating,
					} = values;
					handleSubmit(
						userReview,
						userRating,
						repsyReview,
						repsyRating,
					);
					actions.setSubmitting(true);
				}}
			/>
		</DialogContent>
	</Dialog>
);
