import { refineResponse } from "../helpers/refineResponse";
import { headers, baseUrl } from "../../AuthBase/helpers/auth-header";
import { AddNewCard } from "../../Account/BillingInformation/AddCardForm";
import { AddNewAccount } from "../../Account/BillingInformation/AddAccountForm";

export const listPaymentMethods = () => {
	const requestOptions = {
		method: "POST",
		headers: { ...headers() },
	};

	return fetch(`${baseUrl}payment-method/list`, requestOptions).then(refineResponse);
};


export const toggleDefaultPaymentMethod = (id: number) => {
	const requestOptions = {
		method: "POST",
		headers: { ...headers() },
		body: JSON.stringify({ id }),
	};

	return fetch(`${baseUrl}payment-method/default`, requestOptions).then(refineResponse);
};

export const addPaymentMethod = (billingInformation: AddNewCard | AddNewAccount) => {

	console.log('billingInformation: ', billingInformation)
	//return false;
	const requestOptions = {
		method: "POST",
		headers: { ...headers() },
		body: JSON.stringify(billingInformation),
	};

	return fetch(`${baseUrl}payment-method/create`, requestOptions).then(refineResponse);
};

export const deletePaymentMethod = (id: number) => {
	const requestOptions = {
		method: "DELETE",
		headers: { ...headers() },
	};

	return fetch(`${baseUrl}payment-method/delete/${id}`, requestOptions).then(refineResponse);
};

export const verifyACH = (id: number, amount1: number, amount2: number) => {
	const requestOptions = {
		method: "POST",
		headers: { ...headers() },
		body: JSON.stringify({ id : id, amount1 : amount1, amount2: amount2 })
	};

	return fetch(`${baseUrl}stripe/verify`, requestOptions).then(refineResponse);
}


export const listAgentBandPaymentMethods = (bandId: number) => {
	const requestOptions = {
		method: "POST",
		headers: { ...headers() },
	};

	return fetch(`${baseUrl}payment-method/agent-band-list/${bandId}`, requestOptions).then(refineResponse);
};

export const addBandPaymentMethodByAgent = (bandId: number, billingInformation: AddNewCard | AddNewAccount) => {
	const requestOptions = {
		method: "POST",
		headers: { ...headers() },
		body: JSON.stringify(billingInformation),
	};

	return fetch(`${baseUrl}payment-method/create/${bandId}`, requestOptions).then(refineResponse);
};


export const deleteBandPaymentMethodByAgent = (id: number, bandId: number) => {
	const requestOptions = {
		method: "DELETE",
		headers: { ...headers() },
	};

	return fetch(`${baseUrl}payment-method/delete/${id}/${bandId}`, requestOptions).then(refineResponse);
};

