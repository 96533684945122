import { connect } from "react-redux";
import { IAppState } from "../generic/interfaces";
import { ThunkDispatch } from "redux-thunk";
import { MyFavorites } from "./favorites";
import { search, loadMore } from "./searchFavoritesSlice";
import { bandFavoriteToggle } from "../SearchPage/searchPageSlice";
import {
    bandRequestModalOpen,
    bandRequestModalClose,
    setSelectedEvent,
    addEventRequest,
    warningModalOpen,
    warningModalClose,
} from "../BandPage/BandRequest/bandRequestSlice";
import { listBookerEventsList } from "../Account/BookerEvent/ListBookerEvents/listBookerEventsSlice";

const mapStateToProps = (state: IAppState) => ({
    myFavoritesState: state.searchFavoriteBands,
    authentication: state.authentication,
    isUserApproved: state.authentication.user.status === "APPROVED",
    warningModalOpened: state.bandPage.bandRequest.warningModalOpen,
    bandRequest: state.bandPage.bandRequest,
    bookerEvents: state.account.bookerEvent.listBookerEvent,
});

// const mapStateToProps = (state: IAppState) => ({
// 	searchBands: state.searchBands.searchBands
// });

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
    filterBands: () => {
        dispatch(search());
    },
    loadMore: () => {
        dispatch(loadMore());
    },
    favoriteToggle: (bandId: number, isFavorite: boolean) => {
        dispatch(bandFavoriteToggle(bandId, isFavorite));
        dispatch(search());
    },
    handleOpen: () => {
        dispatch(bandRequestModalOpen());
    },
    handleClose: () => dispatch(bandRequestModalClose()),
    setSelectedEvent: (eventItem: any) => dispatch(setSelectedEvent(eventItem)),
    listBookerEventsList: () => dispatch(listBookerEventsList()),
    handleBandRequestSubmit: (values: any) => {
        dispatch(addEventRequest(values));
    },
    handleOpenWarningModel: () => dispatch(warningModalOpen()),
    handleCloseWarningModel: () => dispatch(warningModalClose()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyFavorites);
