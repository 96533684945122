import React, { useEffect } from "react";
import { Divider, DialogContent, Typography, Dialog, Button, Box, InputLabel, Grid, TextField, Link } from "@material-ui/core";
import { CustomDialogTitle } from "../../generic/components";
import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { BandRequestState } from "./bandRequestSlice";
import ErrorMessage from "../../generic/ErrorMessage";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        textAlign: {
            textAlign: "end",
            "@media screen and (max-width: 599px)": {
                textAlign: "start",
            }
        },
        Link: {
            textDecoration: "none",
            color: "white",
            fontSize: "15px",
            fontWeight: "bold",
        },
        CreateNewEventLink: {
            fontWeight: "bold",
            color: "#64FECC",
        },
        margin: {
            margin: "0px",
        },
        cancel: {
            fontWeight: 200,
        },
        location: {
            "word-wrap": "break-word",
            maxWidth: "320px",
        },
        bookingRequestRow: {
            padding: "15px 0px",
        },
    })
);

export interface BandRequestProps {
    bandRequest: BandRequestState;
    band: any;
    initialValues: any;
    handleClose: () => void;
    handleBandRequestSubmit: (values: any) => void;
    listBookerEventsList: () => void;
    type: string;
    id: any;
}

export interface MyFormValues {
    talent_budget: string;
    specialNotes: string;
    production_budget: string;
}

export const minutesToHours = (min: number | null) => {
    if (min === null) {
        return "";
    }

    const rminutes = min % 60;
    const rhours = rminutes === 0 ? min / 60 : min / 60;

    return `${Math.floor(rhours)} hour(s) ${rminutes > 0 ? "and " + rminutes + " minute(s)." : ""}`;
};

const RequestForm: React.FC<BandRequestProps> = ({
    band,
    bandRequest,
    handleClose,
    handleBandRequestSubmit,
    listBookerEventsList,
    initialValues,
    type,
    id,
}) => {
    useEffect(() => {
        listBookerEventsList();

        return () => handleClose();
    }, [listBookerEventsList, handleClose]);

    const classes = useStyles();

    const validationSchema = Yup.object({
        talent_budget: Yup.string()
            .required()
            .matches(/[0-9]\d{0,6}(\.\d{1,2})?/, { message: "Invalid type" })
            .test("budget", `The budget must meet the band minimum of ${band.price_from}`, (val) => val >= band.price_from)
            .max(6),
        production_budget: Yup.string()
            .required()
            .matches(/[0-9]\d{0,6}(\.\d{1,2})?/, { message: "Invalid type" })
            .max(6),
        specialNotes: Yup.string().max(5000),
    });

    return (
        <Dialog
            fullWidth={true}
            maxWidth="md"
            scroll="body"
            open={bandRequest.bandRequestModalOpen && bandRequest.selectedEvent !== null}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <CustomDialogTitle onClose={handleClose} id="alert-dialog-title">
                <Typography variant="h4" color="textPrimary">
                    {type === "edit" ? "EDIT BOOKING REQUEST" : "SEND BOOKING REQUEST"}
                </Typography>
            </CustomDialogTitle>
            <Divider variant="middle" />
            <DialogContent>
                <Box m={2}>
                    {bandRequest.selectedEvent && (
                        <>
                            <Grid container direction="row" spacing={2} alignItems="center" className={classes.bookingRequestRow}>
                                <Grid item xs={12} sm={4} md={4} lg={4} className={classes.bookingRequestColl}>
                                    <InputLabel className={classes.textAlign} htmlFor="events">
                                        Event Date
                                    </InputLabel>
                                </Grid>
                                <Grid item xs={12} sm={8} md={8} lg={8} className={classes.bookingRequestColl}>
                                    <Typography>{moment(bandRequest.selectedEvent.date, "YYYY.MM.DD").format("LL")}</Typography>
                                </Grid>
                            </Grid>
                            <Grid container direction="row" spacing={2} alignItems="center" className={classes.bookingRequestRow}>
                                <Grid item xs={12} sm={4} md={4} lg={4} className={classes.bookingRequestColl}>
                                    <InputLabel className={classes.textAlign} htmlFor="events">
                                        Location
                                    </InputLabel>
                                </Grid>
                                <Grid item xs={12} sm={8} md={8} lg={8} className={classes.bookingRequestColl}>
                                    <Typography
                                        className={
                                            classes.location
                                        }>{`${bandRequest.selectedEvent.location}, ${bandRequest.selectedEvent.state.title} `}</Typography>
                                </Grid>
                            </Grid>
                            <Grid container direction="row" spacing={2} alignItems="center" className={classes.bookingRequestRow}>
                                <Grid item xs={12} sm={4} md={4} lg={4} className={classes.bookingRequestColl}>
                                    <InputLabel className={classes.textAlign} htmlFor="events">
                                        Number of Attendees
                                    </InputLabel>
                                </Grid>
                                <Grid item xs={12} sm={8} md={8} lg={8} className={classes.bookingRequestColl}>
                                    <Typography> {bandRequest.selectedEvent.number_of_attendees}</Typography>
                                </Grid>
                            </Grid>
                            {bandRequest.selectedEvent.event_type && (
                                <Grid container direction="row" spacing={2} alignItems="center" className={classes.bookingRequestRow}>
                                    <Grid item xs={12} sm={4} md={4} lg={4} className={classes.bookingRequestColl}>
                                        <InputLabel className={classes.textAlign} htmlFor="events">
                                            Event Type
                                        </InputLabel>
                                    </Grid>
                                    <Grid item xs={12} sm={8} md={8} lg={8} className={classes.bookingRequestColl}>
                                        <Typography>{bandRequest.selectedEvent.event_type.title}</Typography>
                                    </Grid>
                                </Grid>
                            )}
                            <Grid container direction="row" spacing={2} alignItems="center" className={classes.bookingRequestRow}>
                                <Grid item xs={12} sm={4} md={4} lg={4} className={classes.bookingRequestColl}>
                                    <InputLabel className={classes.textAlign} htmlFor="events">
                                        Start Time
                                    </InputLabel>
                                </Grid>
                                <Grid item xs={12} sm={8} md={8} lg={8} className={classes.bookingRequestColl}>
                                    <Typography>{moment(bandRequest.selectedEvent.start_time, "h:mm:ss").format("h:mm A")}</Typography>
                                </Grid>
                            </Grid>
                            <Grid container direction="row" spacing={2} alignItems="center" className={classes.bookingRequestRow}>
                                <Grid item xs={12} sm={4} md={4} lg={4} className={classes.bookingRequestColl}>
                                    <InputLabel className={classes.textAlign} htmlFor="events">
                                        Desired Set Length
                                    </InputLabel>
                                </Grid>
                                <Grid item xs={12} sm={8} md={8} lg={8} className={classes.bookingRequestColl}>
                                    <Typography>{minutesToHours(bandRequest.selectedEvent.desired_set_length)}</Typography>
                                </Grid>
                            </Grid>
                            <Grid container direction="row" spacing={2} alignItems="center" className={classes.bookingRequestRow}>
                                <Grid item xs={12} sm={4} md={4} lg={4} className={classes.bookingRequestColl}>
                                    <InputLabel className={classes.textAlign} htmlFor="events">
                                        Event Description
                                    </InputLabel>
                                </Grid>
                                <Grid item xs={12} sm={8} md={8} lg={8} className={classes.bookingRequestColl}>
                                    <Typography>
                                        {bandRequest.selectedEvent.description}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container direction="row" spacing={2} alignItems="center" className={classes.bookingRequestRow}>
                                <Grid item xs={12} sm={4} md={4} lg={4} className={classes.bookingRequestColl}>
                                    <InputLabel className={classes.textAlign} htmlFor="events">
                                        Venue Description
                                    </InputLabel>
                                </Grid>
                                <Grid item xs={12} sm={8} md={8} lg={8} className={classes.bookingRequestColl}>
                                    <Typography>
                                        {bandRequest.selectedEvent.venue_description &&
                                            bandRequest.selectedEvent.venue_description.map((venue: any) => venue.title).join(", ")}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Formik
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                onSubmit={async (values, actions) => {
                                    if (type === "create") {
                                        const obj = {
                                            event: {
                                                id: bandRequest.selectedEvent && bandRequest.selectedEvent.id,
                                            },
                                            band: { id: band.id },
                                            talent_budget: values.talent_budget,
                                            production_budget: values.production_budget,
                                            notes: values.specialNotes,
                                        };
                                        handleBandRequestSubmit(obj);
                                    } else {
                                        const request = {
                                            id,
                                            data: {
                                                talent_budget: Number(values.talent_budget),
                                                production_budget: Number(values.production_budget),
                                                notes: values.specialNotes,
                                            },
                                        };
                                        handleBandRequestSubmit(request);
                                    }
                                    actions.setSubmitting(false);
                                }}
                                render={(formikBag: FormikProps<MyFormValues>) => (
                                    <form onSubmit={formikBag.handleSubmit}>
                                        <Grid container direction="row" spacing={2} alignItems="center" className={classes.bookingRequestRow}>
                                            <Grid item xs={12} sm={4} md={4} lg={4} className={classes.bookingRequestColl}>
                                                <InputLabel className={classes.textAlign} htmlFor="budget">
                                                    Talent Budget *
                                                </InputLabel>
                                            </Grid>
                                            <Grid item xs={12} sm={8} md={8} lg={8} className={classes.bookingRequestColl}>
                                                <TextField
                                                    fullWidth={true}
                                                    placeholder="Talent Budget"
                                                    autoComplete="off"
                                                    id="talent_budget"
                                                    name="talent_budget"
                                                    variant="outlined"
                                                    color="secondary"
                                                    helperText={formikBag.touched.talent_budget ? formikBag.errors.talent_budget : ""}
                                                    error={formikBag.touched.talent_budget && Boolean(formikBag.errors.talent_budget)}
                                                    margin="dense"
                                                    value={formikBag.values.talent_budget}
                                                    onChange={(e: any) => {
                                                        e.persist();
                                                        formikBag.setFieldValue("talent_budget", e.target.value);
                                                        formikBag.setFieldTouched("talent_budget", true, false);
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container direction="row" spacing={2} alignItems="center" className={classes.bookingRequestRow}>
                                            <Grid item xs={12} sm={4} md={4} lg={4} className={classes.bookingRequestColl}>
                                                <InputLabel className={classes.textAlign} htmlFor="budget">
                                                    Production Budget *
                                                </InputLabel>
                                            </Grid>
                                            <Grid item xs={12} sm={8} md={8} lg={8} className={classes.bookingRequestColl}>
                                                <TextField
                                                    fullWidth={true}
                                                    placeholder="Production Budget"
                                                    autoComplete="off"
                                                    id="production_budget"
                                                    name="production_budget"
                                                    variant="outlined"
                                                    color="secondary"
                                                    helperText={formikBag.touched.production_budget ? formikBag.errors.production_budget : ""}
                                                    error={formikBag.touched.production_budget && Boolean(formikBag.errors.production_budget)}
                                                    margin="dense"
                                                    value={formikBag.values.production_budget}
                                                    onChange={(e: any) => {
                                                        e.persist();
                                                        formikBag.setFieldValue("production_budget", e.target.value);
                                                        formikBag.setFieldTouched("production_budget", true, false);
                                                    }}
                                                />
                                                Production budget is any portion of your overall budget that you can spend on sound, lighting and/or staging. If you have all of those things, or you do not need them, please enter "0" into the field.

                                            </Grid>
                                        </Grid>
                                        <Grid container direction="row" spacing={2} alignItems="flex-start" className={classes.bookingRequestRow}>
                                            <Grid item xs={12} sm={4} md={4} lg={4} className={classes.bookingRequestColl}>
                                                <InputLabel className={classes.textAlign} htmlFor="specialNotes">
                                                    Special Notes
                                                </InputLabel>
                                            </Grid>
                                            <Grid item xs={12} sm={8} md={8} lg={8} className={classes.bookingRequestColl}>
                                                <TextField
                                                    placeholder="Special Notes"
                                                    autoComplete="off"
                                                    id="specialNotes"
                                                    name="specialNotes"
                                                    multiline={true}
                                                    rows={6}
                                                    helperText={formikBag.touched.specialNotes ? formikBag.errors.specialNotes : ""}
                                                    error={formikBag.touched.specialNotes && Boolean(formikBag.errors.specialNotes)}
                                                    variant="outlined"
                                                    color="secondary"
                                                    fullWidth={true}
                                                    value={formikBag.values.specialNotes}
                                                    onChange={(e: any) => {
                                                        e.persist();
                                                        formikBag.setFieldValue("specialNotes", e.target.value);
                                                        formikBag.setFieldTouched("specialNotes", true, false);
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Typography variant="caption">
                                            All events will be charged the required Repsy fee and additional hospitality costs that could be requested by
                                            the Talent. All fees will be listed in your contract agreement.
                                        </Typography>
                                        <Grid container direction="column" spacing={2} alignItems="center" justify="flex-start" className={classes.bookingRequestRow}>
                                            <Grid item xs={6} md={6} lg={6} className={classes.bookingRequestColl}>
                                                {bandRequest.error && bandRequest.error.message && <ErrorMessage error={bandRequest.error} />}
                                            </Grid>
                                            <Grid item>
                                                <Grid container direction="column" spacing={2} alignItems="center">
                                                    <Grid item>
                                                        <Button
                                                            style={{marginRight: '10px'}}
                                                            type="submit"
                                                            size="large"
                                                            variant="contained"
                                                            color="secondary"
                                                            disabled={bandRequest.bandRequestRequested}>
                                                            {type === "edit" ? "Edit" : "Send"}
                                                        </Button>
                                                        <Link
                                                            className={classes.cancel}
                                                            color="textPrimary"
                                                            component="button"
                                                            type="button"
                                                            onClick={handleClose}>
                                                            Cancel
                                                        </Link>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </form>
                                )}
                            />
                        </>
                    )}
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default RequestForm;
