import { createStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";

export const styles = createStyles({
	wrapper: {
		// margin: theme.spacing(1),
		position: "relative",
	},
	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
});
