import React, { useEffect } from "react";
import { Avatar, Box, Button, Dialog, Grid, OutlinedInput, Typography } from "@material-ui/core";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import Services from "../../Services";
import { CustomDialogTitle } from "../DialogTitle/dialog-title";
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		paragraphFont: {
			fontSize: "15px",
			color: "#ddd",
		},
		Link: {
			display: "flex",
			alignItems: "center",
			color: "#0F24FD",
			backgroundColor: "#64FECC",
			padding: "5px 20px",
			fontSize: "1rem",
			fontFamily: "Rubik",
			fontWeight: "bold",
			borderRadius: "4px",
			textDecoration: "none",
			margin: 10,
			height: 35,
			"&:hover": {
				backgroundColor: "#64FECC"
			},
		},
	}),
);


export interface MessageDialogProps {
    showDialog: any;
    closeDialog: () => void;
    title?: any;
    message?: any;
}

export const MessageDialog: React.FC<MessageDialogProps> = ({
    title,
    message,
    closeDialog,
    showDialog
}) => {
	const [MessageDialogOpen, setMessageDialogOpen] = React.useState(showDialog);

	useEffect(() => {
	}, [showDialog]);


	const removeRequest = async ()=>{
        setMessageDialogOpen(true);
    }
    
    return (
        <Dialog
						fullWidth={true}
						maxWidth="md"
						open={MessageDialogOpen}
						scroll="body"
						onClose={closeDialog}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
					>
						<CustomDialogTitle
					onClose={closeDialog}
					id="alert-dialog-title"
				>
					<Typography variant="h4">{title}</Typography>
					</CustomDialogTitle>
					
					
					<Grid container direction="row" justify="center" alignItems="center">
						<Grid item xs={12} md={12} lg={11} alignItems="center">
							<Typography component="h6" align="center">{message}</Typography>
						</Grid>
					</Grid>
					
					<Box display="flex" flexWrap="wrap" justifyContent="center" py={4} px={5}>
						<Grid container direction="row" justify="center" alignItems="center">
							<Grid item xs={12} md={12} lg={11} alignItems="center" style={{textAlign: "center"}}>
                                <Button
                                    variant="contained"
                                    size="large"
                                    color="secondary"
                                    aria-label="Search"
                                    style={{ width: 150 }}
                                    onClick={closeDialog} >
                                    Ok
                                </Button>
							</Grid>
						</Grid>
					</Box>
					</Dialog>
                    );
                };
                