import React from "react";
import { BandBase } from "../interfaces";
import { Typography, Button, Box, Grid } from "@material-ui/core";
import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { ReactComponent as Play } from "../Assets/Play.svg";

interface FeaturedArtistMobileProps {
	band: FeaturedBandBase;
}

interface FeaturedBandBase extends BandBase {
	[x: string]: any;
	bio: string;
	featured_songs: any[];
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		bio: {
			margin: "0px",
			display: "block",
			textAlign: "start",
			maxWidth: "100%",
			fontSize: "16px",
			"-webkit-line-clamp": "3",
			"-webkit-box-orient": "vertical",
			overflow: "hidden",
			textOverflow: "ellipsis",
		},
		featuredSong: {
			fontWeight: "bold",
			fontSize: "17px",
			letterSpacing: "1px",
		},
		inline: {
			display: "inline-block",
			marginRight: theme.spacing(1),
		},
		image: {
			height: "220px",
			boxShadow: "-20px -20px #0F24FD",
			float: "right",
			objectFit: "cover",
		},
		alignItems: {
			float: "right",
		},
		FeaturedArtistItems: {
			FeaturedArtistItem: {
				padding: '0px 20px'
			}
		},
		FeaturedArtistItem: {
			position: "relative",
		},
		FeaturedArtistItemOverlay: {
			position: 'absolute',
			bottom: '0',
			left: '0',
			width: '100%',
			minHeight: '60%',
			padding: '40px 20px 20px 20px',
			background: 'linear-gradient(180deg, rgba(15, 36, 253, 0) 0%, #0F24FD 100%)',
			display: 'flex',
			flexWrap: 'wrap',
			alignItems: 'end',
			justifyContent: 'flex-start',
			transition: 'all 0.5s ease-in-out'
		},
		FeaturedArtistItemTitle: {
			fontFamily: "poppins",
			fontWeight: 600,
			fontSize: "2em",
		},
		FeaturedArtistItemTitleLink: {
			color: '#fff',
			textDecoration: 'none',
		},
	}),
);

export const FeaturedArtistMobile: React.FC<FeaturedArtistMobileProps> = ({ band }) => {
	const classes = useStyles();

	const matches = useMediaQuery("(min-width:900px)");

	return (
		<div key="slide1" className="FeaturedArtistItem">
			<Link to={`/${band?.created_by?.username}`} className={classes.FeaturedArtistItemTitleLink}>
			{/* <Link to={`/bands/${band.id}`} className={classes.FeaturedArtistItemTitleLink}> */}

				<Grid className="FeaturedArtistItem">
					<img alt="" src={band.photo.url} style={{ maxWidth: "100%", width: '100%' }} />
					<Grid className="FeaturedArtistItemOverlay">
						<Grid className="FeaturedArtistItemOverlayIn">
							<Typography variant="h5" className="FeaturedArtistItemTitle">{band.name}</Typography>
							<p style={{ marginTop: '5px', fontSize: '16px' }}>
								{band &&
									`${band.genres
										.map((item1: any) => item1.title)
										.join()
										.replace(/,/g, ", ")}`}
							</p>
						</Grid>
					</Grid>
				</Grid>
			</Link>

		</div>
	);
};
