import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import makeStyles from "@material-ui/core/styles/makeStyles";
import FormHelperText from "@material-ui/core/FormHelperText";
import createStyles from "@material-ui/core/styles/createStyles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Formik, FormikProps } from "formik";
import { FormTextField } from "./FormTextField";
import { DiscardDialog } from "./DiscardDialog";
import ErrorMessage from "../../generic/ErrorMessage";
import Services from "../../generic/Services";
import * as Yup from "yup";
import { WarningDialoge } from "./WarningDialoge"
import { useParams } from "react-router-dom";
import InfoIcon from '@material-ui/icons/Info';
import { Tooltip } from "@material-ui/core";
import { userActions } from "../../AuthBase";
import { useDispatch } from 'react-redux';

const useStyles = makeStyles((theme) =>
	createStyles({
		dropDownPlaceholder: {
			color: "#63637B",
			fontWeight: 600,
		},
		select: {
			margin: `${theme.spacing(1)}px 0px 0px 0px`,
		},
		checkbox: {
			"& .MuiIconButton-label .MuiSvgIcon-root": {
				fontSize: "1rem",
				fill: theme.palette.secondary.main,
			},
		},
		margin: {
			margin: "0px",
		},
	}),
);

export interface AddNewAccount {
	street: string;
	city: string;
	state: { id: number } | null;
	zip: string;
	name: string;
	account_number: string;
	routing_number: string;
	bank_name: string;
	account_type: string;
	termsCondition: boolean;
	//id_number: string;
	//dob_day: number;
//	dob_month: number;
//	dob_year: number;
	//gender: string
}

const initialValues = {
	street: "",
	city: "",
	state: null,
	zip: "",
	name: "",
	account_number: "",
	routing_number: "",
	bank_name: "",
	account_type: "",
	termsCondition: false,
	//id_number: "",
	//dob_day: 0,
	//dob_month: 0,
	//dob_year: 0,
	//gender: ""
};

// validation schema of the add new account form
const validationSchema = Yup.object({
	street: Yup.string().required("address is required"),
	city: Yup.string().required("city is required"),
	state: Yup.object({ id: Yup.number().required() }),
	zip: Yup.string()
		.matches(/^[0-9]{5}$|^[0-9]{9}$/, 'Zip Must be 5 or 9 digits')
		.required("zip is required"),
	name: Yup.string().required("name is required"),
	bank_name: Yup.string().required("bank name is required"),
	account_number: Yup.string()
		.min(6)
		.max(17)
		.required("account number is required"),
	routing_number: Yup.string()
		.length(9)
		.required("routing number is required"),
	account_type: Yup.string().required("account type is required"),
	termsCondition: Yup.bool(),
	//id_number: Yup.string().required("SSN number is required"),
	//dob_day: Yup.number().min(1).required("Date of birth is required"),
	//dob_month: Yup.number().min(1).required("Date of birth is required"),
	//dob_year: Yup.number().min(1).required("Date of birth is required"),
	//gender: Yup.string().required("Gender is required"),
});

const accountTypes = [
	{ text: "Checking Account", value: "checking_Account" },
	{ text: "Savings Account", value: "savings_Account" },
];

const genders = [
	{ text: "Male", value: "male" },
	{ text: "Female", value: "female" },
];


const dayList = Array.from({ length: 31 }, (item, index) => index + 1);
const yearList = Array.from({ length: (new Date().getFullYear() - 1950) }, (_, i) => 1950 + (i + 1));
const monthList = [
	{ text: 'January', value: 1 },
	{ text: 'February', value: 2 },
	{ text: 'March', value: 3 },
	{ text: 'April', value: 4 },
	{ text: 'May', value: 5 },
	{ text: 'June', value: 6 },
	{ text: 'July', value: 7 },
	{ text: 'August', value: 8 },
	{ text: 'September', value: 9 },
	{ text: 'October', value: 10 },
	{ text: 'November', value: 11 },
	{ text: 'December', value: 12 }];

const dobData = {
	day: dayList,
	month: monthList,
	year: yearList.reverse()

};

interface AddAccountFormProps {
	states: any;
	addPaymentMethod: boolean;
	setAddPaymentMethod: any;
	dirtyForm: boolean;
	setDirtyForm: any;
	setExpantionOpen: (state: boolean) => void;
	updateUserData: () => void;
}

export const AddAccountForm: React.FC<AddAccountFormProps> = ({
	states,
	addPaymentMethod,
	setAddPaymentMethod,
	dirtyForm,
	setDirtyForm,
	setExpantionOpen,
	updateUserData,
}) => {
	const classes = useStyles();
	const dispatch = useDispatch(); // to update store values

	const [error, setError] = useState<any>();

	const [open, setOpen] = React.useState(false);
	const [openWarning, setOpenWarning] = React.useState(false);

	const handleClose = () => {
		setOpen(false);
	};

	const beforeUnload = (event: any) => {
		if (dirtyForm) {
			event.returnValue = `Are you sure you want to leave?`;
		}
	};

	const { id } = useParams();

	useEffect(() => {
		window.addEventListener("beforeunload", beforeUnload);
		return () => {
			window.removeEventListener("beforeunload", beforeUnload);
		};
	});

	return (
		<Formik
			validationSchema={validationSchema}
			initialValues={initialValues}
			onSubmit={(values, actions) => {
				const accountData = { type: "account", provider: "stripe", ...values };
				if (!values.termsCondition) {
					actions.setFieldError("termsCondition", "You must accept our terms and services");
				} else {
					if (!id) {
						Services.paymentMethods
							.addPaymentMethod(accountData)
							.then((res: any) => {
								if (res?.item?.status === "MISSING_PROFILE")
									setOpenWarning(true)
								updateUserData()
								actions.setSubmitting(false);
								actions.resetForm();
								//setError({});
								setAddPaymentMethod(!addPaymentMethod);

								let user = JSON.parse(localStorage.getItem("user") || "");
								user = { ...user, billing_status: "" };
								dispatch(userActions.updateTalentStatuses(user))
								user = JSON.stringify(user);
          						localStorage.setItem("user", user);
							})
							.catch((err: any) => setError(err));
						actions.setSubmitting(false);
					}
					else {
						Services.paymentMethods
							.addBandPaymentMethodByAgent(id, accountData)
							.then((res: any) => {
								if (res?.item?.status === "MISSING_PROFILE")
									setOpenWarning(true)
								updateUserData()
								actions.setSubmitting(false);
								actions.resetForm();
								setError({});
								setAddPaymentMethod(!addPaymentMethod);


								let user = JSON.parse(localStorage.getItem("user") || "");
								user = { ...user, billing_status: "" };
								dispatch(userActions.updateTalentStatuses(user))
								user = JSON.stringify(user);
          						localStorage.setItem("user", user);

							})
							.catch((err: any) => setError(err));
						actions.setSubmitting(false);
					}

				}
			}}
			render={(formikBag: FormikProps<AddNewAccount>) => {
				const {
					values,
					errors,
					touched,
					handleChange,
					handleSubmit,
					setFieldValue,
					setFieldTouched,
				} = formikBag;

				const onKeyPress = ({ fieldname, length }: { fieldname: "zip"; length: number }) => (e: any) => {
					e.persist();
					if (!(e.key >= "0" && e.key <= "9") || values[fieldname].length > length) {
						e.preventDefault();
					}
				};

				setDirtyForm(formikBag.dirty);
				return (
					<Box width="100%">
						<Box mb={4} fontSize="1.2rem">
							Bank Account Details
						</Box>
						<form onSubmit={handleSubmit}>
							<Grid container direction="row" spacing={9}>
								<Grid item xs={12} md={6} lg={6}>
									<Grid container direction="row" spacing={3}>
										<Grid item xs={12} md={12} lg={12}>
											<FormTextField
												fieldName="name"
												value={values.name}
												touched={touched.name}
												error={errors.name}
												handleChange={handleChange}
											/>
										</Grid>

										{/* <Grid item xs={12} md={12} lg={12} style={{display: 'none'}}>
											<Grid item>
												<InputLabel htmlFor={"account_type"}>Date of Birth</InputLabel>
											</Grid>
											<Grid item xs={12} md={12} lg={12} style={{display: 'none', justifyContent: 'space-between'}}>
												<Grid item xs={12} md={3} lg={3} style={{paddingRight: '5px'}}>
													<Grid item>
														<Select
															classes={{}}
															className={
																values.dob_day
																	? classes.select
																	: `${classes.dropDownPlaceholder} ${classes.select}`
															}
															value={values.dob_day ? values.dob_day : 0}
															fullWidth={true}
															id="dob_day"
															name="dob"
															variant="outlined"
															color="secondary"
															error={touched.dob_day && Boolean(errors.dob_day)}
															MenuProps={{
																getContentAnchorEl: null,
																anchorOrigin: {
																	vertical: "bottom",
																	horizontal: "left",
																},
																transformOrigin: {
																	vertical: "top",
																	horizontal: "left",
																},
																classes: {
																	paper: classes.margin,
																},
															}}
															onChange={(e: any) => {
																e.persist();
																//setFieldValue({...values, dob: { ...dob, day: e.target.value }});
																setFieldValue("dob_day", e.target.value);
																setFieldTouched("dob_day", true, false);
															}}>
															<MenuItem disabled value={0}>
																Day
													</MenuItem>
															{dobData.day.map((day: any) => (
																<MenuItem key={day} value={day}>
																	{day}
																</MenuItem>
															))}
														</Select>
													</Grid>
												</Grid>
												<Grid item xs={12} md={5} lg={5} style={{paddingRight: '5px'}}>
													<Grid item>
														<Select
															classes={{}}
															className={
																values.dob_month
																	? classes.select
																	: `${classes.dropDownPlaceholder} ${classes.select}`
															}
															value={values.dob_month ? values.dob_month : 0}
															fullWidth={true}
															id="dob_month"
															name="dob_month"
															variant="outlined"
															color="secondary"
															error={touched.dob_month && Boolean(errors.dob_month)}
															MenuProps={{
																getContentAnchorEl: null,
																anchorOrigin: {
																	vertical: "bottom",
																	horizontal: "left",
																},
																transformOrigin: {
																	vertical: "top",
																	horizontal: "left",
																},
																classes: {
																	paper: classes.margin,
																},
															}}
															onChange={(e: any) => {
																e.persist();
																setFieldValue("dob_month", e.target.value);
																setFieldTouched("dob_month", true, false);
															}}>
															<MenuItem disabled value={0}>
																Month
													</MenuItem>
															{dobData.month.map((month: any) => (
																<MenuItem key={month.value} value={month.value}>
																	{month.text}
																</MenuItem>
															))}
														</Select>
													</Grid>
												</Grid>
												<Grid item xs={12} md={4} lg={4}>
													<Grid item>
														<Select
															classes={{}}
															className={
																values.dob_year
																	? classes.select
																	: `${classes.dropDownPlaceholder} ${classes.select}`
															}
															value={values.dob_year ? values.dob_year : 0}
															fullWidth={true}
															id="dob_year"
															name="dob"
															variant="outlined"
															color="secondary"
															error={touched.dob_year && Boolean(errors.dob_year)}
															MenuProps={{
																getContentAnchorEl: null,
																anchorOrigin: {
																	vertical: "bottom",
																	horizontal: "left",
																},
																transformOrigin: {
																	vertical: "top",
																	horizontal: "left",
																},
																classes: {
																	paper: classes.margin,
																},
															}}
															onChange={(e: any) => {
																e.persist();
																setFieldValue("dob_year", e.target.value);
																setFieldTouched("dob_year", true, false);
															}}>
															<MenuItem disabled value={0}>
																Year
													</MenuItem>
															{dobData.year.map((year: any) => (
																<MenuItem key={year} value={year}>
																	{year}
																</MenuItem>
															))}
														</Select>
													</Grid>
												</Grid>
											</Grid>

											{errors.dob_day && errors.dob_month && errors.dob_year && touched.dob_day && touched.dob_month && touched.dob_year && (
												<FormHelperText error variant="outlined">
													Date of birth is required
												</FormHelperText>
											)}
										</Grid> */}

										{/* <Grid item xs={12} md={12} lg={12} style={{ display : 'none' }}>
											<Grid item>
												<InputLabel htmlFor={"gender"}>Gender</InputLabel>
											</Grid>
											<Grid item>
												<Select
													classes={{}}
													className={
														values.gender
															? classes.select
															: `${classes.dropDownPlaceholder} ${classes.select}`
													}
													value={values.gender ? values.gender : 0}
													fullWidth={true}
													id="gender"
													name="gender"
													variant="outlined"
													color="secondary"
													error={touched.gender && Boolean(errors.gender)}
													MenuProps={{
														getContentAnchorEl: null,
														anchorOrigin: {
															vertical: "bottom",
															horizontal: "left",
														},
														transformOrigin: {
															vertical: "top",
															horizontal: "left",
														},
														classes: {
															paper: classes.margin,
														},
													}}
													onChange={(e: any) => {
														e.persist();
														setFieldValue("gender", e.target.value);
														setFieldTouched("gender", true, false);
													}}>
													<MenuItem disabled value={0}>
														Gender
													</MenuItem>
													{genders.map((gender: any) => (
														<MenuItem key={gender.value} value={gender.value}>
															{gender.text}
														</MenuItem>
													))}
												</Select>
											</Grid>
											{errors.gender && touched.gender && (
												<FormHelperText error variant="outlined">
													gender is a required field
												</FormHelperText>
											)}
										</Grid> */}
										<Grid item xs={12} md={12} lg={12}>
											<FormTextField
												id="addressAccount"
												fieldName="street"
												value={values.street}
												touched={touched.street}
												error={errors.street}
												handleChange={handleChange}
											/>
										</Grid>
										<Grid item xs={12} md={6} lg={6}>
											<FormTextField
												id="cityAccount"
												fieldName="city"
												value={values.city}
												touched={touched.city}
												error={errors.city}
												handleChange={handleChange}
											/>
										</Grid>
										<Grid item xs={12} md={6} lg={6}>
											<Grid container direction="column">
												<Grid item>
													<InputLabel htmlFor={"state"}>State</InputLabel>
												</Grid>
												<Grid item>
													<Select
														className={
															values.state
																? classes.select
																: `${classes.dropDownPlaceholder} ${classes.select}`
														}
														value={values.state && values.state.id ? values.state.id : 99}
														fullWidth={true}
														id="stateAccount"
														name="state"
														variant="outlined"
														color="secondary"
														error={touched.state && Boolean(errors.state)}
														MenuProps={{
															getContentAnchorEl: null,
															anchorOrigin: {
																vertical: "bottom",
																horizontal: "left",
															},
															transformOrigin: {
																vertical: "top",
																horizontal: "left",
															},
														}}
														onChange={(e: any) => {
															e.persist();
															setFieldValue("state", {
																id: e.target.value,
															});
															setFieldTouched("state", true, false);
														}}>
														<MenuItem disabled value={99}>
															State
														</MenuItem>
														{states.allIds.map((item: any) => (
															<MenuItem key={item} value={item}>
																{states.items[item].title}
															</MenuItem>
														))}
													</Select>
													{errors.state && touched.state && (
														<FormHelperText error variant="outlined">
															state is a required field
														</FormHelperText>
													)}
												</Grid>
											</Grid>
										</Grid>
										<Grid item xs={12} md={5} lg={5}>
											<FormTextField
												textAlignCenter={true}
												id="zipAccount"
												fieldName="zip"
												value={values.zip}
												touched={touched.zip}
												error={errors.zip}
												handleChange={handleChange}
												onKeyPress={onKeyPress({ fieldname: "zip", length: 8 })}
											/>
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={12} md={6} lg={6}>
									<Grid container direction="row" spacing={3}>
										<Grid item xs={12} md={12} lg={12}>
											<FormTextField
												fieldName="account_number"
												value={values.account_number}
												touched={touched.account_number}
												error={errors.account_number}
												handleChange={handleChange}
												type="password"
											/>
										</Grid>
										
										
										<Grid item xs={12} md={12} lg={12}>
											<Grid item>
												<InputLabel htmlFor={"account_type"}>Account Type</InputLabel>
											</Grid>
											<Grid item>
												<Select
													classes={{}}
													className={
														values.account_type
															? classes.select
															: `${classes.dropDownPlaceholder} ${classes.select}`
													}
													value={values.account_type ? values.account_type : 0}
													fullWidth={true}
													id="account_type"
													name="account_type"
													variant="outlined"
													color="secondary"
													error={touched.account_type && Boolean(errors.account_type)}
													MenuProps={{
														getContentAnchorEl: null,
														anchorOrigin: {
															vertical: "bottom",
															horizontal: "left",
														},
														transformOrigin: {
															vertical: "top",
															horizontal: "left",
														},
														classes: {
															paper: classes.margin,
														},
													}}
													onChange={(e: any) => {
														e.persist();
														setFieldValue("account_type", e.target.value);
														setFieldTouched("account_type", true, false);
													}}>
													<MenuItem disabled value={0}>
														Account Type
													</MenuItem>
													{accountTypes.map((accountType: any) => (
														<MenuItem key={accountType.value} value={accountType.value}>
															{accountType.text}
														</MenuItem>
													))}
												</Select>
											</Grid>
											{errors.account_type && touched.account_type && (
												<FormHelperText error variant="outlined">
													account type is a required field
												</FormHelperText>
											)}
										</Grid>
										<Grid item xs={12} md={12} lg={12}>
											<FormTextField
												fieldName="bank_name"
												value={values.bank_name}
												touched={touched.bank_name}
												error={errors.bank_name}
												handleChange={handleChange}
											/>
										</Grid>
										<Grid item xs={12} md={12} lg={12}>
											<FormTextField
												fieldName="routing_number"
												value={values.routing_number}
												touched={touched.routing_number}
												error={errors.routing_number}
												handleChange={handleChange}
												type="password"
											/>
										</Grid>

										{/* <Grid item xs={12} md={12} lg={12} style={{ display: 'none' }}>
											<Grid item>
												<Grid>
													<InputLabel htmlFor={"id_number"} style={{display: 'flex'}}>
														<span>SSN</span>
														<Tooltip  title="Our payment platform requires SSN to process automated payouts." placement="top">
															<InfoIcon style={{fontSize: '1rem', color: '#64FECC', marginLeft: '5px'}} />
														</Tooltip>

													</InputLabel>
												</Grid>
												
											</Grid>
											<FormTextField
												fieldName="id_number"
												value={values.id_number}
												touched={touched.id_number}
												error={errors.id_number}
												handleChange={handleChange}
												type="password"
											/>
										</Grid> */}

										<Grid item xs={12} md={12} lg={12}>
											<FormControlLabel
												control={
													<Checkbox
														color="secondary"
														classes={{ root: classes.checkbox }}
														checked={values.termsCondition}
														onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
															event.persist();
															setFieldValue("termsCondition", event.target.checked);
															setFieldTouched("termsCondition", true, false);
														}}
														value="termsAndCondition"
													/>
												}
												label={<div><span>I accept the </span><a href="/account/terms-conditions">Terms and Conditions</a></div>}
											/>
											{errors.termsCondition && (
												<FormHelperText error variant="outlined">
													{errors.termsCondition}
												</FormHelperText>
											)}
										</Grid>
										{error && error.message && (
											<Grid item xs={12} md={12} lg={12}>
												<ErrorMessage error={error.errors} />
											</Grid>
										)}
									</Grid>
									<Box pt={5} pb={3}>
										<Grid container alignItems="center" justify="flex-end" spacing={3}>
											<Grid item>
												<Link
													onClick={() => {
														if (formikBag.dirty)
															setOpen(true);
														else
															setExpantionOpen(false);
													}}
													color="textPrimary"
													component="button"
													type="button">
													Cancel
												</Link>
											</Grid>
											<Grid item>
												<Button
													type="submit"
													variant="contained"
													color="secondary"
													size="large">
													Save
												</Button>
											</Grid>
										</Grid>
										<DiscardDialog
											open={open}
											resetForm={() => {
												formikBag.resetForm()
												setExpantionOpen(false);
											}}
											handleClose={handleClose}
										/>
									</Box>
								</Grid>
							</Grid>
						</form>
						<WarningDialoge open={openWarning} handleClose={() => setOpenWarning(false)} />
					</Box>
				);
			}}
		/>
	);
};
