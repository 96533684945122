import { connect } from "react-redux";
import { IAppState } from "../generic/interfaces";
import { Account } from "./account";
import { ThunkDispatch } from "redux-thunk";
import {
	setFilterText,
	setPastFilterText,
} from "./BookerEvent/ListBookerEvents/listBookerEventsSlice";
import {
	setFilterText as setFilterTextTalent,
	setPastFilterText as setPastFilterTextTalent,
} from "./TalentEvent/ListTalentEvents/listTalentEventsSlice";
import { updateUser } from "../SearchPage/searchPageSlice"
import { getAgentBandList } from "../generic/components/AgentBandList/agent-band-list-slice";

const mapStateToProps = (state: IAppState) => ({
	talentData: state.authentication.user,
	agentBandData: state.agentbandlistpage.agentBands
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
	setTextFilter: (textFilter: string) => dispatch(setFilterText(textFilter)),
	setPastFilterText: (pastTextFilter: string) =>
		dispatch(setPastFilterText(pastTextFilter)),
	updateUserData: () => dispatch(updateUser()),
	setTextFilterTalent: (textFilter: string) =>
		dispatch(setFilterTextTalent(textFilter)),
	setPastFilterTextTalent: (pastTextFilter: string) =>
		dispatch(setPastFilterTextTalent(pastTextFilter)),
	getAgentBandListData: () => dispatch(getAgentBandList())
});

export default connect(mapStateToProps, mapDispatchToProps)(Account);
