import React, { useState, useCallback, useEffect } from 'react'
import Cropper from 'react-easy-crop'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import { getOrientation } from 'get-orientation/browser'
import { getCroppedImg, getRotatedImage, getOriginalImage } from './canvasUtils'
import { styles } from './styles'
import HeaderDropzone from "../../../ImageUploader/header-uploader";
import CancelIcon from '@material-ui/icons/Cancel';

const ORIENTATION_TO_ANGLE = {
  '3': 180,
  '6': 90,
  '8': -90,
}

const ImageCropperDialog = ({ fileCallback, classes, fileUrl, existingFile, showUpload = null }) => {
  const [imageSrc, setImageSrc] = React.useState(null)
  const [originalImageSrc, setOriginalImageSrc] = React.useState(null)
  const [originalSelectedFile, setOriginalSelectedFile] = React.useState('')
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [rotation, setRotation] = useState(0)
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [croppedImageFile, setCroppedImageFile] = useState(null)
  const [reqFileUrl, setreqFileUrl] = useState(fileUrl);
  const [reqExistingFile, setreqExistingFile] = useState(existingFile);

  useEffect(() => {
    setreqExistingFile(existingFile);
    setreqFileUrl(fileUrl);
    setImageSrc(null);
    setOriginalSelectedFile(null);
    setCroppedImageFile(null);
    setOriginalImageSrc(null);
  }, [fileUrl])

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        imageSrc,
        croppedAreaPixels,
        rotation
      );
      

      var randomNum = Math.random().toString().substring(2, 8);
      let filename = new Date().getTime() + "_" + randomNum + ".jpg";
      let originalFilename = new Date().getTime() + "_" + randomNum + "_original.jpg";
        let originalFileBlob = "";
        if(imageSrc && originalSelectedFile)
          originalFileBlob = originalSelectedFile;
        else
          originalFileBlob = await getOriginalImage(imageSrc);

      var headerfile = new File([croppedImage], filename, { type: "image/jpeg", lastModified: new Date() });
      let fileselected = new File([originalFileBlob], originalFilename, { type: "image/jpeg", lastModified: new Date() });

      let calbackData = {
        originalSelectedFile: fileselected,
        croppedFile: headerfile
      };
      setImageSrc(null);
      setCroppedImageFile(URL.createObjectURL(croppedImage));
      fileCallback(calbackData);
      //setCroppedImage(croppedImage)
    } catch (e) {
      console.error(e)
    }
  }, [imageSrc, croppedAreaPixels, rotation])


  const repositionImage = async () => {
    if(originalImageSrc)
    {
      setImageSrc(originalImageSrc)
    }
    else if(reqExistingFile)
    {
      var filename = reqExistingFile.substring(reqExistingFile.lastIndexOf('/')+1);
      var bucketUrl = reqExistingFile.substring(0, reqExistingFile.lastIndexOf("/"));
      var originFilename = filename.substring(0, filename.lastIndexOf("."));
      originFilename = originFilename + '_original.jpg';
      var originalFileUrl =  bucketUrl + '/' + originFilename;
      UrlExists(originalFileUrl, 
          function(){ 
            setImageSrc(originalFileUrl); 
          }, 
          function(){ 
            setImageSrc(reqExistingFile); 
            setOriginalSelectedFile(reqExistingFile)
          });
    }
    
	  }

  const onFileChange = async (hdfile) => {
    if (hdfile) {
      const file = hdfile //e.target.files[0]
      setOriginalSelectedFile(file);
      let imageDataUrl = await readFile(file)

      // apply rotation if needed
      const orientation = await getOrientation(file)
      const rotation = ORIENTATION_TO_ANGLE[orientation]
      if (rotation) {
        imageDataUrl = await getRotatedImage(imageDataUrl, rotation)
      }

      setImageSrc(imageDataUrl);
      setOriginalImageSrc(imageDataUrl);
    }
  }

  return (
    <div>
      {imageSrc ? (
        <React.Fragment>
          <div className={classes.overlayButton}>
            <div className={classes.cropContainer}>
              <Cropper
                image={imageSrc}
                crop={crop}
                rotation={rotation}
                zoom={zoom}
                aspect={3 / 1.2}
                onCropChange={setCrop}
                onRotationChange={setRotation}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
                showGrid={false}
              />
            </div>
            <div>
              <Button
                onClick={showCroppedImage}
                variant="contained"
                className={classes.overlayCropButton}
              >
                Set Position
            </Button>
            </div>
          </div>
        </React.Fragment>
      ) : (
          <div className={classes.overlayButton}>
          {croppedImageFile ? (
            <div className={classes.overlayButton}>
              <img className={classes.displayImage} src={croppedImageFile}></img>
              <CancelIcon className={classes.closeShowImgButton} onClick={() => {
                      console.log('close');
                      setImageSrc(null);
                      setOriginalSelectedFile(null);
                      setCroppedImageFile(null);
                      setOriginalImageSrc(null);
                      console.log(fileUrl);
										}} fontSize="large"/>
            </div>
          ) : (
            <HeaderDropzone
              url={reqFileUrl}
              handleChange={(file) => {
                onFileChange(file);
              }}
              inputContent=""
            />
          )
          }
          {(reqFileUrl || originalImageSrc) &&
          <Button size="large" className={classes.overlayRepositionButton} onClick={() => {
														repositionImage()
													}} variant="contained">
											Reposition
										</Button>
          }
          {showUpload && !imageSrc && croppedImageFile &&
           <div className={classes.overlayDropZoneUploadButton}> {showUpload} </div>
          }
          </div>
          
        )}
    </div>
  )
}

function readFile(file) {
  return new Promise(resolve => {
    const reader = new FileReader()
    reader.addEventListener('load', () => resolve(reader.result), false)
    reader.readAsDataURL(file)
  })
}

function UrlExists(url, found, notfound)
{
    var img = new Image();
    img.onload = found; 
    img.onerror = notfound;
    img.src = url;
}

export default withStyles(styles)(ImageCropperDialog)