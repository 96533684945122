import React from "react";
import {
	Typography,
	Slider,
	Grid,
	Checkbox,
	FormControlLabel,
	Box,
} from "@material-ui/core";
import bandSize from "../../Assets/Group Size.svg";
import { IsLoggedIn } from "../../AuthBase/helpers/isAuthenticated";

const marks = [
	{
		value: 1,
		label: "1",
	},
	{
		value: 25,
		label: "25",
	},
];

export interface ISizeFilterProps {
	checked: any;
	size: number | null;
	favoritePage: boolean;
	handleChange: (e: any, value: any) => void;
	onChange: (e: any) => void;
}

class SizeFilter extends React.Component<ISizeFilterProps> {
	render() {
		return (
			<Box p={2}>
				<Grid container direction="column" spacing={4}>
					<Grid item>
						<Grid container direction="row" justify="flex-start">
							<Grid item>
								<Typography
									id="discrete-slider-always"
									gutterBottom
								>
									Talent Group Size
								</Typography>
							</Grid>
							<Grid item>
								<img
									height="25em"
									width="30em"
									src={bandSize}
									alt="band size"
									style={{
										paddingLeft: "8px",
										paddingBottom: "5px",
									}}
								/>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<Slider
							value={this.props.size || 25}
							min={1}
							max={25}
							step={1}
							aria-labelledby="discrete-slider-always"
							onChange={this.props.handleChange}
							valueLabelDisplay="auto"
							marks={marks}
							color="secondary"
						/>
					</Grid>
				</Grid>
				{!this.props.favoritePage && (
					<Grid item>
						<IsLoggedIn>
							<Grid item>
								<div>
									<FormControlLabel
										control={
											<Checkbox
												checked={this.props.checked}
												onChange={this.props.onChange}
											/>
										}
										label="Show Favorites Only"
										labelPlacement="end"
									/>
								</div>
							</Grid>
						</IsLoggedIn>
					</Grid>
				)}
			</Box>
		);
	}
}
export default SizeFilter;
