import React from "react";
import { Dialog, withStyles, Typography } from "@material-ui/core";
import { IConfirmationDialogProps } from "./interfaces";
import { CustomDialogTitle } from "../generic/components";
import { dialogStyles } from "./styles";
import { Link } from "@material-ui/core";
declare const window: any;

class ConfirmationDialog extends React.Component<IConfirmationDialogProps> {
	state = {dataLayersetCount: 0};

	componentDidUpdate(){
		if (this.props.userEmail && this.state.dataLayersetCount == 0) {
			this.setState({dataLayersetCount: 1})
			window.dataLayer.push({
				'event':'sign_up',
				'enhanced_conversion_data': {
				  "email": this.props.userEmail
				}
			  })
		}
	}

	render() {
		const {
			classes,
			handleClose,
			opened,
			handleResendConfirmation,
			userEmail,
			state,
		} = this.props;
		
		return (
			<Dialog
				fullWidth={true}
				maxWidth="sm"
				open={opened}
				scroll="body"
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				classes={{ paper: classes.dialogRoot }}
			>
				<CustomDialogTitle
					onClose={handleClose}
					id="alert-dialog-title"
				>
					<Typography variant="h4" style={{ marginBottom: "10px" }}>
						YOUR ACCOUNT HAS BEEN CREATED!
					</Typography>
					<Typography
						variant="body1"
						style={{ marginBottom: "10px" }}
					>
						Please check your email to verify your account.
					</Typography>
					<Typography
						variant="body2"
						style={{ marginBottom: "30px" }}
					>
						Did not receive on email?{" "}
						<Link
							component="button"
							style={{
								color: "white",
								fontSize: "12px",
								fontWeight: "initial",
							}}
							disabled={state && state.confirmationDialogRequest}
							onClick={() => handleResendConfirmation(userEmail)}
						>
							Send it Again.
						</Link>
					</Typography>
				</CustomDialogTitle>
			</Dialog>
		);
	}
}

export default withStyles(dialogStyles)(ConfirmationDialog);
