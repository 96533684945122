import { IMiniEventsRequest } from "../../interfaces";

export enum ListEventRequestActionType {
	BOOKER_REQUEST_LIST_REQUEST = "BOOKER_REQUEST_LIST_REQUEST",
	BOOKER_REQUEST_LIST_SUCCESS = "BOOKER_REQUEST_LIST_SUCCESS",
	BOOKER_REQUEST_LIST_FAILURE = "BOOKER_REQUEST_LIST_FAILURE",
	LOADMORE_BOOKER_REQUEST_LIST_REQUEST = "LOADMORE_BOOKER_REQUEST_LIST_REQUEST",
	LOADMORE_BOOKER_REQUEST_LIST_SUCCESS = "LOADMORE_BOOKER_REQUEST_LIST_SUCCESS",
	LOADMORE_BOOKER_REQUEST_LIST_FAILURE = "LOADMORE_BOOKER_REQUEST_LIST_FAILURE",
	UPDATED_MODAL_OPEN = "UPDATED_MODAL_OPEN",
	UPDATED_MODAL_CLOSE = "UPDATED_MODAL_CLOSE",
	UPDATE_BOOKER_REQUEST = "UPDATE_BOOKER_REQUEST",
	UPDATE_BOOKER_SUCCESS = "UPDATE_BOOKER_SUCCESS",
	UPDATE_BOOKER_FAILURE = "UPDATE_BOOKER_FAILURE",
	GET_FULL_ITEM_REQUEST = "GET_FULL_ITEM_REQUEST",
	GET_FULL_ITEM_SUCCESS = "GET_FULL_ITEM_SUCCESS",
	GET_FULL_ITEM_FAILURE = "GET_FULL_ITEM_FAILURE",
	EVENT_REQUEST_CANCEL_CONFIRM_DIALOG_OPEN = "EVENT_REQUEST_CANCEL_CONFIRM_DIALOG_OPEN",
	EVENT_REQUEST_CANCEL_CONFIRM_DIALOG_CLOSE = "EVENT_REQUEST_CANCEL_CONFIRM_DIALOG_CLOSE",
	CANCEL_BOOKER_REQUEST = "CANCEL_BOOKER_REQUEST",
	CANCEL_BOOKER_SUCCESS = "CANCEL_BOOKER_SUCCESS",
	CANCEL_BOOKER_FAILURE = "CANCEL_BOOKER_FAILURE",
	CONFIRM_EVENT_REQUEST_CONFIRM_DIALOG_OPEN = "CONFIRM_EVENT_REQUEST_CONFIRM_DIALOG_OPEN",
	CONFIRM_EVENT_REQUEST_CONFIRM_DIALOG_CLOSE = "CONFIRM_EVENT_REQUEST_CONFIRM_DIALOG_CLOSE",
	CONFIRM_EVENT_REQUEST = "CONFIRM_EVENT_REQUEST",
	CONFIRM_EVENT_SUCCESS = "CONFIRM_EVENT_SUCCESS",
	CONFIRM_EVENT_FAILURE = "CONFIRM_EVENT_FAILURE",
	EVENT_REQUEST_APPROVE_CONFIRM_DIALOG_OPEN = "EVENT_REQUEST_APPROVE_CONFIRM_DIALOG_OPEN",
	EVENT_REQUEST_APPROVE_CONFIRM_DIALOG_CLOSE = "EVENT_REQUEST_APPROVE_CONFIRM_DIALOG_CLOSE",
	APPROVE_EVENT_REQUEST = "APPROVE_EVENT_REQUEST",
	APPROVE_EVENT_SUCCESS = "APPROVE_EVENT_SUCCESS",
	APPROVE_EVENT_FAILURE = "APPROVE_EVENT_FAILURE",
	EVENT_REQUEST_REJECT_CONFIRM_DIALOG_OPEN = "EVENT_REQUEST_REJECT_CONFIRM_DIALOG_OPEN",
	EVENT_REQUEST_REJECT_CONFIRM_DIALOG_CLOSE = "EVENT_REQUEST_REJECT_CONFIRM_DIALOG_CLOSE",
	REJECT_EVENT_REQUEST = "REJECT_EVENT_REQUEST",
	REJECT_EVENT_SUCCESS = "REJECT_EVENT_SUCCESS",
	REJECT_EVENT_FAILURE = "REJECT_EVENT_FAILURE",
}

export interface IItemApproveEventRequest {
	approveModalOpened: boolean;
	approveIsRequested: boolean;
	approveIsSucceeded: boolean;
	approveError: any;
}

export interface IItemRejectEventRequest {
	rejectModalOpened: boolean;
	rejectIsRequested: boolean;
	rejectIsSucceeded: boolean;
	rejectError: any;
}

export interface IItemConfirmEventRequest {
	confirmModalOpened: boolean;
	confirmIsRequested: boolean;
	confirmIsSucceeded: boolean;
	confirmError: any;
}

export interface IItemCancelEventRequest {
	cancelModalOpened: boolean;
	cancelIsRequested: boolean;
	cancelIsSucceeded: boolean;
	cancelError: any;
}

export interface IItemEventRequestReducer {
	updatedModalOpened: boolean;
	updateIsRequested: boolean;
	error: any;
	getFullItemIsRequested: boolean;
	fullItem: any;
	fullItemError: any;
	cancel: IItemCancelEventRequest;
	confirm: IItemConfirmEventRequest;
	approve: IItemApproveEventRequest;
	reject: IItemRejectEventRequest;
}

export interface IItemEventRequest extends IMiniEventsRequest {
	itemState: IItemEventRequestReducer;
}

export interface IListEventRequestResponse {
	status: string;
	server_message: string;
	user_message: string;
	page: number;
	items_per_page: number;
	total_items: number;
	items: [IMiniEventsRequest];
}

export interface IListEventRequestReducer {
	isRequested: boolean;
	isSuccess: boolean;
	loadMoreIsRequested: boolean;
	loadMoreIsSuccess: boolean;
	bookerRequestPage: number;
	total_items: number;
	items: any;
	error: any;
}

export interface IListEventRequestAction {
	type: ListEventRequestActionType;
	payload: any;
}

export interface IListEventRequestProps {
	classes: any;
	listEventRequest: IListEventRequestReducer;
	listEventRequests: () => any;
	loadMoreEventRequests: () => any;
	getItemFullObject: (id?: number) => any;
	openUpdatedModal: (id?: number) => any;
	closeUpdatedModal: (id?: number) => any;
	updateEventRequest: (eventRequest: any) => any;
	openCancelModal: (id?: number) => any;
	closeCancelModal: (id?: number) => any;
	cancelEventRequest: (id?: number) => any;
}
