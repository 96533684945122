import React, { FC, ChangeEvent, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import InputAdornment from '@material-ui/core/InputAdornment';
import { handleInputChange } from "react-select/src/utils";

import Services from "../../generic/Services";

interface VerificationDialogProps {
	id: number;
	verifyOpen: boolean;
  handleClose: any;
  handleCancel: any;
}


export const VerificationDialog: React.FC<VerificationDialogProps> = function(this: any, { id, verifyOpen, handleClose, handleCancel }) {
  let state:any = {};

  const handleChange = (field: any, value:any) => {
    if (field === 1) state.amount1 = value;
    if (field === 2) state.amount2 = value;
  }


  const verifyACH = (id: number, amount1: number, amount2: number) => {
    Services.paymentMethods
      .verifyACH(id, amount1, amount2)
      .then((res) => {
        handleClose(res.message);
      })
      .catch((err) => {
        // console.log("error", err);
        if (err.message) handleClose(err.message);
        else handleClose("Something went wrong. Please try again later.")
      })
  }

  return (
    <Dialog
      open={verifyOpen}
      onClose={handleClose}
      aria-labelledby="verify-dialog-title"
      aria-describedby="verify-dialog-description">
      <DialogContent>
        <DialogContentText color="textPrimary" id="verify-dialog-description">
          You should have received 2 small deposits into this account of varying amounts.  Please enter those amounts below correctly to verify your account.
          <br/><br/>
          Note: These microdeposits can take 1-2 business days to appear in your account.  If you don't see them, please wait another business day and check again.
        </DialogContentText>
        <Grid container spacing={1}>
          <Grid item lg={4}>
            <TextField
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>
              }}
              inputProps={{ maxLength: 4 }}
              id={"amount1"}
              name={"Amount 1"}
              variant="outlined"
              color="secondary"
              margin="dense"
              fullWidth={true}
              placeholder={"Amount 1"}
              value={state.amount1}
              required={true}
              helperText={"Amount 1"}
              onChange={(e: any) => {
                handleChange(1, e.target.value);
              }}
            />
          </Grid>
          <Grid item lg={4}>
            <TextField
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>
                }}
                inputProps={{ maxLength: 4 }}
                id={"amount2"}
                name={"Amount 2"}
                variant="outlined"
                color="secondary"
                margin="dense"
                fullWidth={true}
                placeholder={"Amount 2"}
                value={state.amount2}
                required={true}
                helperText={"Amount 2"}
                onChange={(e: any) => {
                  handleChange(2, e.target.value);
                }}
              />
          </Grid>
        </Grid>
        
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            if (state.amount1 && state.amount2) {
              verifyACH(id, parseFloat(state.amount1) * 100, parseFloat(state.amount2) * 100);
            }
          }}
          color="default">
          Submit
        </Button>
        <Button onClick={handleCancel} color="default" autoFocus>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
};
