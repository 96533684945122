import React from "react";
import Grid from "@material-ui/core/Grid";
import ArrowLeft from "@material-ui/icons/ArrowLeft";
import Typography from "@material-ui/core/Typography";
import moment from "moment";

interface EventHistoryProps {
	isRequested: boolean;
	isSucceeded: boolean;
	isFailed: boolean;
	eventHistory: any;
	onBackClick: () => void;
}
export const EventHistory: React.FC<EventHistoryProps> = ({
	isRequested,
	isSucceeded,
	isFailed,
	eventHistory,
	onBackClick,
}) => {
	return (
		<Grid container direction="row">
			<Grid item xs={1} onClick={onBackClick}>
				<ArrowLeft
					color="secondary"
					fontSize="large"
					style={{ cursor: "pointer" }}
				/>
			</Grid>
			<Grid item xs={11}>
				<Grid container direction="column" spacing={2}>
					<Grid item xs={12} style={{ paddingBottom: "32px" }}>
						<Typography variant="body1" style={{ fontSize: "2em" }}>
							{isSucceeded &&
								eventHistory.items.length > 0 &&
								eventHistory.items[0].event.title}
						</Typography>
					</Grid>
					{isRequested && <div>Loading...</div>}
					{isFailed && <div>Error...</div>}
					<Grid item xs={12}>
						{isSucceeded &&
							eventHistory.items.length > 0 &&
							eventHistory.items.map(
								(event: any, index: number) => (
									<Grid
										key={index}
										container
										justify="space-between"
										direction="row"
										spacing={2}
									>
										<Grid item xs={3}>
											<Typography variant="subtitle2">
												{moment.unix(event.created_at).format("LL")}
											</Typography>
										</Grid>
										<Grid item xs={9}>
											<Typography variant="subtitle1">
												{event.action}
											</Typography>
										</Grid>
									</Grid>
								),
							)}
						{isSucceeded && eventHistory.items.length === 0 && (
							<div>There is no event histroy</div>
						)}
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};
