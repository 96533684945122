import { createSlice } from "redux-starter-kit";
import Services from "../../../generic/Services";
import { combineReducers } from "redux";
import createBookerEvent from "../CreateBookerEventPage/createEventSlice";

export interface EventObject {
	id: number;
	photo: {
		id: number;
		thumbnail: string;
		url: string;
	};
	title: string;
	location: string;
	created_at: string;
	date: string;
	venue_description: Array<{
		id: number;
		title: string;
	}>;
	event_type: { id: number; title: string };
	start_time: string;
	description: string;
	desired_set_length: number | null;
	number_of_attendees: string;
	state: {
		id: number;
		title: string;
	};
}

export interface ListBookerEventState {
	listEventRequested: boolean;
	listEventSucceeded: boolean;
	listEventFailure: boolean;

	deleteEventRequested: boolean;
	deleteEventSucceeded: boolean;
	deleteEventFailure: boolean;

	eventHistoryRequested: boolean;
	eventHistorySucceeded: boolean;
	eventHistoryFailure: boolean;

	loadMoreRequested: boolean;
	loadMoreSucceeded: boolean;
	pastLoadMoreSucceeded: boolean;
	loadMoredFailure: boolean;

	eventHistory: any;

	events: {
		items: EventObject[] | null;
		userMessage: string;
		total_items: number;
		page: number;
	};

	pastEvents: {
		items: EventObject[] | null;
		userMessage: string;
		total_items: number;
		page: number;
	};

	textFilter: string;

	pastTextFilter: string;

	error: {
		code: number;
		message: string;
		errors: [];
	};
}

const initialState: ListBookerEventState = {
	listEventRequested: false,
	listEventSucceeded: false,
	listEventFailure: false,

	deleteEventRequested: false,
	deleteEventSucceeded: false,
	deleteEventFailure: false,

	eventHistoryRequested: false,
	eventHistorySucceeded: false,
	eventHistoryFailure: false,

	loadMoreRequested: false,
	loadMoreSucceeded: false,
	pastLoadMoreSucceeded: false,
	loadMoredFailure: false,

	eventHistory: {},

	events: {
		items: null,
		userMessage: "",
		total_items: 0,
		page: 1,
	},

	pastEvents: {
		items: null,
		userMessage: "",
		total_items: 0,
		page: 1,
	},

	textFilter: "",

	pastTextFilter: "",

	error: {
		code: 0,
		message: "",
		errors: [],
	},
};

const listEventSlice = createSlice({
	slice: "listEvent",
	initialState,
	reducers: {
		listEventRequested(state, action) {
			state.listEventRequested = true;
			state.listEventSucceeded = false;
			state.listEventFailure = false;
		},
		listEventsSucceeded(state, action) {
			state.listEventRequested = false;
			state.listEventSucceeded = true;
			state.listEventFailure = false;
			state.events = action.payload;
			state.error = {
				code: 0,
				message: "",
				errors: [],
			};
		},
		listPastEventsSucceeded(state, action) {
			state.listEventRequested = false;
			state.listEventSucceeded = true;
			state.listEventFailure = false;
			state.pastEvents = action.payload;
			state.error = {
				code: 0,
				message: "",
				errors: [],
			};
		},
		listEventFailure(state, action) {
			state.listEventRequested = false;
			state.listEventSucceeded = false;
			state.listEventFailure = true;
			state.error = action.payload;
		},

		deleteEventRequested(state, action) {
			state.deleteEventRequested = true;
			state.deleteEventSucceeded = false;
			state.deleteEventFailure = false;
		},
		deleteEventSucceeded(state, action) {
			state.deleteEventRequested = false;
			state.deleteEventSucceeded = true;
			state.deleteEventFailure = false;
			if (state.events && state.events.items) {
				state.events.items =
					state.events &&
					state.events.items.filter(
						(item) => item.id !== action.payload.item.id,
					);
			}
			if (state.pastEvents && state.pastEvents.items) {
				state.pastEvents.items =
					state.pastEvents &&
					state.pastEvents.items.filter(
						(item) => item.id !== action.payload.item.id,
					);
			}
			state.events.total_items = state.events.total_items - 1;
			state.error = {
				code: 0,
				message: "",
				errors: [],
			};
		},
		deleteEventFailure(state, action) {
			state.deleteEventRequested = false;
			state.deleteEventSucceeded = false;
			state.deleteEventFailure = true;
			state.error = action.payload;
		},

		eventHistoryRequested(state, action) {
			state.eventHistoryRequested = true;
			state.eventHistorySucceeded = false;
			state.eventHistoryFailure = false;
		},
		eventHistorySucceeded(state, action) {
			state.eventHistoryRequested = false;
			state.eventHistorySucceeded = true;
			state.eventHistoryFailure = false;
			state.eventHistory = action.payload;
			state.error = {
				code: 0,
				message: "",
				errors: [],
			};
		},
		eventHistoryFailure(state, action) {
			state.eventHistoryRequested = false;
			state.eventHistorySucceeded = false;
			state.eventHistoryFailure = true;
			state.error = action.payload;
		},

		loadMoreRequested(state, action) {
			state.loadMoreRequested = true;
			state.loadMoreSucceeded = false;
			state.loadMoredFailure = false;
		},
		loadMoreSucceeded(state, action) {
			state.loadMoreRequested = false;
			state.loadMoreSucceeded = true;
			state.loadMoredFailure = false;
			if (state.events.items) {
				state.events.items = [
					...state.events.items,
					...action.payload.items,
				];
			}
			state.events.page = state.events.page + 1;
			state.error = {
				code: 0,
				message: "",
				errors: [],
			};
		},
		pastLoadMoreSucceeded(state, action) {
			state.loadMoreRequested = false;
			state.pastLoadMoreSucceeded = true;
			state.loadMoredFailure = false;
			if (state.pastEvents.items) {
				state.pastEvents.items = [
					...state.pastEvents.items,
					...action.payload.items,
				];
			}
			state.pastEvents.page = state.pastEvents.page + 1;
			state.error = {
				code: 0,
				message: "",
				errors: [],
			};
		},
		loadMoreFailure(state, action) {
			state.loadMoreRequested = false;
			state.loadMoreSucceeded = false;
			state.loadMoredFailure = true;
			state.error = action.payload;
		},

		setFilterText(state, action) {
			state.textFilter = action.payload;
		},

		setPastFilterText(state, action) {
			state.pastTextFilter = action.payload;
		},
	},
});

export const {
	listEventRequested,
	listEventsSucceeded,
	listPastEventsSucceeded,
	listEventFailure,
	deleteEventRequested,
	deleteEventSucceeded,
	deleteEventFailure,
	eventHistoryRequested,
	eventHistorySucceeded,
	eventHistoryFailure,
	loadMoreRequested,
	loadMoreSucceeded,
	pastLoadMoreSucceeded,
	loadMoreFailure,
	setFilterText,
	setPastFilterText,
} = listEventSlice.actions;

export default listEventSlice.reducer;

export const deleteBookerEvent = (eventId: number): any => async (
	dispatch: any,
) => {
	try {
		dispatch(deleteEventRequested());
		const res = await Services.events.deleteEvent(eventId);
		dispatch(deleteEventSucceeded(res));
	} catch (err) {
		dispatch(deleteEventFailure(err));
	}
};

export const listEventHistory = (id: number): any => async (dispatch: any) => {
	try {
		dispatch(eventHistoryRequested());
		const res = await Services.events.listEventHistoryById(id);
		dispatch(eventHistorySucceeded(res));
	} catch (err) {
		dispatch(eventHistoryFailure(err));
	}
};

export const listBookerEventsList = (textFilter?: string): any => async (
	dispatch: any,
	getState: any,
) => {
	try {
		dispatch(listEventRequested());
		const res = await Services.events.list({ text: textFilter });
		dispatch(listEventsSucceeded(res));
	} catch (err) {
		dispatch(listEventFailure(err));
	}
};

export const listBookerPastEventsList = (pastTextFilter: string): any => async (
	dispatch: any,
) => {
	try {
		dispatch(listEventRequested());
		const res = await Services.events.listPastEvents({
			text: pastTextFilter,
		});
		dispatch(listPastEventsSucceeded(res));
	} catch (err) {
		dispatch(listEventFailure(err));
	}
};

export const loadMore = (textFilter: string): any => async (
	dispatch: any,
	getState: any,
) => {
	try {
		const page = getState().account.bookerEvent.listBookerEvent.events.page;
		dispatch(loadMoreRequested());
		const res = await Services.events.list({
			page: page + 1,
			text: textFilter,
		});
		dispatch(loadMoreSucceeded(res));
	} catch (err) {
		dispatch(loadMoreFailure(err));
	}
};

export const loadMorePast = (pastTextFilter: string): any => async (
	dispatch: any,
	getState: any,
) => {
	try {
		const page = getState().account.bookerEvent.listBookerEvent.pastEvents
			.page;
		dispatch(loadMoreRequested());
		const res = await Services.events.listPastEvents({
			page: page + 1,
			text: pastTextFilter,
		});
		dispatch(pastLoadMoreSucceeded(res));
	} catch (err) {
		dispatch(loadMoreFailure(err));
	}
};

export const bookerEvent = combineReducers({
	listBookerEvent: listEventSlice.reducer,
	createBookerEvent,
});
