import { IError } from "../../Base/ErrorMessageBase";

const formatError = (errors: any) => {
	const keys = Object.keys(errors);
	return keys.length
		? {
				keys: Object.keys(errors),
				...errors,
		  }
		: [];
};

export const refineResponse = (response: any) => {
	return response.text().then((text: any) => {
		const data = text && JSON.parse(text);
		if (!response.ok) {
			const error: IError = {
				code: response.status,
				message: data.user_message || data.message || "Unkown error occured.",
				server_message: data.server_message || "",
				errors: data.errors || data.item ? formatError(data.errors || data.item) : [],
			};
			return Promise.reject(error);
		}
		return data;
	});
};
