import { connect } from "react-redux";
import { IAppState } from "../generic/interfaces";
import { ThunkDispatch } from "redux-thunk";
import { closeResendActivationLinkModal, resendActivationLink } from "./actions";
import ResendActivationLink from "./resend-activation-link";
import headerActions from "../Header/actions";

const mapStateToProps = (state: IAppState) => ({
	resendActivationLink: state.resendActivationLink,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
	handleClose: () => dispatch(closeResendActivationLinkModal()),
	handleOpenLogin: () => {
		dispatch(closeResendActivationLinkModal());
		setTimeout(() => {
			dispatch(headerActions.openLoginModal());
		}, 50);
	},
	handleOpenSignup: () => {
		dispatch(closeResendActivationLinkModal());
		setTimeout(() => {
			dispatch(headerActions.openSignupModal());
		}, 50);
	},
	handleResendActivationLinkSubmit: (data: { email: string }) =>
		dispatch(resendActivationLink(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResendActivationLink);
