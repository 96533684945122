import React from "react";
import { Button, CircularProgress, withStyles } from "@material-ui/core";
import { IApproveRequestProps } from "./interfaces";
import ConfirmationDialog from "../../ConfirmationDialog/confirmation-dialog";
import { styles } from "./styles";
import { TalentEventRequestActions } from "../../ListEventRequest/actions";
import { connect } from "react-redux";

class ApproveRequest extends React.Component<IApproveRequestProps> {
	handleOk(requestId: number) {
		this.props.dispatch(
			TalentEventRequestActions.approveEventRequest(requestId),
		);
	}

	handleCancel(requestId: number) {
		this.props.dispatch(
			TalentEventRequestActions.closeApproveModal(requestId),
		);
	}

	handleClick(requestId: number) {
		this.props.dispatch(
			TalentEventRequestActions.openApproveModal(requestId),
		);
	}

	render() {
		const {
			classes,
			disabled,
			requestId,
			opened,
			showProgress,
		} = this.props;
		return (
			<div>
				<div className={classes.wrapper}>
					<Button
						variant="outlined"
						color="primary"
						className={classes.buttonClassname}
						disabled={disabled}
						onClick={() => this.handleClick(requestId)}
					>
						Accept Request
					</Button>
					{showProgress && (
						<CircularProgress
							size={24}
							className={classes.buttonProgress}
						/>
					)}
				</div>
				<ConfirmationDialog
					opened={opened}
					handleOk={() => this.handleOk(requestId)}
					handleCancel={() => this.handleCancel(requestId)}
					title="Do you want to confirm"
				/>
			</div>
		);
	}
}

export default connect()(withStyles(styles)(ApproveRequest));
