import React, { useEffect } from "react";
import {
    Avatar,
    ListItem,
    Typography,
} from "@material-ui/core";
import { Link, useLocation } from "react-router-dom";
import { useAgentBandListStyles } from "./styles";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import { RemoveBandDialog } from "../RemoveBandDialog/remove-band-dialog";
import Services from "../../Services";

export interface AgendBandListProps {
    agentBands: any;
}

export const AgentBandListData: React.FC<AgendBandListProps> = ({
    agentBands
}) => {
    let [bandsList, setbandsList] = React.useState();
    const [getNew, setGetNew] = React.useState(true);
    let [filterCountList, setfilterCountList] = React.useState<any>();

    const location = useLocation();
    const [loc, setLoc] = React.useState(location.pathname);
    const classes = useAgentBandListStyles();
    const [toggleMenu, setToggleMenu] = React.useState(false);
    const [removebandDialogOpen, setremovebandDialogOpen] = React.useState(false);

    
    React.useEffect(() => {
		const time = setTimeout(() => setGetNew(!getNew), 30000)
		Services.agent.getAgentBandBadgeCount().then((res) => {
            if(res.status === "SUCCESS")
            {
                if(agentBands)
                {
                    if(res.item.length > 0)
                    {
                        let filterCountList1 = res.item.filter(function(value: any){ return value.band_id == agentBands.band_id;});
                        if(filterCountList1.length > 0)
                        {
                            setfilterCountList(filterCountList1[0]);
                        }
                        else
                        {
                            setfilterCountList({
                                band_id: agentBands.band_id,
                                unread_message_count: 0
                            });
                        }
                    }
                    else
                    {
                        setfilterCountList({
                            band_id: agentBands.band_id,
                            unread_message_count: 0
                        });
                    }
                    
                }
               
            }
				
		})
		return () => clearTimeout(time)
    }, [getNew]);
    
    
    

    const closeRemovebandDialog = () => {
        setremovebandDialogOpen(false);
    }
    
    return (
        <><ul className={(location.pathname === `/account/edit-band/${agentBands.agent_band.id}` || 
        location.pathname === `/account/photo-gallery/${agentBands.agent_band.id}` || 
        location.pathname === `/account/band-billing-information/${agentBands.agent_band.id}` || 
        location.pathname === `/account/active-event/band/${agentBands.agent_band.id}` || 
        location.pathname === `/account/past-event/band/${agentBands.agent_band.id}` ||
        location.pathname === `/account/messages/band/${agentBands.agent_band.id}` ||
            toggleMenu) ? classes.submenulist: classes.submenulistmain}>
            <ListItem className={classes.sidebarSubListMenu}>
                <Avatar alt={agentBands.agent_band.name} src={agentBands.agent_band.photo.thumbnail} className={classes.sidebarSubAvtar} />
                <Typography variant="subtitle2" classes={{ root: classes.gutters }} className={classes.sidebarSubListMenu}>
                    <span>{agentBands.agent_band.name}</span> <ArrowDropDownIcon className={location.pathname === `/account/edit-band/${agentBands.agent_band.id}` || 
        location.pathname === `/account/band-billing-information/${agentBands.agent_band.id}` || 
        location.pathname === `/account/active-event/band/${agentBands.agent_band.id}` || 
        location.pathname === `/account/past-event/band/${agentBands.agent_band.id}` ||
        location.pathname === `/account/messages/band/${agentBands.agent_band.id}` ||
            toggleMenu ? classes.submenulist: ''} onClick={function() {
                        setToggleMenu(!toggleMenu)
                    }} />
                    {filterCountList && filterCountList.band_id === agentBands.band_id && filterCountList.unread_message_count > 0 ? (
                        <small>{filterCountList.unread_message_count}</small>
                    ) : (<></>)}
                    
                </Typography>
            </ListItem>
            <ul>
                <ListItem button className={classes.nested} selected={location.pathname === `/account/edit-band/${agentBands.agent_band.id}`}>
                    <Link to={{
                    pathname: `/account/edit-band/${agentBands.agent_band.id}`}} className={classes.paperLink}>
                        Profile
                    </Link>
                </ListItem>
                <ListItem button className={classes.nested} selected={location.pathname === `/account/photo-gallery/${agentBands.agent_band.id}`}>
                    <Link to={{
                    pathname: `/account/photo-gallery/${agentBands.agent_band.id}`}} className={classes.paperLink}>
                        Profile Images
                    </Link>
                </ListItem>
                <ListItem button className={classes.nested} selected={location.pathname === `/account/band-billing-information/${agentBands.agent_band.id}`}>
                    <Link to={{
                    pathname: `/account/band-billing-information/${agentBands.agent_band.id}`}} className={classes.paperLink}>
                        Billing Information
                    </Link>
                </ListItem>
                <ListItem className={classes.nested2}>
                    <Typography classes={{ root: classes.gutters }}>My Events</Typography>
                </ListItem>
                <ListItem button className={classes.nested3} selected={location.pathname === `/account/active-event/band/${agentBands.agent_band.id}`}>
                    <Link  to={{
                    pathname: `/account/active-event/band/${agentBands.agent_band.id}`}} className={classes.paperLink}>
                        Active
                    </Link>
                </ListItem>
                <ListItem button className={classes.nested3} selected={location.pathname === `/account/past-event/band/${agentBands.agent_band.id}`}>
                    <Link to={{
                    pathname: `/account/past-event/band/${agentBands.agent_band.id}`}} className={classes.paperLink}>
                        Past
                    </Link>
                </ListItem>
                <ListItem button className={classes.nested2} selected={location.pathname === `/account/messages/band/${agentBands.agent_band.id}`}>
                    <Link to={{
                    pathname: `/account/messages/band/${agentBands.agent_band.id}`}} className={classes.paperLink}>
                        Messages {filterCountList && filterCountList.band_id === agentBands.band_id && filterCountList.unread_message_count > 0 ? (
                        <small>{filterCountList.unread_message_count}</small>
                    ) : (<></>)}
                    </Link>
                </ListItem>
                <ListItem button className={classes.removeLink} selected={location.pathname === `/account/removeband`}>
                    <Link to={{
                    pathname: `/account/edit-band/${agentBands.agent_band.id}`}} onClick={() => {
						setremovebandDialogOpen(true);
					}} >
                        <span>Remove Band</span>
                    </Link>
                    {removebandDialogOpen &&
                        <RemoveBandDialog closeRemovebandDialog={closeRemovebandDialog} showRemovebandDialog={removebandDialogOpen} bandData={agentBands.agent_band}  />
                    }

                </ListItem>
                </ul>
            </ul>
        </>
    );
};
