import React from "react";
import { IFilterBandsProps } from "./interfaces";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Icon from "@material-ui/core/Icon";
import searchBarIcon from "../../Assets/Search Bar Icon.svg";
import moment from "moment";

interface ChipData {
	day: string;
}

class FilterBands extends React.Component<IFilterBandsProps> {
	public searchbuttonRef: React.RefObject<any>;

	public state = {
		pricesPopover: null,
		genresPopover: null,
		datesPopover: null,
		sizePopover: null,
		locationPopover: null,
		moreFilters: null,
		sort: null,
	};

	constructor(props: IFilterBandsProps) {
		super(props);
		this.handlePopoverClose = this.handlePopoverClose.bind(this);

		this.searchbuttonRef = React.createRef();
	}

	public handleKeyDown = (e: any) => {
		if (e.key === "Enter") {
			this.searchbuttonRef.current.focus();
		}
	};

	public handlePopoverClick = (name: string) => (e: any) => {
		this.setState({
			[name]: e.currentTarget,
		});
	};

	public handlePopoverClose = (name: string) => (e: any) => {
		this.setState({
			[name]: null,
		});
	};

	public onChange = (e: any) => {
		this.props.handleKeywordChange(e);
		setTimeout(this.props.search, 500);
	};

	public handleDelete = (chipToDelete: ChipData) => () => {
		const days = this.props.filters.days.filter((day: any) => {
			return day.day !== chipToDelete.day;
		});
		const formatedDays = days.map((x: any) => moment(x.day, "YYYY-MM-DD").format("ddd MMM DD YYYY HH:mm:ss"));
		this.props.saveDates(formatedDays);
	};

	public handleDeleteGenre = (chipToDelete: { id: number; title: string }) => () => {
		const x = this.props.filters.genres.filter((item: { id: number; title: string }) => {
			return item !== chipToDelete;
		});
		this.props.saveGenres(x);
	};

	public render() {
		const { filters, resources, search, sort } = this.props;
		let favoritePage = false;
		if (this.props.favoritePage) {
			favoritePage = true;
		}
		return (
			<Grid container direction="row" justify="center" alignItems="center" spacing={1}>
				<Grid item xs={12} md={12} lg={11} alignItems="center">
					<OutlinedInput
						labelWidth={0}
						placeholder="Search for a band"
						onChange={this.onChange}
						onKeyDown={this.handleKeyDown}
						value={filters.text}
						fullWidth={true}
						margin="dense"
						endAdornment={
							<IconButton
								color="primary"
								size="medium"
								aria-label="Search"
								onClick={search}
								ref={this.searchbuttonRef}>
								<Icon fontSize="large">
									<img alt="search icon" src={searchBarIcon} />
								</Icon>
							</IconButton>
						}
					/>
				</Grid>
			</Grid>
		);
	}
}

export default FilterBands;
