import React, { useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Drawer from "@material-ui/core/Drawer";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Icon from "@material-ui/core/Icon";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Box from "@material-ui/core/Box";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import { makeStyles, Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import NotificationsOutlinedIcon from "@material-ui/icons/NotificationsOutlined";
import TextsmsOutlinedIcon from "@material-ui/icons/TextsmsOutlined";
import TextsmsIcon from "@material-ui/icons/Textsms";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import MenuIcon from "@material-ui/icons/Menu";
import useTheme from "@material-ui/core/styles/useTheme";
import ImagePlaceholder from "../Assets/ImagePlaceholder.png";
import SignupDialog from "../SignupDialogNew";
import LoginDialog from "../LoginDialog";
import headerActions from "./actions";
import searchBarIcon from "../Assets/Search Bar Icon.svg";
import ConfirmationDialog from "../ConfirmationDialog";
import ForgetPassword from "../ForgetPassword";
import ResendActivationLink from "../ResendActivationLink";
import { ReactComponent as Logo } from "../Assets/Repsy Logo Green.svg";
import { ReactComponent as LogoWhite } from "../Assets/Repsy Logo White.svg";
import { userActions } from "../AuthBase";
import { IHeaderProps } from "./interfaces";
import { IsLoggedIn, IsAnonymous, IsBooker, IsTalent, IsAgent, IsTalentBooker, IsTalentAgent } from "../AuthBase/";
import { openForgetPasswordModal } from "../ForgetPassword/actions";
import { NavLink, Link, useHistory, useLocation } from "react-router-dom";
import LogoType from "../Assets/repsy-logotype-white.png";
import Badge from "@material-ui/core/Badge";
import Tooltip from "@material-ui/core/Tooltip";
import NotificationItem from "./NotificationItem"
import NotificationsIcon from '@material-ui/icons/Notifications';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import { openResendActivationLinkModal } from "../ResendActivationLink/actions";


const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		toolbar: {
			minHeight: 128,
			alignItems: "flex-start",
			justifyContent: "space-between",
			paddingTop: theme.spacing(1),
			paddingBottom: theme.spacing(2),
		},
		menuButton: {
			//marginRight: theme.spacing(1),
			marginLeft: "0",
			paddingRight: "0"
		},
		list: {
			width: 250,
		},
		iconSearchBar: {
			height: "100%",
		},
		searchBar: {
			height: "39px",
		},
		searchBarWhite: {
			height: "39px",
			'& fieldset': {
				borderColor: 'white !important'
			}
			// border: "1px solid",
			// borderRadius: "3px",
			// outline: 0
		},
		iconSearchBarWhite: {
			color: "#0F24FD",
			width: "2em",
			height: "100%",
			padding: "0px",
			backgroundColor: "#fff",
			borderTopLeftRadius: "0px",
			borderTopRightRadius: '5px',
			borderBottomLeftRadius: "0px",
			borderBottomRightRadius: "5px"
		},
		iconSearchBarPrimary: {
			color: "#0F24FD",
			width: "2em",
			height: "100%",
			padding: "0px",
			backgroundColor: "#64FECC",
			borderTopLeftRadius: "0px",
			borderTopRightRadius: '5px',
			borderBottomLeftRadius: "0px",
			borderBottomRightRadius: "5px"
		},
		bigAvatar: {
			border: `5px solid ${theme.palette.primary.main}`,
			margin: 10,
			width: 60,
			height: 60,
		},
		bigTalentAvatar: {
			border: `5px solid ${theme.palette.secondary.main}`,
			margin: 10,
			width: 60,
			height: 60,
		},
		MenuItem: {
			textDecoration: "none",
			color: "#ddd",
		},
		lastMenuItem: {
			//marginTop: theme.spacing(1),
			color: 'red'
		},
		toolTip: {
			backgroundColor: theme.palette.background.paper,
			textAlign: "center",
			width: "120px",
			border: `1px solid ${theme.palette.secondary.main}`,
			padding: "1px"
		},
		notificationButton: {
			width: "48px",
			height: "52px",
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		notificationList: {
			maxHeight: 290,
			overflow: "hidden"
		}
	}),
);

const Header = (props: IHeaderProps) => {
	const classes = useStyles();
	const { search } = props;
	const btnRef = React.useRef<HTMLButtonElement>(null);
	const theme = useTheme();

	const handleKeyDown = (e: any) => {
		if (e.key === "Enter" && btnRef && btnRef.current) {
			btnRef.current.focus();
		}
	};

	const user = JSON.parse(localStorage.getItem("user") || `{"photo":null}`);

	const [searchText, setSearchText] = useState("");

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const [anchorEl2, setAnchorEl2] = React.useState<null | HTMLElement>(null);
	const [anchorElNot, setAnchorElNot] = React.useState<null | HTMLElement>(null);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClickNotification = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorElNot(event.currentTarget);
		props.openNotification()

		// if( props.header.notification.notifications.length > 0){
		// 	setAnchorElNot(event.currentTarget);
		// 	props.openNotification()
		// }
	};

	const handleClickHowItWorks = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl2(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleCloseNotification = () => {
		setAnchorElNot(null);
	};

	const handleCloseHowItWorks = () => {
		setAnchorEl2(null);
	};

	const [state, setState] = React.useState({
		top: false,
		left: false,
		bottom: false,
		right: false,
	});

	const toggleDrawer = (side: DrawerSide, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
		if (
			event.type === "keydown" &&
			((event as React.KeyboardEvent).key === "Tab" || (event as React.KeyboardEvent).key === "Shift")
		) {
			return;
		}

		setState({ ...state, [side]: open });
	};

	const history = useHistory();
	const location = useLocation();
	// return to the same page after login
	const from = location.pathname || "/";
	const login = () => {
		history.replace(from);
	};



	type DrawerSide = "top" | "left" | "bottom" | "right";

	const sideList = (side: DrawerSide) => (
		<div
			className={classes.list}
			role="presentation"
			onClick={toggleDrawer(side, false)}
			onKeyDown={toggleDrawer(side, false)}>
			<List>
				<ListItem button key="home">
					<Link to="/" className={classes.MenuItem}>
						<ListItemText primary="Home"></ListItemText>
					</Link>
				</ListItem>
				<IsLoggedIn>
					<ListItem button key="Account">
						{/* <IsBooker> */}
						<Link to="/account/edit-profile" className={classes.MenuItem}>
							<ListItemText primary="Account"></ListItemText>
						</Link>
						{/* </IsBooker> */}
						{/* <IsTalent>
							<Link to="/account/edit-profile" className={classes.MenuItem}>
								<ListItemText primary="Account"></ListItemText>
							</Link>
							<Link to="/account/edit-profile" onClick={handleClose} className={classes.MenuItem}>
								Account
							</Link>
						</IsTalent> */}
					</ListItem>
					<IsTalentBooker>
						<ListItem button key="My Events">
							<Link to="/account/active-event" className={classes.MenuItem}>
								<ListItemText primary="My Events"></ListItemText>
							</Link>
						</ListItem>
					</IsTalentBooker>
				</IsLoggedIn>

				{/* <IsTalentAgent>
					<ListItem button key="talent">
						<Link to="/how-it-works/talent" className={classes.MenuItem}>
							<ListItemText primary="How It Works (Talent)"></ListItemText>
						</Link>
					</ListItem>
				</IsTalentAgent>
				
				<IsBooker>
					<ListItem button key="booker">
						<Link to="/how-it-works/booker" className={classes.MenuItem}>
							<ListItemText primary="How It Works (Booker)"></ListItemText>
						</Link>
					</ListItem>
				</IsBooker> */}


				<ListItem>
					<div className={classes.MenuItem}>
						<ListItemText primary="How It Works"></ListItemText>
					</div>
				</ListItem>
				<ListItem button key="talent" style={{ paddingLeft: '36px' }}>
					<Link to="/how-it-works/talent" className={classes.MenuItem}>
						<ListItemText primary="Talent" style={{ margin: '0px' }}></ListItemText>
					</Link>
				</ListItem>
				<ListItem button key="booker" style={{ paddingLeft: '36px' }}>
					<Link to="/how-it-works/booker" className={classes.MenuItem}>
						<ListItemText primary="Booker" style={{ marginTop: '0px' }}></ListItemText>
					</Link>
				</ListItem>

				<ListItem button key="about">
					<Link to="/about" className={classes.MenuItem}>
						<ListItemText primary="About"></ListItemText>
					</Link>
				</ListItem>
				<ListItem button key="contact">
					<Link to="/contact" className={classes.MenuItem}>
						<ListItemText primary="Contact"></ListItemText>
					</Link>
				</ListItem>
			</List>
			<Divider />
			<IsLoggedIn>
				<IsTalentBooker>
					<List>
						{["Notifications", "Messages"].map((text, index) => (
							<ListItem button key={text}>
								<ListItemIcon>
									{index % 2 === 0 ? (
										<NotificationsOutlinedIcon color="secondary" />
									) : (
										<TextsmsOutlinedIcon color="secondary" />
									)}
								</ListItemIcon>
								<Link to={`/account/${text == 'Notifications' ? 'list-notification' : text}`} className={classes.MenuItem}>
									<ListItemText primary={text}></ListItemText>
								</Link>
							</ListItem>
						))}
					</List>
				</IsTalentBooker>
				<Divider />
			</IsLoggedIn>
			<List>
				<IsLoggedIn>
					<ListItem button key="contact" onClick={props.logout}>
						<ListItemText primary="Logout"></ListItemText>
					</ListItem>
				</IsLoggedIn>
				<IsAnonymous>
					<ListItem button onClick={props.openSignupModal}>
						<ListItemText primary={"SIGN UP"}></ListItemText>
					</ListItem>
					<ListItem button onClick={props.openLoginModal}>
						<ListItemText primary={"LOGIN"}></ListItemText>
					</ListItem>
				</IsAnonymous>
			</List>
		</div>
	);

	const matches = useMediaQuery(theme.breakpoints.up("md"));

	return matches ? (
		<Box p={3}>
			<Grid container justify="space-between" alignItems="center">
				<Box display="flex" flexDirection="row" alignItems="center">
					<NavLink to="/">
						{props.page === "talent" || props.page === "about" || props.isWhiteHeader ?
							<LogoWhite width="6em" /> :
							<Logo width="6em" />
						}
						<img src={LogoType} style={{ width: 135, margin: "0px 0px 10px 10px", }} />
						{/* <img src={LogoType} width="180px"></img> */}
					</NavLink>

					{props.page !== "search" && props.page !== "home" && (
						<IsLoggedIn>
							<Grid item style={{ marginLeft: '40px' }}>
								<OutlinedInput
									className={`${props.page === "talent" || props.page === "about" || props?.isWhiteHeader ? classes.searchBarWhite : classes.searchBar}`}
									labelWidth={0}
									onKeyDown={handleKeyDown}
									onChange={(e) => {
										setSearchText(e.target.value);
									}}
									fullWidth={true}
									margin="dense"
									endAdornment={
										<Link to="/search" className={classes.iconSearchBar}>
											<IconButton
												//color="primary"
												className={`${props.page === "talent" || props.page === "about" || props?.isWhiteHeader ? classes.iconSearchBarWhite : classes.iconSearchBarPrimary}`}
												//style={{backgroundColor: `${props?.isWhiteHeader ? '#0F24FD' : '#64FECC'}`, color: `${props?.isWhiteHeader ? '#fff' : '#0F24FD'}`}}
												size="medium"
												aria-label="Search"
												onClick={() => {
													search(searchText);
												}}
												ref={btnRef}>
												<Icon fontSize="large" style={{ color: "white" }}>
													<img src={searchBarIcon} alt="searchIcon" />
												</Icon>
											</IconButton>
										</Link>
									}
								/>
							</Grid>
						</IsLoggedIn>
					)}
				</Box>
				<Grid item>
					<Grid container justify="flex-end" alignItems="center" spacing={3}>
						<Grid item>
							<Button
								variant="text"
								color="default"
								aria-controls="howItWorksMenu"
								aria-haspopup="true"
								onClick={handleClickHowItWorks}>
								HOW IT WORKS
							</Button>
							<Menu
								MenuListProps={{
									disablePadding: true,
								}}
								id="howItWorksMenu"
								anchorEl={anchorEl2}
								keepMounted
								open={Boolean(anchorEl2)}
								onClose={handleCloseHowItWorks}
								getContentAnchorEl={null}
								anchorOrigin={{
									vertical: "bottom",
									horizontal: "center",
								}}
								transformOrigin={{
									vertical: "top",
									horizontal: "center",
								}}>
								<MenuItem onClick={handleCloseHowItWorks}>
									<Link
										to="/how-it-works/talent"
										onClick={handleCloseHowItWorks}
										className={classes.MenuItem}>
										Talent
									</Link>
								</MenuItem>
								<MenuItem onClick={handleCloseHowItWorks}>
									<Link
										to="/how-it-works/booker"
										onClick={handleCloseHowItWorks}
										className={classes.MenuItem}>
										Booker
									</Link>
								</MenuItem>
							</Menu>
						</Grid>
						<Grid item>
							<Link to="/about" className={classes.MenuItem}>
								<Button variant="text" color="default">
									ABOUT
								</Button>
							</Link>
						</Grid>

						<Grid item style={{ display: 'none' }}>
							<Link to="/blogs" className={classes.MenuItem}>
								<Button variant="text" color="default">
									BLOG
								</Button>
							</Link>
						</Grid>

						<Grid item>
							<Link to="/contact" className={classes.MenuItem}>
								<Button variant="text" color="default">
									CONTACT
								</Button>
							</Link>
						</Grid>
						<Divider
							style={{
								width: "2px",
								height: "5em",
								margin: "2px 10px",
							}}
							orientation="vertical"
							color="secondary"
						/>
						<IsAnonymous>
							<Grid item>
								<Button variant="text" color="default" onClick={props.openLoginModal}>
									LOGIN
								</Button>
								<LoginDialog
									opened={props.header.loginModalOpened}
									handleClose={headerActions.closeLoginModal}
									handleOpenSignup={headerActions.openSignupModal}
									handleLoginSubmit={userActions.login}
									handleOpenForgotPassword={openForgetPasswordModal}
									handleOpenResendActivationLink={openResendActivationLinkModal}
									login={login}
								/>
							</Grid>
							<Grid item>
								<Button
									style={{ height: 'auto', padding: '7px 20px 5px 20px', backgroundColor: `${props.page === "talent" || props.page === "about" || props?.isWhiteHeader ? '#0F24FD' : '#64FECC'}`, color: `${props.page === "talent" || props.page === "about" || props?.isWhiteHeader ? '#fff' : '#0F24FD'}` }}
									variant="contained"
									size="large"
									onClick={props.openSignupModal}>
									SIGN UP
								</Button>
								{props.header.signupModalOpened &&
									<SignupDialog
										opened={props.header.signupModalOpened}
										handleClose={headerActions.closeSignupModal}
										handleOpenLogin={props.openLoginModal}
										handleSignupSubmit={userActions.register}
										handleSignUpConfirmationOpen={headerActions.openConfirmationModal}
										handleLoginSubmit={userActions.login}
									/>
								}
								<ConfirmationDialog
									opened={props.header.confirmationModalOpened}
									handleClose={headerActions.closeConfirmationModal}
									handleResendConfirmation={userActions.resendConfirmationEmail}
								/>
							</Grid>
							<Grid>
								<ForgetPassword
								//opened={props.header.forgetPasswordModalOpened}
								/>
							</Grid>
							<Grid>
								<ResendActivationLink
								//opened={props.header.forgetPasswordModalOpened}
								/>
							</Grid>
						</IsAnonymous>
						{props.authentication.isLoggedIn === true ? (
							<>
								<Grid item className={classes.notificationButton}>
									{props.header.notification.new ? (
										<Button variant="text"
											color="default"
											aria-controls="notification-menu"
											aria-haspopup="true"
											onClick={handleClickNotification}>
											<Badge color="error" variant="dot" style={{ width: 18, height: 20 }}
												anchorOrigin={{
													vertical: "bottom",
													horizontal: "right",
												}}>
												<NotificationsIcon color="secondary" />
											</Badge>
										</Button>
									) : (
										<Button variant="text"
											color="default"
											aria-controls="notification-menu"
											aria-haspopup="true"
											onClick={handleClickNotification}>
											<NotificationsOutlinedIcon />
										</Button>
									)}
									<Menu
										MenuListProps={{
											disablePadding: true,
										}}
										id="notification-menu"
										anchorEl={anchorElNot}
										keepMounted
										open={Boolean(anchorElNot)}
										onClose={handleCloseNotification}
										getContentAnchorEl={null}
										anchorOrigin={{
											vertical: "bottom",
											horizontal: "center",
										}}
										className={classes.notificationList}
										classes={{ paper: classes.notificationList }}
										transformOrigin={{
											vertical: "top",
											horizontal: "center",
										}}>

										<SimpleBar style={{ width: 450, maxHeight: 160 }}>
											{props.header.notification.notifications.map((item, index) => <NotificationItem key={index} authentication={props.authentication} handleCloseNotification={handleCloseNotification} handleNotificationSeen={props.notificationSeen} index={index} data={item} />)}
											{!props.header.notification.notifications || props.header.notification.notifications.length <= 0 &&
												<span>No New Notifications<br /></span>
											}
											{props.header.notification.notifications && props.header.notification.notifications.length > 0 &&
												<IsAgent>
													<Link
														to="/account/list-notification"
														onClick={handleCloseNotification}
														className={classes.MenuItem}>
														View All
													</Link>
												</IsAgent>
											}
										</SimpleBar>

									</Menu>
								</Grid>
								<Grid item>
									{props.header.messagesNotification.messageCount > 0 ? (
										<Badge color="error" variant="dot">
											<Tooltip disableFocusListener classes={{ tooltip: classes.toolTip }} disableTouchListener title={<span><p>You have {props.header.messagesNotification.messageCount}</p><p>new messages.</p></span>}>
												<TextsmsIcon cursor="pointer" color="secondary" onClick={() => {
													history.push("/account/messages");
													props.resetMessageNotification();
												}} />
											</Tooltip>
										</Badge>
									) : <TextsmsOutlinedIcon cursor="pointer" onClick={() => history.push("/account/messages")} />}
								</Grid>
								<Grid item>
									<Button
										variant="text"
										color="default"
										aria-controls="simple-menu"
										aria-haspopup="true"
										onClick={handleClick}>
										{props?.authentication?.user?.role === "BOOKER" ? (
											<Avatar
												alt="Talent Avatar"
												src={user.photo !== null ? user.photo.url : ''}
												className={classes.bigAvatar}
												style={{backgroundColor: '#1c1762', color: '#fff'}}
											>
												{user?.fname?.length > 0 && user?.lname?.length > 0 && 
												<span>{user?.fname[0]}{user?.lname[0]}</span>
												}
											</Avatar>
										)
											:
											(<Avatar
												alt="Talent Avatar"
												src={user.photo !== null ? user.photo.url : ImagePlaceholder}
												className={
													props.authentication.user.role === "BOOKER"
														? classes.bigAvatar
														: classes.bigTalentAvatar
												}
											>
												{user?.fname?.length > 0 && user?.lname?.length > 0 && 
													<span>{user?.fname[0]}{user?.lname[0]}</span>
												}
											</Avatar>)
										}


									</Button>
									<Menu
										MenuListProps={{
											disablePadding: true,
										}}
										id="simple-menu"
										anchorEl={anchorEl}
										keepMounted
										open={Boolean(anchorEl)}
										onClose={handleClose}
										getContentAnchorEl={null}
										anchorOrigin={{
											vertical: "bottom",
											horizontal: "center",
										}}
										transformOrigin={{
											vertical: "top",
											horizontal: "center",
										}}>
										<IsAgent>
											<MenuItem onClick={handleClose}>
												<Link
													to="/account/dashboard"
													onClick={handleClose}
													className={classes.MenuItem}>
													Dashboard
												</Link>
											</MenuItem>
										</IsAgent>
										<MenuItem onClick={handleClose}>
											<Link
												to="/account/edit-profile"
												onClick={handleClose}
												className={classes.MenuItem}>
												Account
											</Link>
										</MenuItem>
										<IsTalentBooker>
											<MenuItem onClick={handleClose}>
												<Link
													to="/account/active-event"
													onClick={handleClose}
													className={classes.MenuItem}>
													My Events
												</Link>
											</MenuItem>
											<MenuItem onClick={handleClose}>
												<Link to="/account/messages" onClick={handleClose} className={classes.MenuItem}>
													Messages
												</Link>
											</MenuItem>
										</IsTalentBooker>
										<MenuItem onClick={handleClose}>
											<Link to="/account/support" onClick={handleClose} className={classes.MenuItem}>
												Support
											</Link>
										</MenuItem>
										<MenuItem
											onClick={() => {
												handleClose();
												props.logout();
											}}
											className={classes.lastMenuItem}>
											Logout
										</MenuItem>
									</Menu>
								</Grid>
							</>
						) : null}
					</Grid>
				</Grid>
			</Grid>
		</Box>
	) : (
		<>
			<AppBar position="static" elevation={0}>
				<Toolbar className={classes.toolbar}>
					{props.page === "talent" || props.page === "about" || props.isWhiteHeader ?
						<LogoWhite width="5em" /> :
						<Logo width="5em" />
					}
					<IconButton
						edge="start"
						className={classes.menuButton}
						color="inherit"
						aria-label="open drawer"
						onClick={toggleDrawer("right", true)}>
						<MenuIcon fontSize="large" />
					</IconButton>

					{/* <Logo style={{ width: "5em" }} /> */}
					<Drawer open={state.right} onClose={toggleDrawer("right", false)}>
						{sideList("right")}
					</Drawer>
					{props.header.signupModalOpened &&
						<SignupDialog
							opened={props.header.signupModalOpened}
							handleClose={headerActions.closeSignupModal}
							handleOpenLogin={props.openLoginModal}
							handleSignupSubmit={userActions.register}
							handleSignUpConfirmationOpen={headerActions.openConfirmationModal}
							handleLoginSubmit={userActions.login}
						/>
					}
					<ConfirmationDialog
						opened={props.header.confirmationModalOpened}
						handleClose={headerActions.closeConfirmationModal}
						handleResendConfirmation={userActions.resendConfirmationEmail}
					/>
					<LoginDialog
						opened={props.header.loginModalOpened}
						handleClose={headerActions.closeLoginModal}
						handleOpenSignup={headerActions.openSignupModal}
						handleLoginSubmit={userActions.login}
						handleOpenForgotPassword={openForgetPasswordModal}
						handleOpenResendActivationLink={openResendActivationLinkModal}
						login={login}
					/>
					<ForgetPassword />
					<ResendActivationLink />
				</Toolbar>
			</AppBar>
		</>
	);
};

export default Header;
