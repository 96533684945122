import { createSlice, PayloadAction } from "redux-starter-kit";
import Services from "../../generic/Services";

export interface AgentState {
	agentProfileRequested: boolean;
	agentProfileSucceeded: boolean;
	agentProfileFailure: boolean;
	error: {};
	agentProfile: any;
	editAgentProfileRequested: boolean;
	editAgentProfileSucceeded: boolean;
	editAgentProfileFailure: boolean;
	saveModal: boolean;
}

const initialState: AgentState = {
	agentProfileRequested: false,
	agentProfileSucceeded: false,
	agentProfileFailure: false,
	error: {},
	agentProfile: null,
	editAgentProfileRequested: false,
	editAgentProfileSucceeded: false,
	editAgentProfileFailure: false,
	saveModal: false,
};

const agentProfileSlice = createSlice({
	slice: "agentProfile",
	initialState,
	reducers: {
		
		agentProfileRequested(state, action) {
			state.agentProfileRequested = true;
			state.agentProfileSucceeded = false;
			state.agentProfileFailure = false;
		},
		agentProfileSucceeded(state, action: PayloadAction<boolean>) {
			state.agentProfileSucceeded = true;
			state.agentProfileRequested = false;
			state.agentProfileFailure = false;
			state.agentProfile = action.payload;
		},
		agentProfileFailure(state, action: PayloadAction<boolean>) {
			state.agentProfileSucceeded = false;
			state.agentProfileRequested = false;
			state.agentProfileFailure = true;
			state.error = action.payload;
		},
		editAgentProfileRequested(state, action) {
			state.editAgentProfileRequested = true;
			state.editAgentProfileSucceeded = false;
			state.saveModal = false;
			state.editAgentProfileFailure = false;
		},
		editAgentProfileSucceeded(state, action) {
			state.editAgentProfileRequested = false;
			state.editAgentProfileSucceeded = true;
			state.editAgentProfileFailure = false;
			state.saveModal = true;
			state.agentProfile = action.payload;
		},
		editAgentProfileFailure(state, action) {
			state.editAgentProfileRequested = false;
			state.editAgentProfileSucceeded = false;
			state.editAgentProfileFailure = true;
			state.saveModal = false;
			state.error = action.payload;
		},
		closeSaveModal(state, action) {
			state.saveModal = false;
		},
		openSaveModal(state, action) {
			state.saveModal = true;
		},
		closeFailModal(state, action) {
			state.editAgentProfileFailure = false;
		},
	},
});

export const {
	agentProfileRequested,
	agentProfileSucceeded,
	agentProfileFailure,
	editAgentProfileRequested,
	editAgentProfileSucceeded,
	editAgentProfileFailure,
	closeSaveModal,
	openSaveModal,
	closeFailModal,
} = agentProfileSlice.actions;

export default agentProfileSlice.reducer;

export const editAgentProfile = (bandProfile: any): any => async (dispatch: any) => {
	try {
		console.log('editAgentProfile CALLED');
		dispatch(editAgentProfileRequested());
		const res = await Services.agent.editAgentProfile(bandProfile);
		dispatch(editAgentProfileSucceeded(res));
		getAgentProfile()(dispatch)
	} catch (err) {
		dispatch(editAgentProfileFailure(err));
	}
};

export const getAgentProfile = (): any => async (dispatch: any) => {
	try {
		console.log('getAgentProfile CALLED');
		dispatch(agentProfileRequested());
		const res = await Services.agent.getAgentProfile();
		dispatch(agentProfileSucceeded(res));
	} catch (err) {
		console.log('getAgentProfile Err', err);
		dispatch(agentProfileFailure(err));
	}
};
