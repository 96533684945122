import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { listBookerEventsList } from "../../Account/BookerEvent/ListBookerEvents/listBookerEventsSlice";
import { IAppState } from "../../generic/interfaces";
import headerActions from "../../Header/actions";
import { BandRequest } from "./BandRequest";
import {
	addEventRequest,
	bandRequestModalClose,
	bandRequestModalOpen,
	setSelectedEvent,
	warningModalClose,
	warningModalOpen,
} from "./bandRequestSlice";

const mapStateToProps = (state: IAppState, ownProps: any) => ({
	isUserApproved: state.authentication.user.status === "APPROVED",
	warningModalOpened: state.bandPage.bandRequest.warningModalOpen,
	bandRequest: state.bandPage.bandRequest,
	bookerEvents: state.account.bookerEvent.listBookerEvent,
	genres: state.resources.genres.allIds.map(
		(id: number) => state.resources.genres.items[id],
	),
});

const mapDispatchToProps = (
	dispatch: ThunkDispatch<{}, {}, any>,
	ownProps: any,
) => ({
	handleOpen: () => dispatch(bandRequestModalOpen()),
	handleClose: () => dispatch(bandRequestModalClose()),
	setSelectedEvent: (eventItem: any) => dispatch(setSelectedEvent(eventItem)),
	listBookerEventsList: () => dispatch(listBookerEventsList()),
	handleBandRequestSubmit: (values: any) => {
		dispatch(addEventRequest(values));
	},
	openSignupModal: () => {
		dispatch(headerActions.openSignupModal());
	},
	handleOpenWarningModel: () => dispatch(warningModalOpen()),
	handleCloseWarningModel: () => dispatch(warningModalClose()),
});

export default connect(mapStateToProps, mapDispatchToProps)(BandRequest);
