import React from "react";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import ImagePlaceholder from "../../Assets/ImagePlaceholder.png";

interface ReplyFormProps {
	authentication: any;
	classes: any;
	buttonText: string;
	BandName: string;
	sendMessage: (message: string) => void;
}

export const ReplyForm: React.FC<ReplyFormProps> = ({ authentication, classes, buttonText, sendMessage, BandName }) => {
	const [message, setMessage] = React.useState<string>("");

	const reply = (e: any) => {
		e.preventDefault();
		sendMessage(message);
		setMessage("");
	};

	return (
		<Grid container spacing={3} alignItems="center">
			<Grid item xs={3} md={2} lg={2}>
				<Avatar
					alt="Talent Avatar"
					src={authentication.photo ? authentication.photo.thumbnail : ImagePlaceholder}
					className={authentication.role === "TALENT" ? classes.TALENT : classes.BOOKER}
				/>
			</Grid>
			<Grid item xs={9} md={9} lg={9}>
				<Typography variant="subtitle2">{authentication.role === "TALENT" ? BandName : authentication.fname}</Typography>
			</Grid>
			<Grid item xs={3} md={2} lg={2}></Grid>
			<Grid item xs={9} md={9} lg={9}>
				<form onSubmit={reply}>
					<Grid container spacing={3} justify="flex-end">
						<Grid item xs={12} md={12} lg={12}>
							<TextField
								autoComplete="off"
								id="message"
								name="message"
								multiline={true}
								rows={6}
								variant="outlined"
								color="secondary"
								fullWidth={true}
								value={message}
								onChange={(e: any) => {
									setMessage(e.target.value);
								}}
							/>
						</Grid>
						<Grid item>
							<Button type="submit" variant="contained" color="secondary">
								{buttonText}
							</Button>
						</Grid>
					</Grid>
				</form>
			</Grid>
		</Grid>
	);
};
