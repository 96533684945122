export const styles = {
	dialogContentText: {
		font: '1.2em "Fira Sans", sans-serif',
		"text-align": "center",
	},
	root: {
		height: "100%",
		width: "50%",
		padding: "0 30px",
		boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
	},
	dialogWrapper: {
		width: "100%",
		height: "350px",
	},
	paper: {
		margin: "10px",
	},
};
