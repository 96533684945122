import { connect } from "react-redux";
import { IAppState } from "../generic/interfaces";
import { ThunkDispatch } from "redux-thunk";
import { HomePage } from "./HomePage";
import { listFeatured, getHowItWorks, getQuotes, getTestimonials } from "./homePageSlice";
import { search } from "../SearchPage/searchPageSlice";
import { filterBandsKeywordSet } from "../SearchPage/FilterBar/filterBarSlice/filterSlice";
import headerActions from "../Header/actions";
import { setPage } from "../HomePage/homePageSlice";

const mapStateToProps = (state: IAppState) => ({
	featured: state.homePage.featured,
	howItWorks: state.homePage.howItWorks,
	quotes: state.homePage.quotes,
	testimonials: state.homePage.testimonials,
	authentication: state.authentication,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
	listFeatured: () => {
		dispatch(listFeatured());
	},
	getHowItWorks: (type: string) => {
		dispatch(getHowItWorks(type));
	},
	getQuotes: () => {
		dispatch(getQuotes());
	},
	getTestimonials: () => {
		dispatch(getTestimonials());
	},
	search: (text: string) => {
		dispatch(filterBandsKeywordSet(text));
		dispatch(search());
	},
	openSignupModal: () => {
		dispatch(headerActions.openSignupModal());
	},
	openLoginModal: () => {
		dispatch(headerActions.openLoginModal());
	},
	setPage: (page: string) => {
		dispatch(setPage(page));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
