import React from "react";
import Dialog from "@material-ui/core/Dialog"
import Typography from "@material-ui/core/Typography"
import { CustomDialogTitle } from "../../generic/components";

export interface IWarningDialog {
    warningModalOpened: boolean;
    handleCloseWarningModel: () => void;
}

const WarningDialog: React.FC<IWarningDialog> = ({ warningModalOpened, handleCloseWarningModel }) => {
    return (
        <Dialog
            fullWidth={true}
            maxWidth="sm"
            scroll="body"
            open={warningModalOpened}
            onClose={handleCloseWarningModel}
            aria-labelledby="alert-dialog-title">
            <CustomDialogTitle onClose={handleCloseWarningModel} id="alert-dialog-title">
                <Typography variant="h6" color="textPrimary">
                    Your profile is still pending admin approval. While we confirm your information, you will be able to search and favorite
                    bands.
                </Typography>
            </CustomDialogTitle>
        </Dialog>
    );
};

export default WarningDialog;
