import React from "react";
import {
	FormControlLabel,
	Grid,
	Button,
	Link,
	Checkbox,
	Box,
} from "@material-ui/core";
import { Genre } from "../../interfaces";
import { IGeneratedFetchReducer } from "../../generic/Resources/interfaces";

export interface IGenresFilterProps {
	genres: IGeneratedFetchReducer<Genre>;
	values: Genre[];
	handlePopoverClose: any;
	saveGenres: (genres: Genre[]) => void;
}

class GenresFilter extends React.Component<IGenresFilterProps> {
	state = {
		genres: [],
	};

	componentDidMount() {
		const { values, genres } = this.props;
		const ids = values && values.map(item => item.id);
		this.setState({
			genres: genres.allIds.map(id => ({
				...genres.items[id],
				checked: ids !== null ? ids.includes(id) : false,
			})),
		});
	}

	handleSubmit = () => {
		const chosen = this.state.genres
			.filter((item: any) => item.checked)
			.map((item: Genre) => ({ id: item.id, title: item.title }));
		this.props.saveGenres(chosen);
		this.props.handlePopoverClose();
	};

	handleClearGenres = () => {
		this.setState({
			genres: this.props.genres.allIds.map(id => ({
				...this.props.genres.items[id],
				checked: false,
			})),
		});
	};

	handleChange = (id: number) => (e: any) => {
		const { checked } = e.target;
		this.setState((prevState: any) => ({
			genres: prevState.genres.map((item: any) => {
				return {
					...item,
					checked: item.id === id ? checked : item.checked,
				};
			}),
		}));
	};

	render() {
		const { genres } = this.state;
		return (
			<Box p={2} >
				<Grid
					container={true}
					direction="column"
					justify="flex-end"
					alignItems="center"
					spacing={3}
				>
					<Grid item>
						<Grid
							container={true}
							direction="row"
							justify="flex-end"
							alignItems="center"
						>
							{genres.map((item: any) => (
								<Grid key={item.id} item={true} xs={4}>
									<FormControlLabel
										checked={item.checked}
										onChange={this.handleChange(item.id)}
										control={
											<Checkbox
												color="secondary"
												value={item.id.toString()}
											/>
										}
										label={item.title}
									/>
								</Grid>
							))}
						</Grid>
					</Grid>
					<Grid
						container
						direction="row"
						justify="flex-end"
						alignItems="center"
						spacing={1}
					>
						<Grid item>
							<Link
								color="textPrimary"
								component="button"
								onClick={this.handleClearGenres}
							>
								Clear Filters
							</Link>
						</Grid>
						<Grid item>
							<Button
								size="small"
								variant="contained"
								color="secondary"
								onClick={this.handleSubmit}
							>
								Apply
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</Box>
		);
	}
}

export default GenresFilter;
