import React from "react";
import Grid from "@material-ui/core/Grid"
import Dialog from "@material-ui/core/Dialog"
import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider"
import DialogContent from "@material-ui/core/DialogContent"
import Box from "@material-ui/core/Box"
import InputLabel from "@material-ui/core/InputLabel"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import { Link as LinkRouter } from "react-router-dom";
import { CustomDialogTitle } from "../../generic/components";
import { BandRequestState } from "./bandRequestSlice";

export interface IChooseEvent {
    bandRequest: BandRequestState;
    classes: any;
    handleClose: () => void;
    setSelectedEvent: (eventItem: any) => void;
    bookerEvents: any;
}

const ChooseEvent: React.FC<IChooseEvent> = ({ bandRequest, classes, handleClose, setSelectedEvent, bookerEvents }) => {

    return (
        <Dialog
            fullWidth={true}
            maxWidth="sm"
            scroll="body"
            open={bandRequest.bandRequestModalOpen && bandRequest.selectedEvent === null}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <CustomDialogTitle onClose={handleClose} id="alert-dialog-title">
                <Typography variant="h4" color="textPrimary">
                    SEND BOOKING REQUEST
                </Typography>
            </CustomDialogTitle>
            <Divider variant="middle" />
            <DialogContent>
                <Box m={2}>
                    <Grid container direction="row" spacing={6} alignItems="center" justify="center">
                        <Grid item xs={4} md={4} lg={4}>
                            <InputLabel className={classes.textAlign} htmlFor="events">
                                Choose Event
                            </InputLabel>
                        </Grid>
                        <Grid item xs={8} md={8} lg={8}>
                            <Select
                                placeholder="Choose Event"
                                value={bandRequest.selectedEvent || ""}
                                fullWidth={true}
                                variant="outlined"
                                color="secondary"
                                MenuProps={{
                                    getContentAnchorEl: null,
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left",
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left",
                                    },
                                    classes: {
                                        paper: classes.margin,
                                    },
                                }}
                                onChange={(e: any) => {
                                    setSelectedEvent(e.target.value);
                                }}>
                                {bookerEvents.events.items &&
                                    bookerEvents.events.items.map((event: any) => (
                                        <MenuItem key={event.id} value={event}>
                                            {event.title}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </Grid>
                        <Grid item>
                            <Typography>
                                or{" "}
                                <LinkRouter className={classes.CreateNewEventLink} to="/account/create-event">
                                    Create Event
                                </LinkRouter>
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default ChooseEvent;
