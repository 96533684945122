import { connect } from "react-redux";
import { IAppState } from "../generic/interfaces";
import { ThunkDispatch } from "redux-thunk";
import { HowItWorks } from "./HowItWorks";
import headerActions from "../Header/actions";
import {
	getHowItWorks,
	getQuotes,
	getTestimonials,
	getBenefits,
	getFaqs,
	getMainText,
} from "./howItWorksSlice";
import { setPage } from "../HomePage/homePageSlice";

const mapStateToProps = (state: IAppState) => ({
	howItWorks: state.howItWorksPage,
	quotes: state.howItWorksPage.quotes,
	testimonials: state.howItWorksPage.testimonials,
	benefits: state.howItWorksPage.benefits,
	faqs: state.howItWorksPage.faqs,
	mainText: state.howItWorksPage.headers,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
	getHowItWorks: (type: string) => {
		dispatch(getHowItWorks(type));
	},
	openSignupModal: () => {
		dispatch(headerActions.openSignupModal());
	},
	getQuotes: () => {
		dispatch(getQuotes());
	},
	getTestimonials: () => {
		dispatch(getTestimonials());
	},
	getBenefits: (type: string) => {
		dispatch(getBenefits(type));
	},
	getFaqs: (type: string) => {
		dispatch(getFaqs(type));
	},
	getMainText: (type: string) => {
		dispatch(getMainText(type));
	},
	setPage: (page: string) => {
		dispatch(setPage(page));
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(HowItWorks);
