import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import "typeface-poppins";
import "typeface-rubik";
import "typeface-squada-one";

const defaultTheme = createMuiTheme();
export const theme = createMuiTheme({
	palette: {
		primary: {
			main: "#0F24FD",
			contrastText: "#FFF",
		},
		secondary: {
			main: "#64FECC",
			contrastText: "#0F24FD",
		},
		background: {
			default: "#080522",
			paper: "#080522",
		},
		text: {
			primary: "#FFF",
			secondary: "#1C1762",
		},
	},
	typography: {
		fontFamily: [
			"Poppins",
			"-apple-system",
			"BlinkMacSystemFont",
			'"Segoe UI"',
			"Roboto",
			'"Helvetica Neue"',
			"Arial",
			"sans-serif",
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"',
		].join(","),
		h5: {
			fontSize: "12px",
			fontWeight: "bold",
		},
		h3: {
			fontFamily: "Rubik",
			fontWeight: 800,
		},
		h4: {
			fontFamily: "Squada One",
			fontWeight: "lighter",
		},
		h6: {
			fontFamily: "Squada One",
		},
		subtitle1: {
			fontWeight: 300,
			fontStyle: "none",
		},
		subtitle2: {
			fontSize: "1rem",
			fontWeight: "bold",
			fontStyle: "none",
		},
		body1: {
			fontStyle: "normal",
		},
		body2: {
			fontSize: "12px",
			fontFamily: "Poppins",
		},
		button: {
			fontSize: "1rem",
			fontFamily: "Rubik",
			textTransform: "none",
		},
		caption: {
			fontStyle: "italic",
			fontWeight: 600,
			fontSize: "15px",
		},
	},
	overrides: {
		MuiFormControlLabel: {
			label: {
				fontSize: "0.75rem",
			},
			root: {
				marginRight: "0px",
			},
		},
		MuiSlider: {
			valueLabel: {
				"& > span > span": {
					color: "#0F24FD",
				},
			},
			rail: {
				height: "3px",
				color: "#fff",
				opacity: 1,
			},
			track: {
				color: "#fff",
			},
			mark: {
				height: "3px",
			},
			markLabel: {
				bottom: "22px",
				top: "auto",
				color: "#fff",
			},
			markLabelActive: {
				bottom: "22px",
				top: "auto",
				color: "#fff",
			},
		},
		MuiListItem: {
			gutters: {
				padding: "0px",
			},
			root: {
				"&$selected, &$selected:hover": {
					borderColor: "#0F24FD",
					backgroundColor: "rgba(0, 0, 0, 0)",
					fontWeight: "bold",
				},
			},
		},
		MuiMenuItem: {
			root: {
				height: "25px",
				minHeight: "25px",
				"&.Mui-selected": {
					backgroundColor: "#64FECC",
					color: "#0F24FD",
					"&:hover": {
						backgroundColor: "#64FECC",
					},
				},
			},
		},
		MuiSelect: {
			icon: {
				color: "#fff",
			},
			root: {
				fontFamily: "Poppins",
				fontWeight: 400,
			},
		},
		MuiInputLabel: {
			root: {
				color: "#fff",
				fontSize: "15px",
				fontWeight: "bold",
				paddingBottom: "5px",
			},
		},
		MuiAppBar: {
			colorPrimary: {
				backgroundColor: "transparent",
			},
		},
		MuiDivider: {
			root: {
				backgroundColor: "#FFF",
				color: "#DDD",
			},
			middle: {
				backgroundColor: "#FFF",
				color: "#DDD",
				marginLeft: "60px",
				marginRight: "60px",
			},
		},
		MuiPopover: {
			paper: {
				marginTop: "0.75rem",
				padding: "1em",
				border: "solid 1px rgba(100, 254, 204, 0.5)",
			},
		},
		MuiCardMedia: {
			root: {
				position: "relative",
				height: "30em",
				paddingTop: "56.25%",
			},
		},
		MuiCircularProgress: {
			root: {
				color: "secondary",
			},
		},
		MuiChip: {
			outlinedSecondary: {
				color: "#fff",
				borderRadius: "6px",
				marginRight: "10px",
			},
		},
		MuiOutlinedInput: {
			input: {
				padding: "12.5px 14px",
			},
			adornedEnd: {
				paddingRight: "0px",
			},
			root: {
				height: "43px",
				"& fieldset": {
					borderColor: "rgba(100, 254, 204, 0.5)",
				},
				"&:hover $notchedOutline": {
					borderColor: "rgba(100, 254, 204, 0.5)",
				},
				"&$focused $notchedOutline": {
					borderWidth: "1px",
					borderColor: "rgba(100, 254, 204, 0.5)",
				},
			},
			multiline: {
				height: "auto",
			},
		},
		MuiInput: {
			root: {
				"&.MuiInput-underline:after": {
					borderColor: "#fff",
				},
			},
			underline: {
				"&:before": {
					borderBottom: "2px solid rgba(255,255,255,1)",
				},
			},
		},
		MuiIconButton: {
			colorPrimary: {
				backgroundColor: "#64FECC",
				borderTopRightRadius: "5px",
				borderBottomRightRadius: "5px",
				borderTopLeftRadius: "0px",
				borderBottomLeftRadius: "0px",
				width: "2em",
				height: "100%",
				padding: "0px",
				"&:hover": {
					backgroundColor: "#64FECC !important",
				},
			},
			sizeSmall: {
				position: "absolute",
				left: "8px",
				top: "8px",
				color: "#fff",
			},
		},
		MuiLink: {
			button: {
				textAlign: "start",
				borderRadius: "0px",
				borderBottom: "solid 1px",
				padding: "0px",
				fontWeight: "bold",
				fontFamily: "poppins",
			},
		},
		MuiButton: {
			root: { fontWeight: "bold" },
			sizeLarge: {
				padding: "6px 10px",
				height: "43px",
			},
			text: {
				fontFamily: "poppins",
				fontWeight: "bold",
				"&:hover": {
					backgroundColor: "rgba(255, 255, 255, 0)",
				},
			},
			contained: {
				padding: "1px 20px",
			},
			outlinedSecondary: {
				color: "white",
			},
		},
		MuiSwitch: {
			root: {
				width: 35,
				height: 24,
				padding: 0,
				margin: `0px ${defaultTheme.spacing(1)}px`,
			},
			switchBase: {
				padding: 1,
				"&$checked": {
					transform: "translateX(16px)",
					color: defaultTheme.palette.common.white,
					"& + $track": {
						backgroundColor: "#52d869",
						opacity: 1,
						border: "none",
					},
				},
			},
			thumb: {
				width: 18,
				height: 18,
				backgroundColor: "#080522",
				border: "2px solid #64FECC",
			},
			track: {
				borderRadius: 26 / 2,
				height: "85%",
				border: "2px solid #64FECC",
				backgroundColor: "transparent",
				opacity: 1,
				transition: defaultTheme.transitions.create(["background-color", "border"]),
			},
		},
		MuiCheckbox: {
			colorSecondary: {
				padding: "1px 4px",
			},
			root: {
				color: "none",
			},
		},
		MuiDialog: {
			paperScrollPaper: {
				border: "solid 1px rgba(100, 254, 204, 0.5)",
			},
			paperScrollBody: {
				border: "solid 1px rgba(100, 254, 204, 0.5)",
			},
		},
		MuiDialogTitle: {
			root: {
				marginTop: "44px",
				padding: "16px 16px 30px 16px",
				textAlign: "center",
			},
		},
		MuiDialogContent: {
			root: {
				padding: "30px 24px 24px 24px",
			},
		},
		MuiExpansionPanel: {
			root: {
				borderBottom: "3px solid #0F24FD",
				background: "transparent",
			},
		},
		MuiExpansionPanelSummary: {
			root: {
				"& .MuiExpansionPanelSummary-content": {
					marginTop: "30px",
					marginBottom: "30px",
				},
				padding: "0px",
			},
		},
		MuiExpansionPanelDetails: {
			root: {
				paddingLeft: "0px",
				paddingRight: "0px",
			},
		},
		MuiTextField: {
			root: {
				"& textarea": {
					"&::placeholder": {
						color: "#63637B",
						fontFamily: "Poppins",
						fontWeight: 400,
					},
				},
				"& .MuiOutlinedInput-root": {
					"& input": {
						"&::placeholder": {
						  color: "#63637B",
						  fontFamily: "Poppins",
						  fontWeight: 400,
						},
					},
					color: "#fff",
					"& fieldset": {
						border: "1px solid",
						borderColor: "rgba(100, 254, 204, 0.5)",
					},
					"&:hover fieldset": {
						borderColor: "rgba(100, 254, 204, 0.5)",
					},
					"&.Mui-focused fieldset": {
						borderColor: "rgba(100, 254, 204, 0.5)",
					},
				},
			},
		},
		MuiFormHelperText: {
			contained: {
				margin: "8px 0"
			},
			root: {
				fontSize: "12px",
			},
		},
		MuiCardContent: {
			root: {
				padding: "16px 25px",
			},
		},
		MuiCardActions: {
			root: {
				padding: "0px 16px 8px 16px",
			},
		},
	},
	props: {
		MuiExpansionPanel: {
			elevation: 0,
		},
		MuiLink: {
			underline: "none",
		},
		MuiButtonBase: {
			// The properties to apply
			disableRipple: true, // No more ripple, on the whole application 💣!
		},
	},
});
