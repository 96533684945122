import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import { ListTalentEventState } from "./listTalentEventsSlice";
import { RequestItem } from "../RequestItem";
import { SearchTextField } from "../../../SearchPage/FilterBar/SearchTextField";
import { RequestHistory } from "../RequestHistory";
import ReviewTalent from "../ReviewTalent";
import Button from "@material-ui/core/Button";
import Services from "../../../generic/Services";
import { useHistory, useParams } from "react-router-dom";
import { RequestObject } from "./listTalentEventsSlice"

export interface ListTalentEventsProps {
	authentication: any;
	bandEvents: ListTalentEventState;
	status: string;
	deleteRequest: (requestId: number) => void;
	listBandsEventsList: (bandId: number, filterText: string) => void;
	listBandsPastEventsList: (bandId: number, filterText: string) => void;
	getRequestHistory: (eventId: number) => void;
	loadMore: (bandId: number, filterText: string) => void;
	loadMorePast: (bandId: number, filterText: string) => void;
	setFilterText: (textFilter: string) => void;
	setPastFilterText: (textFilter: string) => void;
	setLoc: (location: string) => void;
	changeRequestStatus: (requestId: number, status: string) => void;
	setPage: (page: string) => void;
	openReviewTalentDialog: (
		bandId: number,
		eventId: number,
		location: string,
	) => void;
}

export const ListTalentEvents: React.FC<ListTalentEventsProps> = ({
	status,
	bandEvents,
	listBandsEventsList,
	listBandsPastEventsList,
	getRequestHistory,
	loadMore,
	loadMorePast,
	setLoc,
	setFilterText,
	setPastFilterText,
	authentication,
	deleteRequest,
	changeRequestStatus,
	openReviewTalentDialog,
	setPage,
}) => {
	const [showEventHistory, setEventHistory] = useState(false);
	const [targetRequest, setTargetRequest] = useState<RequestObject>()
	const history = useHistory();

	useEffect(() => {
		setFilterText("");
		setPastFilterText("");
	}, [setFilterText, setPastFilterText])

	const { bandId } = useParams();
	let setLocUrl = "/account/active-event";

	console.log('useParams bandId: ', bandId);

	let selectedBandId = 0;
	if(bandId){
		selectedBandId = bandId;
	}
	else{
		selectedBandId = authentication.user.bandId;
	}

	console.log('useParams selectedBandId: ', selectedBandId);

	useEffect(() => {
		if (status === "ACTIVE") {
			setLoc("/account/active-event");
			setPage("active-talent-event")
			listBandsEventsList(
				selectedBandId,
				bandEvents.textFilter,
			);
		} else if (status === "PAST") {
			setLoc("/account/past-event");
			setPage("past-talent-event")
			listBandsPastEventsList(
				selectedBandId,
				bandEvents.pastTextFilter,
			);
		}
		if((history?.location?.state as any)?.requestId){
			Services.eventRequest.getItemById((history.location.state as any)?.requestId).then(res => {
				if(status === "ACTIVE" && (res.item.status === "OPEN" || res.item.status === "ACCEPTED_BY_TALENT" || res.item.status === "PENDING_DEPOSIT" || res.item.status === "PENDING_REMAINDER" || res.item.status === "APPROVED" || res.item.status === "PENDING_FULL_PAYMENT")){
					setTargetRequest(res.item)
				} else if (status === "PAST" && (res.item.status === "COMPLETED" || res.item.status === "REJECTED_BY_TALENT" || res.item.status === "CANCELED_BY_BOOKER" || res.item.status === "CANCELED_BY_TALENT")){
					setTargetRequest(res.item)
				}
			})
		}
	}, [bandEvents.textFilter, bandEvents.pastTextFilter, listBandsEventsList, setLoc, status, setPage, authentication.user.bandId, selectedBandId, listBandsPastEventsList, history]);

	return (
		<>
			<Box mb="30px" fontWeight="bold" fontFamily="Rubik" fontSize="30px">
				{status === "ACTIVE" ? "Active Events" : "Past Events"}
			</Box>
			{!showEventHistory && bandEvents.listEventsRequested && (
				<div>Loading...</div>
			)}
			{!showEventHistory &&
				bandEvents.listEventsSucceeded &&
				bandEvents &&
				bandEvents.requests && (
					<>
						<SearchTextField
							value={
								status === "ACTIVE"
									? bandEvents.textFilter
									: bandEvents.pastTextFilter
							}
							search={
								status === "ACTIVE"
									? setFilterText
									: setPastFilterText
							}
						/>
						{targetRequest && (
							<Box mt={3} key={targetRequest.id}>
								<RequestItem
									authentication={authentication}
									key={targetRequest.id}
									request={targetRequest}
									status={status}
									intialOpen={true}
									openReviewTalentDialog={() =>
										openReviewTalentDialog(
											targetRequest.band_id,
											targetRequest.event_id,
											targetRequest.band.location,
										)
									}
									deleteRequest={deleteRequest}
									changeRequestStatus={
										changeRequestStatus
									}
									showEventHistory={() => {
										getRequestHistory(targetRequest.id);
										setEventHistory(true);
									}}
								/>
							</Box>
						)}
						{bandEvents.requests.items &&
							bandEvents.requests.items.length > 0 &&
							bandEvents.requests.items.map((item: any) => {
								if ( item.id !== targetRequest?.id){
									return (
										<Box mt={3} key={item.id}>
											<RequestItem
												authentication={authentication}
												key={item.id}
												request={item}
												intialOpen={false}
												status={status}
												openReviewTalentDialog={() =>
													openReviewTalentDialog(
														item.band_id,
														item.event_id,
														item.band.location,
													)
												}
												deleteRequest={deleteRequest}
												changeRequestStatus={
													changeRequestStatus
												}
												showEventHistory={() => {
													getRequestHistory(item.id);
													setEventHistory(true);
												}}
											/>
										</Box>
									)
								} else {
									return <></>
								}
							})}
						{bandEvents.requests.items &&
							bandEvents.requests.items.length <
								bandEvents.requests.total_items && (
								<Box
									mt={3}
									display="flex"
									justifyContent="center">
									<Button
										variant="contained"
										color="secondary"
										onClick={() =>
											status === "ACTIVE"
												? loadMore(
														authentication.user
															.bandId,
														bandEvents.textFilter,
												  )
												: loadMorePast(
														authentication.user
															.bandId,
														bandEvents.pastTextFilter,
												  )
										}
										disabled={bandEvents.loadMoreRequested}>
										Load More Results
									</Button>
								</Box>
							)}
						<ReviewTalent
							titles={[
								"LEAVE A REVIEW",
								"Let us Know what you thought of",
								"Repsy and your experience with us.",
							]}
							type="Booker"
						/>
					</>
				)}
			{!showEventHistory &&
				bandEvents.listEventsSucceeded &&
				bandEvents.requests.items &&
				bandEvents.requests.items.length === 0 && (
					<Box mt={3}>{status === "ACTIVE" ? "No Active Events" : "No Past Events"}</Box>
				)}

			{/* to show history of request */}
			{showEventHistory && (
				<RequestHistory
					isRequested={bandEvents.requestHistoryRequested}
					isSucceeded={bandEvents.requestHistorySucceeded}
					isFailed={bandEvents.requestHistoryFailure}
					requestHistory={bandEvents.requestHistory}
					onBackClick={() => setEventHistory(false)}
				/>
			)}
		</>
	);
};
