import { ILinkObject } from "./linkObject";

export enum SocialLinkType {
	FACEBOOK = "FACEBOOK",
	SPOTIFY = "SPOTIFY",
	SOUNDCLOUD = "SOUNDCLOUD",
	YOUTUBE = "YOUTUBE",
	INSTAGRAM = "INSTAGRAM",
}
export interface ISocialLink extends ILinkObject {
	type: SocialLinkType;
}
