import { Theme, createStyles } from "@material-ui/core";

export const formStyles = (theme: Theme) =>
	createStyles({
		InputLabel: {
			color: theme.palette.text.primary,
			fontSize: "15px",
			fontWeight: "bold",
		},
		root: {
			margin: 0,
			padding: theme.spacing(5),
			"& .MuiOutlinedInput-root": {
				color: "#fff",
				"& fieldset": {
					border: "1px solid",
					borderColor: theme.palette.secondary.main,
				},
				"&:hover fieldset": {
					borderColor: theme.palette.secondary.main,
				},
				"&.Mui-focused fieldset": {
					borderColor: theme.palette.secondary.main,
				},
			},
		},
		margin: {
			marginBottom: "35px",
		},
	});

export const dialogStyles = (theme: Theme) =>
	createStyles({
		dialogRoot: {
			border: "1px solid",
			borderColor: theme.palette.secondary.main,
		},
		dialogDivider: {
			backgroundColor: theme.palette.text.primary,
			margin: "0px 60px",
		},
	});
