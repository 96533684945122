import { createSlice } from "redux-starter-kit";

export interface FiltersState {
	genres: [] | null;
	price_from: number | null;
	price_to: number | null;
	days: [] | null;
	size: number | null;
	favorite: boolean;
	location: { id: number } | null;
	text: string;
}

let initialState: FiltersState = {
	genres: null,
	price_from: null,
	price_to: null,
	days: null,
	size: null,
	favorite: true,
	location: null,
	text: "",
};

const filtersBarFavoriteSlice = createSlice({
	slice: "filtersBarFavorite",
	initialState,
	reducers: {
		filterBandsGenresSet(state, action) {
			state.genres = action.payload;
		},
		filterBandsGenresReset(state, action) {
			state.genres = null;
		},
		filterBandsPricesFromSet(state, action) {
			state.price_from = action.payload;
		},
		filterBandsPricesFromReset(state, action) {
			state.price_from = null;
		},
		filterBandsPricesToSet(state, action) {
			state.price_to = action.payload;
		},
		filterBandsPricesToReset(state, action) {
			state.price_to = null;
		},
		filterBandsDatesSet(state, action) {
			state.days = action.payload;
		},
		filterBandsDatesReset(state, action) {
			state.days = null;
		},
		filterBandsSizeSet(state, action) {
			state.size = action.payload;
		},
		filterBandsSizeReset(state, action) {
			state.size = null;
		},
		filterBandsLocationSet(state, action) {
			state.location = action.payload;
		},
		filterBandsLocationReset(state, action) {
			state.location = null;
		},
		filterBandsKeywordSet(state, action) {
			state.text = action.payload;
		},
		filterBandsOptionsReset(state, action) {
			state.genres = null;
			state.price_from = null;
			state.price_to = null;
			state.days = null;
			state.size = null;
			state.favorite = false;
			state.location = null;
			state.text = "";
		},
	},
});

export const {
	filterBandsGenresSet,
	filterBandsGenresReset,
	filterBandsPricesFromSet,
	filterBandsPricesFromReset,
	filterBandsPricesToSet,
	filterBandsPricesToReset,
	filterBandsDatesSet,
	filterBandsDatesReset,
	filterBandsSizeSet,
	filterBandsSizeReset,
	filterBandsLocationSet,
	filterBandsLocationReset,
	filterBandsKeywordSet,
	filterBandsOptionsReset,
} = filtersBarFavoriteSlice.actions;

export default filtersBarFavoriteSlice.reducer;
