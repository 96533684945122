import { IError } from "../Base/ErrorMessageBase";

export enum ResendActivationLinkActionType {
	RESEND_ACTIVATION_LINK_MODAL_OPEN = "RESEND_ACTIVATION_LINK_MODAL_OPEN",
	RESEND_ACTIVATION_LINK_MODAL_CLOSE = "RESEND_ACTIVATION_LINK_MODAL_CLOSE",
	RESEND_ACTIVATION_LINK_REQUEST = "RESEND_ACTIVATION_LINK_REQUEST",
	RESEND_ACTIVATION_LINK_SUCCESS = "RESEND_ACTIVATION_LINK_SUCCESS",
	RESEND_ACTIVATION_LINK_FAILURE = "RESEND_ACTIVATION_LINK_FAILURE",
}

export interface IResendActivationLinkActions {
	type: ResendActivationLinkActionType;
	payload: any;
}

export interface IResendActivationLinkReducer {
	resendActivationLinkModalOpened: boolean;
	resendActivationLinkRequest: boolean;
	resendActivationLinkSuccess: boolean;
	error: IError;
}

export interface IResendActivationLinkProps {
	resendActivationLink: IResendActivationLinkReducer;
	handleClose: () => any;
	handleResendActivationLinkSubmit: (data: { email: string }) => any;
	classes: any;
}
