import React, { useEffect, useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import MultipleImageDropzone from "./multiple-image-uploader";
import ImageGalleryComponent from "./ImageGalleryComponent";
import { deleteBandPhotoGalleryImage, getBandPhotoGalleryImages, listMyBands, getBandById } from "../../generic/Services/bands";
import { Link, useParams } from "react-router-dom";
import ProfileImageDropzone from "./ProfileImageDropzone";
import HeaderImageDropzone from "./HeaderImageDropzone";
import { Grid, SnackbarContent } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        missingProfileError: {
			backgroundColor: 'transparent',
			boxShadow: '0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 0px 0px 0px rgb(0 0 0 / 12%)',
			border:'1px solid #FE0000',
			color: '#FE0000',
			fontSize: '18px',
			fontWeight: 800,
			fontStyle: 'italic',
			borderRadius: '10px',
			paddingTop: '2px',
			paddingBottom: '2px',
			textAlign: 'center',
			display: 'inline-block',
			minWidth: '10px',
		}
    })
);

interface TalentImageGalleryProps {
    setLoc: any;
    states: any;
    setPage: (page: string) => void;
    user: any;
}

export const TalentImageGallery: React.FC<TalentImageGalleryProps> = ({ setLoc, states, setPage, user }) => {
    //setLoc("/account/photo-gallery");
    //setPage("photo-gallery");

    const classes = useStyles();
    const [reloadGallery, setReloadGallery] = useState(false);
    const [photoGalleryImages, setPhotoGalleryImages] = useState([]);
    const [bandData, setBandData] = useState(null);
    const { id } = useParams();

    useEffect(() => {
        setPage("photo-gallery");
        if (!id) {
            setLoc("/account/photo-gallery");
            photoGalleryDataAsync(0);
            bandDataAsync(0);
        }
        else {
            bandDataAsync(id);
            setLoc("/account/photo-gallery" + id);
            photoGalleryDataAsync(id);
        }



    }, [reloadGallery])

    const bandDataAsync = async (bandId: any) => {
        if (bandId == 0) {
            const res = await listMyBands(1);
            if (res?.status == 'SUCCESS') {
                if (res?.items?.length > 0) {
                    setBandData(res?.items[0]);
                }
            }
        }
        else {
            const res = await getBandById(bandId, true);
            if (res?.status == 'SUCCESS') {
                if (res?.item) {
                    setBandData(res?.item);
                }
            }
        }
    };

    const photoGalleryDataAsync = async (bandId: Number) => {
        let bandGalleryData = await getBandPhotoGalleryImages(bandId);
        if (bandGalleryData?.item?.length > 0) {
            setPhotoGalleryImages(bandGalleryData?.item);
        }
    };

    const handleImageDelete = async (deleteImage: any) => {
        try {
            await deleteBandPhotoGalleryImage(deleteImage?.id);
            setReloadGallery(!reloadGallery);
            return true;
        } catch (ex) {
            console.log('Delete File Exeception: ', ex);
        }
    }

    if (user?.band_profile_status == 'required') {
        return <Grid item xs={12} style={{ textAlign: 'center', paddingTop: "50px" }}>
            <SnackbarContent
                className={`${classes.missingProfileError}`}
                aria-describedby="error-message"
                message={
                    <span id="error-message">Missing band profile information. Please create <Link to={"/account/edit-profile"} style={{ color: '#FE0000', cursor: "pointer", textDecoration: "underline" }}> band profile</Link> to upload images</span>
                }
            />
        </Grid>
    }
    else {
        return (
            <Grid container direction="row" alignItems="flex-start">
                <Grid item xs={12} md={12} lg={12} style={{ marginBottom: "20px" }}>
                    <ProfileImageDropzone bandData={bandData} setReloadGallery={setReloadGallery} reloadGallery={reloadGallery} />
                </Grid>
                <Grid item xs={12} md={12} lg={12} style={{ marginBottom: "20px" }}>
                    <HeaderImageDropzone bandData={bandData} setReloadGallery={setReloadGallery} reloadGallery={reloadGallery} />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <div className={classes.root}>
                        <ImageGalleryComponent imageList={photoGalleryImages} handleDelete={handleImageDelete} />
                    </div>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <MultipleImageDropzone reloadGallery={reloadGallery} setReloadGallery={setReloadGallery} bandId={id || 0} />
                </Grid>
            </Grid>
        );
    }

}