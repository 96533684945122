import React, { useEffect, useState } from "react";
import {
	Button,
	TextField,
	Grid,
	InputLabel,
	Checkbox,
	FormControlLabel,
	SnackbarContent,
	Theme,
	makeStyles,
	createStyles,
} from "@material-ui/core";
import { IError } from "../Base/ErrorMessageBase";
import ErrorMessage from "../generic/ErrorMessage";
import { IAuthReducer } from "../AuthBase/interfaces";
import { theme } from "../theme";
import { Link } from "@material-ui/core";
import { CheckEmailVerificationStatusV2 } from "../generic/Services/users";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		missingProfileError: {
			backgroundColor: 'transparent',
			boxShadow: '0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 0px 0px 0px rgb(0 0 0 / 12%)',
			border:'1px solid #FE0000',
			color: '#FE0000',
			fontSize: '18px',
			fontWeight: 800,
			fontStyle: 'italic',
			borderRadius: '10px',
			paddingTop: '2px',
			paddingBottom: '2px',
			paddingRight: '5px !important',
			paddingLeft: '5px !important',
			textAlign: 'center',
			display: 'inline-block',
			minWidth: '10px',
		}
	})
);

const Form = (props: {
	classes?: any;
	values: {
		username_email: string | null;
		password: string;
		isRememberMe: boolean;
	};
	requestError?: IError;
	errors: any;
	touched: any;
	handleChange: any;
	isValid: any;
	setFieldTouched: any;
	setFieldValue: any;
	setFieldError: any;
	handleSubmit: any;
	state: IAuthReducer;
	handleOpenForgotPassword: any;
	handleOpenResendActivationLink: any;
	handleOpenSignup: any;
}) => {
	const {
		values: { username_email, password, isRememberMe },
		errors,
		touched,
		handleChange,
		setFieldTouched,
		setFieldValue,
		setFieldError,
		handleSubmit,
		state,
		handleOpenForgotPassword,
		handleOpenResendActivationLink,
		handleOpenSignup,
	} = props;

	const classes = useStyles();
	const [serverError, setServerError] = useState<any>();
	const [isMissingProfileError, setIsMissingProfileError] = useState(false);

	const change = (name: any, e: { persist: () => void }) => {
		e.persist();
		handleChange(e);
		setFieldTouched(name, true, false);
	};

	useEffect(() => {
		if(state?.error?.message !== "email_not_verified"){
			setServerError(state?.error);
		}else{
			setIsMissingProfileError(false)
		}
		updateEmailErrorMessage(state?.error?.message);
	}, [state.error])

	const validateEmail = (valEmail: any) =>
    {
        let re = /\S+@\S+\.\S+/;
        return re.test(valEmail);
    }
	const handleEmailOnBlur = (e: any) => {
		//setServerError(null);
		if(!validateEmail(e?.target?.value)){
			return false;
		}
		CheckEmailVerificationStatusV2(e?.target?.value).then(
			(response: any) => {},
			(error: any) => {
				updateEmailErrorMessage(error?.message);
			},
		);
	}

	const updateEmailErrorMessage = (erroMsg: any) => {
		setIsMissingProfileError(false)
		if(erroMsg == "email_verified_missing_profile"){
			setServerError(null);
			setIsMissingProfileError(true)
			//setFieldError('username_email', <span id="error-message">Missing profile information. Please use <Link onClick={handleOpenSignup} style={{ color: '#FE0000', cursor: "pointer", textDecoration: "underline" }}> Sign Up form</Link> to reverify.</span>);
			
		}
		else if(erroMsg == "email_not_verified"){
			setServerError(null);
			setFieldError('username_email', <span id="error-message">Your email has not been verified. Please check your inbox or use the <Link onClick={handleOpenSignup} style={{ color: '#FE0000', cursor: "pointer", textDecoration: "underline" }}> Sign Up form</Link>.</span>);
		}
	}

	return (
		<form onSubmit={handleSubmit} style={{ padding: theme.spacing(3) }}>
			<Grid container spacing={6}>
				<Grid item xs={12}>
					<InputLabel htmlFor="username_email">
						User Name or Email Address
					</InputLabel>
					<TextField
						autoComplete="off"
						id="username_email"
						name="username_email"
						variant="outlined"
						color="secondary"
						onBlur={handleEmailOnBlur}
						helperText={
							touched.username_email ? errors.username_email : ""
						}
						error={
							touched.username_email &&
							Boolean(errors.username_email)
						}
						fullWidth={true}
						margin="dense"
						value={username_email}
						onChange={change.bind(null, "username_email")}
					/>
				</Grid>
				<Grid item xs={12}>
					<InputLabel htmlFor="password">Password</InputLabel>
					<TextField
						autoComplete="off"
						id="password"
						name="password"
						variant="outlined"
						color="secondary"
						helperText={touched.password ? errors.password : ""}
						error={touched.password && Boolean(errors.password)}
						fullWidth={true}
						type="password"
						value={password}
						onChange={change.bind(null, "password")}
						margin="dense"
					/>
				</Grid>
			</Grid>
			<Grid
				container
				direction="column"
				justify="center"
				alignItems="center"
				spacing={3}
			>
				<Grid item xs={12}>
				
					{(serverError && serverError?.code) && (!isMissingProfileError) ? (
						<Grid item xs={12} style={{ textAlign: 'center' }}>
							<SnackbarContent
								className={`${classes.missingProfileError}`}
								aria-describedby="error-message"
								message={
									<div><span id="error-message">{serverError?.message}</span></div>
								}
							/>
						</Grid>
					) : (null)}
				</Grid>
				{/* <Grid item xs={12}>
					{state.error && state.error.code && state.error.code === 400 && (
						<Grid item>
							<Link
								component="button"
								type="button"
								style={{ color: "white" }}
								onClick={handleOpenResendActivationLink}
							>
								Resend Verification Link
							</Link>
						</Grid>

					)}
				</Grid> */}
				<Grid item>
					<Button
						type="submit"
						variant="contained"
						color="secondary"
						disabled={state.isLoggingIn}
					>
						Login
					</Button>
				</Grid>
				<Grid item>
					<FormControlLabel
						control={
							<Checkbox
								checked={isRememberMe}
								onChange={(
									event: React.ChangeEvent<HTMLInputElement>,
								) => {
									event.persist();
									setFieldValue(
										"isRememberMe",
										event.target.checked,
									);
									setFieldTouched(
										"isRememberMe",
										true,
										false,
									);
								}}
								value="isRememberMe"
							/>
						}
						label="Remember me"
					/>
				</Grid>
				<Grid item>
					<Link
						component="button"
						type="button"
						style={{ color: "white" }}
						onClick={handleOpenForgotPassword}
					>
						Forgot Password?
					</Link>
				</Grid>
				<Grid item xs={12}>
					
					{(serverError && serverError?.code && serverError?.message === "email_verified_missing_profile") || (isMissingProfileError) ? (
						<Grid item xs={12} style={{ textAlign: 'center' }}>
							<SnackbarContent
								className={`${classes.missingProfileError}`}
								aria-describedby="error-message"
								message={
									<div><span id="error-message">Missing profile information. Please use <Link onClick={handleOpenSignup} style={{ color: '#FE0000', cursor: "pointer", textDecoration: "underline" }}> Sign Up form</Link> to reverify.</span></div>
								}
							/>
						</Grid>
					) : (null)}
				</Grid>
			</Grid>
		</form>
	);
};

export default Form;
