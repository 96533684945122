import { connect } from "react-redux";
import { IAppState } from "../../generic/interfaces";
import { ThunkDispatch } from "redux-thunk";
import Support from "./support";
import {
	sendInquiry, closeFailModal, closeSaveModal,
} from "./supportSlice";
import { setPage } from "../../HomePage/homePageSlice";

const mapStateToProps = (state: IAppState) => ({
	support: state.account.support,
	role: state.authentication.user.role,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
	sendInquiry: (inquiry: any) => {
		dispatch(sendInquiry(inquiry));
	},
	setPage: (page: string) => {
		dispatch(setPage(page));
	},
	handleFailClose: () => dispatch(closeFailModal()),
	handleSaveClose: () => dispatch(closeSaveModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Support);
