import { connect } from "react-redux";
import { IAppState } from "../generic/interfaces";
import { ThunkDispatch } from "redux-thunk";
import { Contact } from "./Contact";
import {
	sendInquiry,
	closeContactModal,
	openContactModal,
} from "./contactSlice";
import { setPage } from "../HomePage/homePageSlice";

const mapStateToProps = (state: IAppState) => ({
	contactPage: state.contactPage,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
	sendInquiry: (inquiry: any) => {
		dispatch(sendInquiry(inquiry));
	},
	setPage: (page: string) => {
		dispatch(setPage(page));
	},
	handleClose: () => dispatch(closeContactModal()),
	handleOpen: () => dispatch(openContactModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
