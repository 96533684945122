import React from "react";
import { withStyles } from "@material-ui/core";
import { styles } from "./styles";
import { IConfirmAccountProps } from "./interfaces";

class ConfirmAccount extends React.Component<IConfirmAccountProps> {
	state = {open: true}

	componentDidMount() {		
			this.props.openSignupModal();
	}

	render() {
		return (
			<div>
			</div>
		);
	}
}

export default withStyles(styles)(ConfirmAccount);
