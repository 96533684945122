import React, { useEffect, useState, forwardRef } from "react";
import { Avatar, Box, Button, Dialog, DialogContent, Divider, Grid, InputLabel, Link, OutlinedInput, TextField, Typography,FormHelperText } from "@material-ui/core";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import Services from "../../Services";
import { CustomDialogTitle } from "../DialogTitle/dialog-title";
import DateFnsUtils from '@date-io/date-fns';
import DayPickerInput from "react-day-picker/DayPickerInput";
import DateRangeIcon from "@material-ui/icons/DateRange";
import moment from "moment";
import {
  MuiPickersUtilsProvider,  
  KeyboardDatePicker,
} from '@material-ui/pickers';
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        large: {
            width: theme.spacing(8),
            height: theme.spacing(8),
            marginRight: "15px",
            '& img': {
                borderRadius: "70px",
                border: "4px solid rgb(255, 255, 255)",
            },
        },
        themeBtn: {
            fontWeight: 500,
            fontSize: "0.9rem",
        },
        paragraphFont: {
            fontSize: "15px",
            color: "#ddd",
        },
        Link: {
            display: "flex",
            alignItems: "center",
            color: "#0F24FD",
            backgroundColor: "#64FECC",
            padding: "5px 20px",
            fontSize: "1rem",
            fontFamily: "Rubik",
            fontWeight: "bold",
            borderRadius: "4px",
            textDecoration: "none",
            margin: 10,
            height: 35,
            "&:hover": {
                backgroundColor: "#64FECC"
            },
        },
    }),
);


export interface SuggestionDialogProps {
    viewType: String
}

export const SuggestionDialog: React.FC<SuggestionDialogProps> = ({ viewType }) => {
    console.log('viewType: ', viewType);
    const [inviteDialogOpen, setinviteDialogOpen] = React.useState(false);
    const classes = useStyles();
    const [inviteEmail, setInviteEmail] = React.useState('');
    const [showEmailError, setshowEmailError] = React.useState('');
    const [showEmailExistMessage, setshowEmailExistMessage] = React.useState('');    



    const [values, setValues] = useState({
        first_name: '',
        last_name: '',
        email: '',
        artist_name: '',
        artist_contact: '',
        event_date: '',        
        description: '',
        type: ''
    });

    const [validateError, setValidateErrors] = useState({
        first_name: { message: '', isError: true },
        last_name: { message: '', isError: true },
        email: { message: '', isError: true },
        artist_name: { message: '', isError: true },
        description: { message: '', isError: true },        
        isError: false
    });
    const [showArtisForm, setShowArtisForm] = React.useState(false);
    const [showGenreForm, setShowGenreForm] = React.useState(false);
    const [showThankyou, setShowThankyou] = React.useState(false);

    const handleDayChange = (day: any) => {
            console.log(day);
            setValues({ ...values, event_date : moment(day).format("MMM DD, YYYY").toString()});
        
    };


    const handleChange = (id: string, value: any) => {

        switch (id) {
            case 'first_name':
                setValues({ ...values, first_name: value });
                if (!value) {
                    setValidateErrors({ ...validateError, first_name: { message: '', isError: true } });
                } else {
                    setValidateErrors({ ...validateError, first_name: { message: '', isError: false } });
                }
                break;
            case 'last_name':
                setValues({ ...values, last_name: value });
                if (!value) {
                    setValidateErrors({ ...validateError, last_name: { message: '', isError: true } });
                } else {
                    setValidateErrors({ ...validateError, last_name: { message: '', isError: false } });
                }
                break;

            case 'email':
                setValues({ ...values, email: value });
                if (!value || !validEmail(value)) {
                    setValidateErrors({ ...validateError, email: { message: '', isError: true } });
                } else {
                    setValidateErrors({ ...validateError, email: { message: '', isError: false } });
                }
                console.log(validateError);
                break;
            
            case 'artist_name':
                setValues({ ...values, artist_name: value });
                if (!value && values.type == 'artist') {
                    setValidateErrors({ ...validateError, artist_name: { message: '', isError: true } });
                } else {
                    setValidateErrors({ ...validateError, artist_name: { message: '', isError: false } });
                }
                break;
            case 'artist_contact':
                setValues({ ...values, artist_contact: value });
                break;
            case 'event_date':
                setValues({ ...values, event_date: value });
                break;
            case 'description':
                 setValues({ ...values, description: value });
                 if (!value && values.type == 'genre') {
                    setValidateErrors({ ...validateError, description: { message: '', isError: true } });
                } else {
                    setValidateErrors({ ...validateError, description: { message: '', isError: false } });
                }
                 break;
        }
    }
    useEffect(() => {
    }, []);

    const sendEmailInvitationToBand = () => {
        setshowEmailError('')
        setshowEmailExistMessage('');
        setValidateErrors({ ...validateError, isError: true });

        if (
			validateError.first_name.isError ||
			validateError.last_name.isError ||
			validateError.email.isError || 
            (values.type == 'artist' && validateError.artist_name.isError) ||
            (values.type == 'genre' && validateError.description.isError)
		) {
            console.log('<----------------- ERROR ----------------->')
			return false;
		} else {
            console.log('<----------------- No ERROR ----------------->')
			setValidateErrors({ ...validateError, isError: false });
		}

        if (validEmail(values.email)) {

            //values.email = { ...values, date: moment(event.date).format("YYYY-MM-DD").toString()}
            if(values.event_date){
                values.event_date= moment(values.event_date).format("YYYY-MM-DD").toString();
                //setValues({ ...values, event_date : moment(values.event_date).format("YYYY-MM-DD").toString()});
            }


            console.log('---- API CALL ---- ',values);
            Services.users.sendSuggestion(values)
                .then((res: any) => {
                    console.log('res: ', res)
                    //setinviteDialogOpen(false);
                    setShowThankyou(true);
                    setShowArtisForm(false);
                    setShowGenreForm(false);
                })
                .catch((err) => {
                    if (err.code === 400) {
                        setshowEmailExistMessage(err.message)
                    }
                    console.log(err)
                });
        }
        else {
            console.log('---- Invalid Email ---- ');
            setshowEmailError('Invalid Email!')
        }

    }

    const validEmail = (e: string) => {
        var filter = /^\s*[\w\-\+_]+(\.[\w\-\+_]+)*\@[\w\-\+_]+\.[\w\-\+_]+(\.[\w\-\+_]+)*\s*$/;
        return String(e).search(filter) != -1;
    }

    const showSuggestionBox = (type: string) => {
        setValidateErrors({ ...validateError, isError: true });
        if (
			validateError.first_name.isError ||
			validateError.last_name.isError ||
			validateError.email.isError
		) {
			return false;
		} else {
			setValidateErrors({ ...validateError, isError: false });
		}
        
        if(type === 'artist')
        {
            setValues({ ...values, type: 'artist' });
            
            setShowArtisForm(true);
            setShowGenreForm(false);
            setShowThankyou(false);
        }
        else
        {
            setValues({ ...values, type: 'genre' });
            setShowArtisForm(false);
            setShowThankyou(false);
            setShowGenreForm(true);
        }
    }

    const closeCustomDialog = () =>{
            setinviteDialogOpen(false);
            setShowGenreForm(false);
            setShowArtisForm(false);
            setShowThankyou(false);

            setValues({ ...values, first_name: '', last_name: '', email: '', artist_contact: '', artist_name: '', description: '', event_date: '' });
            var errorValues = {message: '', isError: true}
            setValidateErrors({ ...validateError, 
                first_name: errorValues,
                last_name:  errorValues,
                email: errorValues,
                description: errorValues,
                artist_name: errorValues,
                isError: false}
                )
    }

    // The first commit of Material-UI
  const [event_date, setEvent_date] = React.useState(new Date());

  const handleDateChange = (date:any) => {
    setEvent_date(date);
  };
    


    return (
        <>
            <Link component="button"
                style={{
                    color: "white",
                    fontWeight: "initial",
                    fontSize: "initial",
                }}
                onClick={() => {
                    setinviteDialogOpen(true);
                }}>
                here!
            </Link>


            <Grid container direction="column" spacing={2}>


                <Dialog
                    fullScreen={false}
                    scroll="body"
                    open={inviteDialogOpen}
                    disableBackdropClick={true}
                    onClose={() => {
                        closeCustomDialog();
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    
                    {!showArtisForm && !showGenreForm && !showThankyou && (
                        <>
                    <CustomDialogTitle onClose={() => {
                        closeCustomDialog();
                    }} id="alert-dialog-title">
                        <Typography variant="h4" color="textPrimary" style={{ paddingBottom: "10px" }}>
                            HELP US HELP YOU!
				        </Typography>
                        <Grid item>
                            <Typography> Fill out the information below and we will be in touch soon. </Typography>
                        </Grid>
                    </CustomDialogTitle>
                    <Divider variant="middle" />
                    <DialogContent>
                        <Box px={5} py={1}>
                            <Grid container spacing={5}>
                                <Grid item xs={6}>
                                    <InputLabel htmlFor="first_name">First Name *</InputLabel>
                                    <TextField
                                        inputProps={{
                                            maxLength: 35,
                                        }}
                                        autoComplete="off"
                                        variant="outlined"
                                        color="secondary"
                                        id="first_name"
                                        name="first_name"
                                        //helperText={touched.first_name ? errors.first_name : ""}
                                        //error={touched.first_name && Boolean(errors.first_name)}
                                        fullWidth={true}
                                        margin="dense"
                                        value={values.first_name}
                                        error={validateError.first_name.isError && validateError.isError}
                                        onChange={e => handleChange('first_name', e.target.value)}
                                        placeholder="First name"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabel htmlFor="last_name">Last Name *</InputLabel>
                                    <TextField
                                        inputProps={{
                                            maxLength: 35,
                                        }}
                                        autoComplete="off"
                                        variant="outlined"
                                        color="secondary"
                                        id="last_name"
                                        name="last_name"
                                        fullWidth={true}
                                        margin="dense"
                                        value={values.last_name}
                                        error={validateError.last_name.isError && validateError.isError}
                                        onChange={e => handleChange('last_name', e.target.value)}
                                        placeholder="Last name"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <InputLabel htmlFor="email">Email Address *</InputLabel>
                                    <TextField
                                        inputProps={{
                                            maxLength: 100,
                                        }}
                                        autoComplete="off"
                                        variant="outlined"
                                        color="secondary"
                                        id="email"
                                        name="email"
                                        // helperText={touched.email ? errors.email : ""}
                                        // error={touched.email && Boolean(errors.email)}
                                        fullWidth={true}
                                        margin="dense"
                                        value={values.email}
                                        onChange={e => handleChange('email', e.target.value)}
                                        error={validateError.email.isError && validateError.isError}
                                        placeholder="Email"
                                    />
                                </Grid>
                            </Grid>                            
                            <Box py={5}>
                                <Grid container direction="column" justify="center" alignItems="center">
                                    <Grid item xs={12}>
                                        <Typography variant="body1" style={{ marginBottom: '15px', fontWeight: 'bold' }}>
                                            I want to suggest ...*
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container justify="center" direction="row" spacing={3}>
                                    <Grid item xs={6}>
                                        <Button type="submit" variant="contained" size="medium" color="secondary"  
                                            onClick={() => {showSuggestionBox('artist');}} style={{ padding: '8px 30px 8px', marginRight: '10px', width: '100%' }}>
                                            an Artist
							            </Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button type="submit" variant="contained" size="medium" color="secondary"
                                            onClick={() => {showSuggestionBox('genre');}} style={{ padding: '8px 30px 8px', marginLeft: '10px', width: '100%' }}>
                                            a Genre
							            </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </DialogContent>
                    </>
                    )}
                    {showArtisForm && !showGenreForm && (
                        <>
                        <CustomDialogTitle onClose={() => {
                            closeCustomDialog();
                    }} id="alert-dialog-title">
                        <Typography variant="h4" color="textPrimary" style={{ paddingBottom: "10px" }}>
                            HELP US HELP YOU!
				        </Typography>
                        <Grid item>
                            <Typography> Fill out the information below and we will be in touch soon. </Typography>
                        </Grid>
                    </CustomDialogTitle>
                    <Divider variant="middle" />
                    <Typography variant="h4" color="textPrimary" style={{ marginTop: "30px", textAlign: 'center' }}>
                            SUGGEST AN ARTIST
				</Typography>
                    <DialogContent>
                        <Box px={5} py={1}>
                            <Grid container justify="space-between" spacing={4}>
                                <Grid item xs={12}>
                                    <InputLabel htmlFor="artist_name">Artist Name *</InputLabel>
                                    <TextField
                                        inputProps={{
                                            maxLength: 35,
                                        }}
                                        autoComplete="off"
                                        variant="outlined"
                                        color="secondary"
                                        id="artist_name"
                                        name="artist_name"
                                        fullWidth={true}
                                        margin="dense"
                                        value={values.artist_name}
                                        error={validateError.artist_name.isError && validateError.isError}
                                        onChange={e => handleChange('artist_name', e.target.value)}
                                        placeholder="Artist Name"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <InputLabel htmlFor="artist_contact">Artist Contact or Social Media Link</InputLabel>
                                    <TextField
                                        inputProps={{
                                            maxLength: 35,
                                        }}
                                        autoComplete="off"
                                        variant="outlined"
                                        color="secondary"
                                        id="artist_contact"
                                        name="artist_contact"
                                        fullWidth={true}
                                        margin="dense"
                                        value={values.artist_contact}
                                        onChange={e => handleChange('artist_contact', e.target.value)}
                                        placeholder="Artist Contact or Social Media Link"
                                    />
                                </Grid>                             

                                <Grid item xs={12}>
										<Grid
											container
											direction="row"
											alignItems="center"
											
                                            >
											<Grid item xs={12} md={12} lg={12}>
												<InputLabel
													className={classes.alignText}
													htmlFor="date">
													Event Date
												</InputLabel>
											</Grid>
											<Grid item xs={12} md={12} lg={12}>
												<DayPickerInput
													component={forwardRef(
														(props, ref) => {
															return (
																<TextField
																	placeholder="Event Description"
																	classes={{root: classes.dropDownPlaceholder}}
																	autoComplete="off"
																	id="date"
																	name="description"
																	variant="outlined"
																	color="secondary"
                                                                    value={values.event_date}
																	InputProps={{
																		endAdornment: (
																			<DateRangeIcon
																				color="secondary"
																				style={{
																					marginRight:
																						"8px",
																				}}
																			/>
																		),
																	}}																	
																	fullWidth={true}
																	{...props}
																/>
															);
														},
													)}
													dayPickerProps={{
														disabledDays: {
															before: new Date(),
														},
													}}
													format="LL"
													onDayChange={handleDayChange}
													value={values.event_date}
													placeholder={"Date"}
												/>												
											</Grid>
										</Grid>
									</Grid>


                                <Grid item xs={12}>
                                    <InputLabel htmlFor="descrito">Additional Comments</InputLabel>
                                    <TextField
                                        autoComplete="off"
                                        variant="outlined"
                                        color="secondary"
                                        id="description"
                                        name="description"
                                        // helperText={touched.email ? errors.email : ""}
                                        // error={touched.email && Boolean(errors.email)}
                                        fullWidth={true}
                                        margin="dense"
                                        multiline={true}
										rows={6}
                                        value={values.description}
                                        onChange={e => handleChange('description', e.target.value)}
                                        placeholder="Additional Comments"
                                    />
                                </Grid>
                            </Grid>
                            <Box p={4}>
                                <Grid container justify="center" direction="row" spacing={3}>
                                    <Grid item>
                                        <Button type="submit" variant="contained" size="medium" color="secondary"  
                                        onClick={() => {sendEmailInvitationToBand();}} style={{ padding: '8px 30px 8px' }}>
                                            Submit
							            </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </DialogContent>
                        </>
                    )}

                    {!showArtisForm && showGenreForm && (
                        <>
                        <CustomDialogTitle onClose={() => {
                            closeCustomDialog();

                    }} id="alert-dialog-title">
                        <Typography variant="h4" color="textPrimary" style={{ paddingBottom: "10px" }}>
                            WE ARE OPEN TO SUGGESTION!
				</Typography>
                        <Grid item>
                            <Typography> Fill out the information below and we will be in touch soon. </Typography>
                        </Grid>
                    </CustomDialogTitle>
                    <Divider variant="middle" />
                    <Typography variant="h4" color="textPrimary" style={{ marginTop: "30px", textAlign: 'center' }}>
                            SUGGEST A GENRE
				</Typography>
                    <DialogContent>
                        <Box px={5} py={1}>
                            <Grid container justify="space-between" spacing={6}>
                                    <Grid item xs={12}>
										<Grid
											container
											direction="row"
											alignItems="center"											
                                            >
											<Grid item xs={12} md={12} lg={12}>
												<InputLabel
													className={classes.alignText}
													htmlFor="date">
													Event Date 
												</InputLabel>
											</Grid>
											<Grid item xs={12} md={12} lg={12}>
												<DayPickerInput
													component={forwardRef(
														(props, ref) => {
															return (
																<TextField
																	placeholder="Event Description"
																	classes={{root: classes.dropDownPlaceholder}}
																	autoComplete="off"
																	id="date"
																	name="description"
																	variant="outlined"
																	color="secondary"
                                                                    value={values.event_date}
																	InputProps={{
																		endAdornment: (
																			<DateRangeIcon
																				color="secondary"
																				style={{
																					marginRight:
																						"8px",
																				}}
																			/>
																		),
																	}}																	
																	fullWidth={true}
																	{...props}
																/>
															);
														},
													)}
													dayPickerProps={{
														disabledDays: {
															before: new Date(),
														},
													}}
													format="LL"
													onDayChange={handleDayChange}
													value={values.event_date}
													placeholder={"Date"}
												/>												
											</Grid>
										</Grid>
									</Grid>

                                <Grid item xs={12}>
                                    <InputLabel htmlFor="descrito">Describe the Genre You are interested in*</InputLabel>
                                    <TextField
                                        autoComplete="off"
                                        variant="outlined"
                                        color="secondary"
                                        id="description"
                                        name="description"
                                        // helperText={touched.email ? errors.email : ""}
                                        // error={touched.email && Boolean(errors.email)}
                                        fullWidth={true}
                                        margin="dense"
                                        multiline={true}
										rows={6}
                                        value={values.description}
                                        error={validateError.artist_name.isError && validateError.isError}
                                        onChange={e => handleChange('description', e.target.value)}
                                        placeholder="Describe the Genre You are interested in"
                                    />
                                </Grid>
                            </Grid>
                            <Box p={4}>
                                <Grid container justify="center" direction="row" spacing={3}>
                                    <Grid item>
                                        <Button type="submit" variant="contained" size="medium" color="secondary"  
                                        onClick={() => {sendEmailInvitationToBand();}} style={{ padding: '8px 30px 8px' }}>
                                            Submit
							            </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </DialogContent>
                        </>
                    )}

                    {showThankyou && (
                        <>
                         <CustomDialogTitle onClose={() => {
                            closeCustomDialog();

                    }} id="alert-dialog-title">
                        <Typography variant="h4" color="textPrimary" style={{ paddingBottom: "10px" }}>THANK YOU FOR YOUR SUGGESTION!</Typography>
                        <Grid item>
                            <Typography> We will reach out and be in touch as soon as possible. </Typography>
                        </Grid>
                    </CustomDialogTitle>
                    </>
                    )}


                </Dialog>

            </Grid>
        </>
    );
};