import React, { useEffect } from "react";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import createStyles from "@material-ui/core/styles/createStyles";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { BookerState } from "../EditBookerProfilePage/editBookerProfileSlice";
import BookerProfileForm from "./BookerProfileForm";
import * as Yup from "yup";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		dialogRoot: {
			border: "1px solid",
			borderColor: theme.palette.secondary.main,
		},
	}),
);

const validationSchema = Yup.object({
	photo: Yup.object().nullable(),
	fname: Yup.string()
		.required("First Name field is required")
		.matches(/^(?!\s*$).+/, { message: "First Name field is required" }),
	lname: Yup.string()
		.required("Last Name field is required")
		.matches(/^(?!\s*$).+/, { message: "Last Name field is required" }),
	phone_number: Yup.string().matches(/^[0-9]{10}$/, {
		message: "Phone number is not valid",
	}),
	phone_number1: Yup.string()
		.required()
		.matches(/^[0-9]{3}$/, { message: "Phone number is not valid" }),
	phone_number2: Yup.string()
		.required()
		.matches(/^[0-9]{3}$/, { message: "Phone number is not valid" }),
	phone_number3: Yup.string()
		.required()
		.matches(/^[0-9]{4}$/, { message: "Phone number is not valid" }),
	not_affiliated_organization: Yup.boolean(),
	organization_name: Yup.string().when('not_affiliated_organization', {
        is: false,
        then: Yup.string().required("Organization or Business Name is required")
            .trim()
            .max(65, "you exceeds the limit of allowed letters")
    }),
	events_from: Yup.string().matches(/^[0-9]{1,4}$/, {
		message: "Invalid type",
	}),
	events_to: Yup.string().matches(/^[0-9]{1,4}$/, {
		message: "Invalid type",
	}),
});

export interface EditBookerProfileProps {
	bookerProfile: BookerState;
	user: { username: string; lastLogin: string };
	editBookerProfile: (bookerProfile: any) => void;
	listBookerTypes: () => void;
	getBookerProfile: () => void;
	setPage: (page: string) => void;
	setLoc: (location: string) => void;
	handleSaveClose: () => void;
	handleFailClose: () => void;
}

export const EditBookerProfile: React.FC<EditBookerProfileProps> = ({
	setLoc,
	setPage,
	bookerProfile,
	editBookerProfile,
	listBookerTypes,
	user,
	getBookerProfile,
	handleSaveClose,
	handleFailClose,
}) => {
	const classes = useStyles();

	useEffect(() => {
		listBookerTypes();
		getBookerProfile();
		setPage("edit-profile");
		setLoc("/account/edit-profile");
	}, [getBookerProfile, listBookerTypes, setLoc, setPage]);

	const initialValues = {
		photo: (bookerProfile.bookerProfile && bookerProfile.bookerProfile.item.photo) || null,
		fname: (bookerProfile.bookerProfile && bookerProfile.bookerProfile.item.fname) || "",
		lname: (bookerProfile.bookerProfile && bookerProfile.bookerProfile.item.lname) || "",
		phone_number: (bookerProfile.bookerProfile && bookerProfile.bookerProfile.item.phone_number) || "",
		phone_number1:
			(bookerProfile.bookerProfile &&
				bookerProfile.bookerProfile.item.phone_number &&
				bookerProfile.bookerProfile.item.phone_number.substring(0, 3)) ||
			"",
		phone_number2:
			(bookerProfile.bookerProfile &&
				bookerProfile.bookerProfile.item.phone_number &&
				bookerProfile.bookerProfile.item.phone_number.substring(3, 6)) ||
			"",
		phone_number3:
			(bookerProfile.bookerProfile &&
				bookerProfile.bookerProfile.item.phone_number &&
				bookerProfile.bookerProfile.item.phone_number.substring(6, 10)) ||
			"",
		events_from: (bookerProfile.bookerProfile && bookerProfile.bookerProfile.item.events_from) || "",
		events_to: (bookerProfile.bookerProfile && bookerProfile.bookerProfile.item.events_to) || "",
		organization_name: (bookerProfile.bookerProfile && bookerProfile.bookerProfile.item.organization_name) || "",
		not_affiliated_organization: (bookerProfile.bookerProfile && bookerProfile.bookerProfile.item.organization_name ? false : true),
	};

	return (
		<Box>
			{bookerProfile.bookerProfileSucceeded && (
				<BookerProfileForm
					initialValues={initialValues}
					handleOnSubmit={(bandProfile: any) => editBookerProfile(bandProfile)}
					validationSchema={validationSchema}
					bandName={`${bookerProfile?.bookerProfile?.item?.fname} ${bookerProfile?.bookerProfile?.item?.lname}`}
				/>
			)}
			<Dialog
				fullWidth={true}
				maxWidth="sm"
				scroll="body"
				open={bookerProfile.saveModal}
				onClose={handleSaveClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				classes={{ paper: classes.dialogRoot }}>
				<DialogContent>
					<DialogContentText color="textPrimary" id="alert-dialog-description">
						Your Profile info is saved.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => handleSaveClose()}
						color="default"
						autoFocus>
						Ok
					</Button>
				</DialogActions>
			</Dialog>
			<Dialog
				fullWidth={true}
				maxWidth="sm"
				scroll="body"
				open={bookerProfile.editBookerProfileFailure}
				onClose={handleFailClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				classes={{ paper: classes.dialogRoot }}>
				<DialogContent>
					<DialogContentText color="textPrimary" id="alert-dialog-description">
						Something went wrong, please try again later.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleFailClose} color="default" autoFocus>
						Ok
					</Button>
				</DialogActions>
			</Dialog>
		</Box>
	);
};
