import React from "react";
import {
	Button,
	TextField,
	Grid,
	withStyles,
	InputLabel,
} from "@material-ui/core";
import { formStyles } from "./styles";
import ErrorMessage from "../generic/ErrorMessage";

const Form = (props: {
	classes: any;
	values: {
		password: string;
		confirmPassword: string;
	};
	errors: any;
	touched: any;
	handleChange: any;
	isValid: any;
	setFieldTouched: any;
	handleSubmit: any;
	showLoading: boolean;
}) => {
	const {
		classes,
		values: { password, confirmPassword },
		errors,
		touched,
		handleChange,
		isValid,
		setFieldTouched,
		handleSubmit,
		showLoading,
	} = props;

	const change = (name: any, e: { persist: () => void }) => {
		e.persist();
		handleChange(e);
		setFieldTouched(name, true, false);
	};

	return (
		<form className={classes.root} onSubmit={handleSubmit}>
			<Grid className={classes.margin} item xs={12}>
				<InputLabel className={classes.InputLabel} htmlFor="password">
					Password
				</InputLabel>
				<TextField
					autoComplete="off"
					id="password"
					name="password"
					variant="outlined"
					color="secondary"
					helperText={touched.password ? errors.password : ""}
					error={touched.password && Boolean(errors.password)}
					fullWidth={true}
					type="password"
					value={password}
					onChange={change.bind(null, "password")}
					margin="dense"
				/>
			</Grid>
			<Grid className={classes.margin} item xs={12}>
				<InputLabel
					className={classes.InputLabel}
					htmlFor="confirmPassword"
				>
					Confirm Password
				</InputLabel>
				<TextField
					autoComplete="off"
					id="confirmPassword"
					name="confirmPassword"
					variant="outlined"
					color="secondary"
					helperText={
						touched.confirmPassword ? errors.confirmPassword : ""
					}
					error={
						touched.confirmPassword &&
						Boolean(errors.confirmPassword)
					}
					fullWidth={true}
					type="password"
					value={confirmPassword}
					onChange={change.bind(null, "confirmPassword")}
					margin="dense"
				/>
			</Grid>
			<Grid container justify="center" direction="row" spacing={3}>
				<Grid item>
					<Button
						size="large"
						type="submit"
						variant="contained"
						color="secondary"
						disabled={showLoading ? true : !isValid}
					>
						Reset
					</Button>
				</Grid>
				<Grid item xs={12}>
					{errors && errors.code && <ErrorMessage error={errors} />}
				</Grid>
			</Grid>
		</form>
	);
};

export default withStyles(formStyles)(Form);
