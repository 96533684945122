import { combineReducers, createSlice, PayloadAction } from "redux-starter-kit";
import Services from "../../Services";

export interface AgentBandListState {
	agentBandsRequested: boolean;
	agentBandsSucceeded: boolean;
	agentBandsFailure: boolean;

	agentBands: any;
	error: {};
	page: string;
}

let initialState: AgentBandListState = {
	agentBandsRequested: false,
	agentBandsSucceeded: false,
	agentBandsFailure: false,

	agentBands: null,
	error: {},
	page: "",
};

const agentBandListPageSlice = createSlice({
	slice: "agentbandlistpage",
	initialState,
	reducers: {
		agentBandsRequested(state, action) {
			state.agentBandsRequested = true;
			state.agentBandsSucceeded = false;
			state.agentBandsFailure = false;
		},
		agentBandsSucceeded(state, action: PayloadAction<boolean>) {
			state.agentBandsRequested = false;
			state.agentBandsSucceeded = true;
			state.agentBandsFailure = false;
			state.agentBands = action.payload;
		},
		agentBandsFailure(state, action: PayloadAction<boolean>) {
			state.agentBandsRequested = false;
			state.agentBandsSucceeded = false;
			state.agentBandsFailure = true;
			state.error = action.payload;
		},
		setPage(state, action) {
			state.page = action.payload;
		},
	},
});

export const {
	agentBandsRequested,
	agentBandsSucceeded,
	agentBandsFailure,
	setPage,
} = agentBandListPageSlice.actions;

export default agentBandListPageSlice.reducer;

export const getAgentBandList = (): any => async (dispatch: any) => {
	try {
		dispatch(agentBandsRequested());
		const res = await Services.bands.listAgentBands();
		console.log('getAgentBandList res: ', res)
		dispatch(agentBandsSucceeded(res));
	} catch (err) {
		dispatch(agentBandsFailure(err));
	}
};
