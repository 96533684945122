import React, { useEffect, useState } from "react";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import { PlayCircleFilledWhite } from '@material-ui/icons';
import searchBarIcon from "../../Assets/Search Bar Icon.svg";
import { AppBar, Avatar, Box, Button, CircularProgress, Grid, Tab, Tabs, Typography } from "@material-ui/core";

import ImagePlaceholder from "../../Assets/ImagePlaceholder.png";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
//import moment from "moment";
import moment from "moment-timezone"
import GridLayout from 'react-grid-layout';
import { Responsive as ResponsiveGridLayout } from 'react-grid-layout';
//import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import './AgentDashboard.css'
//import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Calendar, momentLocalizer } from 'react-big-calendar'
import Services from "../../generic/Services";
import { useHistory, useLocation } from "react-router-dom";
import ReactGridLayout from "react-grid-layout";
import useInterval from '@use-it/interval';

interface AgentDashboardProps {
    setLoc: any;
    states: any;
    setPage: (page: string) => void;
    updateUserData: () => void;
    user: any;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        unread: {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText,
            fontWeight: "bold",
            borderRadius: "3px",
            fontSize: "13px",
            display: "inline-block",
            padding: "5px 15px"
        },
        message: {
            wordWrap: "break-word",
            width: "227px",
        },
        BOOKER: {
            border: `5px solid ${theme.palette.primary.main}`,
            width: 44,
            height: 44,
            margin: "0px",
        },
        TALENT: {
            border: `3px solid ${theme.palette.secondary.main}`,
            width: 44,
            height: 44,
            margin: "0px",
        },
        AGENT: {
            border: `3px solid #ffffff`,
            width: 44,
            height: 44,
            margin: "0px",
        },
        ADMIN: {
            width: 44,
            height: 44,
            margin: "0px",
        },
        notificationListGrid: {
            padding: '0px 0px 10px 0px',
            margin: '0px 0px 10px 0px',
            cursor: 'pointer',
        },
        SidebarEventDate: {
            fontFamily: 'Squada One',
            fontWeight: 'normal',
            fontSize: '24px',
            textTransform: 'uppercase'
        },
        eventRightSidebar: {
            width: '100%',
            maxHeight: '250px',
            overflowY: 'auto',
            paddingRight: '15px !important',
            "&::-webkit-scrollbar": {
                width: '3px',
                backgroundColor: 'transparent',
                borderRadius: '20px',
            },
            "&::-webkit-scrollbar-track": {
                borderRadius: '20px',
            },
            "&::-webkit-scrollbar-thumb": {
                borderRadius: '20px',
                backgroundColor: '#434257',
            },
            "&:sidebarEventItem": {
                fontSize: '10px'
            }
        },
        eventSidebarTitle: {
            fontFamily: 'Poppins',
            fontSize: '15px'
        },
        eventSidebarText: {
            fontSize: '11px'
        },
        eventCalenderMain: {
            border: '1px solid #5bffcc',
            borderRadius: '4px',
            padding: '15px',
            transitionProperty: 'none !important',
            transition: 'all 0ms ease !important'
        },
        avtarBack: {
            border: `3px solid ${theme.palette.primary.main}`,
            width: 44,
            height: 44,
            margin: "10px 0px -52px 18px",
            zIndex: 1,
        },
        avtarFront: {
            border: `3px solid ${theme.palette.secondary.main}`,
            width: 44,
            height: 44,
            margin: "0px 0px 0px 0px",
        },
        avtarRequest: {
            border: `3px solid ${theme.palette.secondary.main}`,
            width: 44,
            height: 44,
            margin: "0px",
        },
        notificationNewMsg: {
            position: "relative",
            "&::before": {
                position: "absolute",
                content: '""',
                backgroundColor: "#e11a1a",
                width: '8px',
                height: '8px',
                borderRadius: '15px',
                marginLeft: '-20px',
                marginTop: '15px',
            }
        }
    })
);

// Tab one declaration
interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={2}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: any) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}


// Tab two declaration
interface TabPanel2Props {
    children?: React.ReactNode;
    dir?: string;
    index: any;
    value2: any;
}

function TabPanel2(props: TabPanel2Props) {
    const { children, value2, index, ...other } = props;

    return (
        <div
            role="tabpanel2"
            hidden={value2 !== index}
            id={`full-width-tabpanel2-${index}`}
            aria-labelledby={`full-width-tab2-${index}`}
            {...other}
        >
            {value2 === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps2(index: any) {
    return {
        id: `full-width-tab2-${index}`,
        'aria-controls': `full-width-tabpanel2-${index}`,
    };
}


export const AgentDashboard: React.FC<AgentDashboardProps> = ({ setLoc, states, setPage, updateUserData, user }) => {
    //const AgentDashboard: React.FC = () => {
    let layout = [
        { i: 'a', x: 0, y: 0, w: 8.4, h: 9,  },
        { i: 'b', x: 0, y: 0, w: 4.2, h: 9, },
        { i: 'c', x: 4.2, y: 0, w: 4.2, h: 9, }
    ];

    if (window.screen.width >= 2400) {
        layout = [
            { i: 'a', x: 0, y: 0, w: 12, h: 9, },
            { i: 'b', x: 0, y: 0, w: 6, h: 9, },
            { i: 'c', x: 6, y: 0, w: 6, h: 9, }
        ];
    }

    const layouts = [{ lg: layout }]
    const ResponsiveGridLayout = ReactGridLayout.WidthProvider(ReactGridLayout.Responsive);

    //moment.tz.setDefault("Asia/Calcutta");
    const localizer = momentLocalizer(moment)
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const [eventList, setEventList] = useState([]);
    const [eventListByStatus, setEventListByStatus] = useState([]);

    const [requestWidgetList, setRequestWidgetList] = useState([]);
    const [showEventListByStatusLoading, setshowEventListByStatusLoading] = useState(true);
    const [showEventListByStatusInfo, setShowEventListByStatusInfo] = useState('Select a status label to expand details');
    

    const [requestWidgetTotalBudget, setRequestWidgetTotalBudget] = useState();
    const [requestWidgetTotalRequest, setRequestWidgetTotalRequest] = useState();

    const [notificationWidgetMessagesList, setNotificationWidgetMessagesList] = useState([]);
    const [notificationWidgetMessagesPage, setNotificationWidgetMessagesPage] = useState(0);
    const [notificationWidgetMessagesUnreadCount, setNotificationWidgetMessagesUnreadCount] = useState('');
    const [showMessageListLoading, setShowMessageListLoading] = useState(true);

    const [notificationWidgetRequestList, setNotificationWidgetRequestList] = useState([]);
    const [notificationWidgetRequestPage, setNotificationWidgetRequestPage] = useState(1);
    const [notificationWidgetRequestUnreadCount, setNotificationWidgetRequestUnreadCount] = useState('');
    const [showNotificationWidgetRequestListLoading, setShowNotificationWidgetRequestListLoading] = useState(true);

    const [notificationWidgetUpdateStatusList, setNotificationWidgetUpdateStatusList] = useState([]);
    const [notificationWidgetUpdateStatusPage, setNotificationWidgetUpdateStatusPage] = useState(1);
    const [notificationWidgetUpdateStatusUnreadCount, setNotificationWidgetUpdateStatusUnreadCount] = useState('');
    const [showNotificationWidgetUpdateStatustListLoading, setShowNotificationWidgetUpdateStatustListLoading] = useState(true);



    const [selectedDateEventList, setSelectedDateEventList] = useState([]);
    const [showSelectedDateEventListLoading, setShowSelectedDateEventListLoading] = useState(false);
    const [calendarSelectedDate, setCalendarSelectedDate] = useState(moment(new Date()).format('LL'));

    const [totalEventsBudgetByStatus, setTotalEventsBudgetByStatus] = useState("");

    const [loading, setLoading] = React.useState<boolean>(true);

    const REFRESH_INTERVAL = 15000;


    //Tabs event
    const [value, setValue] = React.useState(0);
    const [value2, setValue2] = React.useState(0);

    const [selectedReqWidgetIndex, setSelectedReqWidgetIndex] = React.useState(0);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index: number) => {
        setValue(index);
    };

    const handleChange2 = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue2(newValue);
    };

    const handleChangeIndex2 = (index: number) => {
        setValue2(index);
    };

    const selectDateHandle = (item: any) => {
        setShowSelectedDateEventListLoading(true);
        setCalendarSelectedDate(moment(item.start).format('LL'));
        const eventListData = Services.agent.listEventsByDate(item.start).then(data => {
            setShowSelectedDateEventListLoading(false);
            if (data) {
                if (data.items) {
                    setSelectedDateEventList(data.items);
                }
                else {
                    setSelectedDateEventList([]);
                }
            }
        })
    }

    const selectMonthHandle = (item: any) => {
        setCalendarSelectedDate(moment(item).format('LL'));
        getEventListData(item);
    }

    const changeText = (status: string) => {
        switch (status) {
            case "OPEN":
                return <Box color="#64fecd">Requested</Box>;
            case "ACCEPTED_BY_TALENT":
                return <Box color="#ffff00">Pending Contract</Box>;
            case "PENDING_DEPOSIT":
                return <Box color="#ffff00">Pending Deposit</Box>;
            case "PENDING_REMAINDER":
                return <Box color="#ff6600">Paid Deposit</Box>;
            case "APPROVED":
                return <Box color="#24b500">Paid in Full</Box>;
            case "COMPLETED":
                return <Box color="#24b500">Completed</Box>;
            case "REJECTED_BY_TALENT":
                return <Box color="#ff0000">Rejected</Box>;
            case "CANCELED_BY_BOOKER":
                return <Box color="#808080">Canceled by Booker</Box>;
            case "CANCELED_BY_TALENT":
                return <Box color="#808080">Canceled by Band</Box>;
            case "PENDING_FULL_PAYMENT":
                return <Box color="#ffff00">Pending Remainder</Box>;
            default:
                return status;
        }
    };


    useEffect(() => {
        setLoc('/account/dashboard');
        setPage("my-dashboard")
        getEventListData(new Date());
        getRequestWidgetData();
        getNotificationWidgetMessagesData();
        setTimeout(() => {
            getNotificationWidgetRequestData();
            getNotificationWidgetUpdateStatusData();
        }, 2000);
        //  getNotificationWidgetRequestData();
        //setNotificationList([]);

    }, [setEventList, setRequestWidgetList])

    const refreshWidgets = () => {
        // console.log('notificationWidgetMessagesPage:', notificationWidgetMessagesPage);
        // console.log('Retrieving widget data');
        getEventListData(calendarSelectedDate);
        getRequestWidgetDataRefresh();
        getNotificationWidgetMessagesDataRefresh();
        setTimeout(() => {
            getNotificationWidgetRequestDataRefresh();
            getNotificationWidgetUpdateStatusDataRefresh();
        }, 2000);
    }

    useInterval(async () => {
        refreshWidgets();
    }, REFRESH_INTERVAL);


    const getNotificationWidgetMessagesData = () => {
        setShowMessageListLoading(true);
        setNotificationWidgetMessagesPage(notificationWidgetMessagesPage + 1);
        Services.agent.dashboardNotificationWidgetGetMessageList(notificationWidgetMessagesPage).then(data => {
            setShowMessageListLoading(false);
            if (data.items.messages) {
                let messageList = [];
                messageList = [...notificationWidgetMessagesList];
                console.log(messageList);
                if (data.items.total_unread_message > 0) {

                    setNotificationWidgetMessagesUnreadCount(`(${data.items.total_unread_message})`);
                }


                if (data.items.messages.length > 0) {
                    if (messageList.length > 0) {
                        data.items.messages.forEach((element: never) => {
                            messageList.push(element);
                        });
                        setNotificationWidgetMessagesList(messageList);
                    }
                    else {
                        setNotificationWidgetMessagesList(data.items.messages);

                    }
                }
            }
        })
    }

    const getNotificationWidgetMessagesDataRefresh = () => {
        setShowMessageListLoading(true);
        // setNotificationWidgetMessagesPage(notificationWidgetMessagesPage + 1);
        Services.agent.dashboardNotificationWidgetGetMessageList(0).then(data => {
            setShowMessageListLoading(false);
            if (data.items.messages) {
                if (data.items.total_unread_message > 0) {
                    setNotificationWidgetMessagesUnreadCount(`(${data.items.total_unread_message})`);
                }
                setNotificationWidgetMessagesList(data.items.messages);
            }
        })
    }


    const getNotificationWidgetRequestData = () => {
        setShowNotificationWidgetRequestListLoading(true);
        setNotificationWidgetRequestPage(notificationWidgetRequestPage + 1);
        Services.agent.dashboardNotificationWidgetGetRequestList(notificationWidgetRequestPage).then(data => {
            setShowNotificationWidgetRequestListLoading(false);
            if (data.items) {
                let requestList = [];
                requestList = [...notificationWidgetRequestList];

                if (data.items.data.length > 0) {

                    if (data.items.total_request > 0) {

                        setNotificationWidgetRequestUnreadCount(`(${data.items.total_request})`);
                    }

                    if (requestList.length > 0) {
                        data.items.data.forEach((element: never) => {
                            requestList.push(element);
                        });
                        
                        setNotificationWidgetRequestList(requestList);

                    }
                    else {
                        setNotificationWidgetRequestList(data.items.data);
                    }
                }
            }
        })
    }
    
    const getNotificationWidgetRequestDataRefresh = () => {
        setShowNotificationWidgetRequestListLoading(true);
        Services.agent.dashboardNotificationWidgetGetRequestList(0).then(data => {
            setShowNotificationWidgetRequestListLoading(false);
            if (data.items) {

                if (data.items.data.length > 0) {

                    if (data.items.total_request > 0) {
                        setNotificationWidgetRequestUnreadCount(`(${data.items.total_request})`);
                    }

                    setNotificationWidgetRequestList(data.items.data);
                }
            }
        })
    }

    const getNotificationWidgetUpdateStatusData = () => {
        setShowNotificationWidgetUpdateStatustListLoading(true);
        setNotificationWidgetUpdateStatusPage(notificationWidgetUpdateStatusPage + 1);
        Services.agent.dashboardNotificationWidgetGetUpdateStatusList(notificationWidgetUpdateStatusPage).then(data => {
            
            setShowNotificationWidgetUpdateStatustListLoading(false);
            if (data.items) {
                let requestList = [];
                requestList = [...notificationWidgetUpdateStatusList];

                if (data.items.data.length > 0) {
                    if (data.items.total_new_request > 0) {

                        setNotificationWidgetUpdateStatusUnreadCount(`(${data.items.total_new_request})`);
                    }
                    if (requestList.length > 0) {
                        data.items.data.forEach((element: never) => {
                            requestList.push(element);
                        });
                        setNotificationWidgetUpdateStatusList(requestList);

                    }
                    else {
                        setNotificationWidgetUpdateStatusList(data.items.data);
                    }
                }
            }
        })
    }

    const getNotificationWidgetUpdateStatusDataRefresh = () => {
        setShowNotificationWidgetUpdateStatustListLoading(true);
        Services.agent.dashboardNotificationWidgetGetUpdateStatusList(0).then(data => {
            
            setShowNotificationWidgetUpdateStatustListLoading(false);
            if (data.items) {
                if (data.items.total_new_request > 0) {
                    setNotificationWidgetUpdateStatusUnreadCount(`(${data.items.total_new_request})`);
                }
                setNotificationWidgetUpdateStatusList(data.items.data);
            }
        })
    }




    const getEventListData = (itemDate: any) => {

        let currentDate = moment(itemDate);
        let currentMonth = currentDate.month() + 1;
        let currentYear = currentDate.year();
        let currentDateFormat = moment(itemDate).format('YYYY-MM-DD');
        const eventListData = Services.agent.listEventsByMonth(currentMonth, currentYear).then(data => {

            setLoading(false);
            if (data.items) {
                if (data.items.length > 0) {
                    data.items.forEach((element: any) => {
                        element.start = new Date(element.startTime * 1000);
                        element.startFormatted = new Date(element.start).toISOString().slice(0, 10);
                    });

                    setEventList(data.items);
                    //Get Today's event data.
                    let getTodaysEventCount = data.items.filter((ele: any) => ele.startFormatted == currentDateFormat);
                    if (getTodaysEventCount.length > 0) {
                        let getCurrentDateData = {
                            start: currentDateFormat
                        }
                        selectDateHandle(getCurrentDateData);
                    }
                    else {
                        setSelectedDateEventList([])
                    }

                }
                else {
                    setSelectedDateEventList([])
                }
            }
            else {
                setSelectedDateEventList([])
            }
        });
    }
    const getRequestWidgetData = () => {

        const eventListData = Services.agent.dashboardRequestWidget().then(data => {
            setLoading(false);
            
            if (data.items) {

                setRequestWidgetList(data.items.list);
                if (data.items.list.length > 0) {
                    showEventsByStatusHandle(data.items.list[0]['status'], 0)
                }

                setRequestWidgetTotalBudget(data.items.total_budget);
                setRequestWidgetTotalRequest(data.items.total_request);

            }
        });
    }

    const getRequestWidgetDataRefresh = () => {

        const eventListData = Services.agent.dashboardRequestWidget().then(data => {
            setLoading(false);
            
            if (data.items) {

                setRequestWidgetList(data.items.list);
                setRequestWidgetTotalBudget(data.items.total_budget);
                setRequestWidgetTotalRequest(data.items.total_request);

            }
        });
    }

    const showEventsByStatusHandle = (item: any, index: number) => {

        setShowEventListByStatusInfo('Select a status label to expand details');
        setTotalEventsBudgetByStatus("");
        setSelectedReqWidgetIndex(index);
        setshowEventListByStatusLoading(true);
        const eventListData = Services.agent.dashboardRequestWidgetGetEventsByStatus(item).then(data => {
            setshowEventListByStatusLoading(false);
            if (data.items) {
                setEventListByStatus(data.items);
                let totalValue = 0;
                if(data.items.length > 0)
                {
                    data.items.forEach((element: any) => {
                        totalValue = totalValue + parseFloat(element.production_budget) + parseFloat(element.talent_budget)
                    });
    
                    setTotalEventsBudgetByStatus(getAbriviatedNumber(totalValue));
                }
                else
                {
                    setTotalEventsBudgetByStatus(getAbriviatedNumber(totalValue));
                    setShowEventListByStatusInfo('No requests in this status');
                }
                
            }
            else
            {
                setShowEventListByStatusInfo('No requests in this status');
            }
        });
    }

    const getCalculatedFees = (item: any) => {
        let number = parseFloat(item.production_budget) + parseFloat(item.talent_budget);

        return getAbriviatedNumber(number)
    }

    const getAbriviatedNumber = (number: any) => {
        var SI_SYMBOL = ["", "K", "M", "G", "T", "P", "E"];
        // what tier? (determines SI symbol)
        var tier = Math.log10(Math.abs(number)) / 3 | 0;

        // if zero, we don't need a suffix
        if (tier == 0) return number;

        // get suffix and determine scale
        var suffix = SI_SYMBOL[tier];
        var scale = Math.pow(10, tier * 3);

        // scale the number
        var scaled = number / scale;

        // format number and add suffix
        return scaled.toFixed(1) + suffix;
    }

    const redirectToEventPageHandle = (item: any, bandId: number, eventRequestId: number, requestStatus?: string) => {
        Services.agent.updateAgentRequestLastSeen(eventRequestId).then(data => {})

        let currentDate = new Date();
        let eventDate = new Date(item);
        if (currentDate > eventDate) {
                history.push({
                    pathname: `/account/past-event/band/${bandId}`,
                    state: { requestId: eventRequestId },
                })
            
        }
        else {
            if(requestStatus != undefined && (requestStatus == 'CANCELED_BY_BOOKER' || requestStatus == 'CANCELED_BY_TALENT' || requestStatus == 'REJECTED_BY_TALENT' || requestStatus == 'COMPLETED'))
            {
                history.push({
                    pathname: `/account/past-event/band/${bandId}`,
                    state: { requestId: eventRequestId },
                })
            }
            else
            {
                history.push({
                    pathname: `/account/active-event/band/${bandId}`,
                    state: { requestId: eventRequestId },
                })
            }
            
        }

    }

    const redirectToMessagePageHandle = (bandId: number, eventRequestId: number) => {
        Services.eventRequest.updateLastSeen(eventRequestId);
        history.push({
            pathname: `/account/messages/band/${bandId}`,
            state: { requestId: eventRequestId, new: true },
        })
    }

    if (loading) {
        return <div>loading...</div>;
    }

    return (
        <>
            {/* <ResponsiveGridLayout  rowHeight={30} width={1200} breakpoints={{lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0}}
        cols={{lg: 12, md: 10, sm: 6, xs: 4, xxs: 2}}> */}
            <GridLayout layout={layout} isDraggable={false} isResizable={false} rowHeight={30} width={1200} style={{ width: '100%', margin: '-10px -35px' }}>
                {/* <ResponsiveGridLayout breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }} rowHeight={30} cols={{ lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 }} layouts={{ lg: layout, md: layout, sm: layout, xs: layout }}> */}
                <Grid key="a" className={classes.eventCalenderMain} style={{ border: '1px solid #5bffcc', borderRadius: '4px', padding: '15px' }}>
                    <Grid container direction="row" justify="space-between" alignItems="stretch">
                        <Grid md={8} lg={8} style={{ paddingRight: '25px' }}>
                            <Calendar
                                localizer={localizer}
                                events={eventList}
                                startAccessor="start"
                                endAccessor="end"
                                onSelectEvent={(e) => { selectDateHandle(e) }}
                                style={{ height: 300, border: 'none' }}
                                views={['month']}
                                onNavigate={(e) => { selectMonthHandle(e) }}
                            />
                        </Grid>
                        <Grid md={4} lg={4}>
                            <Typography variant="h5" className={classes.SidebarEventDate}>{calendarSelectedDate}</Typography>
                            <Box component="div" className={classes.eventRightSidebar}>
                                {selectedDateEventList && selectedDateEventList.length > 0 ? (<>
                                    {
                                        selectedDateEventList.map((item: any, index: number) => (
                                            <Box component="div" display="flex" alignItems="start" justifyContent="flex-start" className={classes.sidebarEventItem}>
                                                <Grid key={index} container direction="row" alignItems="flex-start" justify="space-between" className={classes.notificationListGrid}>
                                                    <Grid item xs={4} md={3} lg={3} justify="flex-start">
                                                        <Avatar
                                                            alt="Band thumbnail"
                                                            // src={ImagePlaceholder}
                                                            className={classes.TALENT}
                                                            src={item.band?.photo?.thumbnail || ImagePlaceholder}
                                                        />

                                                    </Grid>
                                                    <Grid item xs={8} md={9} lg={9}>
                                                        <Grid container direction="row" alignItems="flex-start" justify="space-between" style={{ marginBottom: '7px', }}>
                                                            <Grid item xs={6} md={7} lg={7}>
                                                                <Typography variant="h6" className={classes.eventSidebarTitle}>{item.band.name}</Typography>
                                                            </Grid>
                                                            <Grid item xs={6} md={5} lg={5}>
                                                                <Button onClick={() => {
                                                                    redirectToEventPageHandle(item.event.date, item.band.id, item.id);
                                                                }} variant="contained" size="small" color="secondary" style={{ width: 76, fontSize: '10px', padding: '4px 4px 2px' }}>
                                                                    View Details
                                                    </Button>
                                                            </Grid>
                                                        </Grid>
                                                        <Typography className={classes.eventSidebarText}>{item.event.title}</Typography>
                                                        <Typography className={classes.eventSidebarText}>{item.event.location}</Typography>
                                                        <Typography className={classes.eventSidebarText}>{
                                                            `${moment(item.event.date).format("MMM DD, YYYY").toString()} ${moment(
                                                                item.event.start_time, "h:mm:ss").format("h:mm A")}`} </Typography>
                                                        <Typography className={classes.eventSidebarText}><em className={classes.statusMsg}>{changeText(item.status)}</em></Typography>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        )
                                        )}
                                </>) : (<>
                                    <Typography variant="body2" style={{ marginTop: "10px", fontStyle: "italic" }}>
                                        {!showSelectedDateEventListLoading ? 'You have no events on this day.' : ''}
                                    </Typography>
                                </>)}

                            </Box>
                            {showSelectedDateEventListLoading && (
                                <Grid container direction="row" alignItems="center" justify="center" className={classes.notificationListGrid}>
                                    <CircularProgress />
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>

                <Grid key="b" className={classes.eventCalenderLeft} style={{ border: '1px solid #5bffcc', borderRadius: '4px', padding: '15px' }}>
                    <Grid container direction="row" justify="space-between" alignItems="stretch">
                        <Grid xs={6} md={6} lg={6}>
                            <Grid className="dashTabmain">
                                <AppBar position="static">
                                    <Tabs value={value} onChange={handleChange} aria-label="simple tabs example" className="tabBarMain">
                                        <Tab label="Count" {...a11yProps(0)} className="countTabList" />
                                        <Tab label="Dollars" {...a11yProps(2)} className="countTabList" />
                                    </Tabs>
                                </AppBar>
                                <TabPanel value={value} index={0}>
                                    <Grid className="tabDataListHeight">
                                        {requestWidgetList && requestWidgetList.map((item: any, index: number) => (
                                            <Grid container direction="row" style={{ cursor: "pointer" }} onClick={() => { showEventsByStatusHandle(item.status, index) }} justify="space-between" alignItems="stretch" className={`countDollarItem ${selectedReqWidgetIndex === index ? 'countDollarSelected' : ''}`}>
                                                <Grid xs={10} md={10} lg={10}>{item.display_staus}</Grid>
                                                <Grid xs={2} md={2} lg={2}><Box textAlign="right">
                                                    {item.status == "CANCELED_BY_BOOKER" || item.status == "CANCELED_BY_TALENT" ? (
                                                        <span style={{ "color": "red" }}> {item.count} </span>
                                                    ) : (
                                                            <span > {item.count} </span>
                                                        )}

                                                </Box></Grid>
                                            </Grid>
                                        )
                                        )}
                                    </Grid>
                                    <Grid container direction="row" justify="space-between" alignItems="stretch" className="countDollarFooter">
                                        <Grid xs={10} md={10} lg={10}>Total Count</Grid>
                                        <Grid xs={2} md={2} lg={2}><Box textAlign="right">{requestWidgetTotalRequest}</Box></Grid>
                                    </Grid>
                                </TabPanel>
                                <TabPanel value={value} index={1}>
                                    <Grid className="tabDataListHeight">
                                        {requestWidgetList && requestWidgetList.map((item: any, index: number) => (
                                            <Grid container direction="row" style={{ cursor: "pointer" }} onClick={() => { showEventsByStatusHandle(item.status, index) }} justify="space-between" alignItems="stretch" className={`countDollarItem ${selectedReqWidgetIndex === index ? 'countDollarSelected' : ''}`}>
                                                <Grid xs={10} md={10} lg={10}>{item.display_staus}</Grid>
                                                <Grid xs={2} md={2} lg={2}>
                                                    {item.status == "CANCELED_BY_BOOKER" || item.status == "CANCELED_BY_TALENT" ? (
                                                        <span style={{ "color": "red" }}> ${item.budget_display} </span>
                                                    ) : (
                                                            <span > ${item.budget_display} </span>
                                                        )}
                                                </Grid>
                                            </Grid>
                                        )
                                        )}

                                        

                                    </Grid>
                                    <Grid container direction="row" justify="space-between" alignItems="stretch" className="countDollarFooter">
                                        <Grid xs={10} md={10} lg={10}>Total Value</Grid>
                                        <Grid xs={2} md={2} lg={2}>${requestWidgetTotalBudget}</Grid>
                                    </Grid>


                                </TabPanel>
                            </Grid>
                        </Grid>
                        <Grid xs={6} md={6} lg={6}>
                            <Grid container direction="row" justify="space-between" alignItems="stretch">
                                <Grid xs={12} md={12} lg={12}>
                                        
                                    <Grid className="dollarsListMain">
                                        <Grid className="dollarsItems">
                                            {!showEventListByStatusLoading && (!eventListByStatus || eventListByStatus.length <= 0) && (
                                                <Grid container direction="row" style={{fontSize: "12px", textAlign: "center"}} alignItems="flex-start" justify="space-between" >
                                                    <em> {showEventListByStatusInfo} </em>
                                                </Grid>
                                            )}

                                            {showEventListByStatusLoading && (
                                                <Grid container direction="row" alignItems="center" justify="center">
                                                    <CircularProgress />
                                                </Grid>
                                            )}
                                            {!showEventListByStatusLoading && eventListByStatus && eventListByStatus.map((item: any, index: number) => (
                                                <Grid className="dollarsItem">
                                                    <Typography variant="h5">{item.event.title}</Typography>
                                                    <Typography>{item.band.name}</Typography>
                                                    <Typography><strong className="dollarsPrice">$ {getCalculatedFees(item)}</strong></Typography>
                                                    <Button variant="contained" size="small" color="secondary" style={{ width: 76, fontSize: '10px', padding: '4px 4px 2px' }} onClick={() => { redirectToEventPageHandle(item.event.date, item.band.id, item.id, item.status) }}>View Details</Button>
                                                </Grid>
                                            )
                                            )}

                                        </Grid>
                                        
                                        <Grid className="dollarsListFooter">
                                            Total Value: ${totalEventsBudgetByStatus}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid key="c" className={classes.eventCalenderRight} style={{ border: '1px solid #5bffcc', borderRadius: '4px', padding: '15px' }}>
                    <Grid container direction="row" justify="space-between" alignItems="stretch">
                        <Grid xs={12} md={12} lg={12} >
                            <AppBar position="static">
                                <Tabs value={value2} onChange={handleChange2} aria-label="simple tabs example" className="tabBarMain">
                                    <Tab label={`Messages  ${notificationWidgetMessagesUnreadCount}`} {...a11yProps2(0)} className="countTabList countTabListLg" />
                                    <Tab label={`Requests ${notificationWidgetRequestUnreadCount}`} {...a11yProps2(1)} className="countTabList countTabListLg" />
                                    <Tab label={`Status Updates ${notificationWidgetUpdateStatusUnreadCount}`} {...a11yProps2(2)} className="countTabList countTabListLg" />
                                </Tabs>
                            </AppBar>
                            <TabPanel2 value2={value2} index={0}>
                                <Grid className="notificationListHeight">
                                    {notificationWidgetMessagesList && notificationWidgetMessagesList.map((item: any, index: number) => (
                                        <Grid onClick={() => { redirectToMessagePageHandle(item.data?.band_id, item.data?.id) }} container direction="row" alignItems="flex-start" justify="space-between" className={classes.notificationListGrid} style={{ display: "inline-flex" }} >
                                            <Grid item xs={4} md={3} lg={3} justify="flex-start" className={`${item.is_unread_message ? classes.notificationNewMsg : ''}`}>
                                                <Avatar alt="Messages Avtar" src={item.data?.band?.photo?.thumbnail || ImagePlaceholder} className={classes.avtarBack} />
                                                <Avatar alt="Messages Avtar" src={item.data?.event?.created_by?.photo?.thumbnail || ImagePlaceholder} className={classes.avtarFront} />
                                            </Grid>
                                            <Grid item xs={8} md={9} lg={9}>
                                                <Typography variant="h6" className={classes.eventSidebarTitle}>{item.data?.event?.title}</Typography>
                                                <Typography className={classes.eventSidebarText} style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}><span>{item.message_text}</span> <strong style={{ margin: "-5px 7px 0px 7px", fontWeight: 700, display: "inline-block" }}>.</strong>  <span>{item.message_time ? moment.unix(item.message_time).fromNow() : ""}</span></Typography>
                                            </Grid>
                                        </Grid>
                                    ))}

                                    {!showMessageListLoading && (
                                        <>
                                            {notificationWidgetMessagesList && notificationWidgetMessagesList.length > 0 ? (
                                                <>
                                                    {(notificationWidgetMessagesList.length % 10) === 0 && (
                                                        <Grid container direction="row" alignItems="center" justify="center" className={classes.notificationListGrid}>
                                                            <Grid item xs={12} md={12} lg={12} style={{ textAlign: "center" }}><Button variant="contained" size="medium" color="secondary" style={{ fontSize: '12px', padding: '6px 18px 4px' }} onClick={() => { getNotificationWidgetMessagesData() }}>Load More</Button></Grid>
                                                        </Grid>
                                                    )}
                                                </>

                                            ) : (
                                                    <Grid container direction="row" alignItems="flex-start" justify="space-between" className={classes.notificationListGrid} style={{ display: "inline-flex" }}>
                                                        No Message Found!
                                                    </Grid>
                                                )}
                                        </>
                                    )}

                                    {showMessageListLoading && (
                                        <Grid container direction="row" alignItems="center" justify="center" className={classes.notificationListGrid}>
                                            <CircularProgress />
                                        </Grid>
                                    )}



                                </Grid>
                            </TabPanel2>
                            <TabPanel2 value2={value2} index={1}>
                                <Grid className="notificationListHeight">
                                    {notificationWidgetRequestList && notificationWidgetRequestList.map((item: any, index: number) => (
                                        <Grid container direction="row" alignItems="flex-start" justify="space-between" className={classes.notificationListGrid} style={{ display: "inline-flex" }}>
                                            <Grid item xs={3} md={2} lg={2} justify="flex-start" className={`${item.agent_last_seen_request <= item.created_at ? classes.notificationNewMsg : ''}`}>
                                                <Avatar alt="Messages Avtar" src={item.band?.photo?.thumbnail || ImagePlaceholder} className={classes.avtarRequest} />
                                            </Grid>
                                            <Grid item xs={9} md={10} lg={10} style={{ paddingLeft: "10px" }}>
                                                <Typography variant="h6" className={classes.eventSidebarTitle} style={{ lineHeight: "20px", marginBottom: "8px" }}>{item.event?.title}</Typography>
                                                <Typography className={classes.eventSidebarText}>Band Requested: {item.band?.name} </Typography>
                                                <Typography className={classes.eventSidebarText}>Requested by: {item.event?.created_by?.fname} {item.event?.created_by?.lname}</Typography>
                                                <Typography className={classes.eventSidebarText}>Date: {moment(item.event?.date).format("MMM DD, YYYY").toString()}</Typography>
                                                <Typography className={classes.eventSidebarText}>Time: {moment(item.event?.start_time, "h:mm:ss").format("h:mm A")}</Typography>
                                                <Typography className={classes.eventSidebarText}>Budget: ${getAbriviatedNumber(parseFloat(item.production_budget) + parseFloat(item.talent_budget))}</Typography>
                                                <Typography className={classes.eventSidebarText}><em>{item.created_at ? moment.unix(item.created_at).fromNow() : ""}</em>
                                                    <Button
                                                        variant="contained"
                                                        size="small"
                                                        color="secondary"
                                                        style={{ width: 76, fontSize: '10px', padding: '4px 4px 2px', float: "right" }}
                                                        onClick={() => {
                                                            redirectToEventPageHandle(item.event?.date, item.band?.id, item.id);
                                                        }}>
                                                        View Details
                                                </Button>
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    ))}

                                    {!showNotificationWidgetRequestListLoading && (
                                        <>
                                            {notificationWidgetRequestList && notificationWidgetRequestList.length > 0 ? (
                                                <>
                                                    {(notificationWidgetRequestList.length % 10) === 0 && (
                                                        <Grid container direction="row" alignItems="center" justify="center" className={classes.notificationListGrid}>
                                                            <Grid item xs={12} md={12} lg={12} style={{ textAlign: "center" }}><Button variant="contained" size="medium" color="secondary" style={{ fontSize: '12px', padding: '6px 18px 4px' }} onClick={() => { getNotificationWidgetRequestData() }}>Load More</Button></Grid>
                                                        </Grid>
                                                    )}
                                                </>
                                            ) : (
                                                    <Grid container direction="row" alignItems="center" justify="space-between" className={classes.notificationListGrid} style={{ display: "inline-flex" }}>
                                                        No Request Found!
                                                    </Grid>
                                                )}
                                        </>
                                    )}

                                    {showNotificationWidgetRequestListLoading && (
                                        <Grid container direction="row" alignItems="center" justify="center" className={classes.notificationListGrid}>
                                            <CircularProgress />
                                        </Grid>
                                    )}
                                </Grid>

                            </TabPanel2>
                            <TabPanel2 value2={value2} index={2}>
                                <Grid className="notificationListHeight">
                                    {notificationWidgetUpdateStatusList && notificationWidgetUpdateStatusList.map((item: any, index: number) => (
                                        <Grid container direction="row" alignItems="flex-start" justify="space-between" className={classes.notificationListGrid} style={{ display: "inline-flex" }}>
                                            <Grid item xs={3} md={2} lg={2} justify="flex-start" className={`${item.agent_last_seen_request < item.previous_status_time ? classes.notificationNewMsg : ''}`}>
                                                <Avatar alt="Messages Avtar" src={item.band?.photo?.thumbnail || ImagePlaceholder} className={classes.avtarRequest} />
                                            </Grid>
                                            <Grid item xs={9} md={10} lg={10} style={{ paddingLeft: "10px" }}>
                                                <Typography variant="h6" className={classes.eventSidebarTitle} style={{ lineHeight: "20px", marginBottom: "8px" }}>{item.event?.title}</Typography>
                                                <Typography className={classes.eventSidebarText}>{item.band?.name}</Typography>
                                                <Typography className={classes.eventSidebarText}>{moment(item.event?.date).format("MMM DD, YYYY").toString()} at {moment(item.event?.start_time, "h:mm:ss").format("h:mm A")}</Typography>
                                                <Typography className={classes.eventSidebarText} style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>{changeText(item.previous_status)} <PlayCircleFilledWhite style={{ fontSize: 12, margin: '0px 3px -3px 4px' }} /> {changeText(item.status)}</Typography>
                                                <Typography className={classes.eventSidebarText} style={{ marginTop: 5 }}>
                                                    <em>{item.previous_status_time ? moment.unix(item.previous_status_time).fromNow() : ""}</em>
                                                    <Button
                                                        variant="contained"
                                                        size="small"
                                                        color="secondary"
                                                        onClick={() => {
                                                            redirectToEventPageHandle(item.event?.date, item.band?.id, item.id, item.status);
                                                        }}
                                                        style={{ width: 76, fontSize: '10px', padding: '4px 4px 2px', float: "right" }}>
                                                        View Details
                                                </Button>
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    ))}
                                    {!showNotificationWidgetUpdateStatustListLoading && (
                                        <>
                                            {notificationWidgetUpdateStatusList && notificationWidgetUpdateStatusList.length > 0 ? (
                                                <>
                                                    {(notificationWidgetUpdateStatusList.length % 10) === 0 && (
                                                        <Grid container direction="row" alignItems="center" justify="center" className={classes.notificationListGrid}>
                                                            <Grid item xs={12} md={12} lg={12} style={{ textAlign: "center" }}><Button variant="contained" size="medium" color="secondary" style={{ fontSize: '12px', padding: '6px 18px 4px' }} onClick={() => { getNotificationWidgetUpdateStatusData() }}>Load More</Button></Grid>
                                                        </Grid>
                                                    )}
                                                </>
                                            ) : (
                                                    <Grid container direction="row" alignItems="center" justify="space-between" className={classes.notificationListGrid} style={{ display: "inline-flex" }}>
                                                        No Request Found!
                                                    </Grid>
                                                )}
                                        </>
                                    )}

                                    {showNotificationWidgetUpdateStatustListLoading && (
                                        <Grid container direction="row" alignItems="center" justify="center" className={classes.notificationListGrid}>
                                            <CircularProgress />
                                        </Grid>
                                    )}
                                </Grid>
                            </TabPanel2>
                        </Grid>
                    </Grid>
                </Grid>
            </GridLayout>
            {/* </ResponsiveGridLayout> */}
        </>
    );
}

// export default AgentDashboard;