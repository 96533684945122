export enum ResetPasswordActionType {
	RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST",
	RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS",
	RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE",
	RESET_PASSWORD_DIALOG_OPEN = "RESET_PASSWORD_DIALOG_OPEN",
	RESET_PASSWORD_DIALOG_CLOSE = "RESET_PASSWORD_DIALOG_CLOSE",
	RESET_SUCCESS = "RESET_SUCCESS",
	CONFIRMATION_OPEN = "CONFIRMATION_OPEN",
	CONFIRMATION_CLOSE = "CONFIRMATION_CLOSE",
}

export interface IResetPasswordReducer {
	resetPasswordDialogOpen: boolean;
	resetPasswordRequest: boolean;
	resetPasswordSuccess: boolean;
	confirmationOpen: boolean;
	error: any;
}

export interface IResetPasswordAction {
	type: ResetPasswordActionType;
	payload: any;
}

export interface IResetPasswordProps {
	location: any;
	handleOpen: () => any;
	handleClose: () => any;
	handleSubmit: (data: { password: string }, token: string) => any;
	resetPasswordState: IResetPasswordReducer;
	classes: any;
	handleConfirmationClose: () => void;
}

export interface IConfirmationDialogProps {
	opened: boolean;
	handleClose: () => void;
	classes: any;
}
