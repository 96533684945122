export const headers = () => {
	// return authorization header with jwt token
	const data = localStorage.getItem("user") || "{}";
	const user = JSON.parse(data);

	const headers = {
		"Content-Type": "application/json",
		"X-Requested-With": "XMLHttpRequest",
		Token: "",
	};

	if (user && user.api_token) {
		headers.Token = user.api_token;
	}
	return headers;
};

export const baseUrl =  process.env.REACT_APP_API_URL;
