import { Dispatch } from "redux";
import { ListEventRequestActionType } from "../interfaces";
import Services from "../../../generic/Services";

const openApproveModal = (id?: number) => {
	return {
		type:
			ListEventRequestActionType.EVENT_REQUEST_APPROVE_CONFIRM_DIALOG_OPEN,
		payload: { id },
	};
};

const closeApproveModal = (id?: number) => {
	return {
		type:
			ListEventRequestActionType.EVENT_REQUEST_APPROVE_CONFIRM_DIALOG_CLOSE,
		payload: { id },
	};
};

const approveEventRequest = (requestId?: number) => {
	if (!requestId) {
		return;
	}
	return (dispatch: Dispatch) => {
		dispatch(request(requestId));
		dispatch(closeApproveModal(requestId));
		Services.eventRequest
			.changeStatus(requestId, "APPROVED_BY_TALENT")
			.then(
				(response: any) => {
					dispatch(success(requestId, response));
				},
				error => {
					dispatch(fail(requestId, error));
				},
			);
	};

	function request(id: number) {
		return {
			type: ListEventRequestActionType.APPROVE_EVENT_REQUEST,
			payload: { id },
		};
	}

	function success(id: number, response: any) {
		return {
			type: ListEventRequestActionType.APPROVE_EVENT_SUCCESS,
			payload: { id, response },
		};
	}

	function fail(id: number, response: any) {
		return {
			type: ListEventRequestActionType.APPROVE_EVENT_FAILURE,
			payload: { id, response },
		};
	}
};

const openRejectModal = (id?: number) => {
	return {
		type:
			ListEventRequestActionType.EVENT_REQUEST_REJECT_CONFIRM_DIALOG_OPEN,
		payload: { id },
	};
};

const closeRejectModal = (id?: number) => {
	return {
		type:
			ListEventRequestActionType.EVENT_REQUEST_REJECT_CONFIRM_DIALOG_CLOSE,
		payload: { id },
	};
};

const rejectEventRequest = (requestId?: number) => {
	if (!requestId) {
		return;
	}
	return (dispatch: Dispatch) => {
		dispatch(request(requestId));
		dispatch(closeRejectModal(requestId));
		Services.eventRequest
			.changeStatus(requestId, "REJECTED_BY_TALENT")
			.then(
				(response: any) => {
					dispatch(success(requestId, response));
				},
				error => {
					dispatch(fail(requestId, error));
				},
			);
	};

	function request(id: number) {
		return {
			type: ListEventRequestActionType.REJECT_EVENT_REQUEST,
			payload: { id },
		};
	}

	function success(id: number, response: any) {
		return {
			type: ListEventRequestActionType.REJECT_EVENT_SUCCESS,
			payload: { id, response },
		};
	}

	function fail(id: number, response: any) {
		return {
			type: ListEventRequestActionType.REJECT_EVENT_FAILURE,
			payload: { id, response },
		};
	}
};

export const TalentEventRequestActions = {
	openApproveModal,
	closeApproveModal,
	approveEventRequest,
	openRejectModal,
	closeRejectModal,
	rejectEventRequest,
};
