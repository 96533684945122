import React, { useEffect } from "react";
import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import MusicPlayIcon from "../Assets/music-play.png";
import PartyIcon from "../Assets/party.png";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { Checkbox, FormControlLabel, IconButton, SnackbarContent } from "@material-ui/core";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		signupItem: {
			padding: '10%',
			border: '4px solid #080522',
			textAlign: 'center',
			"&:hover": {
				border: '4px solid #64FECC',
				borderRadius: '20px',
			}
		},
		columnPaddingTop: {
			"@media screen and (max-width: 767px)": {
				paddingTop: "0px !important",
				paddingBottom: "30px !important",
			},
		},
		missingProfileError: {
			backgroundColor: 'transparent',
			boxShadow: '0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 0px 0px 0px rgb(0 0 0 / 12%)',
			border: '1px solid #FE0000',
			color: '#FE0000',
			fontSize: '16px',
			fontWeight: 800,
			fontStyle: 'italic',
			borderRadius: '10px',
			paddingTop: '2px',
			paddingBottom: '2px',
			textAlign: 'center',
			display: 'inline-block',
			minWidth: '10px',
		},
		textFieldWidth: {
			maxWidth: "100px",
			marginRight: "35px",
			"@media screen and (max-width: 767px)": {
				marginRight: "2vw !important",
				maxWidth: "80px",
			},
		},
	})
);



const Form = (props: {
	values: {
		email: string;
		username: string;
		fname: string;
		lname: string;
		password: string;
		confirmPassword: string;
		role: any;
		promo_code: string;
		phone_number: string;
		phone_number1: string;
		phone_number2: string;
		phone_number3: string;
		organization_name: string;
		not_affiliated_organization: boolean;
	};
	errors: any;
	touched: any;
	setFieldValue: any;
	setFieldTouched: any;
	setFieldError: any;
	handleChange: any;
	isValid: any;
	handleSubmit: any;
	handleDialogClose: any;
	fullScreen: boolean;
	setSelectedRole: any;
	setSelectedSignupStep: any;
	selectedSignupStep: number;
	handleOpenLogin: any;
	serverError: any;
	confirmedEmail: string;
	isSubmitLoading: boolean;
}) => {
	const classes = useStyles();
	const location = useLocation();

	const {
		values: { email, username, fname, lname, password, confirmPassword, promo_code, phone_number, phone_number1, phone_number2, phone_number3, organization_name, not_affiliated_organization },
		errors,
		touched,
		setFieldValue,
		setFieldTouched,
		setFieldError,
		handleChange,
		handleSubmit,
		fullScreen,
		setSelectedRole,
		handleDialogClose,
		selectedSignupStep,
		setSelectedSignupStep,
		handleOpenLogin,
		serverError,
		confirmedEmail,
		isSubmitLoading
	} = props;

	const change = (name: any, e: any) => {
		e.persist();
		handleChange(e);
		setFieldTouched(name, true, false);
	};

	const [bookerVariant, setbookerVariant] = React.useState<"text" | "outlined" | "contained" | undefined>(
		"contained",
	);
	const [talentVaraint, settalentVaraint] = React.useState<"text" | "outlined" | "contained" | undefined>("contained");
	const [agentVaraint, setagentVaraint] = React.useState<"text" | "outlined" | "contained" | undefined>("contained");

	const handleRoleSelection = (name: any, value: any) => {
		if (value !== "") {
			setSelectedSignupStep(5);
		}

		setSelectedRole(value);
		setFieldTouched(name, true, false);
		setFieldValue(name, value, true);
	};

	useEffect(() => {
		if (location?.hash?.toLowerCase() == '#talent') {
			setSelectedRole('TALENT');
			handleRoleSelection("role", "TALENT")
		}
		else if (location?.hash?.toLowerCase() == '#booker') {
			setSelectedRole('BOOKER');
			handleRoleSelection("role", "BOOKER")
		}
		else if (location?.hash?.toLowerCase() == '#agent') {
			setSelectedRole('AGENT');
			handleRoleSelection("role", "AGENT")
		}

	}, [])
	useEffect(() => {
		if(serverError && serverError.code && serverError?.server_message == "USERNAME_ALREADY_EXIST"){
			setFieldError("username", "Username already exists")
		}
	}, [serverError])
	const testError = "missing_profile";

	const inputRef1 = React.useRef<HTMLDivElement>(null);
	const inputRef2 = React.useRef<HTMLDivElement>(null);
	const inputRef3 = React.useRef<HTMLDivElement>(null);

	return (
		<>
			{(selectedSignupStep == 1 || selectedSignupStep == 3) &&
				<form onSubmit={handleSubmit}>
					<Box px={fullScreen ? 0 : 5} pt={2}>
						<Grid>
							<Grid container justify="space-between" spacing={6}>
								<Grid item xs={12}>
									<InputLabel htmlFor="email">Email Address *</InputLabel>
									<TextField
										inputProps={{
											maxLength: 100,
										}}
										autoComplete="off"
										variant="outlined"
										color="secondary"
										id="email"
										name="email"
										helperText={touched.email ? errors.email : ""}
										error={touched.email && Boolean(errors.email)}
										fullWidth={true}
										margin="dense"
										value={email}
										placeholder="example@example.com"
										onChange={change.bind(null, "email")}
									/>
								</Grid>
							</Grid>
							<Box px={6} pt={4}>
								<Grid container justify="center" direction="row" spacing={3}>
									<Grid item className="mt-4" style={{ textAlign: 'center' }}>
										<Button type="submit" variant="contained" color="secondary" disabled={isSubmitLoading} style={{ margin: "0px 10px 10px 10px", paddingTop: '7px', paddingBottom: '7px', minWidth: '232px', }}>
											Verify My Email Address
										</Button>
										<Link

											style={{
												color: "white",
												fontWeight: "initial",
												fontSize: "initial",
												cursor: "pointer",
												textDecoration: "underline",
												margin: "0px 10px 10px 10px",
											}}
											onClick={handleDialogClose}>
											Cancel
										</Link>
									</Grid>
								</Grid>
							</Box>
							<Grid item xs={12} style={{ textAlign: 'center', paddingBottom: "25px", paddingTop: "25px" }}>
							{serverError && serverError.code && (
								
									<SnackbarContent
										className={`${classes.missingProfileError}`}
										aria-describedby="error-message"
										message={
											<div>
												{serverError?.message == "account_already_exists" ?
													(<span id="error-message">Account already exists. Please <Link onClick={handleOpenLogin} style={{ color: '#FE0000', cursor: "pointer", textDecoration: "underline" }}> log in.</Link></span>)
													: serverError?.message == "email_verified_missing_profile" ?
														(<span id="error-message">Missing profile information. Please use <Link onClick={() => { setSelectedSignupStep(1) }} style={{ color: '#FE0000', cursor: "pointer", textDecoration: "underline" }}> Sign Up</Link> form to reverify.</span>)
														: serverError?.message == "email_not_verified" ?
															(<span id="error-message">Your email has not been verified. Please check your inbox or use the <Link onClick={() => { setSelectedSignupStep(1) }} style={{ color: '#FE0000', cursor: "pointer", textDecoration: "underline" }}> Sign Up</Link> Sign Up form.</span>)
															: <span id="error-message">{serverError?.message}</span>
												}

											</div>
										}
									/>
								
							)}
							</Grid>
						</Grid>

					</Box>
				</form>
			}
			{selectedSignupStep == 4 &&
				<Box px={fullScreen ? 0 : 5} py={2}>
					<Grid container justify="space-between" spacing={6}>
						<Grid item xs={12} style={{ paddingTop: '0px' }}>
							<Grid container direction="row" justify="space-around" spacing={7}>
								<Grid item xs={12} sm={6} onClick={(e) => handleRoleSelection("role", "TALENT")} style={{ cursor: "pointer", paddingBottom: '0px' }}>
									<Grid className={classes.signupItem}>
										<img alt="Party" src={MusicPlayIcon} height={'88px'} />
										<Typography variant="body2" style={{ fontSize: '20px', fontWeight: 'normal', lineHeight: '1.2em', textAlign: 'center', margin: '0.7em 0em' }}>
											I want to play music at an event.
										</Typography>
										<Button
											size="small"
											name="role"
											value="TALENT"
											variant={talentVaraint}
											color="secondary"
											fullWidth
											onClick={(e) => {
												handleRoleSelection("role", "TALENT")
											}}
											style={{ padding: '5px 4px' }}>
											Rock On!
										</Button>
									</Grid>
								</Grid>
								<Grid item xs={12} sm={6} className={classes.columnPaddingTop} style={{ cursor: "pointer" }} onClick={(e) => handleRoleSelection("role", "BOOKER")}>
									<Grid className={classes.signupItem}>
										<img alt="Party" src={PartyIcon} height={'88px'} />
										<Typography variant="body2" style={{ fontSize: '20px', fontWeight: 'normal', lineHeight: '1.2em', textAlign: 'center', margin: '0.7em 0em' }}>
											I want to book musicians to play my event.
										</Typography>
										<Button
											size="small"
											name="role"
											value="BOOKER"
											variant={bookerVariant}
											color="secondary"
											fullWidth
											onClick={(e) => handleRoleSelection("role", "BOOKER")}
											style={{ padding: '5px 4px' }}>
											Book Now!
										</Button>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<Grid container direction="column" justify="center" alignItems="center" style={{ marginTop: '10px' }}>
						<Grid item>
							<Typography variant="body2" style={{ fontSize: '20px', fontWeight: 'normal', lineHeight: '1.2em', textAlign: 'center', margin: '1.3em 0em' }}>
								Do you represent artists or musicians?
							</Typography>
							<Button
								size="small"
								name="role"
								value="AGENT"
								variant="contained"
								color="secondary"
								fullWidth
								onClick={(e) =>
									handleRoleSelection("role", "AGENT")}
								style={{ padding: '5px 4px' }}>
								Create an Agent Account
							</Button>
						</Grid>
					</Grid>
				</Box>
			}
			{selectedSignupStep == 5 &&
				<form onSubmit={handleSubmit}>
					<Box px={fullScreen ? 0 : 5} pt={2}>
						<IconButton aria-label="Close" size="small" onClick={(e) => {
							setSelectedSignupStep(4);
							setSelectedRole("");
							handleRoleSelection("role", "")
						}} style={{ right: "auto", left: "8px" }}>
							<KeyboardBackspaceIcon
								color="secondary"
								style={{ fontSize: 32 }}
							/>
						</IconButton>
						<Grid container justify="space-between" spacing={6}>
							<Grid item xs={12} sm={6}>
								<InputLabel htmlFor="fname">First Name *</InputLabel>
								<TextField
									inputProps={{
										maxLength: 35,
									}}
									autoComplete="off"
									variant="outlined"
									color="secondary"
									id="fname"
									name="fname"
									helperText={touched.fname ? errors.fname : ""}
									error={touched.fname && Boolean(errors.fname)}
									fullWidth={true}
									margin="dense"
									value={fname}
									placeholder="First name"
									onChange={change.bind(null, "fname")}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<InputLabel htmlFor="lname">Last Name *</InputLabel>
								<TextField
									inputProps={{
										maxLength: 35,
									}}
									autoComplete="off"
									variant="outlined"
									color="secondary"
									id="lname"
									name="lname"
									helperText={touched.lname ? errors.lname : ""}
									error={touched.lname && Boolean(errors.lname)}
									fullWidth={true}
									margin="dense"
									value={lname}
									placeholder="Last name"
									onChange={change.bind(null, "lname")}
								/>
							</Grid>
							<Grid item xs={12}>
								<InputLabel htmlFor="email">Email Address *</InputLabel>
								<TextField
									inputProps={{
										maxLength: 100,
									}}
									autoComplete="off"
									variant="outlined"
									color="secondary"
									id="email"
									name="email"
									helperText={touched.email ? errors.email : ""}
									error={touched.email && Boolean(errors.email)}
									fullWidth={true}
									margin="dense"
									value={email}
									placeholder="example@example.com"
								/>
							</Grid>
							<Grid item xs={12} md={12} lg={12}>
								<InputLabel htmlFor="phone_number">Phone Number *</InputLabel>
								<TextField
									placeholder="XXX"
									className={classes.textFieldWidth}
									autoComplete="off"
									id="phone_number1"
									name="phone_number1"
									value={phone_number1}
									helperText={
										touched.phone_number1
											? errors.phone_number1
											: ""
									}
									error={
										touched.phone_number1 &&
										Boolean(
											errors.phone_number1,
										)
									}
									variant="outlined"
									color="secondary"
									margin="dense"
									inputRef={inputRef1}
									inputProps={{
										maxLength: 3,
										style: { textAlign: "center" },
									}}
									onChange={(e: any) => {
										e.persist();
										if (
											e.target.value.length === 3 &&
											inputRef2 &&
											inputRef2.current
										) {
											inputRef2.current.focus();
										}
										setFieldValue(
											"phone_number1",
											e.target.value,
										);
										setFieldTouched(
											"phone_number1",
											true,
											false,
										);
									}}
								/>
								<TextField
									placeholder="XXX"
									className={classes.textFieldWidth}
									autoComplete="off"
									id="phone_number2"
									name="phone_number2"
									error={
										touched.phone_number2 &&
										Boolean(
											errors.phone_number2,
										)
									}
									helperText={
										touched.phone_number2
											? errors.phone_number2
											: ""
									}
									value={phone_number2}
									variant="outlined"
									color="secondary"
									inputProps={{
										maxLength: 3,
										style: { textAlign: "center" },
									}}
									inputRef={inputRef2}
									margin="dense"
									onChange={(e: any) => {
										e.persist();
										if (
											e.target.value.length === 3 &&
											inputRef3 &&
											inputRef3.current
										) {
											inputRef3.current.focus();
										}
										setFieldValue(
											"phone_number2",
											e.target.value,
										);
										setFieldTouched(
											"phone_number2",
											true,
											false,
										);
									}}
								/>
								<TextField
									placeholder="XXXX"
									className={classes.textFieldWidth}
									autoComplete="off"
									id="phone_number3"
									name="phone_number3"
									error={
										touched.phone_number3 &&
										Boolean(
											errors.phone_number3,
										)
									}
									helperText={
										touched.phone_number3
											? errors.phone_number3
											: ""
									}
									value={phone_number3}
									variant="outlined"
									color="secondary"
									inputProps={{
										maxLength: 4,
										style: { textAlign: "center" },
									}}
									margin="dense"
									inputRef={inputRef3}
									onChange={(e: any) => {
										if (
											e.target.value.length === 1 &&
											inputRef2 &&
											inputRef2.current &&
											e.key === "Backspace"
										) {
											inputRef2.current.focus();
										}
										setFieldValue(
											"phone_number3",
											e.target.value,
										);
										setFieldTouched(
											"phone_number3",
											true,
											false,
										);
									}}
								/>
							</Grid>
							{/* <Grid item xs={12}>
								<InputLabel htmlFor="phone_number">Phone Number *</InputLabel>
								<TextField
									inputProps={{
										maxLength: 10,
										minLength: 10
									}}
									autoComplete="off"
									variant="outlined"
									color="secondary"
									id="phone_number"
									name="phone_number"
									helperText={touched.phone_number ? errors.phone_number : ""}
									error={touched.phone_number && Boolean(errors.phone_number)}
									fullWidth={true}
									margin="dense"
									value={phone_number}
									placeholder="Phone Number"
									onChange={change.bind(null, "phone_number")}
								/>
							</Grid> */}
							{props?.values?.role == "TALENT" && (
								<Grid item xs={12}>
									<InputLabel htmlFor="username">Create Username *</InputLabel>

									<Box>
										<Grid container justify="flex-start" alignItems="flex-start">
											<Grid item xs={12} sm={3} md={2}>
												<span style={{ marginTop: '1.8em', display: 'block', fontSize: '13px' }}>
													repsy.com/
												</span>
											</Grid>
											<Grid item xs={12} sm={9} md={10}>
												<TextField
													inputProps={{
														maxLength: 65,
													}}
													autoComplete="off"
													variant="outlined"
													color="secondary"
													id="username"
													name="username"
													helperText={touched.username ? errors.username : ""}
													error={touched.username && Boolean(errors.username)}
													fullWidth={true}
													margin="dense"
													value={username}
													placeholder="myusername"
													onChange={change.bind(null, "username")}
												/>

											</Grid>
										</Grid>
										{/* {serverError && serverError.code && serverError?.server_message == "USERNAME_ALREADY_EXIST" && (
											<Grid container justify="flex-start" alignItems="center">
												<Grid item xs={11}>
													<Typography variant="body1" color="error">
														Username already exists
													</Typography>
												</Grid>
											</Grid>
										)} */}
										<Grid container justify="flex-start" alignItems="center">
											<Grid item xs={12} sm={11}>
												<Typography variant="body2" color="textPrimary" style={{ paddingBottom: "10px", fontSize: '12px', letterSpacing: '-0.3px' }}>
													Choose a unique username without spaces or symbols. This username will serve as your page URL so we recommend using your band or stage name.
												</Typography>
											</Grid>
										</Grid>

									</Box>
								</Grid>
							)}
							<Grid item xs={12} sm={6}>
								<InputLabel htmlFor="password">Create Password *</InputLabel>
								<TextField
									autoComplete="off"
									variant="outlined"
									color="secondary"
									id="password"
									name="password"
									type="password"
									helperText={touched.password ? errors.password : ""}
									error={touched.password && Boolean(errors.password)}
									fullWidth={true}
									margin="dense"
									value={password}
									placeholder="Password"
									onChange={change.bind(null, "password")}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<InputLabel htmlFor="confirmPassword">Confirm Password *</InputLabel>
								<TextField
									autoComplete="off"
									variant="outlined"
									color="secondary"
									id="confirmPassword"
									name="confirmPassword"
									type="password"
									helperText={touched.confirmPassword ? errors.confirmPassword : ""}
									error={touched.confirmPassword && Boolean(errors.confirmPassword)}
									fullWidth={true}
									margin="dense"
									value={confirmPassword}
									placeholder="Password"
									onChange={change.bind(null, "confirmPassword")}
								/>
							</Grid>
							{props?.values?.role == "BOOKER" && (
								<Grid item xs={12}>
									<InputLabel htmlFor="organization_name">Organization or Business Name*</InputLabel>
									<TextField
										inputProps={{
											maxLength: 65
										}}
										autoComplete="off"
										variant="outlined"
										color="secondary"
										id="organization_name"
										name="organization_name"
										helperText={touched.organization_name ? errors.organization_name : ""}
										error={touched.organization_name && Boolean(errors.organization_name)}
										fullWidth={true}
										margin="dense"
										value={organization_name}
										placeholder="Name of Greek Organization, Company, or Club"
										onChange={change.bind(null, "organization_name")}
									/>
									<FormControlLabel
										style={{ marginLeft: "-3px" }}
										control={
											<Checkbox
												color="secondary"
												id="not_affiliated_organization"
												name="not_affiliated_organization"
												classes={{ root: classes.checkbox }}
												checked={not_affiliated_organization}
												onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
													setFieldValue(
														"not_affiliated_organization",
														event.target.checked,
													);
													setFieldTouched(
														"not_affiliated_organization",
														true,
														false,
													);
												}}
												value="termsAndCondition"
											/>
										}
										label={<div>Not affiliated with an organization or business</div>}
									/>
								</Grid>
							)}
						</Grid>

						<Grid item xs={12}>
							<InputLabel htmlFor="promo_code" style={{ padding: '44px 0px 10px' }}>Promo Code</InputLabel>
							<TextField
								inputProps={{
									maxLength: 8,
									style: { textTransform: 'uppercase' }
								}}
								placeholder="Promo Code"
								autoComplete="off"
								variant="outlined"
								color="secondary"
								id="promo_code"
								name="promo_code"
								helperText={touched.promo_code ? errors.promo_code : ""}
								error={touched.promo_code && Boolean(errors.promo_code)}
								fullWidth={true}
								margin="dense"
								value={promo_code}
								onChange={(e) => {
									const regex = /[^A-Za-z0-9]/g;
									let newStr = e.target.value.replace(regex, "");
									newStr = newStr?.toUpperCase();
									e.target.value = newStr;
									change("promo_code", e);
								}}
							/>
						</Grid>
						<Box py={4}>
							<Grid container justify="center" direction="row">
								<Grid item xs={12}>
									<Button type="submit" style={{ padding: "4px 5px" }} size="medium" variant="contained" color="secondary" fullWidth disabled={isSubmitLoading}>
									{props?.values?.role == "TALENT" ? "Next" : "Create Profile"}
									</Button>
								</Grid>
								{serverError && serverError.code && serverError?.server_message != "USERNAME_ALREADY_EXIST" && (
									<Grid item xs={12} style={{ textAlign: 'center' }}>
										<SnackbarContent
											className={`${classes.missingProfileError}`}
											aria-describedby="error-message"
											message={
												<div><span id="error-message">{serverError?.message}</span></div>
											}
										/>
									</Grid>
								)}
							</Grid>
						</Box>
					</Box>
				</form>
			}
		</>

	);
};

export default Form;
