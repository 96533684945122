export enum ConfirmationDialogActionType {
	RESEND_CONFIRMATION_REQUEST = "RESEND_CONFIRMATION_REQUEST",
	RESEND_CONFIRMATION_SUCCESS = "RESEND_CONFIRMATION_SUCCESS",
	RESEND_CONFIRMATION_FAILURE = "RESEND_CONFIRMATION_FAILURE",
}

export interface IConfirmationDialogReducer {
	confirmationDialogRequest: boolean;
	confirmationDialogSuccess: boolean;
	confirmationDialogFailed: boolean;
}

export interface IConfirmationDialogOwnProps {
	opened: boolean;
	handleClose: () => void;
	handleResendConfirmation: (email: string) => void;
}

export interface IConfirmationDialogProps extends IConfirmationDialogOwnProps {
	classes: any;
	userEmail: string;
	state: IConfirmationDialogReducer;
}

export interface IConfirmationDialogActions {
	type: ConfirmationDialogActionType;
	payload: any;
}
