import { createSlice } from "redux-starter-kit";
import Services from "../../generic/Services";

export interface SettingsState {
	editPasswordRequested: boolean;
	editPasswordSucceeded: boolean;
	editPasswordFailure: boolean;

	editEmailRequested: boolean;
	editEmailSucceeded: boolean;
	editEmailFailure: boolean;

	errorPassword: {
		code: number;
		message: string;
		errors: [];
	};

	errorEmail: {
		code: number;
		message: string;
		errors: [];
	};

	userMessage: string;
}

let initialState: SettingsState = {
	editPasswordRequested: false,
	editPasswordSucceeded: false,
	editPasswordFailure: false,

	editEmailRequested: false,
	editEmailSucceeded: false,
	editEmailFailure: false,

	errorPassword: {
		code: 0,
		message: "",
		errors: [],
	},

	errorEmail: {
		code: 0,
		message: "",
		errors: [],
	},

	userMessage: ""
};

const settingsSlice = createSlice({
	slice: "settings",
	initialState,
	reducers: {
		editPasswordRequested(state, action) {
			state.editPasswordRequested = true;
			state.editPasswordSucceeded = false;
			state.editPasswordFailure = false;
		},
		editPasswordSucceeded(state, action) {
			state.editPasswordRequested = false;
			state.editPasswordSucceeded = true;
			state.editPasswordFailure = false;
			state.errorPassword = {
				code: 0,
				message: "",
				errors: [],
			};
			state.userMessage = action.payload.user_message
		},
		editPasswordFailure(state, action) {
			state.editPasswordSucceeded = false;
			state.editPasswordRequested = false;
			state.editPasswordFailure = true;
			state.errorPassword = action.payload;
			state.userMessage = ""
		},

		editEmailRequested(state, action) {
			state.editEmailRequested = true;
			state.editEmailSucceeded = false;
			state.editEmailFailure = false;
		},
		editEmailSucceeded(state, action) {
			state.editEmailRequested = false;
			state.editEmailSucceeded = true;
			state.editEmailFailure = false;
			state.errorEmail = {
				code: 0,
				message: "",
				errors: [],
			};
			state.userMessage = action.payload.user_message;
		},
		editEmailFailure(state, action) {
			state.editEmailSucceeded = false;
			state.editEmailRequested = false;
			state.editEmailFailure = true;
			state.errorEmail = action.payload;
		},
		closeModal(state, action) {
			state.editPasswordFailure = false;
			state.editPasswordSucceeded = false;
			state.editPasswordRequested = false;
			state.userMessage = "";
			state.errorPassword = {
				code: 0,
				message: "",
				errors: [],
			};

			state.editEmailRequested = false;
			state.editEmailSucceeded = false;
			state.editEmailFailure = false;
			state.errorEmail = {
				code: 0,
				message: "",
				errors: [],
			};
		},
	},
});

export const {
	editPasswordRequested,
	editPasswordSucceeded,
	editPasswordFailure,

	editEmailRequested,
	editEmailSucceeded,
	editEmailFailure,

	closeModal,
} = settingsSlice.actions;

export default settingsSlice.reducer;

export const editPassword = (passwordFormObject: any): any => async (
	dispatch: any,
) => {
	try {
		dispatch(editPasswordRequested());
		const res = await Services.settings.updatePassword(passwordFormObject);
		dispatch(editPasswordSucceeded(res));
		return true;
	} catch (err) {
		dispatch(editPasswordFailure(err));
		return false;
	}
};

export const editEmail = (emailFormObject: any): any => async (
	dispatch: any,
) => {
	try {
		dispatch(editEmailRequested());
		const res = await Services.settings.updateEmail(emailFormObject);
		dispatch(editEmailSucceeded(res));
		return true;
	} catch (err) {
		dispatch(editEmailFailure(err));
		return false;
	}
};
