import React from "react";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { Link as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import { Typography } from "@material-ui/core";

export const Footer = () => {
	const theme = useTheme();

	return (
		<footer>
			<Box
				bgcolor={theme.palette.common.black}
				color={theme.palette.secondary.main}
				fontSize="1rem"
				textAlign="center"
				padding={5}
				position="relative"
				mt={8}>										
				
				Copyright Repsy 2020
				<Box component="span" m={2}>
					|
				</Box>
				All Rights Reserved
				<Box component="span" m={2}>
					|
				</Box>

				<RouterLink to="/blogs"
					type="button"
					style={{"color": "rgb(100, 254, 204)", "textDecoration": "none"}}>
					Blog
				</RouterLink>

				<Box component="span" m={2}>
					|
				</Box>

				
					<Link
						href="/privacy"
						type="button"
						style={{"color": "rgb(100, 254, 204)", "textDecoration": "none"}}>
						Privacy Policy
					</Link>					
				
				<Box component="span" m={2}>
					|
				</Box>
				(205) 905-7500
				<Box component="span" m={2}>
					|
				</Box>
				2020 11th St. N. Birmingham AL, 35203
			</Box>
		</footer>
	);
};
