import { AuthActionType } from "./interfaces";
import { Dispatch } from "redux";
import Services from "../generic/Services";
import { fetchResources } from "../generic/Resources";
import headerActions from "../Header/actions";

function login(email: string, password: string, cb?: () => void) {
	return (dispatch: Dispatch) => {
		dispatch(request({ email }));
		Services.users.login(email, password).then(
			(response) => {
				if (response.server_message === "FIRST_LOGGED_IN") {
					dispatch(first());
				}
				dispatch(success(response.item));
				fetchResources(dispatch);
				const channel = new BroadcastChannel("sw-messages");
				channel.addEventListener("message", (event) => {
					console.log("Received", event.data);
					if (event.data.data.type === "NEW_MESSAGE") {
						dispatch(headerActions.incrementMessageNotification(event.data.data.id));
					} else {
						dispatch(headerActions.newNotification({ message: event.data.notification.body, timestamp: new Date().getTime(), data: event.data.data }))
					}
				});
				if (cb) {
					cb();
				}
			},
			(error) => {
				dispatch(failure(error));
			},
		);
	};

	function request(user: any) {
		return { type: AuthActionType.LOGIN_REQUEST, payload: user };
	}
	function success(user: any) {
		return { type: AuthActionType.LOGIN_SUCCESS, payload: user };
	}
	function failure(error: string) {
		return { type: AuthActionType.LOGIN_FAILURE, payload: error };
	}
	function first() {
		return { type: AuthActionType.FIRST_LOGIN_POPUP_OPEN }
	}
}

function firstLogInClose() {
	return { type: AuthActionType.FIRST_LOGIN_POPUP_CLOSE }
}

function logout() {
	return (dispatch: Dispatch) => {
		dispatch(out());
		Services.users.logout().then(() => {
			localStorage.clear();
			fetchResources(dispatch);
		}).catch((e) => {
			localStorage.clear();
		})
	};
	function out() {
		return { type: AuthActionType.LOGOUT };
	}
}

function updateUserAction(payload: any) {
	return { type: AuthActionType.UPDATE_USER, payload };
}

function updateTalentStatuses(payload: any) {
	return { type: AuthActionType.UPDATE_TALENT_STATUSES, payload };
}

function editUser(payload: any) {
	return { type: AuthActionType.EDIT_USER, payload };
}

function register(user: any, cb?: () => void) {
	return (dispatch: Dispatch) => {
		dispatch(request(user));
		Services.users.register(user).then(
			(response) => {
				dispatch(success(response, user));
				if (cb) {
					cb();
				}
			},
			(error) => {
				dispatch(failure(error));
			},
		);
	};

	function request(payload: any) {
		return { type: AuthActionType.REGISTER_REQUEST, payload };
	}
	function success(response: any, user: any) {
		return {
			type: AuthActionType.REGISTER_SUCCESS,
			payload: { response, user },
		};
	}
	function failure(error: any) {
		return { type: AuthActionType.REGISTER_FAILURE, payload: error };
	}
}

function resendConfirmationEmail(email: string) {
	return (dispatch: Dispatch) => {
		dispatch(request());
		Services.users.resendConfirmationEmail(email).then(
			(response) => {
				dispatch(success(response));
			},
			(error) => {
				dispatch(failure(error));
			},
		);
	};

	function request() {
		return { type: AuthActionType.RESEND_CONFIRMATION_REQUEST };
	}
	function success(response: any) {
		return {
			type: AuthActionType.RESEND_CONFIRMATION_SUCCESS,
			payload: response,
		};
	}
	function failure(error: string) {
		return {
			type: AuthActionType.RESEND_CONFIRMATION_FAILURE,
			payload: error,
		};
	}
}

export const userActions = {
	editUser,
	login,
	logout,
	register,
	resendConfirmationEmail,
	firstLogInClose,
	updateUserAction,
	updateTalentStatuses
};
