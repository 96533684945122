import { connect } from "react-redux";
import { BandReview } from "./BandReviews";
import { IAppState } from "../../generic/interfaces";
import { ThunkDispatch } from "redux-thunk";
import { getBandsReviews, loadMoreReviewsList } from "./bandReviewSlice";

const mapStateToProps = (state: IAppState) => ({
	bandReviews: state.bandPage.bandReviews,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
	getReviewsList: (bandId: number) => dispatch(getBandsReviews(bandId)),
	loadMoreReviewsList: (bandId: number, bandReviewPage: number) =>
		dispatch(loadMoreReviewsList(bandId, bandReviewPage)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BandReview);
