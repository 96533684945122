import { headers, baseUrl } from "../../AuthBase/helpers/auth-header";
import { refineResponse } from "../helpers/refineResponse";
import { ISearchBandsSlice } from "../../../src/SearchPage/interfaces";
import { IError } from "../../Base/ErrorMessageBase";
export interface ISearchPageProps {
	searchBands: ISearchBandsSlice;
}

export const list = (options: any, isAuthenticated: boolean) => {
	var sortBy = options.sort.sort.by;
	var sortDir = options.sort.sort.dir;

	
	
	if(options.page != 1 && sortBy == 'isFeatured' && sortDir == "DESC")
	{
		sortBy = '';
		sortDir = '';
		//window.sessionStorage.removeItem("searched_band_ids");
	}

	let noFiltered = false;
	if(options.filter.favorite == false && 
		options.filter.genres == null && 
		options.filter.location == null && 
		options.filter.price_from == null && 
		options.filter.price_to == null && 
		options.filter.size == null && 
		options.filter.text == "" && sortBy == '' && sortDir == "")
		{
			noFiltered = true;
		}

		if(options.page == 1)
		{
			window.sessionStorage.removeItem("searched_band_ids");
		}

		

		let randomOptions = {};
		if(noFiltered)
		{
			if(options.page == 1)
			{
				randomOptions = {}
			}
			else
			{
				// let sessionBands = window.sessionStorage.getItem("searched_band_ids");
				// if(sessionBands)
				// {
				// 	let sessionBandsarr = sessionBands.split(',');
				// 	if(sessionBandsarr.length <= 9){
				// 		options.page = options.page == 2 ? 1 : options.page;		
				// 	}
				// }
				options.page = 1;
				
				randomOptions = {remove_order_number_record: 'yes', displayed_band_ids : window.sessionStorage.getItem("searched_band_ids") }
			}
		}


	const requestOptions = {
		body: JSON.stringify({
			text: options.filter.text,
			...options,
			sort: { by: sortBy, dir: sortDir },
			...randomOptions,
			items_per_page: 9,
		}),
		headers: { ...headers() },
		method: "POST",
	};

	const apiUrl = isAuthenticated ? `${baseUrl}search/find` : `${baseUrl}search/find-guest`;
	return fetch(apiUrl, requestOptions).then((response) => {
		//window.sessionStorage.setItem("TEST", "value");
		return response.text().then((text: any) => {
			const data = text && JSON.parse(text);
			let searched_band_ids = window.sessionStorage.getItem("searched_band_ids");
			let bandIds = searched_band_ids ? searched_band_ids : "";
			if(data && data.items)
			{
				data.items.forEach((bandData: any) => {
					if(bandIds == "")
						bandIds = bandData.id;
					else
						bandIds = `${bandIds},${bandData.id}`;
				});
				window.sessionStorage.setItem("searched_band_ids", bandIds);
				if(noFiltered){
					data.total_items = data.total_items >= 9 ? 10000 : data.total_items;
				}
			}
			
			if (!response.ok) {
				const error: IError = {
					code: response.status,
					message: data.user_message || data.message || "Unkown error occured.",
					errors: data.errors || data.item ? formatError(data.errors || data.item) : [],
				};
				return Promise.reject(error);
			}
			return data;
		});
	});
};

const formatError = (errors: any) => {
	const keys = Object.keys(errors);
	return keys.length
		? {
				keys: Object.keys(errors),
				...errors,
		  }
		: [];
};

export const favoriteToggle = (bandId: number, isFavorite: boolean) => {
	const requestOptions = {
		method: "POST",
		headers: { ...headers() },
		body: JSON.stringify({ band: { id: bandId } }),
	};
	const apiUrl = isFavorite ? `${baseUrl}bands/remove-favorite` : `${baseUrl}bands/favorite`;
	return fetch(apiUrl, requestOptions).then(refineResponse);
};

export const listFeatured = () => {
	const requestOptions = {
		method: "GET",
		headers: { ...headers() },
	};
	return fetch(`${baseUrl}list/featured`, requestOptions).then(refineResponse);
};

export const getBandById = (bandId: number, authenticated: boolean) => {
	const requestOptions = {
		method: "GET",
		headers: { ...headers() },
	};

	return authenticated
		? fetch(`${baseUrl}band-favorite/${bandId}`, requestOptions).then(refineResponse)
		: fetch(`${baseUrl}bands/${bandId}`, requestOptions).then(refineResponse);
};

export const getBandByUsername = (username: string, authenticated: boolean) => {
	const requestOptions = {
		method: "GET",
		headers: { ...headers() },
	};

	return authenticated
		? fetch(`${baseUrl}user-band-favorite/${username}`, requestOptions).then(refineResponse)
		: fetch(`${baseUrl}user-band/${username}`, requestOptions).then(refineResponse);
};

export const listMyBands = (page: number) => {
	const requestOptions = {
		method: "POST",
		headers: { ...headers() },
		body: JSON.stringify({ page, items_per_page: 1 }),
	};
	return fetch(`${baseUrl}my-bands/list`, requestOptions).then(refineResponse);
};

export const listAgentBands = () => {
	const requestOptions = {
		method: "POST",
		headers: { ...headers() }
	};
	return fetch(`${baseUrl}agent-bands/list`, requestOptions).then(refineResponse);
};

export const addTalentProfile = (bandProfile: any) => {
	const requestOptions = {
		method: "POST",
		headers: { ...headers() },
		body: JSON.stringify(bandProfile),
	};

	return fetch(`${baseUrl}bands`, requestOptions).then(refineResponse);
};

export const editTalentProfile = (talentProfile: any, id: number) => {
	const requestOptions = {
		method: "PUT",
		headers: { ...headers() },
		body: JSON.stringify(talentProfile),
	};

	return fetch(`${baseUrl}bands/${id}`, requestOptions).then(refineResponse);
};

export const getReviewsList = (bandId: number, page: number) => {
	const requestOptions = {
		method: "POST",
		headers: { ...headers() },
		body: JSON.stringify({ page, items_per_page: 4 }),
	};

	return fetch(`${baseUrl}band/reviews/${bandId}`, requestOptions).then(refineResponse);
};

export const getMyFavoritesBands = () => {
	const requestOptions = {
		method: "GET",
		headers: { ...headers() },
	};

	return fetch(`${baseUrl}list/favorite`, requestOptions).then(refineResponse);
};

export const uploadMedia = (file: any, filename?: string, image_segment_name?: string) => {
	const formData = new FormData();
	if (filename) {
		formData.append("media", file);
	} else formData.append("media", file);

	if (image_segment_name) {
		formData.append("image_segment_name", image_segment_name);
	}

	const options = {
		method: "POST",
		headers: { ...headers() },
		body: formData,
	};
	
	delete options.headers["Content-Type"];
	return fetch(`${baseUrl}media/upload`, options);
};

export const updateBandPhotoId = (photoId: any, bandId: number, type: string) => {
	const requestOptions = {
		method: "POST",
		headers: { ...headers() },
		body: JSON.stringify({
			photo_id: photoId,
			band_id: bandId,
			type: type
		}),
	};

	return fetch(`${baseUrl}band/update_photos`, requestOptions).then(refineResponse);
};

export const uploadMultipleMedia = (files: any, bandId: Number) => {
	const formData = new FormData();

		let selectedFiles = files;
		for (const key of Object.keys(selectedFiles)) {
            formData.append('media[]', selectedFiles[key])
        }

		//console.log('files: ', files)
		//formData.append("media[]", files);

	const options = {
		method: "POST",
		headers: { ...headers() },
		body: formData,
	};
	
	delete options.headers["Content-Type"];
	return fetch(`${baseUrl}media/upload-band-gallery/${bandId}`, options);
};

// Delete band gallary Photo
export const deleteBandPhotoGalleryImage = async (photoId: Number) => {
	const requestOptions = {
		method: "DELETE",
		headers: { ...headers() },
	};
	return fetch(`${baseUrl}band/delete/photo-gallery/${photoId}`, requestOptions).then(refineResponse);
};

// Delete band gallary Photo
export const getBandPhotoGalleryImages = async (bandId: Number) => {
	const requestOptions = {
		method: "GET",
		headers: { ...headers() },
	};
	return fetch(`${baseUrl}band/photo-gallery/${bandId}`, requestOptions).then(refineResponse);
};

export const uploadFile = (file: any, filename?: string) => {
	const formData = new FormData();
	if (filename) {
		formData.append("media", file, filename);
	} else formData.append("media", file);
	const options = {
		method: "POST",
		headers: { ...headers() },
		body: formData,
	};
	delete options.headers["Content-Type"];
	return fetch(`${baseUrl}media/upload-file`, options);
};

export const uploadProductFile = (file: any, filename?: string) => {
	const formData = new FormData();
	if (filename) {
		formData.append("media", file, filename);
	} else formData.append("media", file);
	const options = {
		method: "POST",
		headers: { ...headers() },
		body: formData,
	};
	delete options.headers["Content-Type"];
	return fetch(`${baseUrl}media/upload-product-file`, options);
};


export const agentListBands = (options: any, isAuthenticated: boolean) => {
	const requestOptions = {
		body: JSON.stringify({
			text: options.filter.text,
			...options,
			sort: { by: options.sort.sort.by, dir: options.sort.sort.dir },
			items_per_page: 9,
		}),
		headers: { ...headers() },
		method: "POST",
	};

	const apiUrl = `${baseUrl}agent/list-bands`;
	return fetch(apiUrl, requestOptions).then(refineResponse);
};
