import React from "react";
import { Theme, makeStyles, createStyles, withStyles } from "@material-ui/core/styles";
import { IBand, Genre } from "../interfaces";
import Rating from "../generic/Rating";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import BookmarkSharpIcon from "@material-ui/icons/BookmarkSharp";
import BookmarkBorderSharpIcon from "@material-ui/icons/BookmarkBorderSharp";
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import ImagePlaceholder from "../Assets/ImagePlaceholder.png";
import { Link } from "react-router-dom";
import {Link as MaterialLink} from "@material-ui/core";
import { IAuthReducer } from "../AuthBase/interfaces";
import Button from "@material-ui/core/Button";
import Tooltip from '@material-ui/core/Tooltip';
import { IsAnonymous } from "../AuthBase";

interface BandCardProps {
	band: IBand;
	authentication: IAuthReducer;
	favoriteToggle: (bandId: number, isFavorite: boolean) => void;
	isFavouritePage: boolean;
	BookArtist?: () => void;
	openSignupModal?: () => void;
}

const BlueTopTooltip = withStyles({
	tooltip: {
	  color: "#ffffff",
	  backgroundColor: "#0F24FD",
	  fontSize: '1.2em',
	  fontWeight: 'bold',
	  marginBottom: '0px',
	  marginTop: '20px',
	  padding: '6px 15px 7px 15px',
	}
  })(Tooltip);

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		Link: {
			display: "flex",
			alignItems: "center",
			color: "#0F24FD",
			backgroundColor: "#64FECC",
			padding: "5px 20px",
			fontSize: "1rem",
			fontFamily: "Rubik",
			fontWeight: "bold",
			borderRadius: "4px",
			textDecoration: "none",
			margin: 10,
			height: 35,
			"&:hover": {
				backgroundColor: "#64FECC"
			},
		},
		favoriteIcon: {
			position: "absolute",
			top: "-20px",
			right: "8px",
			cursor: "pointer",
		},
		cardContent: {
			minHeight: "106px",
			paddingBottom: '0px',
		},
		dolarText: {
			marginTop: '6px',
			float: 'left',
		},
		blurText: {
			filter: "blur(6px)",
			fontSize: '2.8rem'
		},
	}),
);

export const BandCard: React.FC<BandCardProps> = ({ band, authentication, favoriteToggle, isFavouritePage, BookArtist, openSignupModal }) => {
	const classes = useStyles();

	const showSignupModal = (e: any) => {
		e.stopPropagation();
		if(openSignupModal)
			openSignupModal();
	}

	const stopPropagation = (e: any) => {
		e.stopPropagation();
	}
	
	return (

		<Link to={`/${band?.created_by?.username}`} style={{textDecoration: 'none'}}>
		{/* <Link to={`/bands/${band.id}`}> */}
			<Card square={true}>
				<CardMedia image={band.photo === null ? ImagePlaceholder : band.photo.thumbnail} title={band.name}>
					{authentication.isLoggedIn &&
						authentication.user.role === "BOOKER" &&
						(band.isFavorite ? (
							<BookmarkSharpIcon
								color="secondary"
								style={{ fontSize: 90 }}
								className={classes.favoriteIcon}
								onClick={() => favoriteToggle(band.id, band.isFavorite)}
							/>
						) : (
							<BookmarkBorderSharpIcon
								color="secondary"
								style={{ fontSize: 90 }}
								className={classes.favoriteIcon}
								onClick={() => favoriteToggle(band.id, band.isFavorite)}
							/>
						))}
				</CardMedia>
				<Box border={2} borderTop={0} borderRadius="0px 0px 5px 5px">
					<CardContent classes={{ root: classes.cardContent }}>
						<Typography variant="h4">{band.name}</Typography>
						<Typography component="p" gutterBottom variant="subtitle1" style={{marginBottom: '0px',lineHeight: 'normal'}}>
							{band.genres.map((genre: Genre) => genre.title).join(", ")}
						</Typography>
					</CardContent>
					{authentication.isLoggedIn && authentication.user.status === "APPROVED" && authentication.user.role === "BOOKER" && (
						<Typography component="p" variant="h6" style={{ padding: "25px 25px 0px 25px", fontSize: "2.7em" }}>
							{band.price_show ? `$${band.price_from}-${band.price_to} ` : ''}
						</Typography>
					)}
					{authentication.isLoggedIn && authentication.user.status === "APPROVED" && authentication.user.role === "TALENT" && band.created_by.id === authentication.user.id &&  (
						<Typography component="p" variant="h6" style={{ padding: "25px 25px 0px 25px", fontSize: "2.7em" }}>
							${band.price_from}-${band.price_to}
						</Typography>
					)}
					<CardActions style={{flexWrap:'wrap'}}>
						<IsAnonymous>
							<Box onClick={stopPropagation} display="flex" flexWrap="wrap" alignItems="center" p={1} style={{paddingBottom:'0px',width: '100%'}}>
								<Typography variant="h4"><span className={classes.dolarText} style={{marginRight:'5px'}}>$</span><span style={{marginRight:'8px'}}><span className={classes.blurText}>X</span><span className={classes.blurText}>X</span><span className={classes.blurText}>X</span><span className={classes.blurText}>X</span></span></Typography>
								<Box flexGrow={1} style={{marginTop:'5px'}}><BlueTopTooltip interactive title={
								<React.Fragment>
									<MaterialLink style={{ color: '#ffffff', textDecoration: 'underline', cursor:'pointer' }} onClick={showSignupModal}>Sign up</MaterialLink> to view pricing.
								</React.Fragment>
								} placement="top"><InfoOutlined style={{color:'#64FECC',width:'20px'}} /></BlueTopTooltip></Box>
							</Box>
						</IsAnonymous>
						<Box display="flex" flexGrow={1} p={1} style={{marginLeft:'0px',paddingTop:'0px'}}>
							<Box flexGrow={1}>
								<Rating color="#64FECC" width="24px" rate={band.rating} />
							</Box>
							<Box alignSelf="center">
								{/* <Link to={`/bands/${band.id}`} className={classes.Link}>
									View Artist
								</Link> */}
								<Link to={`/${band?.created_by?.username}`} className={classes.Link}>
									View Artist
								</Link>
								{isFavouritePage && <Button className={classes.Link} onClick={BookArtist && BookArtist}>Book Artist</Button>}
							</Box>
						</Box>
					</CardActions>
				</Box>
			</Card>
		</Link>
	);
};
