import { createSlice } from "redux-starter-kit";

const pageSlice = createSlice({
	slice: "page",
	initialState: 1,
	reducers: {
		filterBandsPageReset(state, action) {
			return 1;
		},
		filterBandsPageIncrement(state, action) {
			return state + 1;
		},
	},
});

export const {
	filterBandsPageReset,
	filterBandsPageIncrement,
} = pageSlice.actions;

export default pageSlice.reducer;
