import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { IAuthReducer } from "../../AuthBase";
import { IAppState } from "../interfaces";

interface RouteComponentProps {
	component: any;
	auth: IAuthReducer;
	path: any;
	exact?: any;
	openLoginModal: () => void;
}

const RouteComponent = ({
	component: Component,
	auth,
	path,
	exact,
	openLoginModal,
	...rest
}: RouteComponentProps) => {
	return (
		<Route
			path={path}
			exact={exact}
			render={props => {
				return auth.isLoggedIn ? (
					<Component {...props} {...rest} />
				) : (
					<Redirect
						to={{
							pathname: "/",
							state: { from: props.location },
						}}
					/>
				);
			}}
		/>
	);
};

const PrivateRoute = (props: any) => (
	<RouteComponent {...props} auth={props.auth} />
);

const mapStateToProps = (state: IAppState) => ({
	auth: state.authentication,
});

export default connect(mapStateToProps)(PrivateRoute);
