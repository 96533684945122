import React from "react";
import { IResendActivationLinkProps } from "./interfaces";
import { CustomDialogTitle } from "../generic/components";
import {
	Divider,
	Dialog,
	DialogContent,
	Typography,
} from "@material-ui/core";
import { Formik } from "formik";
import * as Yup from "yup";
import Form from "./form";
import { dialogStyles } from "./styles";
import { withStyles } from "@material-ui/styles";

const validationSchema = Yup.object({
	email: Yup.string()
		.email("Enter a valid email")
		.required("Email is required"),
});

class ResendActivationLink extends React.Component<IResendActivationLinkProps> {
	render() {
		const {
			classes,
			resendActivationLink,
			handleClose,
			handleResendActivationLinkSubmit,
		} = this.props;
		const values = {
			email: "",
		};

		return (
			<Dialog
				fullWidth={true}
				maxWidth="sm"
				scroll="body"
				open={resendActivationLink?.resendActivationLinkModalOpened}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				classes={{ paper: classes.dialogRoot }}
			>
				{!resendActivationLink?.resendActivationLinkSuccess ? (
					<>
						<CustomDialogTitle
							onClose={handleClose}
							id="alert-dialog-title"
						>
							<Typography variant="h4">
								Resend Activation Link
							</Typography>
							<Typography>Been there. Done that.</Typography>
						</CustomDialogTitle>
						<Divider className={classes.dialogDivider} />
						<DialogContent>
							<Formik
								render={props => (
									<Form {...props} state={resendActivationLink} />
								)}
								initialValues={values}
								validationSchema={validationSchema}
								onSubmit={(values, actions) => {
									handleResendActivationLinkSubmit(values);
									actions.setSubmitting(true);
								}}
							/>
						</DialogContent>
					</>
				) : (
					<CustomDialogTitle
						onClose={handleClose}
						id="alert-dialog-title"
					>
						<Typography variant="h4">
							An Email has been sent!
						</Typography>
						<Typography>
							Please check your email to activate your account
						</Typography>
					</CustomDialogTitle>
				)}
			</Dialog>
		);
	}
}

export default withStyles(dialogStyles)(ResendActivationLink);
