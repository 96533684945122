import { AuthActionType, IRegistrationAction } from "../interfaces";
import { combineReducers } from "redux";
import { HeaderActionType, IHeaderActions } from "../../Header/interfaces";

const isRegistering = (state = false, action: IRegistrationAction) => {
	switch (action.type) {
		case AuthActionType.REGISTER_REQUEST:
			return true;
		case AuthActionType.REGISTER_SUCCESS:
		case AuthActionType.REGISTER_FAILURE:
			return false;
		default:
			return state;
	}
};
const user = (state = {}, action: IRegistrationAction) => {
	switch (action.type) {
		case AuthActionType.REGISTER_SUCCESS:
			return action.payload.user;
		case AuthActionType.REGISTER_REQUEST:
		case AuthActionType.REGISTER_FAILURE:
			return {};
		default:
			return state;
	}
};
const error = (state = {}, action: IRegistrationAction | IHeaderActions) => {
	switch (action.type) {
		case AuthActionType.REGISTER_FAILURE:
			return action.payload;
		case AuthActionType.REGISTER_REQUEST:
		case AuthActionType.REGISTER_SUCCESS:
		case HeaderActionType.SIGNUP_MODAL_OPEN:
			return {};
		default:
			return state;
	}
};

export const registration = combineReducers({
	isRegistering,
	user,
	error,
});
