import { HeaderActionType, IHeaderActions } from "./interfaces";
import { combineReducers } from "redux";

const signupModalOpened = (state: boolean = false, action: IHeaderActions) => {
	switch (action.type) {
		case HeaderActionType.SIGNUP_MODAL_OPEN:
			return true;
		case HeaderActionType.SIGNUP_MODAL_CLOSE:
			return false;
		default:
			return state;
	}
};

const forgotPasswordModalOpened = (state: boolean = false, action: IHeaderActions) => {
	switch (action.type) {
		case HeaderActionType.FORGOT_PASSWORD_MODAL_OPEN:
			return true;
		case HeaderActionType.FORGOT_PASSWORD_MODAL_CLOSE:
			return false;
		default:
			return state;
	}
};

const resendActivationLinkModalOpened = (state: boolean = false, action: IHeaderActions) => {
	switch (action.type) {
		case HeaderActionType.RESEND_ACTIVATION_LINK_MODAL_OPEN:
			return true;
		case HeaderActionType.RESEND_ACTIVATION_LINK_MODAL_CLOSE:
			return false;
		default:
			return state;
	}
};

const confirmationModalOpened = (
	state: boolean = false,
	action: IHeaderActions,
) => {
	switch (action.type) {
		case HeaderActionType.CONFIRMATION_MODAL_OPEN:
			return true;
		case HeaderActionType.CONFIRMATION_MODAL_CLOSE:
			return false;
		default:
			return state;
	}
};

const loginModalOpened = (state: boolean = false, action: IHeaderActions) => {
	switch (action.type) {
		case HeaderActionType.LOGIN_MODAL_OPEN:
			return true;
		case HeaderActionType.LOGIN_MODAL_CLOSE:
			return false;
		default:
			return state;
	}
};

const messagesNotification = (state: { messageCount: number, ids: number[]} = { messageCount: 0, ids: []}, action: IHeaderActions) => {
	const storage = localStorage.getItem("messagesNotification");
	state = storage ? JSON.parse(storage) : { messageCount: 0, ids: []};
	switch (action.type) {
		case HeaderActionType.MESSAGE_NOTIFICATION_NEW:
			const newIDs: number[] = [...state.ids, action.payload];
			localStorage.setItem("messagesNotification",
			JSON.stringify({messageCount: state.messageCount + 1, ids: [...newIDs]}));
			return {messageCount: state.messageCount + 1, ids: [...newIDs]};
		case HeaderActionType.MESSAGE_NOTIFICATION_SEEN:
			localStorage.setItem("messagesNotification",
			JSON.stringify({messageCount: 0, ids: state.ids}));
			return {messageCount: 0, ids: state.ids};
		case HeaderActionType.MESSAGE_SEEN:
			const notSeen = state.ids.filter((item) => Number(item) !== Number(action.payload));
			localStorage.setItem("messagesNotification",
			JSON.stringify({messageCount: state.messageCount, ids: notSeen}));
			return {messageCount: state.messageCount, ids: notSeen};
		case HeaderActionType.MESSAGE_NEW:
			const newID: number[] = [...state.ids, action.payload];
			localStorage.setItem("messagesNotification",
			JSON.stringify({messageCount: state.messageCount, ids: [...newID]}));
			return {messageCount: state.messageCount, ids: [...newID]};
		default:
			return state;
	}
};

const notification = (state: { notifications: { message: string, timestamp: number, data: any }[], new: boolean, snackBar: { open: boolean, message: string } } = { notifications: [], new: false, snackBar: { open: false, message: "" } }, action: IHeaderActions) => {
	const storageItem = localStorage.getItem("Notification")
	state = storageItem ? JSON.parse(storageItem) : { notifications: [], new: false, snackBar: { open: false, message: "" } }
	switch (action.type) {
		case HeaderActionType.NOTIFICATION_RECIEVED:
			const oldNotifications = [ ...state.notifications ]
			localStorage.setItem("Notification", JSON.stringify({notifications: [ action.payload, ...oldNotifications ], new: true, snackBar: { open: true, message: action.payload.message } }))
			return {notifications: [ action.payload, ...oldNotifications ], new: true, snackBar: { open: true, message: action.payload.message }}
		case HeaderActionType.NOTIFICATION_SEEN:
			const Notifications = [ ...state.notifications ]
			Notifications.splice(action.payload, 1)
			localStorage.setItem("Notification", JSON.stringify({notifications: [ ...Notifications ], new: state.new, snackBar: state.snackBar }))
			return {notifications: [ ...Notifications ], new: state.new, snackBar: state.snackBar}
		case HeaderActionType.NOTIFICATIONS_OPENED:
			localStorage.setItem("Notification", JSON.stringify({notifications: state.notifications, new: false, snackBar: state.snackBar }))
			return {notifications: state.notifications, new: false, snackBar: state.snackBar }
		case HeaderActionType.SNACKBAR_CLOSE:
			localStorage.setItem("Notification", JSON.stringify({notifications: state.notifications, new: state.new, snackBar: { open: false, message: "" } }))
			return {notifications: state.notifications, new: state.new, snackBar: { open: false, message: "" } }
		default:
			return state
	}
}

export const header = combineReducers({
	signupModalOpened,
	loginModalOpened,
	confirmationModalOpened,
	messagesNotification,
	forgotPasswordModalOpened,
	resendActivationLinkModalOpened,
	notification,
});
