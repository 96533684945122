import { ForgetPasswordActionType, IForgetPasswordActions } from "./interfaces";
import { combineReducers } from "redux";

const forgetPasswordModalOpened = (
	state: boolean = false,
	action: IForgetPasswordActions,
) => {
	switch (action.type) {
		case ForgetPasswordActionType.FORGET_PASSWORD_MODAL_OPEN:
			return true;
		case ForgetPasswordActionType.FORGET_PASSWORD_MODAL_CLOSE:
			return false;
		default:
			return state;
	}
};

const forgetPasswordRequest = (
	state: boolean = false,
	action: IForgetPasswordActions,
) => {
	switch (action.type) {
		case ForgetPasswordActionType.FORGET_PASSWORD_REQUEST:
			return true;
		case ForgetPasswordActionType.FORGET_PASSWORD_MODAL_OPEN:
		case ForgetPasswordActionType.FORGET_PASSWORD_SUCCESS:
		case ForgetPasswordActionType.FORGET_PASSWORD_FAILURE:
			return false;
		default:
			return state;
	}
};

const forgetPasswordSuccess = (
	state: boolean = false,
	action: IForgetPasswordActions,
) => {
	switch (action.type) {
		case ForgetPasswordActionType.FORGET_PASSWORD_SUCCESS:
			return true;
		case ForgetPasswordActionType.FORGET_PASSWORD_MODAL_OPEN:
		case ForgetPasswordActionType.FORGET_PASSWORD_REQUEST:
		case ForgetPasswordActionType.FORGET_PASSWORD_FAILURE:
			return false;
		default:
			return state;
	}
};

const error = (state = null, action: IForgetPasswordActions) => {
	switch (action.type) {
		case ForgetPasswordActionType.FORGET_PASSWORD_FAILURE:
			return action.payload;
		case ForgetPasswordActionType.FORGET_PASSWORD_MODAL_OPEN:
		case ForgetPasswordActionType.FORGET_PASSWORD_REQUEST:
		case ForgetPasswordActionType.FORGET_PASSWORD_SUCCESS:
			return null;
		default:
			return state;
	}
};

export const forgetPassword = combineReducers({
	forgetPasswordModalOpened,
	forgetPasswordRequest,
	forgetPasswordSuccess,
	error,
});
