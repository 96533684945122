import { createSlice } from "redux-starter-kit";
import Services from "../../generic/Services";

export interface Event {
	id: number;
	title: string;
	date: string;
	location: string;
	number_of_attendees: number | null;
	budget: number | null;
	event_type: {
		id: number;
		title: string;
	} | null;
	start_time: string;
	desired_set_length: number | null;
	description: string;
	venue_description: { id: number; title: string }[] | null;
	state: {
		id: number;
		title: string;
	};
}

export interface BandRequestState {
	bandRequestModalOpen: boolean;
	bandRequestRequested: boolean;
	bandRequestSucceeded: boolean;
	bandRequestFailure: boolean;
	warningModalOpen: boolean;
	selectedEvent: Event | null;
	error: {
		code: number;
		message: string;
		errors: [];
	};
}

let initialState: BandRequestState = {
	bandRequestRequested: false,
	bandRequestSucceeded: false,
	bandRequestFailure: false,
	bandRequestModalOpen: false,
	warningModalOpen: false,
	selectedEvent: null,
	error: {
		code: 0,
		message: "",
		errors: [],
	},
};

const bandRequestSlice = createSlice({
	slice: "bandRequest",
	initialState,
	reducers: {
		bandRequestRequested(state, action) {
			state.bandRequestRequested = true;
			state.bandRequestSucceeded = false;
			state.bandRequestFailure = false;
		},
		bandRequestSucceeded(state, action) {
			state.bandRequestRequested = false;
			state.bandRequestSucceeded = true;
			state.bandRequestFailure = false;
			state.error = {
				code: 0,
				message: "",
				errors: [],
			};
			state.selectedEvent = null;
		},
		bandRequestFailure(state, action) {
			state.bandRequestRequested = false;
			state.bandRequestSucceeded = false;
			state.bandRequestFailure = true;
			state.error = action.payload;
		},
		bandRequestModalOpen(state, action) {
			state.bandRequestModalOpen = true;
		},
		bandRequestModalClose(state, action) {
			state.bandRequestModalOpen = false;
		},
		setSelectedEvent(state, action) {
			state.selectedEvent = action.payload;
		},
		warningModalOpen(state, action) {
			state.warningModalOpen = true;
		},
		warningModalClose(state, action) {
			state.warningModalOpen = false;
		},
	},
});

export const {
	bandRequestRequested,
	bandRequestSucceeded,
	bandRequestFailure,
	bandRequestModalOpen,
	bandRequestModalClose,
	setSelectedEvent,
	warningModalOpen,
	warningModalClose,
} = bandRequestSlice.actions;

export default bandRequestSlice.reducer;

export const addEventRequest = (eventRequest: any): any => async (
	dispatch: any,
) => {
	try {
		dispatch(bandRequestRequested());
		const res = await Services.eventRequest.addEventRequest(eventRequest);
		dispatch(bandRequestSucceeded(res));
		dispatch(bandRequestModalClose());
	} catch (err) {
		dispatch(bandRequestFailure(err));
	}
};

export const editEventRequest = (eventRequest: any): any => async (
	dispatch: any,
) => {
	try {
		dispatch(bandRequestRequested());
		const res = await Services.eventRequest.editEventRequest(eventRequest);
		dispatch(bandRequestSucceeded(res));
		dispatch(bandRequestModalClose());
	} catch (err) {
		dispatch(bandRequestFailure(err));
	}
};
