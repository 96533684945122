import { createSlice, PayloadAction } from "redux-starter-kit";
import Services from "../../generic/Services";

export interface TalentsAgentState {
	error: {};

	talentsAgentRequestRequested: boolean;
	talentsAgentRequestSucceeded: boolean;
	talentsAgentRequestFailure: boolean;
	talentsAgentRequest: any;
}

let initialState: TalentsAgentState = {
	error: {},

	talentsAgentRequestRequested: false,
	talentsAgentRequestSucceeded: false,
	talentsAgentRequestFailure: false,
	talentsAgentRequest: null,
};

const talentsAgentRequestSlice = createSlice({
	slice: "talentsAgentRequest",
	initialState,
	reducers: {
		talentsAgentRequestRequested(state, action) {
			state.talentsAgentRequestRequested = true;
			state.talentsAgentRequestSucceeded = false;
			state.talentsAgentRequestFailure = false;
		},
		talentsAgentRequestSucceeded(state, action: PayloadAction<boolean>) {
			state.talentsAgentRequestSucceeded = true;
			state.talentsAgentRequestRequested = false;
			state.talentsAgentRequestFailure = false;
			state.talentsAgentRequest = action.payload;
		},
		talentsAgentRequestFailure(state, action: PayloadAction<boolean>) {
			state.talentsAgentRequestSucceeded = false;
			state.talentsAgentRequestRequested = false;
			state.talentsAgentRequestFailure = true;
			state.error = action.payload;
		},
	},
});


export const talentsAgentRequestList = (): any => async (dispatch: any) => {
	try {
		dispatch(talentsAgentRequestRequested());
		const res = await Services.agent.talentsAgentRequestList();
		dispatch(talentsAgentRequestSucceeded(res));
	} catch (err) {
		dispatch(talentsAgentRequestFailure(err));
	}
};


export const {
	talentsAgentRequestRequested,
	talentsAgentRequestSucceeded,
	talentsAgentRequestFailure
} = talentsAgentRequestSlice.actions;
