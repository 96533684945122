import { Dispatch } from "redux";
import { ListEventRequestActionType } from "../interfaces";
import Services from "../../../generic/Services";

const listEventRequests = () => {
	return (dispatch: Dispatch) => {
		dispatch(request());
		Services.eventRequest.list(1).then(
			(response: any) => {
				dispatch(success(response));
			},
			error => {
				dispatch(fail(error));
			},
		);
	};

	function request() {
		return { type: ListEventRequestActionType.BOOKER_REQUEST_LIST_REQUEST };
	}

	function success(payload: any) {
		return {
			type: ListEventRequestActionType.BOOKER_REQUEST_LIST_SUCCESS,
			payload,
		};
	}

	function fail(payload: any) {
		return {
			type: ListEventRequestActionType.BOOKER_REQUEST_LIST_FAILURE,
			payload,
		};
	}
};

const loadMoreEventRequests = () => {
	return (dispatch: Dispatch, getState: () => any) => {
		const page = getState().listBookerRequest.bookerRequestPage;
		dispatch(request());
		Services.eventRequest.list(page).then(
			(response: any) => {
				dispatch(success(response));
			},
			error => {
				dispatch(fail(error));
			},
		);
	};

	function request() {
		return {
			type:
				ListEventRequestActionType.LOADMORE_BOOKER_REQUEST_LIST_REQUEST,
		};
	}

	function success(payload: any) {
		return {
			type:
				ListEventRequestActionType.LOADMORE_BOOKER_REQUEST_LIST_SUCCESS,
			payload,
		};
	}

	function fail(payload: any) {
		return {
			type:
				ListEventRequestActionType.LOADMORE_BOOKER_REQUEST_LIST_FAILURE,
			payload,
		};
	}
};

export const ListEventRequestActions = {
	listEventRequests,
	loadMoreEventRequests,
};
