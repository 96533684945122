import { Dispatch } from "redux";
import { ResetPasswordActionType } from "./interfaces";
import Services from "../generic/Services";
import { IError } from "../Base/ErrorMessageBase";
import { enqueueSnackbar } from "../Notifier/actions";

export const openResetPasswordModal = () => {
	return {
		type: ResetPasswordActionType.RESET_PASSWORD_DIALOG_OPEN,
	};
};

export const closeResetPasswordModal = () => {
	return {
		type: ResetPasswordActionType.RESET_PASSWORD_DIALOG_CLOSE,
	};
};

export const resetSuccess = () => {
	return {
		type: ResetPasswordActionType.RESET_SUCCESS,
	};
};

export const confirmationOpen = () => {
	return {
		type: ResetPasswordActionType.CONFIRMATION_OPEN,
	}
}

export const confirmationClose = () => {
	return {
		type: ResetPasswordActionType.CONFIRMATION_CLOSE,
	}
}

export const resetPassword = (data: { password: string }, token: string) => {
	return (dispatch: Dispatch) => {
		dispatch(request());
		Services.users.resetPassword(data, token).then(
			response => {
				dispatch(success(response));
				dispatch(closeResetPasswordModal());
				dispatch(confirmationOpen());
			},
			(error: IError) => {
				dispatch(fail(error));
				dispatch(
					enqueueSnackbar({
						message: error.message,
						options: {
							variant: "error",
						},
					}),
				);
			},
		);
	};
	function request() {
		return { type: ResetPasswordActionType.RESET_PASSWORD_REQUEST };
	}

	function success(payload: any) {
		return {
			type: ResetPasswordActionType.RESET_PASSWORD_SUCCESS,
			payload,
		};
	}

	function fail(payload: any) {
		return {
			type: ResetPasswordActionType.RESET_PASSWORD_FAILURE,
			payload,
		};
	}
};
