import React from "react";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import SizeFilter from "./SizeFilter";
import { LocationFilter, IOptionType } from "./LocationFilter";
import { IState } from "../../interfaces";
import { IGeneratedFetchReducer } from "../../generic/Resources/interfaces";

export interface IMoreFiltersProps {
	sizeValue: number;
	locationValue: { id: number } | null;
	filters: any;
	states: IGeneratedFetchReducer<IState>;
	isFavorite: boolean;
	favoritePage: boolean;
	saveSize: (size: number) => void;
	saveLocation: (id: { id: number } | null) => void;
	saveFavorite: (isFavorite: boolean) => void;
	clearLocation: () => void;
	clearSize: () => void;
	search: () => void;
	handlePopoverClose: (e: any) => void;
}

class MoreFilters extends React.Component<IMoreFiltersProps> {
	public state = {
		size: 0,
		id: null,
		isFavorite: false,
	};

	public suggestions: IOptionType[] = this.props.states.allIds.map((id: number) => ({
		id,
		label: this.props.states.items[id].title,
	}));

	public componentDidMount() {
		const { sizeValue, locationValue, isFavorite } = this.props;
		this.setState({
			size: sizeValue,
			id: locationValue,
			isFavorite,
		});
	}

	public handleSubmit = (e: any) => {
		this.props.saveSize(this.state.size);
		this.props.saveLocation(this.state.id);
		this.props.saveFavorite(this.state.isFavorite);

		this.props.search();
		this.props.handlePopoverClose(e);
	};

	public handleClear = () => {
		this.setState({
			size: null,
			id: null,
			isFavorite: false,
		});
	};

	public handleChangeLocation = (id: { id: number }) => {
		this.setState({
			id,
		});
	};

	public handleClearLocation = () => {
		this.setState({
			id: null,
		});
	};

	public handleChange = (event: any, value: any) => {
		this.setState({ size: value });
	};

	public handleChangeCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({ isFavorite: event.target.checked });
	};

	public getInitialValue = () => {
		if (this.props.locationValue) {
			return {
				id: this.props.locationValue,
				label: this.props.states.items[this.props.locationValue.id].title,
			};
		} else {
			return {};
		}
	};

	public render() {
		const { size, isFavorite } = this.state;
		return (
			<Grid container direction="row" justify="flex-start">
				{/* size and favorite filter */}
				<Grid item>
					<SizeFilter
						favoritePage={this.props.favoritePage}
						size={size}
						handleChange={this.handleChange}
						checked={isFavorite}
						onChange={this.handleChangeCheckBox}
					/>
				</Grid>
				<Hidden only={["xs"]}>
					<Divider
						style={{
							width: "1.5px",
							height: "18em",
							margin: "auto 10px",
						}}
						orientation="vertical"
						color="secondary"
					/>
				</Hidden>
				{/* location filter */}
				<Grid item>
					<LocationFilter
						handleChangeLocation={this.handleChangeLocation}
						handleSubmit={this.handleSubmit}
						handleClear={this.handleClear}
						suggestions={this.suggestions}
						getInitialValue={this.getInitialValue}
						handleClearLocation={this.handleClearLocation}
					/>
				</Grid>
			</Grid>
		);
	}
}
export default MoreFilters;
