import React from "react";
import {
	Button,
	TextField,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	Checkbox,
	FormControlLabel,
	FormGroup,
	FormHelperText,
	Grid,
	Container,
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { styles } from "./styles";

const Form = (props: {
	values: any;
	errors: any;
	touched: any;
	handleChange: any;
	isValid: any;
	classes: any;
	setFieldValue: any;
	setFieldTouched: any;
	handleSubmit: any;
	setSelectedEventItem: any;
}) => {
	const {
		classes,
		values,
		errors,
		touched,
		handleChange,
		isValid,
		setFieldValue,
		setFieldTouched,
		handleSubmit,
		setSelectedEventItem,
	} = props;

	const change = (name: any, e: { persist: () => void }) => {
		e.persist();
		handleChange(e);
		setFieldTouched(name, true, false);
	};

	const eventItems = [
		{
			id: 1,
			eventName: "Event 1",
			eventDate: "2018-10-08",
			location: "25 cairo, Egypt",
		},
		{
			id: 2,
			eventName: "Event 2",
			eventDate: "2018-11-12",
			location: "26 cairo, Egypt",
		},
		{
			id: 3,
			eventName: "Event 3",
			eventDate: "2018-11-22",
			location: "27 cairo, Egypt",
		},
	];

	const isGenreSelected = (id: number) => {
		return (
			values.genres &&
			values.genres.filter((genre: any) => genre.id === id).length > 0
		);
	};

	const handleGenreChange = (e: any) => {
		const { value, checked } = e.target;
		const index = Number(value);
		const genreItem = values.genresList[index];
		let updatedPreferredGenres = [];
		updatedPreferredGenres = checked
			? [...values.genres, genreItem]
			: values.genres.filter((item: any) => item.id !== genreItem.id);
		setFieldValue("genres", updatedPreferredGenres);
		setFieldTouched("genres", true, false);
	};

	const selectEvent = (event: any) => {
		const selectedEvent = eventItems.find(
			item => item.id === event.target.value,
		);
		setSelectedEventItem(selectedEvent);
		change.bind(null, "event");
	};

	values.startTime =
		values.startTime ||
		(values.start_time && values.start_time.split(" ")[1]);
	values.desiredSetLength =
		values.desiredSetLength ||
		(values.start_time &&
			+values.end_time.split(" ")[1].split(":")[0] -
				values.start_time.split(" ")[1].split(":")[0]);

	return (
		// <Grid container direction="column" spacing={2}>
		<Container>
			<form onSubmit={handleSubmit} className={classes.form}>
				<Grid item xs={12}>
					<FormControl style={{ width: "70%", margin: "10px auto" }}>
						<InputLabel htmlFor="event">Event</InputLabel>
						<Select
							name="event"
							native={false}
							value={values.eventName}
							onChange={selectEvent}
						>
							{eventItems.map(
								(element: {
									id: number;
									eventName: string;
								}) => (
									<MenuItem
										value={element.id}
										key={element.id}
										style={{
											display: "block",
											width: "100%",
											textAlign: "center",
											margin: "10px",
											color: "#DDD",
										}}
									>
										<em>{element.eventName}</em>
									</MenuItem>
								),
							)}
						</Select>
					</FormControl>
				</Grid>
				{/* <div className={classes.inputDiv}> */}
				<Grid item xs={12}>
					<TextField
						className={classes.inputField}
						id="title"
						name="title"
						helperText={touched.title ? errors.title : ""}
						error={touched.title && Boolean(errors.title)}
						label="Event Name"
						value={values.title}
						disabled={true}
						onChange={change.bind(null, "title")}
					/>
				</Grid>
				{/* </div> */}

				<Grid item xs={12}>
					<TextField
						className={classes.inputField}
						id="date"
						name="date"
						helperText={touched.date ? errors.date : ""}
						error={touched.date && Boolean(errors.date)}
						label="Event Date"
						type="text"
						value={values.date}
						disabled={true}
						onChange={change.bind(null, "date")}
					/>
				</Grid>

				<Grid item xs={12}>
					<TextField
						className={classes.inputField}
						id="location"
						name="location"
						helperText={touched.location ? errors.location : ""}
						error={touched.location && Boolean(errors.location)}
						label="Location"
						type="text"
						value={values.location}
						disabled={true}
						onChange={change.bind(null, "location")}
					/>
				</Grid>

				<Grid item xs={12}>
					<TextField
						className={classes.inputField}
						id="number_of_attendees"
						name="number_of_attendees"
						helperText={
							touched.number_of_attendees
								? errors.number_of_attendees
								: ""
						}
						error={
							touched.number_of_attendees &&
							Boolean(errors.number_of_attendees)
						}
						label="Number of Attendees"
						type="number"
						value={values.number_of_attendees}
						onChange={change.bind(null, "number_of_attendees")}
					/>
				</Grid>

				<Grid item xs={12}>
					<TextField
						className={classes.inputField}
						id="budget"
						name="budget"
						helperText={touched.budget ? errors.budget : ""}
						error={touched.budget && Boolean(errors.budget)}
						label="Budget"
						type="number"
						value={values.budget}
						onChange={change.bind(null, "budget")}
					/>
				</Grid>

				<Grid item xs={12}>
					<FormControl
						className={classes.inputField}
						component="fieldset"
					>
						<FormGroup>
							{values.genresList.map(
								(item: any, index: number) => (
									<FormControlLabel
										key={item.id}
										control={
											<Checkbox
												checked={isGenreSelected(
													item.id,
												)}
												onChange={handleGenreChange}
												value={index}
											/>
										}
										label={item.title}
									/>
								),
							)}
						</FormGroup>
						<FormHelperText>
							Select All Genres That Apply
						</FormHelperText>
					</FormControl>
				</Grid>

				<Grid item xs={12}>
					<TextField
						className={classes.inputField}
						id="time"
						label="Start Time"
						name="startTime"
						type="time"
						InputLabelProps={{
							shrink: true,
						}}
						inputProps={{
							step: 300,
						}}
						value={values.startTime}
						onChange={change.bind(null, "startTime")}
					/>
				</Grid>

				<Grid item xs={12}>
					<TextField
						className={classes.inputField}
						id="desiredSetLength"
						name="desiredSetLength"
						helperText={
							touched.desiredSetLength
								? errors.desiredSetLength
								: ""
						}
						error={
							touched.desiredSetLength &&
							Boolean(errors.desiredSetLength)
						}
						label="Desired Set Length"
						type="number"
						value={values.desiredSetLength}
						onChange={change.bind(null, "desiredSetLength")}
					/>
				</Grid>

				<Grid item xs={12}>
					<TextField
						className={classes.inputField}
						id="venue_description"
						name="venue_description"
						helperText={
							touched.venue_description
								? errors.venue_description
								: ""
						}
						error={
							touched.venue_description &&
							Boolean(errors.venue_description)
						}
						label="Venue Description"
						type="text"
						value={values.venue_description}
						onChange={change.bind(null, "venue_description")}
					/>
				</Grid>

				<Grid item xs={12}>
					<TextField
						className={classes.inputField}
						id="notes"
						name="notes"
						helperText={touched.notes ? errors.notes : ""}
						error={touched.notes && Boolean(errors.notes)}
						label="Special Notes/Requests"
						type="text"
						multiline={true}
						rows={3}
						value={values.notes}
						onChange={change.bind(null, "notes")}
					/>
				</Grid>

				<Grid item xs={12}>
					<Button
						type="submit"
						variant="contained"
						style={{
							width: "80%",
							margin: "10px",
							textAlign: "center",
						}}
						color="primary"
						disabled={!isValid}
					>
						Submit
					</Button>
				</Grid>
			</form>
		</Container>
	);
};

export default withStyles(styles)(Form);
