import React from "react";
import PropTypes from "prop-types";
import "./SpotifyEmbed.css";

const SpotifyEmbed = ({ embedId }) => (
  <div className="spotify-video-responsive">
    <iframe
      width="100%"
      height="80"
      src={`https://open.spotify.com/embed/track/${embedId}`}
      frameBorder="0"
      allow="autoplay; clipboard-write; encrypted-media; fullscren; picture-in-picture"
      allowFullScreen=""
      title="Embedded Spotify"
    />
  </div>
);

SpotifyEmbed.propTypes = {
  embedId: PropTypes.string.isRequired
};

export default SpotifyEmbed;