import React from "react";
import { IErrorMessageProps } from "./interface";
import { SnackbarContent, Button, withStyles } from "@material-ui/core";
import { styles } from "./styles";

class ErrorMessageBase extends React.Component<IErrorMessageProps> {
	static defaultProps = {
		handleAllErrors: false,
		showDetailedErrors: false,
	};

	actions: JSX.Element[] = [];

	handleOnRetry = () => {
		return (
			<Button
				key="retry"
				color="secondary"
				size="small"
				onClick={this.props.onRetry}
			>
				Retry
			</Button>
		);
	};

	componentDidMount() {
		if (this.props.onRetry) {
			this.actions.push(this.handleOnRetry());
		}
	}

	render() {
		const { className, classes, error } = this.props;

		return (
			<SnackbarContent
				className={`${classes.error} ${className}`}
				aria-describedby="error-message"
				message={<span id="error-message">{error?.message}</span>}
				action={this.actions}
			/>
		);
	}
}

export default withStyles(styles)(ErrorMessageBase);
export * from "./interface";
