import React, { useEffect } from "react";
import { Box, Typography, Grid, Button, Container } from "@material-ui/core";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { PlayCircleFilledWhiteRounded, Instagram } from "@material-ui/icons";
import Services from "../generic/Services";
import { useParams, Link } from "react-router-dom";
import PageBG from "../Assets/Contact.jpg"
import sanitize from "sanitize-html";
import ReactHtmlParser, { processNodes, convertNodeToElement } from 'react-html-parser';
import moment from "moment";
import { MetadataTags } from '../generic/components/MetadataTags/metadata-tags'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        unread: {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText,
        },
        paragraphFont: {
            fontSize: "15px",
            color: "#ddd",
        },
        page: {
			backgroundImage: `url(${PageBG})`,
			height: "550px",
			backgroundPosition: "top",
			backgroundSize: "cover",
			backgroundRepeat: "no-repeat",
        },
        blogItemImg: {
            borderRadius: '15px',
            marginBottom: '25px',
            width: '100%',
            minHeight: '260px',
            backgroundSize: 'cover',
        },
        blogItemTitle: {
            fontFamily: 'Poppins',
            fontWeight: 'bold',
            fontSize: '22px',
            marginBottom: '20px',
        },
        blogItemText: {
            marginBottom: '10px',
        },
        blogItemLink: {
            fontFamily: 'Poppins',
            fontWeight: 'bold',
            fontSize: '20px',
            textDecoration: 'underline',
            color: 'rgb(255, 255, 255)',
            "&:hover": {
                color: 'rgb(100, 254, 204)',
            }
        },
        BlogListItemActive: {
            "&:blogItemImg": {
                border: `4px solid ${theme.palette.secondary.main}`,
            },
            "&:blogItemLink": {
                color: 'rgb(100, 254, 204)',
                "&:hover": {
                    color: 'rgb(255, 255, 255)',
                }
            }
        },
        blogTextEditor: {
            color: '#fff',
            "&:img": {
                maxWidth: '100%'
            }
        }
    })
);

const Blog: React.FC = () => {
    const classes = useStyles();
    const [data, setData] = React.useState<any>();
    const { id }  = useParams();

    useEffect(() => {
        window.scrollTo(0, 0)
        Services.blogs.getBlogBySlug(id).then((response: any) => {
            console.log('response: ', response);
            setData(response.items);
        });
    }, []);

    return (
        <>
            
                <Container maxWidth="lg">
                    <Box>
                        {data &&
                            <MetadataTags 
                                title={"Repsy - " + data?.metadata_title}
                                description={data?.metadata_description}        
                                image={data?.image}
                            />
                        }
                    
                    <Grid container direction="row" justify="center" alignItems="center">
                        {data && 
                            <Grid item xs={11} md={10} lg={9} justify="center">
                                <Grid container direction="row" justify="center" alignItems="center">
                                    <Grid item xs={11} md={10} lg={8} justify="center">
                                        <Typography variant="h2" className={classes.blogItemTitle} style={{ fontFamily: 'Rubik', fontWeight: 'bold', fontSize: '60px', marginTop: '60px', textAlign: 'center' }}>{data.title}</Typography>
                                        <Typography variant="h6" style={{ fontFamily: 'Poppins', fontWeight: 600, fontSize: '18px', marginTop: '0px', marginBottom: '60px', textAlign: 'center' }}>{data.author} &nbsp;|&nbsp; {moment(data.publish_date).format("LL")}</Typography>
                                    </Grid>
                                </Grid>
                                    {/* {ReactHtmlParser(data.description)} */}
                                    <Typography variant="body1" style={{ fontSize: '14px', fontWeight: 'normal', }}><div dangerouslySetInnerHTML={{__html: data.description}}></div></Typography>
                                    {/* <div dangerouslySetInnerHTML={{__html: ReactHtmlParser(data.description)}}></div> */}
                                    {/* <div dangerouslySetInnerHTML={{__html: ReactHtmlParser(data.description)}}></div> */}
                                    {/* <div dangerouslySetInnerHTML={{__html: sanitize(data.description)}}></div>  */}
                                        {/* <div className={classes.blogTextEditor}>
                                            <div dangerouslySetInnerHTML={{__html: data.description}}></div> 
                                        </div> */}
                                
                            </Grid>
                        }
                    </Grid>
                        <Grid container direction="row" justify="center" alignItems="center" style={{ marginTop: '45px' }}>
                            <Grid item xs={11} md={10} lg={9} justify="center"><hr style={{ border: '0px', borderTop: '1px solid rgb(100, 254, 204)' }} /></Grid>
                        </Grid>
                        <Grid container direction="row" justify="center" alignItems="center" style={{ marginTop: '45px' }}>
                            <Grid item xs={11} md={10} lg={9} justify="space-between" style={{ display: 'flex', flexWrap: 'wrap' }}>
                                {data?.previous_blog && (
                                    //  <Link href={`/blog/${data.next_blog.id}`}>
                                    //     <a>Next Link</a>
                                    // </Link>
                                    <Link to={`/blog/${data.previous_blog.slug}`} className={classes.blogItemLink} style={{ fontFamily: 'Poppins', fontWeight: 'normal', fontSize: '14px', display:'flex', alignItems:'center', textDecoration:'none' }}><PlayCircleFilledWhiteRounded fontSize="small" color="secondary" style={{ transform: 'rotate(180deg)', fontSize:'10px !important,', marginRight:'5px' }}/> <span>Previous Blog Article</span></Link>
                                )}
                                {data?.next_blog && (
                                    <Link to={`/blog/${data.next_blog.slug}`} className={classes.blogItemLink} style={{ fontFamily: 'Poppins', fontWeight: 'normal', fontSize: '14px', display:'flex', alignItems:'center', textDecoration:'none' }}><span>Next Blog Article</span> <PlayCircleFilledWhiteRounded fontSize="small" color="secondary" style={{ fontSize:'10px !important,', marginLeft:'5px' }}/></Link>
                                )}
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            
        </>
    );
};

export default Blog;