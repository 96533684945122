import React from "react";
import { BandBase } from "../interfaces";
import { Typography, Button, Box, Grid } from "@material-ui/core";
import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { ReactComponent as Play } from "../Assets/Play.svg";

interface FeaturedArtistProps {
	band: FeaturedBandBase;
}

interface FeaturedBandBase extends BandBase {
	bio: string;
	featured_songs: any[];
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		bio: {
			margin: "0px",
			display: "block",
			textAlign: "start",
			maxWidth: "100%",
			fontSize: "16px",
			"-webkit-line-clamp": "3",
			"-webkit-box-orient": "vertical",
			overflow: "hidden",
			textOverflow: "ellipsis",
		},
		featuredSong: {
			fontWeight: "bold",
			fontSize: "17px",
			letterSpacing: "1px",
		},
		inline: {
			display: "inline-block",
			marginRight: theme.spacing(1),
		},
		image: {
			height: "220px",
			boxShadow: "-20px -20px #0F24FD",
			float: "right",
			objectFit: "cover",
		},
		alignItems: {
			float: "right",
		},
		FeaturedArtistItems: {
            FeaturedArtistItem: {
                padding: '0px 20px'
            }
        },
		FeaturedArtistItem: {
            position: "relative",
        },
        FeaturedArtistItemOverlay: {
            position: 'absolute',
            bottom: '0',
            left: '0',
            width: '100%',
            minHeight: '60%',
            padding: '40px 20px 20px 20px',
            background: 'linear-gradient(180deg, rgba(15, 36, 253, 0) 0%, #0F24FD 100%)',
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'end',
            justifyContent: 'flex-start',
            transition: 'all 0.5s ease-in-out',
            "&:hover": {
                minHeight: '100%',
            }
        },
		FeaturedArtistItemOverlayIn: {
			marginTop: 'auto',
			marginBottom: '0px'
		},
        FeaturedArtistItemTitle: {
            fontFamily: "poppins",
            fontWeight: 600,
            fontSize: "2em",
        },
		FeaturedArtistItemTitleLink: {
			color: '#fff',
			textDecoration: 'none',
		},
	}),
);

export const FeaturedArtist: React.FC<FeaturedArtistProps> = ({ band }) => {
	const classes = useStyles();

	const matches = useMediaQuery("(min-width:900px)");

	return (
		<Link to={`/${band?.created_by?.username}`} className={classes.FeaturedArtistItemTitleLink}>
		{/* <Link to={`/bands/${band.id}`} className={classes.FeaturedArtistItemTitleLink}> */}
		<Grid className={classes.FeaturedArtistItem} style={ band && { background: "no-repeat right top", backgroundSize: 'cover', backgroundImage: `url(${band.photo.url})`, height: '100%', minHeight: '400px',}}>
		
			{/* <img alt="" src={band.photo.url} style={{maxWidth: "100%"}} /> */}
			<Grid className={classes.FeaturedArtistItemOverlay}>
				<Grid className={classes.FeaturedArtistItemOverlayIn}>			
						<Typography variant="h5" className={classes.FeaturedArtistItemTitle}>{band.name}</Typography>                            
					
					<p style={{marginTop:'5px', fontSize:'16px'}}>
						{band &&
						`${band.genres
							.map((item1: any) => item1.title)
							.join()
							.replace(/,/g, ", ")}`}
					</p>
				</Grid>
			</Grid>
		</Grid>
		</Link>
		// <Box
		// 	m={matches ? "20px" : "0px"}
		// 	display="flex"
		// 	justifyContent="center"
		// 	flexDirection={matches ? "row" : "column"}
		// 	alignItems="center"
		// >
		// 	<img src={band && band.photo.url} className={classes.image} alt="" />
		// 	<Box
		// 		ml={matches ? "50px" : "0px"}
		// 		display="flex"
		// 		justifyContent="space-between"
		// 		flexDirection="column"
		// 		alignItems="flex-start"
		// 		flexGrow="1"
		// 	>
		// 		<Box
		// 			display="flex"
		// 			flexDirection={matches ? "row" : "column"}
		// 			alignItems={matches ? "flex-end" : "flex-start"}
		// 			mt={matches ? 0 : 3}
		// 			mb={2}
		// 		>
		// 			<Typography variant="h4" className={classes.inline}>
		// 				{band && band.name}
		// 			</Typography>
		// 			<Typography variant="subtitle1" className={classes.inline}>
		// 				{band &&
		// 					`(${band.genres
		// 						.map(item => item.title)
		// 						.join()
		// 						.replace(/,/g, ", ")})`}
		// 			</Typography>
		// 		</Box>
		// 		<p className={classes.bio}>{band && band.bio}</p>
		// 		<Typography variant="body1" className={classes.featuredSong}>
		// 			FEATURED SONG
		// 		</Typography>
		// 		<Box
		// 			width="100%"
		// 			display="flex"
		// 			justifyContent="space-between"
		// 			flexDirection="row"
		// 			alignItems="center"
		// 		>
		// 			<Box
		// 				component="span"
		// 				display="flex"
		// 				flexDirection="row"
		// 				alignItems="center"
		// 			>
		// 				<Play width="80px" height="80px" />
		// 				<Typography variant="body1" className={classes.inline}>
		// 					{band &&
		// 						band.featured_songs &&
		// 						band.featured_songs[0] &&
		// 						band.featured_songs[0].name}
		// 				</Typography>
		// 			</Box>
		// 			{/* <Link to={`/bands/${band && band.id}`}> */}
		// 			<Link to={`/bands/${band.id}`}>
		// 				<Button
		// 					size="medium"
		// 					variant="contained"
		// 					color="secondary"
		// 					className={classes.alignItems}
		// 				>
		// 					View Artist
		// 				</Button>
		// 			</Link>
		// 		</Box>
		// 	</Box>
		// </Box>
	);
};
