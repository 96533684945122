import { createSlice, PayloadAction } from "redux-starter-kit";
import Services from "../generic/Services";

export interface HomePageState {
	featuredRequested: boolean;
	featuredSucceeded: boolean;
	featuredFailure: boolean;

	howItWorksRequested: boolean;
	howItWorksSucceeded: boolean;
	howItWorksFailure: boolean;

	quotesRequested: boolean;
	quotesSucceeded: boolean;
	quotesFailure: boolean;

	testimonialsRequested: boolean;
	testimonialsSucceeded: boolean;
	testimonialsFailure: boolean;

	quotes: any;
	testimonials: any;
	howItWorks: any;
	featured: any;
	error: {};
	page: string;
}

let initialState: HomePageState = {
	featuredRequested: false,
	featuredSucceeded: false,
	featuredFailure: false,

	howItWorksRequested: false,
	howItWorksSucceeded: false,
	howItWorksFailure: false,

	quotesRequested: false,
	quotesSucceeded: false,
	quotesFailure: false,

	testimonialsRequested: false,
	testimonialsSucceeded: false,
	testimonialsFailure: false,

	quotes: null,
	testimonials: null,
	howItWorks: null,
	featured: null,
	error: {},
	page: "",
};

const homePageSlice = createSlice({
	slice: "homepage",
	initialState,
	reducers: {
		featuredRequested(state, action) {
			state.featuredRequested = true;
			state.featuredSucceeded = false;
			state.featuredFailure = false;
		},
		featuredSucceeded(state, action: PayloadAction<boolean>) {
			state.featuredRequested = false;
			state.featuredSucceeded = true;
			state.featuredFailure = false;
			state.featured = action.payload;
		},
		featuredFailure(state, action: PayloadAction<boolean>) {
			state.featuredRequested = false;
			state.featuredSucceeded = false;
			state.featuredFailure = true;
			state.error = action.payload;
		},
		howItWorksRequested(state, action) {
			state.howItWorksRequested = true;
			state.howItWorksSucceeded = false;
			state.howItWorksFailure = false;
		},
		howItWorksSucceeded(state, action: PayloadAction<boolean>) {
			state.howItWorksRequested = false;
			state.howItWorksSucceeded = true;
			state.howItWorksFailure = false;
			state.howItWorks = action.payload;
		},
		howItWorksFailure(state, action: PayloadAction<boolean>) {
			state.howItWorksRequested = false;
			state.howItWorksSucceeded = false;
			state.howItWorksFailure = true;
			state.error = action.payload;
		},
		quotesRequested(state, action) {
			state.quotesRequested = true;
			state.quotesSucceeded = false;
			state.quotesFailure = false;
		},
		quotesSucceeded(state, action: PayloadAction<boolean>) {
			state.quotesRequested = false;
			state.quotesSucceeded = true;
			state.quotesFailure = false;
			state.quotes = action.payload;
		},
		quotesFailure(state, action: PayloadAction<boolean>) {
			state.quotesRequested = false;
			state.quotesSucceeded = false;
			state.quotesFailure = true;
			state.error = action.payload;
		},
		testimonialsRequested(state, action) {
			state.testimonialsRequested = true;
			state.testimonialsSucceeded = false;
			state.testimonialsFailure = false;
		},
		testimonialsSucceeded(state, action: PayloadAction<boolean>) {
			state.testimonialsRequested = false;
			state.testimonialsSucceeded = true;
			state.testimonialsFailure = false;
			state.testimonials = action.payload;
		},
		testimonialsFailure(state, action: PayloadAction<boolean>) {
			state.testimonialsRequested = false;
			state.testimonialsSucceeded = false;
			state.testimonialsFailure = true;
			state.error = action.payload;
		},
		setPage(state, action) {
			state.page = action.payload;
		},
	},
});

export const {
	featuredRequested,
	featuredSucceeded,
	featuredFailure,
	howItWorksRequested,
	howItWorksSucceeded,
	howItWorksFailure,
	quotesRequested,
	quotesSucceeded,
	quotesFailure,
	testimonialsRequested,
	testimonialsSucceeded,
	testimonialsFailure,
	setPage,
} = homePageSlice.actions;

export default homePageSlice.reducer;

export const listFeatured = (): any => async (dispatch: any) => {
	try {
		dispatch(featuredRequested());
		const res = await Services.bands.listFeatured();
		dispatch(featuredSucceeded(res));
	} catch (err) {
		dispatch(featuredFailure(err));
	}
};

export const getHowItWorks = (type: string): any => async (dispatch: any) => {
	try {
		dispatch(howItWorksRequested());
		const res = await Services.events.getHowItWorks(type);
		dispatch(howItWorksSucceeded(res));
	} catch (err) {
		dispatch(howItWorksFailure(err));
	}
};

export const getQuotes = (): any => async (dispatch: any) => {
	try {
		dispatch(quotesRequested());
		const res = await Services.events.getQuotes();
		dispatch(quotesSucceeded(res));
	} catch (err) {
		dispatch(quotesFailure(err));
	}
};

export const getTestimonials = (): any => async (dispatch: any) => {
	try {
		dispatch(testimonialsRequested());
		const res = await Services.events.getTestimonials();
		dispatch(testimonialsSucceeded(res));
	} catch (err) {
		dispatch(testimonialsFailure(err));
	}
};
