import { createSlice, PayloadAction } from "redux-starter-kit";
import Services from "../../generic/Services";

export interface ISupportState {
	sendInquiryRequested: boolean;
	sendInquirySucceeded: boolean;
	sendInquiryFailure: boolean;
	error: {};
}

let initialState: ISupportState = {
	sendInquiryRequested: false,
	sendInquirySucceeded: false,
	sendInquiryFailure: false,
	error: {},
};

const supportSlice = createSlice({
	slice: "support",
	initialState,
	reducers: {
		sendInquiryRequested(state, action) {
			state.sendInquiryRequested = true;
			state.sendInquirySucceeded = false;
			state.sendInquiryFailure = false;
		},
		sendInquirySucceeded(state, action: PayloadAction<boolean>) {
			state.sendInquirySucceeded = true;
			state.sendInquiryRequested = false;
			state.sendInquiryFailure = false;
		},
		sendInquiryFailure(state, action: PayloadAction<boolean>) {
			state.sendInquirySucceeded = false;
			state.sendInquiryRequested = false;
			state.sendInquiryFailure = true;
			state.error = action.payload;
		},
		closeFailModal(state, action) {
			state.sendInquiryFailure = false;
		},
		closeSaveModal(state, action) {
			state.sendInquirySucceeded = false;
		},
	},
});

export const {
	sendInquiryRequested,
	sendInquirySucceeded,
	sendInquiryFailure,
	closeFailModal,
	closeSaveModal,
} = supportSlice.actions;

export default supportSlice.reducer;

export const sendInquiry = (inquiry: any): any => async (dispatch: any) => {
	try {
		dispatch(sendInquiryRequested());
		const res = await Services.users.sendSupportInquiry(inquiry);
		dispatch(sendInquirySucceeded(res));
	} catch (err) {
		dispatch(sendInquiryFailure(err));
	}
};
