import React from "react";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import useTheme from "@material-ui/core/styles/useTheme";
import makeStyles from "@material-ui/core/styles/makeStyles";
import createStyles from "@material-ui/core/styles/createStyles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import { AddCardForm } from "./AddCardForm";
import { AddAccountForm } from "./AddAccountForm";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCcVisa, faCcMastercard, faCcDiscover, faCcAmex } from "@fortawesome/free-brands-svg-icons"
import { IsBooker, IsTalent, IsAgent } from "../../AuthBase/";

const useStyles = makeStyles((theme) =>
	createStyles({
		margin: {
			margin: "0px",
		},
		pointer: {
			cursor: "pointer",
		},
		addIcon: {
			fontSize: "0.8rem",
		},
		ExpansionPanel: {
			borderBottom: "1px solid rgba(100, 254, 204, 0.5)",
		},
		ExpansionPanelSummary: {
			marginTop: theme.spacing(3),
			marginBottom: theme.spacing(3),
		},
		brandIcons: {
			margin: theme.spacing(.5)
		}
	}),
);

interface CardAccountProps {
	addPaymentMethod: boolean;
	setAddPaymentMethod: any;
	type: "CARD" | "ACCOUNT";
	dirtyForm: boolean;
	setDirtyForm: any;
	states?: any;
	updateUserData: () => void;
}

export const CardAccount: React.FC<CardAccountProps> = ({
	type,
	states,
	addPaymentMethod,
	setAddPaymentMethod,
	dirtyForm,
	setDirtyForm,
	updateUserData,
}) => {
	const classes = useStyles();

	const theme = useTheme();

	const [open, setOpen] = React.useState<boolean>(false);

	return (
		<>
		<IsBooker>
			<Box pb={3} pt={3}>
				<Box mb={type === "CARD" ? "" : 1} fontFamily="poppins" fontSize="1.2rem">
					{type === "CARD" ? "Credit/Debit Cards" : "Checking Account"}
				</Box>
				{type === "CARD" ? (
					<>
						<FontAwesomeIcon icon={faCcVisa} size="2x" className={classes.brandIcons}/>
						<FontAwesomeIcon icon={faCcMastercard} size="2x" className={classes.brandIcons}/>
						<FontAwesomeIcon icon={faCcDiscover} size="2x" className={classes.brandIcons}/>
						<FontAwesomeIcon icon={faCcAmex} size="2x" className={classes.brandIcons}/>
					</>
				) : (
					""
				)}
				<p className={classes.margin}>
					{type === "CARD"
						? "Repsy accepts all major credit and debit cards"
						: open
						? "Pay with an ACH-enabled account from a bank branch located in the U.S. If you're unsure whether your account is ACH-enabled, please contact your bank."
						: "Pay with an ACH-enabled account from a bank branch located in the U.S. If you're unsure whether your account is ACH-enabled, please contact your bank."}
				</p>
				<ExpansionPanel
					className={classes.ExpansionPanel}
					onChange={(event: object, expanded: boolean) => {
						if (expanded) {
							setOpen(true);
						} else {
							setOpen(false);
						}
					}}
					expanded={open}>
					<ExpansionPanelSummary
						classes={{
							content: classes.ExpansionPanelSummary,
						}}>
						<Box color={theme.palette.secondary.main}>
							Add New {type === "CARD" ? "Card" : "Account"}
							<IconButton color="inherit" classes={{ root: classes.addIcon }}>
								<AddCircleIcon fontSize="inherit" />
							</IconButton>
						</Box>
					</ExpansionPanelSummary>
					<ExpansionPanelDetails>
						{type === "CARD" ? (
							<AddCardForm
								addPaymentMethod={addPaymentMethod}
								setAddPaymentMethod={setAddPaymentMethod}
								dirtyForm={dirtyForm}
								setDirtyForm={setDirtyForm}
								states={states}
								setExpantionOpen={setOpen}
								updateUserData={updateUserData}
							/>
						) : (
							<AddAccountForm
								addPaymentMethod={addPaymentMethod}
								setAddPaymentMethod={setAddPaymentMethod}
								dirtyForm={dirtyForm}
								setDirtyForm={setDirtyForm}
								states={states}
								setExpantionOpen={setOpen}
								updateUserData={updateUserData}
							/>
						)}
					</ExpansionPanelDetails>
				</ExpansionPanel>
			</Box>
		</IsBooker>
		<IsAgent>
			<Box pb={3} pt={3}>
				<Box mb={type === "CARD" ? "" : 1} fontFamily="poppins" fontSize="1.2rem">
					{type === "CARD" ? "Credit/Debit Cards" : "Checking Account"}
				</Box>
				{type === "CARD" ? (
					<>
						<FontAwesomeIcon icon={faCcVisa} size="2x" className={classes.brandIcons}/>
						<FontAwesomeIcon icon={faCcMastercard} size="2x" className={classes.brandIcons}/>
						<FontAwesomeIcon icon={faCcDiscover} size="2x" className={classes.brandIcons}/>
						<FontAwesomeIcon icon={faCcAmex} size="2x" className={classes.brandIcons}/>
					</>
				) : (
					""
				)}
				<p className={classes.margin}>
					{type === "CARD"
						? "Repsy accepts all major credit and debit cards"
						: open
						? "Pay with an ACH-enabled account from a bank branch located in the U.S. If you're unsure whether your account is ACH-enabled, please contact your bank."
						: "Pay with an ACH-enabled account from a bank branch located in the U.S. If you're unsure whether your account is ACH-enabled, please contact your bank."}
				</p>
				<ExpansionPanel
					className={classes.ExpansionPanel}
					onChange={(event: object, expanded: boolean) => {
						if (expanded) {
							setOpen(true);
						} else {
							setOpen(false);
						}
					}}
					expanded={open}>
					<ExpansionPanelSummary
						classes={{
							content: classes.ExpansionPanelSummary,
						}}>
						<Box color={theme.palette.secondary.main}>
							Add New {type === "CARD" ? "Card" : "Account"}
							<IconButton color="inherit" classes={{ root: classes.addIcon }}>
								<AddCircleIcon fontSize="inherit" />
							</IconButton>
						</Box>
					</ExpansionPanelSummary>
					<ExpansionPanelDetails>
						{type === "CARD" ? (
							<AddCardForm
								addPaymentMethod={addPaymentMethod}
								setAddPaymentMethod={setAddPaymentMethod}
								dirtyForm={dirtyForm}
								setDirtyForm={setDirtyForm}
								states={states}
								setExpantionOpen={setOpen}
								updateUserData={updateUserData}
							/>
						) : (
							<AddAccountForm
								addPaymentMethod={addPaymentMethod}
								setAddPaymentMethod={setAddPaymentMethod}
								dirtyForm={dirtyForm}
								setDirtyForm={setDirtyForm}
								states={states}
								setExpantionOpen={setOpen}
								updateUserData={updateUserData}
							/>
						)}
					</ExpansionPanelDetails>
				</ExpansionPanel>
			</Box>
		</IsAgent>
		<IsTalent>
			<Box pb={3} pt={3}>
				<Box mb={type === "CARD" ? "" : 1} fontFamily="poppins" fontSize="1.2rem">
					{type === "CARD" ? "Credit/Debit Cards" : "Checking Account"}
				</Box>
				{type === "CARD" ? (
					<>
						<FontAwesomeIcon icon={faCcVisa} size="2x" className={classes.brandIcons}/>
						<FontAwesomeIcon icon={faCcMastercard} size="2x" className={classes.brandIcons}/>
						<FontAwesomeIcon icon={faCcDiscover} size="2x" className={classes.brandIcons}/>
						<FontAwesomeIcon icon={faCcAmex} size="2x" className={classes.brandIcons}/>
					</>
				) : (
					""
				)}
				<p className={classes.margin}>
					{type === "CARD"
						? "Credit Card information is not required but recommended in order to make payment processing easier. Repsy accepts all major credit and debit cards."
						: open
						? "Setup an ACH-enabled account from a bank branch located in the U.S. If you're unsure whether your account is ACH-enabled, please contact your bank."
						: "Checking Account information is required for Talent and Agents to receive funds."}
				</p>
				<ExpansionPanel
					className={classes.ExpansionPanel}
					onChange={(event: object, expanded: boolean) => {
						if (expanded) {
							setOpen(true);
						} else {
							setOpen(false);
						}
					}}
					expanded={open}>
					<ExpansionPanelSummary
						classes={{
							content: classes.ExpansionPanelSummary,
						}}>
						<Box color={theme.palette.secondary.main}>
							Add New {type === "CARD" ? "Card" : "Account"}
							<IconButton color="inherit" classes={{ root: classes.addIcon }}>
								<AddCircleIcon fontSize="inherit" />
							</IconButton>
						</Box>
					</ExpansionPanelSummary>
					<ExpansionPanelDetails>
						{type === "CARD" ? (
							<AddCardForm
								addPaymentMethod={addPaymentMethod}
								setAddPaymentMethod={setAddPaymentMethod}
								dirtyForm={dirtyForm}
								setDirtyForm={setDirtyForm}
								states={states}
								setExpantionOpen={setOpen}
								updateUserData={updateUserData}
							/>
						) : (
							<AddAccountForm
								addPaymentMethod={addPaymentMethod}
								setAddPaymentMethod={setAddPaymentMethod}
								dirtyForm={dirtyForm}
								setDirtyForm={setDirtyForm}
								states={states}
								setExpantionOpen={setOpen}
								updateUserData={updateUserData}
							/>
						)}
					</ExpansionPanelDetails>
				</ExpansionPanel>
			</Box>
		</IsTalent>
		</>
	);
};
