import React, { useState, forwardRef } from "react";
import {
	Box,
	Grid,
	InputLabel,
	TextField,
	Link,
	Button,
	Select,
	MenuItem,
	FormHelperText,
	Dialog,
	DialogContent,
	DialogContentText,
	DialogActions,
	Checkbox,
	ListItemText,
	Avatar,
	Typography,
} from "@material-ui/core";
import ErrorMessage from "../../../generic/ErrorMessage";
import { Formik, FormikProps } from "formik";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import { CreateEventState } from "./createEventSlice";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "./style.css";
import { useHistory } from "react-router-dom";
import DateRangeIcon from "@material-ui/icons/DateRange";
import AccessTimeIcon from '@material-ui/icons/AccessTime';
//import ImagePlaceholder from "../../../Assets/ImagePlaceholder.png";
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardTimePicker } from '@material-ui/pickers';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		alignText: {
			textAlign: "right",
		},
		bigAvatarTitle: {
			marginLeft: '110px',
			"@media only screen and (max-width: 900px)": {
				marginLeft: '90px',
			},
			"@media only screen and (max-width: 767px)": {
				marginLeft: '70px',
			},
			"@media only screen and (max-width: 640px)": {
				marginLeft: '50px',
			},
			"@media only screen and (max-width: 5750px)": {
				marginLeft: '40px',
			},
			"@media only screen and (max-width: 480px)": {
				marginLeft: '30px',
			},
			"@media only screen and (max-width: 414px)": {
				marginLeft: '0px',
			}
		},
		bigAvatar: {
			margin: 10,
			width: 135,
			height: 135,
			border: "solid 8px #0F24FD",
			float: "left",
			"@media only screen and (max-width: 900px)": {
				width: 120,
				height: 120,
				border: "solid 7px #0F24FD",
			},
			"@media only screen and (max-width: 767px)": {
				width: 100,
				height: 100,
				border: "solid 6px #0F24FD",
			},
			"@media only screen and (max-width: 640px)": {
				width: 80,
				height: 80,
				border: "solid 5px #0F24FD",
			},
			"@media only screen and (max-width: 575px)": {
				width: 70,
				height: 70,
				border: "solid 4px #0F24FD",
			}
		},
		dateBtn: {
			"&:hover": {
				backgroundColor: "rgba(100, 254, 204, 0)",
				border: "1px solid rgba(100, 254, 204, 0.5)",
			},
		},
		dropDownPlaceholder: {
			fontFamily: "Poppins",
			color: "#63637b59",
			fontWeight: 400,
		},
		input: {
			fontWeight: 400,
		},
		btnError: {
			borderColor: "red",
		},
		timeField: {
			"input[type='time']::-webkit-calendar-picker-indicator": {
				//-webkitAppearance:"none",
				appearance: "none",
			}
		},
	}),
);

export interface MyFormValues {
	venue_description: any;
	title: string;
	date: string;
	description: string;
	location: string;
	state: { id: number } | null;
	event_type: { id: number } | null;
	start_time: string;
	desired_set_length: string;
	desired_set_length_hours: string;
	desired_set_length_mins: string;
	number_of_attendees: string;
	promo_code: string;
}

export interface CreateBookerEventFormProps {
	id: string;
	validationSchema: any;
	initialValues: any;
	states: any;
	gigs: any;
	bookerEvent: CreateEventState;
	authentication: any;
	handleOnSubmit: (event: any) => void;
}

export const CreateBookerEventForm: React.FC<CreateBookerEventFormProps> = ({
	id,
	bookerEvent,
	gigs,
	states,
	handleOnSubmit,
	initialValues,
	validationSchema,
	authentication,
}) => {
	const classes = useStyles();

	const [type, setType] = useState(true);

	const [open, setOpen] = React.useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const history = useHistory();

	return (
		<Box>
			<Formik
				enableReinitialize={true}
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={(values, actions) => {
					// const arr = values.desired_set_length.split(":");
					values.desired_set_length = (
						Number(values.desired_set_length_hours) * 60 +
						Number(values.desired_set_length_mins)
					).toString();
					handleOnSubmit(values);
					actions.resetForm();
					actions.setSubmitting(false);
				}}
				render={(formikBag: FormikProps<MyFormValues>) => {
					const isVenueDescriptionChecked = (id: number) => {
						return formikBag.values.venue_description.filter(
							(item: any) => item.id === id,
						).length
							? true
							: false;
					};

					const handleDayChange = (day: any) => {
						formikBag.setFieldValue(
							"date",
							moment(day)
								.format("MMM DD, YYYY")
								.toString(),
						);
					};

					const handleVenueDescriptionChange = (e: any) => {
						const { value, checked } = e.target;
						let updatedStates = [];
						const id = Number(value);
						updatedStates = checked
							? [
								...formikBag.values.venue_description,
								{ id: Number(value) },
							]
							: formikBag.values.venue_description.filter(
								(item: any) => Number(item.id) !== id,
							);
						formikBag.setFieldValue(
							"venue_description",
							updatedStates,
						);
						formikBag.setFieldTouched(
							"venue_description",
							true,
							false,
						);
					};

					return (
						<>
							<Grid item>
								<Grid
									container
									item
									direction="row"
									alignItems="center"
									justify="flex-start"
									style={{ marginBottom: "20px" }}
									className={classes.bigAvatarTitle}
								>
									<Avatar
										style={{ backgroundColor: '#1c1762', color: '#fff' }}
										src={authentication?.user?.photo?.thumbnail || ""} className={classes.bigAvatar} >
										{authentication?.user?.fname?.length > 0 && authentication?.user?.lname?.length > 0 &&
											<span>{authentication?.user?.fname[0]}{authentication?.user?.lname[0]}</span>
										}
									</Avatar>
									<Box fontWeight="bold" fontFamily="Rubik" fontSize="30px">
										{bookerEvent.event === null || bookerEvent.createEventFailure
											? "Create Event"
											: "Duplicate Event"}
											<Grid
										container
										direction="row"
										alignItems="center">
										<Grid item xs={12} md={12} lg={12}>
											<Typography variant="caption">Need help? Click <Link href="https://calendly.com/grahamrepsy/repsy-intro?month=2022-09" target="_blank" style={{color: 'white', textDecoration: 'underline'}}>here</Link> to talk to our sales team for assistance.</Typography>
										</Grid>
									</Grid>
									</Box>
									
								</Grid>
							</Grid>
							<form onSubmit={formikBag.handleSubmit}>
								<Grid container direction="column" spacing={4}>
									<Grid item>
										<Grid
											container
											direction="row"
											alignItems="center"
											spacing={4}>
											<Grid item xs={4} md={4} lg={4}>
												<InputLabel
													className={classes.alignText}
													htmlFor="title">
													Event Name *
												</InputLabel>
											</Grid>
											<Grid item xs={8} md={8} lg={8}>
												<TextField
													inputProps={{ className: classes.input }}
													placeholder="Event Name"
													autoComplete="off"
													id="title"
													name="title"
													variant="outlined"
													color="secondary"
													helperText={
														formikBag.touched.title
															? formikBag.errors.title
															: ""
													}
													error={
														formikBag.touched.title &&
														Boolean(
															formikBag.errors.title,
														)
													}
													fullWidth={true}
													margin="dense"
													value={formikBag.values.title}
													onChange={(e: any) => {
														e.persist();
														formikBag.setFieldValue(
															"title",
															e.target.value,
														);
														formikBag.setFieldTouched(
															"title",
															true,
															false,
														);
													}}
												/>
											</Grid>
										</Grid>
									</Grid>
									<Grid item>
										<Grid
											container
											direction="row"
											alignItems="center"
											spacing={4}>
											<Grid item xs={4} md={4} lg={4}>
												<InputLabel
													className={classes.alignText}
													htmlFor="date">
													Event Date *
												</InputLabel>
											</Grid>
											<Grid item xs={8} md={8} lg={8}>
												<DayPickerInput
													component={forwardRef(
														(props, ref) => {
															return (
																<TextField
																	placeholder="Event Description"
																	classes={{ root: classes.dropDownPlaceholder }}
																	autoComplete="off"
																	id="date"
																	name="description"
																	variant="outlined"
																	color="secondary"
																	InputProps={{
																		endAdornment: (
																			<DateRangeIcon
																				color="secondary"
																				style={{
																					marginRight:
																						"8px",
																				}}
																			/>
																		),
																	}}
																	value={
																		formikBag
																			.values
																			.date
																	}
																	fullWidth={true}
																	{...props}
																/>
															);
														},
													)}
													dayPickerProps={{
														disabledDays: {
															before: new Date(),
														},
													}}
													format="LL"
													onDayChange={handleDayChange}
													value={formikBag.values.date}
													placeholder={"Date"}
												/>
												{formikBag.errors.date &&
													formikBag.touched.date && (
														<FormHelperText
															error
															variant="outlined">
															{formikBag.errors.date}
														</FormHelperText>
													)}
											</Grid>
										</Grid>
									</Grid>
									<Grid item>
										<Grid
											container
											direction="row"
											alignItems="center"
											spacing={4}>
											<Grid item xs={4} md={4} lg={4}>
												<InputLabel
													className={classes.alignText}
													htmlFor="description">
													Event Description *
												</InputLabel>
											</Grid>
											<Grid item xs={8} md={8} lg={8}>
												<TextField
													inputProps={{ className: classes.input }}
													multiline={true}
													rows={6}
													placeholder="Event Description"
													autoComplete="off"
													id="description"
													name="description"
													variant="outlined"
													color="secondary"
													helperText={
														formikBag.touched
															.description
															? formikBag.errors
																.description
															: ""
													}
													error={
														formikBag.touched
															.description &&
														Boolean(
															formikBag.errors
																.description,
														)
													}
													fullWidth={true}
													margin="dense"
													value={
														formikBag.values.description
													}
													onChange={(e: any) => {
														e.persist();
														formikBag.setFieldValue(
															"description",
															e.target.value,
														);
														formikBag.setFieldTouched(
															"description",
															true,
															false,
														);
													}}
												/>
											</Grid>
										</Grid>
									</Grid>
									<Grid item>
										<Grid
											container
											direction="row"
											alignItems="center"
											spacing={4}>
											<Grid item xs={4} md={4} lg={4}>
												<InputLabel
													className={classes.alignText}
													htmlFor="venue_description">
													Venue Description *
												</InputLabel>
											</Grid>
											<Grid item xs={8} md={8} lg={8}>
												{<Select
													className={
														formikBag.values
															.venue_description
															? ""
															: classes.dropDownPlaceholder
													}
													fullWidth={true}
													id="venue_description"
													name="venue_description"
													variant="outlined"
													color="secondary"
													error={
														formikBag.touched
															.venue_description &&
														Boolean(
															formikBag.errors
																.venue_description,
														)
													}
													MenuProps={{
														getContentAnchorEl: null,
														anchorOrigin: {
															vertical: "bottom",
															horizontal: "center",
														},
														transformOrigin: {
															vertical: "top",
															horizontal: "center",
														},
													}}
													renderValue={(
														selected: any,
													) => {
														if (
															(selected as string[])
																.length === 0
														) {
															return (
																<div
																	className={
																		classes.dropDownPlaceholder
																	}>
																	Venue
																	Description
																</div>
															);
														}
														const temp = selected.map(
															(item: {
																id: number;
															}) => item.id,
														);
														const x = bookerEvent.venueDescription.items.filter(
															(item: {
																id: number;
																title: string;
															}) => {
																if (
																	temp.includes(
																		item.id,
																	)
																) {
																	return item;
																} else {
																	return ""
																}
															},
														);
														return x
															.map(
																(item: {
																	id: number;
																	title: string;
																}) => item.title,
															)
															.join(", ");
													}}
													displayEmpty
													value={
														formikBag.values
															.venue_description
													}>
													{bookerEvent &&
														bookerEvent.venueDescription &&
														bookerEvent.venueDescription.items.map(
															(item: any) => (
																<MenuItem
																	key={item.id}
																	value={item.id}>
																	<Checkbox
																		name={
																			item.title
																		}
																		checked={isVenueDescriptionChecked(
																			item.id,
																		)}
																		onChange={
																			handleVenueDescriptionChange
																		}
																		value={
																			item.id
																		}
																	/>
																	<ListItemText
																		primary={
																			item.title
																		}
																	/>
																</MenuItem>
															),
														)}
												</Select>}

												{formikBag.errors
													.venue_description &&
													formikBag.touched
														.venue_description && (
														<FormHelperText
															error
															variant="outlined">
															venue Description is a
															required field
														</FormHelperText>
													)}
											</Grid>
										</Grid>
									</Grid>
									<Grid item>
										<Grid
											container
											direction="row"
											alignItems="center"
											spacing={4}>
											<Grid item xs={4} md={4} lg={4}>
												<InputLabel
													className={classes.alignText}
													htmlFor="event_type">
													Event Type *
												</InputLabel>
											</Grid>
											<Grid item xs={8} md={8} lg={8}>
												<Select
													className={
														formikBag.values
															.event_type
															? ""
															: classes.dropDownPlaceholder
													}
													value={
														formikBag.values
															.event_type &&
															formikBag.values.event_type
																.id
															? formikBag.values
																.event_type.id
															: 99
													}
													inputProps={{
														className: formikBag.values
															.event_type
															? ""
															: classes.dropDownPlaceholder
													}}
													fullWidth={true}
													id="event_type"
													name="event_type"
													variant="outlined"
													color="secondary"
													error={
														formikBag.touched
															.event_type &&
														Boolean(
															formikBag.errors
																.event_type,
														)
													}
													MenuProps={{
														getContentAnchorEl: null,
														anchorOrigin: {
															vertical: "bottom",
															horizontal: "center",
														},
														transformOrigin: {
															vertical: "top",
															horizontal: "center",
														},
													}}
													onChange={(e: any) => {
														e.persist();
														formikBag.setFieldValue(
															"event_type",
															{ id: e.target.value },
														);
														formikBag.setFieldTouched(
															"event_type",
															true,
															false,
														);
													}}>
													<MenuItem disabled value={99}>
														Event Type
													</MenuItem>
													{gigs.allIds.map(
														(item: any) => (
															<MenuItem
																key={item}
																value={item}>
																{
																	gigs.items[item]
																		.title
																}
															</MenuItem>
														),
													)}
												</Select>
												{formikBag.errors.event_type &&
													formikBag.touched
														.event_type && (
														<FormHelperText
															error
															variant="outlined">
															event type is a required
															field
														</FormHelperText>
													)}
											</Grid>
										</Grid>
									</Grid>
									<Grid item>
										<Grid
											container
											direction="row"
											alignItems="center"
											spacing={4}>
											<Grid item xs={4} md={4} lg={4}>
												<InputLabel
													className={classes.alignText}
													htmlFor="location">
													Location *
												</InputLabel>
											</Grid>
											<Grid item xs={8} md={8} lg={8}>
												<Grid
													container
													direction="row"
													alignItems="center"
													spacing={4}>
													<Grid item xs={12} md={6} lg={6}>
														<TextField
															inputProps={{ className: classes.input }}
															placeholder="Location"
															autoComplete="off"
															id="location"
															name="location"
															variant="outlined"
															color="secondary"
															helperText={
																formikBag.touched.location
																	? formikBag.errors
																		.location
																	: ""
															}
															error={
																formikBag.touched
																	.location &&
																Boolean(
																	formikBag.errors
																		.location,
																)
															}
															fullWidth={true}
															margin="dense"
															value={
																formikBag.values.location
															}
															onChange={(e: any) => {
																e.persist();
																formikBag.setFieldValue(
																	"location",
																	e.target.value,
																);
																formikBag.setFieldTouched(
																	"location",
																	true,
																	false,
																);
															}}
														/>
													</Grid>
													<Grid item xs={12} md={6} lg={6}>
														<Select
															className={
																formikBag.values
																	.state
																	? ""
																	: classes.dropDownPlaceholder
															}
															value={
																formikBag.values.state &&
																	formikBag.values.state.id
																	? formikBag.values.state
																		.id
																	: 99
															}
															fullWidth={true}
															margin="dense"
															id="state"
															name="state"
															variant="outlined"
															color="secondary"
															error={
																formikBag.touched.state &&
																Boolean(
																	formikBag.errors.state,
																)
															}
															MenuProps={{
																getContentAnchorEl: null,
																anchorOrigin: {
																	vertical: "bottom",
																	horizontal: "left",
																},
																transformOrigin: {
																	vertical: "top",
																	horizontal: "left",
																},
															}}
															onChange={(e: any) => {
																e.persist();
																formikBag.setFieldValue(
																	"state",
																	{ id: e.target.value },
																);
																formikBag.setFieldTouched(
																	"state",
																	true,
																	false,
																);
															}}>
															<MenuItem disabled value={99}>
																State
															</MenuItem>
															{states.allIds.map(
																(item: any) => (
																	<MenuItem
																		key={item}
																		value={item}>
																		{
																			states.items[
																				item
																			].title
																		}
																	</MenuItem>
																),
															)}
														</Select>
														{formikBag.errors.state &&
															formikBag.touched.state && (
																<FormHelperText
																	error
																	variant="outlined">
																	state is a required
																	field
																</FormHelperText>
															)}
													</Grid>
												</Grid>


											</Grid>
										</Grid>
									</Grid>
									<Grid item>
										<Grid
											container
											direction="row"
											alignItems="center"
											spacing={4}>
											<Grid item xs={4} md={4} lg={4}>
												<InputLabel
													className={classes.alignText}
													htmlFor="start_time">
													Start Time *
												</InputLabel>
											</Grid>
											<Grid item xs={8} md={8} lg={8}>
												<Grid
													container
													direction="row"
													alignItems="center"
												>
													<Grid item xs={12} md={5} lg={4}>
														<div className="timeField">
															<MuiPickersUtilsProvider utils={DateFnsUtils}>
																<KeyboardTimePicker
																	// minutesStep={15}
																	onFocus={() => {
																		setType(true);
																	}}
																	onBlur={() => {
																		if (
																			formikBag.values
																				.start_time === ""
																		) {
																			setType(true);
																		}
																	}}
																	//type={type ? "time" : ""}	
																	placeholder=""
																	autoComplete="off"
																	id="start_time"
																	name="start_time"
																	helperText={
																		formikBag.touched.start_time
																			? formikBag.errors
																				.start_time
																			: ""
																	}
																	error={
																		formikBag.touched
																			.start_time &&
																		Boolean(
																			formikBag.errors
																				.start_time,
																		)
																	}

																	keyboardIcon={<AccessTimeIcon
																		color="secondary"
																		style={{
																			marginRight:
																				"2px",
																		}}
																	/>}
																	fullWidth={true}
																	margin="dense"
																	value={
																		formikBag.values.start_time ? formikBag.values.start_time : '01/01/1900 12:00 AM'
																	}
																	onChange={(e) => {
																		//console.log('e.target.value', e);
																		//e.persist();
																		formikBag.setFieldValue(
																			"start_time",
																			e,
																		);
																		formikBag.setFieldTouched(
																			"start_time",
																			true,
																			false,
																		);
																	}}
																/>
															</MuiPickersUtilsProvider>
														</div>
													</Grid>
													<Grid item xs={12} md={7} lg={8}>
														<Box ml="15px" style={{ marginTop: "5px" }}>Enter a time (e.g., 08:00 AM/PM) or click the clock to select a time.</Box>
													</Grid>
												</Grid>


											</Grid>

										</Grid>
									</Grid>
									{/* <Grid>
									<MuiPickersUtilsProvider utils={DateFnsUtils}>
									<KeyboardTimePicker
												margin="normal"
												placeholder=""
												autoComplete="off"
												id="start_time"
												name="start_time"												
												label="Time picker"
												value={
													formikBag.values.start_time ? formikBag.values.start_time : "20:00"
												}
												onChange={(e: any) => {
													e.persist();
													formikBag.setFieldValue(
														"start_time",
														e.target.value,
													);
													formikBag.setFieldTouched(
														"start_time",
														true,
														false,
													);
												}}
												KeyboardButtonProps={{
													'aria-label': 'change time',
												}}
												/>
										</MuiPickersUtilsProvider>
									</Grid> */}
									<Grid item>
										<Grid
											container
											direction="row"
											alignItems="center"
											spacing={4}>
											<Grid item xs={4} md={4} lg={4}>
												<InputLabel
													className={classes.alignText}
													htmlFor="desired_set_length">
													Desired Length *
												</InputLabel>
											</Grid>
											<Grid item xs={8} md={8} lg={8}>
												<Grid
													container
													direction="row"
													alignItems="center"
													spacing={4}>
													<Grid item xs={6} md={6} lg={6}>
														<Select
															inputProps={{ className: classes.input }}
															placeholder="Hours"
															autoComplete="off"
															id="desired_set_length_hours"
															name="desired_set_length_hours"
															variant="outlined"
															color="secondary"
															// helperText={
															// 	formikBag.touched
															// 		.desired_set_length_hours
															// 		? formikBag.errors
															// 				.desired_set_length_hours
															// 		: ""
															// }
															error={
																formikBag.touched
																	.desired_set_length_hours &&
																Boolean(
																	formikBag.errors
																		.desired_set_length_hours,
																)
															}
															fullWidth={true}
															margin="dense"
															value={
																formikBag.values
																	.desired_set_length_hours
															}
															onChange={(e: any) => {
																e.persist();
																formikBag.setFieldValue(
																	"desired_set_length_hours",
																	e.target.value,
																);
																formikBag.setFieldTouched(
																	"desired_set_length_hours",
																	true,
																	false,
																);
															}}
														>
															<MenuItem value={1}>1</MenuItem>
															<MenuItem value={2}>2</MenuItem>
															<MenuItem value={3}>3</MenuItem>
															<MenuItem value={4}>4</MenuItem>
															<MenuItem value={5}>5</MenuItem>
															<MenuItem value={6}>6</MenuItem>
															<MenuItem value={7}>7</MenuItem>
															<MenuItem value={8}>8</MenuItem>
														</Select>
														<Box style={{ marginTop: "5px" }}>Hours</Box>
													</Grid>
													<Grid item xs={6} md={6} lg={6}>
														<Select
															inputProps={{ className: classes.input }}
															placeholder="Minutes"
															autoComplete="off"
															id="desired_set_length_mins"
															name="desired_set_length_mins"
															variant="outlined"
															color="secondary"
															// helperText={
															// 	formikBag.touched
															// 		.desired_set_length_mins
															// 		? formikBag.errors
															// 				.desired_set_length_mins
															// 		: ""
															// }
															error={
																formikBag.touched
																	.desired_set_length_mins &&
																Boolean(
																	formikBag.errors
																		.desired_set_length_mins,
																)
															}
															fullWidth={true}
															margin="dense"
															value={
																formikBag.values
																	.desired_set_length_mins
															}
															onChange={(e: any) => {
																e.persist();
																formikBag.setFieldValue(
																	"desired_set_length_mins",
																	e.target.value,
																);
																formikBag.setFieldTouched(
																	"desired_set_length_mins",
																	true,
																	false,
																);
															}}

														>
															{/* <MenuItem disabled value={0}>
															Minutes
														</MenuItem> */}
															<MenuItem value={0}>0</MenuItem>
															<MenuItem value={15}>15</MenuItem>
															<MenuItem value={30}>30</MenuItem>
															<MenuItem value={45}>45</MenuItem>
														</Select>
														<Box style={{ marginTop: "5px" }}>Minutes</Box>
													</Grid>

												</Grid>



											</Grid>

										</Grid>
									</Grid>
									<Grid item>
										<Grid
											container
											direction="row"
											alignItems="center"
											spacing={4}>
											<Grid item xs={4} md={4} lg={4}>
												<InputLabel
													className={classes.alignText}
													htmlFor="number_of_attendees">
													Number of Attendees *
												</InputLabel>
											</Grid>
											<Grid item xs={8} md={4} lg={4}>
												<TextField
													inputProps={{ className: classes.input }}
													placeholder="Number of Attendees "
													autoComplete="off"
													id="number_of_attendees"
													name="number_of_attendees"
													variant="outlined"
													color="secondary"
													helperText={
														formikBag.touched
															.number_of_attendees
															? formikBag.errors
																.number_of_attendees
															: ""
													}
													error={
														formikBag.touched
															.number_of_attendees &&
														Boolean(
															formikBag.errors
																.number_of_attendees,
														)
													}
													fullWidth={true}
													margin="dense"
													value={
														formikBag.values
															.number_of_attendees
													}
													onChange={(e: any) => {
														e.persist();
														formikBag.setFieldValue(
															"number_of_attendees",
															e.target.value,
														);
														formikBag.setFieldTouched(
															"number_of_attendees",
															true,
															false,
														);
													}}
												/>
											</Grid>
										</Grid>
									</Grid>
									<Grid item>
										<Grid
											container
											direction="row"
											alignItems="center"
											spacing={4}>
											<Grid item xs={4} md={4} lg={4}>
												<InputLabel
													className={classes.alignText}
													htmlFor="promo_code">
													Promo Code
												</InputLabel>
											</Grid>
											<Grid item xs={8} md={4} lg={4}>
												<TextField
													inputProps={{
														className: classes.input,
														maxLength: 12,
														style: { textTransform: 'uppercase' }
													}}
													placeholder="Promo Code "
													autoComplete="off"
													id="promo_code"
													name="promo_code"
													variant="outlined"
													color="secondary"
													helperText={
														formikBag.touched
															.number_of_attendees
															? formikBag.errors
																.promo_code
															: ""
													}
													error={
														formikBag.touched
															.promo_code &&
														Boolean(
															formikBag.errors
																.promo_code,
														)
													}
													fullWidth={true}
													margin="dense"
													value={
														formikBag.values
															.promo_code
													}
													onChange={(e: any) => {
														// get current target and cursoe position
														let target = e.target;
														const cursor = e.target.selectionStart;
														e.persist();
														const regex = /[^A-Za-z0-9]/g;
														let newStr = e.target.value.replace(regex, "");
														newStr = newStr?.toUpperCase();
														e.target.value = newStr;

														// keep cursoe on same position - either cursor will set to end.
														target.setSelectionRange(cursor, cursor);
														formikBag.setFieldValue(
															"promo_code",
															e.target.value,
														);
														formikBag.setFieldTouched(
															"promo_code",
															true,
															false,
														);
													}}
												/>
											</Grid>
										</Grid>
									</Grid>
									<Grid item>
										{/* display error message error from api incase of failure */}
										{bookerEvent.error &&
											bookerEvent.error.message && (
												<ErrorMessage
													error={bookerEvent.error}
												/>
											)}
									</Grid>
									<Grid item>
										<Grid
											container
											direction="row"
											alignItems="center">
											<Grid item xs={12} md={12} lg={12}>
												<Typography variant="caption">All events will be charged the required Repsy fee and additional hospitality costs could be requested by the Talent. All fees will be listed in your contract agreement.</Typography>
											</Grid>
										</Grid>
									</Grid>


									<Grid item>
										<Grid
											container
											direction="row"
											alignItems="center"
											spacing={2}>
											<Grid item xs={4} md={4} lg={4}></Grid>
											<Grid item>
												<Button
													type="submit"
													size="large"
													variant="contained"
													color="secondary"
													disabled={
														formikBag.isSubmitting
													}>
													Create
												</Button>
											</Grid>
											<Grid item>
												<Link
													color="textPrimary"
													component="button"
													type="button"
													onClick={handleClickOpen}>
													Cancel
												</Link>
												<Dialog
													open={open}
													onClose={handleClose}
													aria-labelledby="alert-dialog-title"
													aria-describedby="alert-dialog-description">
													<DialogContent>
														<DialogContentText
															color="textPrimary"
															id="alert-dialog-description">
															Your event is not saved
															yet. Are you sure you
															want to discard?
														</DialogContentText>
													</DialogContent>
													<DialogActions>
														<Button
															onClick={() => {
																handleClose();
																formikBag.resetForm(
																	{
																		venue_description: [],
																		state: null,
																		event_type: null,
																		title: "",
																		date: "",
																		description:
																			"",
																		location:
																			"",
																		start_time:
																			"",
																		desired_set_length:
																			"",
																		desired_set_length_hours: "",
																		desired_set_length_mins: "",
																		number_of_attendees:
																			"",
																		promo_code: ""
																	},
																);
																if (id) {
																	history.push(
																		"/account/active-event",
																	);
																}
															}}
															color="default">
															Discard
														</Button>
														<Button
															onClick={handleClose}
															color="default"
															autoFocus>
															Cancel
														</Button>
													</DialogActions>
												</Dialog>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</form>
						</>
					);
				}}
			/>
		</Box>
	);
};
