import React from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";
import { minutesToHours } from "../../BandPage/BandRequest/BandRequest";
import moment from "moment";
import { changeText } from "../BookerEvent/BookerEventItem/EventRequests";
import Button from "@material-ui/core/Button";
import RequestForm from "../../BandPage/BandRequest/RequestForm";
import { BandRequestState } from "../../BandPage/BandRequest/bandRequestSlice";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        margin: {
            margin: "0px",
        },
    })
);

interface IRequestDetails {
    request: any;
    setSelectedEvent: (eventItem: any) => void;
    handleOpen: () => void;
    bandRequest: BandRequestState;
    handleClose: () => void;
    handleBandRequestSubmit: (values: any) => void;
    listBookerEventsList: () => void;
    authentication: any;
}

const RequestView: React.FC<IRequestDetails> = ({
    request,
    setSelectedEvent,
    handleOpen,
    bandRequest,
    handleClose,
    handleBandRequestSubmit,
    listBookerEventsList,
    authentication,
}) => {
    const classes = useStyles();

    const handleEdit = () => {
        setSelectedEvent(request.event);
        handleOpen();
    };

    return (
        <Box pt={2}>
            <Grid container direction="row">
                <Grid item xs={6}>
                    <Grid container direction="row">
                        <Grid item xs={5}>
                            <h4 className={classes.margin}>Event Date</h4>
                        </Grid>
                        <Grid item xs={7}>
                            <p className={classes.margin}>
                                {moment(request.event.date)
                                    .format("MMM DD, YYYY")
                                    .toString()}
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid container direction="row">
                        <Grid item xs={5}>
                            <h4 className={classes.margin}>Start Time</h4>
                        </Grid>
                        <Grid item xs={7}>
                            <p className={classes.margin}>{moment(request.event.start_time, "h:mm:ss").format("h:mm A")}</p>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid container direction="row">
                        <Grid item xs={5}>
                            <h4 className={classes.margin}>Location</h4>
                        </Grid>
                        <Grid item xs={7}>
                            <p className={classes.margin}>{`${request.event.location}, ${request.event.state ? request.event.state.title : ""} `}</p>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid container direction="row">
                        <Grid item xs={5}>
                            <h4 className={classes.margin}>Desired Length</h4>
                        </Grid>
                        <Grid item xs={7}>
                            <p className={classes.margin}>{minutesToHours(request.event.desired_set_length)}</p>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid container direction="row">
                        <Grid item xs={5}>
                            <h4 className={classes.margin}>Number Of Attendees</h4>
                        </Grid>
                        <Grid item xs={7}>
                            <p className={classes.margin}>{request.event.number_of_attendees}</p>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid container direction="row">
                        <Grid item xs={5}>
                            <h4 className={classes.margin}>Venue Description</h4>
                        </Grid>
                        <Grid item xs={7}>
                            <p className={classes.margin}>
                                {request.event.venue_description ? request.event.venue_description.map((item: any) => item.title).join(", ") : ""}
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid container direction="row">
                        <Grid item xs={5}>
                            <h4 className={classes.margin}>Talent Budget</h4>
                        </Grid>
                        <Grid item xs={7}>
                            <p className={classes.margin}>{`$${request.talent_budget}`}</p>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid container direction="row">
                        <Grid item xs={5}>
                            <h4 className={classes.margin}>Production Budget</h4>
                        </Grid>
                        <Grid item xs={7}>
                            <p className={classes.margin}>{`$${request.production_budget}`}</p>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid container direction="row">
                        <Grid item xs={5}>
                            <h4 className={classes.margin}>Event Type</h4>
                        </Grid>
                        <Grid item xs={7}>
                            <p className={classes.margin}>{request.event.event_type && request.event.event_type.title}</p>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid container direction="row">
                        <Grid item xs={5}>
                            <h4 className={classes.margin}>Status</h4>
                        </Grid>
                        <Grid item xs={7}>
                            <p style={{ fontStyle: "italic" }} className={classes.margin}>
                                {changeText(request.status)}
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid container direction="row">
                        <Grid item xs={5}>
                            <h4 className={classes.margin}>Deposit Percentage</h4>
                        </Grid>
                        <Grid item xs={7}>
                            {request.band.deposit}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid container direction="row">
                        <Grid item xs={5}>
                            <h4 className={classes.margin}>Event Description</h4>
                        </Grid>
                        <Grid item xs={7}>
                            <p className={classes.margin}>{request.event.description}</p>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {authentication.role === "BOOKER" && request.status === "OPEN" && (
                <Box mt={2} display="flex" justifyContent="flex-end">
                    <Button onClick={handleEdit} variant="contained" color="secondary">
                        Edit Request
                    </Button>
                </Box>
            )}
            <RequestForm
                band={request.band}
                bandRequest={bandRequest}
                handleClose={handleClose}
                handleBandRequestSubmit={handleBandRequestSubmit}
                listBookerEventsList={listBookerEventsList}
                initialValues={{
                    talent_budget: request.talent_budget,
                    specialNotes: request.notes || "",
                    production_budget: request.production_budget,
                }}
                type="edit"
                id={request.id}
            />
        </Box>
    );
};

export default RequestView;
