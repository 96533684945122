import { ConfrimAccountActionType, IConfrimAccountAction } from "./interfaces";
import { combineReducers } from "redux";

const loginDialogOpen = (
	state: boolean = false,
	action: IConfrimAccountAction,
) => {
	switch (action.type) {
		case ConfrimAccountActionType.LOGIN_DIALOG_OPEN:
			return true;
		case ConfrimAccountActionType.LOGIN_DIALOG_CLOSE:
			return false;
		default:
			return state;
	}
};

const confirmAccountRequest = (
	state: boolean = false,
	action: IConfrimAccountAction,
) => {
	switch (action.type) {
		case ConfrimAccountActionType.CONFIRM_ACCOUNT_REQUEST:
			return true;
		case ConfrimAccountActionType.CONFIRM_ACCOUNT_SUCCESS:
		case ConfrimAccountActionType.CONFIRM_ACCOUNT_FAILURE:
			return false;
		default:
			return state;
	}
};

const confirmAccountSuccess = (
	state: boolean = false,
	action: IConfrimAccountAction,
) => {
	switch (action.type) {
		case ConfrimAccountActionType.CONFIRM_ACCOUNT_SUCCESS:
			return true;
		case ConfrimAccountActionType.CONFIRM_ACCOUNT_REQUEST:
		case ConfrimAccountActionType.CONFIRM_ACCOUNT_FAILURE:
			return false;
		default:
			return state;
	}
};

const confirmAccountFailed = (
	state: boolean = false,
	action: IConfrimAccountAction,
) => {
	switch (action.type) {
		case ConfrimAccountActionType.CONFIRM_ACCOUNT_FAILURE:
			return true;
		case ConfrimAccountActionType.CONFIRM_ACCOUNT_REQUEST:
		case ConfrimAccountActionType.CONFIRM_ACCOUNT_SUCCESS:
			return false;
		default:
			return state;
	}
};

const error = (state: any = null, action: IConfrimAccountAction) => {
	switch (action.type) {
		case ConfrimAccountActionType.CONFIRM_ACCOUNT_FAILURE:
			return action.payload;
		case ConfrimAccountActionType.CONFIRM_ACCOUNT_REQUEST:
		case ConfrimAccountActionType.CONFIRM_ACCOUNT_SUCCESS:
			return null;
		default:
			return state;
	}
};

const confirmAccountRole = (
	state: boolean = false,
	action: IConfrimAccountAction,
) => {
	switch (action.type) {
		case ConfrimAccountActionType.CONFIRM_ACCOUNT_FAILURE:
		case ConfrimAccountActionType.CONFIRM_ACCOUNT_REQUEST:
		case ConfrimAccountActionType.CONFIRM_ACCOUNT_SUCCESS:
			return action.payload || null;
		default:
			return state;
	}
};

export const confirmAccount = combineReducers({
	loginDialogOpen,
	confirmAccountRequest,
	confirmAccountSuccess,
	confirmAccountFailed,
	confirmAccountRole,
	error,
});
