import React from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import makeStyles from "@material-ui/core/styles/makeStyles";
import createStyles from "@material-ui/core/styles/createStyles";

const useStyles = makeStyles((theme) =>
	createStyles({
		textAlign: {
			textAlign: "center",
		},
	}),
);

interface FormTextFieldProps {
	id?: string;
	type?: string;
	textAlignCenter?: boolean;
	value: string | number;
	touched: boolean | undefined;
	error: string | undefined;
	fieldName: string;
	handleChange: any;
	onKeyPress?: any;
}

const dictonary: any = {
	//  Common
	city: "City",
	state: "State",
	zip: "Zip Code",

	//  Card fields
	street: "Billing Address",
	mobile_number: "Phone Number",
	first_name: "First Name",
	last_name: "Last Name",
	credit_card: "Credit Card Number",
	exp_month: "Expiration Date",
	exp_year: "Expiration Date",
	cvv: "CVV",

	//  Account fields
	name: "Name on Account",
	account_number: "Checking Account Number",
	account_type: "Type of Account",
	bank_name: "Name of Bank",
	routing_number: "Routing Number",

	dob_day: 'Day',
	dob_month: 'Month',
	dob_year: 'Year',
	//id_number: "SSN"
};

const fieldLabel = (fieldName: any) => {
	return dictonary[fieldName];
};

export const FormTextField: React.FC<FormTextFieldProps> = ({
	value,
	touched,
	error,
	fieldName,
	handleChange,
	textAlignCenter,
	...rest
}) => {
	const classes = useStyles();

	return (
		<>
			<Grid container direction="column">
				<Grid item>
					<InputLabel htmlFor={fieldName}>{fieldLabel(fieldName)}</InputLabel>
				</Grid>
				<Grid item>
					<TextField
						InputProps={{ classes: { input: textAlignCenter ? classes.textAlign : "" } }}
						id={fieldName}
						name={fieldName}
						variant="outlined"
						color="secondary"
						margin="dense"
						autoComplete="new-password"
						fullWidth={true}
						placeholder={fieldLabel(fieldName)}
						value={value}
						error={touched && Boolean(error)}
						helperText={touched ? error : ""}
						onChange={(e: any) => {
							handleChange(e);
						}}
						{...rest}
					/>
				</Grid>
			</Grid>
		</>
	);
};
