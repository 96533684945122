import React from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import ImagePlaceholder from "../../Assets/ImagePlaceholder.png";
import moment from "moment";
import { IThread } from "./Interfaces";
import makeStyles from "@material-ui/core/styles/makeStyles";
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        unread: {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText,
            "font-weight": "bold",
            borderRadius: "3px",
            fontSize: "13px",
            display: "inline-block",
            padding: "5px 15px"
        },
        message: {
            "word-wrap": "break-word",
            width: "227px",
        },
    })
);

export interface ThreadProps {
    lastMessage: IThread;
    open: boolean;
    role: "BOOKER" | "TALENT" | "AGENT";
    classes: any;
    unread: number[];
}

export const Thread: React.FC<ThreadProps> = ({ lastMessage, role, classes, open, unread }) => {
    const styles = useStyles();

    const hasUnreadMessage = unread.filter((item: number) => Number(item) === Number(lastMessage.id));
    return open ? (
        <Grid container alignItems="center" justify="space-between" spacing={3}>
            <Grid item>
                <Typography>{lastMessage.band.name}</Typography>
            </Grid>
            <Grid item>{lastMessage.last_message ? moment.unix(lastMessage.last_message?.created_at).fromNow() : ""}</Grid>
        </Grid>
    ) : (
        <Grid container alignItems="center" justify="space-between" spacing={1}>
            <Grid item xs={3} md={2} lg={2} >
                {/* <Avatar
                    alt="Band or Booker thumbnail"
                    src={
                        role === "BOOKER"
                            ? lastMessage.band.photo?.thumbnail || ImagePlaceholder
                            : lastMessage.created_by.photo?.thumbnail || ImagePlaceholder
                    }
                    className={role === "BOOKER" ? classes.TALENT : classes.BOOKER}
                /> */}
                <Box display="flex" alignItems="center" justifyContent="center">
                    <Avatar
                        alt="Booker thumbnail"
                        src={lastMessage.band.photo?.thumbnail || ImagePlaceholder}
                        className={classes.BOOKER}
                    />
                    <Avatar
                        alt="Band thumbnail"
                        src={lastMessage.created_by.photo?.thumbnail || ImagePlaceholder}
                        className={classes.TALENT} style={{marginLeft: '-20px'}}
                    />
                </Box>
                
            </Grid>
            <Grid item xs={3} md={3} lg={3}>
                <Typography variant="subtitle2" noWrap style={{marginLeft: '15px'}}>
                    
                    {role === "BOOKER" ? lastMessage.band.name : lastMessage.created_by.fname}
                </Typography>
            </Grid>
            <Grid item xs={4} md={5} lg={5}>
                <Box p={2} display="flex" flexWrap="wrap">
                    <Typography style={{fontSize: '20px'}}>{lastMessage.event.title}</Typography>
                    <Typography variant="body2" className={styles.message}>
                        {lastMessage.last_message?.created_by.role === "REPSY"
                            ? lastMessage.last_message?.message_text.split(".")[0] + "."
                            : lastMessage.last_message?.message_text}
                    </Typography>
                </Box>
            </Grid>
            {hasUnreadMessage.length > 0 ? (
                <Grid item xs={2} md={2} lg={2}>
                   <Typography className={styles.unread}>Unread</Typography>
                </Grid>
            ) : (
                <Grid item xs={2} md={2} lg={2}><Typography style={{fontSize: '13px'}}>{lastMessage.last_message ? moment.unix(lastMessage.last_message?.created_at).fromNow() : ""}</Typography></Grid>
            )}
        </Grid>
    );
};
