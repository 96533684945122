import React, { useEffect, useState } from "react";
import { ISearchFavoriteBandsCombinedReducer } from "./searchFavoritesSlice";
import { Container, CircularProgress, Grid, Button, Box, Typography } from "@material-ui/core";
import FilterBarFavorite from "./filterBarFavorite";
import { IBand } from "../interfaces";
import { BandCard } from "../SearchPage/BandCard";
import ErrorMessage from "../generic/ErrorMessage";
import { IAuthReducer } from "../AuthBase";
import WarningDialog from "../BandPage/BandRequest/WarningDialog";
import ChooseEvent from "../BandPage/BandRequest/ChooseEvent";
import RequestForm from "../BandPage/BandRequest/RequestForm";
import { BandRequestState } from "../BandPage/BandRequest/bandRequestSlice";
import { useStyles } from "../BandPage/BandRequest/BandRequest"

export interface myFavoritesProps {
    myFavoritesState: ISearchFavoriteBandsCombinedReducer;
    authentication: IAuthReducer;
    favoriteToggle: (bandId: number, isFavorite: boolean) => void;
    setLoc: (location: string) => void;
    filterBands: () => void;
    loadMore: () => void;
	isUserApproved: boolean;
    bandRequest: BandRequestState;
    bookerEvents: any;
    handleOpen: () => void;
    handleClose: () => void;
    setSelectedEvent: (eventItem: any) => void;
    handleBandRequestSubmit: (values: any) => void;
    listBookerEventsList: () => void;
    handleOpenWarningModel: () => void;
    handleCloseWarningModel: () => void;
    warningModalOpened: boolean;
}

export const MyFavorites: React.FC<myFavoritesProps> = ({
    myFavoritesState,
    authentication,
    favoriteToggle,
    setLoc,
    filterBands,
    loadMore,
	warningModalOpened,
	handleCloseWarningModel,
	setSelectedEvent,
	handleClose,
	bookerEvents,
	bandRequest,
	handleBandRequestSubmit,
	listBookerEventsList,
	handleOpen,
	isUserApproved,
	handleOpenWarningModel
}) => {
	const classes = useStyles()

	const [selectedBand, setSelectedBand] = useState<IBand>()

    useEffect(() => {
        setLoc("/account/favorites");
        filterBands();
    }, [filterBands, setLoc]);

    const searchBands = myFavoritesState.searchFavoriteBands;
    return (
        <Container maxWidth="lg">
            <Box display="flex" justifyContent="between" my={4}>
                {/* {alert(navigator.userAgent)} */}
                <FilterBarFavorite favoritePage={true} />
            </Box>
            <Grid container spacing={4}>
                {searchBands.bands.map((band: IBand) => (
                    <Grid item xs={12} sm={12} md={6} key={band.id}>
                        <BandCard
                            band={band}
                            authentication={authentication}
                            favoriteToggle={favoriteToggle}
                            isFavouritePage={true}
                            BookArtist={() => {
								setSelectedBand(band)
								isUserApproved ? handleOpen() : handleOpenWarningModel()
							}}
                        />
                    </Grid>
                ))}
                {!searchBands.isBandsRequested && searchBands.count === 0 && (
                    <Grid item xs={12} sm={12} md={12}>
                        <Typography component="h6">No Search Results</Typography>
                    </Grid>
                )}
                {searchBands.error.code && <ErrorMessage error={searchBands.error} />}
            </Grid>
            {!searchBands.isBandsRequested && Number(searchBands.count) > 0 && Number(searchBands.count) > searchBands.bands.length && (
                <Box display="flex" justifyContent="center" m={4}>
                    {searchBands.isLoadMoreRequested ? (
                        <CircularProgress />
                    ) : (
                        <Button variant="contained" color="secondary" onClick={loadMore} disabled={searchBands.isLoadMoreRequested}>
                            Load More Results
                        </Button>
                    )}
                </Box>
            )}
            <WarningDialog warningModalOpened={warningModalOpened} handleCloseWarningModel={handleCloseWarningModel} />
            <ChooseEvent
                setSelectedEvent={setSelectedEvent}
                handleClose={handleClose}
                classes={classes}
                bookerEvents={bookerEvents}
                bandRequest={bandRequest}
            />
            {selectedBand && 
				<RequestForm
					band={selectedBand}
					bandRequest={bandRequest}
					handleClose={handleClose}
					handleBandRequestSubmit={handleBandRequestSubmit}
                    listBookerEventsList={listBookerEventsList}
                    initialValues={{
                        talent_budget: "",
                        specialNotes: "",
                        production_budget: ""
                    }}
                    type="create"
                    id=""
            />}
        </Container>
    );
};
