import React, { useEffect } from "react";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import createStyles from "@material-ui/core/styles/createStyles";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { AgentState } from "../EditAgentProfilePage/editAgentProfileSlice";
import AgentProfileForm from "./AgentProfileForm";
import * as Yup from "yup";
import { useHistory } from "react-router-dom"

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		dialogRoot: {
			border: "1px solid",
			borderColor: theme.palette.secondary.main,
		},
	}),
);

const validationSchema = Yup.object({
	photo: Yup.object().nullable(),
	fname: Yup.string()
		.required("First Name field is required")
		.matches(/^(?!\s*$).+/, { message: "First Name field is required" }),
	lname: Yup.string()
		.required("Last Name field is required")
		.matches(/^(?!\s*$).+/, { message: "Last Name field is required" }),
	phone_number: Yup.string().matches(/^[0-9]{10}$/, {
		message: "Phone number is not valid",
	}),
	phone_number1: Yup.string()
		.required()
		.matches(/^[0-9]{3}$/, { message: "Phone number is not valid" }),
	phone_number2: Yup.string()
		.required()
		.matches(/^[0-9]{3}$/, { message: "Phone number is not valid" }),
	phone_number3: Yup.string()
		.required()
		.matches(/^[0-9]{4}$/, { message: "Phone number is not valid" }),
});

export interface EditAgentProfileProps {
	agentProfile: AgentState;
	user: { username: string; lastLogin: string };
	editAgentProfile: (agentProfile: any) => void;
	getAgentProfile: () => void;
	setPage: (page: string) => void;
	setLoc: (location: string) => void;
	handleSaveClose: () => void;
	handleFailClose: () => void;
}

export const EditAgentProfile: React.FC<EditAgentProfileProps> = ({
	setLoc,
	setPage,
	agentProfile,
	editAgentProfile,
	user,
	getAgentProfile,
	handleSaveClose,
	handleFailClose,
}) => {
	const classes = useStyles();
	const history = useHistory();

	useEffect(() => {
		console.log('Useeffect called');
		getAgentProfile();
		setPage("edit-profile");
		setLoc("/account/edit-profile");
	}, [getAgentProfile, setLoc, setPage]);

	console.log('agentProfile: ', agentProfile);
	const initialValues = {
		photo: (agentProfile.agentProfile && agentProfile.agentProfile.item.photo) || null,
		fname: (agentProfile.agentProfile && agentProfile.agentProfile.item.fname) || "",
		lname: (agentProfile.agentProfile && agentProfile.agentProfile.item.lname) || "",
		phone_number: (agentProfile.agentProfile && agentProfile.agentProfile.item.phone_number) || "",
		phone_number1:
			(agentProfile.agentProfile &&
				agentProfile.agentProfile.item.phone_number &&
				agentProfile.agentProfile.item.phone_number.substring(0, 3)) ||
			"",
		phone_number2:
			(agentProfile.agentProfile &&
				agentProfile.agentProfile.item.phone_number &&
				agentProfile.agentProfile.item.phone_number.substring(3, 6)) ||
			"",
		phone_number3:
			(agentProfile.agentProfile &&
				agentProfile.agentProfile.item.phone_number &&
				agentProfile.agentProfile.item.phone_number.substring(6, 10)) ||
			"",
		organization_name: (agentProfile.agentProfile && agentProfile.agentProfile.item.organization_name) || "",
	};

	return (
		<Box>
			{agentProfile.agentProfileSucceeded && (
				<AgentProfileForm
					initialValues={initialValues}
					handleOnSubmit={(bandProfile: any) => editAgentProfile(bandProfile)}
					validationSchema={validationSchema}
					bandName={`${agentProfile?.agentProfile?.item?.fname} ${agentProfile?.agentProfile?.item?.lname}`}
				/>
			)}
			<Dialog
				fullWidth={true}
				maxWidth="sm"
				scroll="body"
				open={agentProfile.saveModal}
				onClose={handleSaveClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				classes={{ paper: classes.dialogRoot }}>
				<DialogContent>
					<DialogContentText color="textPrimary" id="alert-dialog-description">
						{agentProfile.agentProfile?.item.status === "MISSING_PAYMENT" ? "Your Profile information is saved. You need to complete your billing information before your account can be approved." : "Your Profile info is saved."}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={
							agentProfile.agentProfile?.item.status === "MISSING_PAYMENT"
								? () => {
									handleSaveClose();
									history.push("/account/billing-information");
								}
								: () => handleSaveClose()
						}
						color="default"
						autoFocus>
						{agentProfile.agentProfile?.item.status === "MISSING_PAYMENT" ? "Add Billing Info" : "Ok"}
					</Button>
				</DialogActions>
			</Dialog>
			<Dialog
				fullWidth={true}
				maxWidth="sm"
				scroll="body"
				open={agentProfile.editAgentProfileFailure}
				onClose={handleFailClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				classes={{ paper: classes.dialogRoot }}>
				<DialogContent>
					<DialogContentText color="textPrimary" id="alert-dialog-description">
						Something went wrong, please try again later.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleFailClose} color="default" autoFocus>
						Ok
					</Button>
				</DialogActions>
			</Dialog>
		</Box>
	);
};
