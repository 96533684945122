import React, { useEffect } from "react";
import { Avatar, Box, Button, Dialog, Grid, OutlinedInput, Typography } from "@material-ui/core";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import Services from "../../Services";
import { CustomDialogTitle } from "../DialogTitle/dialog-title";
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		paragraphFont: {
			fontSize: "15px",
			color: "#ddd",
		},
		Link: {
			display: "flex",
			alignItems: "center",
			color: "#0F24FD",
			backgroundColor: "#64FECC",
			padding: "5px 20px",
			fontSize: "1rem",
			fontFamily: "Rubik",
			fontWeight: "bold",
			borderRadius: "4px",
			textDecoration: "none",
			margin: 10,
			height: 35,
			"&:hover": {
				backgroundColor: "#64FECC"
			},
		},
	}),
);


export interface RemoveAgentDialogProps {
    showRemoveagentDialog: any;
    closeRemoveagentDialog: () => void;
	agentData?: any;
}

export const RemoveAgentDialog: React.FC<RemoveAgentDialogProps> = ({
    agentData,
    closeRemoveagentDialog,
    showRemoveagentDialog
}) => {
    const classes = useStyles();
    let history = useHistory();
	const [removeagentDialogOpen, setremoveagentDialogOpen] = React.useState(showRemoveagentDialog);

	useEffect(() => {
	}, [showRemoveagentDialog]);


	const removeRequest = async ()=>{
        
        
        Services.agent.removeAgentByBand(agentData.id).then(response => {
			console.log('removeAgentBand: ', response);
            //setData(response.item)
            closeRemoveagentDialog();
            history.push('/account/agent-requests');
		})
    }
    
    return (
        <Dialog
			fullWidth={true}
			maxWidth="md"
			open={removeagentDialogOpen}
			scroll="body"
			onClose={closeRemoveagentDialog}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<CustomDialogTitle
		onClose={closeRemoveagentDialog}
		id="alert-dialog-title"
	>
		<Typography variant="h4">ARE YOU SURE?</Typography>
		</CustomDialogTitle>
		
		
		<Grid container direction="row" justify="center" alignItems="center">
			<Grid item xs={12} md={12} lg={11} alignItems="center">
				<Typography component="h6" align="center">If you remove this agent, you will have to request to add them again.</Typography>
			</Grid>
		</Grid>
		
		<Box display="flex" flexWrap="wrap" justifyContent="center" py={4} px={5}>
			<Grid container direction="row" justify="center" alignItems="center">
				<Grid item xs={12} md={12} lg={11} alignItems="center" style={{ padding: "10px 0px", textAlign: "center" }}>
					<Button
						aria-label="Search"
						color="secondary"
						variant="outlined"
						className={classes.themeBtn}
						style={{ padding: "4px 20px", border: "2px solid rgba(100, 254, 204, 1.0)", color: "rgba(100, 254, 204, 1.0)", fontWeight:500 }}
						onClick={removeRequest} >
						Yes, remove this agent
					</Button>
					<Button
						aria-label="Search"
						color="secondary"
						variant="contained"
						className={classes.themeBtn}
						style={{ padding: "6px 20px", marginLeft: "15px", fontWeight:500 }}
						onClick={closeRemoveagentDialog} >
						No, keep this agent
					</Button>
				</Grid>
			</Grid>
		</Box>
		</Dialog>
		);
	};
                