import React from "react";
import {
	ExpansionPanel,
	ExpansionPanelSummary,
	Typography,
	ExpansionPanelDetails,
	Grid,
	Button,
	Box,
	Menu,
	MenuItem,
	Avatar,
	DialogActions,
	DialogContentText,
} from "@material-ui/core";
import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import SettingsIcon from "@material-ui/icons/Settings";
import { useTheme } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { IconButton } from "@material-ui/core";
import { EventObject } from "../ListBookerEvents/listBookerEventsSlice";
import { Link } from "react-router-dom";
import moment from "moment";
import WaveBlue from "../../../Assets/WaveBlue.svg";
import WaveGreen from "../../../Assets/WaveGreen.svg";
import { minutesToHours } from "../../../BandPage/BandRequest/BandRequest";
import EventRequests from "./EventRequests"

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		ExpansionPanelSummary: {
			flexDirection: "row-reverse",
		},
		ExpansionPanel: {
			borderBottom: "1px solid rgba(100, 254, 204, 0.5)",
		},
		ExpansionPanelDetails: {
			paddingLeft: "65px",
		},
		iconButton: {
			marginRight: "20px",
		},
		margin: {
			margin: "0px",
		},
		settingsIcon: {
			position: "absolute",
			top: "14px",
			right: "12px",
		},
		marginMenuItem: {
			marginTop: theme.spacing(1),
			fontWeight: "bold",
		},
		dialog: {
			backgroundColor: "transparent",
			border: "none",
		},
		bg: {
			backgroundImage: `url(${WaveBlue})`,
			backgroundSize: "cover",
			backgroundPosition: "center",
			backgroundColor: theme.palette.secondary.main,
		},
		bgFavorite: {
			backgroundImage: `url(${WaveGreen})`,
			backgroundSize: "cover",
			backgroundPosition: "center",
			backgroundColor: theme.palette.primary.main,
		},
		Link: {
			color: "#fff",
			textDecoration: "none",
		},
		letterSpacing: {
			letterSpacing: "3px",
		},
		title: {
			"word-wrap": "break-word",
			maxWidth: "400px",
		},
	}),
);

interface BookerEventItemProps {
	event: EventObject;
	deleteBookerEvent: (eventId: number) => void;
	showEventHistory: () => void;
	status: string;
	setSelectedEvent: (eventItem: any) => void;
	openReviewTalentDialog: (band_id: number, event_id: number, band: any) => void;
}

export const BookerEventItem: React.FC<BookerEventItemProps> = ({
	event,
	deleteBookerEvent,
	showEventHistory,
	status,
	setSelectedEvent,
	openReviewTalentDialog,
}) => {
	const classes = useStyles();

	const theme = useTheme();

	const [open, setOpen] = React.useState<boolean>(false);

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

	const [openCreateRequest, setOpenCreateRequest] = React.useState(false);

	const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

	const [openDelete, setOpenDelete] = React.useState(false);

	return (
		<React.Fragment>
			<ExpansionPanel
				className={classes.ExpansionPanel}
				onChange={(event: object, expanded: boolean) => {
					if (expanded) {
						setOpen(true);
					} else {
						setOpen(false);
					}
				}}>
				<ExpansionPanelSummary
					className={classes.ExpansionPanelSummary}
					IconButtonProps={{
						edge: "start",
						classes: { root: classes.iconButton },
					}}
					expandIcon={<ArrowDropDownIcon fontSize="large" color="secondary" />}
					aria-controls="panel1a-content"
					id="panel1a-header">
					<Grid container direction="row" alignItems="center" justify="space-between">
						<Grid item xs={2} md={1} lg={1}>
							<Avatar alt="Remy Sharp" src={event.photo && event.photo.thumbnail} />
						</Grid>
						<Grid item xs={6} md={6} lg={6}>
							<Typography className={classes.title}>{event.title}</Typography>
						</Grid>
						<Grid item xs={4} md={4} lg={4}>
							{!open && (
								<h4 className={`${classes.margin} ${classes.letterSpacing}`}>
									{moment(event.date, "YYYY.MM.DD")
										.format("LL")
										.toString() + " " +
										moment(event.start_time, "hh:mm").format("hh:mm A").toString()}
								</h4>
							)}
						</Grid>
					</Grid>
				</ExpansionPanelSummary>
				{open && (
					<IconButton
						aria-controls="settings"
						aria-haspopup="true"
						className={classes.settingsIcon}
						onClick={(event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)}>
						<SettingsIcon color="secondary" />
					</IconButton>
				)}
				<Menu
					MenuListProps={{
						disablePadding: true,
					}}
					classes={{
						paper: classes.margin,
					}}
					id="settings"
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={() => setAnchorEl(null)}
					getContentAnchorEl={null}
					anchorOrigin={{
						vertical: "center",
						horizontal: "center",
					}}
					transformOrigin={{
						vertical: "top",
						horizontal: "right",
					}}>
					{/* {status === "ACTIVE" && (
						<MenuItem>
							<Typography variant="body2">Edit Event</Typography>
						</MenuItem>
					)} */}
					<MenuItem>
						<Link to={`/account/create-event/${event.id}`} className={classes.Link}>
							<Typography variant="body2">Duplicate Event</Typography>
						</Link>
					</MenuItem>
					{status === "ACTIVE" && (
						<MenuItem onClick={() => setOpenCreateRequest(true)}>
							<Typography variant="body2">Create Request</Typography>
						</MenuItem>
					)}
					{/* <MenuItem>
						<Typography variant="body2">View Requests</Typography>
					</MenuItem> */}
					<MenuItem>
						<Typography variant="body2" onClick={showEventHistory}>
							View Event History
						</Typography>
					</MenuItem>
					<MenuItem onClick={(e: any) => setOpenDelete(true)}>
						<Typography className={classes.marginMenuItem} variant="body2">
							Delete Event
						</Typography>
					</MenuItem>
					<Dialog
						open={openDelete}
						onClose={() => setOpenDelete(false)}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description">
						<DialogContent>
							<DialogContentText color="textPrimary" id="alert-dialog-description">
								Are you sure you want to delete this event?
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button
								onClick={() => {
									deleteBookerEvent(event.id);
								}}
								color="default">
								Delete
							</Button>
							<Button onClick={() => setOpenDelete(false)} color="default" autoFocus>
								Cancel
							</Button>
						</DialogActions>
					</Dialog>
				</Menu>
				<ExpansionPanelDetails className={classes.ExpansionPanelDetails}>
					<Grid container direction="column" justify="center" spacing={1} wrap="nowrap">
						<Grid item>
							<Grid container direction="row">
								<Grid item xs={3}>
									<h4 className={classes.margin}>Event Name</h4>
								</Grid>
								<Grid item xs={8}>
									<p className={`${classes.margin} ${classes.title}`}>{event.title}</p>
								</Grid>
							</Grid>
						</Grid>
						<Grid item>
							<Grid container direction="row">
								<Grid item xs={3}>
									<h4 className={classes.margin}>Event Date</h4>
								</Grid>
								<Grid item xs={8}>
									<p className={classes.margin}>{moment(event.date, "YYYY.MM.DD").format("LL")}</p>
								</Grid>
							</Grid>
						</Grid>
						<Grid item>
							<Grid container direction="row">
								<Grid item xs={3}>
									<h4 className={classes.margin}>Event Location</h4>
								</Grid>
								<Grid item xs={8}>
									<p className={`${classes.margin} ${classes.title}`}>{`${event.location}, ${event.state.title} `}</p>
								</Grid>
							</Grid>
						</Grid>
						<Grid item>
							<Grid container direction="row">
								<Grid item xs={3}>
									<h4 className={classes.margin}>Event Description</h4>
								</Grid>
								<Grid item xs={8}>
									<p className={classes.margin}>{event.description}</p>
								</Grid>
							</Grid>
						</Grid>
						<Grid item>
							<Grid container direction="row">
								<Grid item xs={3}>
									<h4 className={classes.margin}>Venue Description</h4>
								</Grid>
								<Grid item xs={8}>
									<p className={classes.margin}>
										{event.venue_description.map((item) => item.title).join(", ")}
									</p>
								</Grid>
							</Grid>
						</Grid>
						{event.event_type && (
							<Grid item>
								<Grid container direction="row">
									<Grid item xs={3}>
										<h4 className={classes.margin}>Event Type</h4>
									</Grid>
									<Grid item xs={8}>
										<p className={classes.margin}>{event.event_type && event.event_type.title}</p>
									</Grid>
								</Grid>
							</Grid>
						)}
						{event.start_time && (
							<Grid item>
								<Grid container direction="row">
									<Grid item xs={3}>
										<h4 className={classes.margin}>Start Time</h4>
									</Grid>
									<Grid item xs={8}>
										<p className={classes.margin}>
											{moment(event.start_time, "h:mm:ss").format("h:mm A")}
										</p>
									</Grid>
								</Grid>
							</Grid>
						)}
						<Grid item>
							<Grid container direction="row">
								<Grid item xs={3}>
									<h4 className={classes.margin}>Desired Length</h4>
								</Grid>
								<Grid item xs={8}>
									<p className={classes.margin}>{minutesToHours(event.desired_set_length)}</p>
								</Grid>
							</Grid>
						</Grid>
						<Grid item>
							<Grid container direction="row">
								<Grid item xs={3}>
									<h4 className={classes.margin}>Number of Attendees</h4>
								</Grid>
								<Grid item xs={8}>
									<p className={classes.margin}>{event.number_of_attendees}</p>
								</Grid>
							</Grid>
						</Grid>
						{status === "ACTIVE" && (
							<Grid item>
								<Box mt={2} display="flex" flexDirection="row-reverse">
									<Button
										size="small"
										variant="contained"
										color="secondary"
										onClick={() => setOpenCreateRequest(true)}>
										Create Request
									</Button>
								</Box>
								<Dialog
									fullWidth={fullScreen}
									open={openCreateRequest}
									onClose={() => setOpenCreateRequest(false)}
									aria-labelledby="responsive-dialog-title"
									classes={{
										paperScrollPaper: classes.dialog,
									}}>
									<DialogContent>
										<Box
											display="flex"
											textAlign="center"
											justifyContent="center"
											alignItems="center"
											height={175}
											width={fullScreen ? "100%" : 425}
											fontWeight={800}
											fontFamily="Rubik"
											fontSize="30px"
											className={classes.bg}>
											<Link to="/search" className={classes.Link} onClick={() => setSelectedEvent(event)}>
												Search for a Band
											</Link>
										</Box>
										<Box
											display="flex"
											textAlign="center"
											justifyContent="center"
											alignItems="center"
											height={175}
											width={fullScreen ? "100%" : 425}
											fontWeight={800}
											fontFamily="Rubik"
											fontSize="30px"
											mt={3}
											className={classes.bgFavorite}>
											<Link to="/account/favorites" className={classes.Link} onClick={() => setSelectedEvent(event)}>
												Choose from a Favorite
											</Link>
										</Box>
									</DialogContent>
								</Dialog>
								{/* <BookerEventDialog
									opened={event.eventEditModalOpened}
									titleForm="Edit an Event"
									handleClose={() => handleClose(event.id)}
									handleBookerEventSubmit={updateBookerEvent}
									values={event}
								/> */}
								{/* onClick={() => handleOpen(event.id)} */}
							</Grid>
						)}
						<Grid item>
							<EventRequests status={status} openReviewTalentDialog={openReviewTalentDialog} id={event.id} />
						</Grid>
					</Grid>
				</ExpansionPanelDetails>
			</ExpansionPanel>
		</React.Fragment>
	);
};
