import { rgbToHex } from "@material-ui/core/styles";

export const styles = theme => ({
    cropContainer: {
      position: 'relative',
      width: '100%',
      height: 200,
      background: '#333',
      [theme.breakpoints.up('sm')]: {
        height: 400,
      },
    },
    cropButton: {
      flexShrink: 0,
      marginLeft: 16,
    },
    controls: {
      padding: 16,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
        alignItems: 'center',
      },
    },
    sliderContainer: {
      display: 'flex',
      flex: '1',
      alignItems: 'center',
    },
    sliderLabel: {
      [theme.breakpoints.down('xs')]: {
        minWidth: 65,
      },
    },
    slider: {
      padding: '22px 0px',
      marginLeft: 16,
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
        alignItems: 'center',
        margin: '0 16px',
      },
    },

    overlayButton:{
      position: 'relative'
    },
    overlayCropButton:{
      position: 'absolute',
      right: '15px',
      bottom: '15px',
      backgroundColor: 'rgba(255,255,255,0.3)',
      color: '#fff',
      padding: '5px 15px',
      fontWeight: '500'
    },

    overlayRepositionButton:{
      position: 'absolute',
      left: '15px',
      bottom: '15px',
      backgroundColor: 'rgba(255,255,255,0.3)',
      color: '#fff',
      padding: '5px 15px',
      fontWeight: '500'

    },
    overlayDropZoneUploadButton:{
      position: 'absolute',
      right: '15px',
      bottom: '15px',
      padding: '5px 15px',
      fontWeight: '500'
    },
    displayImage:{
      maxWidth: '100%'
    },

    closeShowImgButton: {
      position: 'absolute',
      right: '15px',
      top: '15px',
      cursor: "pointer"
    }


  })  