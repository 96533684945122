import { ResendActivationLinkActionType, IResendActivationLinkActions } from "./interfaces";
import { combineReducers } from "redux";

const resendActivationLinkModalOpened = (
	state: boolean = false,
	action: IResendActivationLinkActions,
) => {
	switch (action.type) {
		case ResendActivationLinkActionType.RESEND_ACTIVATION_LINK_MODAL_OPEN:
			return true;
		case ResendActivationLinkActionType.RESEND_ACTIVATION_LINK_MODAL_CLOSE:
			return false;
		default:
			return state;
	}
};

const resendActivationLinkRequest = (
	state: boolean = false,
	action: IResendActivationLinkActions,
) => {
	switch (action.type) {
		case ResendActivationLinkActionType.RESEND_ACTIVATION_LINK_REQUEST:
			return true;
		case ResendActivationLinkActionType.RESEND_ACTIVATION_LINK_MODAL_OPEN:
		case ResendActivationLinkActionType.RESEND_ACTIVATION_LINK_SUCCESS:
		case ResendActivationLinkActionType.RESEND_ACTIVATION_LINK_FAILURE:
			return false;
		default:
			return state;
	}
};

const resendActivationLinkSuccess = (
	state: boolean = false,
	action: IResendActivationLinkActions,
) => {
	switch (action.type) {
		case ResendActivationLinkActionType.RESEND_ACTIVATION_LINK_SUCCESS:
			return true;
		case ResendActivationLinkActionType.RESEND_ACTIVATION_LINK_MODAL_OPEN:
		case ResendActivationLinkActionType.RESEND_ACTIVATION_LINK_REQUEST:
		case ResendActivationLinkActionType.RESEND_ACTIVATION_LINK_FAILURE:
			return false;
		default:
			return state;
	}
};

const error = (state = null, action: IResendActivationLinkActions) => {
	switch (action.type) {
		case ResendActivationLinkActionType.RESEND_ACTIVATION_LINK_FAILURE:
			return action.payload;
		case ResendActivationLinkActionType.RESEND_ACTIVATION_LINK_MODAL_OPEN:
		case ResendActivationLinkActionType.RESEND_ACTIVATION_LINK_REQUEST:
		case ResendActivationLinkActionType.RESEND_ACTIVATION_LINK_SUCCESS:
			return null;
		default:
			return state;
	}
};

export const resendActivationLink = combineReducers({
	resendActivationLinkModalOpened,
	resendActivationLinkRequest,
	resendActivationLinkSuccess,
	error,
});
