import { refineResponse } from "../helpers/refineResponse";
import { headers, baseUrl } from "../../AuthBase/helpers/auth-header";

export const editAgentProfile = (agentProfile: object) => {
	const requestOptions = {
		method: "POST",
		headers: { ...headers() },
		body: JSON.stringify(agentProfile),
	};

	return fetch(`${baseUrl}agent/update`, requestOptions).then(
		refineResponse,
	);
};

export const getAgentProfile = () => {
	const requestOptions = {
		method: "POST",
		headers: { ...headers() },
		body: JSON.stringify({}),
	};
	console.log('getAgentProfile: ')
	return fetch(`${baseUrl}users/details`, requestOptions).then(
		refineResponse,
	);
};

export const getTalentsAgentProfile = () => {
	const requestOptions = {
		method: "POST",
		headers: { ...headers() },
		body: JSON.stringify({}),
	};
	return fetch(`${baseUrl}talent/agent`, requestOptions).then(
		refineResponse,
	);
};

export const agentBandRequest = (bandId: number) => {
	const requestOptions = {
		method: "POST",
		headers: { ...headers() },
		body: JSON.stringify({band_id: bandId}),
	};
	return fetch(`${baseUrl}agent/request-band`, requestOptions).then(
		refineResponse,
	);
};

export const countTalentsAgentRequest = () => {
	const requestOptions = {
		method: "GET",
		headers: { ...headers() }
	};
	return fetch(`${baseUrl}count/agents-requests`, requestOptions).then(
		refineResponse,
	);
};

export const listTalentsAgent = () => {
	const requestOptions = {
		method: "GET",
		headers: { ...headers() }
	};
	return fetch(`${baseUrl}list/myagent`, requestOptions).then(
		refineResponse,
	);
};

export const talentsAgentRequestList = () => {
	const requestOptions = {
		method: "GET",
		headers: { ...headers() }
	};
	return fetch(`${baseUrl}list/agents-requests`, requestOptions).then(
		refineResponse,
	);
};

export const removeAgentBand = (bandId: number) => {
	console.log('agentBandRequest:');
	const requestOptions = {
		method: "PUT",
		headers: { ...headers() }
	};
	return fetch(`${baseUrl}unlink/band/${bandId}`, requestOptions).then(
		refineResponse,
	);
};

export const removeAgentByBand = (agentId: number) => {
	console.log('agentBandRequest:');
	const requestOptions = {
		method: "PUT",
		headers: { ...headers() }
	};
	return fetch(`${baseUrl}unlink/agent/${agentId}`, requestOptions).then(
		refineResponse,
	);
};


export const approveBandAgent = (agentID: number) => {
	console.log('agentBandRequest:');
	const requestOptions = {
		method: "PUT",
		headers: { ...headers() }
	};
	return fetch(`${baseUrl}agent-request/approve/${agentID}`, requestOptions).then(
		refineResponse,
	);
};

export const rejectAgentRequet = (agentID: number) => {
	console.log('rejectAgentRequet:');
	const requestOptions = {
		method: "PUT",
		headers: { ...headers() }
	};
	return fetch(`${baseUrl}agent-request/reject/${agentID}`, requestOptions).then(
		refineResponse,
	);
};

export const sendInvitationToBand = (emailaddress: string) => {
	console.log('agentBandRequest:');
	const requestOptions = {
		method: "POST",
		headers: { ...headers() },
		body: JSON.stringify({emailaddress : emailaddress}),
	};
	return fetch(`${baseUrl}invite/band`, requestOptions).then(
		refineResponse,
	);
};

export const sendInvitationToAgent = (emailaddress: string) => {
	const requestOptions = {
		method: "POST",
		headers: { ...headers() },
		body: JSON.stringify({emailaddress : emailaddress}),
	};
	return fetch(`${baseUrl}invite/agent`, requestOptions).then(
		refineResponse,
	);
};

export const getAgentBandBadgeCount = () => {
	const requestOptions = {
		method: "GET",
		headers: { ...headers() }
	};
	return fetch(`${baseUrl}agent/agent-badge-count`, requestOptions).then(
		refineResponse,
	);
};

export const updateAgentBandRequest = (updateData: string) => {
	console.log('agentBandRequest:');
	const requestOptions = {
		method: "GET",
		headers: { ...headers() }
	};
	return fetch(`${baseUrl}update/band-request-status${updateData}`, requestOptions).then(
		refineResponse,
	);
};

export const listEventsByMonth = (month: number, year: number) => {
	const requestOptions = {
		method: "GET",
		headers: { ...headers() }
	};
	return fetch(`${baseUrl}agent/event-list-by-month/${month}/${year}`, requestOptions).then(
		refineResponse,
	);
};

export const listEventsByDate = (strDate: string) => {
	const requestOptions = {
		method: "GET",
		headers: { ...headers() }
	};
	return fetch(`${baseUrl}agent/event-list-by-date/${strDate}`, requestOptions).then(
		refineResponse,
	);
};

export const dashboardRequestWidget = () => {
	const requestOptions = {
		method: "GET",
		headers: { ...headers() }
	};
	return fetch(`${baseUrl}calender/request_widget_list`, requestOptions).then(
		refineResponse,
	);
};

export const dashboardRequestWidgetGetEventsByStatus = (status: string) => {
	const requestOptions = {
		method: "GET",
		headers: { ...headers() }
	};
	return fetch(`${baseUrl}calender/request_widget_event_list/${status}`, requestOptions).then(
		refineResponse,
	);
};

export const dashboardNotificationWidgetGetMessageList = (page: number) => {
	const requestOptions = {
		method: "GET",
		headers: { ...headers() }
	};
	return fetch(`${baseUrl}calender/widget_messages_list?page=${page}`, requestOptions).then(
		refineResponse,
	);
};

export const dashboardNotificationWidgetGetRequestList = (page: number) => {
	const requestOptions = {
		method: "GET",
		headers: { ...headers() }
	};
	return fetch(`${baseUrl}calender/request_widget_band_request_list?page=${page}`, requestOptions).then(
		refineResponse,
	);
};


export const dashboardNotificationWidgetGetUpdateStatusList = (page: number) => {
	const requestOptions = {
		method: "GET",
		headers: { ...headers() }
	};
	return fetch(`${baseUrl}calender/widget_band_status_update_list?page=${page}`, requestOptions).then(
		refineResponse,
	);
};


export const updateAgentRequestLastSeen = (requestId: number) => {
	const requestOptions = {
		method: "POST",
		headers: { ...headers() },
		body: JSON.stringify({request_band_id : requestId}),
	};
	return fetch(`${baseUrl}agent/update_event_last_seen`, requestOptions).then(
		refineResponse,
	);
};


// export const uploadMedia = (file: any) => {
//     const formData = new FormData();
//     formData.append('media', file);
//     const body = formData;
//     return fetch(`${baseUrl}media/upload`, {
//         method: 'POST',
//         body,
//         headers: { ...headers() },
//     });
// };
