import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { IFilterBandsProps } from "./interfaces";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";
import Grid from "@material-ui/core/Grid";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import { ArrowDropDown } from "@material-ui/icons";
import GenresFilter from "./GenreFilter";
import PricesFilter from "./PriceFilter";
import DatesFilter from "./DateFilter";
import Sort from "./Sort";
import MoreFilters from "./MoreFilters";
import searchBarIcon from "../../Assets/Search Bar Icon.svg";
import priceIcon from "../../Assets/Price.svg";
import genreIcon from "../../Assets/Genre.svg";
import calendarIcon from "../../Assets/Calendar.svg";
import Chip from "@material-ui/core/Chip";
import moment from "moment";
import { IsLoggedIn, IsAnonymous, IsBooker, IsTalent, IsAgent, IsTalentBooker, IsTalentAgent } from "../../AuthBase/";
import { RouteComponentProps } from "react-router-dom";
import Tooltip from '@material-ui/core/Tooltip';

interface ChipData {
	day: string;
}

const BlueTopTooltip = withStyles({
	tooltip: {
	  color: "#ffffff",
	  backgroundColor: "#0F24FD",
	  fontSize: '1.2em',
	  fontWeight: 'bold',
	  marginBottom: '0px',
	  marginTop: '5px',
	  padding: '6px 15px 7px 15px',
	}
  })(Tooltip);

class FilterBands extends React.Component<IFilterBandsProps> {
	public searchbuttonRef: React.RefObject<any>;
	public state = {
		pricesPopover: null,
		genresPopover: null,
		datesPopover: null,
		sizePopover: null,
		locationPopover: null,
		moreFilters: null,
		sort: null

	};

	constructor(props: IFilterBandsProps) {
		super(props);
		this.handlePopoverClose = this.handlePopoverClose.bind(this);
		this.searchbuttonRef = React.createRef();

		// Autofill search text box from querystring -- start
		let query = new URLSearchParams(window.location.search);
		const searchTextQuery = query.get('search');
		const searchText = searchTextQuery == null ? "" : searchTextQuery;
		if(searchText !== ""){
			this.props.searchText(searchText);
			var uri = window.location.toString();
			if (uri.indexOf("?") > 0) {
				var clean_uri = uri.substring(0, uri.indexOf("?"));
				window.history.replaceState({}, document.title, clean_uri);
			}
		}
		// Autofill search text box from querystring -- end
		
	}

	public handleKeyDown = (e: any) => {
		if (e.key === "Enter") {
			this.searchbuttonRef.current.focus();
		}
	};

	public handlePopoverClick = (name: string) => (e: any) => {
		this.setState({
			[name]: e.currentTarget,
		});
	};

	public handlePopoverClose = (name: string) => (e: any) => {
		this.setState({
			[name]: null,
		});
	};

	public onChange = (e: any) => {
		this.props.handleKeywordChange(e);
		setTimeout(this.props.search, 500);
	};

	public handleDelete = (chipToDelete: ChipData) => () => {
		const days = this.props.filters.days.filter((day: any) => {
			return day.day !== chipToDelete.day;
		});
		const formatedDays = days.map((x: any) => moment(x.day, "YYYY-MM-DD").format("ddd MMM DD YYYY HH:mm:ss"));
		this.props.saveDates(formatedDays);
	};

	public handleDeleteGenre = (chipToDelete: { id: number; title: string }) => () => {
		const x = this.props.filters.genres.filter((item: { id: number; title: string }) => {
			return item !== chipToDelete;
		});
		this.props.saveGenres(x);
	};

	public render() {
		const { filters, resources, search, sort, openSignupModal } = this.props;
		//const query = new URLSearchParams(this.props.location.search);

		let favoritePage = false;
		if (this.props.favoritePage) {
			favoritePage = true;
		}
		return (
			<Grid container direction="column" spacing={1}>
				<Grid item>
					<Grid container direction="row" justify="space-between" alignItems="center">
						<Grid item xs={12} md={favoritePage ? 6 : 4}>
							<OutlinedInput
								labelWidth={0}
								placeholder="Filter bands"
								onChange={this.onChange}
								onKeyDown={this.handleKeyDown}
								value={filters.text}
								fullWidth={true}
								margin="dense"
								endAdornment={
									<IconButton
										color="primary"
										size="medium"
										aria-label="Search"
										onClick={search}
										ref={this.searchbuttonRef}>
										<Icon fontSize="large">
											<img alt="search icon" src={searchBarIcon} />
										</Icon>
									</IconButton>
								}
							/>
						</Grid>
						{favoritePage && (
							<Grid item>
								<Typography variant="body1">Filter by:</Typography>
							</Grid>
						)}
						<Grid item>
						
							<Button
								size="large"
								variant="outlined"
								color="secondary"
								disableRipple={true}
								aria-describedby={"genresPopover"}
								onClick={this.handlePopoverClick("genresPopover")}>
								<Typography color="textPrimary" component="span" variant="subtitle2">
									Genre
								</Typography>
								<img height="30em" src={genreIcon} alt="music icon" style={{ paddingLeft: "15px" }} />
							</Button>
							<Popover
								marginThreshold={0}
								open={Boolean(this.state.genresPopover)}
								anchorEl={this.state.genresPopover}
								onClose={this.handlePopoverClose("genresPopover")}
								anchorOrigin={{
									vertical: "bottom",
									horizontal: "center",
								}}
								transformOrigin={{
									vertical: "top",
									horizontal: "center",
								}}>
								<GenresFilter
									saveGenres={this.props.saveGenres}
									genres={resources.genres}
									values={filters.genres}
									handlePopoverClose={this.handlePopoverClose("genresPopover")}
								/>
							</Popover>
						</Grid>
						
						<Grid item className="tooltip-overlay">
						<IsAnonymous>
							<BlueTopTooltip 
							interactive 
							title={
								<React.Fragment>
									<Link onClick={openSignupModal} style={{color: '#ffffff',textDecoration:'underline', cursor: 'pointer'}}>Sign up</Link> to view pricing.
								</React.Fragment>
								} placement="bottom">
								<Button
									size="large"
									variant="outlined"
									color="secondary"
									disableRipple={true}
									aria-describedby={"pricesPopover"}>
									<Typography color="textPrimary" component="span" variant="subtitle2">
										Price
									</Typography>
									<img height="30em" src={priceIcon} alt="price icon" style={{ paddingLeft: "15px" }} />
								</Button>
								</BlueTopTooltip>
						</IsAnonymous>
						<IsLoggedIn>
							<Button
									size="large"
									variant="outlined"
									color="secondary"
									disableRipple={true}
									aria-describedby={"pricesPopover"}
									onClick={this.handlePopoverClick("pricesPopover")}>
									<Typography color="textPrimary" component="span" variant="subtitle2">
										Price
									</Typography>
									<img height="30em" src={priceIcon} alt="price icon" style={{ paddingLeft: "15px" }} />
								</Button>
						</IsLoggedIn>
							
							<Popover
								open={Boolean(this.state.pricesPopover)}
								anchorEl={this.state.pricesPopover}
								onClose={this.handlePopoverClose("pricesPopover")}
								anchorOrigin={{
									vertical: "bottom",
									horizontal: "center",
								}}
								transformOrigin={{
									vertical: "top",
									horizontal: "center",
								}}>	

									
							
								<PricesFilter
									savePrices={this.props.savePrices}
									values={{
										from: filters.price_from,
										to: filters.price_to,
									}}
									handlePopoverClose={this.handlePopoverClose("pricesPopover")}
								/>
								

								

							</Popover>
						</Grid>
						{!favoritePage && (
							<Grid item>
								<Button
									size="large"
									variant="outlined"
									color="secondary"
									disableRipple={true}
									aria-describedby={"datesPopover"}
									onClick={this.handlePopoverClick("datesPopover")}>
									<Typography color="textPrimary" component="span" variant="subtitle2">
										Date
									</Typography>
									<img
										height="30em"
										src={calendarIcon}
										alt="calendar icon"
										style={{ paddingLeft: "15px" }}
									/>
								</Button>

								<Popover
									open={Boolean(this.state.datesPopover)}
									anchorEl={this.state.datesPopover}
									onClose={this.handlePopoverClose("datesPopover")}
									anchorOrigin={{
										vertical: "bottom",
										horizontal: "center",
									}}
									transformOrigin={{
										vertical: "top",
										horizontal: "center",
									}}>
									<DatesFilter
										saveDates={this.props.saveDates}
										values={filters.days}
										handlePopoverClose={this.handlePopoverClose("datesPopover")}
									/>
								</Popover>
							</Grid>
						)}
						{!favoritePage && (
							<Grid item>
								<Link
									variant="button"
									color="textPrimary"
									component="button"
									aria-describedby={"moreFilters"}
									onClick={this.handlePopoverClick("moreFilters")}>
									More Filters
								</Link>

								<Popover
									open={Boolean(this.state.moreFilters)}
									anchorEl={this.state.moreFilters}
									onClose={this.handlePopoverClose("moreFilters")}
									anchorOrigin={{
										vertical: "bottom",
										horizontal: "center",
									}}
									transformOrigin={{
										vertical: "top",
										horizontal: "center",
									}}>
									<MoreFilters
										saveSize={this.props.saveSize}
										saveFavorite={this.props.saveFavorite}
										saveLocation={this.props.saveLocation}
										search={this.props.search}
										clearLocation={this.props.clearLocation}
										clearSize={this.props.clearSize}
										sizeValue={filters.size}
										locationValue={filters.location}
										filters={filters}
										states={resources.states}
										isFavorite={filters.favorite}
										favoritePage={favoritePage}
										handlePopoverClose={this.handlePopoverClose("moreFilters")}
									/>
								</Popover>
							</Grid>
						)}
						{!favoritePage && (
							<Grid item xs={4} md={2}>
								<Link
									style={{
										width: "100%",
									}}
									variant="button"
									color="textPrimary"
									component="button"
									aria-describedby={"sort"}
									onClick={this.handlePopoverClick("sort")}>
									<Box
										component="span"
										style={{
											width: "calc(100% - 20px)",
											float: "left",
											overflow: "hidden",
											whiteSpace: "nowrap",
											textOverflow: "ellipsis",
										}}>
										{sort.sortText === null ? "Featured" : sort.sortText}
									</Box>
									<ArrowDropDown
										style={{
											float: "right",
											width: "18px",
											height: "23px",
										}}
									/>
								</Link>
								<Popover
									open={Boolean(this.state.sort)}
									anchorEl={this.state.sort}
									onClose={this.handlePopoverClose("sort")}
									anchorOrigin={{
										vertical: "bottom",
										horizontal: "center",
									}}
									transformOrigin={{
										vertical: "top",
										horizontal: "center",
									}}>
									<Sort
										setSort={this.props.setSort}
										setSortText={this.props.setSortText}
										value={{
											by: sort.sort.by,
											dir: sort.sort.dir,
										}}
										handlePopoverClose={this.handlePopoverClose("sort")}
									/>
								</Popover>
							</Grid>
						)}
					</Grid>
				</Grid>
				<Grid item>
					{filters.genres &&
						filters.genres.map((item: { id: number; title: string }, index: number) => {
							return (
								<Chip
									variant="outlined"
									color="secondary"
									onDelete={this.handleDeleteGenre(item)}
									key={index}
									label={item.title}
								/>
							);
						})}
					{filters.price_from !== null && (
						<Chip
							variant="outlined"
							color="secondary"
							onDelete={() => {
								this.props.savePrices({
									from: null,
									to: filters.price_to,
								});
							}}
							label={`Price From: ${filters.price_from}`}
						/>
					)}
					{filters.price_to !== null && (
						<Chip
							variant="outlined"
							color="secondary"
							onDelete={() => {
								this.props.savePrices({
									from: filters.price_from,
									to: null,
								});
							}}
							label={`Price To: ${filters.price_to}`}
						/>
					)}
					{filters.days &&
						filters.days.map((day: any, index: number) => {
							return (
								<Chip
									variant="outlined"
									color="secondary"
									onDelete={this.handleDelete(day)}
									key={index}
									label={moment(day.day, "YYYY.MM.DD").format("LL")}
								/>
							);
						})}
					{filters.size !== null && (
						<Chip
							variant="outlined"
							color="secondary"
							onDelete={() => {
								this.props.clearSize();
							}}
							label={`band size: ${filters.size}`}
						/>
					)}
					{filters.location !== null && filters.location.id !== null && (
						<Chip
							variant="outlined"
							color="secondary"
							onDelete={() => {
								this.props.clearLocation();
							}}
							label={`Location: ${resources.states.items[filters.location.id].title}`}
						/>
					)}
					{filters.favorite !== false && !this.props.favoritePage && (
						<Chip
							variant="outlined"
							color="secondary"
							onDelete={() => {
								this.props.clearFavorite();
							}}
							label={`Is Favorite: ${filters.favorite}`}
						/>
					)}
				</Grid>
			</Grid>
		);
	}
}

export default FilterBands;
