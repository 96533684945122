import { createSlice } from "redux-starter-kit";
import Services from "../../../generic/Services";
import { EventObject } from "../../BookerEvent/ListBookerEvents/listBookerEventsSlice";
import { IBand } from "../../../interfaces";

export interface RequestObject {
	id: number;
	event_id: number;
	band_id: number;
	created_by: {
		id: number;
		email: string;
		fname: string;
		lname: string;
	};
	notes: string;
	production_budget: string;
	talent_budget: string;
	status: string;
	created_at: string;
	talent_last_seen: null;
	booker_last_seen: null;
	payment_amount: null;
	band: IBand;
	event: EventObject;
}

export interface ListTalentEventState {
	listEventsRequested: boolean;
	listEventsSucceeded: boolean;
	listEventsFailure: boolean;

	deleteRequestRequested: boolean;
	deleteRequestSucceeded: boolean;
	deleteRequestFailure: boolean;

	requestHistoryRequested: boolean;
	requestHistorySucceeded: boolean;
	requestHistoryFailure: boolean;

	loadMoreRequested: boolean;
	loadMoreSucceeded: boolean;
	pastLoadMoreSucceeded: boolean;
	loadMoredFailure: boolean;

	confirmRequestLoading: boolean;

	requestHistory: any;

	textFilter: string;

	pastTextFilter: string;

	requests: {
		items: RequestObject[] | null;
		userMessage: string;
		total_items: number;
		page: number;
	};

	error: {
		code: number;
		message: string;
		errors: [];
	};
}

const initialState: ListTalentEventState = {
	listEventsRequested: false,
	listEventsSucceeded: false,
	listEventsFailure: false,

	deleteRequestRequested: false,
	deleteRequestSucceeded: false,
	deleteRequestFailure: false,

	requests: {
		items: null,
		userMessage: "",
		total_items: 0,
		page: 1,
	},

	requestHistoryRequested: false,
	requestHistorySucceeded: false,
	requestHistoryFailure: false,

	loadMoreRequested: false,
	loadMoreSucceeded: false,
	pastLoadMoreSucceeded: false,
	loadMoredFailure: false,

	confirmRequestLoading: false,

	requestHistory: {},

	textFilter: "",

	pastTextFilter: "",

	error: {
		code: 0,
		message: "",
		errors: [],
	},
};

const listTalentEventsSlice = createSlice({
	slice: "listTalentEvent",
	initialState,
	reducers: {
		listEventsRequested(state, action) {
			state.listEventsRequested = true;
			state.listEventsSucceeded = false;
			state.listEventsFailure = false;
		},
		listEventsSucceeded(state, action) {
			state.listEventsRequested = false;
			state.listEventsSucceeded = true;
			state.listEventsFailure = false;
			state.requests = action.payload;
			state.error = {
				code: 0,
				message: "",
				errors: [],
			};
		},
		listEventsFailure(state, action) {
			state.listEventsRequested = false;
			state.listEventsSucceeded = false;
			state.listEventsFailure = true;
			state.error = action.payload;
		},

		deleteRequestRequested(state, action) {
			state.deleteRequestRequested = true;
			state.deleteRequestSucceeded = false;
			state.deleteRequestFailure = false;
		},
		deleteRequestSucceeded(state, action) {
			state.deleteRequestRequested = false;
			state.deleteRequestSucceeded = true;
			state.deleteRequestFailure = false;
			if (state.requests && state.requests.items) {
				state.requests.items = state.requests.items.filter(
					(item) => item.id !== action.payload.item[0].id,
				);
			}
			state.requests.total_items = state.requests.total_items - 1;
			state.error = {
				code: 0,
				message: "",
				errors: [],
			};
		},
		deleteRequestFailure(state, action) {
			state.deleteRequestRequested = false;
			state.deleteRequestSucceeded = false;
			state.deleteRequestFailure = true;
			state.error = action.payload;
		},
		requestHistoryRequested(state, action) {
			state.requestHistoryRequested = true;
			state.requestHistorySucceeded = false;
			state.requestHistoryFailure = false;
		},
		requestHistorySucceeded(state, action) {
			state.requestHistoryRequested = false;
			state.requestHistorySucceeded = true;
			state.requestHistoryFailure = false;
			state.requestHistory = action.payload;
			state.error = {
				code: 0,
				message: "",
				errors: [],
			};
		},

		loadMoreRequested(state, action) {
			state.loadMoreRequested = true;
			state.loadMoreSucceeded = false;
			state.loadMoredFailure = false;
		},
		loadMoreSucceeded(state, action) {
			state.loadMoreRequested = false;
			state.loadMoreSucceeded = true;
			state.loadMoredFailure = false;
			if (state.requests.items) {
				state.requests.items = [
					...state.requests.items,
					...action.payload.items,
				];
			}
			state.requests.page = state.requests.page + 1;
			state.error = {
				code: 0,
				message: "",
				errors: [],
			};
		},
		loadMoreFailure(state, action) {
			state.loadMoreRequested = false;
			state.loadMoreSucceeded = false;
			state.loadMoredFailure = true;
			state.error = action.payload;
		},

		requestHistoryFailure(state, action) {
			state.requestHistoryRequested = false;
			state.requestHistorySucceeded = false;
			state.requestHistoryFailure = true;
			state.error = action.payload;
		},

		changeRequestStatusRequested(state, action) {
			state.confirmRequestLoading = true;
		},
		//  on Confirm Success, replace object from API with new Status with the object in state
		confirmRequestSucceeded(state, action) {
			state.confirmRequestLoading = false;
			if (state.requests.items) {
				state.requests.items = state.requests.items.map(
					(item: RequestObject) => {
						if (item.id === action.payload.item.id) {
							return action.payload.item;
						}
						return item;
					},
				);
			}
		},
		// on Reject Success, remove the object from the active requests list
		rejectRequestSucceeded(state, action) {
			state.confirmRequestLoading = false;
			if (state.requests.items) {
				state.requests.items = state.requests.items.filter(
					(item: RequestObject) => item.id !== action.payload.item.id,
				);
			}
			state.requests.total_items = state.requests.total_items - 1;
		},
		// on Cancel Success, remove the object from the active requests list
		cancelRequestSucceeded(state, action) {
			state.confirmRequestLoading = false;
			if (state.requests.items) {
				state.requests.items = state.requests.items.filter(
					(item: RequestObject) => item.id !== action.payload.item.id,
				);
			}
			state.requests.total_items = state.requests.total_items - 1;
		},
		changeRequestStatusFailure(state, action) {
			state.confirmRequestLoading = false;
			state.error = action.payload;
		},

		setFilterText(state, action) {
			state.textFilter = action.payload;
		},
		setPastFilterText(state, action) {
			state.pastTextFilter = action.payload;
		},
	},
});

export const {
	listEventsRequested,
	listEventsSucceeded,
	listEventsFailure,
	deleteRequestRequested,
	deleteRequestSucceeded,
	deleteRequestFailure,
	requestHistoryRequested,
	requestHistorySucceeded,
	requestHistoryFailure,
	loadMoreRequested,
	loadMoreSucceeded,
	loadMoreFailure,
	changeRequestStatusRequested,
	confirmRequestSucceeded,
	cancelRequestSucceeded,
	rejectRequestSucceeded,
	changeRequestStatusFailure,
	setFilterText,
	setPastFilterText,
} = listTalentEventsSlice.actions;

export default listTalentEventsSlice.reducer;

export const getRequestHistory = (id: number): any => async (dispatch: any) => {
	try {
		dispatch(requestHistoryRequested());
		const res = await Services.eventRequest.getRequestHistory(id);
		dispatch(requestHistorySucceeded(res));
	} catch (err) {
		dispatch(requestHistoryFailure(err));
	}
};

export const deleteRequest = (requestId: number): any => async (
	dispatch: any,
) => {
	try {
		dispatch(deleteRequestRequested());
		const res = await Services.eventRequest.deleteRequest(requestId);
		dispatch(deleteRequestSucceeded(res));
	} catch (err) {
		dispatch(deleteRequestFailure(err));
	}
};

export const listBandsEventsList = (
	bandId: number,
	filterText: string,
): any => async (dispatch: any, store: any) => {
	try {
		dispatch(listEventsRequested());
		const res = await Services.events.listBandRequests(bandId, {
			text: filterText,
		});
		console.log('listBandsEventsList RES: ', res);
		dispatch(listEventsSucceeded(res));
	} catch (err) {
		console.log('listBandsEventsList RES: ', err);
		dispatch(listEventsFailure(err));
	}
};

export const listBandsPastEventsList = (
	bandId: number,
	filterText: string,
): any => async (dispatch: any, store: any) => {
	try {
		dispatch(listEventsRequested());
		const res = await Services.events.listPastBandRequests(bandId, {
			text: filterText,
		});
		dispatch(listEventsSucceeded(res));
	} catch (err) {
		dispatch(listEventsFailure(err));
	}
};

export const loadMore = (bandId: number, textFilter: string): any => async (
	dispatch: any,
	getState: any,
) => {
	try {
		const page = getState().account.bandEvent.requests.page;
		dispatch(loadMoreRequested());
		const res = await Services.events.listBandRequests(bandId, {
			page: page + 1,
			text: textFilter,
		});
		dispatch(loadMoreSucceeded(res));
	} catch (err) {
		dispatch(loadMoreFailure(err));
	}
};

export const loadMorePast = (
	bandId: number,
	pastTextFilter: string,
): any => async (dispatch: any, getState: any) => {
	try {
		const page = getState().account.bandEvent.requests.page;
		dispatch(loadMoreRequested());
		const res = await Services.events.listPastBandRequests(bandId, {
			page: page + 1,
			text: pastTextFilter,
		});
		dispatch(loadMoreSucceeded(res));
	} catch (err) {
		dispatch(loadMoreFailure(err));
	}
};

// the api used to change request status
export const changeRequestStatus = (
	requestId: number,
	status: string,
): any => async (dispatch: any) => {
	try {
		dispatch(changeRequestStatusRequested());
		const res = await Services.eventRequest.changeStatus(requestId, status);
		if (status === "ACCEPTED_BY_TALENT") {
			dispatch(confirmRequestSucceeded(res));
		}
		if (status === "REJECTED_BY_TALENT") {
			dispatch(rejectRequestSucceeded(res));
		}
		if (status === "CANCELED_BY_TALENT") {
			dispatch(cancelRequestSucceeded(res));
		}
	} catch (err) {
		dispatch(changeRequestStatusFailure(err));
	}
};
