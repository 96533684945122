import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import LoginDialog from "./login-dialog";
import { IAppState } from "../generic/interfaces";

const mapStateToProps = (state: IAppState, ownProps: any) => ({
	opened: ownProps.opened,
	state: state.authentication,
	passwordReset: state.resetPassword.resetPasswordSuccess,
});

const mapDispatchToProps = (
	dispatch: ThunkDispatch<{}, {}, any>,
	ownProps: any,
) => ({
	handleClose: () => dispatch(ownProps.handleClose()),
	handleOpenSignup: () => {
		dispatch(ownProps.handleClose());
		setTimeout(() => {
			dispatch(ownProps.handleOpenSignup());
		}, 50);
	},
	handleLoginSubmit: (email: string, password: string, login: any) => {
		dispatch(
			ownProps.handleLoginSubmit(email, password, () => {
				dispatch(ownProps.handleClose());
				login();
			}),
		);
	},
	handleOpenForgotPassword: () => {
		dispatch(ownProps.handleClose());
		dispatch(ownProps.handleOpenForgotPassword());
	},

	handleOpenResendActivationLink: () => {
		dispatch(ownProps.handleClose());
		dispatch(ownProps.handleOpenResendActivationLink());
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginDialog);
