import React from "react";
import { Helmet } from 'react-helmet';


interface IMetadataTagsProps {
	// id: string;
	// children: React.ReactNode;
	// onClose?: () => void;
	title?: string;
	description?: string;
	image?: string;
	//classes?: any;
}

export const MetadataTags = (props: IMetadataTagsProps) => {
	const { title, description, image } = props;
	return (
		<Helmet>
			{title !== "" ? <title>{title}</title> : ''}			
			{description !== "" ? <meta name="description" content={description} /> : ''}
			{description !== "" ? <meta name="og:description" content={description} /> : ''}
			{image !== "" ? <meta name="image" content={image} /> : ''}
			{image !== "" ? <meta name="og:image" content={image} /> : ''}
		</Helmet>
	);
};
