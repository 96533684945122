import React, { useEffect } from "react";
import { CreateBookerEventForm } from "./CreateBookerEventForm";
import { CreateEventState } from "./createEventSlice";
import { Box } from "@material-ui/core";
import { RouteComponentProps, Redirect } from "react-router-dom";
import * as Yup from "yup";
import moment from "moment";

const today = new Date();

const validationSchema = Yup.object({
	photo: Yup.object().nullable(),
	title: Yup.string()
		.trim()
		.required("event name is a required field")
		.max(100),
	date: Yup.date()
		.required("date is a required field")
		//  to get yeterday's date
		.min(
			new Date(today.setDate(today.getDate() - 1)),
			"date value is not valid",
		),
	description: Yup.string()
		.trim()
		.required("description is a required field"),
	location: Yup.string()
		.trim()
		.required("location is a required field")
		.max(255),
	state: Yup.object()
		.shape({
			id: Yup.number().required(),
		})
		.required(),
	start_time: Yup.string().required("start time is a required field"),
	desired_set_length_hours: Yup.string()
		.required("desired length (hours) is a required field")
		.matches(/[1-9]([0-9])?/, {
			message: "desired length (hours) is not valid",
		}),
	desired_set_length_mins: Yup.string()
		.required("desired length (minutes) is a required field")
		.matches(/^(0|15|30|45)$/, {
			message: "desired length (mins) value is not valid",
		}),
	number_of_attendees: Yup.string()
		.required("attendees is a required field")
		.matches(/^((?!(0))[0-9]{1,7})$/, {
			message: "attendees value is not valid",
		}),
	event_type: Yup.object()
		.shape({
			id: Yup.number().required(),
		})
		.required(),
	venue_description: Yup.array()
		.min(1)
		.of(
			Yup.object()
				.shape({
					id: Yup.number().required(),
				})
				.required(),
		),
	promo_code: Yup.string()
		.matches(
			/^(?=.*[A-Za-z0-9])(?=.{12,12})/,
			`Minimum twelve characters`,
		)
});

export interface EditBookerProfileProps {
	bookerEvent: CreateEventState;
	states: any;
	gigs: any;
	isUserApproved: boolean;
	authentication: any;
	setLoc: (location: string) => void;
	listVenueDescription: () => void;
	createBookerEvent: (event: any) => void;
	listEventById: (eventId: number) => void;
	setPage: (page: string) => void;
	unMount: () => void;
}

export const CreateBookerEvent: React.FC<EditBookerProfileProps &
	RouteComponentProps<{ id: string }>> = ({
	match,
	setLoc,
	gigs,
	isUserApproved,
	states,
	setPage,
	bookerEvent,
	createBookerEvent,
	listVenueDescription,
	listEventById,
	unMount,
	authentication,
}) => {
	const { id } = match.params;

	useEffect(() => {
		if (id && isUserApproved) {
			listEventById(Number(id));
		}
		setLoc("/account/create-event");
		setPage("create-event");
		if (isUserApproved) {
			listVenueDescription();
		}

		return () => unMount();
	}, [id, isUserApproved, listEventById, listVenueDescription, setLoc, setPage, unMount]);

	const minutesToHours = (min: number | null) => {
		if (min === null) {
			return "";
		}

		const rminutes = min % 60;
		const rhours = rminutes === 0 ? min / 60 : min / 60;

		return moment
			.utc()
			.hours(rhours)
			.minutes(rminutes)
			.format("hh:mm");
	};

	const initialValues =
		bookerEvent.event === null
			? {
					venue_description: [],
					// venue_description: null,
					state: null,
					event_type: null,
					title: "",
					date: "",
					description: "",
					location: "",
					start_time: "",
					desired_set_length: "",
					desired_set_length_hours: "",
					desired_set_length_mins: "",
					number_of_attendees: "",
					promo_code: ""
			  }
			: {
					venue_description: bookerEvent.event.venue_description,
					state: bookerEvent.event.state,
					event_type: bookerEvent.event.event_type,
					title: bookerEvent.event.title,
					date: bookerEvent.event.date,
					description: bookerEvent.event.description,
					location: bookerEvent.event.location,
					start_time: moment(bookerEvent.event.start_time, "hh:mm A")
						.format("LT")
						.toLocaleString(),
					desired_set_length: minutesToHours(
						bookerEvent.event.desired_set_length,
					),
					desired_set_length_hours: bookerEvent.event.desired_set_length_hours,
					desired_set_length_mins: bookerEvent.event.desired_set_length_mins,
					number_of_attendees: bookerEvent.event.number_of_attendees,
					promo_code: bookerEvent.event.promo_code,
			  };
	return (
		<Box>
			{isUserApproved ? (
				bookerEvent.createEventSucceeded ? (
					<Redirect
						to={{
							pathname: "/account/active-event",
						}}
					/>
				) : (
					<CreateBookerEventForm
						id={id}
						bookerEvent={bookerEvent}
						gigs={gigs}
						states={states}
						initialValues={initialValues}
						validationSchema={validationSchema}
						authentication={authentication}
						handleOnSubmit={(event: any) =>{
							event.start_time = event.start_time.toTimeString().split(' ')[0];
							createBookerEvent(event);
						}}
					/>
				)
			) : (
				"Your profile is still pending admin approval. While we confirm your information, you will be able to search and favorite bands."
			)}
		</Box>
	);
};
