import { refineResponse } from "../helpers/refineResponse";
import { headers, baseUrl } from "../../AuthBase/helpers/auth-header";

export const list = () => {
	const requestOptions = {
		method: "POST",
		headers: { ...headers() },
		body: JSON.stringify({ page: 1, items_per_page: 100 }),
	};

	return fetch(`${baseUrl}genres/list`, requestOptions).then(
		refineResponse,
	);
};
