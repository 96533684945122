import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { ReviewDialog } from "../../../generic/components/ReviewDialog";
import { IAppState } from "../../../generic/interfaces";
import { closeReviewTalentDialog, addReview } from "./reviewTalentSlice";

const mapStateToProps = (state: IAppState, ownProps: any) => ({
	opened: state.account.reviewTalent.reviewTalentDialogOpened,
	disabled: state.account.reviewTalent.reviewTalentRequested,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
	handleClose: () => dispatch(closeReviewTalentDialog()),
	handleSubmit: (
		userReview: string,
		userRating: number,
		repsyReview: string,
		repsyRating: number,
	) =>
		dispatch(
			addReview({ userReview, userRating, repsyReview, repsyRating }),
		),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReviewDialog);
