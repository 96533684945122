import { createStyles } from "@material-ui/core/styles";

export const styles = createStyles({
	dialogContentText: {
		font: '1.2em "Fira Sans", sans-serif',
		"text-align": "center",
	},
	root: {
		height: "100%",
		width: "50%",
		padding: "0 30px",
		boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
	},
	dialogWrapper: {
		width: "100%",
		height: "350px",
	},
	paper: {
		margin: "10px",
	},
	bookButton: {
		bottom: "0px",
		margin: "20% 5% 5% 5%",
	},
	inputLabel: {
		display: "inline-block",
		width: "20%",
		"vertical-align": "-webkit-baseline-middle",
		"text-align": "left",
	},
	form: {
		textAlign: "center",
	},
	inputDiv: {
		width: "100%",
		margin: "10px auto",
	},
	inputField: {
		width: "70%",
	},
	progressWrapper: {
		textAlign: "center",
	},
	progress: {},
});
