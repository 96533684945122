import React from "react";
import { IFilterBandsProps } from "./interfaces";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";
import Grid from "@material-ui/core/Grid";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Typography from "@material-ui/core/Typography";
// import Link from "@material-ui/core/Link";
import { Link } from "react-router-dom";
import GenresFilter from "../FilterBar/GenreFilter";
import PricesFilter from "../FilterBar/PriceFilter";
import MoreFilters from "./MoreFilters";
import searchBarIcon from "../../Assets/Search Bar Icon.svg";
import priceIcon from "../../Assets/Price.svg";
import genreIcon from "../../Assets/Genre.svg";
import locationIcon from "../../Assets/Location.svg";
import Chip from "@material-ui/core/Chip";
import moment from "moment";
import { IsLoggedIn } from "../../AuthBase/";

interface ChipData {
	day: string;
}

class FilterBands extends React.Component<IFilterBandsProps> {
	public searchbuttonRef: React.RefObject<any>;
	public state = {
		pricesPopover: null,
		genresPopover: null,
		datesPopover: null,
		sizePopover: null,
		locationPopover: null,
		moreFilters: null,
		sort: null,
		showSearchText: null

	};

	constructor(props: IFilterBandsProps) {
		super(props);
		this.props.clearFilters();
		this.handlePopoverClose = this.handlePopoverClose.bind(this);
		this.searchbuttonRef = React.createRef();

		// Autofill search text box from querystring -- start
		let query = new URLSearchParams(window.location.search);
		const searchTextQuery = query.get('search');
		const searchText = searchTextQuery == null ? "" : searchTextQuery;
		if (searchText !== "") {
			this.props.searchText(searchText);
			var uri = window.location.toString();
			if (uri.indexOf("?") > 0) {
				var clean_uri = uri.substring(0, uri.indexOf("?"));
				window.history.replaceState({}, document.title, clean_uri);
			}
		}
		// Autofill search text box from querystring -- end

	}

	public handleKeyDown = (e: any) => {
		if (e.key === "Enter") {
			this.searchbuttonRef.current.focus();
		}
	};

	public handlePopoverClick = (name: string) => (e: any) => {
		// console.log('this.state.genresPopover: ', this.state, e.currentTarget);

		this.setState({ showSearchText: true });
		this.setState({
			[name]: e.currentTarget,
		});
	};

	public handlePopoverClose = (name: string) => (e: any) => {
		// console.log('this.state.genresPopover CLOSE: ', this.state);
		this.setState({ showSearchText: false });
		this.setState({
			[name]: null,
		});
	};

	public onChange = (e: any) => {
		this.props.handleKeywordChange(e);
		setTimeout(this.props.search, 500);
	};

	public handleDelete = (chipToDelete: ChipData) => () => {
		const days = this.props.filters.days.filter((day: any) => {
			return day.day !== chipToDelete.day;
		});
		const formatedDays = days.map((x: any) => moment(x.day, "YYYY-MM-DD").format("ddd MMM DD YYYY HH:mm:ss"));
		this.props.saveDates(formatedDays);
	};

	public handleDeleteGenre = (chipToDelete: { id: number; title: string }) => () => {
		const x = this.props.filters.genres.filter((item: { id: number; title: string }) => {
			return item !== chipToDelete;
		});
		this.props.saveGenres(x);
	};

	public render() {
		const { filters, resources, search, sort } = this.props;
		//const query = new URLSearchParams(this.props.location.search);
		// console.log('filters', filters);
		let favoritePage = false;
		if (this.props.favoritePage) {
			favoritePage = true;
		}
		return (
			<Grid container direction="row" justify="center" alignItems="center" spacing={1}>
				<Grid sm={12} md={12} lg={12} style={{ textAlign: 'center' }} className={this.state.showSearchText ? "show-search-text" : ''}>

					{/* <OutlinedInput
								labelWidth={0}								
								onChange={this.onChange}
								onKeyDown={this.handleKeyDown}
								value={filters.text}
								fullWidth={true}
								margin="dense"
								endAdornment={
									<IconButton
										color="primary"
										size="medium"
										aria-label="Search"
										onClick={search}
										ref={this.searchbuttonRef}>
										<Icon fontSize="large">
											<img alt="search icon" src={searchBarIcon} />
										</Icon>
									</IconButton>
								}
							/> */}

					<OutlinedInput
						labelWidth={0}
						onKeyDown={this.handleKeyDown}
						onChange={this.onChange}
						placeholder="Search"
						style={{ height: 'auto', display: 'flex', justifyContent: 'space-between' }}
						className="filterBarMain"
						endAdornment={
							<div className="filterBarBtns">
								<Button
									className="filterBtn"
									size="large"
									variant="outlined"
									color="secondary"
									disableRipple={true}
									aria-describedby={"genresPopover"}
									onClick={this.handlePopoverClick("genresPopover")}>
									<img height="30em" src={genreIcon} alt="music icon" style={{ paddingRight: "6px" }} />
									<Typography color="textPrimary" component="span" variant="subtitle2">
										Genre
									</Typography>

								</Button>
								<Popover
									marginThreshold={0}
									open={Boolean(this.state.genresPopover)}
									anchorEl={this.state.genresPopover}
									onClose={this.handlePopoverClose("genresPopover")}
									anchorOrigin={{
										vertical: "bottom",
										horizontal: "center",
									}}
									transformOrigin={{
										vertical: "top",
										horizontal: "center",
									}}>
									<GenresFilter
										saveGenres={this.props.saveGenres}
										genres={resources.genres}
										values={filters.genres}
										handlePopoverClose={this.handlePopoverClose("genresPopover")}
									/>
								</Popover>

								<IsLoggedIn>
									<Button
										className="filterBtn"
										size="large"
										variant="outlined"
										color="secondary"
										disableRipple={true}
										aria-describedby={"pricesPopover"}
										onClick={this.handlePopoverClick("pricesPopover")}>
										<img height="30em" src={priceIcon} alt="price icon" style={{ paddingRight: "6px" }} />
										<Typography color="textPrimary" component="span" variant="subtitle2">
											Price
										</Typography>
									</Button>
									<Popover
										open={Boolean(this.state.pricesPopover)}
										anchorEl={this.state.pricesPopover}
										onClose={this.handlePopoverClose("pricesPopover")}
										anchorOrigin={{
											vertical: "bottom",
											horizontal: "center",
										}}
										transformOrigin={{
											vertical: "top",
											horizontal: "center",
										}}>
										<PricesFilter
											savePrices={this.props.savePrices}
											values={{
												from: filters.price_from,
												to: filters.price_to,
											}}
											handlePopoverClose={this.handlePopoverClose("pricesPopover")}
										/>
									</Popover>
								</IsLoggedIn>
								<Button
									className="filterBtn"
									size="large"
									variant="outlined"
									color="secondary"
									disableRipple={true}
									aria-describedby={"locationPopover"}
									onClick={this.handlePopoverClick("locationPopover")}>
									<img height="30em" src={locationIcon} alt="location icon" style={{ paddingRight: "6px" }} />
									<Typography color="textPrimary" component="span" variant="subtitle2">
										Location
									</Typography>
								</Button>
								<Popover
									open={Boolean(this.state.locationPopover)}
									anchorEl={this.state.locationPopover}
									onClose={this.handlePopoverClose("locationPopover")}
									anchorOrigin={{
										vertical: "bottom",
										horizontal: "center",
									}}
									transformOrigin={{
										vertical: "top",
										horizontal: "center",
									}}>
									<MoreFilters
										saveSize={this.props.saveSize}
										saveFavorite={this.props.saveFavorite}
										saveLocation={this.props.saveLocation}
										search={this.props.search}
										clearLocation={this.props.clearLocation}
										clearSize={this.props.clearSize}
										sizeValue={filters.size}
										locationValue={filters.location}
										filters={filters}
										states={resources.states}
										isFavorite={filters.favorite}
										favoritePage={favoritePage}
										handlePopoverClose={this.handlePopoverClose("locationPopover")}
									/>
								</Popover>

								<Link to={`search/?search=${filters.text}`} className="searchIcon">
									<IconButton
										color="primary"
										size="medium"
										aria-label="Search"
										onClick={search}
										ref={this.searchbuttonRef}
									>
										<img alt="search icon" src={searchBarIcon} width="30px" />
										<span>Search</span>
									</IconButton>
								</Link>
							</div>
						}
					/>



				</Grid>
				<Grid item>
					{filters.genres &&
						filters.genres.map((item: { id: number; title: string }, index: number) => {
							return (
								<Chip
									variant="outlined"
									color="secondary"
									onDelete={this.handleDeleteGenre(item)}
									key={index}
									label={item.title}
								/>
							);
						})}

					{filters.price_from !== null && (
						<Chip
							variant="outlined"
							color="secondary"
							onDelete={() => {
								this.props.savePrices({
									from: null,
									to: filters.price_to,
								});
							}}
							label={`Price From: ${filters.price_from}`}
						/>
					)}

					{filters.price_to !== null && (
						<Chip
							variant="outlined"
							color="secondary"
							onDelete={() => {
								this.props.savePrices({
									from: filters.price_from,
									to: null,
								});
							}}
							label={`Price To: ${filters.price_to}`}
						/>
					)}

					{filters.days &&
						filters.days.map((day: any, index: number) => {
							return (
								<Chip
									variant="outlined"
									color="secondary"
									onDelete={this.handleDelete(day)}
									key={index}
									label={moment(day.day, "YYYY.MM.DD").format("LL")}
								/>
							);
						})}

					{filters.size !== null && (
						<Chip
							variant="outlined"
							color="secondary"
							onDelete={() => {
								this.props.clearSize();
							}}
							label={`band size: ${filters.size}`}
						/>
					)}

					{filters.location !== null && filters.location.id !== null && (
						<Chip
							variant="outlined"
							color="secondary"
							onDelete={() => {
								this.props.clearLocation();
							}}
							label={`Location: ${resources.states.items[filters.location.id].title}`}
						/>
					)}
					{filters.favorite !== false && !this.props.favoritePage && (
						<Chip
							variant="outlined"
							color="secondary"
							onDelete={() => {
								this.props.clearFavorite();
							}}
							label={`Is Favorite: ${filters.favorite}`}
						/>
					)}
				</Grid>
			</Grid>
		);
	}
}

export default FilterBands;
