import * as bands from "./bands";
import * as booker from "./booker";
import * as agent from "./agent";
import * as genres from "./genres";
import * as gigs from "./gigs";
import * as priceRange from "./priceRange";
import * as users from "./users";
import * as paymentMethods from "./paymentMethods";
import * as states from "./states";
import * as eventRequest from "./event-request";
import * as media from "./media";
import * as events from "./events";
import * as termsAndConditions from "./termsAndConditions";
import * as settings from "./settings";
import * as notificationSettings from "./notificationSettings";
import * as blogs from "./blog";

const Services = {
	bands,
	booker,
	agent,
	genres,
	gigs,
	priceRange,
	users,
	paymentMethods,
	states,
	eventRequest,
	media,
	events,
	termsAndConditions,
	settings,
	notificationSettings,
	blogs
};

export default Services;
