import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

interface DeleteDialogProps {
	id: number;
	open: boolean;
	deletePayment?: any;
	handleClose: any;
}

export const DeleteDialog: React.FC<DeleteDialogProps> = ({ id, open, deletePayment, handleClose }) => (
	<Dialog
		open={open}
		onClose={handleClose}
		aria-labelledby="delete-dialog-title"
		aria-describedby="delete-dialog-description">
		<DialogContent>
			<DialogContentText color="textPrimary" id="delete-dialog-description">
				Are you sure you want to delete this payment method?
			</DialogContentText>
		</DialogContent>
		<DialogActions>
			<Button
				onClick={() => {
					deletePayment(id);
				}}
				color="default">
				Yes
			</Button>
			<Button onClick={handleClose} color="default" autoFocus>
				No
			</Button>
		</DialogActions>
	</Dialog>
);
