import { Dispatch } from "redux";
import { ListEventRequestActionType } from "../interfaces";
import Services from "../../../generic/Services";

const getItemFullObject = (requestId?: number) => {
	if (!requestId) {
		return;
	}
	return (dispatch: Dispatch) => {
		dispatch(openUpdatedModal(requestId));
		dispatch(request(requestId));
		Services.eventRequest.getItemById(requestId).then(
			(response: any) => {
				dispatch(success(requestId, response));
			},
			error => {
				dispatch(fail(requestId, error));
			},
		);
	};

	function request(id: number) {
		return {
			type: ListEventRequestActionType.GET_FULL_ITEM_REQUEST,
			payload: { id },
		};
	}

	function success(id: number, response: any) {
		return {
			type: ListEventRequestActionType.GET_FULL_ITEM_SUCCESS,
			payload: { id, response },
		};
	}

	function fail(id: number, response: any) {
		return {
			type: ListEventRequestActionType.GET_FULL_ITEM_FAILURE,
			payload: { id, response },
		};
	}
};

const openUpdatedModal = (id?: number) => {
	return {
		type: ListEventRequestActionType.UPDATED_MODAL_OPEN,
		payload: { id },
	};
};

const closeUpdatedModal = (id?: number) => {
	return {
		type: ListEventRequestActionType.UPDATED_MODAL_CLOSE,
		payload: { id },
	};
};

const updateEventRequest = (eventRequest: any) => {
	return (dispatch: Dispatch) => {
		dispatch(request(eventRequest.id));
		Services.eventRequest.update(eventRequest).then(
			(response: any) => {
				dispatch(success(eventRequest.id, response));
				dispatch(closeUpdatedModal(eventRequest.id));
			},
			error => {
				dispatch(fail(eventRequest.id, error));
			},
		);
	};

	function request(id: number) {
		return {
			type: ListEventRequestActionType.UPDATE_BOOKER_REQUEST,
			payload: { id },
		};
	}

	function success(id: number, response: any) {
		return {
			type: ListEventRequestActionType.UPDATE_BOOKER_SUCCESS,
			payload: { id, response },
		};
	}

	function fail(id: number, response: any) {
		return {
			type: ListEventRequestActionType.BOOKER_REQUEST_LIST_FAILURE,
			payload: { id, response },
		};
	}
};

const openCancelModal = (id?: number) => {
	return {
		type:
			ListEventRequestActionType.EVENT_REQUEST_CANCEL_CONFIRM_DIALOG_OPEN,
		payload: { id },
	};
};

const closeCancelModal = (id?: number) => {
	return {
		type:
			ListEventRequestActionType.EVENT_REQUEST_CANCEL_CONFIRM_DIALOG_CLOSE,
		payload: { id },
	};
};

const cancelEventRequest = (requestId?: number) => {
	if (!requestId) {
		return;
	}
	return (dispatch: Dispatch) => {
		dispatch(request(requestId));
		dispatch(closeCancelModal(requestId));
		Services.eventRequest.changeStatus(requestId, "CANCELED").then(
			(response: any) => {
				dispatch(success(requestId, response));
			},
			error => {
				dispatch(fail(requestId, error));
			},
		);
	};

	function request(id: number) {
		return {
			type: ListEventRequestActionType.CANCEL_BOOKER_REQUEST,
			payload: { id },
		};
	}

	function success(id: number, response: any) {
		return {
			type: ListEventRequestActionType.CANCEL_BOOKER_SUCCESS,
			payload: { id, response },
		};
	}

	function fail(id: number, response: any) {
		return {
			type: ListEventRequestActionType.CANCEL_BOOKER_FAILURE,
			payload: { id, response },
		};
	}
};

const openConfirmModal = (id?: number) => {
	return {
		type:
			ListEventRequestActionType.CONFIRM_EVENT_REQUEST_CONFIRM_DIALOG_OPEN,
		payload: { id },
	};
};

const closeConfirmModal = (id?: number) => {
	return {
		type:
			ListEventRequestActionType.CONFIRM_EVENT_REQUEST_CONFIRM_DIALOG_CLOSE,
		payload: { id },
	};
};

const confirmEventRequest = (requestId?: number) => {
	if (!requestId) {
		return;
	}
	return (dispatch: Dispatch) => {
		dispatch(request(requestId));
		dispatch(closeConfirmModal(requestId));
		Services.eventRequest.changeStatus(requestId, "PENDING_DEPOSIT").then(
			(response: any) => {
				dispatch(success(requestId, response));
			},
			error => {
				dispatch(fail(requestId, error));
			},
		);
	};

	function request(id: number) {
		return {
			type: ListEventRequestActionType.CONFIRM_EVENT_REQUEST,
			payload: { id },
		};
	}

	function success(id: number, response: any) {
		return {
			type: ListEventRequestActionType.CONFIRM_EVENT_SUCCESS,
			payload: { id, response },
		};
	}

	function fail(id: number, response: any) {
		return {
			type: ListEventRequestActionType.CONFIRM_EVENT_FAILURE,
			payload: { id, response },
		};
	}
};

export const BookerEventRequestActions = {
	getItemFullObject,
	openUpdatedModal,
	closeUpdatedModal,
	updateEventRequest,
	openCancelModal,
	closeCancelModal,
	cancelEventRequest,
	openConfirmModal,
	closeConfirmModal,
	confirmEventRequest,
};
