import { NotifierActionType } from "./interfaces";

interface INotificationProps {
	message: string;
	key?: number;
	dismissed?: boolean;
	options?: {
		variant?: string;
		persist?: boolean;
		children?: React.ReactNode;
		preventDuplicate?: boolean;
		action?: any;
		onClose?: any;
	};
}

export const enqueueSnackbar = (notification: INotificationProps) => {
	const key = notification.key;

	return {
		type: NotifierActionType.ENQUEUE_SNACKBAR,
		payload: {
			...notification,
			key: key || new Date().getTime() + Math.random(),
		},
	};
};

export const closeSnackbar = (key: number) => ({
	type: NotifierActionType.CLOSE_SNACKBAR,
	payload: {
		dismissAll: !key, // dismiss all if no key has been defined
		key,
	},
});

export const removeSnackbar = (key: number) => ({
	type: NotifierActionType.REMOVE_SNACKBAR,
	payload: { key },
});
