import { combineReducers } from "redux";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";
import { header } from "../../Header/reducer";
import { authentication, registration } from "../../AuthBase";
import { confirmAccount } from "../../ConfirmAccount/reducer";
import { bandPage } from "../../BandPage/bandDetailsSlice";
import { searchBands } from "../../SearchPage/searchPageSlice";
import { listEventRequest } from "../../EventRequest/ListEventRequest/reducers/list";
import account from "../../Account/reducer";
import { resources } from "../Resources";
import { notifier } from "../../Notifier/reducer";
import { forgetPassword } from "../../ForgetPassword/reducer";
import { resendActivationLink } from "../../ResendActivationLink/reducer";
import { resetPassword } from "../../ResetPassword/reducer";
import { confirmationDialog } from "../../ConfirmationDialog/reducer";
import { configureStore, getDefaultMiddleware } from "redux-starter-kit";
import homePage from "../../HomePage/homePageSlice";
import howItWorksPage from "../../HowItWorks/howItWorksSlice";
import contactPage from "../../ContactPage/contactSlice";
import { searchFavoriteBands } from "../../Favorites/searchFavoritesSlice";

import agentbandlistpage from "../../generic/components/AgentBandList/agent-band-list-slice";
const appReducer = combineReducers({
    header,
    authentication,
    registration,
    confirmAccount,
    confirmationDialog,
    bandPage,
    searchBands,
    listEventRequest,
    homePage,
    howItWorksPage,
    account,
    resources,
    notifier,
    forgetPassword,
    resendActivationLink,
    resetPassword,
    contactPage,
    searchFavoriteBands,
    agentbandlistpage
});

const rootReducer = (state: any, action: any) => {
    if (action.type === "LOGOUT") {
        state = undefined;
    }

    return appReducer(state, action);
};

const middlewares: any = [thunkMiddleware];

if (process.env.NODE_ENV !== "production") {
    middlewares.push(createLogger());
}

// export const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(...middlewares)));

const middlewaresRSK: any = [];

if (process.env.NODE_ENV !== "production") {
    middlewaresRSK.push(createLogger());
}

export const storeRSK = configureStore({
    reducer: rootReducer,
    middleware: [...getDefaultMiddleware(), ...middlewaresRSK],
});
