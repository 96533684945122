import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import { BandRequestState } from "./bandRequestSlice";
import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";
import WarningDialog from "./WarningDialog";
import { IsAnonymous, IsLoggedIn } from "../../AuthBase";
import ChooseEvent from "./ChooseEvent";
import RequestForm from "./RequestForm";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        textAlign: {
            textAlign: "end",
        },
        Link: {
            textDecoration: "none",
            color: "white",
            fontSize: "15px",
            fontWeight: "bold",
        },
        CreateNewEventLink: {
            fontWeight: "bold",
            color: "#64FECC",
        },
        margin: {
            margin: "0px",
        },
        cancel: {
            fontWeight: 200,
        },
        location: {
            "word-wrap": "break-word",
            maxWidth: "320px",
        },
    })
);

export interface BandRequestProps {
    isUserApproved: boolean;
    band: any;
    bandRequest: BandRequestState;
    bookerEvents: any;
    handleOpen: () => void;
    handleClose: () => void;
    openSignupModal: () => void;
    setSelectedEvent: (eventItem: any) => void;
    handleBandRequestSubmit: (values: any) => void;
    listBookerEventsList: () => void;
    handleOpenWarningModel: () => void;
    handleCloseWarningModel: () => void;
    warningModalOpened: boolean;
}

export interface MyFormValues {
    budget: string;
    specialNotes: string;
}

export const minutesToHours = (min: number | null) => {
    if (min === null) {
        return "";
    }

    const rminutes = min % 60;
    const rhours = rminutes === 0 ? min / 60 : min / 60;

    return `${Math.floor(rhours)} hour(s) ${rminutes > 0 ? "and " + rminutes + " minute(s)." : ""}`;
};

export const BandRequest: React.FC<BandRequestProps> = ({
    isUserApproved,
    bookerEvents,
    band,
    bandRequest,
    handleOpen,
    handleClose,
    openSignupModal,
    setSelectedEvent,
    handleBandRequestSubmit,
    listBookerEventsList,
    handleOpenWarningModel,
    handleCloseWarningModel,
    warningModalOpened,
}) => {
    useEffect(() => {        
        listBookerEventsList();

        return () => handleClose();
    }, [listBookerEventsList, handleClose]);

    const classes = useStyles();

    return (
        <Box mt={2}>  
            <IsAnonymous>
                <Button size="large" variant="contained" color="secondary" onClick={openSignupModal}>
                    Sign Up to Request Artist
                </Button>
            </IsAnonymous>   
            <IsLoggedIn>
                <Button size="large" variant="contained" color="secondary" onClick={isUserApproved ? handleOpen : handleOpenWarningModel}>
                    Send Booking Request
                </Button>
            </IsLoggedIn>
            <WarningDialog handleCloseWarningModel={handleCloseWarningModel} warningModalOpened={warningModalOpened} />
            <ChooseEvent
                bandRequest={bandRequest}
                bookerEvents={bookerEvents}
                setSelectedEvent={setSelectedEvent}
                handleClose={handleClose}
                classes={classes}
            />
            <RequestForm
                listBookerEventsList={listBookerEventsList}
                handleClose={handleClose}
                handleBandRequestSubmit={handleBandRequestSubmit}
                bandRequest={bandRequest}
                band={band}
                initialValues={{
                    talent_budget: "",
                    specialNotes: "",
                    production_budget: ""
                }}
                type="create"
                id=""
            />
        </Box>
    );
};
