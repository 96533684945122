import { ResendActivationLinkActionType } from "./interfaces";
import { Dispatch } from "redux";
import Services from "../generic/Services";
import { enqueueSnackbar } from "../Notifier/actions";
import { IError } from "../Base/ErrorMessageBase";

export const openResendActivationLinkModal = () => {
	return {
		type: ResendActivationLinkActionType.RESEND_ACTIVATION_LINK_MODAL_OPEN,
	};
};

export const closeResendActivationLinkModal = () => {
	return {
		type: ResendActivationLinkActionType.RESEND_ACTIVATION_LINK_MODAL_CLOSE,
	};
};

export const resendActivationLink = (data: { email: string }) => {
	return (dispatch: Dispatch) => {
		dispatch(request());
		Services.users.resendConfirmationEmail(data.email).then(
			response => {
				dispatch(success(response.item));
			},
			(error: IError) => {
				dispatch(failure(error));
				dispatch(
					enqueueSnackbar({
						message: error.message,
						options: {
							variant: "error",
						},
					}),
				);
			},
		);
	};

	// export function resendConfirmationEmail(email: string) {
	// 	return (dispatch: Dispatch) => {
	// 		dispatch(request());
	// 		Services.users.resendConfirmationEmail(email).then(
	// 			response => {
	// 				dispatch(success(response.item));
	// 			},
	// 			error => {
	// 				dispatch(failure(error));
	// 			},
	// 		);
	// 	};

	function request() {
		return { type: ResendActivationLinkActionType.RESEND_ACTIVATION_LINK_REQUEST };
	}
	function success(payload: any) {
		return {
			type: ResendActivationLinkActionType.RESEND_ACTIVATION_LINK_SUCCESS,
			payload,
		};
	}
	function failure(payload: IError) {
		return {
			type: ResendActivationLinkActionType.RESEND_ACTIVATION_LINK_FAILURE,
			payload,
		};
	}
};
