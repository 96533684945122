import React, { useEffect } from "react";
import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";
import { Box, Typography, Grid, Link } from "@material-ui/core";
import ErrorMessage from "../../generic/ErrorMessage";
import { BandReviewState } from "./bandReviewSlice";
import { BandReviewItem } from "./bandReviewSlice";
import Rating from "../../generic/Rating";
import moment from "moment";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		Location: {
			fontSize: "16px",
			color: "#DDD",
		},
		margin: {
			margin: `${theme.spacing(1)}px 0px`,
		},
		btn: {
			fontWeight: 200,
		},
	}),
);

export interface BandReviewProps {
	classes?: any;
	bandId: number;
	bandReviews: BandReviewState;
	getReviewsList: any;
	loadMoreReviewsList: (bandId: number, bandReviewPage: number) => void;
}

export const BandReview: React.FC<BandReviewProps> = ({
	bandId,
	getReviewsList,
	loadMoreReviewsList,
	bandReviews,
}) => {
	useEffect(() => {
		getReviewsList(bandId);
	}, [bandId, getReviewsList]);

	const handleClick = () => {
		loadMoreReviewsList(bandId, bandReviews.bandReviewPage);
	};

	const classes = useStyles();
	return (
		<Box>
			{!bandReviews.bandReviewFailure &&
				bandReviews.bandReviews.items.length > 0 && (
					<>
						<h1>Reviews ({bandReviews?.bandReviews?.total_items})</h1>
						<Grid container
								direction="row"
								justify="space-between"
								alignItems="flex-start" spacing={3}>
							{bandReviews.bandReviews.items.map(
								(item: BandReviewItem, index: number) => (
									<Grid item lg={6} md={6} xs={12}>
									<Grid item key={item.id}>
										<h4 className={classes.margin}>
											{moment(
												item.date,
												"YYYY.MM.DD",
											).format("LL")}{" "}
										</h4>
										<Rating
											className={classes.margin}
											color="#64FECC"
											width="24px"
											rate={item.user_rating}
										/>
										<Typography
											className={classes.Location}
										>
											{item.location}
										</Typography>
										<Box mt={3}>
											<p key={index}>
												{item.user_review}
											</p>
										</Box>
									</Grid>
									</Grid>
								),
							)}
							<Grid item>
								{bandReviews.bandReviews.items.length !==
									bandReviews.totalItems && (
									<Link
										color="textPrimary"
										component="button"
										type="button"
										onClick={handleClick}
										className={classes.btn}
									>
										See More
									</Link>
								)}
							</Grid>
						</Grid>
					</>
				)}
			{bandReviews.bandReviewFailure && bandReviews.error.message && (
				<ErrorMessage error={bandReviews.error} />
			)}
		</Box>
	);
};
