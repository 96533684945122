import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { number } from "yup";
import { IAppState } from "../../generic/interfaces";
import { setPage } from "../../HomePage/homePageSlice";
import { EditTalentProfile } from "./EditTalentProfile";
import {
	addTalentProfile,
	closeSaveModal,
	editTalentProfile,
	listMyBands,
	getBandById,
	openSaveModal,
	unMount,
	closeFailModal,
} from "./editTalentProfileSlice";

const mapStateToProps = (state: IAppState) => ({
	talentProfile: state.account.editTalentProfile,
	resources: state.resources,
	priceRange: state.resources.priceRange,
	authentication: state.authentication,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
	getBandById: (bandId: number) =>
		dispatch(getBandById(bandId)),
	listMyBands: () => dispatch(listMyBands()),
	addTalentProfile: (talentProfile: any) =>
		dispatch(addTalentProfile(talentProfile)),
	editTalentProfile: (talentProfile: any, id: number) =>
		dispatch(editTalentProfile(talentProfile, id)),
	setPage: (page: string) => {
		dispatch(setPage(page));
	},
	unMount: () => dispatch(unMount()),
	handleSaveClose: () => dispatch(closeSaveModal()),
	handleOpen: () => dispatch(openSaveModal()),
	handleFailClose: () => dispatch(closeFailModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditTalentProfile);
