import React from "react";
import { Box, TextField, Grid, InputLabel, Button } from "@material-ui/core";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { Formik, FormikProps } from "formik";
import { SettingsState } from "./settingsSlice";
import ErrorMessage from "../../generic/ErrorMessage";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		textAlign: {
			textAlign: "end",
		},
	}),
);

export interface MyFormValues {
	currentEmail: string;
	email: string;
}

export interface EmailFormProps {
	validationSchema: any;
	initialValues: any;
	settings: SettingsState;
	handleOnSubmit: (values: { email: string }) => any;
}

export const EmailForm: React.FC<EmailFormProps> = ({
	validationSchema,
	initialValues,
	handleOnSubmit,
	settings,
}) => {
	const classes = useStyles();

	return (
		<Box mb={7} mt={6}>
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={async (values, actions) => {
					handleOnSubmit({ email: values.email }).then((res: any) => {
						if (res) {
							actions.resetForm();
						}
					});
					actions.setSubmitting(false);
				}}
				render={(formikBag: FormikProps<MyFormValues>) => {
					return (
						<>
							<Box mb={2} fontFamily="poppins" fontSize="1.2rem">
								Email Address
							</Box>
							<form onSubmit={formikBag.handleSubmit}>
								<Grid
									container
									direction="column"
									alignItems="center"
									spacing={4}
								>
									<Grid
										container
										item
										direction="row"
										alignItems="center"
										spacing={4}
									>
										<Grid item xs={4} md={4} lg={4}>
											<InputLabel
												className={classes.textAlign}
												htmlFor="currentEmail"
											>
												Current Email Address
											</InputLabel>
										</Grid>
										<Grid item xs={8} md={5} lg={5}>
											<TextField
												autoComplete="off"
												variant="outlined"
												color="secondary"
												id="currentEmail"
												name="currentEmail"
												helperText={
													formikBag.touched
														.currentEmail
														? formikBag.errors
																.currentEmail
														: ""
												}
												error={
													formikBag.touched
														.currentEmail &&
													Boolean(
														formikBag.errors
															.currentEmail,
													)
												}
												fullWidth={true}
												margin="dense"
												value={
													formikBag.values
														.currentEmail || ""
												}
												placeholder="Current Email Address"
												onChange={(e: any) => {
													e.persist();
													formikBag.setFieldValue(
														"currentEmail",
														e.target.value,
													);
													formikBag.setFieldTouched(
														"currentEmail",
														true,
														false,
													);
												}}
											/>
										</Grid>
									</Grid>
									<Grid
										container
										item
										direction="row"
										alignItems="center"
										spacing={4}
									>
										<Grid item xs={4} md={4} lg={4}>
											<InputLabel
												className={classes.textAlign}
												htmlFor="email"
											>
												Update Email Address
											</InputLabel>
										</Grid>
										<Grid item xs={8} md={5} lg={5}>
											<TextField
												autoComplete="off"
												variant="outlined"
												color="secondary"
												id="email"
												name="email"
												helperText={
													formikBag.touched.email
														? formikBag.errors.email
														: ""
												}
												error={
													formikBag.touched.email &&
													Boolean(
														formikBag.errors.email,
													)
												}
												fullWidth={true}
												margin="dense"
												value={
													formikBag.values.email || ""
												}
												placeholder="Update Email Address"
												onChange={(e: any) => {
													e.persist();
													formikBag.setFieldValue(
														"email",
														e.target.value,
													);
													formikBag.setFieldTouched(
														"email",
														true,
														false,
													);
												}}
											/>
										</Grid>
									</Grid>
									<Grid
										container
										item
										direction="row"
										alignItems="center"
									>
										<Grid item xs={4} md={4} lg={4}></Grid>
										<Grid item xs={6} md={6} lg={6}>
											{settings.errorEmail &&
												settings.errorEmail.message && (
													<ErrorMessage
														error={
															settings.errorEmail
														}
													/>
												)}
										</Grid>
									</Grid>
									<Grid
										container
										item
										direction="row"
										alignItems="center"
										spacing={4}
									>
										<Grid item xs={4} md={4} lg={4}></Grid>
										<Grid item xs={8} md={6} lg={6}>
											<Button
												type="submit"
												size="large"
												variant="contained"
												color="secondary"
												disabled={
													formikBag.isSubmitting
												}
											>
												Update
											</Button>
										</Grid>
									</Grid>
								</Grid>
							</form>
						</>
					);
				}}
			/>
		</Box>
	);
};
