import { IItemEventRequest } from "../ListEventRequest/interfaces";

export enum EventRequestStatus {
	PENDING = "PENDING",
	APPROVED_BY_TALENT = "APPROVED_BY_TALENT",
	REJECTED_BY_TALENT = "REJECTED_BY_TALENT",
	PENDING_DEPOSIT = "PENDING_DEPOSIT",
	PENDING_REMAINDER = "PENDING_REMAINDER",
	CONFIRMED = "CONFIRMED",
	CANCELED = "CANCELED",
}

export interface IEventRequestListItemProps {
	EventRequestItem: IItemEventRequest;
	classes: any;
	getItemFullObject: (id?: number) => any;
	closeUpdatedModal: (id?: number) => any;
	updateEventRequest: (eventRequest: any) => any;
	openCancelModal: (id?: number) => any;
	closeCancelModal: (id?: number) => any;
	cancelEventRequest: (id?: number) => any;
}
