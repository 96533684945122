import bandReviewSlice from "./BandReview/bandReviewSlice";
import bandRequestSlice from "./BandRequest/bandRequestSlice";
import { createSlice } from "redux-starter-kit";
import { combineReducers, Dispatch } from "redux";
import { IBand } from "../interfaces";
import Services from "../generic/Services";

export interface BandDetailsPageState {
	bandDetailsRequested: boolean;
	bandDetailsSucceeded: boolean;
	bandDetailsFailure: boolean;

	bandDetails: {
		item: IBand;
		server_message: string;
		user_message: string;
	} | null;
	error: {
		code: number;
		message: string;
		errors: [];
	};
}

let initialState: BandDetailsPageState = {
	bandDetailsRequested: false,
	bandDetailsSucceeded: false,
	bandDetailsFailure: false,

	bandDetails: null,
	error: {
		code: 0,
		message: "",
		errors: [],
	},
};

const bandDetailsSlice = createSlice({
	slice: "bandDetails",
	initialState,
	reducers: {
		bandDetailsRequested(state, action) {
			state.bandDetailsRequested = true;
			state.bandDetailsSucceeded = false;
			state.bandDetailsFailure = false;
		},
		bandDetailsSucceeded(state, action) {
			state.bandDetailsRequested = false;
			state.bandDetailsSucceeded = true;
			state.bandDetailsFailure = false;
			state.bandDetails = action.payload;
			state.error = {
				code: 0,
				message: "",
				errors: [],
			};
		},
		bandDetailsFailure(state, action) {
			state.bandDetailsRequested = false;
			state.bandDetailsSucceeded = false;
			state.bandDetailsFailure = true;
			state.error = action.payload;
		},
		favoriteToggle(state, action) {
			if (state.bandDetails) {
				state.bandDetails.item.isFavorite = !state.bandDetails.item
					.isFavorite;
			}
		},
	},
});

export const {
	bandDetailsRequested,
	bandDetailsSucceeded,
	bandDetailsFailure,
	favoriteToggle,
} = bandDetailsSlice.actions;

export default bandDetailsSlice.reducer;

export const getBandById = (
	bandId: number,
	authenticated: boolean,
): any => async (dispatch: any) => {
	try {
		dispatch(bandDetailsRequested());
		const res = await Services.bands.getBandById(bandId, authenticated);
		dispatch(bandDetailsSucceeded(res));
	} catch (err) {
		dispatch(bandDetailsFailure(err));
	}
};
export const getBandByUsername = (
	username: string,
	authenticated: boolean,
): any => async (dispatch: any) => {
	try {
		dispatch(bandDetailsRequested());
		const res = await Services.bands.getBandByUsername(username, authenticated);
		dispatch(bandDetailsSucceeded(res));
	} catch (err) {
		dispatch(bandDetailsFailure(err));
	}
};
export const bandFavoriteToggle = (bandId: number, isFavorite: boolean) => {
	return (dispatch: Dispatch, getState: () => any) => {
		Services.bands.favoriteToggle(bandId, isFavorite).then(
			res => {
				dispatch(favoriteToggle({ bandId, isFavorite }));
				return res;
			},
			error => {},
		);
	};
};
export const bandPage = combineReducers({
	bandDetails: bandDetailsSlice.reducer,
	bandReviews: bandReviewSlice,
	bandRequest: bandRequestSlice,
});
