import React, { useEffect } from "react";
import createStyles from "@material-ui/core/styles/createStyles";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import ListItem from "@material-ui/core/ListItem";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import Box from "@material-ui/core/Box";
import { Theme, makeStyles, useTheme } from "@material-ui/core/styles";
import { Route, Link, useLocation, RouteComponentProps, Switch, Redirect } from "react-router-dom";
import EditBookerProfilePage from "./EditBookerProfilePage";
import EditTalentProfilePage from "./EditTalentProfilePage";
import EditAgentProfilePage from "./EditAgentProfilePage";
import TermsAndConditionPage from "./TermsAndConditionPage";
import CreateBookerEvent from "./BookerEvent/CreateBookerEventPage";
import ListBookerEvent from "./BookerEvent/ListBookerEvents";
import ListTalentEvents from "./TalentEvent/ListTalentEvents";
import { RedirectAs404 } from "../generic/Routes/404Route";
import { AgentBandListData } from "../generic/components/AgentBandList/agent-band-list"
import Message from "./Messages";
import Support from "./Support"
import BillingInformation from "./BillingInformation";
import { IsBooker, IsTalent, IsAgent, IsTalentBooker } from "../AuthBase/";
import SettingsPage from "./SettingsPage";
import TalentAgentPage from "./TalentsAgentPage";
import MyFavorites from "../Favorites";
import AgentBandListDialog from "../AgentBandSearchDialog";
import { Button, Divider } from "@material-ui/core";
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Services from "../generic/Services";
import TalentsAgentRequestPage from "./TalentsAgentRequestPage";
import ListNotificaions from "./NotificationListPage/NotificationView";
import AgentDashboard from "./AgentDashboard";
import TalentImageGallery from "./TalentImageGallery";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		paperLink: {
			width: "100%",
			textDecoration: "none",
			color: "#fff",
		},
		gutters: {
			padding: theme.spacing(3),
			paddingBottom: theme.spacing(1),
		},
		nested: {
			borderLeft: "95px solid transparent",
			fontSize: "1rem",
			marginBottom: theme.spacing(1),
		},
		leftNavigationMenu: {
			borderLeft: "20px solid #0F24FD",
			"& li": {
				borderLeft: "20px solid #0F24FD",
				position: "relative",
				'&::before': {
					position: "absolute",
					backgroundColor: "#0F24FD",
					content: `''`,
					width: "30px",
					height: "22px",
					left: "0px",
					top: "25px"
				}
			}
		},
		dashboard: {
			borderLeft: "0px solid transparent",
			fontSize: "1rem",
			position: "relative",
			marginBottom: theme.spacing(1),
			'&::before': {
				position: "absolute",
				backgroundColor: "#0F24FD",
				content: `''`,
				width: "30px",
				height: "22px",
				left: "0px",
				top: "25px"
			}
		},
		padding: {
			paddingTop: theme.spacing(2),
			paddingBottom: theme.spacing(3),
		},
		mainSection: {
			marginTop: theme.spacing(3),
			fontSize: "1rem",
			fontWeight: "bold",
			borderLeft: `${theme.spacing(3)}px solid transparent`,
		},
		newbandicon: {
			float: "right",
		},
		leftsidebarlisttitle: {
			width: "100%",
			'& h6': {
				width: '100%',
				'& small': {
					'& span': {
						float: "left",
						marginRight: "3px",
						fontWeight: "400",
					},
					'& svg': {
						fontSize: "1.2rem",
					}
				}
			}
		},
		requestlabel: {
			backgroundColor: "#E02020",
			textAlign: "center",
			fontSize: "12px",
			padding: "3px 5px",
			minWidth: "82px",
			borderRadius: "5px",
			color: "#ffffff",
			textDecoration: "none",
			fontWeight: "normal",
		},
	}),
);

export interface IAccountProps {
	agentBandData: any;
	talentData: any;
	setTextFilter: (text: string) => void;
	setTextFilterTalent: (text: string) => void;
	setPastFilterText: (text: string) => void;
	setPastFilterTextTalent: (text: string) => void;
	updateUserData: () => void;
	getAgentBandListData: () => void;
}

export const Account: React.FC<RouteComponentProps<{}> & IAccountProps> = ({
	agentBandData,
	talentData,
	setTextFilter,
	setTextFilterTalent,
	setPastFilterText,
	setPastFilterTextTalent,
	updateUserData,
	getAgentBandListData
}) => {
	const classes = useStyles();

	const location = useLocation();

	const theme = useTheme();

	const [loc, setLoc] = React.useState(location.pathname);
	const [openBandList, setOpenBandList] = React.useState(false);
	const [talentsAgentRequestCount, setTalentsAgentRequestCount] = React.useState(0);

	const closeSearchDialog = () => {
		setOpenBandList(false);
	}

	useEffect(() => {}, [talentData])

	React.useEffect(() => {
		updateUserData();
		getAgentBandListData();

		if (talentData.role === 'TALENT') {
			Services.agent.countTalentsAgentRequest().then(countRequest => {
				if (countRequest) {
					setTalentsAgentRequestCount(countRequest.item)
				}
			})
		}
	}, [loc, updateUserData, getAgentBandListData, setTalentsAgentRequestCount])

	return (
		<Container maxWidth="lg">
			<Grid container={true} spacing={6}>
				<Grid item={true} xs={12} md={4} lg={4}>
					<Box border={1} pb={4} borderColor={theme.palette.secondary.main} borderRadius={4}>
						<List component="nav" className="leftNavigationMenu">
							<IsAgent>
								<ListItem selected={loc === "/account/dashboard"}>
									<Link to="/account/dashboard" style={{ "marginBottom": "-24px", "position": "relative", "zIndex": 1 }} className={classes.paperLink}>
										<Typography variant="subtitle2" classes={{ root: classes.gutters }}>
											My Dashboard
										</Typography>
									</Link>
								</ListItem>
							</IsAgent>
							<ListItem>
								<Typography variant="subtitle2" classes={{ root: classes.gutters }}>
									Account
								</Typography>
							</ListItem>
							<ListItem button className={classes.nested} selected={loc === "/account/edit-profile"}>
								<Link to="/account/edit-profile" className={classes.paperLink}>
									Profile{" "}

									{talentData.role === "TALENT" && talentData?.band_profile_status == "required" ?
										(
											<Typography
												variant="caption"
												style={{
													color: "red",
													fontSize: "11px",
													textTransform: "uppercase"
												}}>
												{talentData?.band_profile_status}
											</Typography>
										) :
										(<Typography
											variant="caption"
											style={{
												color: "yellow",
												fontSize: "11px",
											}}>
											{talentData.status === "PENDING" || talentData.status === "PENDING_APPROVAL" ? "PENDING APPROVAL" : talentData.status === "VIRIFIED" ? "VERIFIED" : talentData.status == "MISSING_PAYMENT" ? "MISSING PAYMENT" : talentData.status}
										</Typography>)
									}
									{/* } */}
								</Link>
							</ListItem>
							<IsTalent>
								<ListItem
									button
									className={classes.nested}
									selected={loc === "/account/photo-gallery"}>
									<Link to="/account/photo-gallery" className={classes.paperLink}>
										Profile Images {" "}
										<Typography
											variant="caption"
											style={{
												color: "red",
												fontSize: "11px",
												textTransform: "uppercase"
											}}>
											{talentData?.profile_images_status == "required" ? "required" : ""}
										</Typography>
									</Link>
								</ListItem>
							</IsTalent>
							<ListItem
								button
								className={classes.nested}
								selected={loc === "/account/billing-information"}>
								<Link to="/account/billing-information" className={classes.paperLink}>
									Billing Information {" "}
									<IsTalent>
										<Typography
											variant="caption"
											style={{
												color: "red",
												fontSize: "11px",
												textTransform: "uppercase"
											}}>
											{talentData?.billing_status == "required" ? "required" : ""}
										</Typography>
									</IsTalent>
								</Link>
							</ListItem>
							<ListItem button className={classes.nested} selected={loc === "/account/settings"}>
								<Link to="/account/settings" className={classes.paperLink}>
									Settings
								</Link>
							</ListItem>
							<IsTalent>
								<ListItem button className={classes.nested} selected={loc === "/account/my-agent"}>
									<Link to="/account/my-agent" className={classes.paperLink}>
										My Agent
									</Link>
									{talentsAgentRequestCount > 0 &&
										<Link to="/account/agent-requests" className={classes.requestlabel}>
											{talentsAgentRequestCount}	Request
										</Link>
									}
								</ListItem>
							</IsTalent>
							<ListItem button className={classes.nested} selected={loc === "/account/terms-conditions"}>
								<Link to="terms-conditions" className={classes.paperLink}>
									Terms and Conditions
								</Link>
							</ListItem>
							<IsTalentBooker>
								<ListItem>
									<Typography variant="subtitle2" classes={{ root: classes.gutters }}>
										My Events
									</Typography>
								</ListItem>
							</IsTalentBooker>
							<IsBooker>
								<ListItem button className={classes.nested} selected={loc === "/account/create-event"}>
									<Link to="/account/create-event" className={classes.paperLink}>
										Create New
									</Link>
								</ListItem>
							</IsBooker>
							<IsAgent>
								<Divider style={{ width: '78%', margin: '30px auto 10px auto', backgroundColor: '#5BFFCB', height: '2px' }} color="secondary" variant="middle" />
								<ListItem className={classes.leftsidebarlisttitle}>
									<Typography variant="subtitle2" classes={{ root: classes.gutters }} className={classes.sidebarsubtitle}>
										My Roster
										<Button variant="text" color="default" className={classes.newbandicon} onClick={function () {
											setOpenBandList(true);
										}} style={{ padding: 0 }}>
											<small><span>New Band</span> <PersonAddIcon /></small>
										</Button>
										<AgentBandListDialog opened={openBandList} closeSearchDialog={closeSearchDialog} />
									</Typography>
								</ListItem>
								{agentBandData &&
									agentBandData.items.map((item: any, index: number) => {
										return (
											<Box key={item.id} width="87%" mx="auto" className={classes.featured}>
												<AgentBandListData agentBands={item}></AgentBandListData>
											</Box>
										);
									})}
							</IsAgent>
							<IsTalentBooker>
								<ListItem button className={classes.nested} selected={loc === "/account/active-event"}>
									<Link to="/account/active-event" className={classes.paperLink}>
										Active
									</Link>
								</ListItem>
								<ListItem button className={classes.nested} selected={loc === "/account/past-event"}>
									<Link to="/account/past-event" className={classes.paperLink}>
										Past
									</Link>
								</ListItem>

								<ListItem button className={classes.mainSection} selected={loc === "/account/messages"}>
									<Link to="/account/messages" className={classes.paperLink}>
										Messages
									</Link>
								</ListItem>
							</IsTalentBooker>
							<IsBooker>
								<ListItem
									button
									className={classes.mainSection}
									selected={loc === "/account/favorites"}>
									<Link to="/account/favorites" className={classes.paperLink}>
										My Favorites
									</Link>
								</ListItem>
								<ListItem
									style={{ display: 'none' }}
									button
									className={classes.mainSection}
									selected={loc === "/account/list-notification"}>
									<Link to="/account/list-notification" className={classes.paperLink}>
										Notifications
									</Link>
								</ListItem>
							</IsBooker>
							<IsTalentBooker>
								<ListItem button className={classes.mainSection} selected={loc === "/account/support"}>
									<Link to="/account/support" className={classes.paperLink}>
										Support
									</Link>
								</ListItem>
							</IsTalentBooker>
						</List>
					</Box>
				</Grid>
				<Grid item={true} xs={12} md={8} lg={8}>
					{/* this is the booker routes */}
					<IsBooker>
						<Switch>
							<Route
								path="/account/billing-information"
								exact
								render={() => <BillingInformation setLoc={setLoc} />}
							/>
							<Route
								path="/account/settings"
								exact
								render={(props: RouteComponentProps) => {
									return <SettingsPage setLoc={setLoc} {...props} />;
								}}
							/>
							<Route
								path="/account/terms-conditions"
								exact
								render={(props: RouteComponentProps) => {
									return <TermsAndConditionPage setLoc={setLoc} {...props} />;
								}}
							/>
							<Route
								path="/account/messages"
								exact
								render={() => {
									setLoc("/account/messages");
									return <Message />;
								}}
							/>
							<Route
								path="/account/support"
								exact
								render={() => {
									setLoc("/account/support");
									return <Support />;
								}}
							/>
							<Route
								path="/account/edit-profile"
								exact
								render={() => <EditBookerProfilePage setLoc={setLoc} />}
							/>
							<Redirect exact from="/account" to="/account/edit-profile" />
							<Route
								path="/account/create-event/:id"
								exact
								render={(props: RouteComponentProps<{ id: string }>) => (
									<CreateBookerEvent setLoc={setLoc} {...props} />
								)}
							/>
							<Route
								path="/account/create-event"
								exact
								render={(props: RouteComponentProps<{ id: string }>) => (
									<CreateBookerEvent setLoc={setLoc} {...props} />
								)}
							/>
							<Route
								path="/account/active-event"
								exact
								render={() => {
									return <ListBookerEvent setLoc={setLoc} status="ACTIVE" />;
								}}
							/>
							<Route
								path="/account/past-event"
								exact
								render={() => {
									return <ListBookerEvent setLoc={setLoc} status="PAST" />;
								}}
							/>
							<Route path="/account/favorites" exact render={() => <MyFavorites setLoc={setLoc} />} />
							<Route path="/account/list-notification" exact render={() => <ListNotificaions />} />
							<Route component={RedirectAs404} />
						</Switch>
					</IsBooker>
					{/* this is the talent routes */}
					<IsTalent>
						<Switch>
							<Route
								path="/account/billing-information"
								exact
								render={() => <BillingInformation setLoc={setLoc} />}
							/>
							<Route
								path="/account/photo-gallery"
								exact
								render={() => <TalentImageGallery setLoc={setLoc} />}
							/>
							<Route
								path="/account/my-agent"
								exact
								render={(props: RouteComponentProps) => {
									return <TalentAgentPage setLoc={setLoc} {...props} />;
								}}
							/>
							<Route
								path="/account/agent-requests"
								exact
								render={(props: RouteComponentProps) => {
									return <TalentsAgentRequestPage setLoc={setLoc} {...props} />;
								}}
							/>
							<Route
								path="/account/settings"
								exact
								render={(props: RouteComponentProps) => {
									return <SettingsPage setLoc={setLoc} {...props} />;
								}}
							/>
							<Route
								path="/account/terms-conditions"
								exact
								render={(props: RouteComponentProps) => {
									return <TermsAndConditionPage setLoc={setLoc} {...props} />;
								}}
							/>
							<Route
								path="/account/messages"
								exact
								render={() => {
									setLoc("/account/messages");
									return <Message />;
								}}
							/>
							<Route
								path="/account/support"
								exact
								render={() => {
									setLoc("/account/support");
									return <Support />;
								}}
							/>
							<Route
								path="/account/edit-profile"
								exact
								render={() => <EditTalentProfilePage setLoc={setLoc} />}
							/>
							<Redirect exact from="/account" to="/account/edit-profile" />
							<Route
								path="/account/active-event"
								exact
								render={() => {
									return <ListTalentEvents setLoc={setLoc} status="ACTIVE" />;
								}}
							/>
							<Route
								path="/account/past-event"
								exact
								render={() => {
									return <ListTalentEvents setLoc={setLoc} status="PAST" />;
								}}
							/>
							<Route component={RedirectAs404} />
						</Switch>
					</IsTalent>
					<IsAgent>
						<Switch>
							<Route
								path="/account/billing-information"
								exact
								render={() => <BillingInformation setLoc={setLoc} />}
							/>
							<Route
								path="/account/settings"
								exact
								render={(props: RouteComponentProps) => {
									return <SettingsPage setLoc={setLoc} {...props} />;
								}}
							/>
							<Route
								path="/account/terms-conditions"
								exact
								render={(props: RouteComponentProps) => {
									return <TermsAndConditionPage setLoc={setLoc} {...props} />;
								}}
							/>
							<Route
								path="/account/messages"
								exact
								render={() => {
									setLoc("/account/messages");
									return <Message />;
								}}
							/>
							<Route
								path="/account/support"
								exact
								render={() => {
									setLoc("/account/support");
									return <Support />;
								}}
							/>
							<Route
								path="/account/edit-profile"
								exact
								render={() => <EditAgentProfilePage setLoc={setLoc} />}
							/>
							<Redirect exact from="/account" to="/account/edit-profile" />
							<Route
								path="/account/create-event/:id"
								exact
								render={(props: RouteComponentProps<{ id: string }>) => (
									<CreateBookerEvent setLoc={setLoc} {...props} />
								)}
							/>

							<Route
								path="/account/edit-band/:id"
								exact
								render={() => <EditTalentProfilePage setLoc={setLoc} />}
							/>
							<Route
								path="/account/photo-gallery/:id"
								exact
								render={() => <TalentImageGallery setLoc={setLoc} />}
							/>
							<Route
								path="/account/band-billing-information/:id"
								exact
								render={() => <BillingInformation setLoc={setLoc} />}
							/>
							<Route
								path="/account/create-event"
								exact
								render={(props: RouteComponentProps<{ id: string }>) => (
									<CreateBookerEvent setLoc={setLoc} {...props} />
								)}
							/>
							<Route
								path="/account/active-event/band/:bandId"
								exact
								render={() => {
									return <ListTalentEvents setLoc={setLoc} status="ACTIVE" />;
								}}
							/>
							<Route
								path="/account/past-event/band/:bandId"
								exact
								render={() => {
									return <ListTalentEvents setLoc={setLoc} status="PAST" />;
								}}
							/>
							<Route
								path="/account/messages/band/:bandId"
								exact
								render={() => {
									setLoc("/account/messages/band/:bandId");
									return <Message />;
								}}
							/>
							<Route path="/account/favorites" exact render={() => <MyFavorites setLoc={setLoc} />} />
							<Route path="/account/list-notification" exact render={() => <ListNotificaions />} />
							<Route path="/account/dashboard" exact render={() => <AgentDashboard setLoc={setLoc} />} />
							<Route component={RedirectAs404} />
						</Switch>
					</IsAgent>
				</Grid>
			</Grid>
		</Container>
	);
};
