import React from "react";
import {
	Button,
	TextField,
	Grid,
	InputLabel,
	Typography,
	Link,
} from "@material-ui/core";
import withStyles from "@material-ui/styles/withStyles";
import { formStyles } from "./styles";
import { IForgetPasswordReducer } from "./interfaces";
import ErrorMessage from "../generic/ErrorMessage";

const Form = (props: {
	classes: any;
	values: {
		email: string;
	};
	errors: any;
	touched: any;
	handleChange: any;
	isValid: any;
	setFieldTouched: any;
	handleSubmit: any;
	state: IForgetPasswordReducer;
}) => {
	const {
		classes,
		values: { email },
		errors,
		touched,
		handleChange,
		setFieldTouched,
		handleSubmit,
		state,
	} = props;

	const change = (name: any, e: { persist: () => void }) => {
		e.persist();
		handleChange(e);
		setFieldTouched(name, true, false);
	};

	return (
		<form onSubmit={handleSubmit} className={classes.root}>
			<Grid container spacing={3} justify="center" direction="row">
				<Grid className={classes.margin} item xs={12}>
					<InputLabel className={classes.InputLabel} htmlFor="email">
						Email Address
					</InputLabel>
					<TextField
						autoComplete="off"
						id="email"
						name="email"
						variant="outlined"
						color="secondary"
						helperText={touched.email ? errors.email : ""}
						error={touched.email && Boolean(errors.email)}
						fullWidth={true}
						margin="dense"
						value={email}
						onChange={change.bind(null, "email")}
					/>
				</Grid>
			</Grid>
			<Grid container spacing={3} justify="center" direction="row">
				<Grid item>
					<Button
						type="submit"
						variant="contained"
						color="secondary"
						disabled={state.forgetPasswordRequest}
					>
						Send Link
					</Button>
				</Grid>
				<Grid item xs={12}>
					{state.error && state.error.code && (
						<ErrorMessage error={state.error} />
					)}
				</Grid>
				<Grid item xs={12} style={{ textAlign: "center" }}>
					<Typography variant="body2">
						Didn't receive an email?{" "}
						<Link
							href="/contact"
							type="button"
							style={{
								color: "white",
								fontSize: "12px",
								fontWeight: "initial",
								textDecoration: "underline"
							}}
						>
							Contact Support.
						</Link>
					</Typography>
				</Grid>
			</Grid>
		</form>
	);
};

export default withStyles(formStyles)(Form);
