import {
	ListEventRequestActionType,
	IListEventRequestAction,
	IItemApproveEventRequest,
} from "../../interfaces";
import { generateConfirmReducer } from "../../../ConfirmationDialog/reducer";

const approveModalOpened = generateConfirmReducer("EVENT_REQUEST_APPROVE_");

const approveIsRequested = (state = false, action: IListEventRequestAction) => {
	switch (action.type) {
		case ListEventRequestActionType.APPROVE_EVENT_REQUEST:
			return true;
		case ListEventRequestActionType.APPROVE_EVENT_SUCCESS:
		case ListEventRequestActionType.APPROVE_EVENT_FAILURE:
			return false;
		default:
			return state;
	}
};

const approveIsSucceeded = (state = false, action: IListEventRequestAction) => {
	switch (action.type) {
		case ListEventRequestActionType.APPROVE_EVENT_SUCCESS:
			return true;
		case ListEventRequestActionType.APPROVE_EVENT_REQUEST:
		case ListEventRequestActionType.APPROVE_EVENT_FAILURE:
			return false;
		default:
			return state;
	}
};

const approveError = (state = null, action: IListEventRequestAction) => {
	switch (action.type) {
		case ListEventRequestActionType.APPROVE_EVENT_FAILURE:
			return action.payload;
		case ListEventRequestActionType.APPROVE_EVENT_REQUEST:
			return null;
		default:
			return state;
	}
};

export const approveRequestReducer = (
	state: IItemApproveEventRequest,
	action: IListEventRequestAction,
) => {
	return {
		approveModalOpened: approveModalOpened(
			state && state.approveModalOpened,
			action,
		),
		approveIsRequested: approveIsRequested(
			state && state.approveIsRequested,
			action,
		),
		approveIsSucceeded: approveIsSucceeded(
			state && state.approveIsSucceeded,
			action,
		),
		approveError: approveError(state && state.approveError, action),
	};
};
