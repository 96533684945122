import { Box, Container, createStyles, CssBaseline, Grid, makeStyles, Typography } from "@material-ui/core";
import ThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import React from "react";
import { Link, Redirect } from "react-router-dom";
import Header from "../../Header";
import { theme } from "../../theme";
import PageNotFoundBackground from "../../Assets/404Bg.jpg";
import MobileImageBackround from "../../Assets/404BgMobile.jpg";
import { Footer } from "../../Footer";

const styles = createStyles({
	headerTransperant: {
		position: 'absolute',
        width: '100%',
        zIndex: 2,
        left: '0px',
        top: '0px',
        background: 'linear-gradient(0deg, rgba(7, 5, 33, 0.0001) 40%, #070521 80%)',
	},
	title404Page: {
		color: '#fff',
		fontSize: "50px",
		fontWeight: 800,
		marginBottom: '7px',
		"@media only screen and (max-width: 900px)": {
			fontSize: "40px",
		},
		"@media screen and (max-width: 640px)": {
			fontSize: "36px",
		},
	},
	subTitle404Page: {
		color: '#0F24FD',
		fontSize: "30px",
		fontStyle: 'italic',
		fontWeight: 700,
		maxWidth: '500px',
		lineHeight: '1.1',
		marginBottom: '15px',
		"@media only screen and (max-width: 900px)": {
			fontSize: "26px",
		},
		"@media screen and (max-width: 640px)": {
			fontSize: "22px",
		},
	},
	text404Page: {
		color: '#ffffff',
		fontSize: "20px",
		fontWeight: 700,
		maxWidth: '600px',
		lineHeight: '1.3',
		"@media only screen and (max-width: 900px)": {
			fontSize: "18px",
		},
		"@media screen and (max-width: 640px)": {
			fontSize: "16px",
		},
	},
	page404: {
		// hide background on small screen
		"@media only screen and (min-width: 600px)": {
			background: "no-repeat fixed",
			backgroundImage: `url(${PageNotFoundBackground})`,
			backgroundSize: "cover",
			height: '100vh',
		},
		"@media only screen and (max-width: 1199px)": {
			backgroundPosition: "bottom center",
    		backgroundColor: "#5bffcc",
			backgroundSize: "contain",
		},
		"@media only screen and (max-width: 599px)": {
			background: "no-repeat",
			backgroundImage: `url(${MobileImageBackround})`,
			backgroundSize: "cover",
			height: '100vh',
			backgroundPosition: "bottom center",
		},
		"@media only screen and (max-width: 375px)": {
			height: '118vh',
		}
		
	},
	pageBoxMain404: {
		minHeight:'calc(100vh - 310px)',
		"@media only screen and (max-width: 767px)": {
			minHeight:'calc(100vh - 140px)',
		},
		"@media only screen and (max-width: 375px)": {
			minHeight:'calc(100vh - 20px)',
		},
	},
	BoxMarginTop: {
		marginTop: '100px',
		"@media only screen and (max-width: 767px)": {
			marginTop: '-40px',
		},
	},
})

const useStyles = makeStyles(styles);

export const Global404 = () => {

	const classes = useStyles();

	return (<ThemeProvider theme={theme}>
		<CssBaseline />
		<div className={classes.page404}>
			<header>
				<Header isWhiteHeader={true} />
			</header>
			<Box className={classes.pageBoxMain404}>
				<Container maxWidth="md">
					<Box className={classes.BoxMarginTop}>
						<Grid container direction="column">
							<Grid item>
								<Typography variant="subtitle2" className={classes.title404Page}>Hear that?</Typography>
								<Typography variant="subtitle2" className={classes.subTitle404Page}>We don’t either. Sounds like this page has been unplugged.</Typography>
								<Typography variant="subtitle2" className={classes.text404Page}>Though this page doesn’t exist, the rest of our site does. Find your way back to our website by clicking <Link to="/" style={{color: '#fff'}}>here </Link>.</Typography>
							</Grid>
						</Grid>
					</Box>
				</Container>
			</Box>
			<Footer />
		</div>
	</ThemeProvider>
	)
};

export const RedirectAs404 = ({ location }: any) => (
	<Redirect to={Object.assign({}, location, { state: { is404: true } })} />
);
