import { connect } from "react-redux";
import { IAppState } from "../../generic/interfaces";
import FilterBar from "./FilterBar";
import { ThunkDispatch } from "redux-thunk";
import {
	filterBandsGenresSet,
	filterBandsPricesFromSet,
	filterBandsPricesToSet,
	filterBandsDatesSet,
	filterBandsSizeSet,
	filterBandsSizeReset,
	filterBandsFavoriteSet,
	filterBandsFavoriteReset,
	filterBandsLocationSet,
	filterBandsLocationReset,
	filterBandsKeywordSet,
	filterBandsOptionsReset,
} from "./filterBarSlice/filterSlice";
import { Genre } from "../../interfaces";
import moment from "moment";
import { search } from "../searchPageSlice";
import { filterBandsSort, filterBandsSortText } from "./filterBarSlice/sortSlice";

const mapStateToProps = (state: IAppState) => ({
	filters: state.searchBands.filterBands.filter,
	sort: state.searchBands.filterBands.sort,
	resources: state.resources,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
	// setters actions
	saveGenres: (genres: Genre[]) => {
		dispatch(filterBandsGenresSet(genres));
		dispatch(search());
	},
	savePrices: (prices: any) => {
		dispatch(filterBandsPricesFromSet(prices.from));
		dispatch(filterBandsPricesToSet(prices.to));
		dispatch(search());
	},
	saveDates: (dates: any) => {
		dispatch(
			filterBandsDatesSet(
				dates.map((day: string) => ({
					day: moment(day, "ddd MMM DD YYYY HH:mm:ss").format("YYYY-MM-DD"),
				})),
			),
		);
		dispatch(search());
	},
	saveSize: (size: number) => {
		dispatch(filterBandsSizeSet(size));
	},
	saveFavorite: (isFavorite: boolean) => {
		dispatch(filterBandsFavoriteSet(isFavorite));
	},
	saveLocation: (id: { id: number } | null) => {
		dispatch(filterBandsLocationSet(id));
	},

	setSort: (by: string, dir: string) => {
		dispatch(filterBandsSort({ by, dir }));
		dispatch(search());
	},
	setSortText: (text: any) => dispatch(filterBandsSortText(text)),

	clearSize: () => {
		dispatch(filterBandsSizeReset());
		dispatch(search());
	},
	clearLocation: () => {
		dispatch(filterBandsLocationReset());
		dispatch(search());
	},
	clearFavorite: () => {
		dispatch(filterBandsFavoriteReset());
		dispatch(search());
	},
	clearFilters: () => {
		dispatch(filterBandsOptionsReset());
		dispatch(search());
	},
	search: () => dispatch(search()),
	handleKeywordChange: (e: any) => {
		e.preventDefault();
		const { value } = e.target;
		dispatch(filterBandsKeywordSet(value));
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterBar);
