import React from "react";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";
import { IImageUploaderProps } from "./interfaces";
import { getImageFile } from "../generic/Services/media";
//@ts-ignore
import { getDroppedOrSelectedFiles } from "html5-file-selector";
import { CloudUploadOutlined } from "@material-ui/icons";
import { ILayoutProps } from "react-dropzone-uploader";
import "./styles.css";

class HeaderUploader extends React.Component<IImageUploaderProps> {
	state = {
		file: undefined,
	};

	componentDidMount() {
		if (this.props.url) {
			const promises = [getImageFile(this.props.url)];
			Promise.all(promises).then(responses => {
				this.setState({ file: responses });
			});
		}
	}

	handleChangeStatus = (temp: any, status: any) => {
		if (status === "removed") {
			this.props.handleChange(null);
		} else if (status === "done") {
			this.props.handleChange(temp.file);
		}
	};

	getFilesFromEvent = async (e: any) => {
		const chosenFiles = await getDroppedOrSelectedFiles(e);
		return chosenFiles.map((f: any) => f.fileObject);
	};

	render() {
		const { file } = this.state;
		const { url } = this.props;
		return (
			<Dropzone
				initialFiles={file}
				LayoutComponent={(props: ILayoutProps) => {
					return (
						<>
							{props.previews &&
								props.previews[props.previews.length - 1]}
							{props.files.length === 0 && (
								<div {...props.dropzoneProps}>
									{props.input}
								</div>
							)}
						</>
					);
				}}
				styles={{
					dropzone: {
						background: `url(${url})`,
						backgroundSize: "cover",
						backgroundPosition: "center",
					},
				}}
				onChangeStatus={this.handleChangeStatus}
				maxSizeBytes={10000000}
				accept="image/*"
				multiple={false}
				inputContent={
					<CloudUploadOutlined
						fontSize="large"
						color="secondary"
						key={1}
					/>
				}
				submitButtonContent={null}
				canRemove={true}
			/>
		);
	}
}

export default HeaderUploader;
