import { combineReducers } from "redux";
import { createSlice } from "redux-starter-kit";
import { Dispatch } from "redux";
import { filterBands } from "./FilterBar/filterBarSlice";
import {
	filterBandsPageIncrement,
	filterBandsPageReset,
} from "./FilterBar/filterBarSlice/pageSlice";
import { IAppState } from "../generic/interfaces";
import Services from "../generic/Services";
import { IBand } from "../interfaces";
import { userActions } from "../AuthBase";

const isBandsRequestedSlice = createSlice({
	slice: "isBandsRequested",
	initialState: false,
	reducers: {
		searchBandsRequest(state, action) {
			return true;
		},
		searchBandsSuccess(state, action) {
			return false;
		},
		searchBandsFail(state, action) {
			return false;
		},
	},
});

export const {
	searchBandsRequest,
	searchBandsSuccess,
	searchBandsFail,
} = isBandsRequestedSlice.actions;

export default isBandsRequestedSlice.reducer;

const isLoadMoreRequestedSlice = createSlice({
	slice: "isLoadMoreRequested",
	initialState: false,
	reducers: {
		searchBandsLoadmoreRequest(state, action) {
			return true;
		},
		searchBandsLoadmoreSuccess(state, action) {
			return false;
		},
		searchBandsLoadmoreFail(state, action) {
			return false;
		},
	},
});

export const {
	searchBandsLoadmoreRequest,
	searchBandsLoadmoreSuccess,
	searchBandsLoadmoreFail,
} = isLoadMoreRequestedSlice.actions;

export const { reducer } = isLoadMoreRequestedSlice;

export interface Bands {
	bands: IBand[] | null;
	error: {
		code: number;
		message: string;
		errors: [];
	};
}

let initialState: Bands = {
	bands: null,
	error: {
		code: 0,
		message: "",
		errors: [],
	},
};

const bandsSlice = createSlice({
	slice: "bands",
	initialState,
	reducers: {
		favoriteToggle(state, action) {
			if (state.bands)
				state.bands = state.bands.map((item: IBand) => {
					if (
						item.id === action.payload.bandId &&
						action.payload.isFavorite === false
					)
						item.isFavorite = true;
					else if (
						item.id === action.payload.bandId &&
						action.payload.isFavorite === true
					)
						item.isFavorite = false;
					return item;
				});
		},
		changeBandStatus(state, action) {
			console.log('action and stats: ', action, state);
			if (state.bands)
				state.bands = state.bands.map((item: any) => {
					if(item.id === action.payload.bandId)
					{
							if(item.agent_band_status){
								item.agent_band_status.request_status = 'SENT_BY_AGENT';
							}
							else
							{
								item.agent_band_status = {};
								item.agent_band_status.request_status = 'SENT_BY_AGENT';
							}	
					}
					
					return action.payload.items;
				});
		},
	},
	extraReducers: {
		[isBandsRequestedSlice.actions.searchBandsSuccess.type]: (
			state,
			action,
		) => {
			state.bands = action.payload.items;
		},
		[isLoadMoreRequestedSlice.actions.searchBandsLoadmoreSuccess.type]: (
			state,
			action,
		) => {
			if (state.bands)
				state.bands = [...state.bands, ...action.payload.items];
		},
	},
});

export const { favoriteToggle } = bandsSlice.actions;
export const { changeBandStatus } = bandsSlice.actions;

const countSlice = createSlice({
	slice: "count",
	initialState: 0,
	reducers: {},
	extraReducers: {
		[isBandsRequestedSlice.actions.searchBandsSuccess.type]: (
			state,
			action,
		) => {
			return action.payload.total_items;
		},
		[isLoadMoreRequestedSlice.actions.searchBandsLoadmoreSuccess.type]: (
			state,
			action,
		) => {
			return action.payload.total_items;
		},
	},
});

const errorSlice = createSlice({
	slice: "count",
	initialState: {},
	reducers: {},
	extraReducers: {
		[isBandsRequestedSlice.actions.searchBandsFail.type]: (
			state,
			action,
		) => {
			return action.error;
		},
		[isLoadMoreRequestedSlice.actions.searchBandsLoadmoreFail.type]: (
			state,
			action,
		) => {
			return action.error;
		},
	},
});

export const search = () => {
	return (dispatch: Dispatch, getState: () => IAppState) => {
		dispatch(filterBandsPageReset());
		const { searchBands, authentication } = getState();
		console.log("Search called");
		dispatch(searchBandsRequest());
		Services.bands
			.agentListBands(searchBands.filterBands, authentication.isLoggedIn)
			.then(
				res => {
					dispatch(searchBandsSuccess(res));
					dispatch(filterBandsPageIncrement());
					return res;
				},
				error => {
					dispatch(searchBandsFail(error));
				},
			);
	};
};

export const updateUser = () => {
	return (dispatch: Dispatch, getState: () => IAppState) => {
		Services.users.getUserData().then(response => {
			dispatch(userActions.updateUserAction(response.item))
			let user = JSON.parse(localStorage.getItem("user") || "");
			localStorage.setItem("user", JSON.stringify({ ...user, status: response.item.status }));
		});
	};
}

export const loadMore = () => {
	return (dispatch: Dispatch, getState: () => any) => {
		dispatch(searchBandsLoadmoreRequest());
		const { searchBands, authentication } = getState();
		Services.bands
			.list(searchBands.filterBands, authentication.isLoggedIn)
			.then(
				res => {
					dispatch(searchBandsLoadmoreSuccess(res));
					dispatch(filterBandsPageIncrement());
					return res;
				},
				error => {
					dispatch(searchBandsLoadmoreFail(error));
				},
			);
	};
};

export const bandFavoriteToggle = (bandId: number, isFavorite: boolean) => {
	return (dispatch: Dispatch, getState: () => any) => {
		Services.bands.favoriteToggle(bandId, isFavorite).then(
			res => {
				dispatch(favoriteToggle({ bandId, isFavorite }));
				return res;
			},
			error => {},
		);
	};
};


export const agentChangeBandStatus = (bandId: number, request_status: string) => {
	return (dispatch: Dispatch, getState: () => any) => {
		console.log('agentChangeBandStatus: ')
		dispatch(changeBandStatus({ bandId, request_status }));
	};
};

const searchBandsReducer = () => {
	return combineReducers({
		isBandsRequested: isBandsRequestedSlice.reducer,
		isLoadMoreRequested: isLoadMoreRequestedSlice.reducer,
		bands: bandsSlice.reducer,
		count: countSlice.reducer,
		error: errorSlice.reducer,
	});
};

export const searchBands = combineReducers({
	searchBands: searchBandsReducer(),
	filterBands,
});
