import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import SearchBands from "./SearchPage";
import { IAppState } from "../generic/interfaces";
import { search, loadMore, updateUser } from "./searchPageSlice";
import { setPage } from "../HomePage/homePageSlice";
import { bandFavoriteToggle } from "./searchPageSlice";
import headerActions from "../Header/actions";

const mapStateToProps = (state: IAppState) => ({
	searchBands: state.searchBands.searchBands,
	authentication: state.authentication,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
	filterBands: () => {
		dispatch(search());
	},
	updateUser: () => {
		dispatch(updateUser());
	},
	loadMore: () => {
		dispatch(loadMore());
	},
	setPage: (page: string) => {
		dispatch(setPage(page));
	},
	favoriteToggle: (bandId: number, isFavorite: boolean) => {
		dispatch(bandFavoriteToggle(bandId, isFavorite));
	},
	openSignupModal: () => {
		dispatch(headerActions.openSignupModal());
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchBands);
