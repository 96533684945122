import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import ResetPassword from "./reset-password";
import { IAppState } from "../generic/interfaces";
import headerActions from "../Header/actions";
import {
	openResetPasswordModal,
	closeResetPasswordModal,
	resetPassword,
	resetSuccess,
	confirmationClose,
} from "./actions";

const mapStateToProps = (state: IAppState, ownProps: any) => ({
	resetPasswordState: state.resetPassword,
});

const mapDispatchToProps = (
	dispatch: ThunkDispatch<{}, {}, any>,
	ownProps: any,
) => ({
	handleOpen: () => dispatch(openResetPasswordModal()),
	handleClose: () => {
		dispatch(closeResetPasswordModal());
	},
	handleSubmit: (data: { password: string }, token: string) => {
		dispatch(resetPassword(data, token));
	},
	handleConfirmationClose: () => {
		dispatch(resetSuccess());
		dispatch(confirmationClose());
		dispatch(headerActions.openLoginModal());
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
