import { connect } from "react-redux";
import ListEventRequest from "./list-event-request";
import { IAppState } from "../../generic/interfaces";
import { ThunkDispatch } from "redux-thunk";
import { ListEventRequestActions, BookerEventRequestActions } from "./actions";

const mapStateToProps = (state: IAppState) => ({
	listEventRequest: state.listEventRequest,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
	listEventRequests: () =>
		dispatch(ListEventRequestActions.listEventRequests()),
	loadMoreEventRequests: () =>
		dispatch(ListEventRequestActions.loadMoreEventRequests()),
	getItemFullObject: (id?: number) =>
		dispatch(BookerEventRequestActions.getItemFullObject(id)),
	openUpdatedModal: (id?: number) =>
		dispatch(BookerEventRequestActions.openUpdatedModal(id)),
	closeUpdatedModal: (id?: number) =>
		dispatch(BookerEventRequestActions.closeUpdatedModal(id)),
	updateEventRequest: (eventRequest: any) =>
		dispatch(BookerEventRequestActions.updateEventRequest(eventRequest)),
	openCancelModal: (id?: number) =>
		dispatch(BookerEventRequestActions.openCancelModal(id)),
	closeCancelModal: (id?: number) =>
		dispatch(BookerEventRequestActions.closeCancelModal(id)),
	cancelEventRequest: (id?: number) =>
		dispatch(BookerEventRequestActions.cancelEventRequest(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ListEventRequest);
