import React, { Component } from "react";
import {
	Container,
	CircularProgress,
	Grid,
	Button,
	Box,
	Typography,
} from "@material-ui/core";
import { IBand } from "../interfaces";
import ErrorMessage from "../generic/ErrorMessage";
import { ISearchBandsSlice } from "./interfaces";
import FilterBar from "./FilterBar";
import { BandCard } from "./BandCard";
import { RouteComponentProps } from "react-router-dom";
import { SuggestionDialog } from "../generic/components/SuggestionDialog/suggestion-dialog";
import InfiniteScroll from "react-infinite-scroll-component";

export interface ISearchPageProps {
	authentication: any;
	searchBands: ISearchBandsSlice;
	filterBands: () => void;
	loadMore: () => void;
	favoriteToggle: (bandId: number, isFavorite: boolean) => void;
	setPage: (page: string) => void;
	updateUser: () => void;
	openSignupModal?: () => void;
}

class SearchBands extends Component<
	RouteComponentProps<{}> & ISearchPageProps
> {
	public componentDidMount() {
		let bandsLength = this.props?.searchBands?.bands?.bands?.length || 0;
		if(this?.props?.history?.action !== 'POP' || bandsLength == 0){
			this.props.setPage("search");
			this.props.filterBands();
			this.props.updateUser();
		}
		
	}

	public render() {
		const {
			searchBands,
			loadMore,
			authentication,
			favoriteToggle,
			openSignupModal
		} = this.props;
		return (
			<Container maxWidth="lg">
				<Box display="flex" justifyContent="between" my={4}>
					{/* {alert(navigator.userAgent)} */}
					<FilterBar />
				</Box>
				<InfiniteScroll
					dataLength={searchBands?.bands?.bands?.length || 0} //{searchBands.count || 0}
					next={loadMore}
					scrollThreshold="70%"
					hasMore={Number(searchBands.count) > 0 &&
						Number(searchBands.count) >
							(searchBands.bands.bands === null
								? 0
								: searchBands.bands.bands.length)}
					loader={<Box display="flex" justifyContent="center" m={4}>
						<CircularProgress />
				</Box>}
					//endMessage={}
					style={{overflow: "hidden"}}
					>
				<Grid container spacing={4}>
					{searchBands.bands && 
						searchBands.bands.bands &&
						searchBands.bands.bands.map((band: IBand) => (
							<Grid item xs={12} sm={12} md={6} lg={4} key={band.id}>
								<BandCard
									band={band}
									authentication={authentication}
									favoriteToggle={favoriteToggle}
									isFavouritePage={false}
									openSignupModal={openSignupModal}
								/>
							</Grid>
						))}
				</Grid>
				</InfiniteScroll>
				<Grid container spacing={4}>
					{!searchBands.isBandsRequested && searchBands.count === 0 && (
						<Grid item xs={12} sm={12} md={12}>
							<Typography component="h6" style={{ textAlign: 'center' }}>
								No Search Results <br/>
								Can't find what you are looking for? Submit a suggestion to us <SuggestionDialog viewType="search" />
							</Typography>
						</Grid>
					)}
					{searchBands.error.code && (
						<ErrorMessage error={searchBands.error} />
					)}
				</Grid>
				{/* {!searchBands.isBandsRequested &&
					Number(searchBands.count) > 0 &&
					Number(searchBands.count) >
						(searchBands.bands.bands === null
							? 0
							: searchBands.bands.bands.length) && (
						<Box display="flex" justifyContent="center" m={4}>
							{searchBands.isLoadMoreRequested ? (
								<CircularProgress />
							) : (
								<Button
									variant="contained"
									color="secondary"
									onClick={loadMore}
									disabled={searchBands.isLoadMoreRequested}>
									Load More Results
								</Button>
							)}
						</Box>
					)} */}
			</Container>
		);
	}
}

export default SearchBands;
