//Ref: https://codesandbox.io/s/nameless-cache-75sns?file=/src/Components/imagesList.js:23-1045
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { DeleteOutlineRounded } from "@material-ui/icons";
import { Grid, InputLabel } from "@material-ui/core";
const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    marginBottom: '10px',
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper
  },
  gridList: {
    width: "auto",
    height: "auto",
  },
  icon: {
    color: "rgba(255, 255, 255, 0.54)",
  },
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ImageGalleryComponent(props) {

  const { imageList, handleDelete } = props;
  const classes = useStyles();
  const [selectedTile, setSelectedTile] = React.useState(null);

  const handleClickOpen = tile => {
    setSelectedTile(tile);
  };

  const handleClose = () => {
    setSelectedTile(null);
  };

  const handleImageDelete = async () => {
    await handleDelete(selectedTile);
    setSelectedTile(null);
  };

  return (
    <div className={classes.root}>
      <Grid item xs={12} md={12} lg={12}>
          <InputLabel htmlFor="header_photo">
              Image Gallery
          </InputLabel>
      </Grid>
      <GridList cols={4}>
        className={classes.gridList}
        {imageList.map(tile => (
          <GridListTile key={tile.id} className={classes.dragThumbImg}>
            <img src={tile.image_path} alt={tile.band_id} />
            <GridListTileBar
              title={''}
              subtitle={''}
              actionIcon={
                <IconButton
                  aria-label='delete image'
                  className={classes.icon}
                  value={tile.id}
                  onClick={() => handleClickOpen(tile)}
                >
                  <DeleteOutlineRounded />
                </IconButton>
              }
            />
          </GridListTile>
        ))}
      </GridList>
      <Dialog
        fullWidth={true}
        open={selectedTile !== null}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}> </Typography>
            <Button autoFocus color="secondary" onClick={handleImageDelete} >
              Delete
            </Button>
          </Toolbar>
        </AppBar>

        {selectedTile && (
          <img src={selectedTile.image_path} alt={selectedTile.band_id} style={{maxHeight: "500px"}} />
        )}
      </Dialog>
    </div>
  );
}