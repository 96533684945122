import { connect } from "react-redux";
import { TalentImageGallery } from "./TalentImageGallery";
import { IAppState } from "../../generic/interfaces";
import { setPage } from "../../HomePage/homePageSlice";
import { ThunkDispatch } from "redux-thunk";
import { updateUser } from "../../SearchPage/searchPageSlice";

const mapStateToProps = (state: IAppState) => ({
	states: state.resources.states,
	user: state.authentication.user
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
	setPage: (page: string) => {
		dispatch(setPage(page));
	},
	updateUserData: () => dispatch(updateUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TalentImageGallery);
