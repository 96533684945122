export enum NotifierActionType {
	ENQUEUE_SNACKBAR = "ENQUEUE_SNACKBAR",
	CLOSE_SNACKBAR = "CLOSE_SNACKBAR",
	REMOVE_SNACKBAR = "REMOVE_SNACKBAR",
}

export interface INotification {
	message: string;
	key: number;
	dismissed?: boolean;
	options?: {
		variant?: string;
		persist?: boolean;
		children?: React.ReactNode;
		preventDuplicate?: boolean;
		action?: any;
		onClose?: any;
	};
}

export interface INotifierReducer {
	notifications: INotification[];
}

export interface INotifierActions {
	type: NotifierActionType;
	payload: any;
}

export interface INotifierProps {
	notifications: INotification[];
	closeSnackbar: any;
	enqueueSnackbar: any;
	removeSnackbar: (key: number) => void;
}
