import { connect } from "react-redux";
import { IAppState } from "../../generic/interfaces";
import { Messages } from "./Messages";
import { setPage } from "../../HomePage/homePageSlice";
import { ThunkDispatch } from "redux-thunk";
import headerActions from "../../Header/actions";
import { setSelectedEvent, bandRequestModalOpen, bandRequestModalClose, editEventRequest } from "../../BandPage/BandRequest/bandRequestSlice"
import { listBookerEventsList } from "../BookerEvent/ListBookerEvents/listBookerEventsSlice";

const mapStateToProps = (state: IAppState) => ({
	authentication: state.authentication,
	unread: state.header.messagesNotification.ids,
	bandRequest: state.bandPage.bandRequest,
	states: state.resources.states,
	user: state.authentication.user
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
	setPage: (page: string) => {
		dispatch(setPage(page));
	},
	markAsSeen: (id: number) => dispatch(headerActions.markAsSeen(id)),
	newMessage: (id: number) => dispatch(headerActions.messageNew(id)),
	setSelectedEvent: (eventItem: any) => dispatch(setSelectedEvent(eventItem)),
	handleOpen: () => {
        dispatch(bandRequestModalOpen());
	},
	handleClose: () => dispatch(bandRequestModalClose()),
	handleBandRequestSubmit: (values: any) => {
		dispatch(editEventRequest(values));
	},
	listBookerEventsList: () => dispatch(listBookerEventsList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Messages);
